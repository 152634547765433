import { PDFDocument } from '@cantoo/pdf-lib';

const defaultSettings = {
  ignoreEncryption: true,
  password: '',
};

export const createBlobUrl = async (pdfBuffer) => {
  const pdfDoc = await PDFDocument.load(pdfBuffer, defaultSettings);
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
};
