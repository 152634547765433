import React from 'react';
import Stack from 'common/components/Stacks/Stack';
import PropTypes from 'prop-types';

const HStack = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Stack direction="row" spacing={2} ref={ref} {...props}>
      {children}
    </Stack>
  );
});

HStack.displayName = 'HStack';

export default HStack;

HStack.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
