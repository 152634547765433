import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import Text from 'common/components/Texts/Text';
import FormErrorMessage from 'common/components/Forms/FormErrorMessage';
import { Controller } from 'react-hook-form';

// Local
import useDocumentCategoryOptions from './hooks/useDocumentCategoryOptions';
import useDocumentTypeOptions from './hooks/useDocumentTypeOptions';
import CustomSelect from 'common/components/CustomSelect';
import { CLASSIFICATION } from './constants';
import { FORM_MESSAGES } from 'common/constants';

const ClassifyStaticFormContent = ({ rhfProps }) => {
  // rhf props
  const { clearErrors, control, errors, setValue, watch } = rhfProps;
  // use documentCategory from rhf to populate documentTypeOptions
  const selectedDocumentCategory = watch(CLASSIFICATION.docCategory);
  // Options for 'Select Document Category' dropdown
  const documentCategoryOptions = useDocumentCategoryOptions();
  // Options for 'Select Document Type' dropdown
  const documentTypeOptions = useDocumentTypeOptions(selectedDocumentCategory);
  // redux
  const { selectedDocument } = useSelector(
    ({ classifyDocumentsV2 }) => classifyDocumentsV2,
  );

  /**
   * ------- *
   * Effects *
   * ------- *
   */

  // set default values for 'Select Document Category' dropdown
  // & 'Select Document Type' dropdown on page load
  useEffect(() => {
    if (selectedDocument) {
      setValue(CLASSIFICATION.docCategory, {
        label: selectedDocument.categoryName,
        value: selectedDocument.categoryName,
      });
      setValue(CLASSIFICATION.docType, {
        label: selectedDocument.documentTypeName,
        value: +selectedDocument.documentTypeId,
      });
    }
  }, [selectedDocument, setValue]);

  const handleCategoryChange = (option) => {
    setValue(CLASSIFICATION.docCategory, option);
    option.value !== selectedDocument.categoryId &&
      setValue(CLASSIFICATION.docType, null);
    clearErrors();
  };

  const handleTypeChange = (option) => {
    setValue(CLASSIFICATION.docType, option);
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */

  return (
    <>
      <FormControl
        isInvalid={errors[CLASSIFICATION.docCategory]}
        sx={{ mb: 4 }}
      >
        <FormLabel htmlFor={CLASSIFICATION.docCategory}>
          <Text fontSize="sm">Select Document Category</Text>
        </FormLabel>
        <Controller
          name={CLASSIFICATION.docCategory}
          control={control}
          rules={{ required: FORM_MESSAGES.requiredField }}
          defaultValue={null}
          /**
           * Note: not using 'as' prop here as we need to have custom
           * logic on onChange. In most cases 'as' prop will do the job
           */
          render={({ value }) => (
            <CustomSelect
              value={value}
              options={documentCategoryOptions}
              onChange={handleCategoryChange}
              hasError={!!errors?.documentCategory}
              inputId={CLASSIFICATION.docCategory}
              data-tracking={CLASSIFICATION.docCategory}
              data-testid={CLASSIFICATION.docCategory}
            />
          )}
        />
        <FormErrorMessage>
          <Text fontSize="sm">{errors.documentCategory?.message}</Text>
        </FormErrorMessage>
      </FormControl>
      <FormControl sx={{ mb: 4 }} isInvalid={errors[CLASSIFICATION.docType]}>
        <FormLabel htmlFor={CLASSIFICATION.docType}>
          <Text fontSize="sm">Select Document Type</Text>
        </FormLabel>
        <Controller
          name={CLASSIFICATION.docType}
          control={control}
          rules={{ required: FORM_MESSAGES.requiredField }}
          defaultValue={null}
          render={({ value }) => (
            <CustomSelect
              value={value}
              options={documentTypeOptions}
              onChange={handleTypeChange}
              hasError={!!errors?.documentType}
              inputId={CLASSIFICATION.docType}
              data-tracking={CLASSIFICATION.docType}
              data-testid={CLASSIFICATION.docType}
            />
          )}
        />
        <FormErrorMessage>
          <Text fontSize="sm">{errors.documentType?.message}</Text>
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

// TODO: better propTypes
ClassifyStaticFormContent.propTypes = {
  rhfProps: PropTypes.object.isRequired,
};

export default ClassifyStaticFormContent;
