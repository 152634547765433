import PropTypes from 'prop-types';

const auditHistoryData = PropTypes.shape({
  showButton: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  questionIds: PropTypes.arrayOf(PropTypes.number),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
});

export default auditHistoryData;
