export const CLASSIFICATION = {
  completed: 'Completed',
  actionNeeded: 'Action Needed',
  unclassified: 'Unclassified',
  notListed: -1,
  unknown: 'Unknown',
  docCategory: 'documentCategory',
  docType: 'documentType',
};

export const DROPDOWN_LABEL = {
  borrowerAssociations: 'Borrower',
  incomeDocumentAssociations: 'Income',
  propertyAssociations: 'Property',
  liabilityDocumentAssociations: 'Liability',
  assetDocumentAssociations: 'Asset',
};

export const DROPDOWN_FIELD = {
  associationDetails: 'associationDetails',
};

export const FEATURE_FLAG = 'featureFlag';
export const IS_LEFT_DRAWER_COLLAPSED = 'isLeftDrawerCollapsed';
export const IS_LOAN_SUMMARY_DRAWER_COLLAPSED = 'isLoanSummaryDrawerCollapsed';
export const QUICK_MATCH = 'quickmatch';
export const CLASSIFY = 'CLASSIFY';
