import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import { pixelsToRem } from 'theme/typography';
import { formatCurrency } from 'common/util/format.js';

export default function SingleLineValue({ title, value, isCurrency = false }) {
  const toRender = isCurrency ? formatCurrency(value) : value;
  const fontSize = pixelsToRem(14);

  return (
    <Flex mb="8px" as="dl">
      <Text fontSize={fontSize} fontWeight="bold" d="inline" pr={3} as="dt">
        {title}
      </Text>
      <Text fontSize={fontSize} d="inline" as="dd">
        {toRender}
      </Text>
    </Flex>
  );
}
SingleLineValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isCurrency: PropTypes.bool,
};
