import RentalCalculationReviewBreakdownModel from './RentalCalculationReviewBreakdownModel';

export default class RentalCalculationReviewBreakdown {
  constructor(data = {}, id = null) {
    this.rentalAddressId = id;

    this.calculationId = this.calculationItems =
      Object.prototype.hasOwnProperty.call(data, 'genericIncomeData')
        ? data.genericIncomeData[0]?.calculationId
        : 0;

    this.calculationItems = Object.prototype.hasOwnProperty.call(
      data,
      'genericIncomeData',
    )
      ? data?.genericIncomeData[0]?.calculationItems[0]
        ? new RentalCalculationReviewBreakdownModel(
            data.genericIncomeData[0].calculationItems[0],
          )
        : new RentalCalculationReviewBreakdownModel()
      : new RentalCalculationReviewBreakdownModel();

    this.inputFields = Object.prototype.hasOwnProperty.call(
      data,
      'genericIncomeData',
    )
      ? data?.genericIncomeData[0]?.inputFields
      : null;
  }

  getRentalAddressId() {
    return this.rentalAddressId;
  }

  getCalculationId() {
    return this.calculationId;
  }

  getCalculationItems() {
    return this.calculationItems;
  }

  getAdjustedPITIA() {
    const filterPITIA = this.inputFields?.filter((val) => val.fieldId === -1);
    if (filterPITIA[0]?.fieldValue) {
      return filterPITIA[0]?.fieldValue;
    }
    return '';
  }

  // 605 - 31 'AdjustedMonthlyGrossIncome'
  // 607 - 32 AdjustedMonthlyGrossIncome
  getAdjustedGrossMonthlyIncome() {
    const filterGrossMonthlyIncome = this.inputFields?.filter(
      (val) => val.fieldName === 'AdjustedMonthlyGrossIncome',
    );
    if (filterGrossMonthlyIncome[0]?.fieldValue) {
      return filterGrossMonthlyIncome[0]?.fieldValue;
    }
    return '';
  }
  getPositiveNetRentalIncome() {
    const filterIncome = this.inputFields?.filter((val) => val.fieldId === -2);
    if (filterIncome[0]?.fieldValue) {
      return filterIncome[0]?.fieldValue;
    }
    return '';
  }
}
