//3rd party
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export const CATEGORY_IDS = {
  CLASSIFY: 0,
  BORROWER: 1,
  LIABILITIES: 2,
  ASSETS: 3,
  INCOME: 4,
  PROPERTY: 5,
  FINAL_REVIEW: 6,
  UNDERWRITER_FINAL_REVIEW: 8,
};

export const CATEGORY_DETAILS = [
  {
    id: CATEGORY_IDS.CLASSIFY,
    name: 'Classify',
    heading: "We've sorted your upload!",
    subHeading: "There are some unrecognized files. Let's identify those.",
  },
  {
    id: CATEGORY_IDS.BORROWER,
    name: 'Borrower',
    heading: 'Check your borrower information',
    subHeading: "Let's make sure all of your borrower information is correct.",
  },
  {
    id: CATEGORY_IDS.LIABILITIES,
    name: 'Liabilities',
    heading: 'Liabilities',
    subHeading: "Let's make sure your liability information is correct.",
  },
  {
    id: CATEGORY_IDS.INCOME,
    name: 'Income',
    heading: 'Income Calculator',
    subHeading: "Let's validate the income documents to determine the income.",
  },
  {
    id: CATEGORY_IDS.PROPERTY,
    name: 'Property',
    heading: 'Property',
    subHeading: 'Verify documentation for the subject property.',
  },
  {
    id: CATEGORY_IDS.ASSETS,
    name: 'Assets',
    heading: 'Assets',
    subHeading: "Let's make sure your asset information is correct",
  },
  {
    id: CATEGORY_IDS.FINAL_REVIEW,
    name: 'Final Review',
    heading: "Let's review",
    subHeading:
      'Run final checks on the loan and then review the conditions that are remaining on the loan.',
  },
  {
    id: CATEGORY_IDS.UNDERWRITER_FINAL_REVIEW,
    name: 'Final Review',
    heading: "Let's review",
    subHeading:
      'Run final checks on the loan and then review the conditions that are remaining on the loan.',
  },
];

export const FIXED_HEADER_FOR_AUTO_SCROLL = '141px';

export const FOOTER_LINKS_TOP = [
  {
    title: 'Contact Us',
    link: 'https://www.uwm.com/contact-us ',
  },
  {
    title: 'NMLS Consumer Access',
    link: 'https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/3038',
  },
  {
    title: 'Privacy Policy',
    link: 'https://www.uwm.com/privacy-policy',
  },
];

export const FOOTER_LINKS_BOTTOM = [
  {
    title: 'Report Suspicious Activity',
    link: 'https://www.uwm.com/report-suspicious-activity',
  },
  {
    title: 'Licensing Disclaimer',
    link: 'https://www.uwm.com/licensing-disclaimer',
  },
  {
    title: 'Terms of Use',
    link: 'https://www.uwm.com/terms-of-use',
  },
];

export const SOCIAL_MEDIA_LINKS = [
  {
    name: 'facebook',
    icon: faFacebook,
    alt: 'facebook',
    ariaLabel: 'UWM link to facebook',
    link: 'https://www.facebook.com/UnitedWholesaleMortgage/',
  },
  {
    name: 'twitter',
    icon: faTwitter,
    alt: 'twitter',
    ariaLabel: 'UWM link to twitter',
    link: 'https://twitter.com/uwmlending',
  },
  {
    name: 'linkedin',
    icon: faLinkedin,
    alt: 'linkedin',
    ariaLabel: 'UWM link to linkedin',
    link: 'https://www.linkedin.com/company/united-wholesale-mortgage',
  },
  {
    name: 'instagram',
    icon: faInstagram,
    alt: 'instagram',
    ariaLabel: 'UWM link to instagram',
    link: 'https://www.instagram.com/uwmlending/',
  },
  {
    name: 'youtube',
    icon: faYoutube,
    alt: 'youtube',
    ariaLabel: 'UWM link to youtube',
    link: 'https://www.youtube.com/channel/UCoEosGADHJZ1e-c4sGJb7dw',
  },
];

export const EASE_NAV_LINKS = [
  {
    linkName: '1003',
    link: `/LoanApplication#url=Borrower`,
  },
  {
    linkName: 'AUS',
    link: `/LoanApplication?redirectToOneClickAUS=true`,
  },
  {
    linkName: 'Document Uploader',
    link: `/LoanApplication#url=AttachDocument`,
  },
];

// TODO: In Jira what story is related to TFS-680064?
// TODO: IMPORTANT NOTE - Add income summary skip back after Production Bug 680064 is fixed.
// Also add null checks to wherever selcetedIncome is used.
export const ALERTS_TO_SKIP_IF_CLEARED = [];
