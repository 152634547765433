// 3rd party imports
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ThemeProvider, THEME_ID } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import WebFont from 'webfontloader';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { pdfjs } from 'react-pdf';
import muiTheme from 'theme/mui';

// local imports
import App from './App';
import udsTheme from 'theme';
import store from './redux';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const queryClient = new QueryClient();
const chakraTheme = extendTheme(udsTheme);

WebFont.load({
  google: {
    families: ['Roboto:400,500,700'],
  },
});

library.add(faInfoCircle);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <ChakraProvider theme={chakraTheme} resetCSS>
          <ThemeProvider
            theme={{
              [THEME_ID]: muiTheme,
            }}
          >
            <CssBaseline />
            <App />
          </ThemeProvider>
        </ChakraProvider>
      </ReduxProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
