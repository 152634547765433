﻿export default class ObfuscatedLoanIdentifierDataModel {
  constructor(id = {}) {
    if (Object.prototype.hasOwnProperty.call(id, 'loanRecordId')) {
      this.obfuscatedLenderDatabaseId = Object.prototype.hasOwnProperty.call(
        id,
        'lenderDatabaseId',
      )
        ? parseInt(id.lenderDatabaseId)
        : 0;
      this.obfuscatedLoanRecordId = parseInt(id.loanRecordId);
      return;
    }

    this.obfuscatedLenderDatabaseId = Object.prototype.hasOwnProperty.call(
      id,
      'obfuscatedLenderDatabaseId',
    )
      ? id.obfuscatedLenderDatabaseId
      : 0;

    this.obfuscatedLoanRecordId = Object.prototype.hasOwnProperty.call(
      id,
      'obfuscatedLoanRecordId',
    )
      ? id.obfuscatedLoanRecordId
      : 0;
  }

  getLenderDatabaseId() {
    return this.obfuscatedLenderDatabaseId;
  }

  getLoanRecordId() {
    return this.obfuscatedLoanRecordId;
  }
}
