import { useSelector } from 'react-redux';
import AlertTabAccordion from 'pages/Home/AlertTabAccordion';

export default function WorkNavAlertItems() {
  const { home } = useSelector((state) => state);
  const currentCategory = home?.navigationItems?.categories?.find(
    (category) => category?.navigationCategoryId === home?.selectedCategory,
  );
  const navigationGutterItems = currentCategory?.navigationGutterItems;

  return (
    <nav aria-label="work items navigation">
      {navigationGutterItems?.map((alert, index) => (
        <AlertTabAccordion
          key={alert?.parentRuleSortOrder + index}
          alert={alert}
          navigationGutterItems={navigationGutterItems}
        />
      ))}
    </nav>
  );
}
