import Alert from 'common/components/Alerts/Alert/Alert';
import AlertDescription from 'common/components/Alerts/AlertDescription';
import AlertTitle from 'common/components/Alerts/AlertTitle';
import AlertIcon from 'common/components/Alerts/AlertIcon';
import PropTypes from 'prop-types';

const Error = ({ error }) => (
  <Alert status="error">
    <AlertIcon />
    <AlertTitle marginRight="8px">Error:</AlertTitle>
    <AlertDescription>{error}</AlertDescription>
  </Alert>
);

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
