﻿export const CUSTOMER_EMPLOYMENT_TYPES = {
  GET_CUSTOMER_EMPLOYMENT_ERROR: 'GET_CUSTOMER_EMPLOYMENT_ERROR',
  GET_CUSTOMER_EMPLOYMENT_STARTED: 'GET_CUSTOMER_EMPLOYMENT_STARTED',
  GET_CUSTOMER_EMPLOYMENT_SUCCESS: 'GET_CUSTOMER_EMPLOYMENT_SUCCESS',
};

export const EMPLOYER_DOCUMENT_TYPES = {
  GET_EMPLOYER_DOCUMENT_ERROR: 'GET_EMPLOYER_DOCUMENT_ERROR',
  GET_EMPLOYER_DOCUMENT_STARTED: 'GET_EMPLOYER_DOCUMENT_STARTED',
  GET_EMPLOYER_DOCUMENT_SUCCESS: 'GET_EMPLOYER_DOCUMENT_SUCCESS',
  GET_EMPLOYER_DOCUMENT_RESET: 'GET_EMPLOYER_DOCUMENT_RESET',
};

export const GET_OTHER_INCOME_DATA_TYPES = {
  GET_OTHER_INCOME_DATA_ERROR: 'GET_OTHER_INCOME_DATA_ERROR',
  GET_OTHER_INCOME_DATA_STARTED: 'GET_OTHER_INCOME_DATA_STARTED',
  GET_OTHER_INCOME_DATA_SUCCESS: 'GET_OTHER_INCOME_DATA_SUCCESS',
};

export const SAVE_INCOME_DOCUMENTS_TYPES = {
  SAVE_INCOME_DOCUMENTS_ERROR: 'IC_SAVE_INCOME_DOCUMENTS_ERROR',
  SAVE_INCOME_DOCUMENTS_STARTED: 'IC_SAVE_INCOME_DOCUMENTS_STARTED',
  SAVE_INCOME_DOCUMENTS_SUCCESS: 'IC_SAVE_INCOME_DOCUMENTS_SUCCESS',
  RESET_INCOME_DOCUMENTS: 'IC_RESET_INCOME_DOCUMENTS',
  SAVE_INCOME_DOCUMENTS_TYPE: 'IC_SAVE_INCOME_DOCUMENTS_TYPE',
};

export const GET_ASSOC_INCOME_DOCS_TYPES = {
  GET_ASSOC_INCOME_DOCS_STARTED: 'IC_GET_ASSOC_INCOME_DOCS_STARTED',
  GET_ASSOC_INCOME_DOCS_SUCCESS: 'IC_GET_ASSOC_INCOME_DOCS_SUCCESS',
  GET_ASSOC_INCOME_DOCS_ERROR: 'IC_GET_ASSOC_INCOME_DOCS_ERROR',
};

export const SAVE_INCOME_ANSWER_TYPES = {
  SAVE_INCOME_ANSWER_ERROR: 'IC_SAVE_INCOME_ANSWER_ERROR',
  SAVE_INCOME_ANSWER_STARTED: 'IC_SAVE_INCOME_ANSWER_STARTED',
  SAVE_INCOME_ANSWER_SUCCESS: 'IC_SAVE_INCOME_ANSWER_SUCCESS',
};

export const SAVE_VALIDATE_DOCUMENTS_TYPES = {
  SAVE_VALIDATE_DOCUMENTS_ERROR: 'IC_SAVE_VALIDATE_DOCUMENTS_ERROR',
  SAVE_VALIDATE_DOCUMENTS_CLEAR: 'IC_SAVE_VALIDATE_DOCUMENTS_CLEAR',
  SAVE_VALIDATE_DOCUMENTS_STARTED: 'IC_SAVE_VALIDATE_DOCUMENTS_STARTED',
  SAVE_VALIDATE_DOCUMENTS_SUCCESS: 'IC_SAVE_VALIDATE_DOCUMENTS_SUCCESS',
  SAVE_VALIDATE_DOCUMENTS_TYPE: 'IC_SAVE_VALIDATE_DOCUMENTS_TYPE',
};

export const GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES = {
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_STARTED:
    'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_STARTED',
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_SUCCESS:
    'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_SUCCESS',
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_ERROR:
    'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_ERROR',
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_CLEAR:
    'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_CLEAR',
};

export const UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES =
  {
    UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED:
      'UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED',
    UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS:
      'UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS',
    UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR:
      'UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR',
  };
export const GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES =
  {
    GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED:
      'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED',
    GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS:
      'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS',
    GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR:
      'GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR',
  };

export const PAY_STUB_TYPES = {
  GET_PAY_STUB_INFO_ERROR: 'GET_PAY_STUB_INFO_ERROR',
  GET_PAY_STUB_INFO_STARTED: 'GET_PAY_STUB_INFO_STARTED',
  GET_PAY_STUB_INFO_SUCCESS: 'GET_PAY_STUB_INFO_SUCCESS',
  GET_PAY_STUB_INFO_CLEAR: 'GET_PAY_STUB_INFO_CLEAR',
};

export const W2_TYPES = {
  GET_W2_INFO_ERROR: 'GET_W2_INFO_ERROR',
  GET_W2_INFO_STARTED: 'GET_W2_INFO_STARTED',
  GET_W2_INFO_SUCCESS: 'GET_W2__INFO_SUCCESS',
  GET_W2_INFO_CLEAR: 'GET_W2__INFO_CLEAR',
};

export const VALIDATE_SCHEDULE_C_TYPES = {
  ERROR: 'GET_SCHEDULE_C_INFO_ERROR',
  STARTED: 'GET_SCHEDULE_C_INFO_STARTED',
  SUCCESS: 'GET_SCHEDULE_C_INFO_SUCCESS',
  CLEAR: 'GET_SCHEDULE_C_INFO_CLEAR',
};

export const VALIDATE_FORM_1099_TYPES = {
  GET_FORM_1099_INFO_ERROR: 'GET_FORM_1099_INFO_ERROR',
  GET_FORM_1099_INFO_STARTED: 'GET_FORM_1099_INFO_STARTED',
  GET_FORM_1099_INFO_SUCCESS: 'GET_FORM_1099_INFO_SUCCESS',
};

export const GET_ASSOC_INCOMES_TYPES = {
  GET_ASSOC_INCOMES_ERROR: 'GET_ASSOC_INCOMES_ERROR',
  GET_ASSOC_INCOMES_STARTED: 'GET_ASSOC_INCOMES_STARTED',
  GET_ASSOC_INCOMES_SUCCESS: 'GET_ASSOC_INCOMES_SUCCESS',
  SET_SELECTED_ASSOC_INCOME_RESULT: 'SET_SELECTED_ASSOC_INCOME_RESULT',
};

export const WVOE_TYPES = {
  VALIDATION_SUMMARY_ERROR: 'VALIDATION_SUMMARY_ERROR',
  VALIDATION_SUMMARY_STARTED: 'VALIDATION_SUMMARY_STARTED',
  VALIDATION_SUMMARY_SUCCESS: 'VALIDATION_SUMMARY_SUCCESS',
};

export const SAVE_CALC_TYPES = {
  SAVE_EMPLOYMENT_INCOME_ERROR: 'IC_CALC_SAVE_EMPLOYMENT_INCOME_ERROR',
  SAVE_EMPLOYMENT_INCOME_STARTED: 'IC_CALC_SAVE_EMPLOYMENT_INCOME_STARTED',
  SAVE_EMPLOYMENT_INCOME_SUCCESS: 'IC_CALC_SAVE_EMPLOYMENT_INCOME_SUCCESS',
  SAVE_CALCULATION_RESET_STATUS: 'IC_CALC_SAVE_CALCULATION_RESET_STATUS',
};

export const CALCULATION_TYPES = {
  ADD_FORM: 'IC_ADD_FORM',
  CLEAR: 'IC_CLEAR_RESULTS',
  GET_SELECTED_CALCULATOR_ERROR: 'IC_GET_SELECTED_CALCULATOR_ERROR',
  GET_SELECTED_CALCULATOR_STARTED: 'IC_GET_SELECTED_CALCULATOR_STARTED',
  GET_SELECTED_CALCULATOR_SUCCESS: 'IC_GET_SELECTED_CALCULATOR_SUCCESS',
  GET_SELECTED_CALCULATOR_CLEAR: 'GET_SELECTED_CALCULATOR_CLEAR',
  UPDATE_CALCULATION_FIELD: 'IC_UPDATE_CALCULATION_FIELD',
};

export const CALCULATION_HISTORY_TYPES = {
  CALC_HISTORY_STARTED: 'IC_CALC_HISTORY_STARTED',
  CALC_HISTORY_SUCCESS: 'IC_CALC_HISTORY_SUCCESS',
  CALC_HISTORY_ERROR: 'IC_CALC_HISTORY_ERROR',
  CALC_HISTORY_RESULT_STARTED: 'IC_CALC_HISTORY_RESULT_STARTED',
  CALC_HISTORY_RESULT_SUCCESS: 'IC_CALC_HISTORY_RESULT_SUCCESS',
  CALC_HISTORY_RESULT_ERROR: 'IC_CALC_HISTORY_RESULT_ERROR',
  CALC_HISTORY_RESULT_RESET: 'IC_CALC_HISTORY_RESULT_RESET',
};

export const RUN_CALCS_TYPES = {
  RUN_CALCS_ERROR: 'IC_RUN_CALCS_ERROR',
  RUN_CALCS_STARTED: 'IC_RUN_CALCS_STARTED',
  RUN_CALCS_SUCCESS: 'IC_RUN_CALCS_SUCCESS',
  RUN_CALCS_RESET: 'IC_RUN_CALCS_REEST',
};

export const VALIDATION_TYPES = {
  ADD_VALIDATION_FORM_BY_DOCUMENT_TYPE:
    'IC_ADD_VALIDATION_FORM_BY_DOCUMENT_TYPE',
  ADD_VALIDATION_FORM_BY_DOCUMENT_ID: 'IC_ADD_VALIDATION_FORM_BY_DOCUMENT_ID',
};

export const GET_INCOME_VALIDATION_KEY_DATES_TYPES = {
  GET_INCOME_VALIDATION_KEY_DATES_STARTED:
    'GET_INCOME_VALIDATION_KEY_DATES_STARTED',
  GET_INCOME_VALIDATION_KEY_DATES_SUCCESS:
    'GET_INCOME_VALIDATION_KEY_DATES_SUCCESS',
  GET_INCOME_VALIDATION_KEY_DATES_ERROR:
    'GET_INCOME_VALIDATION_KEY_DATES_ERROR',
};

export const GET_EMPLOYMENT_QUESTIONS_TYPES = {
  GET_EMPLOYMENT_QUESTIONS_STARTED: 'GET_EMPLOYMENT_QUESTIONS_STARTED',
  GET_EMPLOYMENT_QUESTIONS_SUCCESS: 'GET_EMPLOYMENT_QUESTIONS_SUCCESS',
  GET_EMPLOYMENT_QUESTIONS_ERROR: 'GET_EMPLOYMENT_QUESTIONS_ERROR',
};

export const GET_ANSWERED_QUESTIONS_TYPES = {
  GET_ANSWERED_QUESTIONS_STARTED: 'GET_ANSWERED_QUESTIONS_STARTED',
  GET_ANSWERED_QUESTIONS_SUCCESS: 'GET_ANSWERED_QUESTIONS_SUCCESS',
  GET_ANSWERED_QUESTIONS_ERROR: 'GET_ANSWERED_QUESTIONS_ERROR',
};

export const SET_LOAN_NUMBER = 'IC_SET_LOAN_NUMBER';
export const SAVE_SELECTED_INCOME = 'IC_SAVE_SELECTED_INCOME';
export const RESET_SELECTED_INCOME = 'IC_RESET_SELECTED_INCOME';
export const SET_PAYSTUB_ADDRESS_ALERT = 'IC_SET_PAYSTUB_ADDRESS_ALERT';
export const SET_W2_ADDRESS_ALERT = 'IC_SET_W2_ADDRESS_ALERT';
export const SET_PAYSTUB_DEDUCTION_ALERT = 'IC_SET_PAYSTUB_DEDUCTION_ALERT';

export const INCOME_RESOLUTION_GET = {
  STARTED: 'INCOME_RESOLUTION_GET_STARTED',
  SUCCESS: 'INCOME_RESOLUTION_GET_SUCCESS',
  ERROR: 'INCOME_RESOLUTION_GET_ERROR',
};

export const INCOME_RESOLUTION_SAVE = {
  STARTED: 'INCOME_RESOLUTION_SAVE_STARTED',
  SUCCESS: 'INCOME_RESOLUTION_SAVE_SUCCESS',
  ERROR: 'INCOME_RESOLUTION_SAVE_ERROR',
};

export const PREVIOUS_EMPLOYMENT_GET = {
  STARTED: 'PREVIOUS_EMPLOYMENT_GET_STARTED',
  SUCCESS: 'PREVIOUS_EMPLOYMENT_GET_SUCCESS',
  ERROR: 'PREVIOUS_EMPLOYMENT_GET_ERROR',
};
