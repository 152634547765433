import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { createBlobUrl } from 'common/util/pdfUtils';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';

export const useGetPDFUrl = (currentDocId) => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const pdfEndpoint = `${apiEndPoints.classifyDocuments.GET_PDF}/${obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId}/${obfuscatedLoanIdentifier.obfuscatedLoanRecordId}/${currentDocId}`;

  const fetchPDFUrl = async function () {
    // TODO: this is already defined in a thunk, abstract so it can be mostly be used in both
    if (!currentDocId && currentDocId !== 0) return undefined;

    if (parseInt(currentDocId) === 0) return 'noDocumentFound';

    const { data: pdfBuffer } = await apiClient.get(pdfEndpoint, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    });

    return createBlobUrl(pdfBuffer);
  };

  return useQuery({
    queryKey: ['getPDFUrl', currentDocId],
    queryFn: fetchPDFUrl,
    staleTime: Infinity,
  });
};
