import { InputRightElement as ChakraInputRightElement } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const InputRightElement = ({ children, ...props }) => {
  return (
    <ChakraInputRightElement {...props}>{children}</ChakraInputRightElement>
  );
};

export default InputRightElement;

InputRightElement.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
