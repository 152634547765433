import React from 'react';
import { Table as MUITable } from '@mui/material';
import PropTypes from 'prop-types';
import { tableMapper } from 'common/components/PropertyMappers/tableMapper';

const Table = React.forwardRef(({ children, ...props }, ref) => {
  const tableProps = tableMapper(props);

  return (
    <MUITable ref={ref} {...tableProps}>
      {children}
    </MUITable>
  );
});

Table.displayName = 'Table';

export default Table;

Table.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['medium', 'small']),
    PropTypes.string,
  ]),
  stickyHeader: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
