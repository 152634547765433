import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { IS_LEFT_DRAWER_COLLAPSED } from 'pages/ClassifyDocumentsV2/constants';
import {
  handleDrawerBtnLeft,
  handleWidth,
  handleDrawerBtnSize,
  handleDrawerBtnTop,
} from '../handlers';

export const StyledLeftDrawerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== IS_LEFT_DRAWER_COLLAPSED,
})((props) => ({
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: `${props.theme.colors.gray[50]}`,
  transition: 'all 0.5s ease-in-out',
  opacity: 1,
  width: handleWidth(props),
  zIndex: 1000,
}));

export const StyledLeftDrawerButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  align-items: center;
  top: ${(props) => handleDrawerBtnTop(props)};
  left: ${(props) => handleDrawerBtnLeft(props)};
  height: ${(props) => handleDrawerBtnSize(props)};
  width: ${(props) => handleDrawerBtnSize(props)};
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray[300]};
  border-radius: 50%;
  filter: drop-shadow(rgba(9, 30, 66, 0.25) 0px 1px 1px);
  transition: all 0.5s ease-in-out;
  z-index: 1;
  &:hover {
    background-color: ${(props) => props.theme.colors.blue[100]};
  }
  & > svg {
    ${
      '' /* width: ${(props) => (props.isNavigationVisible ? '5rem' : '1rem')}; */
    };
    width: '5rem';
  }
`;

export const StyledLeftDrawerIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 24px;
  ${
    '' /* font-size: ${({ isNavigationVisible, selected }) =>
    selected && !isNavigationVisible ? '15px' : '24px'}; */
  }
  font-size: 15px;
  line-height: 24px;
  transform-origin: center;
  transition: all 0.5s ease-in-out;
  transform: ${(props) =>
    props.selected ? `rotate(-180deg)` : `rotate(0deg)`};
`;
