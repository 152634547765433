import PropTypes from 'prop-types';

import ConfidenceFooter from './Confidence';
import BOLTAnswerFooter from './BOLTAnswers';

const Footer = ({
  isConfidenceVisible = false,
  isBOLTAnswerVisible = false,
  detailedAnswer = '',
  confidenceLevel = null,
  showGaugeIcon,

  answer = '',
  handleConfidenceLevelColor = () => {},
}) => {
  return (
    <footer
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
      }}
    >
      {isConfidenceVisible && (
        <ConfidenceFooter
          confidenceLevel={confidenceLevel}
          detailedAnswer={detailedAnswer}
          handleConfidenceLevelColor={handleConfidenceLevelColor}
          showGaugeIcon={showGaugeIcon}
        />
      )}

      {isBOLTAnswerVisible && (
        <BOLTAnswerFooter
          confidenceLevel={confidenceLevel}
          detailedAnswer={detailedAnswer}
          answer={answer}
          handleConfidenceLevelColor={handleConfidenceLevelColor}
          showGaugeIcon={showGaugeIcon}
        />
      )}
    </footer>
  );
};

Footer.propTypes = {
  isConfidenceVisible: PropTypes.bool,
  isBOLTAnswerVisible: PropTypes.bool,
  detailedAnswer: PropTypes.string,
  confidenceLevel: PropTypes.number,
  answer: PropTypes.string,
  showFooter: PropTypes.bool,
  setShowFooter: PropTypes.func,
  handleConfidenceLevelColor: PropTypes.func,
  showGaugeIcon: PropTypes.bool,
};

export default Footer;
