import CreditReportLinkFromCredit from 'common/components/Credit/CreditReportLink';
import PropTypes from 'prop-types';

export default function CreditReportLink({ marginBottom }) {
  return <CreditReportLinkFromCredit marginBottom={marginBottom} />;
}

CreditReportLink.propTypes = {
  marginBottom: PropTypes.string,
};
