import React from 'react';
import Box from 'common/components/Box';
import PropTypes from 'prop-types';

const Flex = React.forwardRef(({ children, ...props }, ref) => {
  const updatedProps = { display: 'flex', ...props };

  return (
    <Box ref={ref} {...updatedProps}>
      {children}
    </Box>
  );
});

Flex.displayName = 'Flex';

export default Flex;

Flex.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
