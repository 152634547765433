import PropTypes from 'prop-types';
import CalculationInputFieldDataModel from 'pages/IncomeCalculator/model/CalculationInputFieldDataModel';

const extractedIncomeData = PropTypes.shape({
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentId: PropTypes.number,
      documentType: PropTypes.string,
    }),
  ),
  snippets: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [PropTypes.instanceOf(CalculationInputFieldDataModel)],
      PropTypes.shape({
        snippetId: PropTypes.number,
        fiscalYear: PropTypes.number,
        fieldName: PropTypes.string,
        documentType: PropTypes.string,
        documentId: PropTypes.number,
      }),
    ),
  ),
});

export default extractedIncomeData;
