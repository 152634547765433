﻿import PropTypes from 'prop-types';
import { Box, Flex, SimpleGrid, Text } from 'common/components';
import TrendingAnalysis from './TrendingAnalysis';
import BreakdownValueWithChild from '../styles/BreakdownValueWithChild';
import BreakdownContainer from '../BreakdownContainer';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormTypes } from 'pages/IncomeCalculator/enums';
import { formatCurrency } from 'common/util/format';
import lang from '../language.json';

const k1CombineBox = (selectedResult, currentYear) => {
  return (
    (parseFloat(
      selectedResult
        .getInput(FormTypes.ScheduleK1, currentYear, 'ordinaryBusinessIncome')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.ScheduleK1, currentYear, 'netRentalIncome')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.ScheduleK1, currentYear, 'otherNetRentalIncome')
        ?.getValue(),
    ) || 0)
  );
};

const totalAddbacksAndDeductions = (selectedResult, currentYear) => {
  return (
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1120S, currentYear, 'depletion')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1120S, currentYear, 'depreciation')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1120S, currentYear, 'amortizationCasualtyLoss')
        ?.getValue(),
    ) || 0) -
    ((parseFloat(
      selectedResult
        .getInput(FormTypes.Form1120S, currentYear, 'nonRecurringOtherIncome')
        ?.getValue(),
    ) || 0) +
      (parseFloat(
        selectedResult
          .getInput(
            FormTypes.Form1120S,
            currentYear,
            'mortgagesNotesBondsPayableInLessThanOneYear',
          )
          ?.getValue(),
      ) || 0) +
      (parseFloat(
        selectedResult
          .getInput(FormTypes.Form1120S, currentYear, 'travelAndEntertainment')
          ?.getValue(),
      ) || 0))
  );
};

const annualIncome = (selectedResult, currentYear) => {
  return (
    parseFloat(
      selectedResult
        .getInput(FormTypes.Form1120S, currentYear, 'annualIncome')
        ?.getValue(),
    ) || 0
  );
};

const BreakdownView1120s = ({
  currentYear,
  selectedResult,
  showFullBreakdown,
}) => {
  const useK1 =
    selectedResult
      .getInput(FormTypes.Form1120SOutput, 0, 'didPassLiquidityTest')
      ?.getValue()
      ?.toLowerCase() === 'true';
  const k1Combined = k1CombineBox(selectedResult, currentYear);
  const distributions =
    parseFloat(
      selectedResult
        .getInput(FormTypes.ScheduleK1, currentYear, 'distributions')
        ?.getValue(),
    ) || 0;
  const k1OrDistribution = useK1 ? k1Combined : distributions;
  const {
    FIELDS: { ['1120S']: FIELDS },
  } = lang;

  return (
    <BreakdownYearContainer title={currentYear}>
      <BreakdownValueWithChild
        name={FIELDS.WAGES}
        value={
          parseFloat(
            selectedResult
              .getInput(FormTypes.W2, currentYear, 'medicareWages')
              ?.getValue(),
          ) || 0
        }
        isCurrency={true}
        childrenData={[
          {
            name: FIELDS.W2BOX5,
            value:
              parseFloat(
                selectedResult
                  .getInput(FormTypes.W2, currentYear, 'medicareWages')
                  ?.getValue(),
              ) || 0,
            sign:
              (parseFloat(
                selectedResult
                  .getInput(FormTypes.W2, currentYear, 'medicareWages')
                  ?.getValue(),
              ) || 0) < 0
                ? '-'
                : '+',
          },
        ]}
        fontWeight="bold"
      />
      {showFullBreakdown && (
        <>
          {useK1 ? (
            <BreakdownValueWithChild
              name={FIELDS.K1BOXES}
              value={k1Combined}
              isCurrency={true}
              childrenData={[
                {
                  name: FIELDS.KBOX1,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'ordinaryBusinessIncome',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'ordinaryBusinessIncome',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
                {
                  name: FIELDS.KBOX2,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'netRentalIncome',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'netRentalIncome',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
                {
                  name: FIELDS.KBOX3,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'otherNetRentalIncome',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1,
                          currentYear,
                          'otherNetRentalIncome',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
              ]}
              fontWeight="bold"
            />
          ) : (
            <BreakdownValueWithChild
              name={FIELDS.DISTRIBUTIONS}
              value={distributions}
              isCurrency={true}
              childrenData={[
                {
                  name: FIELDS.DISTRIBUTIONS16D,
                  value: distributions,
                  sign: distributions < 0 ? '-' : '+',
                },
              ]}
              fontWeight="bold"
            />
          )}
          <BreakdownValueWithChild
            name={FIELDS.ADDBACK_DEDUCTIONS}
            value={totalAddbacksAndDeductions(selectedResult, currentYear)}
            isCurrency={true}
            childrenData={[
              {
                name: FIELDS.LINE5,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'nonRecurringOtherIncome',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'nonRecurringOtherIncome',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.LINE1415,
                value:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1120S, currentYear, 'depletion')
                      ?.getValue(),
                  ) || 0) +
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'depreciation',
                      )
                      ?.getValue(),
                  ) || 0),
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1120S, currentYear, 'depletion')
                      ?.getValue(),
                  ) || 0) +
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.Form1120S,
                          currentYear,
                          'depreciation',
                        )
                        ?.getValue(),
                    ) || 0) <
                  0
                    ? '-'
                    : '+',
              },
              {
                name: FIELDS.LLINE17,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'mortgagesNotesBondsPayableInLessThanOneYear',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'mortgagesNotesBondsPayableInLessThanOneYear',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.M1LINE3B,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'travelAndEntertainment',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'travelAndEntertainment',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.AMORTIZATION,
                value: parseFloat(
                  selectedResult
                    .getInput(
                      FormTypes.Form1120S,
                      currentYear,
                      'amortizationCasualtyLoss',
                    )
                    ?.getValue() || 0,
                ),
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'amortizationCasualtyLoss',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.PERCENTOWNERSHIP}
            value={
              parseFloat(
                selectedResult
                  .getInput(
                    FormTypes.ScheduleK1,
                    currentYear,
                    'percentageOwnership',
                  )
                  ?.getValue(),
              ) * 100 || 0
            }
            isCurrency={false}
            childrenData={[
              {
                name: FIELDS.BOXF,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1,
                        currentYear,
                        'percentageOwnership',
                      )
                      ?.getValue(),
                  ) * 100 || 0,
              },
            ]}
            fontWeight="bold"
          />
        </>
      )}
      {showFullBreakdown ? (
        <BreakdownValueWithChild
          name={lang.MONTHLY_CALC}
          isCurrency={true}
          childrenData={[
            {
              name: '',
              value:
                parseFloat(
                  selectedResult
                    .getInput(FormTypes.W2, currentYear, 'medicareWages')
                    ?.getValue(),
                ) || 0,
              sign:
                (parseFloat(
                  selectedResult
                    .getInput(FormTypes.W2, currentYear, 'medicareWages')
                    ?.getValue(),
                ) || 0) < 0
                  ? '-'
                  : '+',
            },
            {
              name: '',
              value: k1OrDistribution,
              sign: k1OrDistribution < 0 ? '-' : '+',
            },
            {
              name: '',
              value: totalAddbacksAndDeductions(selectedResult, currentYear),
              sign:
                totalAddbacksAndDeductions(selectedResult, currentYear) < 0
                  ? '-'
                  : '+',
              monthlyIncome: {
                show: true,
                percentValue:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1,
                        currentYear,
                        'percentageOwnership',
                      )
                      ?.getValue(),
                  ) || 0,
                totalSumValue: annualIncome(selectedResult, currentYear),
              },
            },
          ]}
          fontWeight="bold"
        />
      ) : (
        <Box>
          <Flex fontWeight="bold">
            <Text fontSize="sm">{lang.MONTHLY_CALC}</Text>
          </Flex>
          <Text
            fontSize="sm"
            marginLeft="10px"
            marginTop="2px"
            style={{ textIndent: '-6px' }}
            pl={4}
          >
            {formatCurrency(
              selectedResult
                .getInput(FormTypes.W2, currentYear, 'medicareWages')
                ?.getValue(),
            )}
            &nbsp; &divide; 12 (Months)
          </Text>
        </Box>
      )}
      <BreakdownValueWithChild
        name={currentYear + ' Calculated Monthly Income'}
        isCurrency={true}
        childrenData={[
          {
            name: '',
            value: annualIncome(selectedResult, currentYear) / 12,
            sign: annualIncome(selectedResult, currentYear) < 0 ? '-' : '',
          },
        ]}
        fontWeight="bold"
      />
      {showFullBreakdown && (
        <>
          {' '}
          <BreakdownValueWithChild
            name={FIELDS.SCORP_SUBTOTAL}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1120S, currentYear, 'subTotal')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1120S, currentYear, 'subTotal')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.BORROWER_SHARES_CORPINCOME}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'borrowerShareOfIncomeOrLoss',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1120S,
                        currentYear,
                        'borrowerShareOfIncomeOrLoss',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '',
              },
            ]}
            fontWeight="bold"
          />
        </>
      )}
    </BreakdownYearContainer>
  );
};
BreakdownView1120s.propTypes = {
  currentYear: PropTypes.number.isRequired,
  showFullBreakdown: PropTypes.bool.isRequired,
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};

const Form1120sCalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion,
}) => {
  const years = selectedResult
    .getFiscalYears()
    .filter((year) => year !== 0)
    .sort((a, b) => b - a);

  const currentYear = years[0];

  const isPositiveTrend = selectedResult
    .getInput(FormTypes.Form1120SOutput, 0, 'isPositiveTrend')
    ?.getValue()
    ?.toLowerCase();

  const OneYearBreakdown = () => {
    return (
      <SimpleGrid
        w={`${50 * Math.min(years.length, 2)}%`}
        columns={Math.min(2, years.length)}
        spacingX={0}
        spacingY={8}
        pb={4}
      >
        <BreakdownView1120s
          currentYear={currentYear}
          selectedResult={selectedResult}
          showFullBreakdown
        />
      </SimpleGrid>
    );
  };

  const formula = selectedResult.getFormulas()[0];
  const TwoYearBreakdown = () => {
    const priorYear = years[1];

    return (
      <>
        {isPositiveTrend !== false ? (
          <SimpleGrid
            w={`${50 * Math.min(years.length, 2)}%`}
            columns={Math.min(2, years.length)}
            spacingX={0}
            spacingY={8}
            pb={4}
          >
            <Box pr={4}>
              <BreakdownView1120s
                currentYear={currentYear}
                selectedResult={selectedResult}
                showFullBreakdown={true}
              />
            </Box>
            <BreakdownView1120s
              currentYear={priorYear}
              selectedResult={selectedResult}
              showFullBreakdown={true}
            />
          </SimpleGrid>
        ) : (
          <>
            {(formula.getFormulaId() === 32 ||
              formula.getFormulaId() === 33) && (
              <SimpleGrid
                w={`${50 * Math.min(years.length, 2)}%`}
                columns={Math.min(2, years.length)}
                spacingX={0}
                spacingY={8}
                pb={4}
              >
                <Box pr={4}>
                  <BreakdownView1120s
                    currentYear={currentYear}
                    selectedResult={selectedResult}
                    showFullBreakdown={false}
                  />
                </Box>
                <BreakdownView1120s
                  currentYear={priorYear}
                  selectedResult={selectedResult}
                  showFullBreakdown={false}
                />
              </SimpleGrid>
            )}
            {formula.getFormulaId() === 34 && (
              <SimpleGrid
                w={`${50 * Math.min(years.length, 2)}%`}
                columns={Math.min(2, years.length)}
                spacingX={0}
                spacingY={8}
                pb={4}
              >
                <Box pr={4}>
                  <BreakdownView1120s
                    currentYear={currentYear}
                    selectedResult={selectedResult}
                    showFullBreakdown={true}
                  />
                </Box>
                <BreakdownView1120s
                  currentYear={priorYear}
                  selectedResult={selectedResult}
                  showFullBreakdown={true}
                />
              </SimpleGrid>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <BreakdownContainer
      header="1120S Calculation Breakdown"
      onNewWindow={onNewWindow}
      hideAccordion={hideAccordion}
    >
      <Box>
        {formula && (
          <Text pb={4} fontSize="sm">
            <Text as="span" fontWeight="bold">
              Monthly Income Equation:&nbsp;
            </Text>
            {formula.getFormula()}
          </Text>
        )}

        {years.length === 1 && <OneYearBreakdown />}

        {years.length >= 2 && (
          <Box>
            <TwoYearBreakdown />
            <TrendingAnalysis selectedResult={selectedResult} />
          </Box>
        )}
      </Box>

      <Text mt={4}>
        <Text as="span" fontWeight={500} fontSize="lg">
          Calculated Monthly Income:{' '}
        </Text>
        {formatCurrency(selectedResult.getAmount())}

        {years.length >= 2 && !!isPositiveTrend && (
          <>
            &nbsp;&#40;
            {isPositiveTrend === 'true' ? 'Positive Trend' : 'Negative Trend'}
            &#41;
          </>
        )}
      </Text>
    </BreakdownContainer>
  );
};
Form1120sCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};

export default Form1120sCalculationBreakdown;
