import ValidationInfoDataModel from '../ValidationInfoDataModel';

export default class Form1099ValidationInfoDataModel extends ValidationInfoDataModel {
  constructor(vd = {}) {
    super(vd);
    this.form1099ExtractedInfo = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentExtracted1099R',
    )
      ? vd.employmentExtracted1099R
      : null;
  }

  getExtractedInfo() {
    return this.form1099ExtractedInfo;
  }
}
