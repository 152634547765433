import styled from '@emotion/styled';
import Link from 'common/components/Link';
import Box from 'common/components/Box';
import Text from 'common/componentMapper/Text';

// TODO: figure out a better max width for the container box
export const StyledContainerBox = styled(Box)`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  padding: ${({ padding }) => (padding ? padding : '1rem 0')};
  border-top: ${({ showBorderTop, theme }) => {
    return showBorderTop && `1px solid ${theme.colors.gray[100]}`;
  }};
  border-bottom: ${({ showBorderBottom, theme }) => {
    return showBorderBottom && `1px solid ${theme.colors.gray[100]}`;
  }};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.gray[200] : 'none'};
  width: 100%;
`;

export const StyledInnerContainerBox = styled(Box)`
  width: 100%;
  padding: 1rem 0;
`;

export const StyledFormContainerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  display: ${({ display }) => (display ? display : 'grid')};
  place-items: ${({ placeItems }) => (placeItems ? placeItems : 'center')};
  color: ${({ color, theme }) => (color ? color : theme.colors.blue[300])};
  text-decoration: underline;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '15%')};
  cursor: pointer;
`;

export const StyledAnswerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 1rem;
`;

export const StyledText = styled(Text)`
  padding: ${({ padding }) => (padding ? padding : '0.3rem 0')};
  ${'font-style'}: italic;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const StyledIconBox = styled(Box)``;

export const StyledComponentBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledFooter = styled('footer')`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  flex-direction: column;
`;
