import PropTypes from 'prop-types';

const control = PropTypes.shape({
  defaultValuesRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  fieldArrayDefaultValuesRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  fieldArrayNamesRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  fieldsRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  fieldsWithValidationRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  validateFieldsRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  formState: PropTypes.shape({
    dirtyFields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isSubmitSuccessful: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    isValidating: PropTypes.bool.isRequired,
    submitCount: PropTypes.number.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  mode: PropTypes.shape({
    isOnAll: PropTypes.bool.isRequired,
    isOnBlur: PropTypes.bool.isRequired,
    isOnChange: PropTypes.bool.isRequired,
    isOnSubmit: PropTypes.bool.isRequired,
    isOnTouch: PropTypes.bool.isRequired,
  }),
  reValidateMode: PropTypes.shape({
    isReValidateOnBlur: PropTypes.bool,
    isRevalidateOnChange: PropTypes.bool,
  }),
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  isFormDirty: PropTypes.func.isRequired,
  shouldUnregister: PropTypes.bool.isRequired,
});

export default control;
