import PropTypes from 'prop-types';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { useTheme } from '@chakra-ui/react';
import Box from 'common/components/Box';
import styled from '@emotion/styled';
import Button from 'common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

const WrapperDiv = styled(Box)`
  display: grid;
  margin-bottom: 5px;
`;
export default function ArrayItem({
  fields,
  fieldIndex,
  name,
  remove,
  useConditionalMapper,
  sx,
  addRole,
  value,
  buttonDivSx,
}) {
  const { renderForm } = useFormApi();
  const { colors } = useTheme();

  const editedFields = fields.map((field) => {
    const fieldName = `${name}.${field.name}`;

    if (useConditionalMapper) {
      if (addRole) {
        return {
          ...field,
          name: fieldName,
          role: 'cell',
        };
      } else {
        return {
          ...field,
          name: fieldName,
        };
      }
    }
  });

  return (
    <WrapperDiv
      role={addRole ? 'row' : ''}
      sx={{
        ...sx,
        alignItems: sx.alignItems ? sx.alignItems : 'end',
        gap: sx.gap ? sx.gap : '5px',
      }}
    >
      {renderForm(editedFields)}
      <div role={addRole ? 'cell' : ''} style={{ ...buttonDivSx }}>
        <Button
          isPrimary={true}
          sx={{
            width: '3rem',
            paddingLeft: sx.buttonPaddingLeft ? sx.buttonPaddingLeft : '1rem',
          }}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
          _focus={{ backgroundColor: 'transparent' }}
          isDisabled={value.length === 1}
          onClick={() => {
            remove(fieldIndex);
          }}
        >
          <FontAwesomeIcon icon={faTrash} color={colors.red[600]} />
        </Button>
      </div>
    </WrapperDiv>
  );
}

ArrayItem.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.node),
  fieldIndex: PropTypes.string,
  name: PropTypes.string,
  remove: PropTypes.func,
  update: PropTypes.func,
  sx: PropTypes.object,
  enableIndex: PropTypes.number,
  valuesToCompare: PropTypes.object,
  propertyName: PropTypes.string,
  useConditionalMapper: PropTypes.bool,
  value: PropTypes.array,
  addRole: PropTypes.bool,
  buttonDivSx: PropTypes.object,
};
