const RUN_AUS_TYPES = {
  RUN_AUS_STARTED: 'RUN_AUS_STARTED',
  RUN_AUS_SUCCESS: 'RUN_AUS_SUCCESS',
  RUN_AUS_ERROR: 'RUN_AUS_ERROR',
  SET_AUS_TIMEOUT: 'SET_AUS_TIMEOUT',
  SKIP_AUS_STARTED: 'SKIP_AUS_STARTED',
  SKIP_AUS_SUCCESS: 'SKIP_AUS_SUCCESS',
  SKIP_AUS_ERROR: 'SKIP_AUS_ERROR',
  GET_AUS_RECOMMENDATION_STARTED: 'GET_AUS_RECOMMENDATION_STARTED',
  GET_AUS_RECOMMENDATION_SUCCESS: 'GET_AUS_RECOMMENDATION_SUCCESS',
  GET_AUS_RECOMMENDATION_ERROR: 'GET_AUS_RECOMMENDATION_ERROR',
  RUN_BOLT_APPROVAL_STARTED: 'RUN_BOLT_APPROVAL_STARTED',
  RUN_BOLT_APPROVAL_SUCCESS: 'RUN_BOLT_APPROVAL_SUCCESS',
  RUN_BOLT_APPROVAL_ERROR: 'RUN_BOLT_APPROVAL_ERROR',
  CLEAR_BOLT_APPROVAL_DATA: 'CLEAR_BOLT_APPROVAL_DATA',
  GET_LOAN_CONDITIONS_STARTED: 'GET_LOAN_CONDITIONS_STARTED',
  GET_LOAN_CONDITIONS_SUCCESS: 'GET_LOAN_CONDITIONS_SUCCESS',
  GET_LOAN_CONDITIONS_ERROR: 'GET_LOAN_CONDITIONS_ERROR',
  GET_INITIAL_VALIDATIONS_STARTED: 'GET_INITIAL_VALIDATIONS_STARTED',
  GET_INITIAL_VALIDATIONS_SUCCESS: 'GET_INITIAL_VALIDATIONS_SUCCESS',
  GET_INITIAL_VALIDATIONS_ERROR: 'GET_INITIAL_VALIDATIONS_ERROR',
  PRODUCT_VALIDATION_STARTED: 'PRODUCT_VALIDATION_STARTED',
  PRODUCT_VALIDATION_SUCCESS: 'PRODUCT_VALIDATION_SUCCESS',
  PRODUCT_VALIDATION_ERROR: 'PRODUCT_VALIDATION_ERROR',
};
export default RUN_AUS_TYPES;
