const sxMappings = {
  h: (value) => ({ height: value }),
  w: (value) => ({ width: value }),
  d: (value) => ({ display: value }),
  bg: (value) => ({ backgroundColor: value }),
  background: (value) => ({ backgroundColor: value }),
  bgColor: (value) => ({ backgroundColor: value }),
  bgImg: (value) => ({ backgroundImage: value }),
  bgRepeat: (value) => ({ backgroundRepeat: value }),
  bgPos: (value) => ({ backgroundPosition: value }),
  bgSize: (value) => ({ backgroundSize: value }),
  minW: (value) => ({ minWidth: value }),
  maxW: (value) => ({ maxWidth: value }),
  minH: (value) => ({ minHeight: value }),
  maxH: (value) => ({ maxHeight: value }),
  direction: (value) => ({ flexDirection: value }),
  justify: (value) => ({ justifyContent: value }),
  align: (value) => ({ alignItems: value, textAlign: value }),
  gridGap: (value) => ({ gap: value }),
  spacing: (value) => ({ gap: value }),
  gridColumnGap: (value) => ({ columnGap: value }),
  gridRowGap: (value) => ({ rowGap: value }),
  templateColumns: (value) => ({ gridTemplateColumns: value }),
  templateRows: (value) => ({ gridTemplateRows: value }),
  colSpan: (value) => ({ gridColumn: `span ${value}` }),
  rowSpan: (value) => ({ gridRow: `span ${value}` }),
  colStart: (value) => ({ gridColumnStart: value }),
  columns: (value) => ({ gridTemplateColumns: `repeat(${value}, 1fr)` }),
  spacingX: (value) => ({ columnGap: value }),
  spacingY: (value) => ({ rowGap: value }),
};

const directPropsRegex =
  /^(id|className|style|role|data-|aria-|as|component|classes|name)/;

/** Takes the incoming props and creates mui equivalent sx prop */
export function sxMapper(props) {
  let sx = {};
  let sxProp = {};
  const otherProps = {};

  Object.entries(props).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      if (directPropsRegex.test(key) || typeof value === 'function') {
        otherProps[key] = value;
      } else if (key === 'sx' && typeof value === 'object') {
        sxProp = { ...sxProp, ...value };
      } else if (sxMappings[key]) {
        sx = { ...sx, ...sxMappings[key](value) };
      } else {
        sx[key] = value;
      }
    }
  });

  Object.entries(sxProp).forEach(([sxKey, sxValue]) => {
    if (sxMappings[sxKey]) {
      sx = { ...sx, ...sxMappings[sxKey](sxValue) };
    } else {
      sx[sxKey] = sxValue;
    }
  });

  return { ...otherProps, sx };
}
