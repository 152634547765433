// 3rd party imports
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import Spinner from 'common/components/Spinner/index';
import Center from 'common/components/Center';
import genericPageKeys from 'pages/Generic/keys';

// local imports
import HomeContainer from './HomeContainer';
import BlockedPage from 'common/components/BlockedPage';
import FinalModal from './FinalModal';
import BailOutModal from './BailOutModal';
import useSignalR from 'common/hooks/useSignalR';
import { getAdrCompletionStatus } from '../ClassifyDocumentsV2/redux/thunks';
import { API_STATUS, SIGNAL_R_STATUS } from 'common/constants';
import { HomeActions, HomeThunks } from './redux';
import { setLoan } from '../IncomeCalculator/redux/actions';
import { getLoanInfo } from 'common/redux/thunks';
import CategoriesHeader from './CategoriesHeader';

const Home = () => {
  const notification = useSignalR();
  const queryClient = useQueryClient();
  const toast = useToast();
  const validationErrorToastId = 'loan-validation-error-toast';

  // search for categoryId in URL
  const categoryId = new URLSearchParams(useLocation().search).get(
    'categoryId',
  );

  // local state
  const [showBlockedPage, setShowBlockedPage] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  // redux
  const dispatch = useDispatch();
  const {
    boltStatus,
    isLastAlert,
    latestSignalRNotification,
    navigationItems,
    onInit,
    submitToSetup,
    triggerRules,
    shouldGetNextAlert,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    attachDocs,
  } = useSelector(({ home, url: { obfuscatedLoanIdentifier } }) => ({
    ...home,
    ...obfuscatedLoanIdentifier,
  }));

  // true = Bolt loan submitted, false = Bolt loan not submitted, null = not a bolt loan
  const boltStatusValue = boltStatus?.value;
  const isBoltLoan = boltStatusValue === true || boltStatusValue === false;

  // loading check
  const isBoltStatusLoading = boltStatus?.status === API_STATUS.GETTING;
  const isTriggerAllRulesLoading = triggerRules?.status === API_STATUS.GETTING;

  // error check
  const boltStatusError = boltStatus?.error;
  const navigationItemsError = navigationItems.error;
  const submitToSetupError = submitToSetup?.error;

  // call triggerAllRules, getNavigationDetails, getUnderwriterVerifications
  // on page load. triggerAllRules will be removed in the future according to
  // Soniya Pimple - Backend Architect. Check with her after AIME release.
  useEffect(() => {
    if (
      onInit &&
      obfuscatedLenderDatabaseId &&
      obfuscatedLoanRecordId &&
      isBoltLoan
    ) {
      (async () => {
        dispatch(HomeThunks.triggerAllRules());
        dispatch(HomeThunks.getNavigationDetails());
        dispatch(getLoanInfo());
        dispatch(getAdrCompletionStatus());
        dispatch(setLoan(obfuscatedLenderDatabaseId, obfuscatedLoanRecordId));
        dispatch(HomeActions.setOnInit(false));
      })();
    }
  }, [
    onInit,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    isBoltLoan,
    dispatch,
  ]);

  // update redux store on new signalR notification
  useEffect(() => {
    if (isEmpty(notification)) {
      return;
    }

    dispatch(HomeActions.setLatestSignalRNotification(notification));
  }, [notification, dispatch]);

  // eslint-disable-next-line
  const debouncedGetNavigationDetails = useCallback(
    debounce(() => dispatch(HomeThunks.getNavigationDetails(true)), 750),
    [],
  );

  async function invalidateYamlCaches() {
    return debounce(() =>
      queryClient.invalidateQueries({ queryKey: genericPageKeys.all }),
    );
  }

  // new web notification watcher
  useEffect(() => {
    if (
      latestSignalRNotification?.notificationType ===
        SIGNAL_R_STATUS.RULE_EVALUATION_COMPLETED ||
      latestSignalRNotification?.notificationType ===
        SIGNAL_R_STATUS.REQUEST_COMPLETED
    ) {
      // debounce getNavigationDetails until backend is fixed
      debouncedGetNavigationDetails();
      invalidateYamlCaches();
    } else if (
      (latestSignalRNotification?.notificationType ===
        SIGNAL_R_STATUS.RULE_EVALUATION_ERROR ||
        latestSignalRNotification?.notificationType ===
          SIGNAL_R_STATUS.REQUEST_ERRORED) &&
      shouldGetNextAlert &&
      !onInit
    ) {
      dispatch(HomeActions.setShouldGetNextAlert(false));
      if (!toast.isActive(validationErrorToastId)) {
        toast({
          id: validationErrorToastId,
          title: 'OOPS! An error occurred.',
          description: 'Please try again or contact your AE.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    latestSignalRNotification,
  ]);

  // select category based on URL
  useEffect(() => {
    if (!isLoading && categoryId) {
      dispatch(HomeActions.setSelectedCategory(parseInt(categoryId)));
      window.history.pushState(
        null,
        '',
        `/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}${
          attachDocs ? `/${attachDocs}` : ''
        }`,
      );
    }
  }, [
    dispatch,
    categoryId,
    isLoading,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    attachDocs,
  ]);

  // isLoading check
  useEffect(() => {
    if (onInit || isBoltStatusLoading || isTriggerAllRulesLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [onInit, isBoltStatusLoading, isTriggerAllRulesLoading]);

  // blocked page check
  useEffect(() => {
    if (!obfuscatedLoanRecordId || !obfuscatedLenderDatabaseId) {
      setShowBlockedPage(true);
    } else if (
      boltStatusValue === null ||
      boltStatusError ||
      navigationItemsError
    ) {
      setShowBlockedPage(true);
    } else {
      setShowBlockedPage(false);
    }
  }, [
    boltStatusValue,
    boltStatusError,
    navigationItemsError,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
  ]);

  if (showBlockedPage) {
    return <BlockedPage />;
  }

  if (isLoading) {
    <Center width="100%" height="100%">
      <Spinner
        size="xl"
        thickness="4px"
        color="orange.500"
        emptyColor="gray.200"
      />
    </Center>;
  }

  return (
    <>
      <CategoriesHeader />

      <HomeContainer />
      {isLastAlert && !submitToSetupError && <FinalModal />}
      <BailOutModal />
    </>
  );
};

export default Home;
