import PropTypes from 'prop-types';
import Box from 'common/componentMapper/Box/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getExtractionDataIcon } from '../handlers';
import newIcon from 'common/assets/New Icon.svg';
import { TITLE_ICON_TYPE } from '../../QuestionBox/constants';
import aiIcon from 'common/util/icons/AIOn.svg';
import { UnnamedWidget } from 'lib-node-amb-renderer';

export class ExtractionDataIconClass extends UnnamedWidget {
  iconType;
  color;
  isConfidence;
  constructor(props) {
    super(props);
    this.iconType = props.iconType;
    this.color = props.color;
    this.isConfidence = props.isConfidence;
  }
}

const ExtractionDataIcon = ({
  iconType = null,
  color = '',
  isConfidence = false,
}) => {
  const icon = getExtractionDataIcon(iconType, color);

  let defaultStyles = {
    display: 'grid',
    placeItems: 'center',
    color: 'white',
    textAlign: 'center',
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    height: '1.5rem',
    width: '1.5rem',
    lineHeight: '1.3rem',
    backgroundColor: 'uwmLogo.blue',
    borderRadius: '.3rem',
    marginRight: '.3rem',
  };

  const confidenceStyles = {
    ...defaultStyles,
    color,
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
  };

  let ariaLabel;
  switch (iconType) {
    case TITLE_ICON_TYPE.NEW:
      ariaLabel = 'New Icon';
      break;
    case TITLE_ICON_TYPE.BOLT:
      ariaLabel = 'Question answered from extraction';
      break;
    case TITLE_ICON_TYPE.USER:
      ariaLabel = 'Question answered by user';
      break;
    case TITLE_ICON_TYPE.AUTOMATION:
      ariaLabel = 'Question answered by Automation';
      break;
  }
  if (!icon) {
    return null;
  }

  return (
    <Box sx={isConfidence ? confidenceStyles : defaultStyles}>
      {(() => {
        switch (+iconType) {
          case TITLE_ICON_TYPE.NEW:
            return <img src={newIcon} alt={ariaLabel} />;
          case TITLE_ICON_TYPE.AUTOMATION:
            return <img src={aiIcon} alt={ariaLabel} />;
          default:
            return icon ? (
              <FontAwesomeIcon
                icon={icon}
                fontSize="2rem"
                aria-label={ariaLabel}
              />
            ) : null;
        }
      })()}
    </Box>
  );
};

ExtractionDataIcon.propTypes = {
  iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  isConfidence: PropTypes.bool,
};

export default ExtractionDataIcon;
