import PropTypes from 'prop-types';
import { InputLabel as MUIInputLabel } from '@mui/material';

export default function InputLabel({ children, id, ...props }) {
  return (
    <MUIInputLabel id={id} {...props}>
      {children}
    </MUIInputLabel>
  );
}

InputLabel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  props: PropTypes.object,
};
