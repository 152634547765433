export default class ValidationInfoDataModel {
  constructor(vd = {}) {
    this.borrowerSSN = Object.prototype.hasOwnProperty.call(vd, 'borrowerSSN')
      ? vd.borrowerSSN
      : '';

    this.userInputDocumentData = Object.prototype.hasOwnProperty.call(
      vd,
      'userInputDocumentData',
    )
      ? vd.userInputDocumentData
      : '';

    this.validationResults =
      Object.prototype.hasOwnProperty.call(vd, 'results') &&
      vd.results?.length > 0
        ? vd.results
        : [
            {
              ruleResultId: 3773,
              ruleMetadata: {
                ruleId: 0,
                category: 0,
                subCategoryId: 0,
              },
              status: 0,
              conditionText: '',
              questions: [],
              additionalData: {
                licenseId: '',
              },
            },
          ];
  }

  getBorrowerSSN() {
    return this.borrowerSSN;
  }

  getUserInputDocumentData() {
    return this.userInputDocumentData;
  }

  getValidationResults() {
    return this.validationResults;
  }

  getValidationResult(ruleId) {
    return this.validationResults.filter(
      (vr) => vr.ruleMetadata?.ruleId === ruleId,
    );
  }
}
