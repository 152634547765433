﻿import PropTypes from 'prop-types';
import { Alert, Box, Flex, Text } from 'common/components';
import { formatCurrency } from 'common/util/format';
import BreakdownContainer from '../BreakdownContainer';
import VariableIncomeBreakdown from './VariableIncomeBreakdown';
import W2ResponseDataModel from 'pages/IncomeCalculator/model/W2/W2ResponseDataModel';

const W2CalculationBreakdown = ({
  selectedResult,
  hasWvoeCalculation,
  hideAccordion = false,
  onNewWindow,
}) => {
  const annualDetails = selectedResult.getAnnualDetails();
  const payType = selectedResult.getPayType();
  const trimmedPayType = payType.replace('Income', '');
  const YtdIncomeCalculation = () => {
    return (
      <Box mt={10}>
        <Flex pb={2} alignItems="center">
          <Text mr={1} fontSize="md" d="inline">
            Year-to-Date Income Calculation
          </Text>
        </Flex>

        <Flex pb={2} alignItems="center">
          <Text mr={1} fontSize="md" d="inline">
            Calculation:
          </Text>
          <Text fontSize="md" d="inline" data-testid="ytd-formula">
            {annualDetails[0]?.getFormula()}
          </Text>
        </Flex>

        <Flex pb={2} alignItems="center">
          <Text mr={1} fontSize="md" d="inline">
            Calculated Year-to-Date Income:
          </Text>
          <Text fontSize="md" d="inline" data-testid="monthly-ytd-income">
            {formatCurrency(annualDetails[0]?.getMonthlyYearToDate())}
          </Text>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      {hasWvoeCalculation && (
        <Flex pb={4} alignItems="center">
          <Alert status="info" mb={4}>
            Picking this option may result in additional documentation to
            validate the stability and amount of income. Underwriter will review
            and condition if needed.
          </Alert>
        </Flex>
      )}
      {/* Salary Base & non-variable Hourly Base */}
      {!selectedResult.isBaseIncomeVariable && (
        <Box mb={6}>
          <BreakdownContainer
            header="Review Base Income Calculation"
            calculatedAmount={selectedResult.getCalculationAmount()}
            onNewWindow={onNewWindow}
            hideAccordion={hideAccordion}
          >
            <Flex pb={2} pt={4} alignItems="center">
              <Text mr={1} fontSize="md" d="inline" fontWeight="bold">
                Calculator:
              </Text>
              <Text fontSize="md" d="inline" data-testid="calculator-type">
                {selectedResult.getIncomeCalculatorType()}
              </Text>
            </Flex>
            <Flex pb={2} alignItems="center">
              <Text mr={1} fontSize="md" d="inline" fontWeight="bold">
                Pay Frequency:
              </Text>
              <Text fontSize="md" d="inline" data-testid="formula-name">
                {trimmedPayType}
              </Text>
            </Flex>
            <Flex pb={12} alignItems="center">
              <Text mr={1} fontSize="md" d="inline" fontWeight="bold">
                Rate:
              </Text>
              <Text fontSize="md" d="inline" data-testid="earning-rate">
                {formatCurrency(selectedResult.getEarningRate())}
              </Text>
            </Flex>
            <Box pb={2} pt={2}>
              <Flex pb={4} alignItems="center">
                <Text
                  mr={1}
                  d="inline"
                  fontSize="md"
                  fontWeight="bold"
                  textDecoration="underline"
                >
                  Base Income Calculation
                </Text>
              </Flex>

              <Flex pb={4} alignItems="center">
                <Text mr={1} fontSize="md" fontWeight="bold" d="inline">
                  Calculation:
                </Text>
                <Text fontSize="md" d="inline" data-testid="formula">
                  {selectedResult.getFormula()}
                </Text>
              </Flex>

              <Flex pb={2} alignItems="center">
                <Text mr={1} fontSize="md" fontWeight="bold" d="inline">
                  Calculated Monthly Income:
                </Text>
                <Text fontSize="md" d="inline" data-testid="calculation-amount">
                  {formatCurrency(selectedResult.getCalculationAmount())}
                </Text>
              </Flex>
            </Box>
            {!selectedResult.getIsYearToDateInstantiated() && (
              <YtdIncomeCalculation />
            )}
          </BreakdownContainer>
        </Box>
      )}

      {/* Variable Income Types*/}
      {selectedResult.getVariableIncomes().map((variableIncome, idx) => (
        <Box
          key={idx}
          mt={idx > 0 ? 6 : 0}
          data-testid="additional-variable-incomes"
        >
          <VariableIncomeBreakdown
            {...variableIncome}
            isBaseIncomeVariable={selectedResult.isBaseIncomeVariable}
            hideAccordion={hideAccordion}
          />
        </Box>
      ))}
    </>
  );
};

W2CalculationBreakdown.propTypes = {
  /** The calculation result to view */
  selectedResult: PropTypes.instanceOf(W2ResponseDataModel).isRequired,
  /** If calculation has any WVOE calculation  */
  hasWvoeCalculation: PropTypes.bool,
  hideAccordion: PropTypes.bool,
  onNewWindow: PropTypes.func,
};

W2CalculationBreakdown.defaultProps = {
  hasWvoeCalculation: false,
};

export default W2CalculationBreakdown;
