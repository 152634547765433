import { CloseButton as ChakraCloseButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CloseButton = ({ ...props }) => {
  return <ChakraCloseButton {...props} />;
};

export default CloseButton;

CloseButton.propTypes = {
  props: PropTypes.object,
};
