import PropTypes from 'prop-types';
import Select from '@mui/material/Select';

export default function EnhancedSelect({
  value,
  onChange,
  label,
  isMultiple = false,
  id,
  labelId,
  children,
  ...props
}) {
  return (
    <Select
      labelId={labelId}
      id={id}
      multiple={isMultiple}
      label={label}
      value={value}
      onChange={(e) => onChange(e)}
      {...props}
    >
      {children}
    </Select>
  );
}

EnhancedSelect.propTypes = {
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  id: PropTypes.string,
  labelId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};
