﻿import React from 'react';
import PropTypes from 'prop-types';
import DrawerContent from 'common/components/Drawers/DrawerContent';
import DrawerBody from 'common/components/Drawers/DrawerBody';
import Stack from 'common/components/Stacks/Stack';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import HistoryDrawerHeader from './HistoryDrawerHeader';
import CalculationCard from './CalculationCard';
import HistoricalBreakdown from './HistoricalBreakdown';
import { REQUEST_REFERENCE_TYPE } from '../enums';

const HistoryDrawer = ({
  data,
  selectedCalculation,
  setSelectedCalculation,
  resetCalculationHistoryResult,
  requestReferenceType,
}) => {
  const onSelectSummary = (summary) => {
    setSelectedCalculation(summary.getCalculationId());
  };

  const onNewWindow = () => {};

  const closeCalculation = () => {
    setSelectedCalculation(null);
    resetCalculationHistoryResult();
  };

  if (selectedCalculation) {
    return (
      <DrawerContent>
        <HistoryDrawerHeader />
        <DrawerBody>
          <HistoricalBreakdown
            requestReferenceType={requestReferenceType}
            calculationId={selectedCalculation}
            onClose={closeCalculation}
            onNewWindow={onNewWindow}
          />
        </DrawerBody>
      </DrawerContent>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <DrawerContent>
      <HistoryDrawerHeader />
      <DrawerBody>
        <Stack spacing={4}>
          {data.map((summary, i) => (
            <CalculationCard
              summary={summary}
              onClick={() => onSelectSummary(summary)}
              key={i}
            />
          ))}
        </Stack>
      </DrawerBody>
    </DrawerContent>
  );
};
HistoryDrawer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(HistoricalCalculationDataModel)),
  selectedCalculation: PropTypes.number,
  setSelectedCalculation: PropTypes.func.isRequired,
  resetCalculationHistoryResult: PropTypes.func.isRequired,
  requestReferenceType: PropTypes.oneOf([
    REQUEST_REFERENCE_TYPE.employmentIncome,
    REQUEST_REFERENCE_TYPE.rentalIncome,
  ]).isRequired,
};

export default HistoryDrawer;
