import { get } from 'lodash';
const regExDropin =
  /\{\{(\$([a-zA-Z0-9.[\]]+)|([a-zA-Z0-9]+)(\(([^}]*,?)\)))\}\}/gim;
const functions = {
  /**
   * Given a single string, convert it to title case (ignoring certain words)
   *
   * @param {string} copy - A string that needs to have the title-case rules applied to it.
   * @returns A title Cased string.
   */
  titleCase: (copy) => {
    const exclude = [
      //conjunction
      'and',
      'or',
      'for',
      'not',
      'but',
      'yet',
      'so',
      //articles
      'a',
      'an',
      'the',
      'of',
      //prepositions
      'at',
      'around',
      'by',
      'after',
      'along',
      'for',
      'from',
      'of',
      'on',
      'to',
      'with',
      'without',
    ];
    return copy
      .split(' ')
      .map((word, idx) => {
        if (idx === 0 || exclude.indexOf(word) < 0) {
          return word.substring(0, 1).toUpperCase() + word.substring(1);
        }
        return word;
      })
      .join(' ');
  },

  /**
   * Create a date object.
   *
   * @param {number|string} [arg1] - Epoch number, Date/time string/ or year.
   * @param {number} [mIdx] - Month index (0-11).
   * @param {number} [d] - Day of the month.
   * @param {number} [h] - Hour (0-23).
   * @param {number} [m] - Minutes (0-59).
   * @param {number} [s] - Seconds (0-59).
   * @param {number} [ms] - Milliseconds (0-999).
   * @returns {object} A date object.
   *
   * @example date()
   * @example date(100000)
   * @example
   */
  date: (arg1, mIdx, d, h, m, s, ms) => {
    if (!arg1) {
      return new Date();
    } else if (arg1 && !mIdx) {
      return new Date(arg1);
    } else if (arg1 && mIdx && d && !h) {
      return new Date(arg1, mIdx, d);
    } else if (arg1 && mIdx && d && h && m && s && ms) {
      return new Date(arg1, mIdx, d, h, m, s, ms);
    }
  },

  /**
   * Get the month part of a date object
   *
   * @param {object} dt - A date object.
   * @param {number} [mod=0] - Modification to the month.
   * @returns {string} The month part of a date.
   */
  month: (dt, mod = 0) => {
    return new Date(dt.setMonth(dt.getMonth() + mod)).toLocaleString(
      'default',
      { month: 'long' },
    );
  },

  /**
   * select first N items from an array or list
   *
   * @param {array<string>|string} items - an array or comma-delimited list of items.
   * @param {number} [n=-1] - number of items to group, -1 will select all items (default to -1).
   *
   * @example selectN(['a','b','c'],2) => "a, b"
   * @example selectN('a,b,c,d,e,f') = > "a, b, c, d, e, f"
   */
  selectN: (items, n) => {
    const datum = Array.isArray(items) ? items : items.split(',');
    return datum.slice(0, n === -1 ? datum.length : n).join(', ');
  },

  /**
   * given a number of data strings/array, print the data in a comma delimited collated format
   *
   * @param  {string|array} ...data - comma delimited list, or array of data
   * @returns {string} collated comma delimited list of data
   * @example collate('1,2,3','a,b,c', 'A,B,C') => "1 a A, 2 b B, 3 c C"
   */
  collate: (...data) => {
    //ensure that all data elements are array
    const workingSet = data.map((datum) =>
      Array.isArray(datum) ? datum : datum.split(','),
    );
    //loop over each data element, and grab the corresponding item, to collate them
    return workingSet[0]
      .map((_, idx) => workingSet.map((curr) => curr[idx]).join(' '))
      .join(', ');
  },

  /**
   * create a list of months that have missing payments.
   *
   * @param {string|date} firstMissing - first missing m/y
   * @param {string|date} [current=new Date()] - end/current m/y
   * @returns a comma list of all months from firstMissing month, through current
   */
  missingPaymentMonths: (firstMissing, current = new Date()) => {
    //make sure we have a data object
    const firstDate =
      typeof firstMissing === Object ? firstMissing : new Date(firstMissing);
    const endDate = typeof dateEnd === Object ? current : new Date(current);

    //calculate the number of difference in month
    const monthDiff =
      (endDate.getYear() - firstDate.getYear()) * 12 +
      endDate.getMonth() -
      firstDate.getMonth();

    //build an array of months
    const months = [];
    for (let offset = 0; offset <= monthDiff; offset++) {
      const curr = new Date(firstDate.getTime());
      months.push(
        new Date(curr.setMonth(firstDate.getMonth() + offset)).toLocaleString(
          'default',
          { month: 'long' },
        ),
      );
    }

    //convert the array to a friendly human readable list
    return months.join(', ');
  },
};

/**
 * Given a string with dropins, evaluate the dropins.
 *
 * @param {string} copy - String that contain dropins to evaluate.
 * @param {*} additionalData - additionalData from the API used to evaluate dropins
 * @param {*} data - extra data used to evaluate dropins
 * @returns copy modified with dropins evaluated.
 */
function render(copy, additionalData, data) {
  let working = copy;
  let match = regExDropin.exec(working);
  let retroAdditionalData = retrofy(additionalData || {});
  const retroData = data;
  while (match) {
    const [completeDropin, dropin, variable, fn] = match;
    let replacingValue = get(retroAdditionalData, variable);
    if (variable && retroAdditionalData && replacingValue) {
      working = working.replace(completeDropin, replacingValue);
    } else if (variable && retroData && variable in retroData) {
      working = working.replace(completeDropin, retroData[variable]);
    } else if (fn && functions[fn]) {
      const tokens = parse(dropin);
      const tree = buildTree(tokens, []);
      const expression = evalExpression(
        tree.pop(),
        retroAdditionalData,
        retroData,
      );
      working = working.replace(completeDropin, expression, retroData);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Unsupported Dropin: [${dropin}] in [${Object.keys(
          retroAdditionalData || {},
        )}], [${Object.keys(retroData || {})}]`,
      );
      //replace dropin with
      working = working.replace(completeDropin, `~!${dropin}!~`);
    }
    regExDropin.lastIndex = 0;
    match = regExDropin.exec(working);
  }
  return working;
}

/**
 * convert new additionalData format to previous format
 *
 * @todo: This function should be removed, and dropin evaluation should handle the new format
 * @param {object} additionalData - extra data from the API used to render content {Section:[{field1:'foo',field2:'bar'},{field1:'lorem',field2:'ipsum'}]}
 * @return {object} - extra data to use in rendering content {field1:'foo,lorem', field2:'bar,ipsum'}
 */
function retrofy(additionalData) {
  const data = {};
  Object.keys(additionalData).forEach((key) => {
    additionalData[key].forEach((record) => {
      Object.keys(record ?? {}).forEach((field) => {
        if (!(field in data)) {
          data[field] = [];
        }
        data[field].push(record[field]);
      });
    });
  });

  return data;
}

/**
 * convert new additionalData format to previous format
 *
 * @todo: This function should be removed, and dropin evaluation should handle the new format
 * @param {object} additionalData - selected answers for a form question {questionValue:[{field1:'foo',field2:'bar'},{field1:'lorem',field2:'ipsum'}]}
 * @return {object} - selected answers for a form question for rendering content {questionValue:'foo lorem,bar ipsum'}
 */
// function simplifyAnswer(data) {
// if (data) {
//   return Object.keys(data).reduce((datum, key) => {
//     let keyData = data[key];
//     if (Array.isArray(keyData)) {
//       const nonIdFields = Object.keys(keyData[0]).filter(
//         (field) => field.indexOf('Id') === -1,
//       );
//       keyData = keyData
//         .map((ans) => nonIdFields.map((field) => ans[field]).join(' '))
//         .join(',');
//     }
//     return { ...datum, [key]: keyData };
//   }, {});
// }
// return data;
// }

/**
 * Parse a dropin into a series of token for further evaluation
 *
 * @param {string} dropin - a dropin to be parsed
 * @returns {array} a series of tokens
 */
function parse(dropin) {
  let working = dropin;
  let tree = [];
  do {
    const cls = getPeekClass(peek(working));
    if (cls !== 'group') {
      const [token, rest] = consumeToken(working, cls);
      tree.push(token);
      working = rest;
    }
  } while (working.length > 0);
  return tree;
}

/**
 * View the first character in the dropin to determine the token class.
 *
 * @param {string} copy a dropin to parse
 * @returns {string} peek at the first character of the dropin.
 */
function peek(copy) {
  return copy.substring(0, 1);
}

/**
 *
 * @param {string} peek - first character in the dropin used to determine the token class
 * @returns {string} the token class to be comsumed off the dropin
 */
function getPeekClass(peek) {
  const characterClasses = {
    letter: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJLKMNOPQRSTUVWXYZ_'.split(''),
    number: '0123456789'.split(''),
    space: [' '],
    special: `()[]{}"',-`.split(''),
    dropin: ['$'],
  };
  const classes = Object.keys(characterClasses);
  return classes.filter((cls) => characterClasses[cls].indexOf(peek) >= 0)[0];
}

/**
 *
 * @param {string} copy - the dropin to consume a token from
 * @param {string} tokenClass - the class of token to consume from the dropin
 * @returns {array} [tokenObj, rest] the parsed toke object, and the rest of the dropin
 */
function consumeToken(copy, tokenClass) {
  const characterClasses = {
    letter: /([a-zA-Z][a-zA-Z0-9_]*)(.*)/,
    number: /([0-9]+)(.*)/,
    space: /( +)(.*)/,
    special: /([()[\]{}"',-])(.*)/,
    dropin: /(\$[a-zA-Z0-9_]*)(.*)/,
  };
  const [, token, rest] = characterClasses[tokenClass].exec(copy);

  return [polishToken(token, tokenClass), rest];
}

/**
 * given a string token, polish it as a boolean, number, or function
 *
 * @param {string} token - the token parsed off the copy
 * @param {string} tokenClass - the class of token parsed
 * @returns {Object} - a Token object that contains the polished token and the tokenClass
 */
function polishToken(token, tokenClass) {
  switch (tokenClass) {
    case 'letter':
      if (token === 'true' || token === 'false') {
        return { token: token === 'true', tokenClass: 'boolean' };
      } else if (functions[token]) {
        return { token: functions[token], tokenClass: 'function', args: [] };
      }
      return { token, tokenClass };
    case 'number':
      return { token: parseInt(token), tokenClass };
    default:
      return { token, tokenClass };
  }
}

/**
 * Given a series of tokens, build a token tree that is used to evaluate an expression (recursive function)
 *
 * @param {array} - array of tokens from the parsed dropin.
 * @param {array} - root of the tree to build.
 * @param {string} - end token to look for when comibining/grouping tokens for this level
 * @returns {array} a local tree of tokens
 */
function buildTree(stream, tree, groupEnd) {
  const specialTokenInverse = {
    '{': '}',
    '[': '}',
    '(': ')',
    "'": "'",
    '"': '"',
  };
  let prevToken;
  while (stream.length > 0) {
    const currToken = stream.shift();
    if (currToken) {
      const { token, tokenClass } = currToken;
      switch (tokenClass) {
        case 'function':
        case 'dropin':
          tree.push(currToken);
          break;
        case 'letter':
        case 'number':
          tree.push(token);
          break;
        case 'space':
          //check to see if we are inside quotes, and need to preserve
          if (['"', "'"].indexOf(groupEnd) >= 0) {
            tree.push(token);
          }
          break;
        case 'special':
          //check to see if we are at the end of this branch
          if (groupEnd === token) {
            //return this branch to the parent
            return tree;
          } else if (specialTokenInverse[token]) {
            //we are starting a new branch of tokens to support this tken

            if (token === '(' && prevToken.tokenClass === 'function') {
              //function call
              prevToken.args = buildTree(
                stream,
                [],
                specialTokenInverse[token],
              );
            } else if (token === '"' || token === "'") {
              //string
              const str = buildTree(stream, [], token).join('');
              tree.push(str);
            } else {
              //something else
              tree.push(buildTree(stream, [], specialTokenInverse[token]));
            }
          }
          break;
        default:
          throw new Error(`Unhandled Token type ${tokenClass}`);
      }
      prevToken = currToken;
    }
  }
  return tree;
}

/**
 * Evaluate a dropin Expression
 *
 * @param {Object} node - A node in the parse tree.
 * @param {Object} additionalData - API additionalData to render dropins with
 * @param {Object} data - More data to render dropins with.
 * @returns {string} The evaluated dropin expression.
 */
function evalExpression(node, additionalData, data) {
  const val = node.token(
    ...node.args.map((arg) => {
      if (arg.tokenClass === 'function') {
        return evalExpression(arg, additionalData, data);
      } else if (arg.tokenClass === 'dropin') {
        const token = arg.token.substring(1);
        return additionalData[token] ? additionalData[token] : data[token];
      }
      return arg;
    }),
  );
  return val;
}

export default render;
