import ChakraSelect from 'common/components/Selects/Select';
import FormLabel from 'common/componentMapper/FormLabel';
import FormControl from 'common/componentMapper/FormControl';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { validationError } from '../utils';
import { forwardRef, useEffect } from 'react';
import SelectWithToolTip from './SelectWithToolTip';

const Select = forwardRef((props, ref) => {
  const {
    id,
    options = [],
    input,
    label,
    onClick,
    placeholder,
    fcStyle,
    meta,
    validateOnMount,
    isDisabled,
    sx,
    role,
    showToolTip,
    onChange,
    onBlur,
    onFocus,
    isRequired,
    dataTestId,
  } = useFieldApi({
    ...props,
    type: 'select',
  });

  const { name } = props;

  const invalid = validationError(meta, validateOnMount);
  const text = invalid || ((meta.touched || validateOnMount) && meta.warning);

  useEffect(() => {
    if (input) {
      input.onChange(`${input.value}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Show label in the tooltip incase value is different from label
  const findLabelForTooltip = options?.find((m) => m.value === input.value);
  const cutOffLength = 10;

  return (
    <FormControl
      sx={{ ...fcStyle }}
      isInvalid={!!invalid}
      defaultValue={meta.initial}
      role={role ? role : 'group'}
      isRequired={isRequired && label}
    >
      <FormLabel mb="unset">{label}</FormLabel>
      {showToolTip && findLabelForTooltip?.label.length > cutOffLength ? (
        <SelectWithToolTip
          name={name}
          id={id ? id : name}
          options={options}
          input={input}
          label={label}
          onClick={(e) => {
            input.onChange(e);
            onChange?.(e);
          }}
          placeholder={placeholder}
          isDisabled={isDisabled}
          sx={sx}
          invalid={invalid}
          ref={ref}
          dataTestId={dataTestId}
        />
      ) : (
        <ChakraSelect
          name={name}
          id={id ? id : name}
          onClick={onClick}
          placeholder={placeholder}
          isInvalid={!!invalid}
          isDisabled={isDisabled}
          data-testid={dataTestId}
          {...input}
          onChange={(e) => {
            input.onChange(e);
            onChange?.(e);
          }}
          onBlur={(e) => {
            input.onBlur(e);
            onBlur?.(e);
          }}
          onFocus={(e) => {
            input.onFocus(e);
            onFocus?.(e);
          }}
          bg="white"
          whiteSpace={sx?.whiteSpace ? sx?.whiteSpace : 'break-spaces'}
          minHeight={'2.5rem'}
          height={'auto'}
          {...(input.value === '' && { color: 'gray' })}
          sx={{ ...sx }}
          ref={ref}
        >
          {!!options.length &&
            options.map(({ label, value }, idx) => (
              <option
                key={`${name}-${idx}`}
                value={value}
                data-testId={`${dataTestId}-option`}
              >
                {label}
              </option>
            ))}
        </ChakraSelect>
      )}
      {!!invalid && <FormErrorMessage>{text}</FormErrorMessage>}
    </FormControl>
  );
});

Select.displayName = 'SelectWithRef';

Select.propTypes = {
  name: PropTypes.string,
};

export default Select;
