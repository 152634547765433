import { Children, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { titleCase } from 'title-case';

const uCaseChildren = (children) => {
  return Children.map(children, (child) => {
    /* c8 ignore next */
    if (child) {
      /* c8 ignore next */
      if (typeof child === 'string') {
        return titleCase(child);
      } else if (Array.isArray(child)) {
        return uCaseChildren(child);
      } else if (typeof child === 'object') {
        return {
          ...child,
          props: {
            ...child.props,
            children: child.props.children
              ? uCaseChildren(child.props.children)
              : null,
          },
        };
      }
    }
  });
};

const TitleCaseUi = (Ui) => {
  const TitleCase = forwardRef(({ children, ...props }, ref) => {
    return (
      <Ui {...props} ref={ref}>
        {uCaseChildren(children)}
      </Ui>
    );
  });
  TitleCase.displayName = 'TitleCase';
  TitleCase.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.array,
    ]),
  };
  return TitleCase;
};

export default TitleCaseUi;
