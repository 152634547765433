import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'common/components/Spinner/index';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';

const Spinner = ({ loadingMessage, backgroundColor, height, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      borderLeft: 'none',
      backgroundColor: backgroundColor ?? 'gray.50',
      minH: height ?? '614px',
      maxHeight: 'calc(100% - 259px)',
      height: '100%',
    }}
    data-testid={props['data-testid']}
  >
    <CircularProgress
      data-testid="loaderIcon"
      color="orange.500"
      emptyColor="gray.200"
      size="xl"
      thickness="4px"
      role="progressbar"
      aria-busy="true"
      aria-live="polite"
      aria-label="Loading"
    />

    {loadingMessage && (
      <Text as="h2" pt="1rem">
        {loadingMessage}
      </Text>
    )}
  </Box>
);

Spinner.propTypes = {
  loadingMessage: PropTypes.string,
  'data-testid': PropTypes.string,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
};

export default Spinner;
