import PropTypes from 'prop-types';
import { default as CommonButton } from 'common/components/Button';
import IconButton from 'common/components/Icons/IconButton';
import ChakraButton from 'common/components/Buttons/Button/index';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as icons from '@fortawesome/free-solid-svg-icons';

export const ButtonVariant = PropTypes.oneOf(['filled', 'outlined']);
export const ButtonType = PropTypes.oneOf([
  'iconButton',
  'linkButton',
  'button',
]);

export default function Button({ dataTracking, ...props }) {
  const {
    label,
    name,
    disabled,
    variant,
    onClick,
    startIcon,
    icon,
    endIcon,
    sx,
    type,
    role,
    ['aria-checked']: checked,
  } = useFieldApi(props);

  const start = (startIcon && icons[startIcon]) || (icon && icons[icon]);
  const end = endIcon && icons[endIcon];

  const buttonProps = {
    name,
    variant,
    isDisabled: disabled,
    onClick,
    sx,
    role,
  };

  switch (type) {
    case 'iconButton':
      return (
        <IconButton
          {...buttonProps}
          aria-label={label}
          icon={<FontAwesomeIcon icon={start || end} />}
          onClick={onClick}
          aria-checked={checked}
          data-tracking={dataTracking}
        />
      );
    case 'linkButton':
      return (
        <ChakraButton
          {...buttonProps}
          variant="link"
          color={disabled ? 'gray.500' : 'blue.600'}
          leftIcon={(start && <FontAwesomeIcon icon={start} />) || undefined}
          rightIcon={(end && <FontAwesomeIcon icon={end} />) || undefined}
          aria-checked={checked}
          data-tracking={dataTracking}
        >
          {label}
        </ChakraButton>
      );

    default:
      return (
        <CommonButton
          {...buttonProps}
          dataTracking={dataTracking}
          leftIcon={(start && <FontAwesomeIcon icon={start} />) || undefined}
          rightIcon={(end && <FontAwesomeIcon icon={end} />) || undefined}
        >
          {label}
        </CommonButton>
      );
  }
}

Button.propTypes = {
  sx: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined']),
  onClick: PropTypes.func,
  clickAction: PropTypes.shape({
    action: PropTypes.string.isRequired,
    actionName: PropTypes.string,
    actionArgs: PropTypes.array,
  }),
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  dataTracking: PropTypes.string,
};
