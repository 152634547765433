import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import apiClient from 'common/util/api-client';
import API_ROUTES from 'common/constants/api-endpoints';
import { CATEGORY_IDS } from 'pages/Home/constants';
import { INCOME_RULE_ID } from 'common/constants';

async function getCalculation(request) {
  const { data } = await apiClient.post(
    API_ROUTES.incomeCalculator.GET_ASSOC_INCOME_CALCS,
    request,
  );

  return data;
}

export function useGetCalculation({
  requestReferenceType,
  incomeIdentifier,
  rentalAddressId,
}) {
  const obfuscatedLoanIdentifier = useSelector(
    (state) => state.url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: [
      'getCalculation',
      obfuscatedLoanIdentifier?.obfuscatedLoanRecordId,
      requestReferenceType,
      incomeIdentifier?.customerRecordId,
      incomeIdentifier?.employerId,
      rentalAddressId,
    ],
    queryFn: () =>
      getCalculation({
        obfuscatedLoanIdentifier,
        requestReferenceType,
        incomeIdentifier,
        rentalAddressId,
        calculationId: 0,
      }),
    refetchOnWindowFocus: false,
  });
}

async function getNavigationDetails({ queryKey }) {
  const [, obfuscatedLoanIdentifier] = queryKey;
  const { data } = await apiClient.post(
    API_ROUTES.core.GET_NAVIGATION_DETAILS,
    obfuscatedLoanIdentifier,
  );

  return data;
}

function filterGutterItemsForReviewBreakdown(data) {
  const SHOW_REVIEW_BREAKDOWN_RULES = [
    INCOME_RULE_ID.INCOME_REVIEW_BREAKDOWN_RULE_ID,
    INCOME_RULE_ID.REVIEW_BREAKDOWN_YAML_RULE_ID,
    INCOME_RULE_ID.RENTAL_INCOME_CALCULATION_RULE_ID,
  ];
  const incomeNavItems = data.topNavigationItems.find(
    ({ navigationCategoryId }) => navigationCategoryId === CATEGORY_IDS.INCOME,
  )?.navigationGutterItems;

  if (!incomeNavItems) return [];

  return incomeNavItems
    .flatMap(({ displayTitle, parentRuleSortOrder, gutterItemChildren }) =>
      gutterItemChildren
        .filter(({ ruleId }) => SHOW_REVIEW_BREAKDOWN_RULES.includes(ruleId))
        .map((item) => ({
          ...item,
          parentTitle: displayTitle,
          parentRuleSortOrder,
        })),
    )
    .concat(
      incomeNavItems
        .filter(
          ({ additionalReferences }) =>
            additionalReferences.IsNoCalcEmployer === 'True',
        )
        .map((item) => ({ ...item, parentTitle: item.displayTitle })),
    )
    .sort((a, b) => a.parentRuleSortOrder - b.parentRuleSortOrder);
}

export function useGetNavigationDetails() {
  const obfuscatedLoanIdentifier = useSelector(
    (state) => state.url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getNavigationDetail', obfuscatedLoanIdentifier],
    queryFn: getNavigationDetails,
    refetchOnWindowFocus: false,
    select: filterGutterItemsForReviewBreakdown,
  });
}
