import apiClient from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';
import { auditHistoryDataReducer } from 'common/util/auditHistory';

import actions from './actions';

const getIsAuditHistoryFeatureFlagEnabled =
  () => async (dispatch, getState) => {
    dispatch(actions.getIsAuditHistoryFeatureEnabledStarted());
    try {
      const {
        url: { obfuscatedLoanIdentifier },
      } = getState();
      const { data } = await apiClient.post(
        apiEndpoints.auditHistory.IS_AUDIT_HISTORY_FEATURE_ENABLED,
        obfuscatedLoanIdentifier,
      );
      if (!data) throw new Error();

      dispatch(actions.getIsAuditHistoryFeatureEnabledSuccess(data));
    } catch (error) {
      dispatch(actions.getIsAuditHistoryFeatureEnabledError());
    }
  };

const getBoltAuditHistoryDataWithParams =
  ({ auditHistoryTypes, ruleReferenceIdentifiers, questionCategory = null }) =>
  async (dispatch, getState) => {
    dispatch(actions.getAuditHistoryStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();

    try {
      const {
        data: { response },
      } = await apiClient.post(
        apiEndpoints.auditHistory.GET_BOLT_AUDITY_HISTORY,
        {
          obfuscatedLoanIdentifier,
          auditHistoryTypes,
          ruleReferenceIdentifiers,
        },
      );
      const data = auditHistoryDataReducer(response, questionCategory);
      dispatch(actions.getAuditHistorySuccess(data));
    } catch {
      dispatch(actions.getAuditHistoryError());
    }
  };

export default {
  getIsAuditHistoryFeatureFlagEnabled,
  getBoltAuditHistoryDataWithParams,
};
