import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import DatePicker from 'common/components/DatePicker/index';
// Date Picker
export const FB_DatePicker = ({
  name,
  label,
  placeholder,
  disabled,
  getValues,
  onChangeHandler,
  onBlur,
  isInvalid,
  tabIndex,
  dataTestId,
  isRequired,
}) => (
  <FormControl isInvalid={isInvalid ?? false} isRequired={isRequired}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <DatePicker
      tabIndex={tabIndex}
      name={name}
      data-testid={dataTestId}
      style={disabled ? { opacity: 0.6, cursor: 'not-allowed' } : {}}
      disabled={disabled}
      placeholder={placeholder}
      value={getValues(name)}
      isInvalid={isInvalid}
      onBlur={onBlur ?? null}
      disableInvalidCheck={true}
      onChange={(date) => {
        onChangeHandler(date);
      }}
    />
  </FormControl>
);
FB_DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getValues: PropTypes.func,
  disabled: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
};
