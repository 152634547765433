import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Local
import { CLASSIFICATION } from '../constants';

/**
 * Creates new category object and adds the corresponding documents under that category
 * and returns the updated groupedDocuments
 *
 * @param {object} document from getClassifiedDocuments
 * @param {string} currentCategory from one of the documentCategories
 * @param {object} group action needed or completed with list of associated documents
 * @return {object} documents grouped by category
 */

export const getGroupedDocuments = (document, currentCategory, group) => {
  let groupedDocuments = {};
  if (!currentCategory) {
    groupedDocuments = {
      ...group,
      list: [
        ...group.list,
        {
          categoryName: document.categoryName,
          list: [document],
        },
      ],
    };
  } else {
    let updatedCurrentCategory = {
      ...currentCategory,
      list: [...currentCategory.list, document],
    };
    groupedDocuments = {
      ...group,
      list: [
        ...group.list.filter(
          (category) => category.categoryName !== document.categoryName,
        ),
        updatedCurrentCategory,
      ],
    };
  }
  return groupedDocuments;
};

/**
 * Iterates over documentItems(the document list returned by the getClassifyDocuments endpoint)
 * and builds parent, child, grandchild documentList structure for Classify LeftGutter
 */
const useGroupedDocuments = () => {
  // redux
  const {
    documentItems: { data: documentItems },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  const groupByCategory = useCallback((documentItems) => {
    let actionNeededItems = {
      groupCategoryName: CLASSIFICATION.actionNeeded,
      list: [],
      count: 0,
    };
    let completedItems = {
      groupCategoryName: CLASSIFICATION.completed,
      list: [],
      count: 0,
    };
    for (let document of documentItems) {
      if (document.groupCategoryName === CLASSIFICATION.actionNeeded) {
        let currentCategory = actionNeededItems.list.find(
          (item) => item.categoryName === document.categoryName,
        );
        actionNeededItems = {
          ...getGroupedDocuments(document, currentCategory, actionNeededItems),
          count: actionNeededItems.count + 1,
        };
      } else if (document.groupCategoryName === CLASSIFICATION.completed) {
        let currentCategory = completedItems.list.find(
          (item) => item.categoryName === document.categoryName,
        );
        completedItems = {
          ...getGroupedDocuments(document, currentCategory, completedItems),
          count: completedItems.count + 1,
        };
      }
    }
    return [actionNeededItems, completedItems];
  }, []);

  // Memoized groupedDocuments - only calls groupByCategory when documentItems changes
  const groupedDocuments = useMemo(
    () => groupByCategory(documentItems),
    [documentItems, groupByCategory],
  );

  return groupedDocuments;
};

export default useGroupedDocuments;
