/**
 * @param {boolean} value - boolean you want formatted
 * @param {string} opt1 - optional - Option for true values, defaults to 'Yes'
 * @param {string} opt2 - optional - Option for false values, defaults to 'No'
 * @return {string} returning rendered boolean
 */
export const renderBoolean = (value, opt1 = 'Yes', opt2 = 'No') =>
  value ? opt1 : opt2;

/**
 * @param {string} value - Date string
 * @param {string} isNullOpt - If the value is falsey we will return a default value defaults to '-'
 * @returns
 */
// FIXME FIX BACKEND TO ALLOW A NULL VALUE FOR ENDDATE BESIDES 1/1/0001
export const renderDate = (value, isNullOpt = '-') =>
  value != '1/1/0001' && value
    ? new Date(value).toLocaleDateString()
    : isNullOpt;

/**
 * @param {object} obj - Object that contains the values
 * @param {string[]} arrayOfAttributeNames - Field names you want concatenated
 * @returns {string}
 */
export const concatenateObjectAttributes = (obj, arrayOfAttributeNames) => {
  let result = '';
  arrayOfAttributeNames.forEach((att) =>
    att != '' || obj[att] ? (result += `${obj[att]} `) : '',
  );
  return result;
};

export const renderMultipleBorrowers = (array, arrayOfAttributeNames) => {
  return (
    <>
      {array.map((borrower, index) => (
        <p key={index}>
          {concatenateObjectAttributes(borrower, arrayOfAttributeNames)}
        </p>
      ))}
    </>
  );
};

/**
 * @param {string} val
 * @param {string} isNullOpt
 * @returns {any}
 */
export const renderNullValueAsDash = (val, isNullOpt) =>
  val ? val : isNullOpt;

/**
 * UniversalSort supports sorting values that are string | number | boolean | Date
 * @param {any[]} arr
 * @param {string} field - The field to sort the array by
 * @param {'desc' | 'asc'} direction - The two direction options descending or ascending
 * @returns {any[]} - Your sorted array
 */

export const universalSort = (arr, field, direction) => {
  // set the order of the sorted array
  // -1 = descending
  // 1 = ascending
  const order = direction === 'desc' ? -1 : 1;

  if (field.toLowerCase().includes('date')) {
    return sortByDate(arr, field, order);
  }
  return arr.sort((a, b) => {
    // check if the field is a string
    if (typeof a[field] === 'string') {
      return order * b[field].localeCompare(a[field]);
    }
    // check if the field is a number
    else if (typeof a[field] === 'number') {
      return order * (a[field] - b[field]);
    }
    // check if the field is a boolean
    else if (typeof a[field] === 'boolean') {
      return order * (a[field] === b[field] ? 0 : a[field] ? -1 : 1);
    }
    // check if the field is a date
    else if (a[field] instanceof Date) {
      return order * (a[field].getTime() - b[field].getTime());
    }
    return 0;
  });
};

/**
 * @param {any[]} arr
 * @param {string} field
 * @param {number} order
 * @returns {any[]}
 */
function sortByDate(arr, field, order) {
  let emptyDates = [];
  let hasDates = [];
  arr.forEach((a) => {
    if (
      a[field] === '' ||
      a[field] === null ||
      a[field] === undefined ||
      a[field] === '1/1/0001'
    ) {
      emptyDates.push(a);
    } else {
      hasDates.push(a);
    }
  });

  hasDates = hasDates.sort((a, b) => {
    const dateA = new Date(a[field]);
    const dateB = new Date(b[field]);

    if (dateA.toString() === 'Invalid Date') {
      return order === -1 ? 1 : -1;
    }

    return dateB - dateA;
  });
  let returnArray = [...emptyDates, ...hasDates];

  return order === -1 ? returnArray : returnArray.reverse();
}

/**
 * Handle the logic to set the column.
 * @name handleSettingColumnSort
 * @param {object} column
 * @param {string} column.fieldName - Field we are sorting by from the column object
 * @param {boolean} column.sortable - Boolean if that column is sortable
 * @param {Object} currentSort - The current sort being applied to the grid
 * @param {Object} defaultIcon - The default icon for the column
 * @param {Object} ascIcon - The icon for ascending order
 * @param {Object} descIcon - The icon for descending order
 * @returns {{field: string, direction: 'asc'|'desc', icon: *}} Returns a new sort object
 */
export const handleSettingColumnSort = (
  { fieldName, sortable },
  currentSort,
  defaultIcon,
  ascIcon,
  descIcon,
) => {
  let newSort = {};
  if (typeof sortable !== 'boolean' || sortable) {
    if (fieldName === currentSort.field) {
      switch (currentSort.direction) {
        case 'desc':
          newSort = {
            ...currentSort,
            direction: 'asc',
            icon: ascIcon,
          };
          break;
        default:
          newSort = { field: '', direction: '', icon: defaultIcon };
      }
    } else {
      newSort = {
        field: fieldName,
        direction: 'desc',
        icon: descIcon,
      };
    }
    return newSort;
  }
  return currentSort;
};
