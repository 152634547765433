import PropTypes from 'prop-types';
import { useFormApi } from 'lib-node-amb-renderer';
import { TITLE_ICON_TYPE } from '../QuestionBox/constants';
import { StyledContainerBox } from '../QuestionBox/Styled';
import ExtractionDataIcon from './components/ExtractionDataIcon';
import Footer from './components/Footer';
import { inputComponentNames } from './constants';
import { useState } from 'react';
import Stack from 'common/components/Stacks/Stack';
import { handleTitleConfidenceLevelColor } from './handlers';
import { useSelector } from 'react-redux';
import Button from 'common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

export default function QuestionExtraction({
  id = '',
  sx,
  buttonMargin,
  children,
  questionId,
  fields = [],
  iconType: stringifiedIconType,
  aiResponse = null,
  showBorderBottom = false,
  showBorderTop = true,
  showSnapShotButton = false,
  showGaugeIcon = true,
}) {
  const activeField = useSelector(
    ({ questionExtraction }) => questionExtraction?.activeField,
  );
  const { renderForm } = useFormApi();
  const [showFooter, setShowFooter] = useState(false);
  const detailedAnswer = aiResponse?.detailedAnswer ?? '';
  const answer = aiResponse?.answer ?? '';
  const confidenceLevel = parseInt(aiResponse?.confidenceLevel);
  const iconType = parseInt(stringifiedIconType);
  const snippets = aiResponse?.snippets ?? [];

  const questionField = fields.filter((field) => {
    if (field?.name && field?.component) {
      return (
        inputComponentNames.includes(field.component) &&
        field.name === questionId
      );
    }
  });

  const labelInputProps = questionField[0]?.labelInputProps;

  const isConfidenceVisible =
    !!detailedAnswer &&
    !!iconType &&
    !!confidenceLevel &&
    iconType === TITLE_ICON_TYPE.BOLT;

  const isBOLTAnswerVisible =
    !!iconType &&
    (iconType === TITLE_ICON_TYPE.NEW || iconType === TITLE_ICON_TYPE.USER);

  const isIconVisible = !!iconType && iconType !== 0;
  const isActive =
    activeField === questionId ||
    (!!labelInputProps && labelInputProps?.name === activeField);

  const hasSnippets = !!snippets.length;

  const handlePDFOnFocus = () => {
    window.postMessage({ type: 'pdfCitation', payload: snippets });
  };

  return (
    <StyledContainerBox
      id={id}
      {...sx}
      isActive={isActive}
      showBorderTop={showBorderTop}
      showBorderBottom={showBorderBottom}
    >
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {/* INFO: only displays inputs that match questionId */}
          {(questionField.length > 0 || children) && (
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              {children ?? renderForm(questionField)}
            </Stack>
          )}
          {isIconVisible && <ExtractionDataIcon iconType={iconType} />}
        </Stack>
        <Footer
          answer={answer}
          confidenceLevel={confidenceLevel}
          detailedAnswer={detailedAnswer}
          showFooter={showFooter}
          setShowFooter={setShowFooter}
          isConfidenceVisible={isConfidenceVisible}
          isBOLTAnswerVisible={isBOLTAnswerVisible}
          handleConfidenceLevelColor={handleTitleConfidenceLevelColor}
          showGaugeIcon={showGaugeIcon}
        />
        {showSnapShotButton && hasSnippets && (
          <Button
            margin={buttonMargin ?? '1rem 0.5rem 0.5rem 0'}
            width="2rem"
            height="2rem"
            isPrimary={false}
            onClick={() => handlePDFOnFocus()}
          >
            <FontAwesomeIcon icon={faCamera} />
          </Button>
        )}
      </Stack>
    </StyledContainerBox>
  );
}

QuestionExtraction.propTypes = {
  sx: PropTypes.object,
  buttonMargin: PropTypes.string,
  children: PropTypes.node,
  fields: PropTypes.array,
  id: PropTypes.string,
  iconType: PropTypes.string,
  questionId: PropTypes.string,
  aiResponse: PropTypes.shape({
    answer: PropTypes.string,
    detailedAnswer: PropTypes.string,
    confidence: PropTypes.number,
    confidenceLevel: PropTypes.number,
    snippets: PropTypes.array,
  }),
  showBorderBottom: PropTypes.bool,
  showBorderTop: PropTypes.bool,
  showSnapShotButton: PropTypes.bool,
  showGaugeIcon: PropTypes.bool,
};
