﻿import { DocumentTypes, IncomeDocumentId } from './enums';
import { VALIDATE_1099_TITLE, VALIDATE_1099SSA_TITLE } from './language.json';

export const WVOEToolTipContents = [
  "Borrower's Name and Address (Box 7)",
  "Employer's Name and Address (Box 1)",
  "Borrower's Present Position (Box 10)",
  'Probability of Continued Employment (Box 11)',
  'If Overtime or Bonus Continuance is Likely (Box 14)',
  'Details about Pay Increase Amounts and Dates (Boxes 16-19)',
  'Signature and Date from Mortgage Professional (Boxes 3-5)',
  'Signature and Date from Employee Representative (Boxes 26-30)',
  'Current as of date',
];

export const ButtonLabels = {
  next: 'Continue',
  calculate: 'Continue',
  save: 'Continue',
  print: 'Print',
};

export const INCOME_TYPES = {
  BASE_EMPLOYMENT_INCOME: { name: 'BaseEmploymentIncome', value: '1' },
  ACCESSORY_UNIT_INCOME: { name: 'AccessoryUnitIncome', value: '2' },
  ALIMONY: { name: 'Alimony', value: '3' },
  ASSETS_AS_INCOME: { name: 'AssetsAsIncome', value: '4' },
  AUTOMOBILE_OR_EXPENSE_ACCOUNT_INCOME: {
    name: 'AutomobileOrExpenseAccountIncome',
    value: '5',
  },
  BOARDER_INCOME: { name: 'BoarderIncome', value: '6' },
  BONUSES: { name: 'Bonuses', value: '7' },
  CAPITAL_GAINS: { name: 'CapitalGains', value: '8' },
  CHILD_SUPPORT: { name: 'ChildSupport', value: '9' },
  COMMISSIONS: { name: 'Commissions', value: '10' },
  DIVIDENDS_OR_INTEREST: { name: 'DividendsOrInterest', value: '11' },
  EMPLOYMENT_RELATED_ASSET: { name: 'EmploymentRelatedAsset', value: '12' },
  FARM_INCOME: { name: 'FarmIncome', value: '13' },
  FOREIGN_INCOME: { name: 'ForeignIncome', value: '14' },
  FOSTER_CARE: { name: 'FosterCare', value: '15' },
  IRA_DISTRIBUTIONS: { name: 'IraDistributions', value: '16' },
  MILITARY_BASE_PAY: { name: 'MilitaryBasePay', value: '17' },
  MILITARY_COMBAT_PAY: { name: 'MilitaryCombatPay', value: '18' },
  MILITARY_FLIGHT_PAY: { name: 'MilitaryFlightPay', value: '19' },
  MILITARY_HAZARD_PAY: { name: 'MilitaryHazardPay', value: '20' },
  MILITARY_OVERSEAS_PAY: { name: 'MilitaryOverseasPay', value: '21' },
  MILITARY_VARIABLE_HOUSING_ALLOWANCE: {
    name: 'MilitaryVariableHousingAllowance',
    value: '22',
  },
  MILITARY_CLOTHES_ALLOWANCE: { name: 'MilitaryClothesAllowance', value: '23' },
  MILITARY_PROP_PAY: { name: 'MilitaryPropPay', value: '24' },
  MILITARY_QUARTERS_ALLOWANCE: {
    name: 'MilitaryQuartersAllowance',
    value: '25',
  },
  MILITARY_RATIONS_ALLOWANCE: { name: 'MilitaryRationsAllowance', value: '26' },
  NET_RENTAL_INCOME: { name: 'NetRentalIncome', value: '27' },
  NOTES_RECEIVABLE_OR_INSTALLMENT: {
    name: 'NotesReceivableOrInstallment',
    value: '28',
  },
  OTHER_INCOME: { name: 'OtherIncome', value: '29' },
  OTHER_W2_INCOME: { name: 'OtherW2Income', value: '30' },
  OVERTIME: { name: 'Overtime', value: '31' },
  PENSION_OR_RETIREMENT_INCOME: {
    name: 'PensionOrRetirementIncome',
    value: '32',
  },
  REAL_ESTATE_OR_MORTGAGE_DIFFERENTIAL: {
    name: 'RealEstateOrMortgageDifferential',
    value: '33',
  },
  ROYALTY_PAYMENTS: { name: 'RoyaltyPayments', value: '34' },
  SEASONAL_INCOME: { name: 'SeasonalIncome', value: '35' },
  SOCIAL_SECURITY: {
    name: 'SocialSecurityOrDisabilityIncome',
    value: '36',
  },
  SUBJECT_PROPERTY_NET_CASH_FLOW: {
    name: 'SubjectPropertyNetCashFlow',
    value: '37',
  },
  TEMPORARY_LEAVE: { name: 'TemporaryLeave', value: '38' },
  TIP_INCOME: { name: 'TipIncome', value: '39' },
  TRUST_INCOME: { name: 'TrustIncome', value: '40' },
  UNEMPLOYMENT_OR_PUBLIC_ASSITANCE: {
    name: 'UnemploymentOrPublicAssistance',
    value: '41',
  },
  UNREIMBUSED_EXPENSES: { name: 'UnReimbursedExpenses', value: '42' },
  VA_BENEFITS: { name: 'UnReimburVaBenefitssedExpenses', value: '43' },
  SELF_EMPLOYMENT: { name: 'Self Employment', value: '' },
  EMPLOYMENT: { name: 'Employment', value: '' },
};

export const INCOME_TYPES_FOR_AUDIT_HISTORY_BUTTON = [
  INCOME_TYPES.SOCIAL_SECURITY.name,
];

export const WAGE_BONUS_TYPES = {
  AVERAGED: '0',
  ANNUALIZED: '1',
};

export const AUS_TYPES = {
  DU: 1,
  LPA: 2,
};

export const QUESTION_IDS = {
  IRA_ENDING_BALANCE_QUESTION: 1,
  IRA_ACCOUNT_TYPE_QUESTION: 2,
  IRA_ACCOUNT_STATEMENT_UPLOAD_QUESTION: 3,
  IRA_RETIREMENT_ACCOUNT_STATEMENT_QUESTION: 6,
  IRA_DATE_OF_STATEMENT: 7,
  IRA_UNRESTRICTED_WITHDRAW: 8,
  SCHEDULE_B_MULTIBORROWERS: 13,
  SCHEDULE_B: 15,
  SCHEDULE_B_MOST_RECENT_STATEMENT: 16,
  PAYSTUB_NEW_EMPLOYER_NAME: 39,
  FHA_SSI_RETIREMENT_OR_DISABILITY: 109,
  W2_NEW_EMPLOYER_NAME: 44,
  NON_ARMS_LENGTH_QUESTION: 17028,
};

export const PAYSTUB_IDS = {
  PAYSTUB_DATE: 2,
  ANY_DEDUCTIONS_NOT_LISTED: 3,
  KEEP_EASE_EMPLOYER_NAME: 18,
  GIVE_NEW_EMPLOYER_NAME: 19,
  KEEP_EASE_BORROWER_ADDRESS: 20,
};

export const W2_IDS = {
  KEEP_EASE_EMPLOYER_NAME: 21,
  GIVE_NEW_EMPLOYER_NAME: 22,
  KEEP_EASE_BORROWER_ADDRESS: 23,
};

export const CHILD_SUPPORT_CALCULATION_TYPES = {
  37: 'Divorce Decree',
  12: 'Child Support Papers',
};

export const CHILD_SUPPORT_CALCULATED_FIELD_IDS = {
  DIVORCE_DECREE_MONTHLY_AMOUNT: 490,
  CHILD_SUPPORT_PAPERS_MONTHLY_AMOUNT: 489,
};

export const CHILD_SUPPORT_QUESTION_IDS = {
  CHILD_SUPPORT_DOCUMENTATION: 22,
  CHILD_SUPPORT_INCOME_CONTINUANCE: 23,
  CHILD_SUPPORT_RECEIPT_HISTORY: 24,
};

export const CHILD_SUPPORT_INCOME_SUMMARY_QUESTION_IDS = {
  CHILD_SUPPORT_DOCUMENTATION: 22,
  CHILD_SUPPORT_RECEIPT_HISTORY: 24,
};

export const CHILD_SUPPORT_INCOME_SUMMARY_FHA_QUESTION_IDS = {
  CHILD_SUPPORT_DOCUMENTATION_FHA: 82,
  CHILD_SUPPORT_INCOME_CONTINUANCE_FHA: 83,
  CHILD_SUPPORT_RECEIPT_3_MTH_HISTORY_FHA: 84,
  CHILD_SUPPORT_RECEIPT_12_MTH_HISTORY_FHA: 85,
};

export const CHILD_SUPPORT_DOCUMENT_IDS = {
  DIVORCE_DECREE: 34,
  CHILD_SUPPORT_PAPERS: 35,
};

export const MILLISECONDS_IN_A_DAY = 86400000;
export const LPA_DOCUMENT_EXPIRATION_DAYS = 120;

export const FORMULA1065 = {
  OneYrNegBreakdownFormulaId: 36,
  TwoYrNegBreakdownFormulaId: 37,
  BusinessIncomeFormulaId: 38,
};

export const FORMULA1120 = {
  OneYrNegBreakDownFormulaId: 40,
  TwoYrNegBreakdownFormulaId: 41,
  OneYearPositiveOrNegativeTend: 42,
  TwoYearPositiveOrNegativeTrend: 43,
};

export const CHILD_SUPPORT_RULE_IDS = {
  INCOME_SUMMARY: 1,
  VALIDATE: 2,
  CALCULATE: 3,
  REVIEW_BREAKDOWN: 4,
  NO_DOCUMENATION_INCOME_SUMMARY: 11,
};

export const CHILD_SUPPORT_DOCUMENT_TYPE_IDS = {
  DIVORCE_DECREE: '17905',
  CHILD_SUPPORT_PAPERS: '18888',
};

export const CHILD_SUPPORT_INCOME_VALIDATION_DOC_TYPE = {
  DIVORCE_DECREE: 19,
  CHILD_SUPPORT_PAPERS: 20,
};

export const CHILD_SUPPORT_DOCUMENT_FIELD_IDS = {
  DIVORCE_DECREE: 99,
  CHILD_SUPPORT_PAPERS: 100,
};

export const CHILD_SUPPORT_FIELD_NAME = 'THREE_YEAR_CONTINUANCE';

export const CHILD_SUPPORT_DOCUMENT_NAMES = {
  DIVORCE_DECREE: 'Divorce Decree',
  CHILD_SUPPORT_PAPERS: 'Child Support Papers',
};

export const SSI_PENSION_QUESTIONS_ID = {
  INDICATE_CORRECT_TAX_YEAR: 1,
  VERIFY_RECENT_TAX_YEAR: 4,
  DATE_INCORPORATED: 6,
  SOCIAL_SECURITY_NUMBER_ID: 7,
  VALIDATE_DOCUMENT_COMPLETED: 8,
  DATE_OF_IRA_AWARD_LETTER: 11,
  DATE_OF_PENSION_LETTER: 49,
  DATED_PENSION_WITHIN_120_DAYS: 58,
  PENSION_RECEIPT_DATED_WITHIN_120_DAYS: 65,
  DATE_OF_SOCIAL_SECURITY_LETTER: 69,
  PENSION_BEHALF_ANOTHER_PERSON: 79,
  PENSION_SUPPLEMENTAL_INCOME: 80,
  DATED_SSI_WITHIN_120_DAYS: 81,
  DATED_WITHIN_4_MONTHS: 82,
  PENSION_3YEAR_CONTINUANCE: 83,
  PENSION_RECEIPT_DATED_WITHIN_120_DAYS_LPA: 50,
  PENSION_AWARD_LETTER_RECEIPT_DATED_WITHIN_4_MONTHS: 51,
  PENSIONS_BANK_STATEMENT_RECEIPT_DOCUMENT_DATE: 96,
  SSI_BEHALF_ANOTHER_PERSON: 90,
  SSI_SUPPLEMENTAL_INCOME: 91,
  SSI_RECEIPT_DATED_WITHIN_4_MONTHS: 92,
  SSI_3YEAR_CONTINUANCE: 94,
  PENSION_BANK_STATEMENT_END_DATE: 96,
  SSN_INPUT_VALIDATION: 9900,
  SSA_RULE_ID: 4015,
  PENSION_SSN_RULE_ID: 9901,
  SSI_1099_SSN_QUESTION_ID: 4036,
  SSI_1099_DATED_WITHIN_120_DAYS: 93,
  SSI_BANK_STATEMENT_BEHALF_ANOTHER: 95,
  SSI_BANK_STATEMENT_THREE_YEAR_CONTINUANCE: 97,
  SSI_AWARD_LETTER_THREE_YEAR_CONTINUANCE: 72,
  SSI_1099_CONTINUANCE: 105,
  FHA_DOCUMENT_IS_SIGNED: 110,
  SSI_1040_JOINT_TAX_RETURNS: 115,
};

export const INCOME_RESOLUTION_CONDITION_CONTROLLING_QUESTIONS = {
  2: 'No',
  1: 'No',
  3: 'No',
};

export const VARIABLE_INCOME = Object.freeze([
  { name: 'VIT', ariaLabel: 'Variable Income Type' },
  { name: 'YtdAmount', ariaLabel: 'Year-to-Date Amount from Paystub' },
]);

export const VARIABLE_INCOME_TYPES = Object.freeze([
  { name: 'overTimeYTD', ariaLabel: 'Overtime' },
  { name: 'bonusYTD', ariaLabel: 'Bonus' },
  { name: 'commissionYTD', ariaLabel: 'Commission' },
  { name: 'tipYTD', ariaLabel: 'Tip' },
  { name: 'otherYTD', ariaLabel: 'Other' },
]);

export const PAY_TYPE = {
  IS_INCOME_VARIABLE_HOURLY: '11',
};

export const ADDITIONAL_VARIABLE = {
  IS_ADDITIONAL_VARIABLE_INCOME: '1',
};

export const FORM_TYPE_1099 = {
  VALIDATE_1099_R: '1099R',
  VALIDATE_1099_SSA: '1099SSA',
};

export const VALIDATE_1099_FORM_DETAILS = {
  [FORM_TYPE_1099.VALIDATE_1099_R]: {
    documentType: DocumentTypes.Form1099,
    incomeDocumentId: IncomeDocumentId.Form1099,
    taxYearFieldName: 'YEAR_OF_FORM',
    ssnFieldName: 'RECIPIENTS_TIN',
    ssiPensionQuestionRuleId: SSI_PENSION_QUESTIONS_ID.PENSION_SSN_RULE_ID,
    ssiPensionQuestionIds: [
      SSI_PENSION_QUESTIONS_ID.PENSION_RECEIPT_DATED_WITHIN_120_DAYS,
    ],
    pageTitle: VALIDATE_1099_TITLE,
  },
  [FORM_TYPE_1099.VALIDATE_1099_SSA]: {
    documentType: DocumentTypes.SSI1099,
    incomeDocumentId: IncomeDocumentId.FormSSA1099,
    taxYearFieldName: 'YEAR',
    ssnFieldName: 'SOCIAL_SECURITY_NUMBER',
    ssiPensionQuestionRuleId: SSI_PENSION_QUESTIONS_ID.SSA_RULE_ID,
    ssiPensionQuestionIds: [
      SSI_PENSION_QUESTIONS_ID.SSI_BEHALF_ANOTHER_PERSON,
      SSI_PENSION_QUESTIONS_ID.SSI_SUPPLEMENTAL_INCOME,
      SSI_PENSION_QUESTIONS_ID.SSI_3YEAR_CONTINUANCE,
      SSI_PENSION_QUESTIONS_ID.SSI_RECEIPT_DATED_WITHIN_4_MONTHS,
      SSI_PENSION_QUESTIONS_ID.SSI_1099_CONTINUANCE,
      SSI_PENSION_QUESTIONS_ID.SSI_1099_DATED_WITHIN_120_DAYS,
    ],
    pageTitle: VALIDATE_1099SSA_TITLE,
  },
};
