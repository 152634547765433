import { AccordionPanel as ChakraAccordionPanel } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AccordionPanel = ({ children, ...props }) => {
  return <ChakraAccordionPanel {...props}>{children}</ChakraAccordionPanel>;
};

export default AccordionPanel;

AccordionPanel.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
