const FIRST_TIME_HOME_BUYER_TYPES = {
  GET_FIRST_TIME_HOME_BUYER_INFO_STARTED:
    'GET_FIRST_TIME_HOME_BUYER_INFO_STARTED',
  GET_FIRST_TIME_HOME_BUYER_INFO_SUCCESS:
    'GET_FIRST_TIME_HOME_BUYER_INFO_SUCCESS',
  GET_FIRST_TIME_HOME_BUYER_INFO_ERROR: 'GET_FIRST_TIME_HOME_BUYER_INFO_ERROR',
  UPDATE_FIRST_TIME_HOME_BUYER_INFO_STARTED:
    'UPDATE_FIRST_TIME_HOME_BUYER_INFO_STARTED',
  UPDATE_FIRST_TIME_HOME_BUYER_INFO_SUCCESS:
    'UPDATE_FIRST_TIME_HOME_BUYER_INFO_SUCCESS',
  UPDATE_FIRST_TIME_HOME_BUYER_INFO_ERROR:
    'UPDATE_FIRST_TIME_HOME_BUYER_INFO_ERROR',
};

export default FIRST_TIME_HOME_BUYER_TYPES;
