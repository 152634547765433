import PropTypes from 'prop-types';
import theme from 'theme/';

const BoundingBox = ({ x1, x2, x3, x4, y1, y2, y3, y4, showHighLight }) => {
  const arrayOfX = [x1, x2, x3, x4];
  const arrayOfY = [y1, y2, y3, y4];

  const highestX = Math.max(...arrayOfX);
  const lowestX = Math.min(...arrayOfX);
  const highestY = Math.max(...arrayOfY);
  const lowestY = Math.min(...arrayOfY);
  const { colors } = theme;

  return (
    <div
      style={{
        position: 'absolute',
        top: `${lowestY * 100 - 1}%`,
        left: `${lowestX * 100 - 1}%`,
        width: `${(highestX - lowestX) * 100 + 2}%`,
        height: `${(highestY - lowestY) * 100 + 2}%`,
        border: showHighLight ? 'none' : `3px solid ${colors.red[500]}`,
        backgroundColor: showHighLight ? `${colors.red[500]}` : null,
        borderRadius: showHighLight ? 'none' : '0.3rem',
        boxShadow: showHighLight
          ? 'none'
          : `.1rem .1rem .3rem ${colors.gray[500]}`,
      }}
    ></div>
  );
};
BoundingBox.propTypes = {
  x1: PropTypes.any,
  x2: PropTypes.any,
  x3: PropTypes.any,
  x4: PropTypes.any,
  y1: PropTypes.any,
  y2: PropTypes.any,
  y3: PropTypes.any,
  y4: PropTypes.any,
  showHighLight: PropTypes.bool,
};

function HighlightArea(props) {
  const { pageNumber, citations, showHighLight } = props;

  return (
    <div>
      {(citations != undefined) & (citations != null) ? (
        citations
          .filter((citation) => citation.pageNumber === pageNumber)
          .map((citation, i) => (
            <BoundingBox key={i} showHighLight={showHighLight} {...citation} />
          ))
      ) : (
        <div />
      )}
    </div>
  );
}

export default HighlightArea;
HighlightArea.propTypes = {
  highlights: PropTypes.any,
  pageNumber: PropTypes.any,
  citations: PropTypes.any,
  showHighLight: PropTypes.bool,
};
