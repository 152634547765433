import { formatCurrency, formatNumber } from '../../../common/util/format';
import { RULE_REFERENCE_TYPE_ID } from '../../../common/constants';
import { groupBy } from 'lodash';
export default class CreditData {
  columnHeaders = {
    [RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID]: [
      { firstName: 'First Name' },
      { lastName: 'Last Name' },
    ],
    [RULE_REFERENCE_TYPE_ID.INQUIRY_ID]: [
      { inquirerName: 'Creditor' },
      { inquiryDate: 'Date of Credit Inquiry' },
    ],
    [RULE_REFERENCE_TYPE_ID.TRADELINE_ID]: [
      { monthlyPayment: 'Payment', format: formatCurrency },
      { currentBalance: 'Balance', format: formatCurrency },
      { dateLastActive: 'Date Last Active' },
      { numberOfLates: 'Late Payments', format: formatNumber },
      { accordionButton: '' },
    ],
    DerogatoryTradeline: [
      { creditorName: 'Creditor Name' },
      { accountNumber: 'Account Number' },
      { currentBalance: 'Balance', format: formatCurrency },
      { dateAccountOpened: 'Date Opened' },
      { accountStatus: 'Status' },
    ],
    Bankruptcy: [
      { datePaidOff: 'Status Date' },
      { bankruptcyType: 'Bankruptcy Type' },
      { dispositionCode: 'Status' },
    ],
    TaxLien: [
      { datePaidOff: 'Status Date' },
      { dispositionCode: 'Status' },
      { legalAmount: 'Amount', format: formatCurrency },
    ],
    Judgement: [{ datePaidOff: 'Status Date' }, { dispositionCode: 'Status' }],
    [RULE_REFERENCE_TYPE_ID.DEBT_ID]: [
      { liabilityType: 'Type:' },
      { excludePayment: 'Exclude:' },
      { payment: 'Payment:', format: formatCurrency },
      { balance: 'Balance:', format: formatCurrency },
      { dateAccountOpened: 'Date Opened:' },
      { accordionButton: '' },
    ],
    LiabilityMissingFromSystem: [
      { accountType: ' Type:' },
      { excludePayment: 'Exclude:' },
      { monthlyPayment: 'Payment:', format: formatCurrency },
      { currentBalance: 'Balance:', format: formatCurrency },
      { dateAccountOpened: 'Date Opened:' },
      { accordionButton: '' },
    ],
  };
  constructor(cr = undefined) {
    this.dataLoaded = cr !== undefined;
    this.borrowers = cr && cr.borrowers ? cr.borrowers : [];
    this.inquiries = cr && cr.inquiries ? cr.inquiries : [];
    this.liabilities = cr && cr.liabilities ? cr.liabilities : [];
    this.tradelines = cr && cr.tradelines ? cr.tradelines : [];
    this.publicRecords = cr && cr.publicRecords ? cr.publicRecords : [];
    this.creditInquiryLetterId =
      cr && cr.creditInquiryLetterId ? cr.creditInquiryLetterId : 0;
    this.creditBorrowers = cr && cr.creditBorrowers ? cr.creditBorrowers : [];
  }

  isDataLoaded() {
    return this.dataLoaded;
  }

  __filterResults(data, field, val) {
    if (typeof val === 'number') {
      return data.filter((elem) => elem[field] === val).pop();
    } else if (Array.isArray(val)) {
      const datum = data.reduce((d, v) => ({ ...d, [v[field]]: v }), {});
      return val.reduce((d, v) => [...d, datum[v]], []);
    } else {
      return data;
    }
  }

  getBorrowers(customerRecordId) {
    return this.__filterResults(
      this.borrowers,
      'customerRecordId',
      customerRecordId,
    );
  }

  getInquiries(id) {
    return this.__filterResults(this.inquiries, 'inquiryId', id);
  }

  getLiabilities(id) {
    return this.__filterResults(this.liabilities, 'debtId', id);
  }

  getTradelines(id) {
    return this.__filterResults(this.tradelines, 'tradelineId', id);
  }

  getPublicRecords(id) {
    return this.__filterResults(this.publicRecords, 'publicRecordId', id);
  }

  getCreditReportAlerts(id) {
    return this.__filterResults(
      this.borrowers?.reduce(
        (acc, borrower) =>
          acc.concat(
            borrower.creditBureauDetails?.reduce(
              (acc2, details) => acc2.concat(details.alerts),
              [],
            ),
          ),
        [],
      ),
      'alertId',
      id,
    );
  }

  getCreditReportAlertsByUniqueIdentifiers(id, items = []) {
    if (
      !items[RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID] ||
      !items[RULE_REFERENCE_TYPE_ID.CREDIT_REPORT_ALERT_SEQUENCE_NUMBER]
    ) {
      return [];
    }
    let customerRecordId =
      items[RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID][0]?.ruleReferenceValue;
    let allAlertsForBorrower = this.getBorrowers(
      customerRecordId,
    ).creditBureauDetails?.reduce(
      (acc2, details) => acc2.concat(details.alerts),
      [],
    );

    return allAlertsForBorrower.filter(
      (alert) =>
        alert.creditReportAlertId === id &&
        alert.sequenceNumber ===
          items[RULE_REFERENCE_TYPE_ID.CREDIT_REPORT_ALERT_SEQUENCE_NUMBER][0]
            ?.ruleReferenceValue,
    );
  }

  getCreditInquiryLetterId() {
    return this.creditInquiryLetterId;
  }

  getData({ ruleReferenceIdentifiers, borrowerIdentifier, details }) {
    const filters = (ruleReferenceIdentifiers || [])
      .concat(details?.map((detail) => detail.ruleReferenceIdentifier) || [])
      .concat(
        borrowerIdentifier?.customerRecordId
          ? [
              {
                ruleReferenceType: RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID,
                ruleReferenceValue: borrowerIdentifier?.customerRecordId,
              },
            ]
          : [],
      );
    let items = groupBy(filters, 'ruleReferenceType');
    const filterToType = {
      [RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID]: this.getBorrowers.bind(this),
      [RULE_REFERENCE_TYPE_ID.INQUIRY_ID]: this.getInquiries.bind(this),
      [RULE_REFERENCE_TYPE_ID.DEBT_ID]: this.getLiabilities.bind(this),
      [RULE_REFERENCE_TYPE_ID.TRADELINE_ID]: this.getTradelines.bind(this),
      [RULE_REFERENCE_TYPE_ID.PUBLIC_RECORD_ID]:
        this.getPublicRecords.bind(this),
      [RULE_REFERENCE_TYPE_ID.CREDIT_ALERT_ID]:
        this.getCreditReportAlerts.bind(this),
      [RULE_REFERENCE_TYPE_ID.CREDIT_REPORT_ALERT_ID]:
        this.getCreditReportAlertsByUniqueIdentifiers.bind(this),
    };

    const keys = Object.keys(items);
    return keys.reduce((datum, key) => {
      const value = items[key].map((item) => item.ruleReferenceValue).join();
      return key in filterToType
        ? {
            ...datum,
            [key]: filterToType[key](value.split(','), items),
          }
        : { ...datum };
    }, {});
  }

  getColumnHeaders(section) {
    if (section in this.columnHeaders) {
      const columns = this.columnHeaders[section];
      return columns.reduce((acc, col) => ({ ...acc, ...col }), {});
    }
    return {};
  }

  getColumnOrder(section) {
    if (section in this.columnHeaders) {
      const columns = this.columnHeaders[section];
      return columns.map(
        (col) => Object.keys(col).filter((c) => c !== 'format')[0],
      );
    }
    return [];
  }

  getColumnFormatters(section) {
    if (section in this.columnHeaders) {
      const columns = this.columnHeaders[section];
      return columns.map((col) => col.format || ((v) => v));
    }
    return [];
  }
}
