import Center from 'common/components/Center';
import Text from 'common/componentMapper/Text';
import Flex from 'common/components/Flex';
import { UnnamedWidget } from 'lib-node-amb-renderer';
import PropTypes from 'prop-types';

export class YAMLOopsClass extends UnnamedWidget {
  header;
  message;
  constructor(props) {
    super(props);
    this.header = props.header;
    this.message = props.message;
  }
}

const YAMLOops = ({
  header = ' An unexpected error has occurred.',
  message = 'Try again, or submit a CR for additional assistance if the error persists.',
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      data-testid="YAMLOopsError"
      sx={{
        padding: '1rem',
        minHeight: 'calc(100vh - 500px)',
      }}
    >
      <Center>
        <Text
          as="h2"
          sx={{
            fontSize: '6xl',
            fontWeight: 'bold',
            color: 'blue.700',
            mb: '1.5rem',
          }}
        >
          OOPS!
        </Text>
      </Center>
      <Center>
        <Text as="h3" sx={{ fontSize: '1xl' }}>
          {header}
        </Text>
      </Center>
      <Center>
        <Text as="h3" sx={{ fontSize: '1xl' }}>
          {message}
        </Text>
      </Center>
    </Flex>
  );
};

YAMLOops.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
};

export default YAMLOops;
