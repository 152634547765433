export default class ExtractedSnippetDataModel {
  constructor(snip = {}) {
    this.snippetId = Object.hasOwnProperty.call(snip, 'snippetId')
      ? snip.snippetId
      : null;

    this.fiscalYear = Object.hasOwnProperty.call(snip, 'fiscalYear')
      ? snip.fiscalYear
      : 0;

    this.fieldName = Object.hasOwnProperty.call(snip, 'fieldName')
      ? snip.fieldName
      : null;

    this.documentType = Object.hasOwnProperty.call(snip, 'documentType')
      ? snip.documentType
      : null;
  }

  getSnippetId() {
    return this.snippetId;
  }

  getFiscalYear() {
    return this.fiscalYear;
  }

  getFieldName() {
    return this.fieldName;
  }

  getDocumentType() {
    return this.documentType;
  }
}
