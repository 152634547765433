export default class BorrowerNameDataModel {
  constructor(i = {}) {
    this.firstName = Object.prototype.hasOwnProperty.call(i, 'firstName')
      ? i.firstName
      : '';

    this.middleName = Object.prototype.hasOwnProperty.call(i, 'middleName')
      ? i.middleName
      : '';

    this.lastName = Object.prototype.hasOwnProperty.call(i, 'lastName')
      ? i.lastName
      : '';

    this.suffixName = Object.prototype.hasOwnProperty.call(i, 'suffixName')
      ? i.suffixName
      : '';
  }

  /**
   * @returns {string} The first name.
   */
  getFirstName() {
    return this.firstName;
  }

  /**
   * @returns {string} The last name.
   */
  getLastName() {
    return this.lastName;
  }

  /**
   * @return {string} The middle Name
   */
  getMiddleName() {
    return this.middleName;
  }

  /**
   *
   * @returns {string} The SuffixName
   */
  getSuffixName() {
    return this.suffixName;
  }

  /**
   * @return {string} The first and last Name
   */
  getFullName() {
    return this.firstName + ' ' + this.lastName;
  }
}
