﻿import React from 'react';
import DrawerHeader from 'common/components/Drawers/DrawerHeader';
import DrawerCloseButton from 'common/components/Drawers/DrawerCloseButton';
import Text from 'common/components/Texts/Text';
import lang from 'pages/IncomeCalculator/language.json';

const HistoryDrawerHeader = () => {
  return (
    <>
      <DrawerCloseButton />˝
      <DrawerHeader bgColor="gray.50" mb={4}>
        <Text>{lang.CALC_HISTORY}</Text>
      </DrawerHeader>
    </>
  );
};

export default HistoryDrawerHeader;
