import CrudGrid from 'common/components/CrudGrid';
import { useState, useEffect, useMemo } from 'react';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import {
  renderBoolean,
  concatenateObjectAttributes,
} from 'common/components/CrudGrid/crudGridUtils';
import { formatCurrency } from 'common/util/format';
import { USER_ROLE } from 'common/constants';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import RealEstateOwnedSummariesForm from './Form/RealEstateOwnedSummariesForm';
import { OPERATORS } from 'common/components/CrudGrid/Filters/filterGridData';
import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';
import ScrollIntoViewContainer from '../common/components/ScrollIntoViewContainer';
import { isReadOnlyUrl } from 'common/util/appState';

export class NewREO {
  addressId = 0;
  addressLdId = 0;
  addressLine1 = '';
  city = '';
  state = '';
  unit = '';
  country = 'US';
  zipCode = '';
  propertyTypeId = '';
  occupancyType = '';
  propertyStatus = '';
  marketValue = 0;
  mortgagePayment = 0;
  netRentalIncome = 0;
  isSubjectProperty = false;
  mortgageBalance = 0;
  monthlyPayment = 0;
  escrowedOnProperty = 0;
  monthlyPropertyTaxes = 0;
  monthlyHazardInsurance = 0;
  monthlyFloodInsurance = 0;
  monthlyAssociationDues = 0;
  grossRentalIncome = 0;
  leaseGroundRents = 0;
  totalMonthlyExpenses = 0;
  associatedLiabilities = [];
  associatedBorrowers = [];
  constructor(id) {
    this.addressId = id;
  }
}
export default function RealEstateOwned1003Layout() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [allBorrowers, setAllBorrowers] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);
  const featureFlags = useSelector(({ featureFlags }) => featureFlags);
  const isREO1003AndPropertyEnabled = featureFlags.IsREO1003AndPropertyEnabled;
  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !hasWritePermission) ||
      isReadOnlyUrl()
    );
  }, [isUnderwriter, boltStatus, hasWritePermission, role]);

  const columns = [
    {
      fieldName: 'wholeAddressString',
      headerName: 'Property Address',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'propertyStatus',
      headerName: 'Property Status',
      isNumeric: false,
      display: true,
      useOptions: true,
      renderCell: (val, options) =>
        options.availablePropertyStatusTypes.find((value) => value.key === val)
          ?.value,
    },
    {
      fieldName: 'propertyTypeId',
      headerName: 'Property Type',
      isNumeric: false,
      display: true,
      useOptions: true,
      renderCell: (val, options) =>
        options.availablePropertyTypes.find((value) => value.key === val)
          ?.value,
    },
    {
      fieldName: 'isSubjectProperty',
      headerName: 'Subject Property',
      isNumeric: false,
      display: true,
      renderCell: (val) => renderBoolean(val),
    },
    {
      fieldName: 'marketValue',
      headerName: 'Market Value',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
    {
      fieldName: 'mortgagePayment',
      headerName: 'Mortgage Payment',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },

    {
      fieldName: 'netRentalIncome',
      headerName: 'Net Rent',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
  ];

  const getAllData = () => {
    let isMounted = true;
    setIsLoading(true);
    apiClient
      .post(
        apiEndPoints.underwritingSummariesRealEstateOwned
          .GET_REALESTATEOWNED_SUMMARY_DATA,
        obfuscatedLoanIdentifier,
      )
      .then((res) => {
        if (!isMounted) return;
        const mappedData = Array.isArray(res.data.realEstateOwned)
          ? res.data.realEstateOwned.map((reo) => {
              return {
                ...reo,
                ...reo.propertyAddress,
                ...reo.propertyType,
                ...reo.associatedLiabilities,
              };
            })
          : [];
        setAllBorrowers(res.data.availableBorrowers);
        setHasWritePermission(res.data.hasWritePermission ?? false);
        setDropdownOptions(res.data.dropDownOptions);
        setData(mappedData);
      })
      .catch(() => {
        let toastMessage = {
          title: 'Error',
          description: 'Could not get data',
          status: 'error',
          duration: 5000,
          isClosable: false,
        };
        toast(toastMessage);
      })
      .finally(() => {
        if (!isMounted) return;
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAllData, []);

  const handleEditMode = (row, isEditBool) => {
    setIsEdit(isEditBool);
    setRowToEdit(row);
    setShowForm(true);
  };

  const handleClose = () => {
    setRowToEdit({});
    setShowForm(false);
  };

  const handleSave = () => {
    getAllData();
    setShowForm(false);
    setRowToEdit({});
  };

  const handleDelete = async (row) => {
    const {
      addressId: { addressId, addressLdId },
    } = row[0];
    let toastMessage = {};
    try {
      const res = await apiClient.post(
        apiEndPoints.underwritingSummariesRealEstateOwned
          .DELETE_REALESTATEOWNED_SUMMARY_DATA,
        {
          obfuscatedLoanIdentifier,
          addressIdentifier: {
            addressId,
            addressLdId,
          },
        },
      );

      if (res) {
        toastMessage = {
          title: 'Success',
          description: 'REO Deleted',
          status: 'success',
          duration: 5000,
          isClosable: true,
        };
        getAllData();
        setRowToEdit({});
        setShowForm(false);
        toast(toastMessage);
        dispatch(setLoanSummaryUpsertOrDelete(true));
      }
    } catch (error) {
      toastMessage = {
        title: 'Delete Failed',
        description: 'Record was not able to be deleted.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      };
      toast(toastMessage);
    }
  };

  const gridDef = {
    gridType: 'REO(s)',
    uniqueIdAttributeFields: ['addressId.addressId'],
    manageColumns: false,
    allowFilters: true,
    gridLanguage: {
      helperText: '',
      singularItem: 'REO',
      pluralItem: 'REOs',
    },
    getDialogDeleteMessage: (row) =>
      `Are you sure you want to remove this REO: ${
        row[0].propertyAddress?.wholeAddressString
      }${
        row[0].propertyAddress?.addressType
          ? ` - ${row[0].propertyAddress.addressType}`
          : ''
      }?`,
    getAriaLabel: (row) => row[0].propertyAddress?.wholeAddressString,
  };

  const buttonDefs = [
    {
      label: 'Add REO',
      action: () => {
        handleEditMode(new NewREO(0), false);
      },
      visible: !isWriteAccessDisabled,
      isDisable: !isREO1003AndPropertyEnabled,
      testId: 'add-reo-button',
    },
  ];

  const filters = [
    {
      options: allBorrowers.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      label: 'Borrower(s)',
      inputType: 'multiSelect',
      fieldName: 'associatedBorrowers',
      operator: OPERATORS.INCLUDE,
      evaluateFilter: (row, value) =>
        row.associatedBorrowers.some((borrower) =>
          value.includes(borrower.customerRecordId),
        ),
    },
  ];
  return (
    <StyledLoan1003LayoutContainer>
      <CrudGrid
        gridDef={gridDef}
        filters={filters}
        columns={columns}
        data={data}
        dropdownOptions={dropdownOptions}
        onRowEditClick={(row) => {
          handleEditMode(row, true);
        }}
        readOnly={isWriteAccessDisabled}
        selectedRow={rowToEdit}
        isLoading={isLoading}
        disableRowClick={!isREO1003AndPropertyEnabled || isWriteAccessDisabled}
        onDelete={(row) => handleDelete(row)}
        buttonDefs={buttonDefs}
      />
      {!isLoading && (
        <div
          style={{
            margin: '1rem 0rem',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
            width: '100%',
          }}
        ></div>
      )}

      {showForm && (
        <ScrollIntoViewContainer
          valueToWatch={rowToEdit}
          style={{ width: '100%' }}
        >
          <RealEstateOwnedSummariesForm
            rowToEdit={rowToEdit}
            isEdit={isEdit}
            onClose={handleClose}
            onSave={handleSave}
            isWriteAccessDisabled={isWriteAccessDisabled}
            handleToastMessage={(toastMessage) => toast(toastMessage)}
            allBorrowerOptions={allBorrowers}
            dropdownOptions={dropdownOptions}
            obfuscatedLoanIdentifier={obfuscatedLoanIdentifier}
          />
        </ScrollIntoViewContainer>
      )}
    </StyledLoan1003LayoutContainer>
  );
}
