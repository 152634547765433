import PropTypes from 'prop-types';
import Tooltip from 'common/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * Generic UWM styled tooltip
 *
 * @param {element} [children] Optional element to wrap the tooltip trigger acound
 * @param {string} [color='#3182CE'] Icon color (not applicable when children used)
 * @param {object} [icon=faInfoCircle] Font Awesome compatible icon (not applicable when children used)
 * @param {string} label The tooltip to display for the triggering children or icon
 * @param {string} [placement='top'] tooltip location
 *
 * @example basic tooltip rendering on a blue info icon
 *   `<GenericTooltip label="my tooltip" />`
 * @example customized tooltip on red key icon
 *   `<GenericTooltip color="#f00" icon="faKey" label="This is a key" placement="bottom"/>`
 * @example non-icon tooltip trigger
 *   `<GenericTooltip label="What is a keyboard">Computer Input Device</GenericTooltip>
 */
const GenericTooltip = ({ children, color, icon, label, placement }) => (
  <Tooltip
    hasArrow
    placement={placement}
    label={label}
    textAlign="center"
    color="black"
    bg="gray.200"
    borderRadius="2px"
    fontSize="12px"
    fontWeight="normal"
    p={2}
  >
    {children ? (
      children
    ) : (
      <span
        style={{ marginLeft: '4px' }}
        role="tooltip"
        aria-label={label ?? 'Information'}
      >
        <FontAwesomeIcon color={color} icon={icon} />
      </span>
    )}
  </Tooltip>
);

GenericTooltip.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
    'top',
    'top-end',
    'top-start',
  ]),
};
GenericTooltip.defaultProps = {
  children: undefined,
  color: '#3182CE',
  icon: faInfoCircle,
  placement: 'top',
};

export default GenericTooltip;
