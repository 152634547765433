import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { formatCurrency } from '../../../../../common/util/format';
import { PaySchedule, INCOME_TYPES, TRENDS } from '../../../enums';
import DataDisplayBlock from './DataDisplayBlock';
const PStyled = styled.p`
  font-size: 12px;
  font-weight: 400;
  display: inline;
`;

const VoeBreakdown = ({
  result,
  payType,
  ytdMonthlyAverageEquation,
  currentGrossBasePay,
}) => {
  const [payFreq] = Object.keys(PaySchedule).filter(
    (freq) => PaySchedule[freq] === payType,
  );

  const nonVariableIncome =
    payType !== PaySchedule['Variable Hourly'] && result;

  const DisplayBlocks = [
    {
      containerProps: { mb: '20px' },
      header: 'Monthly Income Calculation:',
      dlProps: { fontSize: 'sm' },
      data: [
        { label: 'Pay Frequency', value: payFreq },
        { label: 'Rate', value: formatCurrency(currentGrossBasePay) },
      ],
    },
  ];
  if (nonVariableIncome) {
    DisplayBlocks.push({
      containerProps: {
        mt: '20px',
        ml: '-24px',
        pb: '20px',
        pt: '20px',
        pl: '24px',
        bgColor: 'bgGray.200',
      },
      header: (
        <>
          Base Income Calculation <PStyled>(Applied to monthly income)</PStyled>
        </>
      ),
      dlProps: { fontSize: 'sm' },
      data: [
        { label: 'Calculation', value: result.calculationEquation },
        {
          label: 'Calculated Base Income',
          value: formatCurrency(result.calculatedMonthlyIncome),
        },
      ],
    });
    DisplayBlocks.push({
      containerProps: {
        mb: '20px',
        pt: '20px',
      },
      header: 'Year-to-date Income Calculation',
      dlProps: { fontSize: 'sm' },
      data: [
        { label: 'Calculation', value: ytdMonthlyAverageEquation },
        {
          label: 'Calculated Monthly Income',
          value: formatCurrency(result.incomeDetails[0].monthlyIncome),
        },
      ],
    });
  }
  return (
    <>
      {DisplayBlocks.map((block) => (
        <DataDisplayBlock key={block.header} {...block} />
      ))}
    </>
  );
};

VoeBreakdown.propTypes = {
  result: PropTypes.shape({
    calculatedMonthlyIncome: PropTypes.number,
    calculatedTrendIncome: PropTypes.number,
    incomeType: PropTypes.oneOf(INCOME_TYPES),
    trend: PropTypes.oneOf(TRENDS),
    calculationEquation: PropTypes.string,
    incomeDetails: PropTypes.arrayOf(
      PropTypes.shape({
        incomeYear: PropTypes.oneOf(['Ytd', 'PrevYear', 'TwoYearsPrev']),
        monthlyIncome: PropTypes.number,
        totalIncome: PropTypes.number,
        fiscalYear: PropTypes.number,
      }),
    ),
  }).isRequired,
  payType: PropTypes.oneOf(Object.values(PaySchedule)).isRequired,
  ytdMonthlyAverageEquation: PropTypes.string.isRequired,
  currentGrossBasePay: PropTypes.number.isRequired,
};

export default VoeBreakdown;
