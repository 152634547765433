import CheckboxGroup from 'common/components/Checkboxes/CheckboxGroup';
import Flex from 'common/components/Flex';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import Checkbox from 'common/components/Checkboxes/Checkbox/Checkbox';
import PropTypes from 'prop-types';

const disabledStyles = {
  opacity: 0.6,
};
// SINGLE CHECKBOX

export const FormBuilderCheckbox = ({
  name,
  label,
  position,
  value,
  isChecked,
  disabled = false,
  readOnly = false,
  onChangeHandler,
  getValues,
  tabIndex,
}) => {
  return (
    <FormControl
      padding={'0rem 1rem'}
      style={{
        height: '100%',
        display: 'flex',
        alignItems: position ?? 'flex-end',
      }}
    >
      <Checkbox
        tabIndex={tabIndex}
        isChecked={isChecked ? isChecked() : getValues(value)}
        style={disabled ? disabledStyles : {}}
        disabled={disabled ?? false}
        readOnly={readOnly ?? false}
        onChange={() => {
          if (!readOnly && !disabled) onChangeHandler(getValues(name));
        }}
      >
        {label}
      </Checkbox>
    </FormControl>
  );
};
FormBuilderCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  span: PropTypes.number,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  position: PropTypes.string,
  isChecked: PropTypes.func,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

// MULTIPLE CHECKBOXES
export const FormBuilderCheckboxGroup = ({
  name,
  label,
  options,
  isInvalid,
  isRequired = false,
  isChecked,
  onChangeHandler,
  displayValue,
  readOnly,
  disabled,
  tabIndex,
  dataTestId,
}) => (
  <FormControl isRequired={isRequired}>
    <CheckboxGroup tabIndex={tabIndex}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Flex>
        {options.map(
          (option, i) =>
            (option.isHidden ? !option.isHidden() : true) && (
              <Checkbox
                tabIndex={tabIndex}
                isInvalid={isInvalid ?? false}
                data-testid={`${dataTestId}-${option.value}`}
                key={i}
                style={{ marginRight: '1.5rem', opacity: disabled ? 0.6 : 1 }}
                isChecked={isChecked(option)}
                onChange={() => {
                  if (!readOnly || !disabled) onChangeHandler(option);
                }}
              >
                {option.label ? option.label : displayValue(option)}
              </Checkbox>
            ),
        )}
      </Flex>
    </CheckboxGroup>
  </FormControl>
);

FormBuilderCheckboxGroup.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  span: PropTypes.number,
  isInvalid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  isRequired: PropTypes.bool,
  errors: PropTypes.object,
  validate: PropTypes.func,
  displayValue: PropTypes.func,
  isChecked: PropTypes.func,
  onChangeHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
};
