const TYPES = {
  GET_AUDIT_HISTORY_DATA_ERROR: 'GET_AUDIT_HISTORY_DATA_ERROR',
  GET_AUDIT_HISTORY_DATA_STARTED: 'GET_AUDIT_HISTORY_DATA_STARTED',
  GET_AUDIT_HISTORY_DATA_SUCCESS: 'GET_AUDIT_HISTORY_DATA_SUCCESS',
  GET_IS_AUDIT_HISTORY_FEATURE_ENABLED_ERROR:
    'GET_IS_AUDIT_FEATURE_ENABLED_ERROR',
  GET_IS_AUDIT_HISTORY_FEATURE_ENABLED_STARTED:
    'GET_IS_AUDIT_FEATURE_ENABLED_STARTED',
  GET_IS_AUDIT_HISTORY_FEATURE_ENABLED_SUCCESS:
    'GET_IS_AUDIT_FEATURE_ENABLED_SUCCESS',
  TOGGLE_SHOW_AUDIT_HISTORY: 'TOGGLE_SHOW_AUDIT_HISTORY',
  SET_SELECTED_HISTORY_ID: 'SET_SELECTED_HISTORY_ID',
  SET_QUESTION_IDS: 'SET_QUESTION_IDS',
  SET_REVERSE_QUESTION_ORDER: 'SET_REVERSE_QUESTION_ORDER',
  SET_QUESTIONS: 'SET_QUESTIONS',
  TOGGLE_AUDIT_HISTORY: 'TOGGLE_AUDIT_HISTORY',
};
export default TYPES;
