import PropTypes from 'prop-types';

const alertPropTypes = PropTypes.shape({
  additionalReferences: PropTypes.object,
  customerLenderDatabaseId: PropTypes.number,
  customerRecordId: PropTypes.number,
  displayTitle: PropTypes.string.isRequired,
  displayTitleDesc: PropTypes.string,
  gutterItemChildren: PropTypes.array.isRequired,
  gutterItemId: PropTypes.string.isRequired,
  parentRuleSortOrder: PropTypes.number.isRequired,
  ruleCategoryId: PropTypes.number.isRequired,
  ruleCategoryName: PropTypes.string.isRequired,
  ruleId: PropTypes.number.isRequired,
  ruleName: PropTypes.string.isRequired,
  ruleReferenceId: PropTypes.number,
  ruleReferenceLDId: PropTypes.number,
  ruleReferenceTypeDesc: PropTypes.string,
  ruleReferenceTypeId: PropTypes.number,
  ruleResultId: PropTypes.number.isRequired,
  ruleResultStatusId: PropTypes.number.isRequired,
  ruleSubCategoryId: PropTypes.number.isRequired,
  ruleSubCategoryName: PropTypes.string.isRequired,
});

export default alertPropTypes;
