import PropTypes from 'prop-types';
import { FormControl as MUIFormControl } from '@mui/material';

// TODO: Remove once the MUI transition includes FormControl
export default function FormControl({
  variant,
  sx,
  fullWidth = true,
  children,
  ...props
}) {
  return (
    <MUIFormControl variant={variant} sx={sx} fullWidth={fullWidth} {...props}>
      {children}
    </MUIFormControl>
  );
}

FormControl.propTypes = {
  variant: PropTypes.string,
  sx: PropTypes.object,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  props: PropTypes.object,
};
