import { useState } from 'react';
import Stack from 'common/components/Stacks/Stack';
import Button from 'common/componentMapper/Button/Button';
import Text from 'common/componentMapper/Text/Text';
import { SHOW_LESS, SHOW_MORE } from '../constants';
import PropTypes from 'prop-types';

const DetailedAnswer = ({ detailedAnswer }) => {
  const [{ overflow, whiteSpace, maxWidth, showMore }, setDetailedAnswerStyle] =
    useState({
      overflow: false,
      whiteSpace: false,
      maxWidth: false,
      showMore: false,
    });

  const hasEllipsis = detailedAnswer?.split('').length > 50 ?? false;

  const handleDetailedAnswerStyles = () => {
    setDetailedAnswerStyle((prev) => {
      return {
        ...prev,
        overflow: !prev.overflow,
        whiteSpace: !prev.whiteSpace,
        maxWidth: !prev.maxWidth,
        showMore: !prev.showMore,
      };
    });
  };
  return (
    <>
      <Text
        sx={{
          whiteSpace: whiteSpace ? 'wrap' : 'nowrap',
          overflow: overflow ? 'visible' : 'hidden',
          textOverflow: hasEllipsis && 'ellipsis',
          maxWidth: maxWidth ? 'auto' : '550px',
          paddingRight: '1rem',
        }}
        text={detailedAnswer}
      />
      <Stack
        direction="row"
        sx={{ justifyContent: 'flex-end', marginRight: '1rem' }}
      >
        {hasEllipsis && (
          <Button
            type="linkButton"
            label={showMore ? SHOW_LESS : SHOW_MORE}
            onClick={() => handleDetailedAnswerStyles()}
          />
        )}
      </Stack>
    </>
  );
};

DetailedAnswer.propTypes = {
  detailedAnswer: PropTypes.string,
};
export default DetailedAnswer;
