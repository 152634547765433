import React from 'react';
import { Accordion as ChakraAccordion } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Accordion = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraAccordion ref={ref} {...props}>
      {children}
    </ChakraAccordion>
  );
});

Accordion.displayName = 'Accordion';

export default Accordion;

Accordion.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
