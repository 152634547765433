import { RadioGroup as ChakraRadioGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const RadioGroup = ({ children, ...props }) => {
  return <ChakraRadioGroup {...props}>{children}</ChakraRadioGroup>;
};

export default RadioGroup;

RadioGroup.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
