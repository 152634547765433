export const DepositoryLiquidAssetType = {
  BUSINESS_OWNED: 'Business Owned',
  CD: 'CD',
  CHECKING: 'Checking',
  LIQUIDATED_ACCOUNT: 'Liquidated Account',
  MONEY_MARKET: 'Money Market',
  OTHER_ASSET: 'Other Asset',
  SAVINGS: 'Savings',
};

export const DepositoryLiquidAssetTypeArray = Object.values(
  DepositoryLiquidAssetType,
);

export const DepositoryNonLiquidAssetType = {
  BONDS: 'Bonds',
  MUTUAL_FUND: 'Mutual Fund',
  RETIREMENT_FUND: 'Retirement Fund',
  STOCKS: 'Stocks',
  STOCKS_AND_BONDS: 'Stocks and Bonds',
};

export const DepositoryNonLiquidAssetTypeArray = Object.values(
  DepositoryNonLiquidAssetType,
);

export const GiftAssetType = {
  GIFT_OF_EQUITY: 'Gift of Equity',
  GIFT: 'Gift',
  CASH_DEPOSIT: 'Cash Deposit',
};

export const GiftAssetTypeArray = Object.values(GiftAssetType);

export const PropertyType = {
  RETAINED: 'Retained',
  RENTAL: 'Rental',
  PRIMARY: 'Primary',
};

export const PropertyTypes = Object.values(PropertyType);

export const BusinessAssetType = {
  BUSINESS_CHECKING: 'Business Checking',
  BUSINESS_SAVINGS: 'Business Savings',
};

export const BusinessAssetTypeArray = Object.values(BusinessAssetType);

export const EMDAssetType = {
  EMD: 'Earnest Money Deposit',
};

export const EMDAssetTypeArray = Object.values(EMDAssetType);

export const GrantAssetType = {
  Grant: 'Grant',
};

export const GrantAssetTypeArray = Object.values(GrantAssetType);

export const ExpectedAssetTypeArray = ['Expected Assets'];

export const ExtractedActions = {
  USER_MODIFIED: 'UserModified',
  SYSTEM: 'System',
  NEW: 'New',
};

export const ExtractedActionsArray = Object.values(ExtractedActions);

export const ExcludeDebtsAndDeduction = ['assetTransactionSource'];

export const DefaultDate = '12/31/1969';

export const NetProceeds = 'Net Proceeds';

export const HowGiftIsBeingTransferredAnswers = {
  TO_TITLE_AT_CLOSING: 'ToTitleAtClosing',
  TO_TITLE_PRIOR_TO_CLOSING: 'ToTitlePriorToClosing',
  TO_BORROWER_PRIOR_TO_CLOSING: 'ToTheBorrowerPriorToClosing',
};

export const GiftFields = {
  PROOF_GIFT_TRANSFERRED_TO_TITLE: 'isProofGiftTransferredToTitle',
  PROOF_GIFT_TRANSFERRED_TO_BORROWER: 'isProofGiftTransferredToBorrower',
  GIFT_BORROWERS_ACCOUNT_TRANSFER: 'giftBorrowersAccountTransfer',
  GIFT_AMOUNT_TRANSFER: 'giftAmountTransfer',
  PROOF_OF_WITHDRAWAL_FHA: 'hasProofOfWithdrawal',
};
