export default class UserResponse {
  questionUserResponseID;
  currentAnswerText;
  questionAnswerId;
  ruleResultDetailId;

  constructor(ur = {}) {
    this.questionUserResponseID = Object.prototype.hasOwnProperty.call(
      ur,
      'questionUserResponseID',
    )
      ? ur.questionUserResponseID
      : null;
    this.currentAnswerText = Object.prototype.hasOwnProperty.call(
      ur,
      'currentAnswerText',
    )
      ? ur.currentAnswerText
      : null;
    this.questionAnswerId = Object.prototype.hasOwnProperty.call(
      ur,
      'questionAnswerId',
    )
      ? ur.questionAnswerId
      : null;
    this.ruleResultDetailId = Object.prototype.hasOwnProperty.call(
      ur,
      'ruleResultDetailId',
    )
      ? ur.ruleResultDetailId
      : null;
  }
}
