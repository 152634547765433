import { useRouteMatch } from 'react-router-dom';
import useLeftDrawerContext from '../../LeftDrawer/context/useLeftDrawerContext';

const useRailRouteMatches = () => {
  const { isNavigationVisible } = useLeftDrawerContext();

  const is1003Match = !!useRouteMatch(
    '/:lenderDatabaseId/:loanRecordId/summary',
  );

  const isKnowYourLoanMatch = !!useRouteMatch(
    '/:lenderDatabaseId/:loanRecordId/know-your-loan',
  );

  const isBaseRouteMatch = !!useRouteMatch('/:lenderDatabaseId/:loanRecordId/');

  const isBOLTRouteMatch =
    isBaseRouteMatch &&
    !is1003Match &&
    !isKnowYourLoanMatch &&
    isNavigationVisible;

  const isLoanSummaryMatch =
    !isBOLTRouteMatch &&
    !is1003Match &&
    !isKnowYourLoanMatch &&
    !isNavigationVisible;

  return {
    isBOLTRouteMatch,
    isLoanSummaryMatch,
    is1003Match,
    isKnowYourLoanMatch,
  };
};

export default useRailRouteMatches;
