import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';

export const StyledHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isWriteAccessDisabled',
})((props) => ({
  boxSizing: 'border-box',
  minHeight: '72px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px 0 32px',
  borderBottom: `1px solid ${props.theme.colors.gray[100]}`,
  opacity: props.isWriteAccessDisabled ? 0.6 : 1,
}));

const Header = ({ heading, isWriteAccessDisabled }) => {
  if (!heading) {
    return null;
  }

  return (
    <StyledHeader as={'header'} isWriteAccessDisabled={isWriteAccessDisabled}>
      <Text w="100%" as="h2">
        {heading}
      </Text>
      <Form1003PopUpButton />
    </StyledHeader>
  );
};

Header.propTypes = {
  heading: PropTypes.string,
  isWriteAccessDisabled: PropTypes.bool,
};

export default Header;
