import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';

//Example of the object in an array of inputs for the form builder
// {
//   type: 'infoText',  // This is the type that the FormBuilder uses to determine what component to render
//   text: `Employer: ${rowToEdit.employerName}`, // This is the text that will be displayed
//   span: 4, // This is the number of columns the component will span
//   style: { // This is the style object that will be passed to the Text component
//     fontSize: '2rem',
//     color: theme.colors.gray[500],
//   },
// };

/**
 * InfoText component
 * @param {string} text - Text to display
 * @param {object} style - Style object
 * @returns {JSX.Element}
 */

export default function InfoText({ text, style, dataTestId = 'infoText' }) {
  return (
    <Text sx={style} data-testid={dataTestId}>
      {text}
    </Text>
  );
}

InfoText.propTypes = {
  text: PropTypes.string | PropTypes.node,
  style: PropTypes.object,
  dataTestId: PropTypes.string,
};
