import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ParentAlert from './ParentAlert';
import ChildAlert from './ChildAlert';
import { Collapse } from '@chakra-ui/react';
import useLeftDrawerContext from 'common/components/Nav/LeftDrawer/context/useLeftDrawerContext';

const StyledAccordionItem = styled.div`
  overflow: hidden auto !important;
  max-height: 600px !important;
  border: 0;
  transition: all 0.5s ease-in-out;
`;

const StyledAccordionPanel = styled.div`
  transition: all 0.5s ease-in-out;
  margin: 0;
  padding: 0;
`;

const AlertTabAccordion = ({ alert }) => {
  const { isLeftDrawerCollapsed, showParentChildren } = useLeftDrawerContext();
  const showChildAlert = showParentChildren[alert?.displayTitle];

  return (
    <StyledAccordionItem>
      <ParentAlert alert={alert} />
      <StyledAccordionPanel role="tablist">
        <Collapse in={showChildAlert}>
          {alert?.gutterItemChildren?.map((childAlert) => (
            <ChildAlert
              parentAlert={alert}
              alert={childAlert}
              key={childAlert?.parentRuleSortOrder}
              isLeftDrawerCollapsed={isLeftDrawerCollapsed}
            />
          ))}
        </Collapse>
      </StyledAccordionPanel>
    </StyledAccordionItem>
  );
};

AlertTabAccordion.propTypes = {
  alert: PropTypes.object,
};

export default AlertTabAccordion;
