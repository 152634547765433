import { UnnamedWidget } from 'lib-node-amb-renderer';
import PropTypes from 'prop-types';

//INFO: will be removed after Title Refi pilot
export class HiddenElementClass extends UnnamedWidget {
  text;
  constructor(props) {
    super(props);
    this.text = props.text;
  }
}

const HiddenElement = ({ text }) => {
  return <div hidden>{text}</div>;
};

HiddenElement.propTypes = {
  text: PropTypes.string,
};

export default HiddenElement;
