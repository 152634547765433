import PropTypes from 'prop-types';
import theme from 'theme/';

export default function FormBuilderErrorMessage({ error }) {
  let message = error.message ? error.message : 'Required Field';
  return (
    <p style={{ color: theme.colors.red[500], marginTop: '.3rem' }}>
      {message}
    </p>
  );
}

FormBuilderErrorMessage.propTypes = {
  error: PropTypes.object,
};
