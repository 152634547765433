import AddressDataModel from './AddressDataModel';

export default class BorrowerAddress extends AddressDataModel {
  constructor(b = {}) {
    super(b);
    this.isCurrentStatus = Object.prototype.hasOwnProperty.call(
      b,
      'primaryResidence',
    )
      ? b.primaryResidence
      : false;
  }

  /**
   * @returns {bool}  current employment status.
   */
  getCurrentState() {
    return this.isCurrentStatus;
  }
}
