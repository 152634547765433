import PropTypes from 'prop-types';
import { List, ListItem, Text } from 'common/components';
import BreakdownContainer from '../../BreakdownContainer';
import SingleLineValue from '../../styles/SingleLineValue';
import BreakdownColumnBox from '../../styles/BreakdownColumnBox';
import { pixelsToRem } from 'theme/typography';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import lang from '../../language.json';

const PensionCalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const formula = lang.FORMULAS[selectedResult.getFormulas()[0]?.formulaId];

  return (
    <div>
      <BreakdownContainer
        header={lang.HEADERS[selectedResult.getCalculatorType()]}
        onNewWindow={onNewWindow}
        hideAccordion={hideAccordion}
      >
        {formula && (
          <>
            <SingleLineValue title={lang.MONTHLY_CALC_HEADER} value={formula} />
            <BreakdownColumnBox title={lang.CALCULATION} mb={4}>
              <List fontSize={pixelsToRem(14)}>
                {selectedResult.getFormulas().map(({ formulaId, formula }) => (
                  <Text as={ListItem} key={formulaId}>
                    {!formula.trim() && <br />}
                    {formula}
                  </Text>
                ))}
              </List>
            </BreakdownColumnBox>
          </>
        )}
        <SingleLineValue
          title={lang.MONTHLY_INCOME}
          value={selectedResult.getCalculationAmount()}
          isCurrency
        />
      </BreakdownContainer>
    </div>
  );
};
PensionCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};

export default PensionCalculationBreakdown;
