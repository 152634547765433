import { combineReducers } from 'redux/es/redux.mjs';

import TYPES from './types';
import { API_STATUS } from 'common/constants';

export const manualChecksItems = (
  state = {
    status: null,
    error: null,
    data: [],
  },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_MANUAL_CHECKS_STATUS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_MANUAL_CHECKS_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_MANUAL_CHECKS_STATUS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const saveManualChecksItems = (
  state = {
    status: null,
    error: null,
  },
  { type, error },
) => {
  switch (type) {
    case TYPES.SAVE_MANUAL_CHECKS_STATUS_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case TYPES.SAVE_MANUAL_CHECKS_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case TYPES.SAVE_MANUAL_CHECKS_STATUS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    case TYPES.SAVE_MANUAL_CHECKS_STATUS_CLEAROUT:
      return {
        ...state,
        status: null,
        error: null,
      };
    default:
      return state;
  }
};

const manualChecksReducers = combineReducers({
  manualChecksItems,
  saveManualChecksItems,
});
export default manualChecksReducers;
