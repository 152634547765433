import { InputGroup as ChakraInputGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const InputGroup = ({ children, ...props }) => {
  return <ChakraInputGroup {...props}>{children}</ChakraInputGroup>;
};

export default InputGroup;

InputGroup.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
