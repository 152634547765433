import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalHeader = ({ children, ...props }) => {
  return <ChakraModalHeader {...props}>{children}</ChakraModalHeader>;
};

export default ModalHeader;

ModalHeader.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
