import Button from 'common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import Alert from 'common/componentMapper/Alert/Alert';
import Box from 'common/componentMapper/Box/Box';
import Text from 'common/componentMapper/Text/Text';
import PropTypes from 'prop-types';

const ErrorRetryMessage = ({ refetch }) => {
  return (
    <Box sx={{ padding: '2rem .5rem' }}>
      <Alert severity="error" message="Unable to get Loan Summary data" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Text text="Retry:" sx={{ marginRight: '1rem', fontWeight: 'bold' }} />
        <Button isPrimary={false} onClick={refetch}>
          <FontAwesomeIcon icon={faRepeat} />
        </Button>
      </Box>
    </Box>
  );
};

ErrorRetryMessage.propTypes = {
  refetch: PropTypes.func,
};

export default ErrorRetryMessage;
