import { EMPLOYMENT_REVIEW_TYPES } from './types';
import API_STATUS from 'common/constants/api-status';

const employmentReviewInfoReducer = (
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) => {
  switch (action.type) {
    case EMPLOYMENT_REVIEW_TYPES.GET_EMPLOYMENT_REVIEW_SUCCESS:
      return {
        data: action.payload.data,
        status: API_STATUS.DONE,
        error: null,
      };
    case EMPLOYMENT_REVIEW_TYPES.GET_EMPLOYMENT_REVIEW_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_ANSWERS_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case EMPLOYMENT_REVIEW_TYPES.GET_EMPLOYMENT_REVIEW_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_ANSWERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_ANSWERS_SUCCESS:
      return {
        ...state,
        error: null,
        status: API_STATUS.DONE,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_SUCCESS:
      return {
        ...state,
        error: null,
        status: API_STATUS.DONE,
      };
    case EMPLOYMENT_REVIEW_TYPES.SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    default:
      return state;
  }
};
export default employmentReviewInfoReducer;
