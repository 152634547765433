import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

const ErrorMessage = ({ message, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      borderLeft: 'none',
      color: 'grey',
      minH: '798px',
    }}
    data-testid={props['data-testid']}
  >
    <FontAwesomeIcon
      icon={faCircleExclamation}
      style={{
        color: `grey`,
        fontSize: '8rem',
        marginBottom: '1rem',
      }}
    />
    <Text>{message}</Text>
  </Box>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  'data-testid': PropTypes.string,
};

export default ErrorMessage;
