﻿export const COUNTRIES = [
  { name: 'United States', abbreviation: 'US' },
  { name: 'Argentina', abbreviation: 'AR' },
  { name: 'Australia', abbreviation: 'AU' },
  { name: 'Bangladesh', abbreviation: 'BD' },
  { name: 'Brazil', abbreviation: 'BR' },
  { name: 'Burma', abbreviation: 'MM' },
  { name: 'Canada', abbreviation: 'CA' },
  { name: 'China', abbreviation: 'CN' },
  { name: 'Congo, The Democratic Republic of the', abbreviation: 'CD' },
  { name: 'Egypt', abbreviation: 'EG' },
  { name: 'El Salvador', abbreviation: 'SV' },
  { name: 'Ethiopia', abbreviation: 'ET' },
  { name: 'France', abbreviation: 'FR' },
  { name: 'Germany', abbreviation: 'DE' },
  { name: 'Honduras', abbreviation: 'HN' },
  { name: 'India', abbreviation: 'IN' },
  { name: 'Indonesia', abbreviation: 'ID' },
  { name: 'Iran, Islamic Republic of', abbreviation: 'IR' },
  { name: 'Italy', abbreviation: 'IT' },
  { name: 'Japan', abbreviation: 'JP' },
  { name: 'Mexico', abbreviation: 'MX' },
  { name: 'Nigeria', abbreviation: 'NG' },
  { name: 'Pakistan', abbreviation: 'PK' },
  { name: 'Peru', abbreviation: 'PE' },
  { name: 'Philippines', abbreviation: 'PH' },
  { name: 'Russia', abbreviation: 'RU' },
  { name: 'Spain', abbreviation: 'ES' },
  { name: 'Thailand', abbreviation: 'TH' },
  { name: 'Turkey', abbreviation: 'TR' },
  { name: 'Ukraine', abbreviation: 'UA' },
  { name: 'United Kingdom', abbreviation: 'GB' },
  { name: 'Vietnam', abbreviation: 'VN' },
];

export const COUNTRY_OPTIONS = COUNTRIES.map((country) => (
  <option value={country.abbreviation} key={country.abbreviation}>
    {country.name}
  </option>
));
