import PropTypes from 'prop-types';
import Flex from 'common/components/Flex';
import CrudGridFilter from './Filter';

const findFilterValue = (fieldName, currentFilters) => {
  const foundFilter = currentFilters.find(
    (filter) => filter.fieldName === fieldName,
  );
  return foundFilter ? foundFilter.value : [];
};

export default function FilterMenu({
  filters,
  currentFilters,
  onFilterChange,
}) {
  const handleFilterChange = (filter, value) => {
    const { fieldName, operator, evaluateFilter } = filter;
    const findFilterIndex = currentFilters.findIndex(
      (filter) => filter.fieldName === fieldName,
    );
    if (findFilterIndex > -1) {
      const newFilters = [...currentFilters];
      if (value.length > 0) {
        newFilters[findFilterIndex].value = value;
      } else {
        newFilters.splice(findFilterIndex, 1);
      }
      onFilterChange(newFilters);
    } else {
      onFilterChange([
        ...currentFilters,
        {
          fieldName,
          value: value,
          operator: operator,
          evaluateFilter,
        },
      ]);
    }
  };

  return (
    <Flex direction={'row-reverse'} align={'center'} justify={'center'}>
      {filters.map((filter, i) => (
        <CrudGridFilter
          key={i}
          filter={filter}
          handleFilterChange={handleFilterChange}
          currentFilter={findFilterValue(filter.fieldName, currentFilters)}
        />
      ))}
    </Flex>
  );
}

FilterMenu.propTypes = {
  filters: PropTypes.any,
  currentFilters: PropTypes.any,
  onFilterChange: PropTypes.func,
};
