export const LOAN_PURPOSE_TYPE = {
  REFI_FIRST_MORTGAGE: 'Refinance 1st Mortgage', //3
  PURCHASE: 'Purchase Home', //1
  HELOC: 'HELOC', //2
  CONSTRUCTION_TO_PERMANENT: 'Construction to permanent', // 6?
  PURCHASE_SECOND_MORTGAGE: 'Purchase second mortgage', //9
  REFINANCE_SECOND_MORTGAGE: 'Refinance second mortgage', //10
  HELOC_SECOND_MORTGAGE: 'HELOC second mortgage', //11
  CONSTRUCTION_REFINANCE: 'Construction Refinance', //12
};

export const LIABILITY_TYPES = {
  HELOC: 'HELOC',
  REAL_ESTATE: 'Real Estate',
};

export const REFI_PURPOSE_TYPE = {
  CASH_OUT_OTHER: 'Cash Out / Other',
  NOT_APPLICABLE: 'Not Applicable',
  STREAMLINE_REFINANCE: 'Streamline Refinance',
  CASH_OUT_DEBT_CONSOLIDATION: 'Debt Consolidation', //could be apart of CASH OUT
  RATE_AND_TERM_REDUCTION_FHA: 'Rate And Term Reduction-FHA',
  RATE_AND_TERM_REDUCTION_CONVENTIONAL: 'Rate And Term Reduction-CONV',
  CASH_OUT_HOME_IMPROVEMENT: 'Cash Out / Home Improvement',
  CASH_OUT_COLLEGE_EDUCATION: 'Cash Out / College Education',
  CASH_OUT_STUDENT_LOAN: 'Cash Out / Student Loan',
};
