import AlertDataModel from '../AlertDataModel';
import BreakdownFieldDataModel from '../BreakdownFieldDataModel';

/**
 * A WVOE calculation
 */
export default class WvoeResponseDataModel {
  constructor(r = {}) {
    this.alerts = Object.prototype.hasOwnProperty.call(r, 'alerts')
      ? r.alerts.map((alert) => new AlertDataModel(alert))
      : [];
    this.annualDetails = Object.prototype.hasOwnProperty.call(
      r,
      'annualDetails',
    )
      ? r.annualDetails
      : [];
    this.calculationAmount = Object.prototype.hasOwnProperty.call(
      r,
      'calculationAmount',
    )
      ? r.calculationAmount
      : 0;
    this.calculationFields = Object.prototype.hasOwnProperty.call(
      r,
      'calculationFields',
    )
      ? r.calculationFields.map((f) => new BreakdownFieldDataModel(f))
      : [];
    this.calculationId = Object.prototype.hasOwnProperty.call(
      r,
      'calculationId',
    )
      ? r.calculationId
      : 0;
    this.calculatorTypeId = Object.prototype.hasOwnProperty.call(
      r,
      'calculatorTypeId',
    )
      ? r.calculatorTypeId
      : 0;
    this.incomeCalculatorType = Object.prototype.hasOwnProperty.call(
      r,
      'incomeCalculatorType',
    )
      ? r.incomeCalculatorType
      : '';
    this.inputFields = Object.prototype.hasOwnProperty.call(r, 'inputFields')
      ? r.inputFields.map((f) => new BreakdownFieldDataModel(f))
      : [];
    this.isSelected = Object.prototype.hasOwnProperty.call(r, 'isSelected')
      ? r.isSelected
      : false;
    this.isDisabled = Object.prototype.hasOwnProperty.call(r, 'isDisabled')
      ? r.isDisabled
      : false;
    this.overrideAmounts = Object.prototype.hasOwnProperty.call(
      r,
      'overrideAmounts',
    )
      ? r.overrideAmounts
      : {};
    this.scenarioId = Object.prototype.hasOwnProperty.call(r, 'scenarioId')
      ? r.scenarioId
      : 0;
    this.scenarioName = Object.prototype.hasOwnProperty.call(r, 'scenarioName')
      ? r.scenarioName
      : '';
    this.formulaName = Object.prototype.hasOwnProperty.call(r, 'formulaName')
      ? r.formulaName
      : '';
    this.throughDate = Object.prototype.hasOwnProperty.call(r, 'throughDate')
      ? r.throughDate
      : '';
    this.comment = Object.prototype.hasOwnProperty.call(r, 'comment')
      ? r.comment
      : '';
  }

  /**
   * @returns {number} The amount of net qualifying income that the borrower earns per month.
   */
  getAmount() {
    return this.calculationAmount;
  }
  getCalculationAmount() {
    return this.getAmount();
  }

  /**
   * Get total amounts, using the override, and defaulting to the calculated monthly income
   *
   * @returns {number} total amount
   */
  getOverrideAmount() {
    const [{ calculationResults }] = this.getAnnualDetails();
    return calculationResults.reduce(
      (total, { incomeType, calculatedMonthlyIncome }) => {
        return (total +=
          this.overrideAmounts[`overridden${incomeType}Income`] ??
          calculatedMonthlyIncome);
      },
      0,
    );
  }

  /**
   * determine if any overrides were used
   *
   * @returns {boolean} indicator if override values are set
   */
  isOverrideUsed() {
    return Object.keys(this.overrideAmounts).some(
      (key) => this.overrideAmounts[key] !== null,
    );
  }

  /**
   * @returns {array} The details that go into determining the variable income calculation
   */
  getAnnualDetails() {
    return this.annualDetails;
  }

  /**
   * @returns {[AlertDataModel]} A list of alerts generated by the given calculation.
   */
  getAlerts() {
    return this.alerts;
  }
  /**
   * Get the Wvoe through date.
   */
  getThroughDate() {
    return this.throughDate;
  }
}
