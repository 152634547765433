import {
  useFieldApi,
  FieldArray,
} from '@data-driven-forms/react-form-renderer';
import { NamedWidget, SDUI } from 'lib-node-amb-renderer';
import { cloneDeep } from 'lodash';
import ArrayItem from './ArrayItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'common/components/Button';

export class FieldArrayClass extends NamedWidget {
  originalName;
  constructor(props) {
    super(props);
    this.originalName = props.name;

    // Get all of the Children entries
    const entries = props.Children.flatMap((x) => Object.entries(x));

    // Map each of the Children entries to their respective Widgets
    this.children = entries.map(([key, value]) => {
      // Find our Constructor in the map
      const ctor = SDUI.getInstance().getComponentMap()[key];

      // Build our Widget from the Key in the object
      const widget = new ctor(value);

      // Assign our additiona
      widget.component = key;
      widget.parent = this;

      // Return our Widget
      return widget;
    });
  }

  execute(data, props) {
    // Create our Base Widget
    this.name = null;
    const adjustedProps = { ...cloneDeep(props), clearOnUnmount: false };

    const fields = this.children?.flatMap((x) => {
      this.dataPath = this.parent?.dataPath;
      return x.execute(data, adjustedProps);
    });

    const spec = {
      ...super.execute(data, adjustedProps),
      name: this.parent?.name
        ? `${this?.parent?.name}.${this.originalName}`
        : this.originalName,
      fields: fields ?? [],
    };

    return spec;
  }
}

export default function FieldArrayCustom({ ...props }) {
  const {
    fieldKey,
    fields,
    arrayValidator,
    initialValue,
    sx,
    addLabel,
    valuesToCompare,
    useConditionalMapper,
    propertyName,
    enableIndex,
    itemDefault,
    inputNameToCompare,
    addRole,
    buttonDivSx,
    ...rest
  } = useFieldApi({
    ...props,
  });

  return (
    <FieldArray
      key={fieldKey}
      name={rest.input.name}
      validate={arrayValidator}
      initialValue={initialValue}
      propertyName={propertyName}
    >
      {(cosi) => {
        return (
          <>
            {cosi.fields.map((name, index) => (
              <ArrayItem
                key={`${name || fieldKey}-${index}`}
                fields={fields}
                name={name}
                fieldKey={fieldKey}
                fieldIndex={index}
                remove={cosi.fields.remove}
                useConditionalMapper={useConditionalMapper}
                valuesToCompare={valuesToCompare}
                propertyName={propertyName}
                enableIndex={enableIndex}
                inputNameToCompare={inputNameToCompare}
                sx={sx}
                value={cosi.fields.value}
                addRole={addRole}
                buttonDivSx={buttonDivSx}
              />
            ))}
            <Button
              isPrimary={false}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              _focus={{ backgroundColor: 'transparent' }}
              sx={{ mb: '5px', padding: '10px', border: 0 }}
              onClick={() => {
                cosi.fields.push(itemDefault);
              }}
            >
              {addLabel}
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
}
