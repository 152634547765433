﻿import PropTypes from 'prop-types';
import SelfEmploymentTrendingAnalysis from '../SelfEmploymentTrendingAnalysis';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import { FormTypes } from '../../../enums';

const TrendingAnalysis = ({ selectedResult }) => (
  <SelfEmploymentTrendingAnalysis
    isPositiveTrend={selectedResult
      .getInput(FormTypes.Form1120SOutput, 0, 'isPositiveTrend')
      ?.getValue()}
    grossMinusCogs={selectedResult.getInputAsFloat(
      FormTypes.Form1120SOutput,
      0,
      'percentChangeInGrossMinusCogs',
    )}
    expenses={selectedResult.getInputAsFloat(
      FormTypes.Form1065Output,
      0,
      'percentChangeInExpenses',
    )}
    tangibleIncome={selectedResult.getInputAsFloat(
      FormTypes.Form1120SOutput,
      0,
      'percentChangeInTangibleIncome',
    )}
  />
);
TrendingAnalysis.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};

export default TrendingAnalysis;
