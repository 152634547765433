﻿/* c8 ignore file */

import { connect } from 'react-redux';
import CalculationHistory from './layout';
import {
  getIncomeCalculationHistory,
  getRentalCalculationHistory,
} from '../redux/thunks';
import { resetCalculationHistoryResult } from '../redux/actions';

const mapStateToProps = ({ incomeCalculator, rentalIncomeData }) => ({
  summaries: incomeCalculator.calculationHistory.summaries,
  status: incomeCalculator.calculationHistory.summaryStatus,
  loan: incomeCalculator.loan,
  incomeIdentifier: incomeCalculator.selectedIncome?.getIncomeIdentifier(),
  rentalAddress: rentalIncomeData?.data?.addressIdentifier,
});

const mapDispatchToProps = {
  getIncomeCalculationHistory,
  getRentalCalculationHistory,
  resetCalculationHistoryResult,
};

const CalculationHistoryHOC = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalculationHistory);
export default CalculationHistoryHOC;
