const handleWidth = ({
  isNavigationVisible,
  isLeftDrawerCollapsed,
  is1003Match,
}) => {
  if (!isNavigationVisible || is1003Match) {
    return isLeftDrawerCollapsed ? '0' : '300px';
  } else {
    return isLeftDrawerCollapsed ? '72px' : '300px';
  }
};
const handleDrawerBtnLeft = ({
  isNavigationVisible,
  isLeftDrawerCollapsed,
  is1003Match,
}) => {
  if (isNavigationVisible && !is1003Match) {
    return isLeftDrawerCollapsed ? '8.3rem' : '22.55rem';
  } else {
    return isLeftDrawerCollapsed ? '3.75rem' : '22.55rem';
  }
};

const handleDrawerBtnTop = () => {
  return '1.4rem';
};

const handleDrawerBtnSize = () => {
  return '25px';
};

export {
  handleDrawerBtnLeft,
  handleDrawerBtnTop,
  handleWidth,
  handleDrawerBtnSize,
};
