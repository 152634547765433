import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const BADGE_COLOR = {
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
};

export const BADGE_SIZE = {
  SMALL: 'small',
  BIG: 'big',
};

const getBackgroundColor = (color, theme) => {
  let backgroundColor;
  switch (color) {
    case BADGE_COLOR.RED:
      backgroundColor = theme.colors.red[600];
      break;
    case BADGE_COLOR.GREEN:
      backgroundColor = theme.colors.green[600];
      break;
    case BADGE_COLOR.ORANGE:
      backgroundColor = theme.colors.orange[300];
      break;
    default:
      backgroundColor = theme.colors.green[600];
  }
  return backgroundColor;
};

const StyledBadge = styled('span', {
  shouldForwardProp: (prop) => prop !== 'size',
})((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  borderRadius: '50%',
  lineHeight: '150%',
  minHeight: props.size === BADGE_SIZE.SMALL ? '16px' : '20px',
  minWidth: props.size === BADGE_SIZE.SMALL ? '16px' : '20px',
  color: props.theme.colors.white,
  backgroundColor: getBackgroundColor(props.color, props.theme),
  fontSize:
    props.size === BADGE_SIZE.SMALL
      ? props.theme.functions.pixelsToRem(10)
      : props.theme.functions.pixelsToRem(12),
}));

const Badge = ({ content, description, color, size }) => {
  return (
    <StyledBadge color={color} size={size} id={`${description}Count`}>
      {content}
    </StyledBadge>
  );
};

Badge.propTypes = {
  content: PropTypes.node,
  description: PropTypes.string,
  color: PropTypes.oneOf(Object.values(BADGE_COLOR)),
  size: PropTypes.oneOf(Object.values(BADGE_SIZE)),
};

Badge.defaultProps = {
  size: BADGE_SIZE.SMALL,
};

export default Badge;
