import PropTypes from 'prop-types';
import {
  FormBuilderTextInput,
  FormBuilderTextAreaInput,
  FormBuilderCurrencyInput,
  FormBuilderRightElementInput,
  FormBuilderPatternedInput,
  FormBuilderHiddenInput,
  FormBuilderNumberInput,
} from './FormBuilderInputs/Inputs';
import {
  FormBuilderCheckbox,
  FormBuilderCheckboxGroup,
} from './FormBuilderInputs/Checkboxes';
import InfoText from './FormBuilderNonInputs/InfoText';
import { FormBuilderSelect } from './FormBuilderInputs/Select';
import { FB_DatePicker } from './FormBuilderInputs/DatePicker';
import { FormBuilderRadioButtons } from './FormBuilderInputs/RadioButtons';

export default function InputMapper({ type, ...props }) {
  const inputMap = {
    // Text Input
    ['number']: <FormBuilderNumberInput {...props} />,
    ['text']: <FormBuilderTextInput {...props} />,
    ['textarea']: <FormBuilderTextAreaInput {...props} />,
    ['currency']: <FormBuilderCurrencyInput {...props} />,
    ['inputWithPercentage']: <FormBuilderRightElementInput {...props} />,
    ['patternedInput']: <FormBuilderPatternedInput {...props} />,
    ['hidden']: <FormBuilderHiddenInput {...props} />,
    // Select Input
    ['select']: <FormBuilderSelect {...props} />,
    // Spacer or filler
    ['spacer']: <div style={{ height: '1rem' }} />,
    // Checkboxes
    ['checkbox']: <FormBuilderCheckbox {...props} />,
    ['checkboxGroup']: <FormBuilderCheckboxGroup {...props} />,
    ['datepicker']: <FB_DatePicker {...props} />,
    // Radio
    ['radio']: <FormBuilderRadioButtons {...props} />,
    // Misc / Non Inputs
    ['infoText']: <InfoText {...props} />,
  };
  if (inputMap[type]) {
    return inputMap[type];
  } else {
    return inputMap['spacer'];
  }
}

InputMapper.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
