import Input from 'common/components/Inputs/Input';
import InputGroup from 'common/components/Inputs/InputGroup';
import InputRightElement from 'common/components/Inputs/InputRightElement';
import Textarea from 'common/components/Texts/Textarea';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import theme from 'theme/';
import CurrencyInput from 'common/components/Inputs/CurrencyInput';
import PatternFormatInput from 'common/components/Inputs/InputField/patternFormatInput';

const handleInputChange = (e, pattern, onChangeHandler) => {
  const value = e.target.value;
  if (value !== '' && pattern) {
    const regex = new RegExp(pattern);
    if (!regex.test(value)) {
      return;
    }
  }
  if (onChangeHandler) onChangeHandler(e);
};

// TEXT INPUT
export const FormBuilderTextInput = ({
  name,
  label,
  disabled,
  isInvalid,
  readOnly,
  getValues,
  onChangeHandler = () => {},
  onBlur = () => {},
  pattern,
  tabIndex,
  placeholder,
  dataTestId,
  isRequired = false,
}) => {
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        style={{ backgroundColor: theme.colors.white }}
        readOnly={readOnly ?? false}
        disabled={disabled ?? false}
        tabIndex={tabIndex}
        placeholder={placeholder}
        data-testid={dataTestId}
        id={name}
        value={getValues(name) || ''} // in case of null value
        onChange={(e) => handleInputChange(e, pattern, onChangeHandler)}
        onBlur={(e) => !onBlur ?? onBlur(e.target.value)}
      />
    </FormControl>
  );
};

FormBuilderTextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  span: PropTypes.number,
  onChangeHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  getValues: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
  pattern: PropTypes.string,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
};

// Number Input
export const FormBuilderNumberInput = ({
  name,
  label,
  disabled,
  isInvalid,
  readOnly,
  getValues,
  onChangeHandler = () => {},
  onBlur = () => {},
  tabIndex,
  placeholder,
  pattern,
  dataTestId,
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        type="number"
        style={{ backgroundColor: theme.colors.white }}
        readOnly={readOnly ?? false}
        disabled={disabled ?? false}
        tabIndex={tabIndex}
        placeholder={placeholder}
        data-testid={dataTestId}
        id={name}
        value={getValues(name) || ''} // in case of null value
        onChange={(e) => handleInputChange(e, pattern, onChangeHandler)}
        onBlur={(e) => !onBlur ?? onBlur(e.target.value)}
      />
    </FormControl>
  );
};

FormBuilderNumberInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  span: PropTypes.number,
  onChangeHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  getValues: PropTypes.func,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  dataTestId: PropTypes.string,
};

// Textarea Input
export const FormBuilderTextAreaInput = ({
  name,
  label,
  size,
  resize,
  disabled,
  readOnly,
  isInvalid,
  getValues,
  onChangeHandler,
  onBlur,
  tabIndex,
  placeholder,
  pattern,
  dataTestId,
}) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        id={name}
        placeholder={placeholder}
        data-testid={dataTestId}
        style={{ backgroundColor: theme.colors.white }}
        readOnly={readOnly ?? false}
        disabled={disabled ?? false}
        tabIndex={tabIndex}
        value={getValues(name)}
        size={size ?? 'md'}
        resize={resize ?? 'none'}
        onChange={(e) => handleInputChange(e, pattern, onChangeHandler)}
        onBlur={(e) => !onBlur ?? onBlur(e.target.value)}
      />
    </FormControl>
  );
};

FormBuilderTextAreaInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  size: PropTypes.string,
  resize: PropTypes.string,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  isInvalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
};

// Currency Input
export const FormBuilderCurrencyInput = ({
  name,
  label,
  isInvalid,
  placeholder,
  getValues,
  onChangeHandler,
  onBlur,
  disabled,
  readOnly,
  tabIndex,
  dataTestId,
  isRequired,
  value,
}) => {
  const defaultValues = getValues(name) || '';
  const resolvedValue = name === 'additionalReserves' ? value : defaultValues;

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <CurrencyInput
        id={name}
        data-testid={dataTestId}
        isInvalid={isInvalid}
        defaultValues={defaultValues}
        value={resolvedValue}
        isCustomDisabled={disabled}
        tabIndex={tabIndex}
        placeholder={placeholder}
        onBlur={(e) => (onBlur ? onBlur(e.target.value) : null)}
        onChange={(e) => {
          if (onChangeHandler) onChangeHandler(e.target.value);
        }}
        isReadOnly={readOnly}
        isDisabled={disabled}
      />
    </FormControl>
  );
};
FormBuilderCurrencyInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.string,
};

// Input With Right Symbol
export const FormBuilderRightElementInput = ({
  name,
  label,
  disabled,
  symbol,
  placeholder,
  isInvalid,
  getValues,
  onBlur,
  onChangeHandler,
  dataType,
  tabIndex,
}) => (
  <FormControl tabIndex={tabIndex}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <InputGroup>
      <InputRightElement
        pointerEvents="none"
        backgroundColor="#edf2f7"
        borderRadius={'0 0.375rem 0.375rem 0'}
        border={'1px solid'}
        borderColor={'inherit'}
        borderLeft={'none'}
      >
        {symbol}
      </InputRightElement>
      <Input
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        isInvalid={isInvalid ?? false}
        tabIndex={tabIndex}
        value={getValues(name)}
        onBlur={(e) => {
          if (onBlur) onBlur(e.target.value);
        }}
        onChange={(e) => {
          if (tabIndex >= 0) onChangeHandler(e.target.value);
        }}
        type={dataType}
        style={{
          backgroundColor: theme.colors.white,
        }}
      />
    </InputGroup>
  </FormControl>
);
FormBuilderRightElementInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  symbol: PropTypes.string,
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.number,
};

// Patterned Input
export const FormBuilderPatternedInput = ({
  name,
  label,
  value,
  getValues,
  allowEmptyFormatting,
  mask,
  pattern,
  isInvalid,
  onChangeHandler,
  disabled,
  tabIndex,
  placeholder,
  dataTestId,
}) => (
  <FormControl tabIndex={tabIndex}>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    {/* BEGIN: ed8c6549bwf9 */}
    <PatternFormatInput
      tabIndex={tabIndex}
      allowEmptyFormatting={allowEmptyFormatting ?? false}
      name={name}
      data-testid={dataTestId}
      value={getValues(value)}
      placeholder={placeholder}
      disabled={disabled || tabIndex === -1}
      pattern={pattern}
      isInvalid={isInvalid ?? false}
      mask={mask ?? ' '}
      onChange={(e) => onChangeHandler(e.target.value)}
      style={{
        backgroundColor: theme.colors.white,
      }}
    />
    {/* END: ed8c6549bwf9 */}
  </FormControl>
);
FormBuilderPatternedInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  allowEmptyFormatting: PropTypes.bool,
  pattern: PropTypes.string,
  mask: PropTypes.string,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
};

// Hidden Input
export const FormBuilderHiddenInput = ({ value, getValues }) => (
  <input
    type="hidden"
    defaultValue={getValues(value)}
    value={getValues(value)}
  />
);

FormBuilderHiddenInput.propTypes = {
  name: PropTypes.string,
  getValues: PropTypes.func,
  register: PropTypes.func,
  value: PropTypes.string,
};
