const CLASSIFY_DOCUMENTS_V2_TYPES = {
  SET_ON_FIRST_RENDER: 'SET_ON_FIRST_RENDER',
  SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
  SET_SELECTED_PDF: 'SET_SELECTED_PDF',
  SET_SELECTED_DOCUMENT_ASSOCIATIONS: 'SET_SELECTED_DOCUMENT_ASSOCIATIONS',
  SET_ACTION_REQUIRED: 'SET_ACTION_REQUIRED',
  SET_REMOVE_DOCS: 'SET_REMOVE_DOCS',
  RESET_HIDE_DOCUMENT_INITIAL_STATE: 'RESET_HIDE_DOCUMENT_INITIAL_STATE',
  SET_QUICK_MATCH_DOCUMENT_ASSOCIATION: 'SET_QUICK_MATCH_DOCUMENT_ASSOCIATION',

  GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_STARTED:
    'GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_STARTED',
  GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_SUCCESS:
    'GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_SUCCESS',
  GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_ERROR:
    'GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_ERROR',

  GET_ADR_COMPLETION_STATUS_STARTED: 'GET_ADR_COMPLETION_STATUS_STARTED',
  GET_ADR_COMPLETION_STATUS_SUCCESS: 'GET_ADR_COMPLETION_STATUS_SUCCESS',
  GET_ADR_COMPLETION_STATUS_ERROR: 'GET_ADR_COMPLETION_STATUS_ERROR',

  GET_CLASSIFY_DOCUMENTS_STARTED: 'GET_CLASSIFY_DOCUMENTS_STARTED',
  GET_CLASSIFY_DOCUMENTS_SUCCESS: 'GET_CLASSIFY_DOCUMENTS_SUCCESS',
  GET_CLASSIFY_DOCUMENTS_ERROR: 'GET_CLASSIFY_DOCUMENTS_ERROR',

  GET_DOCUMENT_TYPE_ASSOCIATIONS_STARTED:
    'GET_DOCUMENT_TYPE_ASSOCIATIONS_STARTED',
  GET_DOCUMENT_TYPE_ASSOCIATIONS_SUCCESS:
    'GET_DOCUMENT_TYPE_ASSOCIATIONS_SUCCESS',
  GET_DOCUMENT_TYPE_ASSOCIATIONS_ERROR: 'GET_DOCUMENT_TYPE_ASSOCIATIONS_ERROR',

  GET_PDF_DOCUMENT_STARTED: 'GET_PDF_DOCUMENT_STARTED',
  GET_PDF_DOCUMENT_SUCCESS: 'GET_PDF_DOCUMENT_SUCCESS',
  GET_PDF_DOCUMENT_ERROR: 'GET_PDF_DOCUMENT_ERROR',

  GET_DOCUMENT_ASSOCIATIONS_STARTED: 'GET_DOCUMENT_ASSOCIATIONS_STARTED',
  GET_DOCUMENT_ASSOCIATIONS_SUCCESS: 'GET_DOCUMENT_ASSOCIATIONS_SUCCESS',
  GET_DOCUMENT_ASSOCIATIONS_ERROR: 'GET_DOCUMENT_ASSOCIATIONS_ERROR',

  SAVE_DOCUMENT_ASSOCIATIONS_STARTED: 'SAVE_DOCUMENT_ASSOCIATIONS_STARTED',
  SAVE_DOCUMENT_ASSOCIATIONS_SUCCESS: 'SAVE_DOCUMENT_ASSOCIATIONS_SUCCESS',
  SAVE_DOCUMENT_ASSOCIATIONS_ERROR: 'SAVE_DOCUMENT_ASSOCIATIONS_ERROR',

  SET_HIDE_DOCUMENT_STARTED: 'SET_HIDE_DOCUMENT_STARTED',
  SET_HIDE_DOCUMENT_SUCCESS: 'SET_HIDE_DOCUMENT_SUCCESS',
  SET_HIDE_DOCUMENT_ERROR: 'SET_HIDE_DOCUMENT_ERROR',

  GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_STARTED:
    'GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_STARTED',
  GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_SUCCESS:
    'GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_SUCCESS',
  GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_ERROR:
    'GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_ERROR',
};

export default CLASSIFY_DOCUMENTS_V2_TYPES;
