import TYPES from './types';

let initialState = {
  formState: null,
};

const titleCommitmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_FORM_STATE:
      return {
        ...state,
        formState: action.payload,
      };

    default:
      return state;
  }
};

export default titleCommitmentReducer;
