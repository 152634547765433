import { AlertDescription as ChakraAlertDescription } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AlertDescription = ({ children, ...props }) => {
  return <ChakraAlertDescription {...props}>{children}</ChakraAlertDescription>;
};

export default AlertDescription;

AlertDescription.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
