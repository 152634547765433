﻿import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { formatCurrency } from 'common/util/format';

const BreakdownValue = ({
  name,
  value,
  testId,
  isCurrency,
  isPercentage,
  isChild,
  subtract,
  children,
  ...props
}) => {
  if ([undefined, null, NaN].includes(value)) {
    return null;
  }

  const hasChildren = React.Children.count(children) > 0;
  const fontWeight = hasChildren ? 'bold' : 'normal';
  const showSign = isCurrency && isChild;

  const Sign = ({ value }) => {
    const float = parseFloat(value);
    if (subtract) {
      return float >= 0 ? <>-</> : <>+</>;
    }
    return float < 0 ? <>-</> : <>+</>;
  };

  const Value = () => {
    if (!value) {
      return '';
    }
    if (isCurrency) {
      return (
        <>
          {showSign && <Sign value={value} />}
          {formatCurrency(showSign ? `${Math.abs(parseFloat(value))}` : value)}
        </>
      );
    }
    if (isPercentage) {
      return `${value}%`;
    }
    return value;
  };

  return (
    <Box {...props}>
      <Flex justifyContent="space-between" as="dl">
        {!isChild && name && (
          <Text fontSize="sm" fontWeight={fontWeight} as="dt">
            {name}
          </Text>
        )}

        <Text
          data-testid={testId}
          fontSize="sm"
          pl={1}
          fontWeight={fontWeight}
          as="dd"
        >
          <Value />
          {isChild && name && <>&nbsp;({name})</>}
        </Text>
      </Flex>
      {children &&
        React.Children.map(children, (child) => {
          return React.isValidElement(child)
            ? React.cloneElement(child, {
                isCurrency,
                isPercentage,
                isChild: true,
                ...child.props,
              })
            : child;
        })}
    </Box>
  );
};
BreakdownValue.propTypes = {
  /** The name of the value. */
  name: PropTypes.string,
  /** The value of the field. */
  value: PropTypes.node,
  /** The value for data-testid. */
  testId: PropTypes.string,
  /** Whether the value should be formatted as a currency. */
  isCurrency: PropTypes.bool,
  /** Whether the value should be formatted as a percentage. */
  isPercentage: PropTypes.bool,
  /** Whether to show ths sign on the value. */
  isChild: PropTypes.bool,
  subtract: PropTypes.bool,
  children: PropTypes.node,
};
BreakdownValue.defaultValues = {
  isCurrency: false,
  isPercentage: false,
  showSign: false,
  subtract: false,
};

export default BreakdownValue;
