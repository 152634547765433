import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Button = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraButton ref={ref} {...props}>
      {children}
    </ChakraButton>
  );
});

Button.displayName = 'Button';

export default Button;

Button.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
