import {
  resetActiveField,
  setActiveField,
} from 'common/componentMapper/QuestionExtraction/redux/action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function useSetPdfBoundingBoxes() {
  useEffect(() => {
    return () => {
      window.postMessage(
        {
          type: 'pdfCitation',
          payload: [],
        },
        '*',
      );
    };
  }, []);

  const dispatch = useDispatch();
  //INFO: sets pdf citation/bounding boxes and changes background of QuestionExtraction component on focus

  return (boundingBoxes, fieldName) =>
    function () {
      if (!boundingBoxes) {
        setCitation(null);
      }
      if (!fieldName) {
        dispatch(resetActiveField(''));
      }
      dispatch(setActiveField(fieldName));
      setCitation(boundingBoxes);
    };

  function setCitation(citation) {
    window.postMessage(
      {
        type: 'pdfCitation',
        payload: [citation],
      },
      '*',
    );
  }
}
