import PropTypes from 'prop-types';
import { VStack, HStack, Text } from 'common/components';

export default function FormulaBreakdown({ formulas }) {
  return (
    <VStack align="left" spacing={4}>
      {formulas?.BreakDown.map((formula) => (
        <HStack align="start" key={formula.Name}>
          <Text fontSize="0.875rem" fontWeight="700">
            {formula.Name}:
          </Text>
          <VStack align="left">
            {formula.Value.map((line) => (
              <Text key={line} fontSize="0.875rem" fontWeight="400">
                {line}
              </Text>
            ))}
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
}
FormulaBreakdown.propTypes = {
  formulas: PropTypes.shape({
    BreakDown: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string.isRequired,
        Value: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }),
};
