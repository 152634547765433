import { createContext } from 'react';

const LeftDrawerContext = createContext({
  showParentChildren: false,
  isLeftDrawerCollapsed: true,
  showNavigationItems: Function,
  hideNavigationItems: Function,
  isNavigationVisible: true,
  handleNavigationVisibilityForLinkTiles: Function,
  handleLeftDrawerCollapseForDrawerBtn: Function,
  handleLeftDrawerCollapseForParentAlert: Function,
  handleLeftDrawerCollapseForChildAlert: Function,
  toggleLeftDrawer: Function,
  closeLeftDrawer: Function,
  openLeftDrawer: Function,
});

export default LeftDrawerContext;
