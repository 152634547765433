﻿const components = {
  Checkbox: {
    baseStyle: {
      control: {
        borderRadius: '1px',
        borderColor: 'gray.700',
        _checked: {
          bg: 'blue.600',
          borderColor: 'blue.600',
          borderRadius: '1px',
          _disabled: {
            bg: 'blue.600',
            borderColor: 'blue.600',
            color: 'white',
            opacity: '0.3',
            borderRadius: '1px',
          },
        },
        _disabled: {
          opacity: '0.3',
          borderColor: 'gray.700',
          borderRadius: '1px',
        },
      },
    },
    sizes: { md: { control: { w: '16px', h: '16px' } } },
  },
  Radio: {
    baseStyle: {
      control: {
        borderColor: 'gray.700',
        _checked: {
          _disabled: {
            bg: 'blue.600',
            borderColor: 'blue.600',
            color: 'white',
          },
        },
        _disabled: {
          borderColor: 'gray.700',
          opacity: '0.3',
        },
      },
    },
    sizes: { md: { control: { w: '16px', h: '16px' } } },
  },
  Input: {
    defaultProps: {
      errorBorderColor: 'red.500',
      focusBorderColor: 'blue.600',
    },
  },
  Textarea: {
    defaultProps: {
      borderColor: '#CBD5E0',
    },
  },
};

export default components;
