import { INVALID_DATE } from 'common/constants';
import { formatDate } from 'common/util/format';
/**
 * handler to address "Invalid Date" return from common formatter
 * @param {Date} date
 * @returns null or formatted string date
 */
export const dateFormatterForLoanSummary = (date) => {
  const formattedDate = formatDate(date);
  return formattedDate === INVALID_DATE || !formattedDate
    ? null
    : formattedDate;
};
