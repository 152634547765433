/* eslint-disable jsx-a11y/no-redundant-roles */
import PropTypes from 'prop-types';
import { StyledSection } from './Styled';

export const SelectedCategoryNavContainer = ({ children }) => {
  /**INFO: nav container for top selected categories  i.e Classify, Borrower, Liabilities, etc.*/
  return (
    <StyledSection>
      <nav aria-label="selected category navigation">
        <ul
          style={{ display: 'flex', listStyleType: 'none' }}
          role="list"
          aria-label="category tablist"
        >
          {children}
        </ul>
      </nav>
    </StyledSection>
  );
};

SelectedCategoryNavContainer.propTypes = {
  children: PropTypes.node,
};

export default SelectedCategoryNavContainer;
