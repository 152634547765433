import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MUICircularProgress from 'common/components/Spinner/index';
import Container from 'common/components/Containers/Container';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import styled from '@emotion/styled';
import Oops from 'common/components/Oops';

import Button from 'common/components/Button';
import { LOADER_MESSAGE } from './language.json';
import { HomeActions } from 'pages/Home/redux';
import { StyledClassifyFooter } from './StyledComponents/ClassifyRightContainer.styles';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.gray[50]};
  min-height: 798px;
  height: 100%;
`;

const StyledContainer = styled(Container)`
  position: absolute;
  padding: unset;
`;

const getAltMessage = (
  isLoanInUnderwriting,
  waitingOnADR,
  loadingDocuments,
  noDocuments,
  isUnderwriter,
  noNavigationItems,
) => {
  const {
    ADR_PROCESSING,
    DEFAULT,
    EMPTY,
    NO_NEW_DOCS,
    NO_NAVIGATIONS_ITEMS,
    UNDERWRITING,
  } = LOADER_MESSAGE;
  let altMessage = EMPTY;
  switch (true) {
    case isLoanInUnderwriting && !isUnderwriter:
      altMessage = UNDERWRITING;
      break;
    case waitingOnADR:
      altMessage = ADR_PROCESSING;
      break;
    case loadingDocuments:
      altMessage = DEFAULT;
      break;
    case noDocuments:
      altMessage = NO_NEW_DOCS;
      break;
    case noNavigationItems:
      altMessage = NO_NAVIGATIONS_ITEMS;
      break;
    default:
      altMessage = EMPTY;
  }
  return altMessage;
};

const AltMessageScreen = ({
  isADRResolvedOnTime,
  isDataLoading,
  isLoanInUnderwriting,
  waitingOnADR,
  noDocuments,
  hasError,
  noNavigationItems,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(HomeActions.nextAlert());
  };

  const {
    user: { isUnderwriter },
  } = useSelector(({ home }) => home);

  const isLoading = waitingOnADR || isDataLoading;
  const altMessage = getAltMessage(
    isLoanInUnderwriting,
    waitingOnADR,
    isDataLoading,
    noDocuments,
    isUnderwriter,
    noNavigationItems,
  );
  if (!isADRResolvedOnTime || hasError) {
    return (
      <Container minWidth="1272px" padding="unset" maxWidth="100%">
        <StyledBox as="section">
          <Oops />
        </StyledBox>
      </Container>
    );
  }
  return (
    <StyledContainer
      padding="unset"
      data-testid={props['data-testid'] || 'altScreen'}
      as="form"
      onSubmit={handleContinue}
      maxWidth="100%"
      zIndex={2002}
    >
      <StyledBox as="section">
        <Box
          flexGrow="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          {isLoading && !isLoanInUnderwriting && (
            <MUICircularProgress
              color="orange.500"
              size="xl"
              thickness="4px"
              mb="1rem"
            />
          )}
          {altMessage && (
            <Text
              as="h2"
              style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            >
              {altMessage}
            </Text>
          )}
        </Box>

        {noDocuments && (
          <StyledClassifyFooter
            as="footer"
            width="100%"
            marginTop="auto"
            bg="white"
            borderBottomLeftRadius="6px"
            borderBottomRightRadius="6px"
          >
            <Button id="continueBtn" type="submit" isPrimary>
              Continue
            </Button>
          </StyledClassifyFooter>
        )}
      </StyledBox>
    </StyledContainer>
  );
};

AltMessageScreen.propTypes = {
  'data-testid': PropTypes.string,
  waitingOnADR: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  noDocuments: PropTypes.bool,
  noNavigationItems: PropTypes.bool,
  isLoanInUnderwriting: PropTypes.bool,
  isADRResolvedOnTime: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default AltMessageScreen;
