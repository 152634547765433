import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import PropTypes from 'prop-types';

export default function HeadingAndText({ heading = '', text = '' }) {
  let description = <Text data-testid={`single-text`}>{text}</Text>;
  if (Array.isArray(text)) {
    description = (
      <Text>
        {text.map((el, idx) => (
          <Text
            data-testid="many-text"
            key={`${el}-${idx}`}
            p={0}
            m={0}
            as="span"
            d="block"
          >
            {el}
          </Text>
        ))}
      </Text>
    );
  }

  return (
    <Flex marginY={2}>
      <Text as="h3" data-testid={`heading`} mr={1}>
        {heading}
      </Text>

      {description}
    </Flex>
  );
}

HeadingAndText.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
