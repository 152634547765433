import ObfuscatedLoanIdentifierDataModel from '../../../model/ObfuscatedLoanIdentifierDataModel';

export default class LiabilitiesData {
  constructor(liability = {}) {
    this.debtId = Object.prototype.hasOwnProperty.call(liability, 'debtId')
      ? liability.debtId
      : '';

    this.debtLenderdatabaseId = Object.prototype.hasOwnProperty.call(
      liability,
      'debtLenderdatabaseId',
    )
      ? liability.debtLenderdatabaseId
      : '';

    this.addressIdentifier = Object.prototype.hasOwnProperty.call(
      liability,
      'addressIdentifier',
    )
      ? liability.addressIdentifier
      : { addressLdId: '', addressId: '' };

    this.associatedRealEstate = Object.prototype.hasOwnProperty.call(
      liability,
      'associatedRealEstate',
    )
      ? liability.associatedRealEstate
      : { addressLdId: '', addressId: '' };

    this.liabilityType = Object.prototype.hasOwnProperty.call(
      liability,
      'liabilityType',
    )
      ? liability.liabilityType
      : '';

    this.creditorName = Object.prototype.hasOwnProperty.call(
      liability,
      'creditorName',
    )
      ? liability.creditorName
      : '';

    this.accountNumber = Object.prototype.hasOwnProperty.call(
      liability,
      'accountNumber',
    )
      ? liability.accountNumber
      : '';

    this.originalTermRemaining = Object.prototype.hasOwnProperty.call(
      liability,
      'originalTermRemaining',
    )
      ? liability.originalTermRemaining
      : '';

    this.payment = Object.prototype.hasOwnProperty.call(liability, 'payment')
      ? liability.payment
      : '';

    this.payoffRequired = Object.prototype.hasOwnProperty.call(
      liability,
      'payoffRequired',
    )
      ? liability.payoffRequired
      : null;

    this.balance = Object.prototype.hasOwnProperty.call(liability, 'balance')
      ? liability.balance
      : '';

    this.availableLiabilityTypes = Object.prototype.hasOwnProperty.call(
      liability,
      'availableLiabilityTypes',
    )
      ? liability.availableLiabilityTypes
      : { key: '', value: '' };

    this.availableExclusionReasons = Object.prototype.hasOwnProperty.call(
      liability,
      'availableExclusionReasons',
    )
      ? liability.availableExclusionReasons
      : { key: '', value: '' };

    this.borrowerNames = Object.prototype.hasOwnProperty.call(
      liability,
      'borrowerNames',
    )
      ? liability.borrowerNames
      : {
          firstName: '',
          lastName: '',
          middleName: '',
          suffixName: '',
        };

    this.excludePayment = Object.prototype.hasOwnProperty.call(
      liability,
      'excludePayment',
    )
      ? liability.excludePayment
      : null;

    this.excludeBalance = Object.prototype.hasOwnProperty.call(
      liability,
      'excludeBalance',
    )
      ? liability.excludeBalance
      : null;

    this.exclusionReason = Object.prototype.hasOwnProperty.call(
      liability,
      'exclusionReason',
    )
      ? liability.exclusionReason
      : '';
    this.availableREOAddresses = Object.prototype.hasOwnProperty.call(
      liability,
      'availableREOAddresses',
    )
      ? liability.availableREOAddresses
      : [
          {
            addressId: {
              addressLdId: '',
              addressId: '',
            },
            propertyAddress: {
              addressLine1: '',
              unit: '',
              city: '',
              state: '',
              zipCode: '',
            },
          },
        ];

    this.obfuscatedLoanIdentifier = Object.prototype.hasOwnProperty.call(
      liability,
      'obfuscatedLoanIdentifier',
    )
      ? new ObfuscatedLoanIdentifierDataModel(
          liability.obfuscatedLoanIdentifier,
        )
      : new ObfuscatedLoanIdentifierDataModel();

    this.associatedRealEstateAddress = Object.prototype.hasOwnProperty.call(
      liability,
      'associatedRealEstateAddress',
    )
      ? liability.associatedRealEstateAddress
      : [
          {
            addressLine1: '',
            unit: '',
            city: '',
            state: '',
            zipCode: '',
          },
        ];

    this.customerIdentifiers = Object.prototype.hasOwnProperty.call(
      liability,
      'customerIdentifiers',
    )
      ? liability.customerIdentifiers
      : [
          {
            borrowerLenderDatabaseId: '',
            borrowerRecordId: '',
          },
        ];
  }
}
