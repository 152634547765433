import Oidc from 'oidc-client';

const config = {
  client_id: window.globalConfig?.auth.clientId,
  redirect_uri: window.globalConfig?.auth.redirect_uri,
  authority: window.globalConfig?.auth?.authority,
  debug: window.globalConfig?.auth.debug,
  response_type: window.globalConfig?.auth.response_type,
  post_logout_redirect_uri: window.globalConfig?.auth.post_logout_redirect_uri,
  scope: window.globalConfig?.auth.scope,
  userStore: new Oidc.WebStorageStateStore(),
  automaticSilentRenew: true,
  refreshTokenAllowedScope: window.globalConfig?.auth.resource,
  resource: window.globalConfig?.auth.resource,
  loadUserInfo: false,
  response_mode: 'query',
};

const oidcManager = new Oidc.UserManager(config);

oidcManager.signInSilentWithRedirectFallback = async () => {
  try {
    await oidcManager.signinSilent();
  } catch {
    await oidcManager.clearStaleState();
    oidcManager.signinRedirect({
      state: window.location.href,
    });
  }
};

/* c8 ignore next */
oidcManager.events.addAccessTokenExpired(() =>
  oidcManager.signInSilentWithRedirectFallback(),
);
export default oidcManager;
