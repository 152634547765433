import PropTypes from 'prop-types';
import { Flex, Text } from 'common/components';
import { pixelsToRem } from 'theme/typography';
import { formatCurrency } from 'common/util/format';
import BreakdownContainer from '../BreakdownContainer';
import Formulas from 'common/components/Formulas';
import BaseFormulas from './BaseFormulas';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';

const CapitalGainsCalculationBreakdown = ({
  selectedResult,
  hideAccordion = false,
  onNewWindow,
}) => {
  return (
    <BreakdownContainer
      header={`Review ${selectedResult.incomeCalculatorType}`}
      calculatedAmount={selectedResult.calculationAmount}
      hideAccordion={hideAccordion}
      onNewWindow={onNewWindow}
    >
      <BaseFormulas formulas={selectedResult.formulas} />
      <Formulas formulas={selectedResult.formulas} />
      <Flex alignItems="center">
        <Text mr={1} fontSize={pixelsToRem(14)} fontWeight="bold" d="inline">
          Capital Gain:
        </Text>
        <Text
          fontSize={pixelsToRem(14)}
          d="inline"
          data-testid="monthly-income"
        >
          {formatCurrency(selectedResult.calculationAmount)}
        </Text>
      </Flex>
    </BreakdownContainer>
  );
};
CapitalGainsCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  hideAccordion: PropTypes.bool,
  onNewWindow: PropTypes.func,
};

export default CapitalGainsCalculationBreakdown;
