export const HIDE_BOLT_ANSWER = 'Hide BOLT Answer';
export const SHOW_BOLT_ANSWER = 'Show BOLT Answer';
export const SHOW_MORE = 'Show More';
export const SHOW_LESS = 'Show Less';
export const inputComponentNames = [
  'Select',
  'Input',
  'Radio',
  'BorrowerCheckboxGroup',
];
