export default class ExtractedDocumentDataModel {
  constructor(doc = {}) {
    this.fiscalYear = Object.hasOwnProperty.call(doc, 'fiscalYear')
      ? doc.fiscalYear
      : 0;

    this.documentType = Object.hasOwnProperty.call(doc, 'documentType')
      ? doc.documentType
      : 'Document';

    this.documentId = Object.hasOwnProperty.call(doc, 'documentId')
      ? doc.documentId
      : null;
  }

  /**
   * @return {number || null} The document id.
   */
  getDocumentId() {
    return this.documentId;
  }

  /**
   * @return {number} The fiscal year of the document.
   */
  getFiscalYear() {
    return this.fiscalYear;
  }

  /**
   * @return {string} The document type name;
   */
  getDocumentType() {
    return this.documentType;
  }

  getDocumentName() {
    if (this.getFiscalYear() === 0) {
      return `${this.getDocumentType()}`;
    }
    return `${this.getFiscalYear()} ${this.getDocumentType()}`;
  }
}
