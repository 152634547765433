import React from 'react';
import PropTypes from 'prop-types';
import Stack from 'common/components/Stacks/Stack';
import Text from 'common/components/Texts/Text';

export default function ConsideredFormulas({ consideredFormulas }) {
  return (
    <Stack as="ul">
      {consideredFormulas.map((consideredFormula) => (
        <Text
          fontWeight={consideredFormula.isFormulaUsed ? 'bold' : 'normal'}
          listStyleType="none"
          lineHeight="1"
          as="li"
          key={consideredFormula.formulaType}
        >
          {consideredFormula.formula}
        </Text>
      ))}
    </Stack>
  );
}
ConsideredFormulas.propTypes = {
  consideredFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      formula: PropTypes.string,
      formulaType: PropTypes.string,
      incomeValue: PropTypes.number,
      isFormulaUsed: PropTypes.bool,
    }),
  ).isRequired,
};
