import TYPES from './types';
import { API_STATUS } from '../../../common/constants';
import { setBoltApprovalData } from '../model/BoltApprovalController';
const initialState = {
  status: null,
  error: null,
  approvalData: setBoltApprovalData(),
};
const BoltApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_APPROVAL_DATA_SUCCESS:
      return {
        ...state,
        approvalData: setBoltApprovalData(action.approvalData),
        status: null,
      };
    case TYPES.GET_APPROVAL_DATA_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_APPROVAL_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        status: null,
      };
    default:
      return state;
  }
};
export default BoltApprovalReducer;
