import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, TitleCaseUi } from 'common/components';
import { formatCurrency } from 'common/util/format';
import AccordionWrapper from './AccordionWrapper';
import WvoeBreakdown from './WvoeBreakdown';
import WvoeSection from './WvoeSection';
import { PaySchedule } from '../../../enums';

const TitleBox = TitleCaseUi(Box);

const copyTrend = {
  Undefined: { copy: 'Undefined' },
  Classic: { copy: '' },
  IncreasingTrend: { copy: ' - Increasing' },
  DecreasingTrend: { copy: ' - Declining' },
  DecliningButStabilizingTrendAboveAverage: {
    copy: ' - Declining but Stabilized',
  },
  DecliningButStabilizingTrendBelowAverage: {
    copy: ' - Declining but Stabilized',
  },
};

const CalculationBreakdown = ({ selectedResult, hideAccordion = false }) => {
  const annualDetails = selectedResult?.annualDetails || [];
  const {
    calculationResults,
    currentGrossBasePay,
    payType,
    ytdMonthlyAverageEquation,
  } = annualDetails[0] || {
    calculationResults: [],
    currentGrossBasePay: 0,
    payType: 0,
    ytdMonthlyAverageEquation: '',
  };

  return (
    <AccordionWrapper allowMultiple hide={hideAccordion}>
      {calculationResults
        ?.filter((result) => result.trend !== 'Undefined')
        .map((result) => (
          <Fragment key={result.incomeType}>
            <>
              <TitleBox flex="1" textAlign="left">
                <span style={{ fontWeight: 500 }}>{result.incomeType}</span>
                {copyTrend[result.trend].copy}
              </TitleBox>
              <Text flex="1" textAlign="right" fontWeight={500} mr="18.5">
                {formatCurrency(result.calculatedMonthlyIncome)}
              </Text>
            </>
            {result.incomeType === 'Base' &&
            payType !== PaySchedule['Variable Hourly'] ? (
              <WvoeBreakdown
                result={result}
                payType={payType}
                ytdMonthlyAverageEquation={ytdMonthlyAverageEquation}
                currentGrossBasePay={currentGrossBasePay}
              />
            ) : (
              <WvoeSection key={result.incomeType} {...result} />
            )}
          </Fragment>
        ))}
    </AccordionWrapper>
  );
};

CalculationBreakdown.propTypes = {
  selectedResult: PropTypes.shape({
    annualDetails: PropTypes.arrayOf(
      PropTypes.shape({
        payType: PropTypes.number,
        calculationResults: PropTypes.array,
        ytdMonthlyAverageEquation: PropTypes.string,
        currentGrossBasePay: PropTypes.number,
      }),
    ),
  }).isRequired,
  hideAccordion: PropTypes.bool,
};

CalculationBreakdown.defaulProps = {};

export default CalculationBreakdown;
