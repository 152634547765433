import React from 'react';
import { Tabs as MUITabs } from '@mui/material';
import PropTypes from 'prop-types';

const Tabs = ({ children, ...props }) => {
  return <MUITabs {...props}>{children}</MUITabs>;
};

Tabs.propTypes = {
  action: PropTypes.func,
  allowScrollButtonsMobile: PropTypes.bool,
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  centered: PropTypes.bool,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  indicatorColor: PropTypes.oneOf(['primary', 'secondary', PropTypes.string]),
  onChange: PropTypes.func,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  ScrollButtonComponent: PropTypes.elementType,
  scrollButtons: PropTypes.oneOf(['auto', false, true]),
  selectionFollowsFocus: PropTypes.bool,
  slotProps: PropTypes.shape({
    endScrollButtonIcon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    startScrollButtonIcon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
  }),
  slots: PropTypes.shape({
    EndScrollButtonIcon: PropTypes.elementType,
    StartScrollButtonIcon: PropTypes.elementType,
  }),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  TabIndicatorProps: PropTypes.object,
  TabScrollButtonProps: PropTypes.object,
  textColor: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  value: PropTypes.any,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
  visibleScrollbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Tabs;
