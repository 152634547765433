﻿export default class CalculationInputFieldDataModel {
  constructor(field = {}) {
    this.documentFieldId = field.hasOwnProperty.call(field, 'documentFieldId')
      ? field.documentFieldId
      : 0;

    this.documentFieldName = field.hasOwnProperty.call(
      field,
      'documentFieldName',
    )
      ? field.documentFieldName[0]?.toLowerCase() +
        field.documentFieldName.substring(1)
      : '';

    this.documentFieldValue = field.hasOwnProperty.call(
      field,
      'documentFieldValue',
    )
      ? field.documentFieldValue
      : '';

    this.boundingBoxCoordinates = field.hasOwnProperty.call(
      field,
      'boundingBoxCoordinates',
    )
      ? field.boundingBoxCoordinates
      : '';

    this.snippetId = field.hasOwnProperty.call(field, 'snippetId')
      ? field.snippetId
      : 0;
  }

  /**
   * @returns {number} The DocHub field identifier.
   */
  getFieldId() {
    return this.documentFieldId;
  }

  /** @returns {string} The name of the input field. */
  getName() {
    return this.documentFieldName;
  }

  /** @returns {string} The value of the input field. */
  getValue() {
    return this.documentFieldValue;
  }
  /** @returns {Array} The value of the boundingBoxCoordinates. */
  getBoundingBoxCoordinates() {
    return this.boundingBoxCoordinates;
  }

  /**
   * Sets the field value.
   * @param value {string|number} The value to set.
   */
  setValue(value) {
    this.documentFieldValue = `${value}`;
  }

  /** @returns {number|null} The identifier for the field snippet display. */
  getSnippetId() {
    if (this.snippetId === 0) return null;
    return this.snippetId;
  }
}
