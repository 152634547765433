import { useTheme } from '@emotion/react';
import SimpleGrid from 'common/components/Grids/Grid/SimpleGrid';
import { ChildrenWidget } from 'lib-node-amb-renderer';
import { useSelector } from 'react-redux';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { inputComponentNames } from '../QuestionExtraction/constants';
import PropTypes from 'prop-types';

export class ActiveFieldBackgroundClass extends ChildrenWidget {
  constructor(props) {
    super(props);
  }
}

const ActiveFieldBackground = ({
  fields = [],
  columns,
  rowSpacing = '10px',
  columnSpacing = '10px',
  sx,
}) => {
  const { renderForm } = useFormApi();

  const theme = useTheme();
  const activeField = useSelector(
    ({ questionExtraction }) => questionExtraction.activeField,
  );

  const fieldsToMakeActive = fields?.map(({ name, component }) => {
    if (!!name && inputComponentNames.includes(component)) return name;
  });

  const isActive = fieldsToMakeActive.includes(activeField);

  let styles = {
    backgroundColor: isActive ? theme.colors.gray[200] : 'none',
    padding: sx?.padding ?? '1rem 0',
    ...sx,
  };
  return (
    <SimpleGrid
      spacingX={columnSpacing}
      spacingY={rowSpacing}
      columns={columns}
      sx={styles}
    >
      {renderForm(fields)}
    </SimpleGrid>
  );
};

ActiveFieldBackground.propTypes = {
  fields: PropTypes.array,
  columns: PropTypes.number,
  rowSpacing: PropTypes.string,
  columnSpacing: PropTypes.string,
  sx: PropTypes.object,
};
export default ActiveFieldBackground;
