import { ListItem as ChakraListItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ListItem = ({ children, ...props }) => {
  return <ChakraListItem {...props}>{children}</ChakraListItem>;
};

export default ListItem;

ListItem.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
