import React from 'react';
import { Grid as MUIGridContainer } from '@mui/material';
import PropTypes from 'prop-types';
import { gridMapper } from 'common/components/PropertyMappers/GridMapper';

const Grid = React.forwardRef(({ children, ...props }, ref) => {
  const gridContainerProps = gridMapper(props);

  return (
    <MUIGridContainer container ref={ref} {...gridContainerProps}>
      {children}
    </MUIGridContainer>
  );
});

Grid.displayName = 'Grid';

export default Grid;

const numberOrString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);
Grid.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.object,
  ]),
  columnSpacing: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  component: PropTypes.elementType,
  container: PropTypes.bool,
  direction: PropTypes.oneOfType([
    PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
    PropTypes.arrayOf(
      PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
    ),
    PropTypes.object,
  ]),
  item: PropTypes.bool,
  lg: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.bool,
  ]),
  md: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.bool,
  ]),
  rowSpacing: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  sm: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.bool,
  ]),
  spacing: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  wrap: PropTypes.oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  xl: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.bool,
  ]),
  xs: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.bool,
  ]),
  zeroMinWidth: PropTypes.bool,
  templateColumns: numberOrString,
  templateRows: numberOrString,
  gap: numberOrString,
  rowGap: numberOrString,
  columnGap: numberOrString,
};
