import { RULE_REFERENCE_TYPE_ID } from '../../common/constants';
import { ALERT_STYLE } from '../../common/constants/api-ids';

export const formatTemplatedText = (message, additionalData = {}) => {
  if (!message) {
    return message;
  }
  let customerRecord =
    additionalData[RULE_REFERENCE_TYPE_ID.CUSTOMER_RECORD_ID];
  if (customerRecord) {
    const firstName = customerRecord[0]?.firstName;
    const lastName = customerRecord[0]?.lastName;
    message = message.replace('{{BorrowerName}}', `${firstName} ${lastName}`);

    const creditAlert =
      (additionalData[RULE_REFERENCE_TYPE_ID.CREDIT_REPORT_ALERT_ID] &&
        additionalData[RULE_REFERENCE_TYPE_ID.CREDIT_REPORT_ALERT_ID][0]) ||
      (additionalData[RULE_REFERENCE_TYPE_ID.CREDIT_ALERT_ID] &&
        additionalData[RULE_REFERENCE_TYPE_ID.CREDIT_ALERT_ID][0]);
    if (creditAlert) {
      const alertDescription = creditAlert.description;
      message = message.replace('{{AlertMessage}}', alertDescription);
    }
  }
  let associatedAddress = (additionalData[RULE_REFERENCE_TYPE_ID.DEBT_ID] ||
    additionalData[RULE_REFERENCE_TYPE_ID.TRADELINE_ID] ||
    [])[0]?.associatedRealEstate?.propertyAddress?.addressLine1;
  if (associatedAddress) {
    message = message.replace(
      '{{AssociatedRealEstate}}',
      'property located at {{$associatedRealEstate[0].propertyAddress.addressLine1}}',
    );
  } else {
    message = message.replace('{{AssociatedRealEstate}}', 'associated address');
  }

  if (message.includes('{CO-OBLIGOR}')) {
    message = message.replace('{CO-OBLIGOR}', 'the co-obligor');
  }

  return message;
};

export const QUESTION_ID = {
  BANKRUPTCY_CONV: 14003,
  TAX_LIEN: 14016,
  PAID_BY_EMPLOYER: 14030,
  LESS_THAN_10_MONTHS: 14032,
  CREDIT_INQUIRE_CONV: 6,
  CREDIT_INQUIRE_FHA: 14043,
  CPS_SPOUSE: 16006,
  COLLECTION_CUMULATIVE_BALANCE: 14045,
  COLLECTION_OPTION_TO_ADDRESS: 14046,
  COLLECTION_UPDATED_LIABILITY_BALANCE: 14047,
  COLLECTION_PAYOFF_DOCS: 14048,
  DOCUMENTATION_BY_STUDENT_LOAN_SERVICER: 14122,
  FHA_DOCUMENTATION_FOR_EXCEPTION: 14055,
  REVOLVING_PAYMENT_AMOUNT: [14102, 14123],
  PAID_BY_OTHERS_MOST_RECENT_MONTH: 14117,
  PAID_BY_OTHERS_MONTHS_PROVIDED: [14118, 14119],
  PAID_BY_OTHERS_CO_OBLIGOR_NAME: 14120,
  STUDENT_LOAN_PAYMENT_ISSUE: 14121,
};
QUESTION_ID.PAID_BY_OTHERS_MONTHS_PROVIDED_CONV_NON_MTG =
  QUESTION_ID.PAID_BY_OTHERS_MONTHS_PROVIDED[1];

// Derogatory collection constants
export const NO = 'No';

export const LIABILITY_DETAIL_TYPES = {
  CURRENCY: 'CURRENCY',
  STRING: 'STRING',
  LIST: 'LIST',
};

export const LIABILITY_TYPES = {
  ALIMONY: 'Alimony',
  AUTO_LEASE_PAYMENT: 'Auto Lease Payment',
  CHILD_CARE: 'Child Care',
  CHILD_SUPPORT: 'Child Support',
  HELOC: 'HELOC',
  HERO_PACE_LIEN: 'HERO/Pace Lien',
  INSTALLMENT: 'Installment',
  OPEN: 'Open',
  OTHER_JOB_EXPENSE: 'Other Job Expense',
  OTHER_LIABILITY: 'Other Liability',
  REAL_ESTATE: 'Real Estate',
  REVOLVING: 'Revolving',
  SEPARATE_MAINTENANCE: 'Separate Maintenance',
  STUDENT_LOAN: 'Student Loan',
  UNION_DUES: 'Union Dues',
};

export const LIABILITY_TYPE_CODES = {
  ALIMONY: 0,
  AUTO_LEASE_PAYMENT: 1,
  CHILD_CARE: 2,
  CHILD_SUPPORT: 3,
  HELOC: 4,
  HERO_PACE_LIEN: 16,
  INSTALLMENT: 5,
  OPEN: 6,
  OTHER_JOB_EXPENSE: 7,
  OTHER_LIABILITY: 8,
  REAL_ESTATE: 9,
  REVOLVING: 10,
  SEPARATE_MAINTENANCE: 11,
  STUDENT_LOAN: 15,
  UNION_DUES: 12,
};

export const EXCLUSION_REASON = {
  COSIGNED_LOAN: 'Co-signed Loan',
  COURT_ORDERED: 'Court Ordered',
  DEFERRED: 'Deferred',
  DUPLICATE: 'Duplicate',
  LESS_THAN_10_MONTHS_REMAIN: 'Less Than 10 Mths Remain',
  NONE: 'None',
  NOT_THE_BORROWERS: "Not the Borrower's",
  PAID_BY_OTHERS: 'Paid by Others',
  PAID_BY_THE_EMPLOYERS: 'Paid by Employer',
  PAID_IN_FULL: 'Paid in Full',
  PROPERTY_BUYOUT: 'Property Buyout',
  SECURED: 'Secured',
  TO_BE_PAID: 'To Be Paid',
};

export const EXCLUSION_REASON_CODES = {
  COSIGNED_LOAN: 1,
  COURT_ORDERED: 2,
  DEFERRED: 3,
  DUPLICATE: 4,
  LESS_THAN_10_MONTHS_REMAIN: 5,
  NONE: 0,
  NOT_THE_BORROWERS: 6,
  PAID_BY_OTHERS: 8,
  PAID_BY_THE_EMPLOYERS: 7,
  PAID_IN_FULL: 9,
  PROPERTY_BUYOUT: 10,
  SECURED: 11,
  TO_BE_PAID: 12,
};

export const LIABILTIY_DRAWER_INPUTS = {
  accountNumber: 'Account Number',
  associatedRealEstateAddress: 'Associated Real Estate Address',
  availableREOAddresses: 'Associated Real Estate Address',
  associatedRealEstate: 'Associated Real Estate Address',
  balance: 'Balance',
  creditorName: 'Creditor Name',
  customerIdentifiers: 'Associated Customers',
  excludeBalance: 'Exclude Balance',
  excludePayment: 'Exclude Payment',
  exclusionReason: 'Exclusion Reason',
  liabilityType: 'Liability Type',
  payment: 'Payment',
  payoffRequired: 'Payoff Required',
};

export const ACTION_STATUS = {
  [ALERT_STYLE.UWM_ACTION_REQUIRED]: 'warning',
  [ALERT_STYLE.ERROR]: 'error',
  [ALERT_STYLE.NO_ACTION_REQUIRED]: 'success',
  [ALERT_STYLE.TIP]: 'info',
};

export const IN_EASE = 'In EASE';
