import { useRouteMatch } from 'react-router-dom';
import { NavLink, NavItem } from './Styled';
import PropTypes from 'prop-types';

const NavItemWrapper = ({ to, label }) => {
  const match = useRouteMatch({ path: to });

  return (
    <NavLink to={to} role="listitem">
      <NavItem selected={match}>{label}</NavItem>
    </NavLink>
  );
};
NavItemWrapper.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default NavItemWrapper;
