﻿import AlertDataModel from '../AlertDataModel';
import { FormTypes } from '../../enums';
import W2AnnualDetailDataModel from './W2AnnualDetailDataModel';
import BreakdownFieldDataModel from '../BreakdownFieldDataModel';

/**
 * A W2 calculation
 */
export default class W2ResponseDataModel {
  constructor(r = {}) {
    this.calculatorTypeId = Object.prototype.hasOwnProperty.call(
      r,
      'calculatorTypeId',
    )
      ? r.calculatorTypeId
      : 0;

    this.calculationId = Object.prototype.hasOwnProperty.call(
      r,
      'calculationId',
    )
      ? r.calculationId
      : 0;

    this.calculationAmount = Object.prototype.hasOwnProperty.call(
      r,
      'calculationAmount',
    )
      ? r.calculationAmount
      : 0;

    this.overrideAmounts = Object.prototype.hasOwnProperty.call(
      r,
      'overrideAmounts',
    )
      ? r.overrideAmounts
      : {};

    this.inputFields = Object.prototype.hasOwnProperty.call(r, 'inputFields')
      ? r.inputFields.map((field) => new BreakdownFieldDataModel(field))
      : [];

    this.totalIncome = Object.prototype.hasOwnProperty.call(r, 'totalIncome')
      ? r.totalIncome
      : 0;

    this.earningRate = Object.prototype.hasOwnProperty.call(r, 'earningRate')
      ? r.earningRate
      : 0;

    this.annualDetails = Object.prototype.hasOwnProperty.call(
      r,
      'annualDetails',
    )
      ? r.annualDetails.map((x) => new W2AnnualDetailDataModel(x))
      : [];

    this.alerts = Object.prototype.hasOwnProperty.call(r, 'alerts')
      ? r.alerts.map((alert) => new AlertDataModel(alert))
      : [];

    this.throughDate = Object.prototype.hasOwnProperty.call(r, 'throughDate')
      ? r.throughDate
      : '';

    this.incomeCalculatorType = Object.prototype.hasOwnProperty.call(
      r,
      'incomeCalculatorType',
    )
      ? r.incomeCalculatorType
      : '';

    this.formula = Object.prototype.hasOwnProperty.call(r, 'formula')
      ? r.formula
      : '';

    this.formulaName = Object.prototype.hasOwnProperty.call(r, 'formulaName')
      ? r.formulaName
      : '';

    this.scenarioId = Object.prototype.hasOwnProperty.call(r, 'scenarioId')
      ? r.scenarioId
      : null;

    this.scenarioName = Object.prototype.hasOwnProperty.call(r, 'scenarioName')
      ? r.scenarioName
      : null;

    this.isSelected = Object.prototype.hasOwnProperty.call(r, 'isSelected')
      ? r.isSelected
      : false;
    this.isDisabled = Object.prototype.hasOwnProperty.call(r, 'isDisabled')
      ? r.isDisabled
      : false;

    this.isSavedToLoan = Object.prototype.hasOwnProperty.call(
      r,
      'isSavedToLoan',
    )
      ? r.isSavedToLoan
      : false;

    this.isBaseIncomeVariable = Object.prototype.hasOwnProperty.call(
      r,
      'isBaseIncomeVariable',
    )
      ? r.isBaseIncomeVariable
      : false;

    this.isYearToDateInstantiated = Object.prototype.hasOwnProperty.call(
      r,
      'isYearToDateInstantiated',
    )
      ? r.isYearToDateInstantiated
      : false;

    this.calculationFields = Object.prototype.hasOwnProperty.call(
      r,
      'calculationFields',
    )
      ? r.calculationFields
      : [];

    this.variableIncomes = Object.prototype.hasOwnProperty.call(
      r,
      'variableIncomes',
    )
      ? r.variableIncomes
      : [];

    this.payType = Object.prototype.hasOwnProperty.call(r, 'payType')
      ? r.payType
      : '';

    this.comment = Object.prototype.hasOwnProperty.call(r, 'comment')
      ? r.comment
      : null;
  }

  /**
   * @returns {string} The type of tax form.
   * @see FORM_TYPES
   */
  getFormType() {
    return FormTypes.W2;
  }

  /**
   * @returns {number} The amount of net qualifying income that the borrower earns per month.
   */
  getCalculationAmount() {
    return this.calculationAmount;
  }

  getOverrideAmount() {
    let amount = 0;

    for (const key in this.overrideAmounts) {
      amount += this.overrideAmounts[key] ?? 0;
    }
    return amount;
  }

  /**
   * determine if any overrides were used
   *
   * @returns {boolean} indicator if override values are set
   */
  isOverrideUsed() {
    return Object.keys(this.overrideAmounts).some(
      (key) => this.overrideAmounts[key] !== null,
    );
  }

  /**
   * @returns {number} The calculation id
   */
  getCalculationId() {
    return this.calculationId;
  }

  /**
   * @returns {number} The earing rate amount
   */
  getEarningRate() {
    return this.earningRate;
  }

  /**
   * @returns {[W2AnnualDetailDataModel]} A list of anuual detail objects
   */
  getAnnualDetails() {
    return this.annualDetails;
  }

  /**
   * @returns {[AlertDataModel]} A list of alerts generated by the given calculation.
   */
  getAlerts() {
    return this.alerts;
  }

  getIncomeCalculatorType() {
    return this.incomeCalculatorType;
  }

  /**
   * @returns {Formula} A formula generated by the given calculation.
   */
  getFormula() {
    return this.formula;
  }

  /**
   * @returns {string} The name of the formula (the pay frequency).
   */
  getFormulaName() {
    return this.formulaName;
  }

  getScenarioId() {
    return this.scenarioId;
  }

  getScenarioName() {
    return this.scenarioName;
  }

  getIsSelected() {
    return this.isSelected;
  }

  getIsSavedToLoan() {
    return this.isSavedToLoan;
  }

  getIsYearToDateInstantiated() {
    return this.isYearToDateInstantiated;
  }

  getIsBaseIncomeVariable() {
    return this.isBaseIncomeVariable;
  }

  getCalculationFields() {
    return this.calculationFields;
  }

  /**
   * @returns {number} The total amount of net qualifying income
   */
  getTotalIncome() {
    return this.totalIncome;
  }

  /**
   * Get variable calculated incomes
   * @returns {array}
   */
  getVariableIncomes() {
    return this.variableIncomes;
  }

  /**
   * Get pay type of the related calculation
   * @returns {string}
   */
  getPayType() {
    return this.payType;
  }

  /**
   * Get the throughDate of the Paystub Document
   */
  getThroughDate() {
    return this.throughDate;
  }
}
