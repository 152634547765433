// 3rd party imports
import { useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'common/components/Spinner/index';
import Center from 'common/components/Center';

// local imports
import ErrorBoundary from './ErrorBoundary';
import { HomeActions } from './redux';
import { ALERTS_TO_SKIP_IF_CLEARED } from './constants';
import { CategoryToDisplay } from './routerConstants';
import { RULE_RESULT_STATUS_ID } from 'common/constants';
import {
  PanelContainerFacade,
  RightContainer,
} from 'common/components/StyledActivityPanel';
import { CATEGORY_IDS } from './constants';
import ClassifyDocumentsV2 from 'pages/ClassifyDocumentsV2/ClassifyDocumentsV2';
import RailNav from 'common/components/Nav/Rail/components/RailNav';
import LeftDrawerWrapper from 'common/components/Nav/LeftDrawer/components/LeftDrawerWrapper';
import SelectedLeftDrawerContent from 'common/components/Nav/LeftDrawer/components/SelectedLeftDrawerContent';

const Fallback = () => (
  <Center width="100%" height="100%">
    <Spinner
      size="xl"
      thickness="4px"
      color="orange.500"
      emptyColor="gray.200"
    />
  </Center>
);

const ActivityPanel = () => {
  // redux
  const dispatch = useDispatch();
  const { home } = useSelector((state) => state);
  const selectedAlert = home?.selectedAlert;
  const selectedCategory = home.selectedCategory;
  const isUnderwriter = home?.user?.isUnderwriter;
  const alerts = home?.navigationItems?.categories?.find(
    (category) => category?.navigationCategoryId === home?.selectedCategory,
  )?.navigationGutterItems;

  // get & set the first item in the alerts as selectedAlert on pageLoad
  // skip certain cleared alerts (Income Summary for now, this list can grow.)
  const getNextAlert = (alerts) => {
    let nextAlert;
    const nextValidAlert = (alert) =>
      !(
        alert.ruleResultStatusId === RULE_RESULT_STATUS_ID.CLEARED &&
        ALERTS_TO_SKIP_IF_CLEARED.includes(alert.ruleName)
      );
    if (alerts[0]?.gutterItemChildren?.length) {
      nextAlert = alerts[0]?.gutterItemChildren.find(nextValidAlert);
    } else {
      nextAlert = alerts.find(nextValidAlert);
    }
    return nextAlert;
  };
  useEffect(() => {
    if (alerts?.length > 0 && selectedAlert === null) {
      const nextAlert = getNextAlert(alerts);
      dispatch(HomeActions.setSelectedAlert(nextAlert));
    }
  }, [alerts, selectedAlert, dispatch]);

  return (
    <PanelContainerFacade>
      <RailNav />
      <LeftDrawerWrapper>
        <SelectedLeftDrawerContent selectedCategory={selectedCategory} />
      </LeftDrawerWrapper>
      {selectedCategory === CATEGORY_IDS.CLASSIFY ? (
        <ClassifyDocumentsV2 />
      ) : (
        <RightContainer>
          <ErrorBoundary>
            <Suspense fallback={<Fallback />}>
              {selectedAlert && Object.keys(selectedAlert).length && (
                <CategoryToDisplay
                  subId={selectedAlert?.ruleSubCategoryId}
                  ruleId={selectedAlert?.ruleId}
                  additionalReferences={selectedAlert?.additionalReferences}
                  isUnderwriter={isUnderwriter}
                />
              )}
            </Suspense>
          </ErrorBoundary>
        </RightContainer>
      )}
    </PanelContainerFacade>
  );
};

export default ActivityPanel;
