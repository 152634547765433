import EARLY_CLOSING_DISCLOSURE_TYPES from './types';
import { API_STATUS } from '../../../common/constants';
import { cloneDeep, set } from 'lodash';

const initialState = {
  data: null,
  status: null,
  error: null,
  persistFormData: false,
};

export const earlyClosingDisclosureReducer = (state = initialState, action) => {
  switch (action.type) {
    case EARLY_CLOSING_DISCLOSURE_TYPES.GET_EARLY_CLOSING_DISCLOSURE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: API_STATUS.SUCCESS,
      };

    case EARLY_CLOSING_DISCLOSURE_TYPES.GET_EARLY_CLOSING_DISCLOSURE_STARTED:
      return {
        ...state,
        data: null,
        status: API_STATUS.GETTING,
        error: null,
      };

    case EARLY_CLOSING_DISCLOSURE_TYPES.SAVE_EARLY_CLOSING_DISCLOSURE_ERROR:
    case EARLY_CLOSING_DISCLOSURE_TYPES.GET_EARLY_CLOSING_DISCLOSURE_ERROR: {
      return {
        ...state,
        error: action.error,
        status: API_STATUS.ERROR,
      };
    }

    case EARLY_CLOSING_DISCLOSURE_TYPES.SAVE_EARLY_CLOSING_DISCLOSURE_STARTED: {
      return {
        ...state,
        status: API_STATUS.UPDATING,
        error: null,
      };
    }

    case EARLY_CLOSING_DISCLOSURE_TYPES.SAVE_EARLY_CLOSING_DISCLOSURE_SUCCESS: {
      return {
        ...state,
        status: API_STATUS.DONE,
        persistFormData: false,
      };
    }

    case EARLY_CLOSING_DISCLOSURE_TYPES.PERSIST_FORM_DATA:
      return {
        ...state,
        persistFormData: true,
      };

    case EARLY_CLOSING_DISCLOSURE_TYPES.UPDATE_FORM_FIELD: {
      const stateCopy = cloneDeep(state);
      set(stateCopy, action.payload.path, action.payload.value);
      return stateCopy;
    }

    default:
      return state;
  }
};
