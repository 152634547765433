import ChakraAlert from 'common/components/Alerts/Alert/Alert';
import AlertDescription from 'common/components/Alerts/AlertDescription';
import AlertTitle from 'common/components/Alerts/AlertTitle';
import Box from 'common/components/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export default function Alert({ message, name, title, severity, sx }) {
  let alertColor, alertIcon, alertBackground, status, borderColor;

  if (!message) {
    return null;
  }

  switch (severity) {
    case 'info':
      alertColor = 'blue.500';
      alertBackground = 'blue.50';
      alertIcon = faInfoCircle;
      title = title || 'Tip:';
      break;
    case 'warning':
      alertColor = 'orange.600';
      alertBackground = 'orange.100';
      alertIcon = faExclamationTriangle;
      title = title || 'New Condition:';
      break;
    case 'error':
      alertColor = 'red.600';
      alertBackground = 'red.100';
      alertIcon = faExclamationCircle;
      title = title || 'Error:';
      break;
    case 'success':
      alertColor = 'green.600';
      alertBackground = 'green.50';
      alertIcon = faCheckCircle;
      title = title || 'All Set:';
      break;
    case 'extractionFailed':
    case 'manualreview':
      alertBackground = 'orange.300';
      alertIcon = faFlag;
      title = title || 'Manual Review:';
      borderColor = 'orange.900';
      break;
    default:
      status = 'info';
      alertColor = 'blue.600';
      alertBackground = 'blue.50';
      alertIcon = faInfoCircle;
      title = title || 'Tip:';
      break;
  }
  return (
    <ChakraAlert
      name={name}
      border="1px solid"
      borderColor={severity === 'extractionFailed' ? borderColor : alertColor}
      background={alertBackground}
      borderRadius="4px"
      status={status}
      w="100%"
      mb="1rem"
      sx={sx}
    >
      <Box color={`${alertColor}`} mr={2}>
        <FontAwesomeIcon icon={alertIcon} />
      </Box>
      {title && (
        <AlertTitle mr={4} fontSize="sm" color={alertColor} whiteSpace="nowrap">
          {title}
        </AlertTitle>
      )}
      <AlertDescription fontSize="sm" lineHeight="24px">
        {message}
      </AlertDescription>
    </ChakraAlert>
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  severity: PropTypes.oneOf([
    'info',
    'warning',
    'success',
    'error',
    'manualreview',
    'extractionFailed',
  ]).isRequired,
  sx: PropTypes.object,
};
