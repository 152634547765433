import React from 'react';
import { TableCell as MUITd } from '@mui/material';
import { tableMapper } from 'common/components/PropertyMappers/tableMapper';
import PropTypes from 'prop-types';

const Td = React.forwardRef(({ children, ...props }, ref) => {
  const tableProps = tableMapper(props);

  return (
    <MUITd ref={ref} {...tableProps}>
      {children}
    </MUITd>
  );
});

Td.displayName = 'Td';

export default Td;

Td.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
  scope: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['medium', 'small']),
    PropTypes.string,
  ]),
  sortDirection: PropTypes.oneOfType([
    PropTypes.oneOf(['asc', 'desc']),
    PropTypes.bool,
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['body', 'footer', 'head']),
    PropTypes.string,
  ]),
};
