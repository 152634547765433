import TYPES from './types';

export const dataError = `Unable to get Audit History`;
export const featureFlagError = `${dataError} Feature Flag Value`;

const getAuditHistoryError = () => ({
  type: TYPES.GET_AUDIT_HISTORY_DATA_ERROR,
  error: dataError,
});

const getAuditHistoryStarted = () => ({
  type: TYPES.GET_AUDIT_HISTORY_DATA_STARTED,
});

const getAuditHistorySuccess = (auditHistoryData) => ({
  type: TYPES.GET_AUDIT_HISTORY_DATA_SUCCESS,
  payload: auditHistoryData,
});

const toggleShowAuditHistory = () => ({
  type: TYPES.TOGGLE_SHOW_AUDIT_HISTORY,
});

const setSelectedHistoryId = (id) => ({
  type: TYPES.SET_SELECTED_HISTORY_ID,
  payload: id,
});

const setQuestionIds = (id) => ({
  type: TYPES.SET_QUESTION_IDS,
  payload: id,
});

const setQuestions = (questions) => ({
  type: TYPES.SET_QUESTIONS,
  payload: questions,
});

/**
 *
 * @param {{questions: [number], questionIds: [number], selectedIds: [number]}} auditHistory
 */
const toggleAuditHistory = (auditHistory) => ({
  type: TYPES.TOGGLE_AUDIT_HISTORY,
  payload: auditHistory,
});

export default {
  getAuditHistoryError,
  getAuditHistoryStarted,
  getAuditHistorySuccess,
  toggleShowAuditHistory,
  setSelectedHistoryId,
  setQuestionIds,
  setQuestions,
  toggleAuditHistory,
};
