import PropTypes from 'prop-types';
import { Box, Flex, Stack, Text } from 'common/components';
import { formatCurrency } from 'common/util/format';
import BreakdownContainer from 'pages/IncomeCalculator/Pages/ReviewBreakdown/BreakdownContainer';

export default function QualifyingCalculationBreakdown({
  selectedResult,
  hideAccordion = false,
}) {
  return (
    <Box>
      {selectedResult.calculations.map((calculation) => {
        const title = `${calculation.title}${
          (calculation.trend && ` - ${calculation.trend}`) || ''
        }`;
        return (
          <BreakdownContainer
            header={title}
            calculatedAmount={parseFloat(calculation.value)}
            hideAccordion={hideAccordion}
            key={calculation.incomeType}
          >
            {hideAccordion && (
              <Flex
                justifyContent="space-between"
                textDecoration="underline"
                fontWeight="bold"
                mb="1rem"
              >
                <Text>{title}</Text>
                <Text>{formatCurrency(calculation.value)}</Text>
              </Flex>
            )}
            <Stack>
              {calculation.cardData.map((field) => (
                <Text key={field.title}>
                  <strong>{field.title}</strong> {field.description}
                </Text>
              ))}
            </Stack>
          </BreakdownContainer>
        );
      })}
    </Box>
  );
}
QualifyingCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.shape({
    title: PropTypes.string.isRequired,
    calculationId: PropTypes.number.isRequired,
    method: PropTypes.string,
    throughDate: PropTypes.string,
    total: PropTypes.number.isRequired,
    calculations: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        trend: PropTypes.string,
        value: PropTypes.string.isRequired,
        incomeType: PropTypes.oneOf([
          'base',
          'overtime',
          'bonus',
          'commission',
          'tip',
          'other',
          'seasonal',
          'secondaryBase',
        ]).isRequired,
        cardData: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ),
  }).isRequired,
  hideAccordion: PropTypes.bool,
};
