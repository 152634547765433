export const CALCULATORTYPEID = {
  RENTAL1040: 31,
  LEASEAGREEMENT: 32,
  RENTAL1007: 33,
  RENTAL998: 42,
};

export const GET_DATA_ERROR_MESSAGE = 'UNABLE TO GET THE DATA';

export const AUS_METHOD_ID = {
  DESKTOP_UNDERWRITER: 1,
  LOAN_PRODUCT_ADVISOR: 2,
};

export const APPRAISAL_FORM_1007_1025 = 1;
export const LEASE_AGREEMENT = 2;
export const ONE_YEAR_1040_SCHEDULE_E = 3;
export const APPRAISAL_ORDER_PENDING = 4;
export const FHA_ONE_YEAR_1040_SCHEDULE_E = 5;
export const FHA_TWO_YEAR_1040_SCHEDULE_E = 6;
export const OPERATING_INCOME_FORM_998 = 7;
export const SCHEDULE_E_1040_DOCS = [
  ONE_YEAR_1040_SCHEDULE_E,
  FHA_ONE_YEAR_1040_SCHEDULE_E,
  FHA_TWO_YEAR_1040_SCHEDULE_E,
];

export const CALCULATION_FORM_NAMES = {
  FORM_1040: 'form1040',
  FORM_1007: 'form1007',
  FORM_998: 'form998',
  FORM_LEASE_AGREEMENT: 'leaseAgreement',
};

export const RENTAL_REPAIR_DOCUMENTATION_QUESTION_ID = 119;
export const RENT_FROM_DEPARTING_PRIMARY_QUESTION_ID = 66;
export const DEFAULT_AMOUNT = -1;
export const DEFAULT_SECONDARY_AMOUNT = -2;
