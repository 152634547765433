﻿export default class BreakdownFieldDataModel {
  constructor(f = {}) {
    this.fieldId = Object.hasOwnProperty.call(f, 'fieldId') ? f.fieldId : 0;
    this.fieldName = Object.hasOwnProperty.call(f, 'fieldName')
      ? f.fieldName[0].toLowerCase() + f.fieldName.substring(1)
      : '';
    this.fieldValue = Object.hasOwnProperty.call(f, 'fieldValue')
      ? f.fieldValue
      : '';
    this.formId = Object.hasOwnProperty.call(f, 'formId') ? f.formId : 0;
    this.documentFieldId = Object.hasOwnProperty.call(f, 'documentFieldId')
      ? f.documentFieldId
      : 0;
    this.documentFieldName = Object.hasOwnProperty.call(f, 'documentFieldName')
      ? f.documentFieldName
      : '';
    this.documentFieldDesc = Object.hasOwnProperty.call(f, 'documentFieldDesc')
      ? f.documentFieldDesc
      : '';
    this.fiscalYear = Object.hasOwnProperty.call(f, 'fiscalYear')
      ? f.fiscalYear
      : 0;

    if (Object.hasOwnProperty.call(f, 'documentTypeID')) {
      this.documentTypeId = f.documentTypeID;
    } else if (Object.hasOwnProperty.call(f, 'documentTypeId')) {
      this.documentTypeId = f.documentTypeId;
    } else {
      this.documentTypeId = 0;
    }

    this.snippetId = Object.hasOwnProperty.call(f, 'snippetId')
      ? f.snippetId
      : 0;
    this.documentId = Object.hasOwnProperty.call(f, 'documentID')
      ? f.documentID
      : 0;
  }

  getFieldId() {
    return this.fieldId;
  }

  getFieldName() {
    return this.fieldName;
  }

  getValue() {
    return this.fieldValue;
  }

  getFormId() {
    return this.formId;
  }

  getDocumentType() {
    return this.documentTypeId;
  }

  getFiscalYear() {
    return this.fiscalYear;
  }
}
