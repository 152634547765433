import TYPES from './types';
import { API_STATUS } from '../../../common/constants';
import { setDriversLicenseData } from '../model/DriversLicenseController';

const driverLicenseReviewInfo = {
  status: null,
  snippetStatus: null,
  error: null,
  driverLicense: setDriversLicenseData(),
  driverLicenseSnippet: {},
  driverLicenseResults: null,
  driverLicenseQuestionAnswer: null,
  selectedLicenseDocument: null,
};

const driverLicenseReducer = (state = driverLicenseReviewInfo, action) => {
  switch (action.type) {
    case TYPES.GET_DRIVER_LICENSE_VALIDATION_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_VALIDATION_SUCCESS:
      return {
        ...state,
        driverLicense: setDriversLicenseData(action.payload),
        status: null,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_VALIDATION_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    case TYPES.SAVE_DRIVER_LICENSE_VALIDATION_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.SAVE_DRIVER_LICENSE_VALIDATION_SUCCESS:
      return {
        ...state,
        status: null,
        error: null,
      };
    case TYPES.SAVE_DRIVER_LICENSE_VALIDATION_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    case TYPES.GET_DRIVER_LICENSE_SNIPPET_STARTED:
      return {
        ...state,
        snippetStatus: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_SNIPPET_SUCCESS:
      return {
        ...state,
        driverLicenseSnippet: action.payload,
        snippetStatus: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_SNIPPET_ERROR:
      return {
        ...state,
        snippetStatus: API_STATUS.ERROR,
        error: action.error,
      };
    case TYPES.GET_SELECTED_LICENSE_SNIPPET_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_SELECTED_LICENSE_SNIPPET_SUCCESS:
      return {
        ...state,
        status: null,
        selectedLicenseDocument: action.payload,
        error: null,
      };
    case TYPES.GET_SELECTED_LICENSE_SNIPPET_ERROR:
      return {
        ...state,
        selectedLicenseDocument: {
          ...state.selectedLicenseDocument,
          error: action.payload?.error,
        },
        status: null,
      };
    case TYPES.RESET_SELECTED_LICENSE_SNIPPET:
      return {
        ...state,
        selectedLicenseDocument: {},
      };
    case TYPES.GET_DRIVER_LICENSE_RESULTS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_RESULTS_SUCCESS:
      state.driverLicense.setValidationResults(action.payload.results);
      return {
        ...state,
        driverLicenseResults: action.payload,
        status: null,
        error: null,
      };
    case TYPES.GET_DRIVER_LICENSE_RESULTS_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    case TYPES.POST_DRIVER_LICENSE_QUESTION_ANSWERS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.POST_DRIVER_LICENSE_QUESTION_ANSWERS_SUCCESS:
      return {
        ...state,
        driverLicenseQuestionAnswer: action.payload,
        status: null,
        error: null,
      };
    case TYPES.POST_DRIVER_LICENSE_QUESTION_ANSWERS_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default driverLicenseReducer;
