// 3rd party imports
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';

// local imports
import oidcManager from '../util/oidc-manager';

const useAuthentication = () => {
  const [authorized, setAuthorized] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState(null);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    // get target key/value from URLSearchParams object
    const authCode = params.get('code');

    if (!isEmpty(authCode)) {
      (async () => {
        const user = await oidcManager.signinRedirectCallback();

        setRedirect(user.state || '/');
      })();
      return;
    }

    if (isEmpty(claims))
      (async () => {
        try {
          await oidcManager.clearStaleState();
          const user = await oidcManager.getUser();

          const userClaims =
            user?.access_token && jwt_decode(user.access_token);
          // if user is not empty and the token is not expired set
          // authenticated to true else redirect to sso
          if (
            userClaims?.email &&
            new Date(userClaims?.exp * 1000) > new Date()
          ) {
            setClaims(userClaims);
            setAuthorized(true);
          } else {
            return oidcManager.signinRedirect({ state: window.location.href });
          }
        } catch (error) {
          setAuthorized(false);
        } finally {
          setIsLoading(false);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [authorized, claims, isLoading, redirect];
};

export default useAuthentication;
