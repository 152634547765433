﻿import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import CalculationCard from 'common/components/CalculationCard';

const CalculationCards = ({ calculations, selectedCard, onSelect }) => {
  return (
    <Box
      p={0}
      pb={10}
      m={0}
      styleType="none"
      d="inline-flex"
      role="radiogroup"
      aria-controls="calculationBreakdown"
      aria-labelledby="page-header-subtitle"
    >
      {calculations?.map((card, index) => {
        const {
          formulaName,
          calculationAmount,
          incomeCalculatorType,
          payFrequency,
          isSelected,
          totalIncome,
          isDisabled,
        } = card;
        const value = formulaName + '_' + payFrequency;
        const isChecked =
          selectedCard?.formulaName === formulaName &&
          selectedCard?.incomeCalculatorType === incomeCalculatorType;
        const props = { ...card, isChecked };

        return (
          <Box
            key={index}
            pr={3}
            onClick={() => {
              onSelect(card);
            }}
          >
            <CalculationCard
              title={incomeCalculatorType}
              payFrequency={formulaName}
              isChecked={isSelected}
              isDisabled={isDisabled}
              value={value}
              amount={totalIncome || calculationAmount}
              {...props}
            />
          </Box>
        );
      })}
    </Box>
  );
};

CalculationCards.propTypes = {
  calculations: PropTypes.array,
  selectedCard: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

export default CalculationCards;
