import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { CATEGORY_IDS } from './constants';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-top: 1px;
  margin-right: 10px;
  color: ${(props) =>
    props.color === 'red'
      ? props.theme.colors.red[600]
      : props.theme.colors.green[600]};
  font-size: ${(props) => props.theme.functions.pixelsToRem(20)};
`;

const StyledNotification = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 50%;
  line-height: 150%;
  margin-top: 1px;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.functions.pixelsToRem(12)};
  border: 1px solid ${(props) => props.theme.colors.orange[300]};
  background-color: ${(props) => props.theme.colors.orange[300]};
`;

const Notification = ({
  currentCategoryId,
  numberOfActiveAlerts,
  isVerified,
}) => {
  const state = useSelector((state) => state);

  // Delete Feature Flag after Production is considered stable
  const {
    featureFlagStatus: { data: featureFlag },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  const showClassifyDocNotificationStatus = featureFlag
    ? state?.classifyDocumentsV2?.actionRequired
    : state?.classifyDocuments?.whetherAnyActionIsRequired;
  if (currentCategoryId === CATEGORY_IDS.CLASSIFY) {
    return showClassifyDocNotificationStatus ? (
      <StyledFontAwesomeIcon
        color="red"
        icon={faExclamationCircle}
        data-tracking="Classification pending"
        data-testid="classifiedPendingIcon"
        id="classifiedPendingIcon"
        alt="Classification pending"
      />
    ) : (
      <StyledFontAwesomeIcon
        icon={faCheckCircle}
        data-tracking="Classification completed"
        data-testid="classifiedCompletedIcon"
        id="classifiedCompletedIcon"
        alt="Classification completed"
      />
    );
  }
  return numberOfActiveAlerts > 0 ? (
    <StyledNotification
      data-tracking={`${numberOfActiveAlerts} ${
        numberOfActiveAlerts > 1 ? 'pending conditions' : 'pending condition'
      }`}
    >
      {numberOfActiveAlerts}
    </StyledNotification>
  ) : isVerified ? (
    <StyledFontAwesomeIcon
      data-tracking="Underwriter verified"
      icon={faShieldCheck}
      data-testid="underwriterVerifiedIcon"
      id="underwriterVerifiedIcon"
      alt="Underwriter verified"
    />
  ) : (
    <StyledFontAwesomeIcon
      data-tracking="Broker verified"
      icon={faCheckCircle}
      data-testid="brokerVerifiedIcon"
      id="brokerVerifiedIcon"
      alt="Broker verified"
    />
  );
};

Notification.propTypes = {
  currentCategoryId: PropTypes.number.isRequired,
  numberOfActiveAlerts: PropTypes.number,
  isVerified: PropTypes.bool,
};

export default Notification;
