const RENTAL_INCOME_DATA_TYPES = {
  GET_RENTAL_INCOME_DATA_STARTED: 'GET_RENTAL_INCOME_DATA_STARTED',
  GET_RENTAL_INCOME_DATA_SUCCESS: 'GET_RENTAL_INCOME_DATA_SUCCESS',
  GET_RENTAL_INCOME_DATA_ERROR: 'GET_RENTAL_INCOME_DATA_ERROR',
  SET_RADIO_BUTTON_SELECTED_STATUS: 'SET_RADIO_BUTTON_SELECTED_STATUS',
  SET_1040_CHECKBOX_SELECTED_STATUS: 'SET_1040_CHECKBOX_SELECTED_STATUS',
  SET_ACQUISITION_DATE_IS_INVALID: 'SET_ACQUISITION_DATE_IS_INVALID',
};

export default RENTAL_INCOME_DATA_TYPES;
