import { ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalOverlay = ({ children, ...props }) => {
  return <ChakraModalOverlay {...props}>{children}</ChakraModalOverlay>;
};

export default ModalOverlay;

ModalOverlay.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
