﻿/** Represents a document that a user can provide inputs for */
import CalculationInputFieldDataModel from './CalculationInputFieldDataModel';
import { DocumentNames } from '../enums';

export default class {
  constructor(ci = {}) {
    this.fiscalYear = Object.prototype.hasOwnProperty.call(ci, 'fiscalYear')
      ? ci.fiscalYear
      : 0;

    this.documentTypeId = Object.prototype.hasOwnProperty.call(
      ci,
      'documentTypeId',
    )
      ? ci.documentTypeId
      : 0;

    this.documentId = Object.prototype.hasOwnProperty.call(ci, 'documentId')
      ? ci.documentId
      : 0;

    this.receivedMailItemId = Object.prototype.hasOwnProperty.call(
      ci,
      'receivedMailItemId',
    )
      ? ci.receivedMailItemId
      : 0;

    this.calculationInputData = Object.prototype.hasOwnProperty.call(
      ci,
      'calculationInputData',
    )
      ? this.buildCalculationInputData(ci.calculationInputData)
      : [];
  }

  /** @returns {number} The fiscal year of the document. */
  getFiscalYear() {
    return this.fiscalYear;
  }

  /** @returns {number|null} An ID corresponding to the document in DocHub. */
  getDochubDocumentId() {
    if (this.documentId === 0) return null;
    return this.documentId;
  }

  /** @returns {number} The DocHub document type identifier. */
  getDocumentTypeId() {
    return this.documentTypeId;
  }

  /** @returns {string} The common name of the document. */
  getDocumentName() {
    return DocumentNames[this.getDocumentTypeId()] ?? 'Document';
  }

  /**
   * @returns {[CalculationInputFieldDataModel]} The list of calculation fields.
   */
  getInputFields() {
    return this.calculationInputData;
  }

  /**
   * Get an input field matching the given name.
   * @param fieldId {string|number} The name or id of the field.
   * @returns {CalculationInputFieldDataModel} The input field.
   */
  getInputField(fieldId) {
    if (typeof fieldId === 'number') {
      return this.getInputFields().find(
        (field) => field.getFieldId() === fieldId,
      );
    }
    return this.getInputFields().find((field) => field.getName() === fieldId);
  }

  /**
   * Check if a field should be displayed on the calculator.
   * @param fieldId {number} The id of the field.
   * @returns {boolean} True if the field should be displayed on the calculator.
   */
  containsField(fieldId) {
    return this.getInputFields().some(
      (field) => field.getFieldId() === fieldId,
    );
  }

  buildCalculationInputData(calculationInputData) {
    const PayType = calculationInputData.filter(({ documentFieldName }) => {
      return (
        documentFieldName.toLowerCase &&
        documentFieldName.toLowerCase() === 'paytype'
      );
    });
    return calculationInputData
      .filter(
        ({ documentFieldName }) =>
          documentFieldName.toLowerCase &&
          documentFieldName.toLowerCase() !== 'paytype',
      )
      .concat(
        PayType.length > 0
          ? PayType.find(({ documentFieldValue }) => documentFieldValue) ??
              PayType.find(() => true)
          : [],
      )
      .map((input) => {
        return new CalculationInputFieldDataModel(input);
      });
  }
}
