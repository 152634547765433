import PropTypes from 'prop-types';
import { Table, Tbody, Thead, Tr, Th } from 'common/components/Tables';
import Box from 'common/components/Box';
import GenericTooltip from 'common/components/GenericTooltip';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { ChildrenWidget } from 'lib-node-amb-renderer';

export class KYLTableClass extends ChildrenWidget {
  constructor(props) {
    super(props);
  }
}

export default function KYLTable({ tableHeader, fields, tbodyMaxHeight }) {
  const { renderForm } = useFormApi();
  const totalCols = tableHeader.reduce((acc, cur) => acc + cur.colSpan, 0);
  return (
    <Box
      sx={{
        borderRadius: '4px',
        marginBottom: '18px',
        border: '1px solid #979797 !important',
        background: 'none !important',
        maxHeight: tbodyMaxHeight,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          border: 'none !important',
          backgroundColor: '#575957 !important',
        },
      }}
    >
      <Table sx={{ background: 'none !important' }}>
        <colgroup>
          {Array.from({ length: totalCols }, (_, index) => (
            <col key={index} width={`${100 / totalCols}%`}></col>
          ))}
        </colgroup>
        <Thead sx={{ position: 'sticky', top: 0 }}>
          <Tr>
            {tableHeader.map(({ label, colSpan, helperText }, index) => (
              <Th
                key={label}
                borderLeft={index !== 0 ? '0px ' : undefined}
                colSpan={colSpan}
                sx={{
                  letterSpacing: 'none',
                  textTransform: 'none !important',
                  color: 'black !important',
                  fontSize: '0.875rem',
                  backgroundColor: 'lightgray !important',
                  borderBottom: '0 !important',
                  borderRight: '1px solid #979797 !important',

                  '&:first-child': {
                    borderLeft: '0 !important',
                    borderRadius: '4px 0 0 0',
                    paddingLeft: '1.875rem !important',
                  },

                  '&:last-child': {
                    borderRadius: '0 4px 0 0',
                    borderRight: 'none !important',
                  },
                }}
              >
                {label}
                {helperText && <GenericTooltip label={helperText} />}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((field, index) => {
            field.headerCols = tableHeader;
            field.position = index;
            return renderForm([field]);
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
KYLTable.propTypes = {
  fields: PropTypes.array,
  index: PropTypes.number,
  thStyle: PropTypes.object,
  doesTBodyScroll: PropTypes.bool,
  tbodyMaxHeight: PropTypes.string,
  tableHeader: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      colSpan: PropTypes.number,
    }),
  ),
};
