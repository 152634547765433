import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { HomeActions } from 'pages/Home/redux';
import useGetParentChildrenStates from '../hooks/useGetParentChildrenStates';
import LeftDrawerContext from './LeftDrawerContext';

//click on BOLT then takes them to previous page and shows left gutter
//click on Loan Summary should old show and hide

export function LeftDrawerContextProvider({ children }) {
  const [isLeftDrawerCollapsed, setIsLeftDrawerCollapsed] = useState(true);
  const [isNavigationVisible, setIsNavigationVisible] = useState(true);

  const {
    allFalseStates,
    allTrueStates,
    showParentChildren,
    setShowParentChildren,
  } = useGetParentChildrenStates(isLeftDrawerCollapsed);

  /**
   * handles onClick event for Drawer/Double Arrow button - show or hide parent alert children based on LeftDrawer collapsed state
   * @param {boolean} isLeftDrawerCollapsed
   */
  const handleLeftDrawerCollapseForDrawerBtn = useCallback(
    (isLeftDrawerCollapsed) => {
      //clearing out state
      setShowParentChildren({});

      //if LeftDrawer is open, close it and hide ALL ParentAlert children
      if (!isLeftDrawerCollapsed) {
        setIsLeftDrawerCollapsed(true);
        setShowParentChildren((prev) => {
          return { ...prev, ...allFalseStates };
        });
      } else {
        //if LeftDrawer is closed, open it and show ALL ParentAlert children
        setIsLeftDrawerCollapsed(false);
        setShowParentChildren((prev) => {
          return { ...prev, ...allTrueStates };
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFalseStates, allTrueStates],
  );

  /**
   * handles onClick event for ParentAlert component - show or hide parent children alerts - open or collapse LeftDrawer
   * @param {Object} alert
   * @param {Object} selectedAlert
   * @param {function} dispatch
   */
  const handleLeftDrawerCollapseForParentAlert = useCallback(
    (alert, selectedAlert, dispatch) => {
      const hasGutterItemChildren = alert?.gutterItemChildren?.length > 0;
      const doesParentAlertMatch =
        alert?.ruleId === selectedAlert?.ruleId &&
        alert?.displayTitle === selectedAlert?.displayTitle &&
        alert?.parentRuleSortOrder === selectedAlert?.parentRuleSortOrder;

      //if user has NOT clicked on the currently selected ParentAlert gutter tab AND it has no child alerts, change the page to the selectedAlert (currently selected Parent Alert)
      if (!doesParentAlertMatch && !hasGutterItemChildren) {
        dispatch(HomeActions.setShouldGetNextAlert(false));
        dispatch(HomeActions.setSelectedAlert(alert));
      }

      //if LeftDrawer is closed, open it and show ALL ParentAlert children
      if (isLeftDrawerCollapsed) {
        setIsLeftDrawerCollapsed(false);
        setShowParentChildren((prev) => {
          return { ...prev, ...allTrueStates };
        });
      }

      //if LeftDrawer is open AND there are child alerts, only show that Parent Alert's children
      if (!isLeftDrawerCollapsed && hasGutterItemChildren) {
        setShowParentChildren((prev) => {
          return { ...prev, [alert?.displayTitle]: !prev[alert?.displayTitle] };
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allTrueStates, isLeftDrawerCollapsed],
  );

  /**
   * handles onClick event for ChildAlert component - opens or collapses left drawer
   * @param {Object} alert
   * @param {Object} selectedAlert
   * @param {function} dispatch
   */
  const handleLeftDrawerCollapseForChildAlert = useCallback(
    (alert, selectedAlert, dispatch) => {
      const doesChildAlertMatch =
        alert?.ruleId === selectedAlert?.ruleId &&
        alert?.displayTitle === selectedAlert?.displayTitle &&
        alert?.parentRuleSortOrder === selectedAlert?.parentRuleSortOrder;

      //if user has NOT clicked on the currently selected ChildAlert gutter tab, change the page to the selectedAlert (currently selected Child Alert)
      if (!doesChildAlertMatch) {
        dispatch(HomeActions.setSelectedAlert(alert));
        dispatch(HomeActions.setShouldGetNextAlert(false));
      }
      //if LeftDrawer is closed, open it
      if (isLeftDrawerCollapsed) {
        setIsLeftDrawerCollapsed(false);
      }
    },
    [isLeftDrawerCollapsed],
  );

  /**
   * toggles left drawer collapsed state
   */
  const toggleLeftDrawer = useCallback(() => {
    setIsLeftDrawerCollapsed(!isLeftDrawerCollapsed);
  }, [isLeftDrawerCollapsed]);

  /**
   * collapses left drawer
   */
  const closeLeftDrawer = useCallback(() => {
    setIsLeftDrawerCollapsed(true);
  }, []);

  /**
   * opens left drawer
   */
  const openLeftDrawer = useCallback(() => {
    setIsLeftDrawerCollapsed(false);
  }, []);

  /**
   * displays navigation items and opens left drawer
   */
  const showNavigationItems = useCallback(() => {
    toggleLeftDrawer();
    if (!isNavigationVisible) true;
  }, [isNavigationVisible, toggleLeftDrawer]);

  /**
   * hides navigation items and opens left drawer
   */
  const hideNavigationItems = useCallback(() => {
    if (isLeftDrawerCollapsed) {
      openLeftDrawer();
    }
    setIsNavigationVisible(false);
  }, [isLeftDrawerCollapsed, openLeftDrawer]);

  /**
   * hides rail item drawer and opens left nav if closed - only for non-Link rail tiles
   */
  const handleNavigationVisibilityForLinkTiles = useCallback(() => {
    //clearing out state
    setShowParentChildren({});
    setIsNavigationVisible(true);
    if (isLeftDrawerCollapsed) {
      openLeftDrawer();
    }
  }, [isLeftDrawerCollapsed, openLeftDrawer, setShowParentChildren]);

  return (
    <LeftDrawerContext.Provider
      value={{
        showParentChildren,
        isLeftDrawerCollapsed,
        showNavigationItems,
        hideNavigationItems,
        isNavigationVisible,
        handleNavigationVisibilityForLinkTiles,
        handleLeftDrawerCollapseForDrawerBtn,
        handleLeftDrawerCollapseForParentAlert,
        handleLeftDrawerCollapseForChildAlert,
        toggleLeftDrawer,
        closeLeftDrawer,
        openLeftDrawer,
      }}
    >
      {children}
    </LeftDrawerContext.Provider>
  );
}

LeftDrawerContextProvider.propTypes = {
  children: PropTypes.node,
  alerts: PropTypes.array,
};
