import { EMPLOYMENT_REVIEW } from 'pages/AuditHistory/constants';

// Question History Card
export const orderHistoryByDate = (history) => {
  return history.reduce((accum, item) => {
    const { modifiedDateTime, answer } = item;
    const dateKey = new Date(modifiedDateTime).toLocaleString('en-US');
    if (!accum[dateKey]) {
      accum[dateKey] = [];
    }
    accum[dateKey].push({
      ...item,
      answer: accum[dateKey]?.answer
        ? `${accum[dateKey].answer}, ${answer}`
        : answer,
    });
    return accum;
  }, {});
};

export const createHistoryData = (historyByDate) => {
  return (
    Object.keys(historyByDate)
      // sort the keys (which are dates) in most recent first
      .sort((a, b) => new Date(b) - new Date(a))
      // map a new array of row objects
      .map((key) => {
        const row = [...historyByDate[key]];
        // reduce each row array into an object with the row properties
        return row.reduce(
          (accum, curr) => ({
            ...accum,
            answer: [...accum.answer, curr.answer],
          }),
          { ...row[0], answer: [] },
        );
      })
  );
};

// Audit History Drawer
const substitutePlaceholderText = (questions, questionText, id) => {
  if (questions.length === 0) return questionText;

  const foundReplacement = questions.find((item) => item.id === id);

  if (foundReplacement) {
    const regex = /\{.*?\}+/g; // capture everything within the {{}}
    return questionText.replace(regex, foundReplacement.text);
  }
  return questionText;
};

const swapEntireQuestionText = (questions, id) => {
  const found = questions.find((item) => item.id === id);
  if (found) return found.text;
};

/**
 *
 * @param {[{id: number, text: string}]} questionArray
 * @param {string} questionText
 * @param {number} questionId
 * @param {number} selectedId
 * @returns {string}
 */
export const assignQuestionText = (
  questionArray,
  questionText,
  questionId,
  selectedId,
) => {
  if (!questionArray.length) {
    // no question replacements have been specified, assign default
    return questionText;
  } else if ([EMPLOYMENT_REVIEW].includes(selectedId)) {
    // there are replacements, and they are the entire question
    return swapEntireQuestionText(questionArray, questionId) ?? questionText; // returns undefined if not found
  } else {
    return substitutePlaceholderText(
      // there are replacements, they are specific character sequences ex: {{ replace_me }}
      questionArray,
      questionText,
      questionId,
    );
  }
};
