export default class FormulaResponseDataModel {
  constructor(cr = {}) {
    this.formulaId = Object.prototype.hasOwnProperty.call(cr, 'formulaId')
      ? cr.formulaId
      : null;

    this.formulaName = Object.prototype.hasOwnProperty.call(cr, 'formulaName')
      ? cr.formulaName
      : '';

    this.formula = Object.prototype.hasOwnProperty.call(cr, 'formula')
      ? cr.formula
      : '';
  }

  /**
   * @returns {number} The formula Id
   */
  getFormulaId() {
    return this.formulaId;
  }

  /**
   * @returns {string} The formula's name
   */
  getFormulasName() {
    return this.formulaName;
  }

  /**
   * @returns {string} The formula
   */
  getFormula() {
    return this.formula;
  }
}
