export const RAIL_ITEM_NAMES = {
  BOLT: {
    SHORT: 'BOLT',
    DEFAULT: 'BOLT',
  },
  TEN_ZERO_THREE: {
    SHORT: '1003 Information',
    DEFAULT: '1003 Information',
  },
  KNOW_YOUR_LOAN: {
    SHORT: 'KYL',
    DEFAULT: 'Know Your Loan',
  },

  LOAN_SUMMARY: {
    SHORT: 'Loan Summary',
    DEFAULT: 'Loan Summary',
  },
};
