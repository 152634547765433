import ChakraSelect from 'common/components/Selects/Select';
import Tooltip from 'common/components/Tooltip';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
const SelectWithToolTip = forwardRef(
  (
    {
      name,
      options,
      input,
      label,
      onClick,
      placeholder,
      isDisabled,
      sx,
      invalid,
      onChange,
      onBlur,
      onFocus,
      dataTestId,
    },
    ref,
  ) => {
    const findLabelForTooltip = options.find((m) => m.value === input.value);

    return (
      <Tooltip
        placement="top"
        textAlign="center"
        color="black"
        bg="gray.200"
        borderRadius="2px"
        fontSize="12px"
        fontWeight="normal"
        p={2}
        label={findLabelForTooltip?.label}
      >
        <span role="tooltip" aria-label={label ?? 'Information'}>
          <ChakraSelect
            name={name}
            onClick={onClick}
            placeholder={placeholder}
            isInvalid={!!invalid}
            isDisabled={isDisabled}
            data-testid={dataTestId}
            {...input}
            onChange={(e) => {
              input.onChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              input.onBlur(e);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              input.onFocus(e);
              onFocus?.(e);
            }}
            bg="white"
            whiteSpace={sx?.whiteSpace ? sx?.whiteSpace : 'break-spaces'}
            minHeight={'2.5rem'}
            height={'auto'}
            sx={{ ...sx }}
            {...(input.value === '' && { color: 'gray' })}
            ref={ref}
          >
            {!!options.length &&
              options.map(({ label, value }, idx) => (
                <option
                  key={`${name}-${idx}`}
                  value={value}
                  data-testId={`${dataTestId}-option`}
                >
                  {label}
                </option>
              ))}
          </ChakraSelect>
        </span>
      </Tooltip>
    );
  },
);

SelectWithToolTip.displayName = 'SelectWithToolTip';

export default SelectWithToolTip;

SelectWithToolTip.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  input: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  sx: PropTypes.object,
  invalid: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  dataTestId: PropTypes.string,
};
