﻿import PropTypes from 'prop-types';
import ChakraAlert from 'common/components/Alerts/Alert/Alert';
import AlertDescription from 'common/components/Alerts/AlertDescription';
import AlertTitle from 'common/components/Alerts/AlertTitle';
import Box from 'common/components/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { ALERT_SEVERITY } from '../../../constants';

const Alert = ({ title, status, children, ...props }) => {
  let alertColor, alertIcon, alertBackground, borderColor;
  switch (status) {
    case 1:
    case 'info':
      status = 'info';
      alertColor = 'blue.500';
      alertBackground = 'blue.50';
      alertIcon = faInfoCircle;
      title = title || 'Tip:';
      break;
    case 2:
    case 'warning':
      status = 'warning';
      alertColor = 'orange.600';
      alertBackground = 'orange.100';
      alertIcon = faExclamationTriangle;
      title = title || 'New Condition:';
      break;
    case 'error':
      status = 'error';
      alertColor = 'red.600';
      alertBackground = 'red.100';
      alertIcon = faExclamationCircle;
      title = title || 'Error:';
      break;
    case 'success':
      status = 'success';
      alertColor = 'green.600';
      alertBackground = 'green.50';
      alertIcon = faCheckCircle;
      title = title || 'All Set:';
      break;
    case 'extractionFailed':
    case ALERT_SEVERITY.ATTENTION:
      status = undefined;
      alertBackground = 'orange.300';
      borderColor = 'orange.900';
      alertIcon = faFlag;
      title = title || 'Manual Review:';
      break;
    default:
      status = 'info';
      alertColor = 'blue.600';
      alertBackground = 'blue.50';
      alertIcon = faInfoCircle;
      title = title || 'Tip:';
      break;
  }
  return (
    <ChakraAlert
      border="1px solid"
      borderColor={status === 'extractionFailed' ? borderColor : alertColor}
      background={alertBackground}
      borderRadius="4px"
      {...props}
      status={status}
    >
      <Box color={`${alertColor}`} mr={2}>
        <FontAwesomeIcon icon={alertIcon} />
      </Box>
      {title && (
        <AlertTitle mr={4} fontSize="sm" color={alertColor} whiteSpace="nowrap">
          {title}
        </AlertTitle>
      )}
      <AlertDescription fontSize="sm" lineHeight="24px">
        {children}
      </AlertDescription>
    </ChakraAlert>
  );
};
Alert.propTypes = {
  /** The alert title, or undefined for a default value based on the status type. */
  title: PropTypes.string,
  /** The alert text to render. */
  children: PropTypes.any,
  /** The alert status type. */
  status: PropTypes.oneOf(['warning', 'info', 'error', 'success']), // this is not required, the switch has a default case
};
export default Alert;
