import { NumberInput as ChakraNumberInput } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const NumberInput = ({ children, ...props }) => {
  return <ChakraNumberInput {...props}>{children}</ChakraNumberInput>;
};

export default NumberInput;

NumberInput.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
