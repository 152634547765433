import { API_STATUS } from 'common/constants';
import TYPES from './types';

//define initialState
const [
  // eslint-disable-next-line no-unused-vars
  _,
  obfuscatedLenderDatabaseId,
  obfuscatedLoanRecordId,
  attachDocs,
] = window.location.pathname.split('/');

/* URL obfuscatedLoanIdentifier */
export const urlInitialState = {
  obfuscatedLoanIdentifier: {
    obfuscatedLenderDatabaseId: parseInt(obfuscatedLenderDatabaseId),
    obfuscatedLoanRecordId: parseInt(obfuscatedLoanRecordId),
  },
  attachDocs: attachDocs !== '' && attachDocs === 'true',
};

export const url = (state = urlInitialState, { type, payload }) => {
  switch (type) {
    case TYPES.URL_SET_LOAN_PARAMS:
      return {
        ...state,
        obfuscatedLoanIdentifier: payload,
      };
    case TYPES.URL_SET_ATTACH_PARAMS:
      return {
        ...state,
        attachDocs: payload,
      };
    case TYPES.URL_SET_ALL_PARAM:
      return {
        obfuscatedLoanIdentifier: payload.obfuscatedLoanIdentifier,
        attachDocs: payload.attachDocs,
      };
    default:
      return state;
  }
};

/* Loan Info */
const loanInfoInitialState = {
  data: null,
  status: null,
  error: null,
};

export const loanInfo = (state = loanInfoInitialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_LOAN_INFO_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case TYPES.GET_LOAN_INFO_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: payload,
      };
    case TYPES.GET_LOAN_INFO_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
      };
    default:
      return state;
  }
};

/* Validation Questions */
export const validationQuestions = function (
  state = {
    save: {
      status: null,
      error: null,
    },
  },
  action,
) {
  switch (action.type) {
    case TYPES.SAVE_ANSWERS_STARTED:
      return {
        ...state,
        save: {
          status: API_STATUS.UPDATING,
          error: null,
        },
      };

    case TYPES.SAVE_ANSWERS_SUCCESS:
      return {
        ...state,
        save: {
          status: API_STATUS.SUCCESS,
          error: null,
        },
      };

    case TYPES.SAVE_ANSWERS_ERROR:
      return {
        ...state,
        save: {
          status: API_STATUS.ERROR,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

/* Feature Flag  */
const featureFlagInitialState = {
  IncludeAsset: false,
  IncludeREO: false,
  IncludeBorrower: false,
  IncludeLiability: false,
  IncludeKnowYourLoan: false,
  IncludeIncome: false,
  IsREO1003AndPropertyEnabled: false,
  isAuditHistoryRouteEnabled: false,
  showSummaryTile: false,
  IsQuestionLibraryEnabled: false,
};

export function featureFlags(
  state = featureFlagInitialState,
  { type, payload },
) {
  switch (type) {
    case TYPES.SET_FEATURE_FLAG:
      // eslint-disable-next-line
      const { IsRailNavSummary, IncludeKnowYourLoan, ...restFlags } = payload;
      // eslint-disable-next-line
      const includeSummaryPage = Object.keys(restFlags)
        .filter((k) => k.includes('Include'))
        .some((k) => !!restFlags[k]);

      return {
        ...state,
        ...restFlags,
        IncludeKnowYourLoan,
        showSummaryTile: includeSummaryPage,
      };
    default:
      return state;
  }
}

/* PDF Citation */
const pdfCitationInitialState = {
  documentType: '',
  text: '',
  documentId: 0,
  boundingBoxes: [],
};

export function pdfCitation(
  state = pdfCitationInitialState,
  { type, payload },
) {
  switch (type) {
    case TYPES.SET_PDF_CITATION: {
      const { documentType, text, documentId, boundingBoxes } = payload;

      return {
        ...state,
        documentType,
        text,
        documentId,
        boundingBoxes,
      };
    }
    default:
      return state;
  }
}

const upsertOrDeleteInitialState = {
  loanSummaryUpsertOrDelete: false,
};

export const loanSummaryUpsertOrDelete = (
  state = upsertOrDeleteInitialState,
  { type, payload },
) => {
  switch (type) {
    case TYPES.SET_LOAN_SUMMARY_UPSERT_OR_DELETE:
      return {
        ...state,
        loanSummaryUpsertOrDelete: payload,
      };
    default:
      return state;
  }
};

export const pdfDocumentId = (state = { documentId: 0 }, { type, payload }) => {
  switch (type) {
    case TYPES.SET_PDF_DOCUMENT_ID:
      return {
        documentId: payload,
      };
    default:
      return state;
  }
};
