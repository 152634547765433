import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatCurrency } from 'common/util/format';
import { LOAN_PRODUCT_TYPE } from 'common/constants';
import theme from 'theme/';

const StyledBox = styled(Box)`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

const StyledInfoText = styled(Text)`
  text-decoration: underline;
  text-decoration-color: ${theme?.colors.gray[400]};
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledInfoValue = styled(Text)`
  color: ${theme?.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledInfoItem = ({ label, value }) => (
  <StyledBox>
    <StyledInfoText>{label}</StyledInfoText>
    <StyledInfoValue>{value}</StyledInfoValue>
  </StyledBox>
);
StyledInfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function TotalAssetInfoWindow({ assetsInfo, loanProductType }) {
  const {
    cashToFromBorrower,
    totalFundsAvailable,
    totalFundsAvailableFHA,
    largeDepositThreshold,
    totalReservesRequired,
    minimumInvestment,
    actualBorrowerInvestment,
  } = assetsInfo;

  const isLoanFHA = loanProductType === LOAN_PRODUCT_TYPE.FHA;
  const isAssetsInfoReady = assetsInfo && Object.keys(assetsInfo).length > 0;

  return isAssetsInfoReady ? (
    <Flex
      justify={'center'}
      align={'center'}
      wrap={'wrap'}
      border={`0px solid ${theme?.colors.blue[600]}`}
      borderTopWidth={'2px'}
      borderBottomWidth={'2px'}
      paddingBottom={'1rem'}
      width={'100%'}
      mb={'1rem'}
    >
      <StyledInfoItem
        label="Cash to/from Borrower:"
        value={formatCurrency(cashToFromBorrower ?? 0)}
      />
      <StyledInfoItem
        label={isLoanFHA ? 'Total Funds Available' : 'Max Funds Available'}
        value={formatCurrency(
          isLoanFHA ? totalFundsAvailableFHA ?? 0 : totalFundsAvailable ?? 0,
        )}
      />
      <StyledInfoItem
        label="Large Deposit Threshold"
        value={formatCurrency(largeDepositThreshold ?? 0)}
      />
      <StyledInfoItem
        label="Total Required Reserves"
        value={formatCurrency(totalReservesRequired ?? 0)}
      />
      {minimumInvestment > 0 && (
        <>
          <StyledInfoItem
            label="Required Minimum Investment"
            value={formatCurrency(minimumInvestment ?? 0)}
          />
          <StyledInfoItem
            label="Actual Borrower Investment"
            value={formatCurrency(actualBorrowerInvestment ?? 0)}
          />
        </>
      )}
    </Flex>
  ) : null;
}

TotalAssetInfoWindow.propTypes = {
  assetsInfo: PropTypes.object,
  loanProductType: PropTypes.number,
};
