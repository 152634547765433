import PropTypes from 'prop-types';
import Button from 'common/components/Button';

const stateProp = PropTypes.shape({
  label: PropTypes.string,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  icon: PropTypes.string,
  sx: PropTypes.object,
});

export default function ToggleButton(props) {
  const {
    name,
    label,
    defaultValue = false,
    startIcon,
    endIcon,
    icon,
    sx,
  } = props;
  const value = defaultValue || false;
  const toPass = {
    name,
    label,
    startIcon,
    endIcon,
    icon,
    sx: {
      ...(sx || {}),
    },
    role: 'switch',
    ['aria-checked']: value,
  };

  return <Button {...toPass} />;
}
ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  on: stateProp.isRequired,
  off: stateProp.isRequired,
  sx: PropTypes.object,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};
