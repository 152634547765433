import { useEffect, useState, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/componentMapper/Text';
import HighlightArea from './HighlightArea';
import propTypes from 'common/propTypes';

export const SectionTitle = ({ sx, children }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Text sx={sx} variant="h4" align="center" mt={2} pb={1}>
      {children}
    </Text>
  </Box>
);
SectionTitle.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.any,
};

function Pages({
  pdf,
  scale,
  highlights,
  pageRotations,
  setCalculatedPageRotations,
  onRenderSuccess,
  onLoadSuccess,
  onLoadError,
  onRenderError,
  setPageRef,
  clearPageRef,
  citations,
  showHighLight,
}) {
  const [pageCount, setPageCount] = useState(0);
  const [key, setKey] = useState();
  const calculatedPageRotationRef = useRef([]);
  const overlayStyle = {
    parent: {
      position: 'relative',
      width: 'auto',
      height: 'auto',
      marginBottom: '0.5rem',
    },
    child: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
      pointerEvents: 'none',
    },
  };

  useEffect(() => {
    calculatedPageRotationRef.current = [];
    setCalculatedPageRotations([]);
    const key = crypto.randomUUID();
    setKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  function getCalculatedPageRotation(number) {
    var rot = 0;
    if (
      calculatedPageRotationRef.current != null &&
      calculatedPageRotationRef.current.length > 0
    ) {
      var page = calculatedPageRotationRef.current.find(
        (pr) => pr.pageNumber == number,
      );
      if (page != null) {
        rot = page.rotationAngle;
      }
    }
    return rot;
  }

  function calculatePageRotation(page) {
    var originalRotation = page.rotate == undefined ? 0 : page.rotate;
    var AddedRotation = 0;
    if (pageRotations != null && pageRotations.length > 0) {
      var pageRot = pageRotations.find(
        (pr) => pr.pageNumber == page.pageNumber,
      );
      if (pageRot != null) {
        AddedRotation = pageRot.rotationAngle;
      } else {
        const otherPageRot = pageRotations.find(
          (pr) => pr !== null && pr !== undefined,
        );
        if (otherPageRot != null) {
          AddedRotation = otherPageRot.rotationAngle;
        }
      }
    }
    var calculatedRotation = AddedRotation + originalRotation;
    var calcPage = calculatedPageRotationRef.current.find(
      (pr) => pr.pageNumber == page.pageNumber,
    );
    if (calcPage != null) {
      calcPage.rotationAngle = calculatedRotation;
    } else {
      calculatedPageRotationRef.current.push({
        pageNumber: page.pageNumber,
        rotationAngle: calculatedRotation,
      });
    }
    setCalculatedPageRotations(calculatedPageRotationRef.current);
  }

  function createPages(n) {
    var pages = [];
    if (clearPageRef) {
      clearPageRef();
    }
    for (let i = 1; i <= n; i++) {
      pages.push(
        <Box
          key={i}
          sx={{ pb: '2px', width: 'fit-content' }}
          ref={(element) => {
            setPageRef(i, element);
          }}
        >
          <div style={overlayStyle.parent}>
            <Page
              renderTextLayer={false}
              onRenderSuccess={onRenderSuccess}
              renderAnnotationLayer={false}
              pageNumber={i}
              scale={scale}
              onLoadSuccess={handlePageLoadSuccess}
              rotate={getCalculatedPageRotation(i)}
            />

            {citations?.length > 0 && (
              <div style={overlayStyle.child}>
                <HighlightArea
                  highlights={highlights}
                  citations={citations}
                  pageNumber={i}
                  showHighLight={showHighLight}
                />
              </div>
            )}
          </div>
        </Box>,
      );
    }
    return pages;
  }

  function handlePageLoadSuccess(page) {
    calculatePageRotation(page);
  }

  function handleDocumentLoadSuccess(doc) {
    setPageCount(doc.numPages);
    onLoadSuccess(doc);
  }

  return (
    <Box sx={{ width: 'fit-content', margin: 'auto' }}>
      <Document
        key={key}
        file={pdf}
        // error={<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', pt:10}}><DocErrorSvg style={{ width: 'auto', height: '125px'}}/> <SectionTitle sx={{color: 'white'}}>There was a problem with this document</SectionTitle><Typography color="white">Failed loading that document. If the issue persists, please submit a ticket.</Typography></Box>}
        loading={null}
        onRenderError={onRenderError}
        onLoadError={onLoadError}
        onLoadSuccess={handleDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        noData={null}
        // onPassword={<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', pt:10}}><RedLockSvg/> <SectionTitle sx={{color: 'white'}}>This document is password protected</SectionTitle><Typography color="white">Please reach out to the borrower to upload a non-password protected document.</Typography></Box>}
      >
        {createPages(pageCount)}
      </Document>
    </Box>
  );
}

export default Pages;

Pages.propTypes = {
  pdf: PropTypes.any,
  scale: PropTypes.any,
  highlights: PropTypes.any,
  pageRotations: PropTypes.any,
  pageNumber: PropTypes.number,
  setCalculatedPageRotations: PropTypes.any,
  onRenderSuccess: PropTypes.any,
  onLoadSuccess: PropTypes.any,
  onLoadError: PropTypes.any,
  onRenderError: PropTypes.any,
  setPageRef: PropTypes.any,
  clearPageRef: PropTypes.any,
  citations: PropTypes.arrayOf(propTypes.citation),
};
