import { MenuList as ChakraMenuList } from '@chakra-ui/react';
import Box from 'common/components/Box';

const MenuList = ({ children, ...props }) => {
  return <ChakraMenuList {...props}>{children}</ChakraMenuList>;
};

export default MenuList;

MenuList.propTypes = Box.propTypes;
