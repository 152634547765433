export default class AddressDataModel {
  constructor(b = {}) {
    this.addressLine1 = Object.prototype.hasOwnProperty.call(b, 'addressLine1')
      ? b.addressLine1
      : '';

    this.state = Object.prototype.hasOwnProperty.call(b, 'state')
      ? b.state
      : '';

    this.city = Object.prototype.hasOwnProperty.call(b, 'city') ? b.city : '';

    this.zipCode = Object.prototype.hasOwnProperty.call(b, 'zipCode')
      ? b.zipCode
      : '';

    this.unit = Object.prototype.hasOwnProperty.call(b, 'unit') ? b.unit : '';
  }

  /**
   * @returns {string} The AddressL Line 1.
   */
  getAddressLine() {
    return this.addressLine1;
  }

  /**
   * @returns {string} state name.
   */
  getState() {
    return this.state;
  }

  /**
   * @returns {string} city Name.
   */
  getCity() {
    return this.city;
  }

  /**
   * @returns {string} zipcode.
   */
  getZipCode() {
    return this.zipCode;
  }

  /**
   * @returns {string} unit number.
   */
  getUnitNumber() {
    return this.unit;
  }
}
