import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Center from 'common/components/Center';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';

import GlobalLayout from 'common/components/GlobalLayout';

function FallbackScreen({ resetError }) {
  function handleNext() {
    resetError();
    return true;
  }

  return (
    <GlobalLayout
      primaryButton={{
        name: 'Continue',
        onSubmit: handleNext,
      }}
      secondaryButton={{
        name: 'Reload',
        onSubmit: () => resetError(),
      }}
      customHeader={<Box height="72px" />}
    >
      <Box data-testid="fallback">
        <Flex flexDirection="column" mt="100px">
          <Center>
            <Text fontSize="6xl" color="blue.700" fontWeight="bold">
              OOPS!
            </Text>
          </Center>
          <Center>
            <Text fontSize="1xl" fontWeight="semibold">
              An unexpected error has occurred.
            </Text>
          </Center>
          <Center>
            <Text fontSize="1xl" fontWeight="semibold">
              Please try again, or submit a CR for additional assistance if the
              error persists.
            </Text>
          </Center>
        </Flex>
      </Box>
    </GlobalLayout>
  );
}
FallbackScreen.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default FallbackScreen;
