import { propsMapper } from './propsMapper';

const gridPropsRegex =
  /^(columns|columnSpacing|direction|lg|md|rowSpacing|sm|spacing|wrap|xl|xs|zeroMinWidth)$/;

export const gridMapper = (props) => {
  const gridProps = {};

  Object.entries(props).forEach(([key, value]) => {
    if (gridPropsRegex.test(key)) {
      gridProps[key] = value;
      delete props[key];
    }
  });

  const haveGridProps = Object.keys(gridProps).length > 0;

  const mappedProps = propsMapper(props);
  const gridContainerProps = haveGridProps
    ? { ...mappedProps, ...gridProps }
    : { display: 'grid', ...mappedProps };

  return gridContainerProps;
};
