import { ModalBody as ChakraModalBody } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalBody = ({ children, ...props }) => {
  return <ChakraModalBody {...props}>{children}</ChakraModalBody>;
};

export default ModalBody;

ModalBody.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
