import { Switch as ChakraSwitch } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Switch = ({ ...props }) => {
  return <ChakraSwitch {...props} />;
};

export default Switch;

Switch.propTypes = {
  props: PropTypes.object,
};
