import { useState } from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import aion from 'common/util/icons/AIOn.svg';
import aioff from 'common/util/icons/AIOff.svg';

const MaterialUISwitchAI = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 3,
    padding: 0,
    transform: 'translateX(0)',
    '&.Mui-checked': {
      color: theme.palette.blue[600],
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${aion})`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.blue[600],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${aioff})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 10,
  },
}));

export default function AISwitchToggle() {
  const [isAISwitchOn, setAISwitchOn] = useState();

  const handleSwitchChange = (e) => {
    const switchState = e.target.checked;
    setAISwitchOn(switchState);
    sessionStorage.setItem('isAISwitchOn', switchState);
    window.postMessage({ isAISwitchOn: switchState }, '*');
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <MaterialUISwitchAI
            inputProps={{ role: 'switch' }}
            sx={{ m: 1, marginLeft: '1rem' }}
            checked={isAISwitchOn}
            onChange={handleSwitchChange}
          />
        }
      />
    </FormGroup>
  );
}
