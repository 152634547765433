import { Link as ChakraLink } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Link = ({ children, ...props }) => {
  return <ChakraLink {...props}>{children}</ChakraLink>;
};

export default Link;

Link.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
