import { combineReducers } from 'redux/es/redux.mjs';
import TYPES from './types';
import { API_STATUS } from 'common/constants';
import {
  setCreditValidationData,
  setCreditValidationResultsData,
} from '../model/CreditController';
import LiabilitiesData from '../model/LiabilitiesDataModel';

export const setLiabilitiesData = (data) =>
  data?.map((liabilitiy) => new LiabilitiesData(liabilitiy));
export const initialState = {
  status: null,
  error: null,
  results: setCreditValidationResultsData(),
  refData: setCreditValidationData(),
  liabilities: {
    status: null,
    error: null,
    data: [],
  },
  remainingTerms: {
    status: null,
    error: null,
    data: null,
  },
  creditReports: {
    status: null,
    error: null,
    data: null,
  },
  liabilitiesVerifyData: {
    status: null,
    error: null,
    data: {
      liabilities: [],
      internalTradelines: [],
    },
  },
  liabilitiesAssociateData: {
    status: null,
    error: null,
    data: {
      liabilities: [],
      availableREOAddresses: [],
    },
  },
};

const saveCreditData = (
  state = {
    status: null,
    error: null,
  },
  { type, error },
) => {
  switch (type) {
    case TYPES.UPDATE_LIABILITIES_VERIFY_DATA_STARTED:
      return {
        ...state,
        error: null,
        status: API_STATUS.UPDATING,
      };
    case TYPES.UPDATE_LIABILITIES_VERIFY_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        status: API_STATUS.SUCCESS,
      };
    case TYPES.UPDATE_LIABILITIES_VERIFY_DATA_ERROR:
      return {
        ...state,
        error: error,
        status: API_STATUS.ERROR,
      };
    case TYPES.CLEAR_SAVE_CREDIT_DATA:
      return {
        ...state,
        error: null,
        status: null,
      };
    default:
      return state;
  }
};

const creditReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.UPDATE_SAVE_CREDIT_ANSWERS_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case TYPES.UPDATE_SAVE_CREDIT_ANSWERS_SUCCESS:
      return {
        ...state,
        status: null,
      };
    case TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_SUCCESS:
      return {
        ...state,
        results: setCreditValidationResultsData(payload.result),
        status: API_STATUS.SUCCESS,
      };
    case TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_ERROR:
      return {
        ...state,
        error: payload,
        status: null,
      };
    case TYPES.UPDATE_CREDIT_INFO_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.UPDATE_CREDIT_INFO_SUCCESS:
      return {
        ...state,
        refData: setCreditValidationData(payload),
        status: null,
      };
    case TYPES.UPDATE_CREDIT_INFO_ERROR:
      return {
        ...state,
        error: payload,
        status: null,
      };
    case TYPES.GET_CREDIT_REPORT_STARTED:
      return {
        ...state,
        creditReports: {
          status: API_STATUS.GETTING,
          data: null,
          error: null,
        },
      };
    case TYPES.GET_CREDIT_REPORT_SUCCESS:
      return {
        ...state,
        creditReports: {
          status: API_STATUS.SUCCESS,
          data: payload,
        },
      };
    case TYPES.GET_CREDIT_REPORT_ERROR:
      return {
        ...state,
        creditReports: {
          status: API_STATUS.ERROR,
          error: payload,
        },
      };
    case TYPES.GET_LIABILITIES_STARTED:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          error: null,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.GET_LIABILITIES_SUCCESS:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          data: setLiabilitiesData(payload),
          error: null,
          status: API_STATUS.SUCCESS,
        },
      };
    case TYPES.GET_LIABILITIES_ERROR:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          error: payload,
          status: API_STATUS.ERROR,
        },
      };

    case TYPES.UPDATE_LIABILITIES_STARTED:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          error: null,
          status: API_STATUS.UPDATING,
        },
      };
    case TYPES.UPDATE_LIABILITIES_SUCCESS:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          error: null,
          status: API_STATUS.SUCCESS,
          data: [
            ...state.liabilities.data.filter((liability) => {
              return liability?.debtId !== payload.debtId;
            }),
            new LiabilitiesData(payload),
          ],
        },
      };
    case TYPES.UPDATE_LIABILITIES_ERROR:
      return {
        ...state,
        liabilities: {
          ...state.liabilities,
          error: payload,
          status: API_STATUS.ERROR,
        },
      };

    case TYPES.GET_LIABILITIES_VERIFY_DATA_STARTED:
      return {
        ...state,
        liabilitiesVerifyData: {
          status: API_STATUS.GETTING,
          data: {
            liabilities: [],
            internalTradelines: [],
          },
          error: null,
        },
      };
    case TYPES.GET_LIABILITIES_VERIFY_DATA_SUCCESS:
      return {
        ...state,
        liabilitiesVerifyData: {
          status: API_STATUS.SUCCESS,
          data: payload,
          error: null,
        },
      };
    case TYPES.GET_LIABILITIES_VERIFY_DATA_ERROR:
      return {
        ...state,
        liabilitiesVerifyData: {
          ...state.liabilitiesVerifyData,
          status: API_STATUS.ERROR,
          error: payload,
        },
      };
    case TYPES.GET_LIABILITIES_ASSOCIATE_DATA_STARTED:
      return {
        ...state,
        liabilitiesAssociateData: {
          status: API_STATUS.GETTING,
          data: {
            liabilities: [],
            availableREOAddresses: [],
          },
          error: null,
        },
      };
    case TYPES.GET_LIABILITIES_ASSOCIATE_DATA_SUCCESS:
      return {
        ...state,
        liabilitiesAssociateData: {
          status: API_STATUS.SUCCESS,
          data: payload,
          error: null,
        },
      };
    case TYPES.GET_LIABILITIES_ASSOCIATE_DATA_ERROR:
      return {
        ...state,
        liabilitiesAssociateData: {
          ...state.liabilitiesAssociateData,
          status: API_STATUS.ERROR,
          error: payload,
        },
      };
    case TYPES.RETRIEVE_PDF_SUCCESS:
      return {
        ...state,
        status: API_STATUS.DONE,
        error: null,
        documentPdfUrl: payload,
      };
    case TYPES.RETRIEVE_PDF_ERROR:
      return {
        ...state,
        error: payload,
        isLoadingPdf: false,
      };
    case TYPES.SET_IS_LOADING_DOCUMENT_LINK:
      return {
        ...state,
        isLoadingPdf: payload,
      };

    case TYPES.CALCULATE_REMAINING_TERMS_RESET:
      return {
        ...state,
        remainingTerms: {
          ...initialState.remainingTerms,
        },
      };
    case TYPES.CALCULATE_REMAINING_TERMS_STARTED:
      return {
        ...state,
        remainingTerms: {
          ...state.remainingTerms,
          status: API_STATUS.GETTING,
          data: null,
          error: null,
        },
      };
    case TYPES.CALCULATE_REMAINING_TERMS_SUCCESS:
      return {
        ...state,
        remainingTerms: {
          ...state.remainingTerms,
          status: API_STATUS.SUCCESS,
          data: payload,
        },
      };
    case TYPES.CALCULATE_REMAINING_TERMS_ERROR: {
      return {
        ...state,
        remainingTerms: {
          ...state.remainingTerms,
          status: API_STATUS.ERROR,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  creditData: creditReducer,
  saveCreditData,
});
export default combinedReducer;
