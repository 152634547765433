/* c8 ignore next */
export const CITIZENSHIP_RESOLUTION_TEXT = {
  YES: 'Yes',
  NO: 'No',
};
export const CITIZENSHIP_API_STATUS = {
  GETTING: 'GETTING',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_ERROR: 'GET_ERROR',
  UPDATING: 'UPDATING',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',
};

export const INITIAL_CITIZENSHIP_INFO = {
  citizenshipData: [],
};
