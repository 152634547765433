export default class EmploymentIncome {
  constructor(b = {}) {
    this.employerName = Object.prototype.hasOwnProperty.call(b, 'employerName')
      ? b.employerName
      : '';

    this.incomeType = Object.prototype.hasOwnProperty.call(b, 'incomeType')
      ? b.incomeType
      : '';

    this.isCurrentEmployment = Object.prototype.hasOwnProperty.call(
      b,
      'isCurrentEmployment',
    )
      ? b.isCurrentEmployment
      : false;
  }

  /**
   * @returns {string} The Employer name.
   */
  getEmployerName() {
    return this.employerName;
  }

  /**
   * @returns {string} employer income type.
   */
  getIncomeType() {
    return this.incomeType;
  }

  /**
   * @returns {bool} The customer's current employment.
   */
  getCurrentEmploymentStatus() {
    return this.isCurrentEmployment;
  }
}
