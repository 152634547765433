import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';

export default function ScrollIntoViewContainer({
  valueToWatch,
  testId,
  sx = { width: '100%' },
  children,
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (valueToWatch && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [valueToWatch]);

  return (
    <div ref={containerRef} id={testId} style={sx}>
      {children}
    </div>
  );
}

ScrollIntoViewContainer.propTypes = {
  valueToWatch: PropTypes.any,
  testId: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node,
};
