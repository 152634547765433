import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormControl from 'common/components/Forms/FormControl';
import FormErrorMessage from 'common/components/Forms/FormErrorMessage';
import FormLabel from 'common/components/Forms/FormLabel';
import Text from 'common/components/Texts/Text';
import { Controller } from 'react-hook-form';
import { sortBy } from 'lodash';

// Local
import CustomSelect from 'common/components/CustomSelect';
import { CLASSIFICATION, DROPDOWN_FIELD, DROPDOWN_LABEL } from './constants';
import { FORM_MESSAGES } from 'common/constants/index';

const ClassifyDynamicFormContent = ({ rhfProps }) => {
  const { clearErrors, control, errors, setValue, watch } = rhfProps;

  /**
   * -------------------- *
   * Local & global state *
   * -------------------- *
   */

  const [currentDocumentTypeAndCategory, setCurrentDocumentTypeAndCategory] =
    useState(null);

  // redux
  const {
    selectedDocument,
    selectedDocumentAssociations,
    documentTypesAndCategories: { data: documentTypesAndCategories },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  // helpers
  const getDropDownOptions = (options) => {
    return options?.map((option) => ({
      label: option.associationDetails,
      value: option,
    }));
  };

  /**
   * ------- *
   * Effects *
   * ------- *
   */

  // watch for documentType change
  const currentDocumentType = watch(CLASSIFICATION.docType);
  // get the correct documentTypesAndCategories based on documentType
  useEffect(() => {
    if (currentDocumentType?.value && selectedDocument) {
      const current = documentTypesAndCategories?.find((documentType) => {
        return documentType.documentTypeId === +currentDocumentType.value;
      });
      setCurrentDocumentTypeAndCategory(current);
      /**
       * DO NOT remove this: clearErrors every time the
       * dynamic dropdown changes based on documentType
       */
      clearErrors();
    } else {
      setCurrentDocumentTypeAndCategory([]);
    }
  }, [
    clearErrors,
    documentTypesAndCategories,
    selectedDocument,
    /**
     * DO NOT remove this: currentDocumentType decides the currentDocumentTypeAndCategory
     * which populates dynamic dropdowns and options
     */
    currentDocumentType,
  ]);

  const getDefaultValues = useCallback(
    (dropDownName, previouslySelectedOptions, dropDownFields) => {
      const sortedPreviouslySelectedOptions = sortBy(
        previouslySelectedOptions,
        DROPDOWN_FIELD.associationDetails,
      );
      const allCurrentDropDownOptions = sortBy(
        dropDownFields?.find((field) => field.dropDownName === dropDownName)
          ?.options,
        DROPDOWN_FIELD.associationDetails,
      );
      let selectedOptions = [];
      if (allCurrentDropDownOptions?.length) {
        let selectedOptionsIndex = 0;
        let allCurrentDropDownOptionsIndex = 0;
        while (
          selectedOptionsIndex < sortedPreviouslySelectedOptions?.length &&
          allCurrentDropDownOptionsIndex < allCurrentDropDownOptions.length
        ) {
          if (
            allCurrentDropDownOptions[allCurrentDropDownOptionsIndex]
              .associationId ===
              sortedPreviouslySelectedOptions[selectedOptionsIndex]
                .associationId &&
            allCurrentDropDownOptions[allCurrentDropDownOptionsIndex]
              .associationDetails ===
              sortedPreviouslySelectedOptions[selectedOptionsIndex]
                .associationDetails
          ) {
            selectedOptions = [
              ...selectedOptions,
              allCurrentDropDownOptions[allCurrentDropDownOptionsIndex],
            ];
            selectedOptionsIndex += 1;
            allCurrentDropDownOptionsIndex += 1;
          } else {
            allCurrentDropDownOptionsIndex += 1;
          }
        }
      }
      return getDropDownOptions(selectedOptions);
    },
    [],
  );

  // when getDocumentAssociations is resolved, set already saved values if any
  useEffect(() => {
    if (selectedDocumentAssociations) {
      for (const [dropDownName, previouslySelectedOptions] of Object.entries(
        selectedDocumentAssociations,
      )) {
        const values = getDefaultValues(
          dropDownName,
          previouslySelectedOptions,
          currentDocumentTypeAndCategory?.dropDownFields,
        );
        setValue(dropDownName, values?.length ? values : null);
      }
    }
  }, [
    setValue,
    selectedDocumentAssociations,
    currentDocumentTypeAndCategory?.dropDownFields,
    getDefaultValues,
  ]);

  // When documentType is changed clear doc associations
  // When isDefaultNA populate NA
  useEffect(() => {
    if (
      currentDocumentType &&
      currentDocumentTypeAndCategory?.dropDownFields &&
      selectedDocument?.documentTypeName !== currentDocumentType?.label
    ) {
      for (let dropDown of currentDocumentTypeAndCategory.dropDownFields) {
        if (dropDown.isDefaultNa) {
          const options = getDropDownOptions(
            dropDown.options.filter((option) => option.associationId === 0),
          );
          setValue(dropDown.dropDownName, options?.length ? options : null);
        } else {
          setValue(dropDown.dropDownName, null);
        }
      }
    }
  }, [
    selectedDocument,
    currentDocumentType,
    currentDocumentTypeAndCategory,
    setValue,
  ]);

  /**
   * -------------- *
   * Event handlers *
   * -------------- *
   */

  const handleChange = (isMulti, dropDownName, options, currentSelection) => {
    if (!isMulti) {
      currentSelection = options;
      return setValue(dropDownName, [currentSelection]);
    }
    // if NA selected, clear remaining selections
    if (currentSelection?.value?.associationId === 0) {
      setValue(dropDownName, [currentSelection]);
      // if any option other than NA is selected, clear NA from list
    } else {
      const remaining = options?.filter(
        (option) => option.value.associationId !== 0,
      );
      setValue(dropDownName, remaining.length ? remaining : null);
    }
    if (options.length) {
      clearErrors([dropDownName]);
    }
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */

  return (
    <>
      {currentDocumentTypeAndCategory?.dropDownFields?.map(
        ({ dropDownName, options, isMulti }, index) => {
          return (
            <FormControl
              isInvalid={errors[dropDownName]}
              key={`${index}-${dropDownName}`}
              sx={{ mb: 4 }}
            >
              <FormLabel htmlFor={dropDownName}>
                <Text fontSize="sm">
                  {`Select ${DROPDOWN_LABEL[dropDownName]} Record${
                    isMulti ? '(s)' : ''
                  }`}
                </Text>
              </FormLabel>
              <Controller
                name={dropDownName}
                control={control}
                rules={{ required: FORM_MESSAGES.requiredField }}
                options={getDropDownOptions(options)}
                defaultValue={null}
                render={({ field, value }) => {
                  return (
                    <CustomSelect
                      {...field}
                      isMulti={isMulti}
                      value={value}
                      hasError={errors[dropDownName] ? true : false}
                      options={getDropDownOptions(options)}
                      onChange={(
                        allSelectedOptions,
                        { option: currentSelection },
                      ) => {
                        if (isMulti) {
                          handleChange(
                            isMulti,
                            dropDownName,
                            allSelectedOptions,
                            currentSelection,
                          );
                        }
                        // If isMulti is false, handle allSelectedOptions only
                        else {
                          handleChange(
                            isMulti,
                            dropDownName,
                            allSelectedOptions,
                          );
                        }
                      }}
                      inputId={dropDownName}
                      data-tracking={`documentAssociation-${
                        index + 1
                      }--${dropDownName}`}
                      data-testid={`documentAssociation-${
                        index + 1
                      }--${dropDownName}`}
                    />
                  );
                }}
              />
              <FormErrorMessage>
                <Text fontSize="sm">{errors[dropDownName]?.message}</Text>
              </FormErrorMessage>
            </FormControl>
          );
        },
      )}
    </>
  );
};

ClassifyDynamicFormContent.propTypes = {
  rhfProps: PropTypes.object.isRequired,
};

export default ClassifyDynamicFormContent;
