﻿import PropTypes from 'prop-types';
import BreakdownValue from '../styles/BreakdownValue';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import { FORMULA1120 } from '../../../constants';
import { FormTypes } from '../../../enums';
import lang from '../language.json';

const AnnualIncome = ({ selectedResult, currentYear, formulaId }) => {
  const useAddbacks =
    formulaId === FORMULA1120.OneYearPositiveOrNegativeTend ||
    formulaId === FORMULA1120.TwoYearPositiveOrNegativeTrend;

  const annualIncome = selectedResult.getInputAsFloat(
    FormTypes.Form1120,
    currentYear,
    'annualIncome',
  );
  const medicareWages = selectedResult.getInputAsFloat(
    FormTypes.W2,
    currentYear,
    'medicareWages',
  );
  const borrowerShareOfIncome = selectedResult.getInputAsFloat(
    FormTypes.Form1120,
    currentYear,
    'borrowerShareOfIncomeOrLoss',
  );

  const {
    FIELDS: { ['1120']: FIELDS },
  } = lang;

  return (
    <BreakdownValue
      name={FIELDS.ANNUAL_INCOME}
      value={annualIncome}
      isCurrency={true}
    >
      <BreakdownValue name={FIELDS.ANNUAL_INCOME_WAGES} value={medicareWages} />
      {useAddbacks && (
        <BreakdownValue
          name={FIELDS.ANNUAL_INCOME_BORROWER_SHARES_INCOME}
          value={borrowerShareOfIncome}
        />
      )}
    </BreakdownValue>
  );
};
AnnualIncome.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
  formulaId: PropTypes.number.isRequired,
};

export default AnnualIncome;
