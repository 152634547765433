import Text from 'common/componentMapper/Text/Text';
import Stack from 'common/components/Stacks/Stack';
import Button from 'common/componentMapper/Button/Button';
import { TITLE_ICON_TYPE } from '../../QuestionBox/constants';
import ExtractionDataIcon from './ExtractionDataIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { HIDE_BOLT_ANSWER, SHOW_BOLT_ANSWER } from '../constants';
import DetailedAnswer from './DetailedAnswer';

const BOLTAnswerFooter = ({
  answer = '',
  detailedAnswer = '',
  confidenceLevel = 0,
  handleConfidenceLevelColor = () => {},
  showGaugeIcon,
}) => {
  const iconColor = handleConfidenceLevelColor(confidenceLevel);
  const [showBOLTAnswer, setShowBOLTAnswer] = useState(false);
  const showIcon = showGaugeIcon && !isNaN(confidenceLevel);

  return (
    <Stack
      sx={{
        paddingTop: showBOLTAnswer && '1rem',
        width: '100%',
      }}
    >
      {showBOLTAnswer && (
        <Stack direction="row" alignItems="center" width="100%">
          {showIcon && (
            <ExtractionDataIcon
              isConfidence={true}
              iconType={TITLE_ICON_TYPE.GAUGE}
              color={iconColor}
            />
          )}

          <Stack
            direction="column"
            width="100%"
            sx={{ fontStyle: 'italic', color: 'gray.500' }}
          >
            {!!answer && (
              <Text
                sx={{
                  width: '100%',
                  padding: 0,
                  textAlign: 'left',
                }}
                text={`BOLT Answer: ${answer}`}
              />
            )}
            {!!detailedAnswer && (
              <DetailedAnswer detailedAnswer={detailedAnswer} />
            )}
          </Stack>
        </Stack>
      )}

      <Stack
        direction="row"
        sx={{
          width: '100%',
          justifyContent: 'left',
          paddingTop: '1rem',
        }}
      >
        <Button
          type="linkButton"
          onClick={() => setShowBOLTAnswer((prev) => !prev)}
          data-tracking="show-footer-confidence-bolt-answer"
          label={showBOLTAnswer ? HIDE_BOLT_ANSWER : SHOW_BOLT_ANSWER}
        />
      </Stack>
    </Stack>
  );
};

BOLTAnswerFooter.propTypes = {
  detailedAnswer: PropTypes.string,
  confidenceLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleConfidenceLevelColor: PropTypes.func,
  answer: PropTypes.string,
  showGaugeIcon: PropTypes.bool,
};

export default BOLTAnswerFooter;
