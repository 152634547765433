// 3rd party imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDisclosure } from '@chakra-ui/react';
import Modal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalBody from 'common/components/Modals/ModalBody';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import Button from 'common/components/Buttons/Button/index';
import Center from 'common/components/Center';
import Flex from 'common/components/Flex';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

// local imports
import { HomeActions, HomeThunks } from './redux';
import { API_STATUS, ALERT_SEVERITY } from 'common/constants';
import lang from './language.json';
import AlertOkModal from 'common/components/Modals/Modal/AlertOkModal';

function ModalIcon({ severity }) {
  const commonStyle = { marginBottom: '8px', fontSize: '32px' };

  switch (severity) {
    case ALERT_SEVERITY.WARNING:
      return (
        <Box
          as="span"
          sx={commonStyle}
          color="orange.300"
          id="bailout-warning-icon"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Box>
      );

    case ALERT_SEVERITY.ALERT:
      return (
        <Box as="span" sx={commonStyle} color="red.500" id="bailout-error-icon">
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Box>
      );
    default:
      return null;
  }
}
ModalIcon.propTypes = {
  severity: PropTypes.number,
};

function BailOutModalLayout({ isOpen, onClose, children, footer }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginTop={350}>
        <ModalCloseButton />
        <ModalBody mt="24px">{children}</ModalBody>
        <ModalFooter display="flex" justifyContent="center" pb="2rem">
          <Flex justifyContent="space-between" gridGap="12px">
            {footer()}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
BailOutModalLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  footer: PropTypes.func.isRequired,
};

export default function BailOutModal() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  // redux
  const dispatch = useDispatch();
  const {
    home: { isBailOut, bailOutAndSubmitToSetup },
    url: { obfuscatedLoanIdentifier },
  } = useSelector((state) => state);

  const [isBailedOut, setBailedOut] = useState(false);

  const isBailOutUpdating =
    bailOutAndSubmitToSetup?.status === API_STATUS.UPDATING;
  const error = bailOutAndSubmitToSetup?.error;
  const data = bailOutAndSubmitToSetup?.data;
  const modalData = error || data;

  function closeBailOutView() {
    dispatch(HomeActions.setIsBailOut(false));
  }

  // handlers
  const handleYes = async () => {
    const response = await dispatch(HomeThunks.bailOutAndSubmitToSetup());

    // if there is an error or an alert to confirm, don't mark bailed out
    if (response !== true) {
      return;
    }

    closeBailOutView();
    setBailedOut(true);
  };

  const handleAlertOk = async (isError) => {
    if (!isError) {
      await dispatch(HomeThunks.bailOutAndSubmitToSetup(true));
      setBailedOut(true);
    }

    closeBailOutView();
  };

  useEffect(() => {
    isBailOut ? onOpen() : onClose();
  }, [isBailOut, onOpen, onClose]);

  // on bailout success navigate to EASE
  useEffect(() => {
    if (isBailedOut)
      window.location.href = `${window.globalConfig.ease.baseUrl}/Loan/${obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId}/${obfuscatedLoanIdentifier.obfuscatedLoanRecordId}/LoanApplication`;
  }, [isBailedOut, obfuscatedLoanIdentifier]);

  if (!isBailOut) {
    return null;
  }

  if (modalData) {
    return (
      <AlertOkModal
        isOpen={isOpen}
        handleClose={closeBailOutView}
        handleOk={() => handleAlertOk(!!error)}
        loading={isBailOutUpdating}
        data={modalData}
      />
    );
  }

  return (
    <BailOutModalLayout
      isOpen={isOpen}
      onClose={closeBailOutView}
      footer={() => (
        <>
          <Button
            backgroundColor="blue.600"
            color="white"
            isLoading={isBailOutUpdating}
            loadingText={lang.YES_BUTTON}
            _hover={{
              backgroundColor: 'blue.500',
            }}
            onClick={handleYes}
          >
            {lang.YES_BUTTON}
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            disabled={isBailOutUpdating}
            onClick={closeBailOutView}
          >
            {lang.NO_BUTTON}
          </Button>
        </>
      )}
    >
      <Center>
        <ModalIcon severity={ALERT_SEVERITY.WARNING} />
      </Center>
      <Text as="h3" textAlign="center" mb="16px">
        {lang.BAIL_OUT_TITLE}
      </Text>
      <Text as="p" fontSize="14px" textAlign="center" lineHeight="1.5rem">
        {lang.BAIL_OUT_QUESTION}
      </Text>
    </BailOutModalLayout>
  );
}
