import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Options for 'Select Document Type' dropdown
const useDocumentTypeOptions = (selectedDocumentCategory) => {
  // local state
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  // redux
  const {
    documentTypesAndCategories: { data: documentTypesAndCategories },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  // generate documentTypeOptions
  useEffect(() => {
    let options = [];
    if (selectedDocumentCategory?.value) {
      options = documentTypesAndCategories?.reduce((acc, curr) => {
        if (curr.documentCategory === selectedDocumentCategory.value) {
          acc.push({
            label: curr.documentClassification,
            value: curr.documentTypeId,
          });
        }
        return acc;
      }, []);
    }
    setDocumentTypeOptions(options);
  }, [selectedDocumentCategory, documentTypesAndCategories]);

  return documentTypeOptions;
};

export default useDocumentTypeOptions;
