import { OrderedList as ChakraOrderedList } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const OrderedList = ({ children, ...props }) => {
  return <ChakraOrderedList {...props}>{children}</ChakraOrderedList>;
};

export default OrderedList;

OrderedList.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
