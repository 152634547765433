import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NavItemWrapper from 'common/components/Nav/components/NavItemWrapper';
import { NavWrapper } from '../../Nav/components/Styled';
import RailNav from 'common/components/Nav/Rail/components/RailNav';
import {
  PanelContainerFullWidth,
  RightContainer,
} from '../../StyledActivityPanel';
import BlockedPage from '../../BlockedPage';
import { useSelector } from 'react-redux';
import Spinner from '../../Spinner';
import SelectedCategoryNavContainer from 'common/components/Nav/components/SelectedCategoryNavContainer';

const Navigation = ({ url, featureFlags, categories }) => {
  return (
    <NavWrapper>
      <SelectedCategoryNavContainer>
        {categories.map(({ flagName, label, to }, i) =>
          featureFlags[flagName] ? (
            <NavItemWrapper
              key={`${label}-${i}`}
              label={label}
              to={`${url}${to}`}
            />
          ) : null,
        )}
      </SelectedCategoryNavContainer>
    </NavWrapper>
  );
};
Navigation.propTypes = {
  url: PropTypes.string,
  categories: PropTypes.array,
  featureFlags: PropTypes.object,
};

export default function PageLayout({
  categories,
  topNavigation = true,
  isLoading,
}) {
  const { path, url } = useRouteMatch();
  const featureFlags = useSelector(({ featureFlags }) => featureFlags);
  function extractPath(url) {
    // Split the URL by slashes
    let parts = url.split('/');

    // Remove the first two numbers
    parts.splice(1, 2);

    // Join the remaining parts back together
    let path = parts.join('/');

    // Replace dashes with camel case
    path = path.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });

    // Remove leading slash if it exists
    if (path.startsWith('/')) {
      path = path.substring(1);
    }

    return path;
  }

  const renderedApp = useMemo(() => {
    return (
      !isLoading &&
      categories.map(({ to, flagName, component: Component }) => (
        <Route
          key={crypto.randomUUID()}
          path={`${path}${to}`}
          render={() => {
            if (featureFlags[flagName]) {
              return <Component />;
            }
            return <BlockedPage />;
          }}
        />
      ))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, categories]);

  return (
    <>
      {topNavigation && (
        <Navigation
          url={url}
          featureFlags={featureFlags}
          categories={categories}
        />
      )}
      <PanelContainerFullWidth templateColumns="auto 1fr">
        <RailNav />
        <RightContainer featureFlag id={extractPath(url)}>
          <Switch>{!isLoading ? renderedApp : <Spinner />}</Switch>
        </RightContainer>
      </PanelContainerFullWidth>
    </>
  );
}

PageLayout.propTypes = {
  categories: PropTypes.array,
  topNavigation: PropTypes.bool,
  isLoading: PropTypes.bool,
};
