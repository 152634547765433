﻿export default class HistoricalCalculationDataModel {
  constructor(hc = {}) {
    this.changedByUserName = Object.hasOwnProperty.call(hc, 'changedByUserName')
      ? hc.changedByUserName
      : '';
    this.calculatedMonthlyAmount = Object.hasOwnProperty.call(
      hc,
      'calculatedMonthlyAmount',
    )
      ? hc.calculatedMonthlyAmount
      : 0;
    this.requestReferenceType = Object.hasOwnProperty.call(
      hc,
      'requestReferenceType',
    )
      ? hc.requestReferenceType
      : 0;
    this.calculationId = Object.hasOwnProperty.call(hc, 'calculationId')
      ? hc.calculationId
      : 0;

    this.calculatorTypeName = Object.hasOwnProperty.call(
      hc,
      'calculatorTypeName',
    )
      ? hc.calculatorTypeName
      : '';

    this.rentalAddressId = Object.hasOwnProperty.call(hc, 'rentalAddressId')
      ? hc.rentalAddressId
      : 0;
    this.rentalIncomePitia = Object.hasOwnProperty.call(hc, 'rentalIncomePitia')
      ? hc.rentalIncomePitia
      : 0;
    this.calculationDate = Object.hasOwnProperty.call(hc, 'calculationDate')
      ? hc.calculationDate
      : '01/01/1970';
    this.calculationTime = Object.hasOwnProperty.call(hc, 'calculationTime')
      ? hc.calculationTime
      : '12:00 AM';
  }

  /**
   * @returns {string} The name of the user that ran the calculation.
   */
  getUsername() {
    return this.changedByUserName;
  }

  /**
   * @returns {number} The calculated monthly income value for the calculation.
   */
  getCalculatedMonthlyAmount() {
    return this.calculatedMonthlyAmount;
  }

  /**
   * @returns {number} The request reference type, based on the rquest reference enum.
   */
  getRequestReferenceType() {
    return this.requestReferenceType;
  }

  /**
   * @returns {number} The calculation id in the database.
   */
  getCalculationId() {
    return this.calculationId;
  }

  /**
   * @returns {number} The rental address identifier, if the calculation is for a rental income.
   */
  getRentalAddressId() {
    return this.rentalAddressId;
  }

  /**
   * @returns {number} The rental PITIA amount, if the calculation is for a rental income.
   */
  getPitia() {
    return this.rentalIncomePitia;
  }

  /**
   * @returns {string} The date and time of the calculation.
   */
  getCalculationDateTime() {
    return `${this.calculationDate} ${this.calculationTime}`;
  }

  /**
   * @returns {string} The name of the calculator that was used to complete the calculation.
   */
  getCalculatorName() {
    return this.calculatorTypeName;
  }
}
