export const INITIAL_REO_FORM_ERRORS = {};

export const REO_API_ERROR = {
  GETTING: 'Unable to get REO data.',
  UPDATING: 'Unable to save REO data.',
};
export const TABLE_HEADERS = {
  ADDRESS: 'Address',
  PROPERTY_STATUS: 'Property Status',
  TYPE: 'Type',
  OCCUPANCY_TYPE: 'Occupancy Type',
  SUB_PROPERTY: 'Sub Property',
  ASSOCIATED_LIABILITIES: 'Associated Liabilities',
};
export const CURRENCY_QUESTION_IDS = {
  IS_INSURANCE_TAXES_ESCROWED: 5001,
  MONTHLY_INSURANCE: 5013,
  MONTHLY_TAXES: 5014,
  MONTHLY_HOA: 5015,
  ANNUAL_HOA: 5020,
  ANNUAL_TAXES: 5019,
  ANNUAL_INSURANCE: 5018,
  EXPECTED_TO_CLOSE: 5021,
  PROVIDED_INSURANCE_DOCS: 5004,
  PROVIDED_INSURANCE_DOCS_NEW: 5022,
  PROVIDED_TAX_DOCS: 5006,
  PROVIDED_TAX_DOCS_NEW: 5023,
  PROVIDED_HOA_DOCS: 5007,
  PROVIDED_HOA_DOCS_NEW: 5024,
};
