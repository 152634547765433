import { AccordionItem as ChakraAccordionItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AccordionItem = ({ children, ...props }) => {
  return <ChakraAccordionItem {...props}>{children}</ChakraAccordionItem>;
};

export default AccordionItem;

AccordionItem.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
