export const EMPLOYMENT_REVIEW_TYPES = {
  GET_EMPLOYMENT_REVIEW_ERROR: 'GET_EMPLOYMENT_REVIEW_ERROR',
  GET_EMPLOYMENT_REVIEW_STARTED: 'GET_EMPLOYMENT_REVIEW_STARTED',
  GET_EMPLOYMENT_REVIEW_SUCCESS: 'GET_EMPLOYMENT_REVIEW_SUCCESS',
  SAVE_EMPLOYMENT_REVIEW_ANSWERS_ERROR:
    'IC_SAVE_EMPLOYMENT_REVIEW_ANSWERS_ERROR',
  SAVE_EMPLOYMENT_REVIEW_ANSWERS_STARTED:
    'IC_SAVE_EMPLOYMENT_REVIEW_ANSWERS_STARTED',
  SAVE_EMPLOYMENT_REVIEW_ANSWERS_SUCCESS:
    'IC_SAVE_EMPLOYMENT_REVIEW_ANSWERS_SUCCESS',
  SAVE_EMPLOYMENT_REVIEW_ANSWERS_TYPE: 'IC_SAVE_EMPLOYMENT_REVIEW_ANSWERS_TYPE',
  SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_STARTED:
    'IC_SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_STARTED',
  SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_SUCCESS:
    'IC_SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_SUCCESS',
  SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_ERROR:
    'IC_SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS_ERROR',
};

export default EMPLOYMENT_REVIEW_TYPES;
