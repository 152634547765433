import DocumentDetailsDataModel from './DocumentFieldDataModel';

export default class EmploymentExtractedDataDataModel {
  constructor(b = {}) {
    this.documentField = Object.prototype.hasOwnProperty.call(
      b,
      'documentField',
    )
      ? b.documentField.map((x) => new DocumentDetailsDataModel(x))
      : [];
  }

  /**
   * @returns {DocumentDetailsDataModel}.
   */
  getDocumentField() {
    return this.documentField;
  }
}
