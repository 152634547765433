import { useFormApi } from '@data-driven-forms/react-form-renderer';
import PropTypes from 'prop-types';

export default function Form({ fields = [] }) {
  const { renderForm } = useFormApi();

  return <>{!!fields?.length && fields.map((field) => renderForm([field]))}</>;
}

Form.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
  ]),
};
