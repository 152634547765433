export const RENTAL_INCOME_RULE_ID = {
  VALIDATE_DOCUMENTATION: 5,
  CALCULATE_INCOME: 6,
};

export const PROPERTY_TYPE = {
  PURCHASE_SUBJECT_PROPERTY: 1,
  REFI_SUBJECT_PROPERTY: 2,
  REAL_ESTATE_OWNED: 3,
};

export const RENTAL_INCOME_DATA_API_STATUS = {
  GETTING: 'GETTING',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_ERROR: 'GET_ERROR',
};
