﻿export default class DocumentTypeSelectionDataModel {
  constructor(sel = {}) {
    this.incomeDocumentationTypeId = Object.hasOwnProperty.call(
      sel,
      'incomeDocumentationTypeId',
    )
      ? sel.incomeDocumentationTypeId
      : 0;
    this.isSelected = Object.hasOwnProperty.call(sel, 'isSelected')
      ? sel.isSelected
      : true;
  }

  getId() {
    return this.incomeDocumentationTypeId;
  }

  getIsSelected() {
    return this.isSelected;
  }

  setSelected(value) {
    this.isSelected = value;
  }
}
