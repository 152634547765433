import PropTypes from 'prop-types';
import SingleLineValue from '../styles/SingleLineValue';
import BreakdownContainer from '../BreakdownContainer';
import Continuance from './Continuance';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import lang from '../language.json';

export default function IRABankBreakdown({
  selectedResult,
  hideAccordion = false,
  onNewWindow,
}) {
  return (
    <BreakdownContainer
      header={lang.HEADERS[selectedResult.getCalculatorType()]}
      hideAccordion={hideAccordion}
      onNewWindow={onNewWindow}
    >
      <SingleLineValue
        title={lang.MONTHLY_INCOME}
        value={selectedResult.getCalculationAmount()}
        isCurrency
      />
      <Continuance selectedResult={selectedResult} />
    </BreakdownContainer>
  );
}

IRABankBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel),
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};
