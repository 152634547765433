import SummaryPerBorrower from './components/SummaryPerBorrower';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from './components/PageLayout';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import './styles.css';
import { useQuery } from '@tanstack/react-query';

export default function KnowYourLoan() {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );

  const getKnowYourLoanSummary = async () => {
    const response = await apiClient.post(
      apiEndPoints.GET_KNOW_YOUR_LOAN_SUMMARY,
      obfuscatedLoanIdentifier,
    );
    return response?.data;
  };
  const { isLoading, data } = useQuery({
    queryKey: ['knowYourLoanSummary'],
    queryFn: getKnowYourLoanSummary,
    refetchOnWindowFocus: false,
  });
  const categories = useMemo(() => {
    let additionalBorrowersCount = 1;
    const borrowers = data?.screenData?.borrowers;
    if (borrowers) {
      return borrowers.map((borrower) => {
        return {
          to: `/${
            borrower.isPrimaryBorrower
              ? 'primary'
              : `additionalBorower${additionalBorrowersCount++}`
          }`,
          label: `${borrower.firstName} ${borrower.lastName}`,
          flagName: 'IncludeKnowYourLoan',
          component: () => <SummaryPerBorrower borrower={borrower} />,
        };
      });
    }
    return [];
  }, [data?.screenData?.borrowers]);

  return (
    <PageLayout
      categories={categories}
      topNavigation={true}
      isLoading={isLoading}
    />
  );
}
