import typography from './typography';

const styles = {
  global: {
    'html, body, #root': {
      height: '100%',
      backgroundColor: '#F7F7F7',
      color: 'gray.900',
      fontFamily:
        '"Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    a: {
      color: 'blue.600',
    },
    h1: {
      fontWeight: typography.h1.fontWeight,
      fontSize: typography.h1.fontSize,
      lineHeight: typography.h1.lineHeight,
      letterSpacing: typography.h1.letterSpacing,
    },
    h2: {
      fontWeight: typography.h2.fontWeight,
      fontSize: typography.h2.fontSize,
      lineHeight: typography.h2.lineHeight,
      letterSpacing: typography.h2.letterSpacing,
    },
    h3: {
      fontWeight: typography.h3.fontWeight,
      fontSize: typography.h3.fontSize,
      lineHeight: typography.h3.lineHeight,
      letterSpacing: typography.h3.letterSpacing,
    },
    h4: {
      fontWeight: typography.h4.fontWeight,
      fontSize: typography.h4.fontSize,
      lineHeight: typography.h4.lineHeight,
      letterSpacing: typography.h4.letterSpacing,
    },
    h5: {
      fontWeight: typography.h5.fontWeight,
      fontSize: typography.h5.fontSize,
      lineHeight: typography.h5.lineHeight,
      letterSpacing: typography.h5.letterSpacing,
    },
    h6: {
      fontWeight: typography.h6.fontWeight,
      fontSize: typography.h6.fontSize,
      lineHeight: typography.h6.lineHeight,
      letterSpacing: typography.h6.letterSpacing,
    },
    legend: {
      fontSize: typography.h6.fontSize,
      fontWeight: typography.h6.fontWeight,
      paddingBottom: '0.5rem',
    },
    /* scrollbar width */
    '*::-webkit-scrollbar': {
      backgroundColor: '#fff',
      width: '14px',
    },
    /* scrollbar background */
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
    },
    /* custom scrollbar */
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '4px solid #fff',
    },
    /* hide default scrollbar */
    '*::-webkit-scrollbar-button': {
      display: 'none',
    },
    '.chakra-input__group': {
      borderRadius: '0.375rem',
    },
  },
};

export default styles;
