// AUS underwriting methods
export const DESKTOP_UNDERWRITER = 'Desktop Underwriter';
export const LOAN_PROSPECTOR = 'Loan Prospector';

// AUS Status
export const APPROVE_ELIGIBLE = 'Approve/Eligible';
export const ACCEPT = 'Accept';
export const PROCESS_DATA_FAILED = 'ProcessDataFailed';

export const DESKTOP_UNDERWRITER_APPROVED_AUS_STATUS = APPROVE_ELIGIBLE;
export const LOAN_PROSPECTOR_APPROVED_AUS_STATUS = ACCEPT;

export const BOLT_APPROVAL_DOC_STATUS = {
  NOT_REVIEWED: 5,
  UPLOADED: 7,
};

export const BOLT_APPROVAL_ERROR_MESSAGE =
  'We are unable to run BOLT approval.';

export const TIMER_STATUS = {
  NOT_STARTED: 'Not Started',
  STARTED: 'started',
  ENDED: 'Ended',
};

export const APPLIED_BOLT_CONDITION_CATEGORIES = 12;
