import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendEnterAction, sendLeaveAction } from 'lib-node-observability';

if (process.env.NODE_ENV !== 'production') {
  // dtrum is not available in development, only on the servers
  window.dtrum = {
    enterAction: (action) => {
      console?.group('enterAction');
      console.log(action);
      console?.groupEnd();
      return action;
    },
    leaveAction: (id) => {
      console?.group('leaveAction');
      console.log(id);
      console?.groupEnd();
    },
  };
}
const CATEGORY_MAP = Object.freeze({
  0: 'classify',
  1: 'borrower',
  2: 'liabilities',
  3: 'assets',
  4: 'income',
  5: 'property',
  6: 'final review',
  8: 'uw_final_review',
});
const CHECKPOINTS = new Proxy(CATEGORY_MAP, {
  get(target, prop) {
    if (!target[prop]) {
      console.error('unknown categoryId sent:', prop);
      return `unknown categoryId: ${prop}`;
    }
    return target[prop];
  },
});

/**
 * @description This hook is used to track the user's progress through the work items in the application. It does not
 * throw any errors but logs them because this shouldn't impact the user experience.
 */
const useWorkTracking = () => {
  const [categoryId, setCategoryId] = useState(null);
  const [workItemId, setWorkItemId] = useState(null);
  const [parentWorkId, setParentWorkId] = useState(null);
  const { selectedCategory, selectedAlert, selectedAlertParent } = useSelector(
    ({ home }) => home,
  );
  useEffect(() => {
    // on category change
    if (!selectedCategory) return;
    const categoryName = CHECKPOINTS[selectedCategory];
    const metaData = {
      category: categoryName,
    };
    sendEnterAndLeaveAction('category', categoryId, setCategoryId, metaData);
  }, [selectedCategory]);

  useEffect(() => {
    // on alert change
    if (!selectedAlert || !selectedCategory) return;
    const metaData = {
      category: CHECKPOINTS[selectedCategory],
      description: selectedAlert.displayTitle,
    };
    sendEnterAndLeaveAction('work item', workItemId, setWorkItemId, metaData);
  }, [selectedAlert]);

  useEffect(() => {
    // on parent alert change
    if (
      !selectedAlertParent ||
      !selectedCategory ||
      selectedAlert.displayTitle === selectedAlertParent.displayTitle
    ) {
      return;
    }
    const metaData = {
      category: CHECKPOINTS[selectedCategory],
      description: selectedAlertParent.displayTitle,
    };
    sendEnterAndLeaveAction(
      'parent work item',
      parentWorkId,
      setParentWorkId,
      metaData,
    );
  }, [selectedAlertParent]);
};

/**
 *
 * @description This function is used in combination with dtrum.
 * If dtrum is not available, it will log an error.
 * It will call the enterAction function from dtrum with the actionTitle and return the actionId.
 * If there is a currentId, it will call the leaveAction function from dtrum with the currentId.
 * If there is a setter function, it will call the setter function with the actionId.
 * If there is no setter function, it will return the actionId.
 * @param actionTitle - The title of the action to be sent to dtrum.enterAction
 * @param currentId - The current actionId to be sent back to the dtrum.leaveAction
 * @param setter - The function to set the actionId in state, this is often a useState setter function. IF not provided, the function will return the actionId to be used outside of this function
 * @param metaData - The metaData object to be stringified and added to the actionTitle then sent to dtrum.enterAction
 */
export const sendEnterAndLeaveAction = (
  actionTitle = '',
  currentId = 0,
  setter = () => {},
  metaData = {
    category: '',
    description: '',
  },
) => {
  if (!window.dtrum) {
    console.error('there is no dtrum object');
    return;
  }
  const actionId = sendEnterAction(actionTitle, metaData);
  const isLeaving = currentId && currentId !== actionId;
  if (isLeaving) {
    sendLeaveAction(currentId);
  } else {
    setter(actionId);
  }
};
export default useWorkTracking;
