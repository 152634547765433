import React from 'react';
import Stack from 'common/components/Stacks/Stack';
import PropTypes from 'prop-types';

const VStack = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Stack direction="column" spacing={2} ref={ref} {...props}>
      {children}
    </Stack>
  );
});

VStack.displayName = 'VStack';

export default VStack;

VStack.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
