import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { formatCurrency } from 'common/util/format';

export default function W2ItemsYTD({
  pastYearItems = [],
  w2Items = [],
  variableTitle = '',
  showCalcEquation = true,
}) {
  const showCalcs = !!pastYearItems.length && showCalcEquation;

  return (
    <>
      <Text
        textDecoration="underline"
        fontSize="md"
        fontWeight="bold"
        data-testid={'pay-type'}
      >
        W2
      </Text>
      <Flex alignItems={'flex-end'}>
        <Box data-testid="w2-item-headings">
          <Text mr={6} fontSize="md">
            W2 Total
          </Text>
          <Text mr={6} fontSize="md">
            Monthly
          </Text>
          {variableTitle && (
            <Text
              mr={6}
              fontSize="md"
              data-testid={`variable-title-${variableTitle}`}
            >
              {variableTitle}
            </Text>
          )}
        </Box>

        {!!pastYearItems.length &&
          pastYearItems.map(({ fiscalYear, annualIncome }, idx) => (
            <Box data-testid="past-year-section" key={idx} mr={6}>
              <Text data-testid={`fiscal-year-${idx}`} mr={1} fontWeight="bold">
                {fiscalYear}
              </Text>
              <Text data-testid={`w2-income-${idx}`}>
                {formatCurrency(w2Items[idx].annualIncome)}
              </Text>
              <Text data-testid={`monthly-amount-${idx}`} d="inline">
                {formatCurrency(w2Items[idx].monthlyAmount)}
              </Text>
              <Text data-testid={`annual-income-${idx}`}>
                {formatCurrency(annualIncome)}
              </Text>
            </Box>
          ))}
      </Flex>

      {showCalcs && (
        <Box my={5}>
          {pastYearItems.map(
            ({ fiscalYear, totalBackedInVariableIncomeFormula }) => (
              <Text
                key={`${fiscalYear}-totalbackedintoformula}`}
                data-testid="totalBackedIntoFormula"
              >
                {fiscalYear} Variable Income:&nbsp;
                {totalBackedInVariableIncomeFormula}
              </Text>
            ),
          )}
        </Box>
      )}

      {showCalcs && (
        <Box my={5}>
          {pastYearItems.map(
            ({ fiscalYear, backingIntoVariableIncomeFormula }) => (
              <Text
                key={`${fiscalYear}-backingintoformula}`}
                data-testid="backingIntoFormula"
              >
                {fiscalYear} {variableTitle.trim()}:&nbsp;
                {backingIntoVariableIncomeFormula}
              </Text>
            ),
          )}
        </Box>
      )}
    </>
  );
}

W2ItemsYTD.propTypes = {
  variableTitle: PropTypes.string.isRequired,
  w2Items: PropTypes.arrayOf(
    PropTypes.shape({
      fiscalYear: PropTypes.number,
      monthlyAmount: PropTypes.number,
      annualIncome: PropTypes.number,
    }),
  ),
  pastYearItems: PropTypes.array,
  showCalcEquation: PropTypes.bool,
};
