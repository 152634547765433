import { useParams } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TabPanel from 'common/components/Tab/TabPanel';
import Tab from 'common/components/Tab/Tab';
import Tabs from 'common/components/Tab/Tabs';
import AccordionContainer from 'common/components/AccordionContainer';
import AuditTable from './AuditTable';
import Layout from './Layout';
import { useGetAuditHistorySummary } from './hooks';
import { toTitleCase } from 'common/util/format';

const StyledDeletedByText = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  letter-spacing: -0.12px;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  span {
    font-weight: 500;
    padding-right: 0.25rem;
    letter-spacing: normal;
  }
`;
function GetNoDataMessage(category, status) {
  return (
    <div>
      <p>
        There are no {status} {category} to display.
      </p>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `auditHistory-tab-${index}`,
    'aria-controls': `auditHistory-tabpanel-${index}`,
  };
}
export default function AuditHistory1003Summary({ category }) {
  const [value, setValue] = useState(0);
  const { pageEnum } = useParams();
  const {
    data: auditHistory,
    isLoading,
    isError,
  } = useGetAuditHistorySummary(pageEnum);
  const hasEntities =
    auditHistory?.activeEntities?.length > 0 ||
    auditHistory?.deletedEntities?.length > 0;

  const statusDeleted = 'deleted';
  const statusActive = 'active';

  const categoryTitle = toTitleCase(category);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout
      pageEnum={pageEnum}
      isLoading={isLoading}
      isError={isError}
      isDataEmpty={!hasEntities}
      category={category}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        ariaLabel={`${category} Audit History tabs`}
      >
        <Tab label={`Active ${categoryTitle}`} a11yProps={a11yProps(0)} />
        <Tab label={`Deleted ${categoryTitle}`} a11yProps={a11yProps(1)} />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        name="auditHistory"
        ariaLabel={`${category}-panel-active`}
      >
        {auditHistory?.activeEntities?.length > 0
          ? auditHistory.activeEntities.map((entity, index) => (
              <AccordionContainer
                key={`activeEntities-${index}`}
                header={entity.entityDisplayName}
                allowToggle
                defaultIndex={-1}
                layoutType={'summary1003AuditHistory'}
                marginTop="unset"
                accordionButtonProps={{
                  padding: '1rem',
                  accordionBgExpanded: 'blue.50',
                }}
                accordionPanelProps={{
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  padding: '1.5rem 1rem',
                }}
              >
                <AuditTable data={entity.entityFields} />
              </AccordionContainer>
            ))
          : GetNoDataMessage(category, statusActive)}
      </TabPanel>
      <TabPanel value={value} index={1} name="auditHistory">
        {auditHistory?.deletedEntities?.length > 0
          ? auditHistory.deletedEntities.map((entity, index) => (
              <section key={`deletedEntities-${index}`}>
                <StyledDeletedByText>
                  <span>Deleted By:</span>
                  {entity.deletedByDisplayName}
                </StyledDeletedByText>
                <AccordionContainer
                  header={entity.entityDisplayName}
                  allowToggle
                  defaultIndex={-1}
                  layoutType={'summary1003AuditHistory'}
                  marginTop="unset"
                  accordionButtonProps={{
                    padding: '1rem',
                    accordionBgExpanded: 'blue.50',
                  }}
                  accordionPanelProps={{
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    padding: '1.5rem 1rem',
                  }}
                >
                  <AuditTable data={entity.entityFields} />
                </AccordionContainer>
              </section>
            ))
          : GetNoDataMessage(category, statusDeleted)}
      </TabPanel>
    </Layout>
  );
}

AuditHistory1003Summary.propTypes = {
  category: PropTypes.string,
};
