import { useParams } from 'react-router-dom';
import AuditHistoryAccordion from './AuditHistoryAccordion';
import { useGetAssetAuditHistory } from './hooks';
import Layout from './Layout';

export default function AssetAuditHistory() {
  const { assetId } = useParams();

  const {
    data: auditHistory,
    isLoading,
    isError,
  } = useGetAssetAuditHistory(assetId);

  return (
    <Layout
      pageEnum="ASSETS"
      isLoading={isLoading}
      isError={isError}
      isDataEmpty={!auditHistory?.length}
    >
      <AuditHistoryAccordion data={auditHistory} />
    </Layout>
  );
}
