﻿import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';

const BreakdownYearContainer = ({ title, children, pb, ...props }) => {
  return (
    <Box
      role="region"
      aria-label={title}
      border="1px solid"
      borderRadius={4}
      borderColor="gray.200"
      {...props}
    >
      <Box bgColor="gray.50" borderTopRadius={4} h="40px" p={2} px={4}>
        <Text fontWeight={500} fontSize="lg">
          {title}
        </Text>
      </Box>
      <Box
        d="inline-block"
        minW="40%"
        p={5}
        pb={pb || 0}
        css={css`
          & > div {
            width: 100%;
            clear: both;
            float: left;
            margin-bottom: 1rem;
          }
        `}
      >
        {children}
      </Box>
    </Box>
  );
};
BreakdownYearContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BreakdownYearContainer;
