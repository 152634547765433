export const CONFIDENCE_LEVEL = {
  RED: 0,
  YELLOW: 1,
  GREEN: 2,
};

export const CONFIDENCE_LEVEL_ICON_COLORS = {
  GREEN: 'green.600',
  RED: 'red.600',
  YELLOW: 'orange.300',
};

export const TITLE_ICON_TYPE = {
  NONE: 0,
  BOLT: 1,
  USER: 2,
  NEW: 3,
  GAUGE: 4,
  AUTOMATION: 5,
};

export const allConfidenceColors = [
  CONFIDENCE_LEVEL_ICON_COLORS.GREEN,
  CONFIDENCE_LEVEL_ICON_COLORS.YELLOW,
  CONFIDENCE_LEVEL_ICON_COLORS.RED,
];

export const CONFIDENCE_LEVEL_THRESHOLD = {
  HIGH_CONFIDENCE_THRESHOLD: 80,
  MEDIUM_CONFIDENCE_THRESHOLD: 70,
};

export const AI_RESPONSE_DEFAULT = {
  answer: '',
  detailedAnswer: '',
  confidence: null,
  confidenceLevel: null,
  snippets: [],
};

export const CONTENT_TYPE = {
  TITLE: 'Title',
  CPL: 'CPL',
  WIRE: 'Wire',
  INCOME: 'Income',
};
