import { forwardRef, useEffect, useState } from 'react';
import Input from '../Input/Input';
import PropTypes from 'prop-types';
import { Checkbox } from '../Checkbox';
import Select from '../Select/Select';
import useSetPdfBoundingBoxes from 'common/useActionMapper/useSetPdfBoundingBoxes';

//TODO: inputs with two separate names/guids - should only use one named input per label for now
//TODO: triggering snippets works, but can be a bit wonky given where you click on the Radio input - need to propagate or stop events in the future
//TODO: DatePicker toggleInputOnSelection does not work, merged refs are needed - can merge them, but fails many tests - will address at a later date
const LabelInputSwitch = forwardRef(
  ({ componentType, onFocus, ...rest }, ref) => {
    switch (componentType) {
      case 'Input':
        return <Input {...rest} ref={ref} onFocus={onFocus} />;
      case 'Checkbox':
        return <Checkbox {...rest} ref={ref} onFocus={onFocus} />;

      //TODO: select is unfinished, need to update lib-node library to be able to pass template options
      case 'Select':
        return <Select {...rest} options={[]} ref={ref} onFocus={onFocus} />;
      default:
        return <Input {...rest} ref={ref} onFocus={onFocus} />;
    }
  },
);

LabelInputSwitch.displayName = 'LabelInputSwitch';

LabelInputSwitch.propTypes = {
  componentType: PropTypes.oneOf(['Input', 'Checkbox', 'Select']),
  onFocus: PropTypes.func,
};

const LabelInput = forwardRef(
  (
    {
      snippets = [],
      toggleInputOnSelection = false,
      focusInputOnSelection = false,
      optionLabel = '',
      selectedOption = '',
      onChange = () => {},
      componentType = 'Input',
      ...rest
    },
    ref,
  ) => {
    const [showInput, setShowInput] = useState(false);
    const setPdfBoundingBoxes = useSetPdfBoundingBoxes();
    const handleOnFocus = () => {
      onChange(optionLabel);
      setPdfBoundingBoxes(snippets, rest?.name)();
    };

    useEffect(() => {
      if (toggleInputOnSelection) {
        if (selectedOption === optionLabel) {
          setShowInput(true);
          focusInputOnSelection && ref.current?.focus();
        } else {
          setShowInput(false);
        }
      } else {
        setShowInput(true);
      }
    }, [
      toggleInputOnSelection,
      focusInputOnSelection,
      selectedOption,
      optionLabel,
      showInput,
      ref,
    ]);

    return (
      showInput && (
        <LabelInputSwitch
          {...rest}
          ref={ref}
          componentType={componentType}
          onFocus={handleOnFocus}
        />
      )
    );
  },
);

LabelInput.displayName = 'LabelInput';

LabelInput.propTypes = {
  snippets: PropTypes.array,
  hasExtraction: PropTypes.bool,
  toggleInputOnSelection: PropTypes.bool,
  focusInputOnSelection: PropTypes.bool,
  shouldToggleFocusForDatePicker: PropTypes.bool,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentType: PropTypes.oneOf(['Input', 'Checkbox', 'Select']),
  optionLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default LabelInput;
