import { Alert as ChakraAlert } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Alert = ({ children, ...props }) => {
  return <ChakraAlert {...props}>{children}</ChakraAlert>;
};

export default Alert;

Alert.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
