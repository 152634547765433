﻿export default class DocumentSelectionDataModel {
  constructor(sel = {}) {
    this.incomeDocumentId = Object.hasOwnProperty.call(sel, 'incomeDocumentId')
      ? sel.incomeDocumentId
      : 0;
    this.name = Object.hasOwnProperty.call(sel, 'name') ? sel.name : '';
    this.isSelected = Object.hasOwnProperty.call(sel, 'isSelected')
      ? sel.isSelected
      : true;
    this.documentTypeId = Object.hasOwnProperty.call(sel, 'documentTypeId')
      ? sel.documentTypeId
      : null;
  }

  getId() {
    return this.incomeDocumentId;
  }

  getName() {
    return this.name;
  }

  getIsSelected() {
    return this.isSelected;
  }

  setSelected(value) {
    this.isSelected = value;
  }

  getDocumentType() {
    return this.documentTypeId;
  }
}
