import * as rPdf from '@react-pdf/renderer';
const { G, Path, Svg, Rect } = rPdf;

const UWMLogo = () => (
  <Svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 242 113"
    enable-background="new 0 0 242 113"
    width="115"
    height="54"
  >
    <G>
      <G>
        <Path
          fill="#A1CE57"
          d="M1.89,99.98v7.3c0,1,0.1,1.7,0.3,2.1c0.2,0.4,0.6,0.8,1.1,1s1,0.4,1.5,0.4s1-0.1,1.4-0.3
                c0.4-0.2,0.8-0.5,1-0.7c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.1-0.9,0.1-1.6v-7.3h1.3v7.6c0,0.8-0.1,1.4-0.2,1.9
                c-0.2,0.5-0.4,0.9-0.9,1.3c-0.4,0.4-0.9,0.7-1.4,1c-0.5,0.2-1.1,0.3-1.7,0.3c-0.7,0-1.4-0.2-2.1-0.5c-0.7-0.4-1.2-0.8-1.6-1.4
                s-0.6-1.5-0.6-2.6v-7.5h1.4V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M11.39,99.48l9.2,9.7v-9.2h1.3v12.4l-9.2-9.7v9.1h-1.3C11.39,111.78,11.39,99.48,11.39,99.48z"
        />
        <Path fill="#A1CE57" d="M25.79,99.98v11.9h-1.3v-11.9H25.79z" />
        <Path
          fill="#A1CE57"
          d="M34.09,99.98v1.2h-2.9v10.6h-1.3v-10.6h-2.9v-1.2H34.09z"
        />
        <Path
          fill="#A1CE57"
          d="M41.49,99.98v1.2h-4.8v3.5h4.7v1.2h-4.7v4.7h4.8v1.2h-6.1v-11.9h6.1V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M45.89,99.98c1.1,0,2,0.2,2.8,0.5s1.4,0.8,2,1.3c0.5,0.6,0.9,1.2,1.2,1.9c0.3,0.7,0.4,1.5,0.4,2.2
                c0,1-0.2,2-0.7,2.8c-0.5,0.9-1.2,1.6-2.1,2.2c-0.9,0.6-2.1,0.9-3.6,0.9h-2.5v-11.9h2.5V99.98z M45.99,110.58c0.7,0,1.4-0.1,2-0.3
                c0.6-0.2,1.1-0.5,1.6-1c0.5-0.4,0.8-0.9,1-1.5s0.4-1.2,0.4-1.9c0-0.9-0.2-1.6-0.6-2.3c-0.4-0.7-0.9-1.3-1.6-1.7
                c-0.7-0.4-1.7-0.7-2.9-0.7h-1.1v9.4H45.99z"
        />
        <Path
          fill="#A1CE57"
          d="M66.29,99.38l3.8,9.5l3.1-8.9h1.4l-4.5,12.5l-3.8-9.5l-3.8,9.5l-4.5-12.5h1.4l3.1,8.9L66.29,99.38z"
        />
        <Path
          fill="#A1CE57"
          d="M77.19,99.98v4.8h6.1v-4.8h1.3v11.9h-1.3v-5.8h-6.1v5.8h-1.3v-11.9H77.19z"
        />
        <Path
          fill="#A1CE57"
          d="M86.49,105.88c0-1.1,0.3-2.1,0.8-3.1s1.3-1.7,2.3-2.3c1-0.6,2-0.8,3.1-0.8s2.1,0.3,3.1,0.8
                c0.9,0.5,1.7,1.3,2.3,2.2c0.6,1,0.8,2,0.8,3.1s-0.3,2.1-0.8,3s-1.3,1.7-2.2,2.2c-0.9,0.6-2,0.8-3.1,0.8s-2.1-0.3-3.1-0.8
                s-1.7-1.3-2.3-2.2C86.69,107.98,86.49,106.98,86.49,105.88z M92.59,100.98c-0.9,0-1.7,0.2-2.4,0.7c-0.7,0.5-1.3,1.1-1.8,1.8
                c-0.4,0.7-0.6,1.6-0.6,2.4s0.2,1.7,0.7,2.4c0.4,0.8,1,1.4,1.8,1.8c0.7,0.4,1.6,0.7,2.4,0.7c0.9,0,1.7-0.2,2.4-0.7
                c0.8-0.5,1.4-1.1,1.8-1.8c0.4-0.8,0.6-1.6,0.6-2.4c0-0.9-0.2-1.7-0.7-2.5c-0.4-0.8-1-1.4-1.8-1.8
                C94.29,101.18,93.49,100.98,92.59,100.98z"
        />
        <Path
          fill="#A1CE57"
          d="M101.89,99.98v10.6h3.3v1.2h-4.6v-11.9h1.3V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M112.59,99.98v1.2h-4.8v3.5h4.7v1.2h-4.7v4.7h4.8v1.2h-6.1v-11.9h6.1V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M119.59,102.28c-0.3-0.5-0.6-0.8-0.9-1s-0.7-0.3-1.2-0.3c-0.6,0-1.1,0.2-1.4,0.5c-0.4,0.4-0.6,0.8-0.6,1.3
                c0,0.4,0.2,0.7,0.5,1s0.7,0.5,1.1,0.7s0.8,0.4,1.2,0.5c0.4,0.1,0.8,0.4,1.2,0.7c0.5,0.3,0.8,0.7,1.1,1.1c0.3,0.4,0.4,0.9,0.4,1.5
                s-0.2,1.3-0.5,1.8c-0.3,0.6-0.8,1-1.4,1.3s-1.2,0.5-1.9,0.5c-0.9,0-1.7-0.3-2.4-0.8c-0.7-0.6-1.1-1.3-1.3-2.2l1.3-0.4
                c0.1,0.7,0.4,1.2,0.8,1.6s1,0.6,1.6,0.6c0.7,0,1.2-0.2,1.7-0.7s0.7-1,0.7-1.6s-0.2-1-0.6-1.4s-1-0.7-2-1.1s-1.7-0.8-2.2-1.3
                s-0.7-1.1-0.7-1.9c0-0.9,0.3-1.6,1-2.2c0.6-0.6,1.4-0.8,2.3-0.8c0.7,0,1.3,0.2,1.8,0.5s0.9,0.8,1.3,1.4L119.59,102.28z"
        />
        <Path
          fill="#A1CE57"
          d="M126.99,99.38l5.3,12.4h-1.5l-1.4-3.4h-5.1l-1.4,3.4h-1.4L126.99,99.38z M124.99,107.28h4l-2-4.7
                L124.99,107.28z"
        />
        <Path
          fill="#A1CE57"
          d="M134.89,99.98v10.6h3.3v1.2h-4.6v-11.9h1.3V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M145.49,99.98v1.2h-4.8v3.5h4.7v1.2h-4.7v4.7h4.8v1.2h-6.1v-11.9h6.1V99.98z"
        />
        <Path
          fill="#A1CE57"
          d="M154.09,99.28l4.3,10l4.3-10l2.4,12.5h-1.3l-1.5-8l0,0l-3.8,8.5l-3.8-8.5l0,0l-1.5,8h-1.4L154.09,99.28z"
        />
        <Path
          fill="#A1CE57"
          d="M165.99,105.88c0-1.1,0.3-2.1,0.8-3.1c0.6-0.9,1.3-1.7,2.3-2.3c1-0.6,2-0.8,3.1-0.8s2.1,0.3,3.1,0.8
                c0.9,0.5,1.7,1.3,2.3,2.2c0.6,1,0.8,2,0.8,3.1s-0.3,2.1-0.8,3c-0.6,0.9-1.3,1.7-2.2,2.2c-0.9,0.6-2,0.8-3.1,0.8s-2.1-0.3-3.1-0.8
                s-1.7-1.3-2.3-2.2C166.19,107.98,165.99,106.98,165.99,105.88z M172.09,100.98c-0.9,0-1.7,0.2-2.4,0.7s-1.3,1.1-1.8,1.8
                c-0.4,0.7-0.6,1.6-0.6,2.4s0.2,1.7,0.7,2.4c0.4,0.8,1,1.4,1.8,1.8c0.7,0.4,1.6,0.7,2.4,0.7c0.9,0,1.7-0.2,2.4-0.7
                c0.8-0.5,1.4-1.1,1.8-1.8c0.4-0.8,0.6-1.6,0.6-2.4c0-0.9-0.2-1.7-0.7-2.5c-0.4-0.8-1-1.4-1.8-1.8
                C173.79,101.18,172.99,100.98,172.09,100.98z"
        />
        <Path
          fill="#A1CE57"
          d="M181.89,99.98c2.8,0,4.2,1.1,4.2,3.3c0,0.9-0.3,1.7-0.8,2.3c-0.5,0.6-1.3,0.9-2.2,1l3.8,5.3h-1.6l-3.6-5.2
                h-0.3v5.2h-1.3v-11.9C180.09,99.98,181.89,99.98,181.89,99.98z M181.39,105.48h0.6c0.9,0,1.6-0.2,2.1-0.5s0.7-0.9,0.7-1.6
                c0-0.8-0.2-1.3-0.7-1.7c-0.5-0.3-1.1-0.5-2-0.5h-0.7L181.39,105.48L181.39,105.48z"
        />
        <Path
          fill="#A1CE57"
          d="M194.29,99.98v1.2h-2.9v10.6h-1.3v-10.6h-2.9v-1.2H194.29z"
        />
        <Path
          fill="#A1CE57"
          d="M204.39,103.28c-0.5-0.7-1.1-1.3-1.8-1.7c-0.7-0.4-1.4-0.6-2.3-0.6c-0.9,0-1.7,0.2-2.4,0.7
                s-1.3,1.1-1.8,1.8c-0.4,0.8-0.7,1.6-0.7,2.5s0.2,1.7,0.7,2.5s1.1,1.3,1.8,1.8c0.7,0.4,1.5,0.6,2.2,0.6s1.4-0.2,2-0.5
                s1.2-0.8,1.6-1.3c0.4-0.6,0.6-1.2,0.6-1.9h-3.5v-1.2h4.9v0.7c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.2-1.2,1.7s-1.2,0.9-1.9,1.2
                c-0.7,0.3-1.4,0.4-2.2,0.4c-1.1,0-2.1-0.3-3-0.8s-1.7-1.3-2.2-2.2c-0.6-0.9-0.8-2-0.8-3c0-1.1,0.3-2.1,0.8-3.1
                c0.6-1,1.3-1.7,2.3-2.3c1-0.6,2-0.8,3.1-0.8c1,0,2,0.2,2.8,0.7c0.8,0.4,1.6,1.1,2.2,1.9L204.39,103.28z"
        />
        <Path
          fill="#A1CE57"
          d="M211.49,99.38l5.3,12.4h-1.5l-1.4-3.4h-5.1l-1.4,3.4h-1.4L211.49,99.38z M209.49,107.28h4l-2-4.7
                L209.49,107.28z"
        />
        <Path
          fill="#A1CE57"
          d="M227.09,103.28c-0.5-0.7-1.1-1.3-1.8-1.7c-0.7-0.4-1.4-0.6-2.3-0.6c-0.9,0-1.7,0.2-2.4,0.7
                s-1.3,1.1-1.8,1.8c-0.4,0.8-0.7,1.6-0.7,2.5s0.2,1.7,0.7,2.5s1.1,1.3,1.8,1.8c0.7,0.4,1.5,0.6,2.2,0.6s1.4-0.2,2-0.5
                s1.2-0.8,1.6-1.3c0.4-0.6,0.6-1.2,0.6-1.9h-3.5v-1.2h4.9v0.7c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.2-1.2,1.7s-1.2,0.9-1.9,1.2
                c-0.7,0.3-1.4,0.4-2.2,0.4c-1.1,0-2.1-0.3-3-0.8s-1.7-1.3-2.2-2.2c-0.6-0.9-0.8-2-0.8-3c0-1.1,0.3-2.1,0.8-3.1
                c0.6-1,1.3-1.7,2.3-2.3c1-0.6,2-0.8,3.1-0.8c1,0,2,0.2,2.8,0.7c0.8,0.4,1.6,1.1,2.2,1.9L227.09,103.28z"
        />
        <Path
          fill="#A1CE57"
          d="M236.49,99.98v1.2h-4.8v3.5h4.7v1.2h-4.7v4.7h4.8v1.2h-6.1v-11.9h6.1V99.98z"
        />
      </G>
      <Rect x="0.59" y="89.08" fill="#B2B5B6" width="236" height="0.8" />
      <G>
        <G>
          <Path
            fill="#005F9E"
            d="M0.59,1.48h12.5v45.5c0,7,1.6,12.3,4.9,15.9c3.3,3.5,7.2,5.3,13.7,5.3c6.4,0,10.4-1.8,13.6-5.3
                    c3.3-3.5,4.9-8.8,4.9-15.9V1.48h12.6v46.7c0,10.6-2.7,18.5-8,23.7c-5.3,5.2-12.4,7.8-23.2,7.8c-10.7,0-17.8-2.6-23.1-7.8
                    c-5.3-5.3-7.9-13.2-7.9-23.8V1.48z"
          />
          <Path
            fill="#005F9E"
            d="M67.59,1.48h12.5l14.1,45.8l19-45.8l19.7,45.9l13.6-45.9h12.6l-24.4,79l-21.6-50.6l-21.1,50.6L67.59,1.48
                    z"
          />
        </G>
        <Path
          fill="#005F9E"
          d="M218.69,33.28l-24,32.7l-22.5-32.7l-5.3,46.2h-12.6l8.9-79.4l31.4,44.2l32.9-44.2l8.9,79.4h-12.6
                L218.69,33.28z"
        />
      </G>
    </G>
    <G>
      <Path
        fill="#005F9E"
        d="M236.09,0.98c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.2,5.1c-2.8,0-5.1-2.3-5.1-5.1
            C230.99,3.28,233.29,0.98,236.09,0.98z M236.09,1.98c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1
            C240.19,3.88,238.39,1.98,236.09,1.98z"
      />
      <Path
        fill="#005F9E"
        d="M235.29,8.68c-0.5,0-0.9,0-1.4,0c0-1.8,0-3.6,0-5.4l0,0c0.8,0,1.7,0,2.5,0c0.3,0,0.5,0.1,0.7,0.1
            c0.5,0.2,0.8,0.5,0.9,1.1c0,0.5,0,0.9-0.4,1.3c-0.2,0.2-0.4,0.3-0.6,0.4c0.5,0.2,0.7,0.5,0.9,0.9c0.3,0.5,0.5,1,0.7,1.5
            c0,0,0,0,0,0.1h-0.1c-0.4,0-0.8,0-1.3,0c-0.1,0-0.1,0-0.1-0.1c-0.2-0.4-0.4-0.9-0.6-1.3c0-0.1-0.1-0.2-0.1-0.2
            c-0.2-0.3-0.4-0.4-0.8-0.4c-0.1,0-0.2,0-0.3,0s-0.1,0-0.1,0.1c0,0.6,0,1.2,0,1.8C235.29,8.58,235.29,8.58,235.29,8.68z
             M235.29,5.68c0.2,0,0.4,0,0.5,0s0.2,0,0.3,0c0.3-0.1,0.5-0.2,0.5-0.6v-0.1c0-0.4-0.2-0.6-0.5-0.6s-0.6,0-0.8,0l0,0
            C235.29,4.78,235.29,5.18,235.29,5.68z"
      />
    </G>
    <Path fill="none" d="M-0.03,0.08h242v113h-242V0.08z" />
  </Svg>
);
export default UWMLogo;
