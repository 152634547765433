import { MenuButton as ChakraMenuButton } from '@chakra-ui/react';
import Box from 'common/components/Box';

const MenuButton = ({ children, ...props }) => {
  return <ChakraMenuButton {...props}>{children}</ChakraMenuButton>;
};

export default MenuButton;

MenuButton.propTypes = Box.propTypes;
