import Box from 'common/components/Box';
import styled from '@emotion/styled';

export const StyledClassifyRightContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 798px;
  height: 100%;
  border-left: none;
  background-color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

export const StyledClassifyHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 72px;
  padding: 20px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[200]};
`;

export const StyledClassifyBody = styled(Box)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 36px;
  height: 100%;
`;

export const StyledClassifyFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px;
  height: 72px;
  border-top: 1px solid ${(props) => props.theme.colors.gray[200]};
`;
