import { CLASSIFICATION } from '../constants';
import language from '../language.json';
import { HomeActions } from 'pages/Home/redux/';
import { CATEGORY_IDS } from 'pages/Home/constants';

/**
 * Returns next document from action needed documents, if action needed document list
 * is empty returns first document from completed documents
 *
 * @param {object} allDocuments
 * @param {number} currentDocumentId
 * @return {object} next document in the list
 */
export const getNextDocument = (allDocuments, currentDocumentId = null) => {
  const actionNeededDocuments = [];
  const completedDocuments = [];
  let currentDocument = null;

  allDocuments?.forEach((document) => {
    if (document.groupCategoryName === CLASSIFICATION.actionNeeded) {
      actionNeededDocuments.push(document);
    } else if (document.groupCategoryName === CLASSIFICATION.completed) {
      completedDocuments.push(document);
    }

    if (document.documentId === currentDocumentId) currentDocument = document;
  });

  if (currentDocumentId === 'pageLoad') {
    return actionNeededDocuments.length > 0
      ? actionNeededDocuments[0]
      : completedDocuments[0] || null;
  }

  if (
    !currentDocument ||
    currentDocument.groupCategoryName === CLASSIFICATION.completed
  ) {
    return actionNeededDocuments[0] || null;
  }

  if (currentDocument.groupCategoryName === CLASSIFICATION.actionNeeded) {
    if (actionNeededDocuments.length <= 1) return null;

    return actionNeededDocuments[0].documentId === currentDocumentId
      ? actionNeededDocuments[1]
      : actionNeededDocuments[0];
  }

  return null;
};

/**
 * Returns next document from action needed documents, if action needed document list
 * is empty returns first document from completed documents
 *
 * @param {object} allDocuments
 * @param {number} currentDocumentId
 * @return {object} next document in the list
 */
export const getNextDocumentAndSave = (
  allDocuments,
  currentDocumentId = null,
) => {
  const actionNeededDocs = [];
  const completedDocs = [];
  let currentDocumentFoundInActionNeeded = false;
  let currentDocumentFoundInCompleted = false;
  let currentDocumentIndexInActionNeeded = -1;
  let currentDocumentIndexInCompleted = -1;

  // Categorize documents and find the current document index
  for (let i = 0; i < allDocuments.length; i++) {
    const doc = allDocuments[i];
    if (doc.groupCategoryName === CLASSIFICATION.actionNeeded) {
      actionNeededDocs.push(doc);
      if (doc.documentId === currentDocumentId) {
        currentDocumentFoundInActionNeeded = true;
        currentDocumentIndexInActionNeeded = actionNeededDocs.length - 1;
      }
    } else if (doc.groupCategoryName === CLASSIFICATION.completed) {
      completedDocs.push(doc);
      if (doc.documentId === currentDocumentId) {
        currentDocumentFoundInCompleted = true;
        currentDocumentIndexInCompleted = completedDocs.length - 1;
      }
    }
  }

  // Determine the next document to return
  if (currentDocumentFoundInActionNeeded) {
    // Current document is in actionNeededDocs
    if (currentDocumentIndexInActionNeeded + 1 < actionNeededDocs.length) {
      return actionNeededDocs[currentDocumentIndexInActionNeeded + 1];
    } else {
      // If the current document is the last one in actionNeededDocs then return the first document in completedDocs
      return actionNeededDocs.length === 1
        ? completedDocs[0]
        : actionNeededDocs[0]; // Else Wrap around to the first actionNeededDocs
    }
  } else if (currentDocumentFoundInCompleted) {
    // Current document is in completedDocs
    if (actionNeededDocs.length > 0) {
      return actionNeededDocs[0]; // Prioritize actionNeededDocs
    } else if (currentDocumentIndexInCompleted + 1 < completedDocs.length) {
      return completedDocs[currentDocumentIndexInCompleted + 1];
    } else {
      return null; // No more documents to return
    }
  } else if (actionNeededDocs.length > 0) {
    // Current document is not found, return the first document in actionNeededDocs
    return actionNeededDocs[0];
  } else if (completedDocs.length > 0 && actionNeededDocs.length === 0) {
    // Current document is not found, return the first document in completedDocs
    return completedDocs[0];
  }

  return null;
};

export const sentenceCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function advanceToNextAlert(isClassifyCompleted, dispatch) {
  if (isClassifyCompleted) {
    dispatch(HomeActions.setIsUnderwriterCheckboxDisabled(false));
    dispatch(HomeActions.setSelectedAlert(null));
    dispatch(HomeActions.setSelectedCategory(CATEGORY_IDS.CLASSIFY));
    return language.NEXT_ALERT;
  } else {
    return language.NO_ACTION;
  }
}
