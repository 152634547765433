import Stack from 'common/components/Stacks/Stack';
import { TITLE_ICON_TYPE } from '../../QuestionBox/constants';
import DetailedAnswer from './DetailedAnswer';
import ExtractionDataIcon from './ExtractionDataIcon';
import PropTypes from 'prop-types';

const ConfidenceFooter = ({
  detailedAnswer = '',
  confidenceLevel = 0,
  handleConfidenceLevelColor = () => {},
  showGaugeIcon,
}) => {
  const iconColor = handleConfidenceLevelColor(confidenceLevel);
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'left',
        alignItems: 'center',
        width: '100%',
        paddingTop: '1rem',
      }}
    >
      {showGaugeIcon && (
        <ExtractionDataIcon
          isConfidence={true}
          data-tracking="show-context"
          sx={{ fontSize: 'clamp(1.5rem, 1.5rem, 2rem)' }}
          iconType={TITLE_ICON_TYPE.GAUGE}
          color={iconColor}
        />
      )}
      {!!detailedAnswer && <DetailedAnswer detailedAnswer={detailedAnswer} />}
    </Stack>
  );
};

ConfidenceFooter.propTypes = {
  detailedAnswer: PropTypes.string,
  confidenceLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleConfidenceLevelColor: PropTypes.func,
  showGaugeIcon: PropTypes.bool,
};

export default ConfidenceFooter;
