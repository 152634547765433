import AlertDataModel from '../../../IncomeCalculator/model/AlertDataModel';

export default class RentalCalculationReviewBreakdownModel {
  constructor(data = {}) {
    this.calculationAmount = Object.prototype.hasOwnProperty.call(
      data,
      'calculationAmount',
    )
      ? data.calculationAmount
      : 0;

    this.formulas = Object.prototype.hasOwnProperty.call(data, 'formulas')
      ? data.formulas
      : [];

    this.calculatorType = Object.prototype.hasOwnProperty.call(
      data,
      'incomeCalculatorTypeId',
    )
      ? data.incomeCalculatorTypeId
      : '';

    this.alerts = Object.prototype.hasOwnProperty.call(data, 'alerts')
      ? data.alerts.map((alert) => new AlertDataModel(alert))
      : [];
  }

  getCalculationAmount() {
    return this.calculationAmount;
  }

  getFormulas() {
    return this.formulas[0];
  }

  getCalculatorType() {
    return this.calculatorType;
  }

  getAlerts() {
    return this.alerts;
  }
}
