import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClassifyRightContainer from './ClassifyRightContainer';
import actions from './redux/actions';
import {
  getClassifyDocuments,
  getDocumentTypeAssociations,
  getQuickMatchDocumentAssociations,
} from './redux/thunks';
import { API_STATUS, SIGNAL_R_STATUS } from 'common/constants';
import AltMessageScreen from './AltMessageScreen';
import { StyledClassifyGrid } from './StyledComponents/ClassifyWrapper.styles';
import { getNextDocument, advanceToNextAlert } from './utils/helpers';
import QuickMatch from './QuickMatch';
import { HomeActions } from 'pages/Home/redux';
import { CLASSIFY, QUICK_MATCH } from './constants';

const ClassifyDocumentsV2 = () => {
  const dispatch = useDispatch();
  const [adrTimer, setADRTimer] = useState(0);
  const [isADRCompleted, setIsADRCompleted] = useState(false);
  const [waitingOnADR, setWaitingOnADR] = useState(false);
  const [isADRResolvedOnTime, setIsADRResolvedOnTime] = useState(true);
  const {
    latestSignalRNotification,
    boltStatus: { value: isLoanInUnderwriting },
    user: { isUnderwriter },
    navClickedSelectedCategory,
    navigationItems,
    selectedAlert,
  } = useSelector(({ home }) => home);

  const {
    onFirstRender,
    adrCompletionStatus: {
      data: { adrCompletionStatusExists: adrCompletionStatus },
      status: getAdrCompletionAPICallStatus,
    },
    documentItems: {
      data: allDocuments,
      status: getClassifiedDocumentsAPICallStatus,
    },
    removeDocuments: { status },
    quickMatchDocumentAssociation: { status: quickMatchStatus },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  const isDataLoading =
    getAdrCompletionAPICallStatus === API_STATUS.GETTING ||
    (quickMatchStatus !== API_STATUS.SUCCESS && onFirstRender);

  const onGetAdrCompletionAPICallSuccess =
    getAdrCompletionAPICallStatus === API_STATUS.SUCCESS;

  const noDocuments =
    getClassifiedDocumentsAPICallStatus === API_STATUS.SUCCESS &&
    allDocuments.length === 0;

  const hasError =
    getClassifiedDocumentsAPICallStatus === API_STATUS.ERROR ||
    getAdrCompletionAPICallStatus === API_STATUS.ERROR;

  const nextDocument = getNextDocument(allDocuments, 'pageLoad');

  const removeDocumentSuccess = status === API_STATUS.SUCCESS;

  // This will be needed for logic if navigationItems are empty What should be displayed on screen for Classify
  const noNavigationItems =
    navigationItems.categories?.length === 0 &&
    getClassifiedDocumentsAPICallStatus === API_STATUS.SUCCESS;

  const showAltScreen =
    hasError ||
    waitingOnADR ||
    isDataLoading ||
    !isADRResolvedOnTime ||
    (isLoanInUnderwriting && !isUnderwriter) ||
    (noDocuments && !isLoanInUnderwriting) ||
    noNavigationItems;

  const brokerActionRequired = allDocuments.some(
    (document) => document.brokerActionRequired === true,
  );

  const isClassifyCompleted =
    !isUnderwriter &&
    isADRCompleted &&
    !brokerActionRequired &&
    getClassifiedDocumentsAPICallStatus === API_STATUS.SUCCESS;

  // This sets the selected document the Nav tab click.
  useEffect(() => {
    const shouldSetDocumentForUnderwriter =
      isUnderwriter && selectedAlert === null;

    const shouldSetDocument =
      (!isUnderwriter || shouldSetDocumentForUnderwriter) &&
      getClassifiedDocumentsAPICallStatus === API_STATUS.SUCCESS;

    if (shouldSetDocument || navClickedSelectedCategory === CLASSIFY) {
      if (nextDocument) {
        dispatch(actions.setSelectedDocument(nextDocument));
      }
      if (navClickedSelectedCategory === CLASSIFY) {
        dispatch(HomeActions.setClickedSelectedCategory(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navClickedSelectedCategory,
    getClassifiedDocumentsAPICallStatus,
    selectedAlert,
  ]);

  /**
   * get adrCompletionStatus from the api. If ADR is not completed, setup
   * a one minute timer and wait for signalR status for ADR
   */
  useEffect(() => {
    if (onGetAdrCompletionAPICallSuccess && adrCompletionStatus) {
      setIsADRCompleted(true);
      setIsADRResolvedOnTime(true);
    } else if (onGetAdrCompletionAPICallSuccess && !adrCompletionStatus) {
      // start a one minute timer and wait for ADR completion status from signalR
      const timer = setTimeout(() => {
        setIsADRResolvedOnTime(false);
        setWaitingOnADR(false);
      }, 60000);
      setWaitingOnADR(true);
      setADRTimer(timer);
    }
  }, [adrCompletionStatus, onGetAdrCompletionAPICallSuccess]);

  /**
   * after getAdrCompletionStatus api call is resolved with no ADR completion status
   * wait for the status from signalR and clear the timer
   */
  useEffect(() => {
    if (
      onGetAdrCompletionAPICallSuccess &&
      !isADRCompleted &&
      latestSignalRNotification.notificationType ===
        SIGNAL_R_STATUS.ADR_PROCESS_COMPLETED
    ) {
      clearTimeout(adrTimer);
      setIsADRCompleted(true);
      setWaitingOnADR(false);
      setIsADRResolvedOnTime(true);
    }
  }, [
    adrTimer,
    isADRCompleted,
    latestSignalRNotification.notificationType,
    onGetAdrCompletionAPICallSuccess,
  ]);

  const initiateData = useCallback(() => {
    dispatch(getClassifyDocuments(true));
    dispatch(getQuickMatchDocumentAssociations());
    dispatch(getDocumentTypeAssociations());
    dispatch(actions.setOnFirstRender(false));
    !isUnderwriter && dispatch(HomeActions.setSelectedAlert(null));
    isUnderwriter && dispatch(HomeActions.setSelectedAlert(QUICK_MATCH));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isADRCompleted && isLoanInUnderwriting !== true && onFirstRender) {
      initiateData();
    }
    if (isUnderwriter && isLoanInUnderwriting === true && onFirstRender) {
      initiateData();
    }
    if (removeDocumentSuccess) {
      initiateData();
      dispatch(actions.resetHideDocumentState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initiateData,
    isADRCompleted,
    isLoanInUnderwriting,
    isUnderwriter,
    onFirstRender,
    quickMatchStatus,
    removeDocumentSuccess,
  ]);

  // If the classify process is completed, go to the next alert
  useEffect(() => {
    advanceToNextAlert(isClassifyCompleted, dispatch);
  }, [isClassifyCompleted, dispatch, getClassifiedDocumentsAPICallStatus]);

  /**
   * -------- *
   * Template *
   * -------- *
   */

  if (showAltScreen) {
    return (
      <AltMessageScreen
        hasError={hasError}
        noNavigationItems={noNavigationItems}
        isADRResolvedOnTime={isADRResolvedOnTime}
        isDataLoading={isDataLoading}
        isLoanInUnderwriting={isLoanInUnderwriting}
        noDocuments={noDocuments}
        waitingOnADR={waitingOnADR}
      />
    );
  }

  return (
    <StyledClassifyGrid id="classifyDocumentRoot" as="section">
      {selectedAlert === QUICK_MATCH ? (
        <QuickMatch />
      ) : (
        <ClassifyRightContainer />
      )}
    </StyledClassifyGrid>
  );
};

const ClassifyDocumentsV2Memo = memo(ClassifyDocumentsV2);

export default ClassifyDocumentsV2Memo;
