import PropTypes from 'prop-types';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import ExtractionDataIcon from '../QuestionExtraction/components/ExtractionDataIcon';
import { TITLE_ICON_TYPE } from '../QuestionBox/constants';

function checkIfDirty(initial, value) {
  const pristine = `${initial}` === `${value}`;
  return !pristine;
}

export default function ExtractionStatusIcon({
  defaultStatus,
  fieldName,
  sx,
  ...rest
}) {
  const { getFieldState } = useFormApi();

  const fieldState = getFieldState(fieldName);

  const dirty = checkIfDirty(fieldState?.initial, fieldState?.value);

  const iconType =
    defaultStatus == 'Extraction'
      ? dirty
        ? 'User'
        : 'Extraction'
      : defaultStatus;

  if (!iconType || iconType === 'ExtractionFailed') {
    return null;
  }

  return (
    <ExtractionDataIcon
      iconType={
        iconType === 'Extraction' ? TITLE_ICON_TYPE.BOLT : TITLE_ICON_TYPE.USER
      }
      sx={sx}
      {...rest}
    />
  );
}
ExtractionStatusIcon.propTypes = {
  defaultStatus: PropTypes.oneOf(['Extraction', 'User', 'ExtractionFailed']),
  fieldName: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
