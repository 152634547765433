import PropTypes from 'prop-types';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import BreakdownContainer from 'pages/IncomeCalculator/Pages/ReviewBreakdown/BreakdownContainer';
import lang from 'pages/IncomeCalculator/Pages/ReviewBreakdown/language.json';
import SingleLineValue from 'pages/IncomeCalculator/Pages/ReviewBreakdown/styles/SingleLineValue';
import { FormTypes } from 'pages/IncomeCalculator/enums';

export default function NoCalcCalculationBreakdown({ selectedResult }) {
  return (
    <BreakdownContainer hideAccordion>
      <SingleLineValue
        title={lang.MONTHLY_INCOME}
        value={selectedResult.getInputAsFloat(
          FormTypes.NoCalcForm,
          0,
          'amount',
        )}
        isCurrency
      />
    </BreakdownContainer>
  );
}
NoCalcCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};
