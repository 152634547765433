import { sxMapper } from './sxMapper';

/** Takes the incoming props and convert to the mui equivalent props */
export const propsMapper = (props) => {
  const updatedProps = sxMapper(props);
  const { sx, as, className, ...otherProps } = updatedProps;

  return {
    ...otherProps,
    sx: sx,
    className: className,
    component: as ? as : otherProps?.component,
  };
};
