﻿import React from 'react';
import PropTypes from 'prop-types';
import Stack from 'common/components/Stacks/Stack';
import Alert from 'common/components/Alerts/Alert';
import AlertDataModel from '../../model/AlertDataModel';
import IncomeRecordData from '../../model/IncomeRecordDataModel';

const Alerts = ({ alerts, selectedIncome, ...props }) => {
  if (!alerts || alerts.length === 0) {
    return null;
  }

  const replacePlaceholders = (title) => {
    if (!selectedIncome) {
      return title;
    }

    return title.replace('{EmployerName}', selectedIncome.getSource());
  };

  return (
    <Stack data-testid="alerts-stack" spacing={3} mt={8} {...props}>
      {alerts.map((alert, i) => (
        <Alert title={alert.getTitle()} status={alert.getSeverity()} key={i}>
          {replacePlaceholders(alert.getDescription())}
        </Alert>
      ))}
    </Stack>
  );
};
Alerts.propTypes = {
  /** A list of alerts to display. */
  alerts: PropTypes.arrayOf(PropTypes.instanceOf(AlertDataModel)).isRequired,
  selectedIncome: PropTypes.instanceOf(IncomeRecordData),
};

export default Alerts;
