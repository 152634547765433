import { Menu as ChakraMenu } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Menu = ({ children, ...props }) => {
  return <ChakraMenu {...props}>{children}</ChakraMenu>;
};

export default Menu;

Menu.propTypes = {
  children: PropTypes.node,
  autoSelect: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  defaultIsOpen: PropTypes.bool,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  flip: PropTypes.bool,
  isLazy: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  placement: PropTypes.string,
  strategy: PropTypes.oneOf(['fixed', 'absolute']),
};
