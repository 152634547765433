import { useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'common/components/Accordions/Accordion';

// Local
import DocumentList from 'pages/ClassifyDocumentsV2/DocumentList';
import useGroupedDocuments from 'pages/ClassifyDocumentsV2/hooks/useGroupedDocuments';
import QuickMatchDocumentAssociation from 'pages/ClassifyDocumentsV2/QuickMatchDocumentAssociation';
import useLeftDrawerContext from '../../context/useLeftDrawerContext';
import { QUICK_MATCH } from 'pages/ClassifyDocumentsV2/constants';

export default function ClassifyNavAlertItems() {
  const groupedDocuments = useGroupedDocuments();
  const noActionNeeded = groupedDocuments[0].list.length === 0;
  const [accordionIndex, setAccordionIndex] = useState(0);
  const {
    quickMatchDocumentAssociation: { data: quickMatch },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  const selectedAlert = useSelector(
    ({ home: { selectedAlert } }) => selectedAlert,
  );

  const { isLeftDrawerCollapsed } = useLeftDrawerContext();

  const isQuickMatchData = Object.keys(quickMatch).length !== 0;
  const isQuickMatch = selectedAlert === QUICK_MATCH;

  const handleAccordionChange = (value) => {
    setAccordionIndex(value);
  };

  return (
    <nav aria-label="classify navigation">
      <Accordion
        index={[!isLeftDrawerCollapsed && accordionIndex]}
        onChange={handleAccordionChange}
        allowToggle
        maxHeight="728px"
      >
        {isQuickMatchData && (
          <QuickMatchDocumentAssociation
            documentAssociationActive={isQuickMatch}
            documentItems={quickMatch?.screenData?.quickMatchCategory ?? []}
            isActive={selectedAlert === QUICK_MATCH}
          />
        )}

        <DocumentList
          documentAssociationActive={isQuickMatch}
          documentItems={groupedDocuments}
          setAccordionIndex={setAccordionIndex}
          offset={isQuickMatchData && !noActionNeeded ? 1 : 0}
        />
      </Accordion>
    </nav>
  );
}
