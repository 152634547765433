import AddressDataModel from 'model/AddressDataModel';

export default class REOData {
  constructor(address = {}) {
    this.addressId = Object.prototype.hasOwnProperty.call(address, 'addressId')
      ? address.addressId
      : {
          addressId: 0,
          addressLdid: 0,
        };
    this.associatedLiabilities = Object.prototype.hasOwnProperty.call(
      address,
      'associatedLiabilities',
    )
      ? address.associatedLiabilities
      : [];
    this.isInsuranceAndTaxesEscrowed = Object.prototype.hasOwnProperty.call(
      address,
      'isInsuranceAndTaxesEscrowed',
    )
      ? address.isInsuranceAndTaxesEscrowed
      : false;

    this.isSubjectProperty = Object.prototype.hasOwnProperty.call(
      address,
      'isSubjectProperty',
    )
      ? address.isSubjectProperty
      : false;

    this.monthlyFloodInsuranceAmount = Object.prototype.hasOwnProperty.call(
      address,
      'monthlyFloodInsuranceAmount',
    )
      ? address.monthlyFloodInsuranceAmount
      : 0;

    this.monthlyHOAAmount = Object.prototype.hasOwnProperty.call(
      address,
      'monthlyHOAAmount',
    )
      ? address.monthlyHOAAmount
      : 0;

    this.monthlyInsuranceAmount = Object.prototype.hasOwnProperty.call(
      address,
      'monthlyInsuranceAmount',
    )
      ? address.monthlyInsuranceAmount
      : 0;

    this.monthlyTaxesAmount = Object.prototype.hasOwnProperty.call(
      address,
      'monthlyTaxesAmount',
    )
      ? address.monthlyTaxesAmount
      : 0;

    this.occupancyType = Object.prototype.hasOwnProperty.call(
      address,
      'occupancyType',
    )
      ? address.occupancyType
      : '';

    this.occupancyTypeDescription = Object.prototype.hasOwnProperty.call(
      address,
      'occupancyTypeDescription',
    )
      ? address.occupancyTypeDescription
      : '';

    this.propertyAddress = Object.prototype.hasOwnProperty.call(
      address,
      'propertyAddress',
    )
      ? new AddressDataModel(address.propertyAddress)
      : new AddressDataModel();

    this.propertyStatus = Object.prototype.hasOwnProperty.call(
      address,
      'propertyStatus',
    )
      ? address.propertyStatus
      : 0;
    this.propertyStatusString = Object.prototype.hasOwnProperty.call(
      address,
      'propertyStatusString',
    )
      ? address.propertyStatusString
      : '';

    this.propertyType = Object.prototype.hasOwnProperty.call(
      address,
      'propertyType',
    )
      ? address.propertyType
      : {
          propertyTypeAlias: '',
          propertyTypeDescription: '',
          propertyTypeId: 0,
        };
    this.mortgageStatementDocumentIds = Object.prototype.hasOwnProperty.call(
      address,
      'mortgageStatementDocumentIds',
    )
      ? address.mortgageStatementDocumentIds
      : [];

    this.taxDocumentIds = Object.prototype.hasOwnProperty.call(
      address,
      'taxDocumentIds',
    )
      ? address.taxDocumentIds
      : [];

    this.results = Object.prototype.hasOwnProperty.call(address, 'results')
      ? address.results
      : [
          {
            additionalData: null,
            message: '',
            questions: [
              {
                answers: [
                  {
                    additionalResolutionTextAnswers: [],
                    answerGroupSeq: 0,
                    answerText: '',
                    dtlAnswer: null,
                    fieldType: null,
                    nextQuestionIds: [],
                    processEndMessageActionDesc: null,
                    processEndMessageActionId: null,
                    processEndMessageActionName: null,
                    questionAnswerId: 0,
                    resolutionText: '',
                  },
                ],
                isVisibleOnLoad: false,
                questionDisplayFormat: 0,
                questionId: 0,
                questionSubtext: '',
                questionText: '',
                userResponse: {
                  currentAnswerText: '',
                  questionAnswerId: 0,
                  questionUserResponseID: 0,
                  ruleResultDetailId: null,
                },
              },
            ],
            ruleDtails: {
              categoryId: 0,
              parentRuleId: null,
              ruleDisplayDescription: '',
              ruleDisplayTitle: '',
              ruleId: 0,
              subCategoryId: 0,
            },
            status: 0,
          },
        ];
  }
}
