import TYPES from './types';

export const getSubjectPropertyDataStarted = () => ({
  type: TYPES.GET_SUBJECT_PROPERTY_DATA_STARTED,
});

export const getSubjectPropertyDataSuccess = (payload) => ({
  type: TYPES.GET_SUBJECT_PROPERTY_DATA_SUCCESS,
  payload,
});

export const getSubjectPropertyDataError = (payload) => ({
  type: TYPES.GET_SUBJECT_PROPERTY_DATA_ERROR,
  payload,
});

export const saveSubjectPropertyDataStarted = () => ({
  type: TYPES.SAVE_SUBJECT_PROPERTY_DATA_STARTED,
});

export const saveSubjectPropertyDataSuccess = (payload) => ({
  type: TYPES.SAVE_SUBJECT_PROPERTY_DATA_SUCCESS,
  payload,
});

export const saveSubjectPropertyDataError = (payload) => ({
  type: TYPES.SAVE_SUBJECT_PROPERTY_DATA_ERROR,
  payload,
});

const subjectPropertyActions = {
  getSubjectPropertyDataStarted,
  getSubjectPropertyDataSuccess,
  getSubjectPropertyDataError,
  saveSubjectPropertyDataStarted,
  saveSubjectPropertyDataSuccess,
  saveSubjectPropertyDataError,
};

export default subjectPropertyActions;
