const apiEndPoints = {
  // GET
  GET_QUESTION_ANSWERS_DATA_ASYNC:
    '/v1/PropertyInformation/GetQuestionAnswersDataAsync',
  GET_KNOW_YOUR_LOAN_SUMMARY: '/v1/KnowYourLoan/GetKnowYourLoanSummary',
  GET_YAML: 'sdui',
  GET_EMPLOYER_VALIDATION_PAY_STUB_INFO:
    'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataPaystub',
  GET_EMPLOYER_VALIDATION_W2_INFO:
    'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataW2',
  GET_EMPLOYER_VALIDATION_AWARD_LETTER_INFO:
    'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataAwardLetter',
  GET_VALIDATION_SCHEDULE_C_INFO:
    'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataScheduleC',
  GET_PURCHASE_AGREEMENT: '/v1/PurchaseAgreement/GetPurchaseAgreement',
  GET_EARLY_CLOSING_DISCLOSURE: '/v1/Property/GetQuestionAnswersDataAsync',
  GET_FHA_REQUIREMENTS: '/v1/PurchaseAgreement/GetFhaRequirements',
  GET_CREDIT_INFO: '/v1/CreditValidation/GetCreditValidationInfo',
  GET_CREDIT_REPORT: '/v1/CreditValidation/GetLatestCreditDocument',
  GET_LIABILTIES: '/v1/Liability/GetLiabilities',
  GET_LIABILTIES_VERIFY_DATA: '/v1/Liability/GetLiabilitiesVerifyData',
  GET_LIABILTIES_ASSOCIATE_DATA: '/v1/Liability/GetLiabilitiesAssociateData',
  CALCULATE_REMAINING_TERMS: '/v1/Liability/CalculateRemainingTerm',
  GET_DRIVER_LICENSE_VALIDATION_INFO:
    '/v1/DriversLicenseValidation/ValidateDriversLicenseInfo',
  GET_HOME_ALERTS: '/v1/Validation/GetAlertsSummary',
  GET_DOCUMENT_SNIPPET: '/v1/DriversLicenseValidation/GetDocumentSnippet',
  RETRIEVE_PDF: '/v1/ClassifyDocuments/GetDocumentPdf',
  GET_CITIZENSHIP_INFO: '/v1/Borrower1003Validation/GetCitizenship',
  GET_FIRST_TIME_HOME_BUYER_INFO:
    '/v1/Borrower1003Validation/GetFirstTimeHomeBuyer',
  GET_DOCUMENT: '/v1/DocumentExtraction/Document',
  GET_SNIPPET: '/v1/DocumentExtraction/Snippet',
  GET_UNDERWRITER_VERIFICATION_STATUS:
    '/v1/UnderwriterVerified/GetUnderwriterVerified',
  GET_EMPLOYMENT_REVIEW:
    '/v1/EmploymentIncomeValidation/GetEmploymentReviewData',
  GET_ASSETS_SUMMARY_INFORMATION:
    '/v1/AssetsInformation/GetAssetsSummaryInformation',
  GET_ASSETS_INFORMATION_FOR_ASSET_SUMMARY:
    '/v1/AssetsInformation/GetAssetsInformationForAssetSummary',
  GET_SINGLE_ASSET_INFORMATION:
    '/v1/AssetsInformation/GetSingleAssetInformation',
  GET_ASSET_RECONCILIATION_INFORMATION:
    '/v1/AssetsInformation/GetAssetReconciliationInformation',
  GET_MORTGAGE_HISTORY_QUESTION_ANSWERS:
    '/v1/CreditValidation/GetMortgageHistoryQuestionAnswers',
  GET_LOAN_STATUS_FLIP_ELIGIBILITY_DATA:
    '/v1/FinalReview/GetLoanStatusFlipEligibilityData',

  DELETE_ASSET_INFORMATION: '/v1/AssetsInformation/DeleteAsset',
  BATCH_DELETE_ASSET: '/v1/AssetsInformation/BatchDeleteAssets',
  RECONCILE_ASSETS: '/v1/SaveAssets/ReconcileAssets',

  GET_VALIDATE_DOCUMENT_QUESTION_ANSWERS_DATA_ASYNC:
    '/v1/EmploymentIncomeValidation/GetValidateSmartQuestionAnswers',
  SEARCH_BY_WIRE_INFO: '/v1/PropertyInformation/SearchByWireInfo',
  SAVE_VALIDATE_DOCUMENT_QUESTION_ANSWERS_DATA_ASYNC:
    '/v1/EmploymentIncomeValidation/UpdateEmploymentSmartQuestionAnswers',
  // POST, UPDATE & SAVE

  SAVE_FINAL_RUN_VALIDATION_RESULT:
    '/v1/FinalReview/SaveFinalRunValidationResult',
  CHANGE_LOAN_STATUS: '/v1/FinalReview/ChangeLoanStatus',
  CHANGE_LOAN_STATUS_SIGNAL_R: '/v1/FinalReview/FlipLoanStatus',
  GET_COMMUNICATION_PREFERENCES:
    'v1/CommunicationPreferences/GetCommunicationPreferences',
  SAVE_ASSET_SUMMARY: '/v1/SaveAssets/SaveAssetSummary',
  SAVE_PROPERTY_INFO_QUESTION_ANSWERS_DATA_ASYNC:
    '/v1/PropertyInformation/SavePropertyInformationQuestionAnswersData',
  SAVE_ASSET_QUESTIONS_ANSWERS: '/v1/SaveAssets/SaveAssetQuestionAnswers',
  UPDATE_ADDITIONAL_RESERVES: '/v1/AssetsInformation/UpdateAdditionalReserves',
  SAVE_MORTGAGE_HISTORY_ANSWER_DATA:
    '/v1/CreditValidation/SaveMortgageHistoryAnswerData',
  POST_SAVE_EARLY_CLOSING_DISCLOSURE:
    '/v1/Property/SaveQuestionAnswersDataAsync',
  POST_CHECK_PRODUCT_VALIDITY_BASED_ON_AUS_RESULT:
    '/v1/Submission/GetFinalReviewValidationAfterAUSRun',
  POST_SAVE_FIRST_TIME_HOME_BUYER_INFO:
    '/v1/Borrower1003Validation/SaveFirstTimeHomeBuyer',
  POST_PURCHASE_AGREEMENT: '/v1/PurchaseAgreement/UpdatePurchaseAgreement',
  CALCULATE_ALLOCATED_SELLER_CONCESSIONS:
    '/v1/Fees/CalculateAllocatedSellerConcessions',
  CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS:
    '/v1/Fees/CalculateMaxAllowableSellerConcessions',
  POST_COMPANY_PROFILE: '/companyProfile',
  POST_CREDIT_INFO: '/v1/CreditValidation/GetCreditValidationInfo',
  POST_CREDIT_VALIDATION_RESULTS:
    '/v1/CreditValidation/GetCreditValidationResults',
  POST_SAVE_CREDIT_ANSWERS: '/v1/CreditValidation/UpdateCreditValidationData',

  POST_SAVE_EMPLOYER_VALIDATION_DOCUMENTS:
    '/v1/EmploymentIncomeValidation/UpdateEmploymentAndQuestionAnswers',
  POST_SAVE_BORROWER_INFO_QUESTION_ANSWERS:
    '/v2/Validation/SaveBorrowerInfoQuestionAnswers',
  POST_CITIZENSHIP_INFO: '/v1/Borrower1003Validation/SaveCitizenship',
  POST_SAVE_UNDERWRITER_RULE_VERIFICATION:
    '/v1/UnderwriterVerified/SaveUnderwriterVerified',
  POST_APPROVAL_DATA: '/v1/BoltApproval/GetBoltApprovalData',
  POST_ERROR_LOG: '/v1/Error/ReportError',
  POST_SAVE_EMPLOYMENT_REVIEW_ANSWERS:
    '/v1/EmploymentIncomeValidation/UpdateEmploymentReviewAnswers',
  POST_SAVE_EMPLOYMENT_REVIEW_CPA_ANSWERS:
    '/v1/EmploymentIncomeValidation/InsertCpaInformation',

  UPDATE_LIABILTIES: '/v1/Liability/UpdateLiabilities',
  UPDATE_LIABILTIES_VERIFY_DATA: '/v1/Liability/UpdateLiabilitiesVerifyData',
  UPDATE_LIABILTIES_ASSOCIATE_DATA:
    '/v1/Liability/UpdateLiabilitiesAssociateData',

  UPDATE_FHA_REQUIREMENTS: '/v1/PurchaseAgreement/UpdateFhaRequirements',

  homeOwnerInsurance: {
    SAVE_HOI_POLICY_DATA: '/v1/PropertyInsurance/SaveInsuranceDataAsync',
    GET_HOI_POLICY_DATA: '/v1/PropertyInsurance/GetInsuranceDataAsync',
    SAVE_HOMEOWNER_INSURANCE_DATA:
      'v1/PropertyInsurance/SaveHomeownersInsuranceData',
    GET_HOMEOWNER_INSURANCE_DATA:
      '/v1/PropertyInsurance/GetHomeownersInsuranceData',
    TRY_ORDER_FLOOD_CERT: '/v1/PropertyInsurance/TryOrderFloodCertAsync',
  },

  REO: {
    GET_REO_SINGLE_DATA: '/v1/RealEstateOwned/GetRealEstateOwnedSingleProperty',
    SAVE_REO_DATA: '/v2/RealEstateOwned/SaveRealEstateOwned',
  },
  childSupport: {
    GET_CHILD_SUPPORT: '/v1/IncomeCalculator/GetOtherIncomeData',
    SAVE_CHILD_SUPPRT: '/v1/IncomeCalculator/SaveOtherIncomeData',
  },
  otherIncome: {
    GET_OTHER_INCOME: '/v1/IncomeCalculator/GetOtherIncomeData',
    SAVE_OTHER_INCOME: '/v1/IncomeCalculator/SaveOtherIncomeData',
  },

  incomeCalculator: {
    BASE_URL: '/v1/IncomeCalculator',
    GET_CUSTOMER_EMPLOYMENT_DETAILS:
      '/v1/EmploymentIncome/GetCustomerEmploymentDetail',
    GET_ASSOC_INCOME_CALCS: '/v1/incomeCalculator/GetCalculations',
    CALCULATE: '/v1/IncomeCalculator/RunCalculations',
    SAVE_EMPLOYMENT_INCOME: '/v1/IncomeCalculator/SaveEmploymentIncomeToLoan',
    GET_INCOME_SUMMARY_DATA: '/v1/IncomeSummary/Get',
    POST_SAVE_INCOME_DOCUMENT_SELECTION: '/v1/IncomeSummary/Save',
    GET_ASSOC_INCOME_DOCS:
      '/v1/DocumentAssociation/GetAssociatedDocumentsByIncome',
    GET_INCOME_SUMMARY_FHA_CHILD_SUPPORT:
      '/v1/IncomeSummary/GetIncomeSummaryAndQuestionAnswerChildSupportFha',
    SAVE_INCOME_SUMMARY_FHA_CHILD_SUPPORT:
      '/v1/IncomeSummary/SaveIncomeSummaryAndQuestionAnswerChildSupportFha',
    SAVE_EMPLOYMENT_ANSWER: '/v1/EmploymentIncome/SaveEmploymentAnswer',
    GET_CAN_RUN_LEASE_AGREEMENT_CALCULATOR:
      '/v1/IncomeCalculator/CanRunLeaseAgreementCalculator',
    GET_SELECTED_CALCULATOR_DATA:
      '/v1/IncomeCalculator/GetDataForSelectedCalculator',
    GET_EMPLOYMENT_INCOME_VALIDATION_DATA:
      'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationData',
    GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION:
      'v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataWithFieldResolution',
    GET_WVOE_VALIDATION_SUMMARY: '/v1/WVoeValidation/GetWvoeValidationSummary',
    GET_OTHER_INCOME_DATA: '/v1/IncomeCalculator/GetOtherIncomeData',
    GET_CALCULATION_HISTORY: '/v1/IncomeCalculator/GetCalculationsHistory',
    SAVE_RENTAL_INCOME_TO_LOAN: '/v1/IncomeCalculator/SaveRentalIncomeToLoan',
    //API to be filled in
    GET_PREVIOUS_EMPLOYMENT: '/v1/PreviousEmployment/GetPreviousEmploymentData',
    SAVE_PREVIOUS_EMPLOYMENT:
      '/v1/PreviousEmployment/SavePreviousEmploymentData',

    GET_CALCULATION_DATA: '/v1/IncomeCalculator/GetCalculationData',
    SAVE_CALCULATION_DATA: '/v1/IncomeCalculator/SaveCalculationDataToLoan',

    GET_INCOME_RESOLUTION: '/v1/IncomeResolution/GetIncomeResolutionDetails',
    SAVE_INCOME_RESOLUTION: '/v1/IncomeResolution/SaveIncomeResolutionDetails',

    GET_NO_DOCUMENTS_PROVIDED_QUESTION_ANSWERS:
      '/v1/EmploymentIncome/GetNoDocumentsProvidedQuestionAnswers',
    GET_SMART_DATA_FOR_SELECTED_CALCULATOR:
      '/v1/IncomeCalculator/GetSmartDataForSelectedCalculator',
    SAVE_SMART_DATA_FOR_SELECTED_CALCULATOR:
      '/v1/IncomeCalculator/RunSmartCalculations',
  },

  incomeReconciliation: {
    GET_INCOME_RECONCILIATION_DATA:
      '/v1/IncomeReconciliation/GetIncomeReconciliationData',
    SAVE_INCOME_RECONCILIATION_DATA:
      '/v1/IncomeReconciliation/SaveIncomeReconciliationData',
    CALCULATE_DEBT_TO_INCOME: '/v1/IncomeReconciliation/CalculateDebtToIncome',
  },

  loanInfo: {
    GET_LOAN_INFO: '/v1/LoanInfo/GetLoanInfo',
  },

  employmentIncome: {
    GET_EMPLOYMENT_QUESTIONS: '/v1/EmploymentIncome/GetEmploymentQuestions',
    GET_ANSWERED_QUESTIONS: '/v1/EmploymentIncome/GetAnsweredQuestions',
    REMOVE_INCOME_AND_EMPLOYMENT_INFO:
      'v1/EmploymentIncome/RemoveIncomeAndEmploymentInfo',
  },
  underwritingSummaries: {
    GET_SUMMARY_INCOME_DATA:
      'v1/UnderwritingSummaryIncome/GetUnderwritingSummaryIncomeData',
    UPSERT_SUMMARY_INCOME:
      'v1/UnderwritingSummaryIncome/UpsertUnderwritingSummaryIncome',
    DELETE_SUMMARY_INCOME:
      'v1/UnderwritingSummaryIncome/DeleteUnderwritingSummaryIncome',
  },

  underwritingSummariesLiability: {
    GET_LIABILITIES_SUMMARY_DATA:
      '/v1/UnderwritingSummaryLiabilities/GetLiabilitiesSummaryData',
    UPSERT_LIABILITIES_SUMMARY_DATA:
      '/v1/UnderwritingSummaryLiabilities/UpsertLiability',
    DELETE_LIABILITIES_SUMMARY_DATA:
      '/v1/UnderwritingSummaryLiabilities/DeleteLiabilities',
  },

  underwritingSummariesRealEstateOwned: {
    GET_REALESTATEOWNED_SUMMARY_DATA:
      '/v1/RealEstateOwned/GetRealEstateOwnedSummary',
    UPSERT_REALESTATEOWNED_SUMMARY_DATA:
      '/v1/RealEstateOwned/SaveRealEstateOwnedSummary',
    DELETE_REALESTATEOWNED_SUMMARY_DATA:
      '/v1/RealEstateOwned/DeleteRealEstateOwned',
  },
  rentalIncome: {
    GET_RENTAL_INCOME_DATA: '/v1/RentalIncomeValidation/GetRentalIncomeData',
    GET_RENTAL_INCOME_VALIDATION_INFO:
      '/v1/RentalIncome/GetRentalIncomeValidationInfo',
    POST_RENTAL_INCOME_VALIDATION_INFO:
      '/v1/RentalIncomeValidation/SaveRentalIncomeDocumentSelectionAndQuestionAnswer',
    GET_NO_DOCUMENTS_PROVIDED_QUESTION_ANSWERS:
      '/v1/RentalIncomeValidation/GetNoDocumentsProvidedQuestionAnswers',
    SAVE_RENTAL_INCOME_QUESTION_ANSWERS:
      '/v1/RentalIncomeValidation/SaveRentalIncomeQuestionAnswers',
  },

  validationData: {
    GET_INCOME_VALIDATION_KEY_DATES:
      '/v1/ValidationData/GetIncomeValidationKeyDates',
  },

  questions: {
    SAVE_ANSWERS: '/v1/Questions/Answers',
  },

  classifyDocuments: {
    // V1
    GET_DOCUMENT_TYPE_ASSOCIATIONS:
      '/v1/ClassifyDocuments/GetDocumentTypeAssociations',
    GET_PDF: '/v1/ClassifyDocuments/GetDocumentPdf',
    GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING:
      '/v1/ClassifyDocuments/GetClassifiedDocumentsForBatchProcessing',
    SAVE_BATCH_DOCUMENT_ASSOCIATIONS:
      '/v1/DocumentAssociation/SaveBatchDocumentAssociations',
    POST_ADR_COMPLETION_STATUS_EXISTS:
      '/v1/ClassifyDocuments/GetAdrCompletionStatusExists',
    POST_UPDATE_DOCUMENT_CLASSIFICATION:
      '/v1/ClassifyDocuments/UpdateDocumentClassification',
    SAVE_DOCUMENT_ASSOCIATION:
      '/v1/DocumentAssociation/SaveDocumentAssociations',
    GET_DOCUMENT_ASSOCIATION: '/v1/DocumentAssociation/GetDocumentAssociations',
    UPLOAD_BOLT_APPROVAL_LETTER:
      '/v1/ClassifyDocuments/UploadBoltApprovalLetter',
    SET_HIDE_DOCUMENT: '/v1/ClassifyDocuments/HideDocument',
    // V2
    GET_CLASSIFY_DOCUMENTS: '/v2/ClassifyDocuments/GetClassifiedDocuments',
    GET_DOCUMENT_TYPE_ASSOCIATIONS_V2:
      '/v2/ClassifyDocuments/GetDocumentTypeAssociations',
    GET_DOCUMENT_ASSOCIATIONS_V2:
      '/v2/DocumentAssociation/GetDocumentAssociations',
    SAVE_DOCUMENT_ASSOCIATION_V2:
      '/v2/DocumentAssociation/SaveDocumentAssociations',
  },

  core: {
    GET_LOAN_SUMMARY_V2: '/v2/Loan/GetLoanSummary',
    BAIL_OUT_AND_SUBMIT_TO_SETUP: '/v1/Submission/BailOutAndSubmitLoanToSetup',
    GET_BOLT_STATUS: '/v1/BoltStatus/GetBoltStatus',
    GET_CLASSIFY_DOCUMENTS_NOTIFICATION: '/v1/Rule/GetClassifyFlag',
    GET_NAVIGATION_DETAILS: '/v1/Loan/GetNavigationDetails',
    SUBMIT_LOAN_TO_SETUP: '/v1/Submission/SubmitLoanToSetup',
    TRIGGER_ALL_RULES: '/v1/Rule/TriggerAllRules',
    GET_LOAN_SUMMARY: '/v1/Loan/GetLoanSummary',
    GET_FEATURE_FLAGS: '/v1/Loan/GetBoltNavFeatureFlags',
    UW_CATEGORY_TRACKING: '/v1/Checkpoint/SendCheckpoint',
  },

  finalReview: {
    RUN_AUS: '/v1/AUS/RunAUS',
    SKIP_AUS: '/v1/AUS/SkipAUS',
    GET_AUS_RECOMMENDATION: '/v1/AUS/GetAUSRecommendation',
    RUN_BOLT_APPROVAL: '/v1/Submission/RunBoltApprovalProcess',
    GET_LOAN_CONDITIONS: '/v1/Conditions/GetLoanConditions',
    GET_INITIAL_VALIDATIONS: '/v1/Submission/GetInitialFinalReviewValidation',
    GET_MANUAL_CHECKS: '/v1/FinalReview/GetManualCheckStatus',
    SAVE_MANUAL_CHECKS: '/v1/FinalReview/SaveManualCheckStatus',
  },

  thirdPartyCheck: {
    GET_DOCUMENTS_CHECK_ALERT_STATUS: '/v1/FraudGuard/InsertFraudGuardRequest',
    GET_DOCUMENTS_ALERT_DATA: '/v1/FraudGuard/GetRedFlags',
    SAVE_THIRD_PARTY_CHECKS_STATUS: '/v1/FraudGuard/SaveThirdPartyChecksStatus',
    GET_LATEST_FRAUD_GUARD_DATA: '/v1/FraudGuard/GetLatestFraudGuardData',
    UPDATE_FRAUD_GUARD_VIEWED_INDICATOR:
      '/v1/FraudGuard/UpdateFraudGuardViewedIndicator',
  },

  driversLicense: {
    GET_BORROWERS_DATA:
      '/v1/DriversLicenseValidation/GetBorrowersDriversLicenseData',
    POST_DRIVER_LICENSE_VALIDATION_INFO:
      '/v1/DriversLicenseValidation/SaveBorrower',
  },

  subjectProperty: {
    GET_SUBJECT_PROPERTY:
      '/v1/Borrower1003Validation/GetSubjectPropertyOccupancy',
    POST_SUBJECT_PROPERTY:
      '/v1/Borrower1003Validation/SaveSubjectPropertyOccupancy',
  },

  auditHistory: {
    IS_AUDIT_HISTORY_FEATURE_ENABLED:
      '/v1/BoltHistory/IsAuditHistoryFeatureEnabled',
    GET_BOLT_AUDITY_HISTORY: '/v1/BoltHistory/GetBoltAuditHistory',
    GET_AUDIT_HISTORY_DATA: '/v1/BoltAudit/GetBoltAudit',
    GET_ASSET_AUDIT_HISTORY: '/v1/BoltAudit/GetAssetDepositoryAudit',
    GET_BOLT_AUDIT_1003: '/v1/BoltAudit/GetBoltAuditSummaryScreen',
  },

  validation: {
    SAVE_QUESTION_ANSWERS: '/v2/Validation/SaveQuestionAnswers',
  },

  NON_OCCUPYING_CO_BORROWER: {
    GET: '/v1/Borrower1003Validation/GetNonOccupyingCoBorrowerInfo',
    SAVE: '/v1/Borrower1003Validation/SaveNonOccupyingCoBorrowerInfo',
  },
};

export default apiEndPoints;
