import TYPES from './types';
import { CITIZENSHIP_API_STATUS } from '../constants';
import { setCitizenshipData } from '../model/CitizenshipController';
import { INITIAL_CITIZENSHIP_INFO } from '../constants';

const initialState = {
  status: null,
  error: null,
  data: setCitizenshipData(INITIAL_CITIZENSHIP_INFO),
};

const CitizenshipReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CITIZENSHIP_INFO_STARTED:
      return {
        ...state,
        status: CITIZENSHIP_API_STATUS.GETTING,
        error: null,
      };
    case TYPES.UPDATE_CITIZENSHIP_INFO_STARTED:
      return {
        ...state,
        status: CITIZENSHIP_API_STATUS.UPDATING,
        error: null,
      };
    case TYPES.GET_CITIZENSHIP_INFO_SUCCESS:
      return {
        ...state,
        data: setCitizenshipData(action.payload),
        status: CITIZENSHIP_API_STATUS.GET_SUCCESS,
        error: null,
      };
    case TYPES.UPDATE_CITIZENSHIP_INFO_SUCCESS:
      return {
        ...state,
        status: CITIZENSHIP_API_STATUS.UPDATE_SUCCESS,
        error: null,
      };
    case TYPES.GET_CITIZENSHIP_INFO_ERROR:
      return {
        ...state,
        status: CITIZENSHIP_API_STATUS.GET_ERROR,
        error: action.error,
      };
    case TYPES.UPDATE_CITIZENSHIP_INFO_ERROR:
      return {
        ...state,
        status: CITIZENSHIP_API_STATUS.UPDATE_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};

export default CitizenshipReducer;
