/* c8 ignore file */

import { connect } from 'react-redux';
import IncomeHeader from './layout';

function mapStateToProps({ incomeCalculator }) {
  return {
    selectedIncome: incomeCalculator.selectedIncome,
  };
}
const IncomeHeaderHOC = connect(mapStateToProps, null)(IncomeHeader);
export default IncomeHeaderHOC;
