import { isEmpty } from 'lodash';

import { RULE_CATEGORY_ID, INCOME_RULE_ID, RULE_ID } from 'common/constants';

const IncomePagesWithUnderwriterCheckbox = [
  INCOME_RULE_ID.INCOME_REVIEW_BREAKDOWN_RULE_ID,
  INCOME_RULE_ID.RENTAL_INCOME_CALCULATION_RULE_ID,
  INCOME_RULE_ID.REVIEW_BREAKDOWN_YAML_RULE_ID,
];

const PropertyPagesWithUnderwriterCheckbox = [
  RULE_ID.CPL_REVIEW_RULE,
  RULE_ID.WIRE_INSTRUCTIONS_RULE,
  RULE_ID.TITLE_COMMITMENT_RULE,
  RULE_ID.PURCHASE_AGREEMENT_RULE_ID,
  RULE_ID.SELLER_ACQUISITION_RULE,
];

export const isVisible = (
  isUnderwriter,
  selectedAlert,
  boltStatus,
  isZappedLoan,
) => {
  const isUnderwriterCheckboxVisible =
    selectedAlert?.isUnderwriterCheckboxVisible;

  const isPropertyPageWithCheckbox =
    PropertyPagesWithUnderwriterCheckbox.includes(selectedAlert?.ruleId);

  const shouldDisplayUnderwriterCheckboxOnProperty =
    isPropertyPageWithCheckbox && isUnderwriterCheckboxVisible && isUnderwriter;

  if (shouldDisplayUnderwriterCheckboxOnProperty) {
    return true;
  }

  const isZappedLoanNonUnderwriterNonBolt =
    isEmpty(selectedAlert) ||
    !isUnderwriter ||
    !boltStatus?.value ||
    isZappedLoan;
  if (isZappedLoanNonUnderwriterNonBolt) return false;

  if (
    (selectedAlert?.ruleCategoryId === RULE_CATEGORY_ID.INCOME &&
      !selectedAlert.ruleResultId &&
      IncomePagesWithUnderwriterCheckbox.every(
        (pageId) => pageId !== selectedAlert?.ruleId,
      )) ||
    isUnderwriterCheckboxVisible === false
  )
    return false;
  if (
    !isUnderwriterCheckboxVisible &&
    selectedAlert?.ruleCategoryId !== RULE_CATEGORY_ID.INCOME
  )
    return false;

  return true;
};

export const determineAutoCheck = (
  isZapped,
  shouldAutoCheck = false,
  ruleId,
) => {
  if (PropertyPagesWithUnderwriterCheckbox.includes(ruleId)) {
    return false;
  }

  return isZapped || shouldAutoCheck;
};
