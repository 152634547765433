export const handleParcelIdLabelChange = ({
  values,
  isFirstParcelInput,
  setLabel,
}) => {
  const multipleParcelsQuestionAnswer =
    values['775bff60-367a-4c1c-94d0-e6b5799f7226'] ?? null;

  if (multipleParcelsQuestionAnswer === 'Yes' && isFirstParcelInput) {
    setLabel('Parcel 1');
  } else if (multipleParcelsQuestionAnswer === 'No' && isFirstParcelInput) {
    setLabel('Parcel ID');
  }
};
