import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';

// Local
import ClassifyStaticFormContent from './ClassifyStaticFormContent';
import ClassifyDynamicFormContent from './ClassifyDynamicFormContent';
import ClassifyHideDocsModal from './ClassifyHideDocsModal';
import { FORM_DESCRIPTION } from './language.json';

// Styles
import { StyledClassifyFormContentContainer } from './StyledComponents/ClassifyFormContent.styles';

const ClassifyFormContent = ({ rhfProps }) => {
  return (
    <StyledClassifyFormContentContainer as="section">
      <Text fontSize="sm" sx={{ mb: 6 }} id="formInstructions">
        {FORM_DESCRIPTION}
      </Text>
      <ClassifyStaticFormContent rhfProps={rhfProps} />
      <ClassifyDynamicFormContent rhfProps={rhfProps} />

      <ClassifyHideDocsModal />
    </StyledClassifyFormContentContainer>
  );
};

ClassifyFormContent.propTypes = {
  rhfProps: PropTypes.object.isRequired,
};

export default ClassifyFormContent;
