import { Textarea as ChakraTextarea } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Textarea = forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraTextarea {...props} ref={ref}>
      {children}
    </ChakraTextarea>
  );
});

Textarea.displayName = 'TextArea';

export default Textarea;

Textarea.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
