﻿import CalculationDocumentDataModel from './CalculationDocumentDataModel';
import DataExtractionDataModel from 'common/components/SnippetViewer/models/DataExtractionDataModel';
import { DocumentTypes } from '../enums';

export default class {
  constructor(data = {}) {
    this.calculatorTypeId = Object.hasOwnProperty.call(data, 'calculatorTypeId')
      ? data.calculatorTypeId
      : 0;

    this.calculationId = Object.hasOwnProperty.call(data, 'calculationId')
      ? data.calculationId
      : 0;

    this.documentDetails = Object.hasOwnProperty.call(data, 'documentDetails')
      ? data.documentDetails.map((doc) => new CalculationDocumentDataModel(doc))
      : [];

    this.tip = Object.hasOwnProperty.call(data, 'tip') ? data.tip : '';

    this.toolTip = Object.hasOwnProperty.call(data, 'toolTip')
      ? data.toolTip
      : '';
  }

  /** @returns {number} The id of the current calculation in the database */
  getCalculationId() {
    return this.calculationId;
  }

  /** @returns {CalculationDocumentDataModel} A list of calculation input documents. */
  getDocuments() {
    return this.documentDetails;
  }

  /** @returns {CalculationDocumentDataModel} The tip description text. */
  getTip() {
    return this.tip;
  }

  /** @returns {CalculationDocumentDataModel} The toolTip description text for information. */
  getToolTip() {
    return this.toolTip;
  }

  /**
   * Get the first document that matches the given criteria.
   * @param documentTypeId {string} The DocHub type id of the document.
   * @param fiscalYear {number|null} The fiscal year of the document.
   * @returns {CalculationDocumentDataModel|null} The document, or null if it was not found.
   */
  getDocument(documentTypeId, fiscalYear = null) {
    const documentId = parseInt(documentTypeId);
    let search;
    if (fiscalYear) {
      search = (doc) =>
        doc.getDocumentTypeId() === documentId &&
        doc.getFiscalYear() === fiscalYear;
    } else {
      search = (doc) => doc.getDocumentTypeId() === documentId;
    }

    return this.getDocuments().find(search);
  }

  /**
   * Build the form data for prepopulating input fields.
   * @param type The type of data model
   * @returns [type] An array of data models.
   */
  buildPrepopFormData(type) {
    let years = {};

    this.getDocuments().forEach((doc) => {
      let docFields = {};

      doc.getInputFields().forEach((field) => {
        if (field.getName() === 'percentageOwnership') {
          if (
            doc.documentTypeId === parseInt(DocumentTypes.ScheduleGForm1120) ||
            doc.documentTypeId === parseInt(DocumentTypes.ScheduleK1Form1065) ||
            doc.documentTypeId === parseInt(DocumentTypes.ScheduleK1Form1120s)
          ) {
            field.getValue()
              ? (docFields[field.getName()] = parseFloat(
                  field.getValue(),
                )?.toString())
              : (docFields[field.getName()] = field.getValue());
          }
        } else if (
          doc.documentTypeId === parseInt(DocumentTypes.Form1120S) &&
          field.getName() === 'ordinaryBusinessIncome'
        ) {
          docFields['ordinaryIncome'] = field.getValue();
        } else if (
          doc.documentTypeId === parseInt(DocumentTypes.Form1065) &&
          field.getName() === 'ordinaryBusinessIncome'
        ) {
          docFields['ordinaryBusinessIncome1065'] = field.getValue();
        } else docFields[field.getName()] = field.getValue();
      });

      years[`${doc.getFiscalYear()}`] = {
        ...years[`${doc.getFiscalYear()}`],
        ...docFields,
        fiscalYear: doc.getFiscalYear(),
      };
    });

    return Object.keys(years).map((key) => new type(years[key]));
  }

  /**
   * Builds the data extraction model to be used in the extraction display.
   * @returns {DataExtractionDataModel} The full doc and snippet data.
   */
  buildDataExtraction() {
    const documents = this.getDocuments()
      .filter((doc) => !!doc.getDochubDocumentId())
      .map((doc) => ({
        fiscalYear: doc.getFiscalYear(),
        documentType: doc.getDocumentName(),
        documentId: doc.getDochubDocumentId(),
      }));

    const snippets = this.getDocuments()
      .map((doc) =>
        doc
          .getInputFields()
          .filter((field) => !!field.getSnippetId())
          .map((field) => ({
            fiscalYear: doc.getFiscalYear(),
            snippetId: field.getSnippetId(),
            fieldName: field.getName(),
            documentType: doc.getDocumentName(),
          })),
      )
      .flat();

    return new DataExtractionDataModel({
      documents,
      snippets,
    });
  }
}
