﻿/**
 * Calculated W2 data for a tax year.
 */
export default class W2AnnualDetailDataModel {
  constructor(ad = {}) {
    this.fiscalYear = Object.prototype.hasOwnProperty.call(ad, 'fiscalYear')
      ? ad.fiscalYear
      : 0;

    this.annualIncome = Object.prototype.hasOwnProperty.call(ad, 'annualIncome')
      ? ad.annualIncome
      : 0;

    this.formulaName = Object.prototype.hasOwnProperty.call(ad, 'formulaName')
      ? ad.formulaName
      : '';

    this.formula = Object.prototype.hasOwnProperty.call(ad, 'formula')
      ? ad.formula
      : '';

    this.monthlyYearToDate = Object.prototype.hasOwnProperty.call(
      ad,
      'monthlyYearToDate',
    )
      ? ad.monthlyYearToDate
      : 0;

    this.totalMonthsWorked = Object.prototype.hasOwnProperty.call(
      ad,
      'totalMonthsWorked',
    )
      ? ad.totalMonthsWorked
      : 0;
  }

  /**
   * @returns {number} The year the income was calculated for.
   */
  getFiscalYear() {
    return this.fiscalYear;
  }

  /**
   * @returns {number} The annual income for this year.
   */
  getAnnualIncome() {
    return this.annualIncome;
  }

  /**
   * @returns {number} The monthly year to date income
   */
  getMonthlyYearToDate() {
    return this.monthlyYearToDate;
  }

  /**
   * @returns {number} The number of months worked
   */
  getTotalMonthsWorked() {
    return this.totalMonthsWorked;
  }

  /**
   * @returns {string} The formula name of the annual income
   */
  getFormulaName() {
    return this.formulaName;
  }

  /**
   * @returns {string} The formula of the annual income
   */
  getFormula() {
    return this.formula;
  }
}
