import { Tag as ChakraTag } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Tag = ({ children, ...props }) => {
  return <ChakraTag {...props}>{children}</ChakraTag>;
};

export default Tag;

Tag.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
