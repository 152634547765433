import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AccordionPanel from 'common/components/Accordions/AccordionPanel';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { camelCase } from 'lodash';
import { sentenceCase } from './utils/helpers';
import useLeftDrawerContext from 'common/components/Nav/LeftDrawer/context/useLeftDrawerContext';

// Local
import Badge, { BADGE_COLOR } from 'common/components/Badge';
import Ring, { RING_COLOR } from 'common/components/Ring';
import { CLASSIFICATION } from './constants';
import actions from './redux/actions';
import { API_STATUS } from 'common/constants';
import { HomeActions } from 'pages/Home/redux';

// Styles
import {
  StyledAccordionItem,
  StyledCategoryGroupButton,
  StyledAccordionPanelItems,
  StyledCategoryText,
  StyledDocumentButton,
  StyledAccordionIcon,
} from './StyledComponents/DocumentList.styles';

const DocumentList = ({
  documentItems: groupCategoryItems,
  documentAssociationActive,
  setAccordionIndex,
  offset,
}) => {
  const dispatch = useDispatch();

  /**
   * -------------------- *
   * Local & Global state *
   * -------------------- *
   */

  const { selectedDocument, removeDocuments } = useSelector(
    ({ classifyDocumentsV2 }) => classifyDocumentsV2,
  );

  /**
   * -------------- *
   * Event handlers *
   * -------------- *
   */

  const handleDocumentClick = (event, document) => {
    event.preventDefault();
    if (document.documentId !== selectedDocument?.documentId) {
      if (removeDocuments.status === API_STATUS.ERROR) {
        dispatch(actions.resetHideDocumentState());
      }
      dispatch(actions.setSelectedDocument(document));
    }
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */

  return (
    <>
      {groupCategoryItems.map((groupCategoryItem, index) => (
        <DocumentCategory
          groupCategoryItem={groupCategoryItem}
          documentAssociationActive={documentAssociationActive}
          handleDocumentClick={handleDocumentClick}
          setAccordionIndex={setAccordionIndex}
          index={index + offset}
          key={index}
        />
      ))}
    </>
  );
};

/* eslint-disable react/prop-types */
function DocumentCategory({
  groupCategoryItem,
  documentAssociationActive,
  handleDocumentClick,
  setAccordionIndex,
  index,
}) {
  const { selectedDocument } = useSelector(
    ({ classifyDocumentsV2 }) => classifyDocumentsV2,
  );
  const { isLeftDrawerCollapsed, toggleLeftDrawer } = useLeftDrawerContext();
  const selectCategoryTopDocument = groupCategoryItem.list?.[0]?.list?.[0];

  const dispatch = useDispatch();

  // open accordion panel based on selected groupCategoryName
  // This pull the index and sets the correct accordion
  useEffect(() => {
    if (
      selectedDocument?.groupCategoryName !==
      groupCategoryItem.groupCategoryName
    ) {
      return;
    }
    setAccordionIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument, groupCategoryItem]);

  return (
    <Fragment key={`${groupCategoryItem.groupCategoryName}-${index}`}>
      {!!groupCategoryItem.count && (
        <StyledAccordionItem overflow="hidden scroll" maxHeight="850px">
          {({ isExpanded }) => (
            <>
              <StyledCategoryGroupButton
                data-tracking={camelCase(groupCategoryItem.groupCategoryName)}
                selected={
                  groupCategoryItem.groupCategoryName ===
                    selectedDocument?.groupCategoryName &&
                  !documentAssociationActive
                }
                onClick={() => {
                  isLeftDrawerCollapsed && toggleLeftDrawer();
                  // When the user clicks on the category, we want the first document in the category to be selected
                  dispatch(
                    actions.setSelectedDocument(selectCategoryTopDocument),
                  );
                  dispatch(HomeActions.setSelectedAlert(null));
                }}
              >
                <Badge
                  content={groupCategoryItem.count}
                  description={camelCase(groupCategoryItem.groupCategoryName)}
                  color={
                    groupCategoryItem.groupCategoryName ===
                    CLASSIFICATION.actionNeeded
                      ? BADGE_COLOR.RED
                      : BADGE_COLOR.GREEN
                  }
                />
                <Text
                  as="h3"
                  sx={{ ml: 2 }}
                  opacity={isLeftDrawerCollapsed ? 0 : 1}
                  whiteSpace="nowrap"
                  transform={
                    isLeftDrawerCollapsed ? 'translateX(-8px)' : 'translateX(0)'
                  }
                  transition="all 0.5s ease-in-out"
                >
                  {groupCategoryItem.groupCategoryName}
                </Text>
                <Box flexGrow={1} />
                <StyledAccordionIcon icon={faCaretUp} isExpanded={isExpanded} />
              </StyledCategoryGroupButton>
              <AccordionPanel p={0}>
                {groupCategoryItem.list?.map((categoryItem, index) => (
                  <StyledAccordionPanelItems
                    key={`${categoryItem.categoryName}-${index}`}
                    id={`categoryGroup${sentenceCase(
                      camelCase(groupCategoryItem.groupCategoryName),
                    )}${index}`}
                  >
                    <StyledCategoryText
                      as="header"
                      id={camelCase(
                        groupCategoryItem.groupCategoryName +
                          categoryItem.categoryName,
                      )}
                    >
                      <Badge
                        content={categoryItem.list.length}
                        description={`${camelCase(
                          groupCategoryItem.groupCategoryName,
                        )}${sentenceCase(
                          camelCase(categoryItem.categoryName),
                        )}`}
                        color={
                          groupCategoryItem.groupCategoryName ===
                          CLASSIFICATION.actionNeeded
                            ? BADGE_COLOR.RED
                            : BADGE_COLOR.GREEN
                        }
                      />
                      <Text as="h4" sx={{ ml: 2 }}>
                        {categoryItem.categoryName}
                      </Text>
                    </StyledCategoryText>
                    {categoryItem.list?.map((documentItem, index) => (
                      <StyledDocumentButton
                        data-tracking={`${camelCase(
                          categoryItem.categoryName,
                        )}-${index}`}
                        as="button"
                        onClick={(event) =>
                          handleDocumentClick(event, documentItem)
                        }
                        key={documentItem.documentId}
                        selected={
                          selectedDocument?.documentId ===
                          documentItem.documentId
                        }
                      >
                        <Ring
                          color={
                            groupCategoryItem.groupCategoryName ===
                            CLASSIFICATION.actionNeeded
                              ? RING_COLOR.RED
                              : RING_COLOR.GREEN
                          }
                        />
                        <Text
                          as="h4"
                          sx={{
                            ml: 2,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {documentItem.documentTypeName}
                        </Text>
                      </StyledDocumentButton>
                    ))}
                  </StyledAccordionPanelItems>
                ))}
              </AccordionPanel>
            </>
          )}
        </StyledAccordionItem>
      )}
    </Fragment>
  );
}

DocumentList.propTypes = {
  documentItems: PropTypes.array.isRequired,
  documentAssociationActive: PropTypes.bool,
  setAccordionIndex: PropTypes.func,
  offset: PropTypes.number,
};

export default DocumentList;
