import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Box from 'common/components/Box';
import Button from 'common/components/Button';
import Flex from 'common/components/Flex';
import Spinner from 'common/components/Spinner';
import Text from 'common/components/Texts/Text';
import { PageTitleMap } from './constants';
import language from './language.json';
import { toTitleCase } from 'common/util/format';

const TealButton = styled(Button)`
  margin-top: 1rem;
  margin-left: auto;
  border: 1px solid ${(props) => props.theme.colors.teal[200]};
  background-color: ${(props) => props.theme.colors.teal[100]};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.teal[100]};
  }
`;

const Container = styled(Box)`
  margin: 32px 24px;
`;

const StatusText = styled(Text)`
  padding: 16px 10px 16px 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
`;

function getPageTitle(pageEnum, category) {
  const pageTitle = PageTitleMap[pageEnum] || PageTitleMap[0];
  if (pageTitle === 'Unknown' && category === undefined) {
    return 'Unknown';
  }
  if (category !== undefined) {
    return toTitleCase(category);
  }
  return pageTitle;
}

export default function Layout({
  children,
  pageEnum,
  isError,
  isLoading,
  isDataEmpty,
  category,
}) {
  const theme = useTheme();
  const isSummary1003AuditPage = category !== undefined;

  if (isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container>
        <StatusText>{language.error}</StatusText>
      </Container>
    );
  }

  const pageTitle = getPageTitle(pageEnum, category); // Default to 0 if pageEnum is not found (eg: Unknown)

  const getSubheading = (isSummary, language, pageTitle, category) => {
    let subheadingTemplate = isSummary
      ? language.subheadingSummary
      : language.subheading;

    // Replace {{PAGE_TITLE}} with pageTitle
    subheadingTemplate = subheadingTemplate.replace(
      /{{PAGE_TITLE}}/g,
      pageTitle,
    );

    // If isSummary is true, replace {{CATEGORY}} with category
    if (isSummary) {
      subheadingTemplate = subheadingTemplate.replace(
        /{{CATEGORY}}/g,
        category,
      );
    }

    return subheadingTemplate;
  };

  const auditHistoryNotFound = language.notfound.replace(
    /{{PAGE_TITLE}}/g,
    pageTitle,
  );

  return (
    <Container as="section">
      <Text
        as="h2"
        data-testid="page-title"
        color="blue.700"
        borderBottom="2px solid"
        borderColor={theme.colors.teal[100]}
      >
        {pageTitle} {isSummary1003AuditPage && 'Summary'}
        <Text as="span" color={theme.colors.teal[100]} px={2}>
          |
        </Text>
        {language.heading}
      </Text>
      <Text as="p" py={4}>
        {getSubheading(isSummary1003AuditPage, language, pageTitle, category)}
      </Text>

      {isDataEmpty ? <StatusText>{auditHistoryNotFound}</StatusText> : children}

      <Flex>
        <TealButton
          onClick={() => {
            window.close();
          }}
        >
          Done
        </TealButton>
      </Flex>
    </Container>
  );
}
Layout.propTypes = {
  pageEnum: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isDataEmpty: PropTypes.bool,
  category: PropTypes.string,
};
