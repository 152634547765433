import PropTypes from 'prop-types';

const alertMessage = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  severity: PropTypes.oneOf([0, 1, 2, 3]).isRequired,
});

export default alertMessage;
