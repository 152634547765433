﻿export default class IncomeIdentifierDataModel {
  constructor(id = {}) {
    this.obfuscatedLenderDatabaseId = Object.prototype.hasOwnProperty.call(
      id,
      'obfuscatedLenderDatabaseId',
    )
      ? id.obfuscatedLenderDatabaseId
      : 0;

    this.customerRecordId = Object.prototype.hasOwnProperty.call(
      id,
      'customerRecordId',
    )
      ? id.customerRecordId
      : 0;

    this.employerId = Object.prototype.hasOwnProperty.call(id, 'employerId')
      ? id.employerId
      : 0;

    this.sequenceNumber = Object.prototype.hasOwnProperty.call(
      id,
      'sequenceNumber',
    )
      ? id.sequenceNumber
      : 0;
  }

  getLenderDatabaseId() {
    return this.obfuscatedLenderDatabaseId;
  }

  getCustomerId() {
    return this.customerRecordId;
  }

  getEmployerId() {
    return this.employerId;
  }

  getSequenceNumber() {
    return this.sequenceNumber;
  }
}
