import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'common/components';

import { formatCurrency } from 'common/util/format';
import BreakdownContainer from '../BreakdownContainer';
import lang from '../language.json';
import HeadingAndText from './HeadingAndText';
import PaystubYTD from './PaystubYTD';
import W2ItemsYTD from './W2ItemsYTD';
import ConsideredFormulas from '../styles/ConsideredFormulas';

function FormulaDisplay({ formula, consideredFormulas }) {
  if (consideredFormulas?.length) {
    return (
      <div data-testid="variable-formula">
        <Text fontWeight="bold" mb={1}>
          Calculations:
        </Text>
        <ConsideredFormulas consideredFormulas={consideredFormulas} />
      </div>
    );
  }

  if (!formula) {
    return null;
  }

  return (
    <div data-testid="variable-formula">
      <HeadingAndText heading="Calculation:" text={formula} />
    </div>
  );
}
FormulaDisplay.propTypes = {
  formula: PropTypes.string,
  consideredFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      formula: PropTypes.string,
      formulaType: PropTypes.string,
      incomeValue: PropTypes.number,
      isFormulaUsed: PropTypes.bool,
    }),
  ),
};

//TODO: <HeadingText /> and calculation data should work better as a data-list (<dl></dl>)
const VariableIncomeBreakdown = ({
  title = '',
  w2Items = [],
  formula = null,
  trendTitle = '',
  currentYearItems = {},
  pastYearsItems = [],
  calculatedVariableTitle = '',
  calculatedVariableAmount = 0,
  isBaseIncomeVariable = false,
  variablePercentageFormula,
  consideredFormulas,
  hideAccordion,
}) => {
  const { fiscalYear = 0, currentYearIncome = [] } = currentYearItems;
  const isBaseIncome = title.includes('Base');
  let showStaticHeading = true;
  if (isBaseIncome) {
    showStaticHeading =
      !trendTitle.includes('Declining') || trendTitle.includes('Stabilizing');
  }

  // FHA doesn't use trends, so don't show on screen
  if (trendTitle === '- Classic') {
    trendTitle = '';
  }

  return (
    <BreakdownContainer
      header={title}
      subheader={trendTitle}
      calculatedAmount={calculatedVariableAmount}
      hideAccordion={hideAccordion}
    >
      {!isBaseIncomeVariable && (
        <div data-testid="non-variable-static-text">
          <HeadingAndText
            heading="Percentage:"
            text={lang.W2_NON_VARIABLE_INCOME.PERCENTAGE}
          />
          <HeadingAndText
            heading={`Last Year Income:`}
            text={lang.W2_NON_VARIABLE_INCOME.LAST_YEAR_INCOME}
          />
          <HeadingAndText
            heading={`Prior Year Income:`}
            text={lang.W2_NON_VARIABLE_INCOME.PRIOR_YEAR_INCOME}
          />
          <HeadingAndText
            heading="Calculation Equations:"
            text={lang.W2_NON_VARIABLE_INCOME.CALCULATION_EQUATIONS}
          />
        </div>
      )}

      {isBaseIncomeVariable && showStaticHeading && (
        <div data-testid="variable-static-text">
          <HeadingAndText
            heading="Percentage:"
            text={lang.W2_VARIABLE_INCOME.PERCENTAGE}
          />
          <HeadingAndText
            heading="Calculation Equation:"
            text={lang.W2_VARIABLE_INCOME.CALCULATION_EQUATION}
          />
        </div>
      )}

      {!!currentYearIncome.length && (
        <div data-testid="paystub-ytd">
          <PaystubYTD
            fiscalYear={fiscalYear}
            currentYearIncome={currentYearIncome}
            variableTitle={
              isBaseIncomeVariable
                ? calculatedVariableTitle
                : `Variable ${calculatedVariableTitle
                    .replace(/:/g, '')
                    .replace('Income', '')}`
            }
          />
        </div>
      )}
      {showStaticHeading && (
        <Text mb={10}>Percentage: {variablePercentageFormula}</Text>
      )}

      {!!pastYearsItems.length && !!w2Items.length && (
        <div data-testid="w2-items-ytd">
          <W2ItemsYTD
            pastYearItems={pastYearsItems}
            w2Items={w2Items}
            variableTitle={
              isBaseIncomeVariable
                ? calculatedVariableTitle
                : `Variable ${calculatedVariableTitle
                    .replace(/:/g, '')
                    .replace('Income', '')}`
            }
            showCalcEquation={showStaticHeading}
          />
        </div>
      )}

      <FormulaDisplay
        formula={formula}
        consideredFormulas={consideredFormulas}
      />

      <HeadingAndText
        heading={calculatedVariableTitle}
        text={formatCurrency(calculatedVariableAmount)}
      />
    </BreakdownContainer>
  );
};

VariableIncomeBreakdown.propTypes = {
  /** The accordion title*/
  title: PropTypes.string.isRequired,
  /** The subtitle on the accordion*/
  trendTitle: PropTypes.string,
  /** The equation for the current calculation*/
  formula: PropTypes.string,
  /** The variable calculation title*/
  calculatedVariableTitle: PropTypes.string,
  /** The calculated amount*/
  calculatedVariableAmount: PropTypes.number.isRequired,
  /** The w2 objects  */
  w2Items: PropTypes.array.isRequired,
  /** The past years data*/
  pastYearsItems: PropTypes.array.isRequired,
  /** The current year income data*/
  currentYearItems: PropTypes.object.isRequired,
  /** The formula and values used for calculations */
  variablePercentageFormula: PropTypes.string,
  /** The base income is variable or not */
  isBaseIncomeVariable: PropTypes.bool,
  /** List of possible formulas for WVOE */
  consideredFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      formula: PropTypes.string,
      formulaType: PropTypes.string,
      incomeValue: PropTypes.number,
      isFormulaUsed: PropTypes.bool,
    }),
  ),
  hideAccordion: PropTypes.bool,
};

export default VariableIncomeBreakdown;
