import PropTypes from 'prop-types';
import Grid from 'common/components/Grids/Grid';
import GridItem from 'common/components/Grids/GridItem';
import Text from 'common/components/Texts/Text';
import { pixelsToRem } from 'theme/typography';

export default function BreakdownColumnBox({ title, children, ...props }) {
  return (
    <Grid {...props} templateColumns="fit-content(30%) 1fr" as="dl">
      <GridItem as="dt">
        <Text fontSize={pixelsToRem(14)} fontWeight="bold" d="inline" pr={3}>
          {title}
        </Text>
      </GridItem>
      <GridItem as="dd">{children}</GridItem>
    </Grid>
  );
}
BreakdownColumnBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
