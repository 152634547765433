import React from 'react';
import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';

const Formulas = ({ formulas }) => {
  const arr = formulas.map((formula) => formula.formula);
  const handleMultiLineFormula = (arr) => {
    const spaced = arr.map((val, i) => {
      if (val.trim() === '') {
        return <br key={i} />;
      } else {
        return val;
      }
    });
    return spaced.map((val, i) => (
      <Text fontSize="14px" d="inline" key={i}>
        {val}
      </Text>
    ));
  };

  return (
    <Flex pb={4} alignItems="flex-start">
      <Text mr={1} fontSize="14px" fontWeight="bold" d="inline">
        Calculation:
      </Text>
      <Flex direction="column">{handleMultiLineFormula(arr)}</Flex>
    </Flex>
  );
};
Formulas.propTypes = {
  formulas: PropTypes.array.isRequired,
};
export default Formulas;
