﻿import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import BreakdownYearContainer from './styles/BreakdownYearContainer';
import lang from 'pages/IncomeCalculator/language.json';
import GenericTooltip from 'common/components/GenericTooltip';

const TrendTitle = ({ isPositiveTrend }) => (
  <Text fontWeight={500} fontSize="lg">
    {lang.TREND_ANALYSIS} &mdash;&nbsp;
    {isPositiveTrend ? lang.POSITIVE_TREND : lang.NEGATIVE_TREND}&emsp;
    <GenericTooltip label={lang.TIP_TREND_ANALYSIS} />
  </Text>
);

const TrendingAnalysis = ({
  isPositiveTrend,
  grossMinusCogs,
  expenses,
  tangibleIncome,
}) => {
  if (!isPositiveTrend) {
    return null;
  }

  return (
    <BreakdownYearContainer
      title={<TrendTitle isPositiveTrend={isPositiveTrend === 'true'} />}
    >
      <Box fontSize="sm">
        <Text>
          {lang.PER_CHANGE_GROSS_MINUS_COGS}&nbsp;
          {grossMinusCogs * 100}%
        </Text>
        <Text pt={2}>
          {lang.PER_CHANGE_EXPENSES}&nbsp;
          {expenses * 100}%
        </Text>
        <Text pt={2}>
          {lang.PER_CHANGE_TANGIBLE}&nbsp;
          {tangibleIncome * 100}%
        </Text>
      </Box>
    </BreakdownYearContainer>
  );
};
TrendTitle.propTypes = {
  isPositiveTrend: PropTypes.bool.isRequired,
};
TrendingAnalysis.propTypes = {
  isPositiveTrend: PropTypes.oneOf(['true', 'false']),
  grossMinusCogs: PropTypes.number,
  expenses: PropTypes.number,
  tangibleIncome: PropTypes.number,
};

export default TrendingAnalysis;
