import * as actions from './actions';
import apiClient from 'common/util/api-client';
import API_ENDPOINTS from 'common/constants/api-endpoints';

export const getSubjectPropertyData = () => async (dispatch, getState) => {
  dispatch(actions.getSubjectPropertyDataStarted());
  try {
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();

    const { data } = await apiClient.post(
      API_ENDPOINTS.subjectProperty.GET_SUBJECT_PROPERTY,
      {
        obfuscatedLoanIdentifier,
      },
    );

    dispatch(actions.getSubjectPropertyDataSuccess(data));
  } catch (e) {
    dispatch(actions.getSubjectPropertyDataError(e.response?.data ?? e));
  }
};

export const saveSubjectPropertyData =
  (payload) => async (dispatch, getState) => {
    dispatch(actions.saveSubjectPropertyDataStarted());
    try {
      const {
        url: { obfuscatedLoanIdentifier },
      } = getState();
      const { data } = await apiClient.post(
        API_ENDPOINTS.subjectProperty.POST_SUBJECT_PROPERTY,
        {
          obfuscatedLoanIdentifier,
          ...payload,
        },
      );
      if (data !== true) throw new Error('Oops error saving your data');

      return dispatch(actions.saveSubjectPropertyDataSuccess(payload));
    } catch (error) {
      dispatch(
        actions.saveSubjectPropertyDataError(
          error.response?.data || error?.message,
        ),
      );
    }
  };

const subjectPropertyThunks = {
  getSubjectPropertyData,
  saveSubjectPropertyData,
};
export default subjectPropertyThunks;
