import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Table, Tbody, Thead, Tr, Th, Td } from 'common/components/Tables';
import Box from 'common/components/Box';
import { ChildrenWidget } from 'lib-node-amb-renderer';

import theme from 'theme';

export class DynamicTableWidget extends ChildrenWidget {
  columns;

  constructor(props) {
    super(props);
    this.columns = props.columns;
  }
}

function groupArrayElementsBySize(arr, size) {
  if (!Array.isArray(arr) || typeof size !== 'number' || size < 1) {
    throw new Error(
      'Invalid input! I need an array and a positive chunk size, darling!',
    );
  }
  let groupArray = []; // We will store our groups in this array

  for (let i = 0; i < arr.length; i += size) {
    groupArray.push(arr.slice(i, i + size)); // Slice parts of main array and push into our new groupArray
  }
  return groupArray; // Serving you the nicely grouped array hot and ready!
}

export default function DynamicTable({
  // colTitles should have an object with column & fieldName
  columns = [],
  fields = [],
  name,
  headerBg = theme.colors.blue[50],
  headerColor,
  tableBorderColor = theme.colors.gray[200],
  tableProps,
  colorScheme,
  sx,
  dynamicHeaderColName,
}) {
  const { renderForm } = useFormApi();
  const mappedFields = groupArrayElementsBySize(fields, columns.length);

  return (
    <Box>
      <Table
        id={`dynamicTable-${name}`}
        colorScheme={colorScheme}
        sx={{
          overflow: tableProps?.overflow ? tableProps.overflow : 'hidden',
          borderRadius: '5px',
          borderSpacing: 0,
          borderCollapse: 'separate',
          border: `1px solid ${tableBorderColor}`,
          'thead tr th': {
            textTransform: 'unset',
            color: theme.colors.black,
            fontSize: '0.875rem',
            fontWeight: 500,
            padding: '16px',
          },
          'thead tr th:not(:first-of-type), tbody tr td:not(:first-of-type)': {
            borderLeft: `1px solid ${tableBorderColor}`,
          },
          'tbody tr td div.chakra-text': {
            margin: 'unset',
          },
          'tbody tr:nth-of-type(even)': {
            backgroundColor:
              tableProps?.variant === 'striped' && tableProps?.color,
          },
          'tbody tr td:nth-child(2)': {
            padding: '16px',
          },
        }}
      >
        <Thead bg={headerBg} color={headerColor}>
          <Tr>
            {!!columns?.length &&
              columns.map(({ column, columnWidth }, i) => {
                return (
                  <Th
                    key={`${column}-${i}`}
                    width={columnWidth}
                    sx={sx}
                    scope="col"
                  >
                    {column === 'Select Record(s)'
                      ? `Select ${dynamicHeaderColName} Record(s)`
                      : column}
                  </Th>
                );
              })}
          </Tr>
        </Thead>
        <Tbody>
          {mappedFields.map((mf, idx) => {
            return (
              <Tr key={`row-${idx}`}>
                {mf.map((field, i) => {
                  return (
                    <Td key={`column-${idx}-${i}`} padding={field?.cellPadding}>
                      {renderForm([field])}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

DynamicTable.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  sx: PropTypes.object,
  fields: PropTypes.array,
  headerBg: PropTypes.string,
  headerColor: PropTypes.string,
  tableBorderColor: PropTypes.string,
  tableProps: PropTypes.object,
  colorScheme: PropTypes.string,
  dynamicHeaderColName: PropTypes.string,
};
