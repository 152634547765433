import PropTypes from 'prop-types';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

export default function Datalist({
  name,
  options,
  onChange,
  onFocus,
  onBlur,
  ...props
}) {
  const { input, meta, isRequired, label } = useFieldApi({ name, ...props });
  const dataListName = name + 'List';

  function handleChange(e) {
    onChange?.(e);

    input.onChange({
      value: e.target.value,
      label:
        options.find((option) => option.label === e.target.value)?.value ??
        null,
    });
  }

  return (
    <FormControl
      defaultValue={meta.initial}
      isRequired={isRequired}
      isInvalid={meta.invalid}
    >
      {label && <FormLabel m={0}>{label}</FormLabel>}
      <Input
        type="text"
        list={dataListName}
        {...input}
        name={name}
        value={input.value?.value}
        onFocus={(e) => {
          input.onFocus(e);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          input.onBlur(e);
          onBlur?.(e);
        }}
        onChange={handleChange}
      />
      <datalist id={dataListName}>
        {options.map(({ label: optionLabel }) => (
          <option value={optionLabel} key={optionLabel} />
        ))}
      </datalist>
      {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
}
Datalist.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
