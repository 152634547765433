﻿import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import Card from './styles/Card';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import { formatCurrency } from 'common/util/format';
import lang from 'pages/IncomeCalculator/language.json';

const RentalCalculationCard = ({ summary, onClick }) => {
  return (
    <Card onClick={onClick} spacing={4}>
      <Box flex={1} flexBasis="33%" lineHeight="24px">
        <Text fontSize="xl" fontWeight={500} overflow="wrap">
          {summary.getCalculatorName()}
        </Text>
        <Text fontWeight={500} fontSize="xs">
          {summary.getUsername()}
        </Text>
        <Text fontSize="xs">{summary.getCalculationDateTime()}</Text>
      </Box>
      <Box
        flexBasis="33%"
        fontWeight={500}
        pl={4}
        borderLeft="1px solid"
        borderColor="gray.300"
      >
        <Text fontSize="xs" mb={2} w="50%">
          {lang.CALC_HISTORY_PITIA}
        </Text>
        <Text fontSize="2xl">{formatCurrency(summary.getPitia())}</Text>
      </Box>
      <Box
        flexBasis="33%"
        fontWeight={500}
        pl={4}
        borderLeft="1px solid"
        borderColor="gray.300"
      >
        <Text w="50%" fontSize="xs" mb={2} lineHeight={4}>
          {lang.CALC_HISTORY_NET_RENTAL_INCOME}
        </Text>
        <Text fontSize="2xl">
          {formatCurrency(summary.getCalculatedMonthlyAmount())}
        </Text>
      </Box>
      <Box flexShrink={1}>
        <Text fontSize="xl">
          <FontAwesomeIcon icon={faChevronRight} />
        </Text>
      </Box>
    </Card>
  );
};
RentalCalculationCard.propTypes = {
  summary: PropTypes.instanceOf(HistoricalCalculationDataModel).isRequired,
  onClick: PropTypes.func,
};

export default RentalCalculationCard;
