import { SNIPPET_TYPES } from './types';
import { API_STATUS } from 'common/constants';

function snippetReducer(
  state = {
    status: {},
    lastError: null,
    images: {},
  },
  action,
) {
  switch (action.type) {
    case SNIPPET_TYPES.GET_SNIPPET_STARTED:
      state.status[action.payload.snippetId] = API_STATUS.GETTING;

      return {
        ...state,
      };

    case SNIPPET_TYPES.GET_SNIPPET_SUCCESS:
      state.status[action.payload.snippetId] = API_STATUS.DONE;
      state.images[action.payload.snippetId] = action.payload.snippet;

      return {
        ...state,
      };

    case SNIPPET_TYPES.GET_SNIPPET_ERROR:
      state.status[action.payload.snippetId] = API_STATUS.ERROR;
      state.lastError = action.payload.error;

      return {
        ...state,
      };

    default:
      return state;
  }
}

export default snippetReducer;
