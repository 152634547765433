import PropTypes from 'prop-types';
import FormTemplate from './FormTemplate';
import Header from 'pages/AuditHistory/Header';

export default function FormTemplateWithAuditHistory({
  schema: { title } = {},
  ...props
}) {
  const isTitleCommitment = title === 'Title Commitment';

  return (
    <FormTemplate
      {...props}
      customHeader={<Header title={title} isAISwitch={isTitleCommitment} />}
    />
  );
}

FormTemplateWithAuditHistory.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
};
