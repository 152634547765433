﻿import IncomeIdentifierDataModel from './IncomeIdentifierDataModel';
import BorrowerNameDataModel from 'model/BorrowerNameDataModel';
import AddressDataModel from 'model/AddressDataModel';
import IncomeHeader from './IncomeHeader.js';

export default class IncomeRecordData {
  constructor(i = {}) {
    this.incomeIdentifier = Object.prototype.hasOwnProperty.call(
      i,
      'incomeIdentifier',
    )
      ? new IncomeIdentifierDataModel(i.incomeIdentifier)
      : new IncomeIdentifierDataModel();

    this.source = Object.prototype.hasOwnProperty.call(i, 'source')
      ? i.source
      : '';

    this.incomeTypes = Object.prototype.hasOwnProperty.call(i, 'incomeTypes')
      ? i.incomeTypes
      : [];

    this.startDate = Object.prototype.hasOwnProperty.call(i, 'startDate')
      ? i.startDate
      : '1970-01-01T05:00:00.000Z';

    this.endDate = Object.prototype.hasOwnProperty.call(i, 'endDate')
      ? i.endDate
      : '1970-01-01T05:00:00.000Z';

    this.monthlyAmount = Object.prototype.hasOwnProperty.call(
      i,
      'monthlyAmount',
    )
      ? parseFloat(i.monthlyAmount)
      : 0;

    this.employerName = Object.prototype.hasOwnProperty.call(i, 'employerName')
      ? i.employerName
      : '';

    this.primarySource = Object.prototype.hasOwnProperty.call(i, 'isPrimary')
      ? i.isPrimary
      : false;

    this.isCurrent = Object.prototype.hasOwnProperty.call(i, 'isCurrent')
      ? i.isCurrent
      : false;

    this.borrowerName = Object.prototype.hasOwnProperty.call(i, 'borrowerName')
      ? new BorrowerNameDataModel(i.borrowerName)
      : new BorrowerNameDataModel();

    this.businessAddress = Object.prototype.hasOwnProperty.call(
      i,
      'businessAddress',
    )
      ? new AddressDataModel(i.businessAddress ?? {})
      : new AddressDataModel();

    this.incomeHeader = new IncomeHeader(this);
  }

  getDatabaseId() {
    return this.getIncomeIdentifier().getLenderDatabaseId();
  }

  /**
   * @returns {number} The identifier of the customer this income belongs to.
   */
  getCustomerId() {
    return this.getIncomeIdentifier().getCustomerId();
  }

  /**
   * @returns {BorrowerNameDataModel} Returns an object with borrower name
   */
  getBorrowerName() {
    return this.borrowerName;
  }

  /**
   * @returns {string} Returns the fullname of the borrower
   */
  getBorrowerFullName() {
    return this.borrowerName.getFullName();
  }

  /**
   * @returns {number} The income identifier.
   */
  getEmployerId() {
    return this.getIncomeIdentifier().getEmployerId();
  }

  /**
   * @returns {number} The sequence number.
   */
  getSequenceNumber() {
    return this.getIncomeIdentifier().getSequenceNumber();
  }

  /**
   * @returns {string} The type of income category.
   */
  getIncomeCategoryType() {
    return this.source;
  }

  /**
   * @returns {[Number]} The specific income types for the employer.
   */
  getIncomeTypes() {
    return this.incomeTypes;
  }

  /**
   * @returns {Date} The date the borrower started employment at this income source.
   */
  getStartDate() {
    return new Date(this.startDate);
  }

  /**
   * @returns {Date} The End date the borrower started employment at this income source.
   */
  getEndDate() {
    return new Date(this.endDate);
  }

  /**
   * @returns {string} The End date the borrower started employment at this income source.
   */
  getEndDateString() {
    return new Date(this.endDate).toLocaleDateString();
  }

  /**
   * @returns {string} The date the borrower started employment at this income source.
   */
  getStartDateString() {
    return new Date(this.startDate).toLocaleDateString();
  }

  /**
   * @returns {number} The amount of income earned from the source per month.
   */
  getMonthlyAmount() {
    return this.monthlyAmount;
  }

  /**
   * @returns {string} The name of this income source.
   */
  getSource() {
    return this.employerName;
  }

  /**
   * @returns {boolean} True if the income source is the primary source for the borrower.
   */
  getIsPrimarySource() {
    return this.primarySource;
  }

  /**
   * @returns {AddressDataModel|null} The address of the borrower's place of employment.
   */
  getBusinessAddress() {
    return this.businessAddress;
  }

  /**
   * @returns {boolean} returns boolean for the isCurrent source for the borrower.
   */
  getIsCurrent() {
    return this.isCurrent;
  }

  /**
   * @return {IncomeIdentifierDataModel} The income record identifier for the income source.
   */
  getIncomeIdentifier() {
    return this.incomeIdentifier;
  }

  /**
   * @return {IncomeHeader} The income record identifier for the income source.
   */
  getIncomeHeader() {
    return this.incomeHeader;
  }
}
