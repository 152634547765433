﻿import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDisclosure } from '@chakra-ui/react';
import Drawer from 'common/components/Drawers/Drawer';
import Button from 'common/components/Buttons/Button/index';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import HistoryDrawer from './HistoryDrawer';
import ObfuscatedLoanIdentifierDataModel from '../../../model/ObfuscatedLoanIdentifierDataModel';
import IncomeIdentifierDataModel from '../model/IncomeIdentifierDataModel';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import RentalAddressIdentifierDataModel from '../model/RentalAddressIdentifierDataModel';
import { API_STATUS } from 'common/constants';
import { REQUEST_REFERENCE_TYPE } from '../enums';
import lang from 'pages/IncomeCalculator/language.json';

const CalculationHistory = ({
  loan,
  incomeIdentifier,
  rentalAddress,
  requestReferenceType,
  getIncomeCalculationHistory,
  getRentalCalculationHistory,
  resetCalculationHistoryResult,
  status,
  summaries,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef();
  const [selectedCalculation, setSelectedCalculation] = useState(null);

  useEffect(() => {
    if (
      requestReferenceType === REQUEST_REFERENCE_TYPE.employmentIncome &&
      incomeIdentifier
    ) {
      getIncomeCalculationHistory(loan, incomeIdentifier);
    } else if (
      requestReferenceType === REQUEST_REFERENCE_TYPE.rentalIncome &&
      rentalAddress
    ) {
      getRentalCalculationHistory(loan, rentalAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status !== API_STATUS.DONE || !summaries || summaries.length === 0) {
    return null;
  }

  return (
    <>
      <Box {...props}>
        <Button
          variant="outline"
          colorScheme="blue"
          maxWidth="175px"
          zIndex={5}
          filter="drop-shadow(rgba(9, 30, 66, 0.25) 0px 1px 1px)"
          ref={buttonRef}
          onClick={() => onOpen()}
        >
          <Text as="span" color="gray.900">
            {lang.CALC_HISTORY}
          </Text>
        </Button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={selectedCalculation ? 'xl' : 'lg'}
        onClose={onClose}
        finalFocusRef={buttonRef}
      >
        {isOpen && (
          <HistoryDrawer
            data={summaries}
            selectedCalculation={selectedCalculation}
            setSelectedCalculation={setSelectedCalculation}
            resetCalculationHistoryResult={resetCalculationHistoryResult}
            requestReferenceType={requestReferenceType}
          />
        )}
      </Drawer>
    </>
  );
};
CalculationHistory.propTypes = {
  loan: PropTypes.instanceOf(ObfuscatedLoanIdentifierDataModel).isRequired,
  incomeIdentifier: PropTypes.instanceOf(IncomeIdentifierDataModel),
  rentalAddress: PropTypes.instanceOf(RentalAddressIdentifierDataModel),
  requestReferenceType: PropTypes.oneOf([
    REQUEST_REFERENCE_TYPE.employmentIncome,
    REQUEST_REFERENCE_TYPE.rentalIncome,
  ]),
  getIncomeCalculationHistory: PropTypes.func.isRequired,
  getRentalCalculationHistory: PropTypes.func.isRequired,
  resetCalculationHistoryResult: PropTypes.func.isRequired,
  status: PropTypes.string,
  summaries: PropTypes.arrayOf(
    PropTypes.instanceOf(HistoricalCalculationDataModel),
  ),
};
CalculationHistory.defaultProps = {
  requestReferenceType: REQUEST_REFERENCE_TYPE.employmentIncome,
};

export default CalculationHistory;
