import RentalAddressIdentifierDataModel from '../../IncomeCalculator/model/RentalAddressIdentifierDataModel';

export default class RentalIncomeData {
  constructor(data = {}) {
    this.obfuscatedLoanIdentifier = Object.hasOwn(
      data,
      'obfuscatedLoanIdentifier',
    )
      ? data.obfuscatedLoanIdentifier
      : null;

    this.addressIdentifier = Object.hasOwn(data, 'addressIdentifier')
      ? new RentalAddressIdentifierDataModel(data.addressIdentifier)
      : null;

    this.propertyType = Object.hasOwn(data, 'propertyType')
      ? data.propertyType
      : 1;

    this.ausTipMessage = Object.hasOwn(data, 'ausTipMessage')
      ? data.ausTipMessage
      : '';

    this.ausIneligibleMessage = Object.hasOwn(data, 'ausIneligibleMessage')
      ? data.ausIneligibleMessage
      : '';

    this.ausMethod = Object.hasOwn(data, 'ausMethod') ? data.ausMethod : '';

    this.pitiaAmount = Object.hasOwn(data, 'pitiaAmount')
      ? data.pitiaAmount
      : 0;

    this.duEligible = Object.hasOwn(data, 'duEligible')
      ? data.duEligible
      : false;

    this.lpaEligible = Object.hasOwn(data, 'lpaEligible')
      ? data.lpaEligible
      : false;

    this.propertyData = Object.hasOwn(data, 'propertyData')
      ? data.propertyData
      : null;

    this.extractedPropertyAddresses = Object.hasOwn(
      data,
      'extractedPropertyAddresses',
    )
      ? data.extractedPropertyAddresses
      : null;

    this.extractedYear = Object.hasOwn(data, 'extractedYear')
      ? data.extractedYear
      : null;

    this.loanDates = Object.hasOwn(data, 'loanDates') ? data.loanDates : null;

    this.isDocumentOptionRadioButton = Object.hasOwn(
      data,
      'isDocumentOptionRadioButton',
    )
      ? data.isDocumentOptionRadioButton
      : false;

    this.documentOptions = Object.hasOwn(data, 'documentOptions')
      ? data.documentOptions
      : [];

    this.realEstateOwned = Object.hasOwn(data, 'realEstateOwned')
      ? data.realEstateOwned
      : [];

    this.shouldCheckDuLpaEligibility = Object.hasOwn(
      data,
      'shouldCheckDuLpaEligibility',
    )
      ? data.shouldCheckDuLpaEligibility
      : false;

    this.extracted1040Datas = Object.hasOwn(data, 'extracted1040Datas')
      ? data.extracted1040Datas
      : false;

    this.isRentalIneligible = Object.hasOwn(data, 'isRentalIneligible')
      ? data.isRentalIneligible
      : false;
  }

  getDocumentOptions() {
    return this.documentOptions;
  }

  getAusMethod() {
    return this.ausMethod;
  }

  getPitiaAmount() {
    return this.pitiaAmount;
  }
}
