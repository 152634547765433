import { SET_ACTIVE_FIELD, RESET_ACTIVE_FIELD } from './types';

const initialState = {
  activeField: '',
};

export const questionExtractionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_FIELD:
      return {
        ...state,
        activeField: action.payload,
      };
    case RESET_ACTIVE_FIELD:
      return {
        ...state,
        activeField: '',
      };
    default:
      return state;
  }
};
