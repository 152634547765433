import AppRenderer from 'lib-node-amb-renderer';
import componentMapper from 'common/componentMapper';
// eslint-disable-next-line import/no-unresolved
import layout from '../layout.yaml?raw';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Spinner from '../../GlobalLayout/Spinner';
import { KYLTableRowClass } from 'common/componentMapper/ChakraTable/KYLTableRow';
import { KYLTableClass } from 'common/componentMapper/ChakraTable/KYLTable';

const customComponents = {
  KYLTableRow: KYLTableRowClass,
  KYLTable: KYLTableClass,
};
export function KYLFormTemplate({ formFields }) {
  return <Box sx={{ padding: '2rem' }}>{formFields}</Box>;
}
KYLFormTemplate.propTypes = {
  schema: PropTypes.object,
  formFields: PropTypes.any,
};

export default function SummaryPerBorrower({ borrower }) {
  return (
    <AppRenderer
      markup={layout}
      data={borrower}
      componentMapper={componentMapper}
      FormTemplate={KYLFormTemplate}
      LoadingComponent={<Spinner />}
      customComponents={customComponents}
    />
  );
}

SummaryPerBorrower.propTypes = {
  borrower: PropTypes.object,
};
