﻿import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
} from 'common/components';
import { formatCurrency } from 'common/util/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

function Contents({ header, children }) {
  return (
    <Box p={3} pl={6} pr={6} id={`calculation-breakdown-${header}`}>
      {children}
    </Box>
  );
}
Contents.propTypes = {
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const BreakdownContainer = ({
  calculatedAmount,
  header = 'Calculation Breakdown',
  subheader,
  onNewWindow,
  hideAccordion = false,
  children,
}) => {
  if (hideAccordion) {
    return <Contents header={header}>{children}</Contents>;
  }

  return (
    <Accordion
      defaultIndex={onNewWindow ? 0 : undefined}
      allowToggle={!onNewWindow}
      as="article"
      allowMultiple
    >
      <AccordionItem border="solid 1px #E2E8F0" borderRadius="4px">
        <AccordionButton bg="gray.50" _hover={{ backgroundColor: 'gray.50' }}>
          <Box d="inline-flex" flex="1" textAlign="left">
            <Text as="h3" fontWeight="bold">
              {header}
            </Text>
            {subheader && <span>&nbsp;{subheader}</span>}
          </Box>
          {typeof calculatedAmount === 'number' && (
            <Box as="h3" mr={5} textAlign="right" fontWeight="bold">
              {formatCurrency(calculatedAmount)}
            </Box>
          )}
          {!onNewWindow && <AccordionIcon />}
          {onNewWindow && (
            <Button
              d="none"
              variant="link"
              colorScheme="blue"
              fontWeight={100}
              rightIcon={<FontAwesomeIcon icon={faExternalLink} />}
              onClick={onNewWindow}
            >
              Open in new window
            </Button>
          )}
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Contents header={header}>{children}</Contents>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

BreakdownContainer.propTypes = {
  /** The header text to display for the breakdown */
  header: PropTypes.string,
  /** The subheader text to display for the breakdown */
  subheader: PropTypes.string,
  /** The calculated amount to display on the accordion header */
  calculatedAmount: PropTypes.number,
  /** A click handler for performing an action when the user requests to see the breakdown in a popout window.
   * Settings this value is only required for session history. */
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default BreakdownContainer;
