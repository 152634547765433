import PropTypes from 'prop-types';
import { MenuItem as MUIMenuItem } from '@mui/material';

export default function MenuItem({ children, ...props }) {
  return <MUIMenuItem {...props}>{children}</MUIMenuItem>;
}

MenuItem.propTypes = {
  children: PropTypes.node,
  closeOnSelect: PropTypes.bool,
  icon: PropTypes.any,
  isFocusable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  command: PropTypes.string,
  onClick: PropTypes.func,
};
