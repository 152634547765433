import { useParams } from 'react-router-dom';
import AuditTable from './AuditTable';
import Layout from './Layout';
import { useGetAuditHistory } from './hooks';

export default function AuditHistory() {
  const { pageEnum, assetId, entityEnum } = useParams();
  const {
    data: auditHistory,
    isLoading,
    isError,
  } = useGetAuditHistory(pageEnum, assetId, entityEnum);

  return (
    <Layout
      pageEnum={pageEnum}
      isLoading={isLoading}
      isError={isError}
      isDataEmpty={!auditHistory?.length}
    >
      {auditHistory?.map(({ fieldsAudit }) => (
        <AuditTable key={fieldsAudit[0].id} data={fieldsAudit} />
      ))}
    </Layout>
  );
}
