import React from 'react';
import { NumberInputField as ChakraNumberInputField } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const NumberInputField = React.forwardRef((props, ref) => {
  return <ChakraNumberInputField ref={ref} {...props} />;
});

NumberInputField.displayName = 'NumberInputField';

export default NumberInputField;

NumberInputField.propTypes = {
  props: PropTypes.object,
};
