import Box from 'common/components/Box';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-solid-svg-icons';

export default function Icon({
  iconType,
  variant = 'ghost',
  fontSize,
  lineHeight,
  color,
  bgColor,
  sx,
}) {
  const icon = icons[iconType];
  color ??= 'uwmLogo.blue';

  let styles = {
    sx,
    color,
    textAlign: 'center',
    width: '1.3rem',
    height: '1.3rem',
    borderRadius: '3px',
    alignSelf: 'center',
  };

  if (variant === 'solid') {
    styles = {
      ...styles,
      color: 'white',
      bgColor: bgColor ?? color,
      borderColor: color,
    };
  } else if (variant === 'outline') {
    styles = {
      ...styles,
      color: color,
      borderColor: color,
    };
  } else if (variant === 'round') {
    styles = {
      ...styles,
      color: color ?? 'black',
      bgColor: bgColor,
      borderRadius: '1rem',
      h: '1.6rem',
      w: '1.6rem',
      minW: '1.6rem',
    };
    lineHeight = lineHeight ?? '1.6rem';
  }

  return (
    <Box {...styles} lineHeight={lineHeight ? lineHeight : '1.3rem'}>
      <FontAwesomeIcon icon={icon} fontSize={fontSize ? fontSize : 'sm'} />
    </Box>
  );
}

Icon.propTypes = {
  iconType: PropTypes.string,
  sx: PropTypes.object,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  variant: PropTypes.oneOf(['outline', 'solid', 'ghost', 'round']),
};
