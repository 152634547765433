import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChakraText from 'common/componentMapper/Text';
import { useFormApi, FormSpy } from '@data-driven-forms/react-form-renderer';
import { UnnamedWidget } from 'lib-node-amb-renderer';

const formatWithDecimals = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const currencyFormat = (strNum) => {
  const n = Number(strNum);
  return formatWithDecimals.format(n);
};

export class MonthlyCalculator extends UnnamedWidget {
  prefix;

  constructor(props) {
    super(props);
    this.prefix = props.prefix;
  }
}

export default function ReviewBreakdownDetails(props) {
  return (
    <FormSpy subcription={{ values: true }}>
      {() => <InnerComponent {...props} />}
    </FormSpy>
  );
}

function InnerComponent({ name, prefix, ...rest }) {
  const { getState } = useFormApi();
  const { values } = getState();
  const [calculated, setCalculated] = useState('');
  const calculationId = `${prefix}${values.calculationId}`;
  const outcomeDetails =
    values.calculationDetails[calculationId].outcomeDetails;

  useEffect(() => {
    const total = Object.values(outcomeDetails).reduce(
      (accum, { calculationResultAmount, overrideAmount }) => {
        let n = +calculationResultAmount;

        if (overrideAmount !== null) {
          n = overrideAmount;
        }

        accum += n;

        return accum;
      },
      0,
    );
    setCalculated(total);

    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [values]);

  return (
    <ChakraText
      bgColor="bgGray.100"
      p="24px"
      id={name}
      fontSize="lg"
      word-spacing={4}
      marginBottom={1}
      width="100%"
      {...rest}
    >
      <strong style={{ marginRight: '1rem' }}>Monthly Income</strong>
      {currencyFormat(calculated)}
    </ChakraText>
  );
}

InnerComponent.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
};
