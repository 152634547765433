const CREDIT_TYPES = {
  GET_CREDIT_INFO_STARTED: 'GET_CREDIT_INFO_STARTED',
  GET_CREDIT_INFO_SUCCESS: 'GET_CREDIT_INFO_SUCCESS',
  GET_CREDIT_INFO_ERROR: 'GET_CREDIT_INFO_ERROR',

  GET_CREDIT_REPORT_STARTED: 'GET_CREDIT_REPORT_STARTED',
  GET_CREDIT_REPORT_SUCCESS: 'GET_CREDIT_REPORT_SUCCESS',
  GET_CREDIT_REPORT_ERROR: 'GET_CREDIT_REPORT_ERROR',

  GET_LIABILITIES_STARTED: 'GET_LIABILITIES_STARTED',
  GET_LIABILITIES_SUCCESS: 'GET_LIABILITIES_SUCCESS',
  GET_LIABILITIES_ERROR: 'GET_LIABILITIES_ERROR',

  GET_LIABILITIES_VERIFY_DATA_STARTED: 'GET_LIABILITIES_VERIFY_DATA_STARTED',
  GET_LIABILITIES_VERIFY_DATA_SUCCESS: 'GET_LIABILITIES_VERIFY_DATA_SUCCESS',
  GET_LIABILITIES_VERIFY_DATA_ERROR: 'GET_LIABILITIES_VERIFY_DATA_ERROR',

  GET_LIABILITIES_ASSOCIATE_DATA_STARTED:
    'GET_LIABILITIES_ASSOCIATE_DATA_STARTED',
  GET_LIABILITIES_ASSOCIATE_DATA_SUCCESS:
    'GET_LIABILITIES_ASSOCIATE_DATA_SUCCESS',
  GET_LIABILITIES_ASSOCIATE_DATA_ERROR: 'GET_LIABILITIES_ASSOCIATE_DATA_ERROR',

  UPDATE_LIABILITIES_STARTED: 'UPDATE_LIABILITIES_STARTED',
  UPDATE_LIABILITIES_SUCCESS: 'UPDATE_LIABILITIES_SUCCESS',
  UPDATE_LIABILITIES_ERROR: 'UPDATE_LIABILITIES_ERROR',

  UPDATE_LIABILITIES_VERIFY_DATA_STARTED:
    'UPDATE_LIABILITIES_VERIFY_DATA_STARTED',
  UPDATE_LIABILITIES_VERIFY_DATA_SUCCESS:
    'UPDATE_LIABILITIES_VERIFY_DATA_SUCCESS',
  UPDATE_LIABILITIES_VERIFY_DATA_ERROR: 'UPDATE_LIABILITIES_VERIFY_DATA_ERROR',

  UPDATE_LIABILITIES_ASSOCIATE_DATA_STARTED:
    'UPDATE_LIABILITIES_ASSOCIATE_DATA_STARTED',
  UPDATE_LIABILITIES_ASSOCIATE_DATA_SUCCESS:
    'UPDATE_LIABILITIES_ASSOCIATE_DATA_SUCCESS',
  UPDATE_LIABILITIES_ASSOCIATE_DATA_ERROR:
    'UPDATE_LIABILITIES_ASSOCIATE_DATA_ERROR',

  UPDATE_CREDIT_INFO_STARTED: 'UPDATE_CREDIT_INFO_STARTED',
  UPDATE_CREDIT_INFO_SUCCESS: 'UPDATE_CREDIT_INFO_SUCCESS',
  UPDATE_CREDIT_INFO_ERROR: 'UPDATE_CREDIT_INFO_ERROR',
  UPDATE_CREDIT_VALIDATION_RESULTS_STARTED:
    'UPDATE_CREDIT_VALIDATION_RESULTS_STARTED',
  UPDATE_CREDIT_VALIDATION_RESULTS_SUCCESS:
    'UPDATE_CREDIT_VALIDATION_RESULTS_SUCCESS',
  UPDATE_CREDIT_VALIDATION_RESULTS_ERROR:
    'UPDATE_CREDIT_VALIDATION_RESULTS_ERROR',
  UPDATE_SAVE_CREDIT_ANSWERS_STARTED: 'UPDATE_SAVE_CREDIT_ANSWERS_STARTED',
  UPDATE_SAVE_CREDIT_ANSWERS_SUCCESS: 'UPDATE_SAVE_CREDIT_ANSWERS_SUCCESS',
  UPDATE_SAVE_CREDIT_ANSWERS_ERROR: 'UPDATE_SAVE_CREDIT_ANSWERS_ERROR',
  RETRIEVE_PDF_SUCCESS: 'RETRIEVE__PDF_SUCCESS',
  RETRIEVE_PDF_ERROR: 'RETRIEVE__PDF_ERROR',
  SET_IS_LOADING_DOCUMENT_LINK: 'SET_IS_LOADING_DOCUMENT_LINK',

  CLEAR_SAVE_CREDIT_DATA: 'CLEAR_SAVE_CREDIT_DATA',

  CALCULATE_REMAINING_TERMS_RESET: 'CALCULATE_REMAINING_TERMS_RESET',
  CALCULATE_REMAINING_TERMS_STARTED: 'CALCULATE_REMAINING_TERMS_STARTED',
  CALCULATE_REMAINING_TERMS_SUCCESS: 'CALCULATE_REMAINING_TERMS_SUCCESS',
  CALCULATE_REMAINING_TERMS_ERROR: 'CALCULATE_REMAINING_TERMS_ERROR',
};

export default CREDIT_TYPES;
