import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import styled from '@emotion/styled';

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${({ color }) => color};
  }
`;

export default function IconBadge({ children, badgeContent, color, ...props }) {
  return (
    <StyledBadge badgeContent={badgeContent} color={color} {...props}>
      {children}
    </StyledBadge>
  );
}

IconBadge.propTypes = {
  children: PropTypes.node,
  badgeContent: PropTypes.node,
  color: PropTypes.string,
};
