import TYPES from './types';
import lang from '../language.json';
import commonLang from 'common/constants/language.json';
import { getNextAlert } from 'common/util/ruleUtils';
import { RULE_RESULT_STATUS_ID } from 'common/constants';
import { ALERTS_TO_SKIP_IF_CLEARED, CATEGORY_IDS } from '../constants';
import { QUICK_MATCH } from 'pages/ClassifyDocumentsV2/constants';

// set data
const setOnInit = (value) => ({
  type: TYPES.SET_ON_INIT,
  value,
});

const setSelectedCategory = (category) => ({
  type: TYPES.SET_SELECTED_CATEGORY,
  category,
});

const setClickedSelectedCategory = (category) => ({
  type: TYPES.SET_CLICKED_SELECTED_CATEGORY,
  category,
});

const setSelectedAlert = (alert) => ({
  type: TYPES.SET_SELECTED_ALERT,
  alert,
});

const attachIsEandOToSelectedAlert = (isEandO) => ({
  type: TYPES.ATTACH_IS_EANDO_TO_SELECTED_ALERT,
  isEandO,
});

const setSelectedAlertParent = (alert) => ({
  type: TYPES.SET_SELECTED_ALERT_PARENT,
  alert,
});

const setLatestSignalRNotification = (notification) => ({
  type: TYPES.SET_LATEST_SIGNAL_R_NOTIFICATION,
  notification,
});

const setIsUnderwriter = (value) => ({
  type: TYPES.SET_IS_UNDERWRITER,
  value,
});

const setShouldGetNextAlert = (value) => ({
  type: TYPES.SET_SHOULD_GET_NEXT_ALERT,
  value,
});

const setIsLastAlert = (value) => ({
  type: TYPES.SET_IS_LAST_ALERT,
  value,
});

const setIsBailOut = (value) => ({
  type: TYPES.SET_IS_BAIL_OUT,
  value,
});

const setIsUnderwriterCheckboxDisabled = (value) => ({
  type: TYPES.SET_IS_UNDERWRITER_CHECKBOX_DISABLED,
  value,
});

const setIsToastOpen = (
  value,
  message = { description: commonLang.DEFAULT_OOPS_DESCRIPTION },
) => ({
  type: TYPES.SET_IS_TOAST_OPEN,
  value,
  message,
});

const setUserDetails = (user) => ({
  type: TYPES.SET_USER_DETAILS,
  user,
});

// navigation items
const getNavigationDetailsStarted = () => ({
  type: TYPES.GET_NAVIGATION_ITEMS_STARTED,
});

const getNavigationDetailsSuccess = (payload) => {
  return {
    type: TYPES.GET_NAVIGATION_ITEMS_SUCCESS,
    allAlerts: payload.allAlerts,
    categories: payload.categories,
    allRequiredRulesCompleted: payload.allRequiredRulesCompleted,
  };
};

const getNavigationDetailsError = () => ({
  type: TYPES.GET_NAVIGATION_ITEMS_ERROR,
  error: lang.GET_NAVIGATION_DETAILS_ERROR_MESSAGE,
});

// trigger all rules
const triggerAllRulesStarted = () => ({
  type: TYPES.POST_TRIGGER_ALL_RULES_STARTED,
});

const triggerAllRulesSuccess = () => ({
  type: TYPES.POST_TRIGGER_ALL_RULES_SUCCESS,
});

const triggerAllRulesError = () => ({
  type: TYPES.POST_TRIGGER_ALL_RULES_ERROR,
  error: lang.POST_TRIGGER_ALL_RULES_ERROR_MESSAGE,
});

// submit to set up
const submitLoanToSetupStarted = () => ({
  type: TYPES.SUBMIT_TO_SETUP_STARTED,
});

const submitLoanToSetupSuccess = (payload) => ({
  type: TYPES.SUBMIT_TO_SETUP_SUCCESS,
  payload,
});

const submitLoanToSetupError = () => ({
  type: TYPES.SUBMIT_TO_SETUP_ERROR,
  error: lang.SUBMIT_LOAN_TO_SET_UP_ERROR_MESSAGE,
});

// Bolt Status
const getBoltStatusStarted = () => ({
  type: TYPES.GET_BOLT_STATUS_STARTED,
});

const getBoltStatusSuccess = (value) => ({
  type: TYPES.GET_BOLT_STATUS_SUCCESS,
  value,
});

const getBoltStatusError = () => ({
  type: TYPES.GET_BOLT_STATUS_ERROR,
  error: lang.GET_BOLT_STATUS_ERROR_MESSAGE,
});

// underwriter

const getUnderwriterVerificationStarted = () => ({
  type: TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_STARTED,
});

const getUnderwriterVerificationSuccess = (payload) => ({
  type: TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_SUCCESS,
  payload,
});

const getUnderwriterVerificationError = () => ({
  type: TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_ERROR,
  error: lang.GET_UNDERWRITER_VERIFICATION_STATUS_ERROR,
});

const saveUnderwriterVerificationStarted = () => ({
  type: TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_STARTED,
});

const saveUnderwriterVerificationSuccess = ({
  ruleResultID,
  isUnderwriterVerified,
}) => ({
  type: TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_SUCCESS,
  ruleResultID,
  isUnderwriterVerified,
});

const saveUnderwriterVerificationError = () => ({
  type: TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_ERROR,
  error: lang.SAVE_UNDERWRITER_RULE_VERIFICATION_ERROR_MESSAGE,
});

// get loan summary
const getLoanSummaryStarted = () => ({
  type: TYPES.GET_LOAN_SUMMARY_STARTED,
});

const getLoanSummarySuccess = (payload) => ({
  type: TYPES.GET_LOAN_SUMMARY_SUCCESS,
  payload,
});

const getLoanSummaryError = () => ({
  type: TYPES.GET_LOAN_SUMMARY_ERROR,
  error: lang.GET_LOAN_SUMMARY_ERROR_MESSAGE,
});

// get loan summary
const bailOutAndSubmitToSetupStarted = () => ({
  type: TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_STARTED,
});

const bailOutAndSubmitToSetupSuccess = (data) => ({
  type: TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_SUCCESS,
  payload: data,
});

const bailOutAndSubmitToSetupError = (error) => ({
  type: TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_ERROR,
  payload: error,
});

// PDF Link for global component use instead of Classify Documents
const retrievePdfStarted = () => ({
  type: TYPES.RETRIEVE_PDF_STARTED,
});

const retrievePdfSuccess = (payload) => ({
  type: TYPES.RETRIEVE_PDF_SUCCESS,
  payload,
});

const retrievePdfError = (error) => ({
  type: TYPES.RETRIEVE_PDF_ERROR,
  error,
});

const retrievePdfClear = () => ({
  type: TYPES.RETRIEVE_PDF_CLEAR,
});

export const nextAlert = () => (dispatch, getState) => {
  let next;
  const {
    home: {
      selectedAlert,
      selectedCategory,
      navigationItems: { allAlerts },
      user: { isUnderwriter },
    },
  } = getState();

  const isClassifyGetNextAlert =
    selectedAlert === null && selectedCategory === CATEGORY_IDS.CLASSIFY;

  next = getNextAlert(allAlerts, selectedAlert, isUnderwriter);
  if (selectedAlert === QUICK_MATCH) {
    dispatch(setSelectedAlert(null));
  } else {
    if (
      next?.ruleResultStatusId === RULE_RESULT_STATUS_ID.CLEARED &&
      isClassifyGetNextAlert &&
      !isUnderwriter
    ) {
      next = getNextAlert(allAlerts, next);
    }
    if (next) {
      dispatch(setShouldGetNextAlert(false));
      if (selectedAlert?.ruleCategoryId !== next?.ruleCategoryId) {
        dispatch(setSelectedCategory(next?.ruleCategoryId));
      }
      dispatch(setSelectedAlert(next));
    } else {
      dispatch(setIsLastAlert(true));
    }
  }
};

export default {
  attachIsEandOToSelectedAlert,
  getNavigationDetailsStarted,
  getNavigationDetailsSuccess,
  getNavigationDetailsError,
  triggerAllRulesStarted,
  triggerAllRulesSuccess,
  triggerAllRulesError,
  submitLoanToSetupStarted,
  submitLoanToSetupSuccess,
  submitLoanToSetupError,
  setSelectedCategory,
  setClickedSelectedCategory,
  setSelectedAlert,
  setSelectedAlertParent,
  getBoltStatusStarted,
  getBoltStatusSuccess,
  getBoltStatusError,
  setLatestSignalRNotification,
  setShouldGetNextAlert,
  setIsLastAlert,
  setIsUnderwriter,
  getUnderwriterVerificationStarted,
  getUnderwriterVerificationSuccess,
  getUnderwriterVerificationError,
  saveUnderwriterVerificationStarted,
  saveUnderwriterVerificationSuccess,
  saveUnderwriterVerificationError,
  getLoanSummaryStarted,
  getLoanSummarySuccess,
  getLoanSummaryError,
  nextAlert,
  setOnInit,
  setUserDetails,
  setIsBailOut,
  setIsUnderwriterCheckboxDisabled,
  setIsToastOpen,
  bailOutAndSubmitToSetupStarted,
  bailOutAndSubmitToSetupSuccess,
  bailOutAndSubmitToSetupError,
  retrievePdfStarted,
  retrievePdfSuccess,
  retrievePdfError,
  retrievePdfClear,
};
