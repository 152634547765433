// 3rd party imports
import { useSelector } from 'react-redux';
import Text from 'common/components/Texts/Text';

// local imports
import CategoriesNav from './CategoriesNav';
import { NavWrapper } from 'common/components/Nav/components/Styled';

const CategoriesHeader = () => {
  // redux
  const { home } = useSelector((state) => state);
  const isUnderwriter = home?.user?.isUnderwriter;

  return (
    <>
      <NavWrapper>
        <CategoriesNav />
        {isUnderwriter && (
          <Text
            id="underwriterViewLabel"
            position="absolute"
            as="h3"
            right="0"
            top="0"
          >
            Underwriter View
          </Text>
        )}
      </NavWrapper>
    </>
  );
};

export default CategoriesHeader;
