﻿import DocumentTypeSelectionDataModel from './DocumentTypeSelectionDataModel';
import DocumentSelectionDataModel from './DocumentSelectionDataModel';

export default class IncomeDocumentNode {
  constructor(b = {}) {
    this.id = Object.prototype.hasOwnProperty.call(b, 'id') ? b.id : 0;
    this.name = Object.prototype.hasOwnProperty.call(b, 'name') ? b.name : '';
    this.isSelected = Object.prototype.hasOwnProperty.call(b, 'isSelected')
      ? b.isSelected
      : false;
    this.documentTypeId = Object.prototype.hasOwnProperty.call(
      b,
      'documentTypeId',
    )
      ? b.documentTypeId
      : null;
    this.hasChildren = Object.prototype.hasOwnProperty.call(b, 'hasChildren')
      ? b.hasChildren
      : false;
    this.allowMultiple = Object.prototype.hasOwnProperty.call(
      b,
      'allowMultiple',
    )
      ? b.allowMultiple
      : false;
    this.sortOrder = Object.prototype.hasOwnProperty.call(b, 'sortOrder')
      ? b.sortOrder
      : null;
    this.children = Object.prototype.hasOwnProperty.call(b, 'children')
      ? b.children.map((x) => new IncomeDocumentNode(x))
      : [];
  }

  /**
   * @returns {number} The id associated with the income document type.
   */
  getId() {
    return this.id;
  }

  /**
   * @returns {string} The name of the node.
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {number} The sort order.
   */
  getSortOrder() {
    return this.sortOrder;
  }

  /**
   * @returns {boolean} Determines whether or not the associated checkbox is checked.
   */
  getIsSelected() {
    return this.isSelected;
  }

  /**
   * @returns {number|null} The document type id corresponding to the income document, or null if not applicable
   */
  getDocumentType() {
    return this.documentTypeId;
  }

  /**
   * @returns {[IncomeDocumentNode]} A list of child nodes.
   */
  getChildren() {
    return this.children;
  }

  doesHaveChildren() {
    return this.hasChildren;
  }

  /**
   * @returns {boolean} True if the node is for a type of calculation.
   */
  isDocumentType() {
    return this.hasChildren && !this.allowMultiple;
  }

  /**
   * @returns {DocumentTypeSelectionDataModel} The document type selection data model for this node.
   */
  asDocumentTypeSelection() {
    return new DocumentTypeSelectionDataModel({
      incomeDocumentationTypeId: this.getId(),
      isSelected: this.getIsSelected(),
    });
  }

  /**
   * @returns {DocumentSelectionDataModel} The document selection data model for this node.
   */
  asDocumentSelection() {
    return new DocumentSelectionDataModel({
      incomeDocumentId: this.getId(),
      name: this.getName(),
      isSelected: this.getIsSelected(),
      documentTypeId: this.getDocumentType(),
    });
  }
}
