import ChakraAlert from 'common/components/Alerts/Alert/Alert';
import AlertDescription from 'common/components/Alerts/AlertDescription';
import AlertTitle from 'common/components/Alerts/AlertTitle';
import Box from 'common/components/Box';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NamedWidget, useFormApi } from 'lib-node-amb-renderer';

export class EffectiveDateAlertClass extends NamedWidget {
  constructor(props) {
    super(props);
  }
}

const EffectiveDateAlert = ({ name, sx }) => {
  const { getState } = useFormApi();

  const { values } = getState();

  const dateForScreenData = new Date(
    new Date(values?.titleCommitmentDatePlus180).setHours(0, 0, 0, 0),
  ).toDateString();

  let alertColor, alertIcon, alertBackground, status, title, message;
  alertColor = 'orange.600';
  alertBackground = 'orange.100';
  alertIcon = faExclamationTriangle;
  title = 'Warning';
  message = `Based on the estimated closing date, the title commitment may expire.
  If closing after ${dateForScreenData} , must condition for a new title commitment.`;

  return (
    <ChakraAlert
      name={name}
      border="1px solid"
      borderColor={alertColor}
      background={alertBackground}
      borderRadius="4px"
      status={status}
      w="100%"
      mb="1rem"
      sx={sx}
    >
      <Box color={`${alertColor}`} mr={2}>
        <FontAwesomeIcon icon={alertIcon} />
      </Box>

      <AlertTitle mr={4} fontSize="sm" color={alertColor} whiteSpace="nowrap">
        {title}
      </AlertTitle>

      <AlertDescription fontSize="sm" lineHeight="24px">
        {message}
      </AlertDescription>
    </ChakraAlert>
  );
};

EffectiveDateAlert.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
};

export default EffectiveDateAlert;
