import TYPES from './types';
import { FIRST_TIME_HOME_BUYER_API_STATUS } from '../constants';
import { INITIAL_FIRST_TIME_HOME_BUYER_INFO } from '../constants';
import { setFirstTimeHomeBuyerData } from '../model/FirstTimeHomeBuyerController';

const initialState = {
  status: null,
  error: null,
  data: setFirstTimeHomeBuyerData(INITIAL_FIRST_TIME_HOME_BUYER_INFO),
};

const FirstTimeHomeBuyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_FIRST_TIME_HOME_BUYER_INFO_STARTED:
      return {
        ...state,
        status: FIRST_TIME_HOME_BUYER_API_STATUS.GETTING,
        error: null,
      };
    case TYPES.UPDATE_FIRST_TIME_HOME_BUYER_INFO_STARTED:
      return {
        ...state,
        status: FIRST_TIME_HOME_BUYER_API_STATUS.UPDATING,
        error: null,
      };
    case TYPES.GET_FIRST_TIME_HOME_BUYER_INFO_SUCCESS:
      return {
        ...state,
        data: setFirstTimeHomeBuyerData(action.payload),
        status: FIRST_TIME_HOME_BUYER_API_STATUS.GET_SUCCESS,
        error: null,
      };
    case TYPES.UPDATE_FIRST_TIME_HOME_BUYER_INFO_SUCCESS:
      return {
        ...state,
        status: FIRST_TIME_HOME_BUYER_API_STATUS.UPDATE_SUCCESS,
        error: null,
      };
    case TYPES.GET_FIRST_TIME_HOME_BUYER_INFO_ERROR:
      return {
        ...state,
        status: FIRST_TIME_HOME_BUYER_API_STATUS.GET_ERROR,
        error: action.error,
      };
    case TYPES.UPDATE_FIRST_TIME_HOME_BUYER_INFO_ERROR:
      return {
        ...state,
        status: FIRST_TIME_HOME_BUYER_API_STATUS.UPDATE_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};

export default FirstTimeHomeBuyerReducer;
