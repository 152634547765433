import TYPES from './types';

let initialState = { FEVerified: false };

const feVerifiedReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_FE_VERIFIED:
      return { FEVerified: action.payload };
    default:
      return state;
  }
};

export default feVerifiedReducer;
