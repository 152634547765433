import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePDF } from '@react-pdf/renderer';
import Button from 'common/components/Buttons/Button/index';
import { BoltApprovalThunks } from './redux';
import { API_STATUS } from 'common/constants';

import PdfCreator from './PdfCreator.js';

const BoltApproval = () => {
  const { status, error, approvalData, obfuscatedLoanIdentifier, loanNumber } =
    useSelector(
      ({
        boltApproval,
        home: { loanSummary },
        url: { obfuscatedLoanIdentifier },
      }) => {
        return {
          ...boltApproval,
          obfuscatedLoanIdentifier,
          loanNumber: loanSummary.value?.loanNumber,
        };
      },
    );
  const dispatch = useDispatch();
  const lenderDatabaseId = obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId;
  const loanRecordId = obfuscatedLoanIdentifier.obfuscatedLoanRecordId;
  const [show, updateShow] = useState(false);
  const [instance, updateInstance] = usePDF({
    document: <PdfCreator data={[approvalData]} />,
  });

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (lenderDatabaseId && loanRecordId) {
      dispatch(
        BoltApprovalThunks.getApprovalData(lenderDatabaseId, loanRecordId),
      );
      updateShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loanRecordId, lenderDatabaseId]);

  useEffect(() => {
    updateInstance();
  }, [approvalData, updateInstance]);

  if (status === API_STATUS.GETTING) return <div>Loading...</div>;
  if (status === API_STATUS.ERROR)
    return <div>Something went wrong: {error}</div>;

  return (
    <div>
      {show && (
        <a
          onClick={() => {
            openInNewTab(instance.url);
          }}
          href={instance.url}
          download={`BOLT_APPROVAL_${loanNumber}.pdf`}
        >
          <Button
            backgroundColor="blue.600"
            color="white"
            _hover={{
              cursor: 'pointer',
              backgroundColor: 'blue.500',
            }}
          >
            View BOLT Approval
          </Button>
        </a>
      )}
    </div>
  );
};

export default BoltApproval;
