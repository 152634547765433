import PropTypes from 'prop-types';
import SimpleGrid from 'common/components/Grids/Grid/SimpleGrid';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

export default function Grid({
  fields = [],
  name,
  columns,
  rowSpacing = '10px',
  columnSpacing = '10px',
  sx,
}) {
  const { renderForm } = useFormApi();

  const styles = {
    marginBottom: '1rem',
    ...sx,
  };

  return (
    <SimpleGrid
      id={name}
      spacingX={columnSpacing}
      spacingY={rowSpacing}
      columns={columns}
      sx={styles}
    >
      {renderForm(fields)}
    </SimpleGrid>
  );
}

Grid.propTypes = {
  fields: PropTypes.array,
  name: PropTypes.string,
  columns: PropTypes.number,
  rowSpacing: PropTypes.string,
  columnSpacing: PropTypes.string,
  sx: PropTypes.object,
};
