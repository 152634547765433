import CrudGrid from 'common/components/CrudGrid';
import { useState, useEffect, useMemo } from 'react';
import apiEndPoints from 'common/constants/api-endpoints';
import { USER_ROLE } from 'common/constants';
import apiClient from 'common/util/api-client';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';

import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';

export class NewBorrower {
  customerRecordId = 0;
  lastName = '';
  firstName = '';
  suffixName = '';
  middleName = '';
  borrowerPosition = '';
  socialSecurityNumber = '';
}

export default function Borrower1003Layout() {
  // TODO refactor to useReducer and useQuery
  const dispatch = useDispatch();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [allBorrowers, setAllBorrowers] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);
  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !hasWritePermission)
    );
  }, [isUnderwriter, boltStatus, hasWritePermission, role]);

  const columns = [
    {
      fieldName: 'firstName',
      headerName: 'First Name',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'middleName',
      headerName: 'MI',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'lastName',
      headerName: 'Last Name',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'suffixName',
      headerName: 'Suffix',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'socialSecurityNumber',
      headerName: 'Social Security Number',
      isNumeric: false,
      display: true,
      renderCell: (val) => (val ? val : '-'),
    },
    {
      fieldName: 'borrowerPosition',
      headerName: 'Position',
      isNumeric: false,
      display: true,
    },
  ];

  const getAllData = () => {
    let isMounted = true;
    setIsLoading(true);
    // TODO: once the API is ready, switch this out with the get borrower API call
    apiClient
      .post(
        apiEndPoints.underwritingSummariesLiability
          .GET_LIABILITIES_SUMMARY_DATA,
        {
          obfuscatedLoanIdentifier,
          dropdownOptionsNeeded: true,
        },
      )
      .then((res) => {
        if (!isMounted) return;
        setAllBorrowers(res.data.availableBorrowers);
        setHasWritePermission(res.data.hasWritePermission ?? false);
        setDropdownOptions(res.data.dropdownOptions);
        setData(
          res.data.availableBorrowers.sort(
            (a, b) => a.borrowerPosition - b.borrowerPosition,
          ),
        );
      })
      .catch(() => {
        let toastMessage = {
          title: 'Error',
          description: 'Could not get data',
          status: 'error',
          duration: 5000,
          isClosable: false,
        };
        toast(toastMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAllData, []);

  const handleEditMode = (row, isEditBool) => {
    setIsEdit(isEditBool);
    setRowToEdit(row);
    setShowForm(true);
  };

  const handleClose = () => {
    const url = new URL(window.location);
    url.searchParams.delete('tab');
    window.history.pushState({}, '', url);
    setRowToEdit({});
    setShowForm(false);
  };

  const handleSave = () => {
    getAllData();
    setShowForm(false);
    setRowToEdit({});
  };

  const handleDelete = async (rows) => {
    let toastMessage = {};
    const liabilitiesToDelete = rows.map(({ liabilityIdentifier }) => ({
      ...liabilityIdentifier,
    }));

    try {
      const res = await apiClient.post(
        apiEndPoints.underwritingSummariesLiability
          .DELETE_LIABILITIES_SUMMARY_DATA,
        {
          obfuscatedLoanIdentifier,
          liabilitiesToDelete,
        },
      );

      if (res) {
        toastMessage = {
          title: 'Success',
          description: 'Liabilities Deleted',
          status: 'success',
          duration: 5000,
          isClosable: true,
        };
        getAllData();
        setRowToEdit({});
        setShowForm(false);
        toast(toastMessage);
        dispatch(setLoanSummaryUpsertOrDelete(true));
      }
    } catch (error) {
      toastMessage = {
        title: 'Delete Failed',
        description: 'Records were not able to be deleted.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      };
      toast(toastMessage);
    }
  };

  const gridDef = {
    gridType: 'Borrower',
    defaultGridMode: 'singleAction',
    defaultSort: { field: 'balance', direction: 'desc' },
    uniqueIdAttributeFields: ['socialSecurityNumber', 'borrowerPosition'],
    manageColumns: false,
    allowFilters: false,
    gridLanguage: {
      singularItem: 'Borrower',
      pluralItem: 'Borrowers',
    },
  };

  const buttonDefs = [
    {
      label: 'Add Borrower',
      action: () => {
        handleEditMode(new NewBorrower());
      },
      visible: true,
      isDisable: false,
      testId: 'addBorrowerButton',
    },
  ];

  return (
    <StyledLoan1003LayoutContainer>
      <CrudGrid
        gridDef={gridDef}
        columns={columns}
        data={data}
        dropdownOptions={dropdownOptions}
        onRowEditClick={(row) => {
          handleEditMode(row, true);
        }}
        // TODO: remove readOnly when API is ready
        readOnly={true}
        selectedRow={rowToEdit}
        isLoading={isLoading}
        onDelete={(row) => handleDelete(row)}
        buttonDefs={buttonDefs}
        onGridModeChange={() => setShowForm(false)}
      />
    </StyledLoan1003LayoutContainer>
  );
}
