import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Box from 'common/components/Box';
import Toolbar from './Toolbar';
import Area from './Area';
import Spinner from 'common/components/Spinner';
import ErrorMessage from 'common/components/ErrorMessage';
import { minScale, defaultScale } from './constants';

function Viewer({
  docname,
  pdf,
  highlights,
  pageRotations,
  pageNumber,
  citations,
  isFullPage,
  documentOptions,
  showHighLight,
}) {
  const [numPages, setNumPages] = useState(0);
  const [isLoadComplete, setIsLoadComplete] = useState(true);
  const [display, setDisplay] = useState('none');
  const [scale, setScale] = useState(defaultScale);
  const [zoom, setZoom] = useState(convertScaleToZoom(scale));
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);

  useEffect(() => {
    if (pdf !== null) {
      setIsLoadComplete(false);
    } else {
      setNumPages(0);
      setCurrentPageNumber(pageNumber);
      setIsLoadComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  useEffect(() => {
    if (isLoadComplete) {
      setDisplay('flex');
      observePageVisibility();
    } else {
      setDisplay('none');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadComplete]);

  useEffect(() => {
    if (!citations) return;

    if (citations[0]?.pageNumber) {
      setCurrentPageNumber(citations[0].pageNumber);
    }
  }, [citations]);

  useEffect(() => {
    setZoom(convertScaleToZoom(scale));
  }, [scale]);

  function convertScaleToZoom(scale) {
    let percent = scale * 100;
    let roundedPercent = Math.ceil(percent / 5) * 5;
    return roundedPercent;
  }

  function handleScaleChange(newScale) {
    setValidatedScale(newScale);
  }

  function setValidatedScale(scale) {
    if (scale >= minScale) {
      setScale(scale);
      observePageVisibility();
    }
  }

  function handleDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function handleRenderSuccess() {
    setIsLoadComplete(true);
  }

  function handleDocumentError(error) {
    var errorString = error.toString();
    if (errorString.includes('Worker was destroyed')) return;
    setIsLoadComplete(true);
  }

  function observePageVisibility() {
    let options = {
      root: document.querySelector('#viewerArea'),
      rootMargin: '0px',
      threshold: 0,
    };
    const visiblePages = new Set();
    // let callback = (entries, observer) => {
    let callback = (entries) => {
      entries.forEach((entry) => {
        let pageNum = entry.target.dataset.pageNumber;
        if (entry.isIntersecting) {
          visiblePages.add(pageNum);
        } else {
          visiblePages.delete(pageNum);
        }
      });
      let minPage = Math.min.apply(this, [...visiblePages]);
      if (visiblePages.size > 0) {
        setCurrentPageNumber(minPage);
      }
    };
    let observer = new IntersectionObserver(callback, options);
    for (let i = 1; i <= numPages; i++) {
      let selector = `.react-pdf__Page[data-page-number="${i}"]`;
      let target = document.querySelector(selector);
      if (target !== null) {
        observer.observe(target);
      }
    }
  }

  return (
    <Box
      id="viewerArea"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: '#1E1E1E',
        height: '100%',
      }}
    >
      <Toolbar
        docName={docname}
        currentPageNumber={pdf !== undefined ? currentPageNumber : 0}
        setPageNumberChange={(e) => setCurrentPageNumber(e)}
        totalPages={numPages}
        minZoom={minScale * 100}
        scale={scale}
        zoom={zoom}
        onScaleChange={handleScaleChange}
        sx={{ display: { display } }}
        documentOptions={documentOptions}
      />
      <Area
        pdf={pdf}
        scale={scale}
        display={display}
        onScaleChange={handleScaleChange}
        onRenderSuccess={handleRenderSuccess}
        onLoadSuccess={handleDocumentLoadSuccess}
        onRenderError={handleDocumentError}
        onLoadError={handleDocumentError}
        highlights={highlights}
        citations={citations}
        pageNumber={currentPageNumber}
        pageRotations={pageRotations}
        isFullPage={isFullPage}
        showHighLight={showHighLight}
      />
      {!isLoadComplete && pdf && <Spinner style={{ flex: 1 }} />}
      {(pdf === undefined || pdf === 'noDocumentFound') && (
        <ErrorMessage message={'No Document(s) Available'} />
      )}
    </Box>
  );
}

export default Viewer;

Viewer.propTypes = {
  docname: PropTypes.any,
  pdf: PropTypes.any,
  highlights: PropTypes.any,
  pageRotations: PropTypes.any,
  pageNumber: PropTypes.any,
  setPageNumber: PropTypes.any,
  citations: PropTypes.any,
  isFullPage: PropTypes.bool,
  documentOptions: PropTypes.any,
  showHighLight: PropTypes.bool,
};
