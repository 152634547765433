import { useFieldApi, FormSpy } from '@data-driven-forms/react-form-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Box from 'common/components/Box';
import { ExtractedActionsArray } from '../../../pages/Assets/AssetDepositoryAccounts/enum';
import { FormElementWidget } from 'lib-node-amb-renderer';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash-es';
import { reverseFormatCurrency, formatDate } from 'common/util/format';
import boltIcon from '../../util/icons/Bolt.svg';
import userIcon from '../../util/icons/User.svg';
import PropTypes from 'prop-types';
import { RULE_ID } from 'common/constants';

export class FormIconClass extends FormElementWidget {
  constructor(props) {
    super(props);
  }
}

const getIcon = (iconType) => {
  switch (iconType) {
    case 'NewEntry':
      return;
    default:
      return faCamera;
  }
};

function getValuePerRow(tableValues, name, valueToCompare) {
  const nameContents = name.split(/\W+/);
  return tableValues[`1-${nameContents[2]}`]?.[valueToCompare]?.[
    nameContents[4]
  ];
}

function getComparingValuesPerRow(tableValues, name) {
  const nameContents = name.split(/\W+/);
  if (nameContents[3] === 'debtsAndDeduction') {
    return [
      +tableValues?.amount,
      tableValues?.description?.trim()?.toLowerCase(),
      formatDate(tableValues?.date)?.trim(),
      tableValues?.type,
    ];
  } else {
    return [
      +tableValues?.amount,
      tableValues?.description?.trim()?.toLowerCase(),
      formatDate(tableValues?.date)?.trim(),
    ];
  }
}

function getBoltAnswerPerRow(data, name, valueToCompare) {
  const nameContents = name.split(/\W+/);
  const rowValuesBoltAnswer =
    data[`1-${nameContents[2]}`]?.[valueToCompare]?.[nameContents[4]]
      ?.originalExtractedData;
  const originalExtractedData = rowValuesBoltAnswer?.split('//*');
  const boltAnswer = [];

  if (nameContents[3] === 'debtsAndDeduction') {
    boltAnswer.push(+originalExtractedData?.[0]?.slice(13));
    boltAnswer.push(originalExtractedData?.[1]?.trim()?.toLowerCase());
    boltAnswer.push(formatDate(originalExtractedData?.[2]));
    boltAnswer.push(originalExtractedData?.[3]?.trim()?.split(/\W+/)?.join(''));
    return boltAnswer;
  } else {
    boltAnswer.push(
      reverseFormatCurrency(originalExtractedData?.[0]?.slice(13)),
    );
    boltAnswer.push(originalExtractedData?.[1]?.trim()?.toLowerCase());
    boltAnswer.push(formatDate(originalExtractedData?.[2]));
    return boltAnswer;
  }
}

export default function FormIcon(props) {
  const { selectedAlert } = useSelector(({ home: { selectedAlert } }) => ({
    selectedAlert,
  }));
  const { ruleId } = selectedAlert || {};

  if (ruleId === RULE_ID.ASSET_TYPE_RULE_ID) {
    return (
      <FormSpy subscription={{ values: true, valid: true }}>
        {({ values }) => {
          // eslint-disable-next-line react/prop-types
          const { statementDetails } = values;
          const { name } = props;
          const nameContents = name.split(/\W+/);
          const rowValuesTransactionSource =
            statementDetails[`1-${nameContents[2]}`]?.[nameContents[3]]?.[
              nameContents[4]
            ]?.assetTransactionSource;

          if (rowValuesTransactionSource === 'NewEntry') {
            return <InnerComponent {...props} />;
          }

          const currentFormValues = getValuePerRow(
            statementDetails,
            name,
            props.valueToCompare,
          );

          const currentFormComparingValues = getComparingValuesPerRow(
            currentFormValues,
            name,
          );
          const boltAnswer = getBoltAnswerPerRow(
            statementDetails,
            name,
            props.valueToCompare,
          );

          let hasDataNotChanged;
          if (
            rowValuesTransactionSource === ExtractedActionsArray[0] ||
            rowValuesTransactionSource === ExtractedActionsArray[1]
          ) {
            hasDataNotChanged = isEqual(currentFormComparingValues, boltAnswer);
          }

          return (
            <InnerComponent {...props} hasDataNotChanged={hasDataNotChanged} />
          );
        }}
      </FormSpy>
    );
  } else {
    return <InnerComponent {...props} />;
  }
}

FormIcon.propTypes = {
  props: PropTypes.object,
  name: PropTypes.string,
  valueToCompare: PropTypes.string,
};

function InnerComponent(props) {
  const { name, sx, color, fontSize, lineHeight, hasDataNotChanged, input } =
    useFieldApi({
      ...props,
    });

  if (
    input.value !== ExtractedActionsArray[2] &&
    hasDataNotChanged !== undefined
  ) {
    if (hasDataNotChanged) {
      input.onChange((input.value = 'System'));
    } else if (!hasDataNotChanged && input.value === 'System') {
      input.onChange((input.value = 'UserModified'));
    }
  }

  return (
    <Box sx={sx} color={color ? color : 'uwmLogo.blue'}>
      {input.value !== ExtractedActionsArray[2] &&
        (input.value === 'System' ? (
          <img src={boltIcon} alt=" boltIcon" />
        ) : input.value === 'UserModified' ? (
          <img src={userIcon} alt="usericon" />
        ) : (
          <FontAwesomeIcon
            icon={getIcon(input.value)}
            name={name}
            fontSize={fontSize ? fontSize : 'sm'}
            lineHeight={lineHeight ? lineHeight : '1.5rem'}
            {...input}
          />
        ))}
    </Box>
  );
}
