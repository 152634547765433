import * as React from 'react';
import PropTypes from 'prop-types';
import CommonTypes from 'common/propTypes';
import FallbackScreen from './FallbackScreen';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';

class ErrorBoundary extends React.Component {
  static errorLogDelay = 5;
  static regex =
    /(?<filename>[a-zA-Z._]+\.(?:html|js|jsx|ts|tsx)).*?:(?<line>\d+)(?::(?<column>\d+))?/;

  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedAlert === prevProps.selectedAlert) {
      return;
    }

    // The selected alert has changed, meaning we are viewing a new page.
    // Reset error state so that the user can see the new page.
    this.reset();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error) {
    const errorMessage = `${this.props.logPrefix ?? ''}${
      error.message
    }\nStack:\n${error.stack}`;
    const selectedAlert = this.props.selectedAlert;

    if (!this._checkIfShouldSendError(errorMessage)) {
      return;
    }

    try {
      await apiClient.post(apiEndPoints.POST_ERROR_LOG, {
        obfuscatedLoanIdentifier: this.props.loan,
        error: errorMessage,
        selectedAlert: selectedAlert && {
          ruleResultId: selectedAlert.ruleResultId,
          ruleName: selectedAlert.ruleName,
          ruleCategoryName: selectedAlert.ruleCategoryName,
          additionalReferences: selectedAlert.additionalReferences,
        },
        lastAction: apiClient.lastRequest,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // In order to avoid spamming the endpoint in the case of repeating errors,
  // we cache sent errors and only re-send after a timeout window.
  _checkIfShouldSendError(error) {
    if (!window.sessionStorage) {
      return false;
    }
    const now = new Date().getTime();
    const sentErrors = JSON.parse(
      window.sessionStorage.getItem('sentErrors') ?? '[]',
    );
    const lineNumber = error.match(ErrorBoundary.regex).groups.line;
    const cachedError = sentErrors.find((e) => e.line === lineNumber);
    const result =
      now - (cachedError?.time ?? 0) > ErrorBoundary.errorLogDelay * 1000;

    window.sessionStorage.setItem(
      'sentErrors',
      JSON.stringify(
        sentErrors
          .filter((e) => e !== cachedError)
          .concat({
            line: lineNumber,
            time: now,
          }),
      ),
    );

    return result;
  }

  reset() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ? (
        this.props.fallback
      ) : (
        <FallbackScreen resetError={this.reset} />
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  loan: CommonTypes.obfuscatedLoanIdentifier.isRequired,
  selectedAlert: CommonTypes.alert,
  fallback: PropTypes.node,
  logPrefix: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ErrorBoundary;
