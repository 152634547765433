import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { css } from '@emotion/react';
import { useTheme } from '@chakra-ui/react';
import Box from 'common/components/Box';
import IconButton from 'common/components/Icons/IconButton';
import {
  Table as TableComponent,
  Tbody,
  Thead,
  Tr,
} from 'common/components/Tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import GenericTooltip from '../components/GenericTooltip';
import { Icon } from './Icon';

export function TableRow({ fields, headerCols, sx = {} }) {
  const { renderForm } = useFormApi();
  const { colors } = useTheme();

  return (
    <Tr
      sx={sx}
      css={css`
        :nth-of-type(even) {
          background-color: ${colors.bgGray['100']};
        }
      `}
    >
      {fields.map((field, i) => (
        <td
          colSpan={headerCols[i]?.colSpan}
          css={css`
            font-size: 0.875rem;
            margin: 0;
          `}
          key={field.name}
        >
          {renderForm([field])}
        </td>
      ))}
    </Tr>
  );
}
TableRow.propTypes = {
  fields: PropTypes.array,
  headerCols: PropTypes.arrayOf(PropTypes.shape({ colSpan: PropTypes.number })),
  sx: PropTypes.object,
};

export default function Table({
  tableHeader,
  fields,
  accordion = false,
  isEditable = false,
  headerBgColor = 'gray.50',
  sx = {},
}) {
  const { renderForm } = useFormApi();
  const { colors } = useTheme();
  const [expanded, setExpanded] = useState(true);

  return (
    <TableComponent
      size="sm"
      variant="unstyled"
      css={css`
        border-radius: 4px;
        margin: 1rem 0;
        border-collapse: separate;
        border-spacing: 0;

        & th,
        & td {
          text-align: left;
          vertical-align: middle;
          padding: 0.5rem 1.5rem;
          min-height: 40px;
        }
      `}
      sx={{
        border: `1px solid ${colors.gray['200']}`,
        ['td']: {
          verticalAlign: sx?.verticalAlign ? sx.verticalAlign : 'middle',
        },
        ...sx,
      }}
    >
      <Thead
        bg={isEditable ? colors.blue[50] : headerBgColor}
        position="relative"
      >
        {tableHeader.some((obj) => obj.label) && (
          <HeaderMapper tableHeader={tableHeader} />
        )}
        {accordion && (
          <IconButton
            aria-label="expand"
            size="sm"
            variant="ghost"
            icon={
              <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            }
            onClick={() => setExpanded((e) => !e)}
            css={css`
              display: inline-block;
              position: absolute;
              top: 0.25rem;
              right: 1rem;
            `}
          />
        )}
      </Thead>
      {expanded && (
        <Tbody>
          {fields.map((field) => {
            field.headerCols = tableHeader;
            return renderForm([field]);
          })}
        </Tbody>
      )}
    </TableComponent>
  );
}
function HeaderMapper({ tableHeader }) {
  return (
    <Tr>
      {tableHeader.map(({ label, tooltip, colSpan, startIcon, endIcon }, i) => (
        <th scope="col" colSpan={colSpan} key={i} style={{ fontWeight: 500 }}>
          <Box d="flex" gap="8px">
            {startIcon && <HeaderIcon icon={startIcon} />}
            {label}
            {tooltip && <GenericTooltip label={tooltip} />}
            {endIcon && <HeaderIcon icon={endIcon} />}
          </Box>
        </th>
      ))}
    </Tr>
  );
}

HeaderMapper.propTypes = {
  tableHeader: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.number,
      label: PropTypes.string,
      tooltip: PropTypes.string,
      startIcon: PropTypes.string,
      endIcon: PropTypes.string,
    }),
  ).isRequired,
};

function HeaderIcon({ icon }) {
  let iconProps;

  switch (icon) {
    case 'manualReview':
      iconProps = {
        iconType: 'faFlag',
        variant: 'round',
        color: 'black',
        bgColor: 'orange.300',
      };
      break;

    default:
      iconProps = { iconType: icon };
  }

  return <Icon {...iconProps} />;
}
HeaderIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

Table.propTypes = {
  tableHeader: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.number,
      label: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  fields: PropTypes.array,
  accordion: PropTypes.bool,
  isEditable: PropTypes.bool,
  headerBgColor: PropTypes.string,
  sx: PropTypes.object,
};
