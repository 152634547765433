import React from 'react';
import { FormControl as ChakraFormControl } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const FormControl = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraFormControl ref={ref} {...props}>
      {children}
    </ChakraFormControl>
  );
});

FormControl.displayName = 'FormControl';

export default FormControl;

FormControl.propTypes = {
  children: PropTypes.node,
  isInvalid: PropTypes.bool,
};
