import { FormLabel as ChakraFormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const FormLabel = ({ children, ...props }) => {
  return <ChakraFormLabel {...props}>{children}</ChakraFormLabel>;
};

export default FormLabel;

FormLabel.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
