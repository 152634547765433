export const PURCHASE_AGREEMENT_TYPES = {
  GET_PURCHASE_AGREEMENT_ERROR: 'GET_PURCHASE_AGREEMENT_ERROR',
  GET_PURCHASE_AGREEMENT_STARTED: 'GET_PURCHASE_AGREEMENT_STARTED',
  GET_PURCHASE_AGREEMENT_SUCCESS: 'GET_PURCHASE_AGREEMENT_SUCCESS',
  UPDATE_PURCHASE_AGREEMENT_ERROR: 'UPDATE_PURCHASE_AGREEMENT_ERROR',
  UPDATE_PURCHASE_AGREEMENT_STARTED: 'UPDATE_PURCHASE_AGREEMENT_STARTED',
  UPDATE_PURCHASE_AGREEMENT_SUCCESS: 'UPDATE_PURCHASE_AGREEMENT_SUCCESS',
  GET_FHA_REQUIREMENTS_ERROR: 'GET_FHA_REQUIREMENTS_ERROR',
  GET_FHA_REQUIREMENTS_STARTED: 'GET_FHA_REQUIREMENTS_STARTED',
  GET_FHA_REQUIREMENTS_SUCCESS: 'GET_FHA_REQUIREMENTS_SUCCESS',
  UPDATE_FHA_REQUIREMENTS_ERROR: 'UPDATE_FHA_REQUIREMENTS_ERROR',
  UPDATE_FHA_REQUIREMENTS_STARTED: 'UPDATE_FHA_REQUIREMENTS_STARTED',
  UPDATE_FHA_REQUIREMENTS_SUCCESS: 'UPDATE_FHA_REQUIREMENTS_SUCCESS',

  CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_STARTED:
    'CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_STARTED',
  CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_SUCCESS:
    'CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_SUCCESS',
  CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_ERROR:
    'CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_ERROR',

  CALCULATE_ALLOCATED_SELLER_CONCESSIONS_STARTED:
    'CALCULATE_ALLOCATED_SELLER_CONCESSIONS_STARTED',
  CALCULATE_ALLOCATED_SELLER_CONCESSIONS_SUCCESS:
    'CALCULATE_ALLOCATED_SELLER_CONCESSIONS_SUCCESS',
  CALCULATE_ALLOCATED_SELLER_CONCESSIONS_ERROR:
    'CALCULATE_ALLOCATED_SELLER_CONCESSIONS_ERROR',

  RESET_STATUS: 'RESET_STATUS',
};

export const ERROR_TYPES = {
  INITIALIZE: 'ERROR_INITIALIZE',
  RESET: 'ERROR_RESET',
  UPDATE: 'ERROR_UPDATE',
};
export const INPUT_TYPES = {
  INITIALIZE: 'INITIALIZE',
  STRING: 'STRING',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  UPDATE_EMD_FIELD: 'UPDATE_EMD_FIELD',
  INPUT_TOUCHED_RESET: 'INPUT_TOUCHED_RESET',
  INPUT_TOUCHED_UPDATE: 'INPUT_TOUCHED_UPDATE',
};
