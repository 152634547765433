import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Text from 'common/components/Texts/Text';

import { StyledHeader as Header } from 'common/components/GlobalLayout/Header';
import AuditHistoryButton from './AuditHistoryButton';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';

const AuditHistoryLayoutHeader = ({
  showButton = true,
  isWriteAccessDisabled,
  ...props
}) => {
  const { displayTitle } = useSelector(({ home }) => ({
    displayTitle: home.selectedAlert?.displayTitle,
  }));
  return (
    <Header
      data-testid="audit-history-header"
      isWriteAccessDisabled={isWriteAccessDisabled}
    >
      {displayTitle && (
        <Text data-testid="audit-history--title" w="100%" as="h2">
          {displayTitle}
        </Text>
      )}
      <Form1003PopUpButton style={{ marginRight: '1rem' }} />
      {showButton && (
        <div data-testid="audit-history--button">
          <AuditHistoryButton {...props} />
        </div>
      )}
    </Header>
  );
};

AuditHistoryLayoutHeader.propTypes = {
  showButton: PropTypes.bool,
  isWriteAccessDisabled: PropTypes.bool,
};

export default AuditHistoryLayoutHeader;
