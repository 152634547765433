import PropTypes from 'prop-types';
import * as rPdf from '@react-pdf/renderer';
import UWMLogo from '../../common/components/UWMLogo';
import CoverLetter from './CoverLetter';

const { Page, Text, View, Document, StyleSheet, Font } = rPdf;

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-black-webfont.ttf',
      fontWeight: 900,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
  ],
});

const PdfCreator = (props) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#ffffff',
      fontFamily: 'Roboto',
      fontWeight: 400,
      position: 'relative',
      paddingTop: '8px',
      paddingBottom: '60px',
    },
    base: {
      display: 'flex',
      position: 'relative',
    },
    header: {
      color: '#ffffff',
      backgroundColor: '#1E4F8C',
      fontSize: '12px',
      fontWeight: 'black',
      paddingLeft: '16px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    topSection: {
      border: '1px solid #4A5568',
      padding: '8px',
      margin: '8px',
      marginTop: 0,
      display: 'flex',
    },
    headerContentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      margin: '8px',
    },
    label: {
      fontSize: '8px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4px',
    },
    text: {
      fontSize: '9px',
      fontWeight: 400,
      color: '#1A202C',
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '24px',
    },
    column3: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '20px',
    },
    logo: {
      marginLeft: '16px',
      display: 'flex',
      flexDirection: 'column',
    },
    conditionsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8px',
      marginLeft: '20px',
      marginRight: '20px',
      marginBottom: '8px',
    },
    conditions: {
      borderBottom: '3px solid #dddcdd',
      marginBottom: '6px',
    },
    conditionsTitle: {
      fontSize: '9px',
      fontWeight: 'bold',
      color: '#1A202C',
      marginBottom: '2px',
    },
    conditionsIterationWrapper: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '2px solid #565757',
      paddingBottom: '8px',
    },
    conditionLineItem: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '4px',
    },
    conditionCol1: {
      fontSize: '9px',
      color: '#1A202C',
      width: '10%',
      paddingBottom: '4px',
    },
    conditionCol2: {
      fontSize: '9px',
      color: '#1A202C',
      width: '20%',
      paddingBottom: '4px',
    },
    conditionCol3: {
      fontSize: '9px',
      color: '#1A202C',
      width: '70%',
      paddingRight: '8px',
      paddingBottom: '4px',
    },
    footer: {
      display: 'flex',
      position: 'absolute',
      bottom: '10px',
      left: 0,
      right: 0,
    },
    legal: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '7px',
      fontWeight: 'normal',
      marginLeft: '20px',
      marginRight: '40px',
    },
  });

  const dateCreated = `${new Date().toLocaleDateString(
    'en-US',
  )} ${new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;

  // this is an array of booleans and it will map to the incoming object that is an array of length = 1
  let categoryDividers = [];

  props.data.forEach((a) => {
    categoryDividers = [];
    const n = a.loanConditionData.preapprovalLoanConditions.length;
    a.loanConditionData.preapprovalLoanConditions.forEach((obj, i) => {
      let j = i + 1;
      if (j < n) {
        if (
          obj.condAlias !==
          a.loanConditionData.preapprovalLoanConditions[j].condAlias
        ) {
          // Category barrior!
          categoryDividers.push(true);
        } else {
          // It is the same!
          categoryDividers.push(false);
        }
      }
    });
  });

  return (
    <Document>
      <CoverLetter />
      {props.data
        ? props.data.map((a, index) => {
            return (
              <Page style={styles.page} key={`page-${index + 1}`} wrap>
                <View style={styles.base}>
                  <View id="topSection" style={styles.topSection}>
                    <View id="header">
                      <Text style={styles.header}>BOLT APPROVAL</Text>
                    </View>
                    <View
                      id="headerContentWrapper"
                      style={styles.headerContentWrapper}
                    >
                      <View style={styles.logo}>
                        <UWMLogo />
                      </View>
                      <View style={styles.column2}>
                        <Text style={styles.label}>
                          Loan Number:
                          <Text style={styles.text}>
                            {a.loanInfo.loanNumber}
                          </Text>
                        </Text>
                        {a.loanInfo.borrowers.map((borrower, index) => {
                          return (
                            <Text
                              key={`borrower-${index + 1}`}
                              style={styles.label}
                            >
                              Borrower:
                              <Text style={styles.text}>
                                {borrower.firstName} {borrower.lastName}
                              </Text>
                            </Text>
                          );
                        })}
                        <Text style={styles.label}>
                          Property:
                          <Text style={styles.text}>
                            {a.loanInfo.propertyAddress.unit
                              ? `${a.loanInfo.propertyAddress.addressLine1}, ${a.loanInfo.propertyAddress.unit}, ${a.loanInfo.propertyAddress.city}, ${a.loanInfo.propertyAddress.state}, ${a.loanInfo.propertyAddress.zipCode}`
                              : `${a.loanInfo.propertyAddress.addressLine1}, ${a.loanInfo.propertyAddress.city}, ${a.loanInfo.propertyAddress.state}, ${a.loanInfo.propertyAddress.zipCode}`}
                          </Text>
                        </Text>
                      </View>
                      <View style={styles.column3}>
                        <Text style={styles.label}>
                          Loan Amount:
                          <Text style={styles.text}>
                            ${a.loanInfo.totalLoanAmount}
                          </Text>
                        </Text>

                        {!!a.loanInfo.salesPrice && (
                          <Text style={styles.label}>
                            Purchase Price:
                            <Text style={styles.text}>
                              ${a.loanInfo.salesPrice}
                            </Text>
                          </Text>
                        )}
                        <Text style={styles.label}>
                          Date Created:
                          <Text style={styles.text}>{`${dateCreated}`}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.conditionsWrapper}>
                    <View style={styles.conditions}>
                      <Text style={styles.conditionsTitle}>CONDITIONS</Text>
                    </View>
                    <View style={styles.conditionsIterationWrapper}>
                      {a.loanConditionData.preapprovalLoanConditions.map(
                        (conditions, index) => {
                          if (categoryDividers[index] === true) {
                            return (
                              <View
                                key={`${conditions.condAlias}-${conditions.condID}`}
                              >
                                <View
                                  style={styles.conditionLineItem}
                                  key={`${conditions.condId}-${index}`}
                                >
                                  <View style={styles.conditionCol1}>
                                    <Text>{conditions.condID}</Text>
                                  </View>
                                  <View style={styles.conditionCol2}>
                                    <Text>{conditions.condAlias}</Text>
                                  </View>
                                  <View style={styles.conditionCol3}>
                                    <Text>{conditions.condName}</Text>
                                  </View>
                                </View>
                                <View
                                  style={styles.conditionsIterationWrapper}
                                ></View>
                              </View>
                            );
                          } else {
                            return (
                              <View
                                style={styles.conditionLineItem}
                                key={conditions.condID}
                              >
                                <View style={styles.conditionCol1}>
                                  <Text>{conditions.condID}</Text>
                                </View>
                                <View style={styles.conditionCol2}>
                                  <Text>{conditions.condAlias}</Text>
                                </View>
                                <View style={styles.conditionCol3}>
                                  <Text>{conditions.condName}</Text>
                                </View>
                              </View>
                            );
                          }
                        },
                      )}
                    </View>
                  </View>
                </View>
                <View id="footer" style={styles.footer} fixed>
                  <Text style={styles.legal}>{a.complianceFooter}</Text>
                </View>
              </Page>
            );
          })
        : ''}
    </Document>
  );
};

PdfCreator.propTypes = {
  props: PropTypes.object,
  data: PropTypes.array,
};

export default PdfCreator;
