import React from 'react';
import { Tab as MUITab } from '@mui/material';
import PropTypes from 'prop-types';

const Tab = ({ ...rest }) => {
  return <MUITab {...rest} />;
};

Tab.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.oneOf([null]), // Unsupported prop
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  disableRipple: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  iconPosition: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  label: PropTypes.node,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  value: PropTypes.any,
  wrapped: PropTypes.bool,
};

export default Tab;
