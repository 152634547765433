import TYPES from './types';
import { RENTAL_INCOME_DATA_API_STATUS } from '../constants';
import { setRentalIncomeData } from '../model/RentalIncomeDataController';

const initialState = {
  status: null,
  error: null,
  data: null,
  isRadioButtonSelectedStatus: false,
  is1040CheckboxSelectedStatus: false,
  isAcquisitionDateInvalid: {
    value: false,
    year: undefined,
    errorMessage: '',
  },
};

const RentalIncomeDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_RADIO_BUTTON_SELECTED_STATUS:
      return {
        ...state,
        isRadioButtonSelectedStatus: action.payload,
      };
    case TYPES.SET_1040_CHECKBOX_SELECTED_STATUS:
      return {
        ...state,
        is1040CheckboxSelectedStatus: action.payload,
      };
    case TYPES.SET_ACQUISITION_DATE_IS_INVALID: {
      return {
        ...state,
        isAcquisitionDateInvalid: action.payload,
      };
    }
    case TYPES.GET_RENTAL_INCOME_DATA_STARTED:
      return {
        ...state,
        status: RENTAL_INCOME_DATA_API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_DATA_SUCCESS:
      return {
        ...state,
        data: setRentalIncomeData(action.payload),
        status: RENTAL_INCOME_DATA_API_STATUS.GET_SUCCESS,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_DATA_ERROR:
      return {
        ...state,
        status: RENTAL_INCOME_DATA_API_STATUS.GET_ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};

export default RentalIncomeDataReducer;
