export default class PurchaseAgreementData {
  constructor(pa = {}) {
    this.purchaseContract = Object.prototype.hasOwnProperty.call(
      pa,
      'purchaseContract',
    )
      ? pa.purchaseContract
      : {
          contractEffectiveDate: '',
          purchasePrice: '',
          isPurchasePriceMatch: '',
          isAddressMatch: '',
          isBorrowerMatch: '',
          isShortSale: '',
          isShortSaleAgreementProvided: '',
          shortSaleAgreementExpirationDate: '',
          isMissingContractAddendums: '',
          isPagesMissingOrNotExecuted: '',
          containsUnallowablePersonalItems: '',
          ammendatoryClauseAmount: null,
        };

    this.propertyAddress = Object.prototype.hasOwnProperty.call(
      pa,
      'propertyAddress',
    )
      ? pa.propertyAddress
      : {
          streetAddress: '',
          unit: '',
          city: '',
          state: '',
          zipCode: '',
        };

    this.borrowers = Object.prototype.hasOwnProperty.call(pa, 'borrowers')
      ? pa.borrowers
      : {
          lenderdatabaseId: '',
          customerRecordId: '',
          firstName: '',
          middleName: '',
          lastName: '',
          suffixName: '',
          borrowerPosition: '',
          isPrimaryBorrower: '',
          dateOfBirth: '',
        };

    this.sellerContribution = Object.prototype.hasOwnProperty.call(
      pa,
      'sellerContribution',
    )
      ? pa.sellerContribution
      : {
          agentCredits: '',
          allocatedSellerConcessions: '',
          allowableMaxSellerConcessions: '',
          sellerConcessions: '',
        };
    this.assets = Object.prototype.hasOwnProperty.call(pa, 'assets')
      ? pa.assets
      : [
          {
            assetId: 0,
            assetLdId: 0,
            customerLenderDatabaseId: '',
            customerRecordId: '',
            isLiquid: true,
            verifiedDate: null,
            balance: '',
            accountNumber: null,
            addressLine1: null,
            addressLine2: null,
            assetType: 'Earnest Money Deposi',
            bankName: null,
            city: null,
            country: null,
            faceValue: 0,
            fhaSource: 0,
            giftDepositedDuFlag: 0,
            giftSourceId: 0,
            includeBalance: null,
            sellerFundDap: null,
            sendToAus: null,
            sourceDesc: null,
            sourceEin: null,
            state: null,
            verified: null,
            zipCode: null,
          },
        ];

    this.primaryAppraisal = Object.prototype.hasOwnProperty.call(
      pa,
      'primaryAppraisal',
    )
      ? pa.primaryAppraisal
      : {
          sellerConcessions: '',
        };

    this.purchaseAgreementDocumentIds = Object.prototype.hasOwnProperty.call(
      pa,
      'purchaseAgreementDocumentIds',
    )
      ? pa.purchaseAgreementDocumentIds
      : [];

    this.loanInformation = Object.prototype.hasOwnProperty.call(
      pa,
      'loanInformation',
    )
      ? pa.loanInformation
      : {
          loanLocked: 0,
          statusId: 0,
          statusDescription: '',
        };
    this.loanProductType = Object.prototype.hasOwnProperty.call(
      pa,
      'loanProductType',
    )
      ? pa.loanProductType
      : null;
  }
}
