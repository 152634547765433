import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import UnderwriterCheckbox from 'common/components/UnderwriterCheckbox/UnderwriterCheckbox';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isWriteAccessDisabled',
})((props) => ({
  opacity: props.isWriteAccessDisabled ? 0.6 : 1,
}));

const StyledText = styled(Text)((props) => ({
  fontWeight: 500,
  marginBottom: '24px',
  ...props?.customStyles,
}));

const Content = ({
  subHeading,
  subHeadingStyles,
  children,
  isWriteAccessDisabled,
}) => {
  return (
    <>
      {subHeading && (
        <StyledText customStyles={subHeadingStyles}>{subHeading}</StyledText>
      )}
      <StyledBox
        onClick={(e) => {
          if (isWriteAccessDisabled) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        isWriteAccessDisabled={isWriteAccessDisabled}
      >
        {children}
      </StyledBox>
      <UnderwriterCheckbox />
    </>
  );
};

Content.propTypes = {
  children: PropTypes.any,
  subHeading: PropTypes.string,
  subHeadingStyles: PropTypes.object,
  isWriteAccessDisabled: PropTypes.bool,
};

export default Content;
