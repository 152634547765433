import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const RING_COLOR = {
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
};

export const RING_SIZE = {
  SMALL: 'small',
  BIG: 'big',
};

const getBorderColor = (color, theme) => {
  let borderColor;
  switch (color) {
    case RING_COLOR.RED:
      borderColor = theme.colors.red[600];
      break;
    case RING_COLOR.GREEN:
      borderColor = theme.colors.green[600];
      break;
    case RING_COLOR.ORANGE:
      borderColor = theme.colors.orange[300];
      break;
    default:
      borderColor = theme.colors.green[600];
  }
  return borderColor;
};

const StyledRing = styled('span', {
  shouldForwardProp: (prop) => prop !== 'size',
})((props) => ({
  borderRadius: '50%',
  minHeight: props.size === RING_SIZE.SMALL ? '16px' : '20px',
  minWidth: props.size === RING_SIZE.SMALL ? '16px' : '20px',
  color: props.theme.colors.white,
  border: '1px solid',
  borderColor: getBorderColor(props.color, props.theme),
}));

const Ring = ({ color, size }) => {
  return <StyledRing color={color} size={size} />;
};

Ring.propTypes = {
  color: PropTypes.oneOf(Object.values(RING_COLOR)),
  size: PropTypes.oneOf(Object.values(RING_SIZE)),
};

Ring.defaultProps = {
  size: RING_SIZE.SMALL,
};

export default Ring;
