import PropTypes from 'prop-types';
import Stack from 'common/components/Stacks/Stack';
import Text from 'common/componentMapper/Text';
import { useState } from 'react';
import { useFormApi } from 'lib-node-amb-renderer';
import {
  AI_RESPONSE_DEFAULT,
  CONTENT_TYPE,
  TITLE_ICON_TYPE,
} from './constants';
import { CONFIDENCE_LEVEL, CONFIDENCE_LEVEL_ICON_COLORS } from './constants';
import {
  StyledContainerBox,
  StyledInnerContainerBox,
  StyledFormContainerBox,
  StyledLink,
  StyledText,
  StyledFooter,
  StyledIconBox,
  StyledAnswerBox,
  StyledComponentBox,
} from './Styled';
import QuestionBoxSmartQuestion from './SmartQuestion.js';
import Button from 'common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { ChildrenWidget } from 'lib-node-amb-renderer';
import ExtractionDataIcon from '../QuestionExtraction/components/ExtractionDataIcon';

export class QuestionBoxClass extends ChildrenWidget {
  aiResponse;
  name;
  iconType;
  contentType;
  questionBoxId;
  showBorderTop;
  showBorderBottom;

  constructor(props) {
    super(props);
    this.iconType = props.iconType;
    this.questionBoxId = props.questionBoxId;
    this.name = props.name;
    this.contentType = props.contentType;
    this.aiResponse = props.aiResponse;
    this.showBorderTop = props.showBorderTop;
    this.showBorderBottom = props.showBorderBottom;
  }

  execute(data, props) {
    const n = this.children.find(({ name }) => !!name)?.name || '';
    this.aiResponse = data.questionAnswers[n]?.aiResponse
      ? data.questionAnswers[n].aiResponse
      : AI_RESPONSE_DEFAULT;

    return super.execute(data, props);
  }
}

export default function QuestionBox({
  sx,
  children,
  questionBoxId,
  fields = [],
  contentType = '',
  iconType,
  aiResponse = {
    confidenceLevel: null,
    confidence: null,
    answer: '',
    snippets: [],
    detailedAnswer: '',
  },
  questionName = '',
  showBorderTop = true,
  showBorderBottom = false,
}) {
  const { renderForm } = useFormApi();
  const [{ overflow, whiteSpace, showMore }, setDetailedAnswerStyle] = useState(
    {
      overflow: false,
      whiteSpace: false,
      showMore: false,
    },
  );
  const [showBOLTAnswer, setShowBOLTAnswer] = useState(false);

  const detailedAnswer = aiResponse?.detailedAnswer ?? '';
  const answer = aiResponse?.answer ?? '';
  const confidenceLevel = aiResponse?.confidenceLevel ?? 0;
  const snippets = aiResponse?.snippets ?? [];
  const iconTypeINT = parseInt(iconType);
  const hasEllipsis = detailedAnswer.split('').length > 50 ?? false;

  const isIncomeDocument = contentType === CONTENT_TYPE.INCOME;
  const isTitleCommitment = contentType === CONTENT_TYPE.TITLE;
  const isCPL = contentType === CONTENT_TYPE.CPL;
  const isWire = contentType === CONTENT_TYPE.WIRE;
  const isTitleOrWireOrIncome = isIncomeDocument || isTitleCommitment || isWire;
  const displayConfidenceFooter =
    isTitleOrWireOrIncome &&
    detailedAnswer &&
    Number.isInteger(confidenceLevel) &&
    iconTypeINT === TITLE_ICON_TYPE.BOLT;
  const displayIcon =
    !!iconType && iconTypeINT !== 0 && (isTitleOrWireOrIncome || isCPL);
  const displayBOLTAnswerFooter =
    !!iconType &&
    (isTitleCommitment || isWire || isCPL) &&
    (iconTypeINT === TITLE_ICON_TYPE.NEW ||
      iconTypeINT === TITLE_ICON_TYPE.USER);

  const handleDetailedAnswerStyles = () => {
    setDetailedAnswerStyle((prev) => {
      return {
        ...prev,
        overflow: !prev.overflow,
        whiteSpace: !prev.whiteSpace,
        showMore: !prev.showMore,
      };
    });
  };

  const handleBOLTAnswerShowHide = () => {
    setShowBOLTAnswer((prev) => !prev);
  };

  const handleConfidenceLevelColor = (confidenceLevel) => {
    switch (+confidenceLevel) {
      case CONFIDENCE_LEVEL.GREEN:
        return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
      case CONFIDENCE_LEVEL.RED:
        return CONFIDENCE_LEVEL_ICON_COLORS.RED;
      case CONFIDENCE_LEVEL.YELLOW:
        return CONFIDENCE_LEVEL_ICON_COLORS.YELLOW;
      default:
        return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
    }
  };

  const [input, ...restFields] = fields;
  const hasSnippets = !!snippets.length;
  const guid = input?.name ?? '';

  const handlePDFOnFocus = () => {
    window.postMessage({ type: 'pdfCitation', payload: snippets });
  };

  return (
    <StyledContainerBox
      {...sx}
      showBorderTop={showBorderTop}
      showBorderBottom={showBorderBottom}
    >
      {isTitleOrWireOrIncome && (
        <StyledInnerContainerBox>
          <StyledFormContainerBox>
            <StyledComponentBox>
              <StyledFormContainerBox>
                {children ? children : renderForm([input])}
              </StyledFormContainerBox>
            </StyledComponentBox>
            {displayIcon && (
              <ExtractionDataIcon
                sx={{
                  display: 'grid',
                  placeItems: 'center',
                  backgroundColor: 'blue.600',
                  color: 'white',
                  borderRadius: '.3rem',
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                }}
                iconType={iconType}
              />
            )}
          </StyledFormContainerBox>
          {displayConfidenceFooter && (
            <StyledFooter>
              <StyledAnswerBox>
                <StyledIconBox
                  onClick={handleDetailedAnswerStyles}
                  minWidth={'40px'}
                  data-tracking="show-context"
                  paddingTop="0"
                  paddingRight=".5rem"
                >
                  <ExtractionDataIcon
                    sx={{ fontSize: 'clamp(1.5rem, 1.5rem, 2rem)' }}
                    iconType={TITLE_ICON_TYPE.GAUGE}
                    color={handleConfidenceLevelColor(confidenceLevel)}
                  />
                </StyledIconBox>

                <StyledText overflow={overflow} whiteSpace={whiteSpace}>
                  {detailedAnswer}
                </StyledText>
                {hasEllipsis && (
                  <StyledLink
                    onClick={handleDetailedAnswerStyles}
                    minWidth={'100px'}
                    data-tracking={`showContext-${guid}${
                      questionName ? `-${questionName}` : ''
                    }`}
                  >
                    {showMore ? 'Show Less' : 'Show More'}
                  </StyledLink>
                )}
              </StyledAnswerBox>
            </StyledFooter>
          )}
          {displayBOLTAnswerFooter && (
            <StyledFooter>
              <Stack direction="column" paddingTop="1rem">
                {showBOLTAnswer && (
                  <Stack direction="row">
                    <StyledIconBox
                      paddingTop="0"
                      paddingRight=".5rem"
                      minWidth="4%"
                    >
                      <ExtractionDataIcon
                        sx={{ fontSize: '1.5rem' }}
                        iconType={TITLE_ICON_TYPE.GAUGE}
                        color={handleConfidenceLevelColor(confidenceLevel)}
                      />
                    </StyledIconBox>
                    <Stack direction="column" width="90%">
                      <StyledText>BOLT Answer: {answer}</StyledText>
                      <Text
                        sx={{
                          fontStyle: 'italic',
                          color: 'gray.500',
                          padding: '0.3rem 0',
                        }}
                      >
                        {detailedAnswer}
                      </Text>
                    </Stack>
                  </Stack>
                )}
                <StyledLink
                  minWidth={'30%'}
                  textAlign="left"
                  placeItems="left"
                  onClick={handleBOLTAnswerShowHide}
                  data-tracking="show-bolt-answer"
                >
                  {showBOLTAnswer ? 'Hide BOLT Answer' : 'Show BOLT Answer'}
                </StyledLink>
              </Stack>
            </StyledFooter>
          )}
          {hasSnippets && (
            <Button
              mt="1rem"
              isPrimary={false}
              onClick={() => handlePDFOnFocus()}
            >
              <FontAwesomeIcon icon={faCamera} />
              <Text ml=".5rem" as="span">
                Snapshot
              </Text>
            </Button>
          )}
          {!!restFields?.length && (
            <StyledFooter>{renderForm(restFields)}</StyledFooter>
          )}
        </StyledInnerContainerBox>
      )}
      {isCPL && (
        <StyledInnerContainerBox>
          {fields.map((field, i) => {
            if (field.name === questionBoxId) {
              return (
                <QuestionBoxSmartQuestion
                  key={i}
                  questionBoxId={questionBoxId}
                  input={field}
                  detailedAnswer={detailedAnswer}
                  answer={answer}
                  confidenceLevel={confidenceLevel}
                  renderForm={renderForm}
                  iconType={iconType}
                  snippets={snippets}
                  handlePDFOnFocus={handlePDFOnFocus}
                  displayFlags={{
                    displayIcon,
                    displayConfidenceFooter,
                    displayBOLTAnswerFooter,
                  }}
                />
              );
            }
            if (field.name !== questionBoxId) {
              return <StyledFooter key={i}>{renderForm([field])}</StyledFooter>;
            }
          })}
        </StyledInnerContainerBox>
      )}
    </StyledContainerBox>
  );
}

QuestionBox.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
  fields: PropTypes.array,
  id: PropTypes.string,
  questionBoxId: PropTypes.string,
  confidence: PropTypes.any,
  detailedAnswer: PropTypes.string,
  iconType: PropTypes.string,
  contentType: PropTypes.oneOf(['Title', 'CPL']),
  name: PropTypes.string,
  aiResponse: PropTypes.shape({
    answer: PropTypes.string,
    detailedAnswer: PropTypes.string,
    confidence: PropTypes.number,
    confidenceLevel: PropTypes.number,
    snippets: PropTypes.array,
  }),
  questionName: PropTypes.string,
  showBorderBottom: PropTypes.bool,
  showBorderTop: PropTypes.bool,
};
