import { Image as ChakraImage } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Image = ({ ...props }) => {
  return <ChakraImage {...props} />;
};

export default Image;

Image.propTypes = {
  props: PropTypes.object,
};
