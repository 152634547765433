import { AlertIcon as ChakraAlertIcon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AlertIcon = ({ ...props }) => {
  return <ChakraAlertIcon {...props} />;
};

export default AlertIcon;

AlertIcon.propTypes = {
  props: PropTypes.object,
};
