const DRIVER_LICENSE_REVIEW_TYPES = {
  GET_DRIVER_LICENSE_VALIDATION_ERROR: 'GET_DRIVER_LICENSE_VALIDATION_ERROR',
  GET_DRIVER_LICENSE_VALIDATION_STARTED:
    'GET_DRIVER_LICENSE_VALIDATION_STARTED',
  GET_DRIVER_LICENSE_VALIDATION_SUCCESS:
    'GET_DRIVER_LICENSE_VALIDATION_SUCCESS',
  SAVE_DRIVER_LICENSE_VALIDATION_ERROR: 'SAVE_DRIVER_LICENSE_VALIDATION_ERROR',
  SAVE_DRIVER_LICENSE_VALIDATION_STARTED:
    'SAVE_DRIVER_LICENSE_VALIDATION_STARTED',
  SAVE_DRIVER_LICENSE_VALIDATION_SUCCESS:
    'SAVE_DRIVER_LICENSE_VALIDATION_SUCCESS',
  GET_DRIVER_LICENSE_SNIPPET_STARTED: 'GET_DRIVER_LICENSE_SNIPPET_STARTED',
  GET_DRIVER_LICENSE_SNIPPET_SUCCESS: 'GET_DRIVER_LICENSE_SNIPPET_SUCCESS',
  GET_DRIVER_LICENSE_SNIPPET_ERROR: 'GET_DRIVER_LICENSE_SNIPPET_ERROR',
  GET_SELECTED_LICENSE_SNIPPET_STARTED: 'GET_SELECTED_LICENSE_SNIPPET_STARTED',
  GET_SELECTED_LICENSE_SNIPPET_SUCCESS: 'GET_SELECTED_LICENSE_SNIPPET_SUCCESS',
  GET_SELECTED_LICENSE_SNIPPET_ERROR: 'GET_SELECTED_LICENSE_SNIPPET_ERROR',
  RESET_SELECTED_LICENSE_SNIPPET: 'RESET_SELECTED_LICENSE_SNIPPET',
  GET_DRIVER_LICENSE_RESULTS_STARTED: 'GET_DRIVER_LICENSE_RESULTS_STARTED',
  GET_DRIVER_LICENSE_RESULTS_SUCCESS: 'GET_DRIVER_LICENSE_RESULTS_SUCCESS',
  GET_DRIVER_LICENSE_RESULTS_ERROR: 'GET_DRIVER_LICENSE_RESULTS_ERROR',
  POST_DRIVER_LICENSE_QUESTION_ANSWERS_STARTED:
    'POST_DRIVER_LICENSE_QUESTION_ANSWERS_STARTED',
  POST_DRIVER_LICENSE_QUESTION_ANSWERS_SUCCESS:
    'POST_DRIVER_LICENSE_QUESTION_ANSWERS_SUCCESS',
  POST_DRIVER_LICENSE_QUESTION_ANSWERS_ERROR:
    'POST_DRIVER_LICENSE_QUESTION_ANSWERS_ERROR',
};

export default DRIVER_LICENSE_REVIEW_TYPES;
