import Grid from 'common/components/Grids/Grid';
import Stack from 'common/components/Stacks/Stack';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'common/util/format';
import { AUS_METHOD_ID } from '../../RentalIncomeCalculation/constants';
import { PROPERTY_TYPE } from '../../constants';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';

const PageHeader = ({ propertyInfo }) => {
  return (
    <Grid
      templateColumns="1fr 1fr"
      p={4}
      pl={8}
      pr={8}
      fontSize="sm"
      fontWeight={500}
      height="72px"
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <Box>
        <Stack direction="row">
          <Text
            data-testid="aus-method-label"
            fontSize="1rem"
            fontWeight="500"
            h={6}
          >
            AUS Type:
          </Text>
          <Text
            data-testid="aus-method-value"
            fontSize="1rem"
            fontWeight="400"
            h={6}
          >
            {propertyInfo.ausMethod === AUS_METHOD_ID.DESKTOP_UNDERWRITER
              ? 'DU'
              : 'LPA'}
          </Text>
        </Stack>
        <Stack direction="row">
          <Text
            data-testid="pitia-amount-label"
            fontSize="1rem"
            fontWeight="500"
            h={6}
          >
            PITIA:
          </Text>
          <Text
            data-testid="pitia-amount-value"
            fontSize="1rem"
            fontWeight="400"
            h={6}
          >
            {formatCurrency(
              propertyInfo.propertyType === PROPERTY_TYPE.REAL_ESTATE_OWNED
                ? propertyInfo.realEstateOwned?.reopitiaAmount
                : propertyInfo.pitiaAmount,
            )}
          </Text>
        </Stack>
      </Box>
      <Flex justifyContent="flex-end">
        <Form1003PopUpButton />
      </Flex>
    </Grid>
  );
};

PageHeader.propTypes = {
  propertyInfo: PropTypes.shape({
    ausMethod: PropTypes.oneOf(Object.values(AUS_METHOD_ID)),
    pitiaAmount: PropTypes.number,
    propertyType: PropTypes.oneOf(Object.values(PROPERTY_TYPE)),
    realEstateOwned: PropTypes.shape({
      reopitiaAmount: PropTypes.number,
    }),
  }),
};

export default PageHeader;
