import { useState, useEffect } from 'react';
import Box from 'common/components/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import GlobalLayout from 'common/components/GlobalLayout';
import print from 'common/util/print';
import { RULE_ID } from 'common/constants';
import { ButtonLabels } from 'pages/IncomeCalculator/constants';
import { AuditHistoryTypes } from 'pages/AuditHistory/constants';
import { HomeActions } from 'pages/Home/redux';
import { useMemo } from 'react';
import PrintInfoHeader from 'common/components/PrintInfoHeader';
import { getTitleFormState } from 'pages/TitlePackageReview/redux/actions';
import { TITLE_WALK_ME_PRIMARY_BUTTON_ID } from 'pages/TitlePackageReview/utils/constants';
import { ASSETS_WALK_ME_PRIMARY_BUTTON_ID } from 'pages/Assets/util/constants';
import IncomeHeaderWithCalculationHistory from 'pages/IncomeCalculator/Header/IncomeHeaderWithCalculationHistory.js';
import useFullPageWithPDFViewer from 'common/hooks/useFullPageWithPDFViewer';
import RentalPageHeader from 'pages/RentalIncome/common/PageHeader';
import { IncomeRuleIds, NetProceeds } from 'pages/IncomeCalculator/enums';
import { RENTAL_INCOME } from 'common/constants/api-ids.js';
import AssetSummaryHeader from 'pages/Assets/AssetSummary/AssetSummaryHeader';
import { CATEGORY_IDS } from 'pages/Home/constants';
import useErrorScroll from 'common/hooks/useErrorScroll';

export default function FormTemplate({
  schema,
  formFields,
  shouldWaitForSignalR,
  isLoading,
  error,
  isPrimaryButtonDisabled,
  isPrimaryButtonForceDisabled,
  checkAllFieldsFalsy,
  toastMessage,
  isPrintButtonVisible = false,
  printBoxId,
  throughDates,
  shouldAutoCheck = false,
  showCalculationHistory = false,
  primaryButtonId,
  useBareContent = false,
  customHeader = null,
}) {
  const [primaryButtonIdName, setPrimaryButtonIdName] = useState(
    primaryButtonId || '',
  ); //TODO: remove when Title WalkMe survey is no longer needed
  const dispatch = useDispatch();
  const { getState, handleSubmit } = useFormApi();
  const { submitting, valid, values, hasValidationErrors } = getState();
  const scrollToFirstErrorMessage = useErrorScroll(hasValidationErrors);

  const {
    ruleCategoryName,
    displayTitle,
    employerName,
    startDate,
    ausMethod,
    loanNumber,
    borrowerName,
    ruleId,
    obfuscatedLoanIdentifier,
    rentalIncomeData,
    ruleSubCategoryId,
    assetsCalculationInformation,
    isDocumentProvided,
    categories,
    ruleReferenceId,
    assetType,
  } = useSelector(
    ({
      home: {
        loanSummary,
        selectedAlert: {
          ruleCategoryName,
          displayTitle,
          ruleId,
          ruleSubCategoryId,
          ruleReferenceId,
        },
        navigationItems: { categories },
      },
      incomeCalculator,
      rentalIncomeData,
      url: { obfuscatedLoanIdentifier },
      assets,
    }) => {
      // FIXME: Soon these data points will come from /sdui/data, so this code is temporary.
      const { loanNumber } = loanSummary?.value || {};
      const ausMethod = loanSummary?.value?.ausMethod ?? '';
      const {
        selectedIncome,
        customerEmploymentDetails: { data: employmentData },
      } = incomeCalculator || { customerEmploymentDetails: {} };
      const borrowerName = selectedIncome?.getBorrowerFullName();
      const employerName = employmentData?.employerName;
      const startDate = employmentData?.startDate;

      return {
        ruleCategoryName,
        displayTitle,
        employerName,
        startDate,
        ausMethod,
        loanNumber,
        borrowerName,
        ruleId,
        obfuscatedLoanIdentifier,
        rentalIncomeData,
        ruleSubCategoryId,
        assetsCalculationInformation:
          assets?.data?.assetsCalculationInformation,
        isDocumentProvided: assets?.isDocumentProvided,
        categories,
        ruleReferenceId,
        assetType: assets.assetType,
      };
    },
  );

  //TODO: remove once GlobalLayout has been refactored and SDUI has been implemented for selected page
  const {
    isTitleCommitmentRuleId,
    isTitlePackageReviewRuleId,
    isFullPageWithPDFViewer,
  } = useFullPageWithPDFViewer();

  const [isPrintMode, setPrintMode] = useState(false);
  const [throughDate, setThroughDate] = useState(null);
  const printPage = print(`#${printBoxId}`, schema?.title ?? displayTitle);
  const [auditHistoryParams, setAuditHistoryParams] = useState(null);
  const isNewCreditInquiry =
    ruleId === RULE_ID.NEW_DEBT_ON_CREDIT_INQUIRY_LETTER_RULE;

  const isLastAssetTypeCategory = (categories) => {
    const allAssetCategories = categories.find(
      (category) => category?.navigationCategoryId === CATEGORY_IDS.ASSETS,
    );
    const assetNavigationGutterItems =
      allAssetCategories?.navigationGutterItems?.filter(
        (assetTypeCategory) =>
          assetTypeCategory?.ruleId === RULE_ID.ASSET_TYPE_RULE_ID,
      );

    if (!assetNavigationGutterItems) {
      return false;
    }

    const lastAssetCategory =
      assetNavigationGutterItems[assetNavigationGutterItems?.length - 1];
    return (
      ruleReferenceId === lastAssetCategory?.ruleReferenceId &&
      RULE_ID.ASSET_TYPE_RULE_ID === lastAssetCategory?.ruleId
    );
  };

  useEffect(() => {
    if (Object.hasOwn(values, 'calculationId')) {
      setThroughDate(
        throughDates?.find((td) => td.calculationId == values.calculationId)
          ?.throughDate,
      );
    }
  }, [throughDates, values, values?.calculationId]);

  useEffect(() => {
    if (isPrintMode) {
      printPage();
      setPrintMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintMode]);

  useEffect(() => {
    if (isNewCreditInquiry) {
      setAuditHistoryParams({
        auditHistoryTypes: [AuditHistoryTypes.EsignCreditInquiry],
        obfuscatedLoanIdentifier: obfuscatedLoanIdentifier,
        ruleReferenceIdentifiers: [],
        questionCategory: 'CreditInquiry',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //INFO: needed to remove WalkMe survey when pages for Title have validation errors
  //TODO: remove when Title WalkMe survey is no longer needed
  useEffect(() => {
    if (isTitleCommitmentRuleId) {
      hasValidationErrors
        ? setPrimaryButtonIdName('')
        : setPrimaryButtonIdName(TITLE_WALK_ME_PRIMARY_BUTTON_ID);
    } else if (isLastAssetTypeCategory(categories)) {
      setPrimaryButtonIdName(ASSETS_WALK_ME_PRIMARY_BUTTON_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidationErrors, isTitleCommitmentRuleId]);

  const auditHistoryDataObj = {
    selectedIds: ['CreditInquiry'],
    questionIds: Array.from({ length: 9999 }, (_, i) => i),
    showButton: true,
    auditHistoryParams,
  };

  const isIncome = ruleCategoryName?.toLowerCase().includes('income') || false;
  const title = schema?.title ?? displayTitle;
  const isAssetType = ruleId === RULE_ID.ASSET_TYPE_RULE_ID;
  const isNoDocProvided =
    ruleId === IncomeRuleIds.NoDocumentationProvidedIncomeSummary;
  const isRentalIncome = ruleSubCategoryId === RENTAL_INCOME;
  const headerToDisplay = setHeaderToDisplay({
    customHeader,
    useBareContent,
    income: {
      isIncome,
      isNoDocProvided,
      isRentalIncome,
      rentalIncomeData,
      showCalculationHistory,
    },
    asset: {
      isAssetType,
      isDocumentProvided,
      assetsCalculationInformation,
      assetType,
    },
  });

  const globalLayoutProps = {
    heading: headerToDisplay || isAssetType ? null : title,
    customHeader: headerToDisplay,
    auditHistoryData: isNewCreditInquiry ? auditHistoryDataObj : undefined,
  };

  const areAllFieldValuesFalsy = useMemo(() => {
    return Object.values(values).every((value) => !value);
  }, [values]);

  const isPrimaryBtnDisableAllFieldsFalsy =
    areAllFieldValuesFalsy && checkAllFieldsFalsy;

  return (
    <GlobalLayout
      {...globalLayoutProps}
      waitForSignalR={shouldWaitForSignalR}
      isLoading={isLoading}
      shouldAutoCheck={shouldAutoCheck}
      error={error}
      primaryButton={{
        id: primaryButtonIdName,
        isSubmitting: submitting,
        isDisabled:
          submitting ||
          (hasValidationErrors && isPrimaryButtonDisabled) ||
          isPrimaryButtonForceDisabled ||
          isPrimaryBtnDisableAllFieldsFalsy,
        onSubmit: async () => {
          //formState & dispatch used to throw validation error only in Title pages, consumed in useSaveTitleCommitmentAnswers hook
          if (isTitlePackageReviewRuleId) {
            dispatch(
              getTitleFormState({
                valid,
                values,
                hasValidationErrors,
              }),
            );
          }

          scrollToFirstErrorMessage();

          let res;
          try {
            res = await handleSubmit(values);
            if (res === undefined) {
              throw new Error('Response is undefined');
            }
            // Handle the response if needed
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error during form submission:', error);
          }

          if (!res && valid) {
            dispatch(HomeActions.setIsToastOpen(true, toastMessage));
          }

          return !!res;
        },
      }}
      secondaryButton={
        isPrintButtonVisible && {
          name: ButtonLabels.print,
          onSubmit: () => {
            setPrintMode(true);
          },
        }
      }
    >
      <Box
        id={printBoxId}
        sx={{
          overflowY: 'visible',
          padding: !isFullPageWithPDFViewer && !useBareContent ? '1rem' : '0',
        }}
      >
        {isIncome && !useBareContent && (
          <PrintInfoHeader
            rows={2}
            columns={3}
            loanNumber={loanNumber}
            borrowerName={borrowerName}
            ausMethod={ausMethod}
            employerName={employerName}
            startDate={startDate}
            throughDate={throughDate}
          />
        )}
        {formFields}
      </Box>
    </GlobalLayout>
  );
}

FormTemplate.propTypes = {
  schema: PropTypes.object,
  formFields: PropTypes.any,
  shouldWaitForSignalR: PropTypes.bool,
  isLoading: PropTypes.bool,
  /** Disables the primary button if this value is true and there are form validation errors */
  isPrimaryButtonDisabled: PropTypes.bool,
  /** Disable the primary button if this value is true regardless of form validation errors */
  isPrimaryButtonForceDisabled: PropTypes.bool,
  error: PropTypes.string,
  checkAllFieldsFalsy: PropTypes.bool,
  toastMessage: PropTypes.shape({ description: PropTypes.string }),
  isPrintButtonVisible: PropTypes.bool,
  printBoxId: PropTypes.string,
  throughDates: PropTypes.any,
  shouldAutoCheck: PropTypes.bool,
  showCalculationHistory: PropTypes.bool,
  primaryButtonId: PropTypes.string,
  /* Get rid of default header and all margins in content box */
  useBareContent: PropTypes.bool,
  customHeader: PropTypes.node,
};

function setHeaderToDisplay({ customHeader, useBareContent, income, asset }) {
  // TODO: refactor && write tests
  if (useBareContent) {
    return null;
  }
  if (customHeader) {
    return customHeader;
  }
  if (income.isIncome) {
    if (income.isNoDocProvided && income.isRentalIncome) {
      return <RentalPageHeader propertyInfo={income.rentalIncomeData.data} />;
    } else {
      return (
        <IncomeHeaderWithCalculationHistory
          showCalculationHistory={income.showCalculationHistory}
        />
      );
    }
  } else if (
    asset.isAssetType &&
    !asset.isDocumentProvided &&
    asset.assetType !== NetProceeds
  ) {
    return (
      <AssetSummaryHeader
        assetSummaryData={asset.assetsCalculationInformation}
      />
    );
  }
}
