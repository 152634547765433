import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { faMemoCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { faBolt, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { categories as summaryCategories } from '../../SummaryWrapper/SummaryWrapper';
import { RAIL_ITEM_NAMES } from '../constants';

import useLeftDrawerContext from '../../LeftDrawer/context/useLeftDrawerContext';
import RailItems from './RailItems';
import { Rail, RailNavContainer } from './Styled';
import useRailRouteMatches from '../hooks/useRailRouteMatches';
import { isReadOnlyUrl } from 'common/util/appState';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { getDocumentTypeAssociations } from 'pages/ClassifyDocumentsV2/redux/thunks';
import { HomeActions, HomeThunks } from 'pages/Home/redux';

export default function RailNav() {
  const { obfuscatedLoanRecordId, obfuscatedLenderDatabaseId } = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  const featureFlags = useSelector(({ featureFlags }) => featureFlags);
  const isUnderwriter = useSelector(({ home }) => home?.user?.isUnderwriter);
  const { loanSummaryUpsertOrDelete } = useSelector(
    ({ loanSummaryUpsertOrDelete }) => loanSummaryUpsertOrDelete,
  );
  const dispatch = useDispatch();

  const { isBOLTRouteMatch, isKnowYourLoanMatch, is1003Match } =
    useRailRouteMatches();

  const {
    handleNavigationVisibilityForLinkTiles,
    isNavigationVisible,
    isLeftDrawerCollapsed,
    closeLeftDrawer,
  } = useLeftDrawerContext();

  const baseRoute = `/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}`;

  const defaultRoute =
    summaryCategories.find(({ flagName }) => featureFlags[flagName])?.to || '';

  const { BOLT, TEN_ZERO_THREE, KNOW_YOUR_LOAN } = RAIL_ITEM_NAMES;

  const handleOutsideBoltUpdates = () => {
    if (loanSummaryUpsertOrDelete) {
      dispatch(HomeActions.setSelectedAlert(null));
      dispatch(getDocumentTypeAssociations());
      dispatch(HomeThunks.getNavigationDetails());
      dispatch(setLoanSummaryUpsertOrDelete(false));
    }
  };

  const handleBoltClick = () => {
    handleOutsideBoltUpdates();
    handleNavigationVisibilityForLinkTiles();
  };

  let railItems = [
    {
      to: baseRoute,
      label: BOLT.DEFAULT,
      title: BOLT.SHORT,
      icon: faBolt,
      active: isBOLTRouteMatch,
      onClick: () => handleBoltClick(),
    },
  ];

  if (featureFlags.showSummaryTile) {
    railItems = [
      ...railItems,
      {
        to: `${baseRoute}/summary${defaultRoute}`,
        label: TEN_ZERO_THREE.DEFAULT,
        title: TEN_ZERO_THREE.SHORT,
        icon: faMemoCircleInfo,
        active: is1003Match,
        onClick: () => closeLeftDrawer(),
      },
    ];
  }

  if (isUnderwriter) {
    railItems = [
      ...railItems,
      {
        to: `${baseRoute}/know-your-loan/primary`,
        label: KNOW_YOUR_LOAN.SHORT,
        title: KNOW_YOUR_LOAN.DEFAULT,
        icon: faPuzzlePiece,
        active: isKnowYourLoanMatch,
      },
    ];
  }

  return (
    !isReadOnlyUrl() && (
      <RailNavContainer
        aria-label="rail navigation"
        isNavigationVisible={isNavigationVisible}
        isLeftDrawerCollapsed={isLeftDrawerCollapsed}
      >
        <Rail>
          <RailItems railItems={railItems} />
        </Rail>
      </RailNavContainer>
    )
  );
}
RailNav.propTypes = {
  summaryMatch: PropTypes.bool,
};
