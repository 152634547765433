﻿export default class IncomeSummaryMessageDataModel {
  constructor(data = {}) {
    this.messageType = Object.hasOwnProperty.call(data, 'messageType')
      ? data.messageType
      : 0;

    this.message = Object.hasOwnProperty.call(data, 'message')
      ? data.message
      : '';
  }

  getStatus() {
    switch (this.messageType) {
      case 1:
        return 'error';
      default:
        return 'info';
    }
  }

  getDescription() {
    return this.message;
  }
}
