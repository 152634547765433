import PropTypes from 'prop-types';
import { StyledLeftDrawerButton, StyledLeftDrawerIcon } from './Styled';
import { faAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import useLeftDrawerContext from '../context/useLeftDrawerContext';

const LeftDrawerButton = ({ is1003Match }) => {
  const { boltRedesignV2 } = useSelector(({ featureFlags }) => featureFlags);
  const {
    isLeftDrawerCollapsed,
    isNavigationVisible,
    handleLeftDrawerCollapseForDrawerBtn,
  } = useLeftDrawerContext();

  return (
    <StyledLeftDrawerButton
      id="leftDrawerBtn"
      as="button"
      aria-controls="leftDrawerBtn"
      aria-expanded={!isLeftDrawerCollapsed}
      aria-label="Toggle left drawer"
      isNavigationVisible={isNavigationVisible}
      onClick={() =>
        handleLeftDrawerCollapseForDrawerBtn(isLeftDrawerCollapsed)
      }
      isLeftDrawerCollapsed={isLeftDrawerCollapsed}
      featureFlag={boltRedesignV2}
      is1003Match={is1003Match}
    >
      <StyledLeftDrawerIcon
        icon={faAngleDoubleLeft}
        selected={isLeftDrawerCollapsed}
        isLeftDrawerCollapsed={isLeftDrawerCollapsed}
        isNavigationVisible={isNavigationVisible}
      />
    </StyledLeftDrawerButton>
  );
};

export default LeftDrawerButton;

LeftDrawerButton.propTypes = {
  is1003Match: PropTypes.bool,
};
