import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const IconButton = ({ children, ...props }) => {
  return <ChakraIconButton {...props}>{children}</ChakraIconButton>;
};

export default IconButton;

IconButton.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
