export default class CreditResultsData {
  constructor(cr = undefined) {
    this.dataLoaded = cr !== undefined;
    this.results = cr && cr.results ? cr.results : [];
  }

  isDataLoaded() {
    return this.dataLoaded;
  }

  getResults() {
    return this.results;
  }

  getResultsByRule(ruleId, customerRecordId) {
    const childRules = this.results.filter(
      ({ ruleMetadata, borrowerIdentifier }) => {
        if (borrowerIdentifier?.customerRecordId) {
          return (
            ruleMetadata.parentRuleId === ruleId &&
            customerRecordId === parseInt(borrowerIdentifier?.customerRecordId)
          );
        }
        return ruleMetadata.parentRuleId === ruleId;
      },
    );

    return childRules.length > 0
      ? childRules
      : this.results.filter(({ ruleMetadata }) => {
          return ruleMetadata.ruleId === ruleId;
        });
  }
}
