import TYPES from './types';
import { API_STATUS } from 'common/constants';
import { combineReducers } from 'redux/es/redux.mjs';

export const onFirstRender = (state = true, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ON_FIRST_RENDER:
      return payload;
    default:
      return state;
  }
};

export const selectedPDF = (state = null, { type, payload }) => {
  switch (type) {
    case TYPES.SET_SELECTED_PDF:
      return payload;
    default:
      return state;
  }
};

export const selectedDocument = (state = null, { type, payload }) => {
  switch (type) {
    case TYPES.SET_SELECTED_DOCUMENT:
      return payload;
    default:
      return state;
  }
};

export const selectedDocumentAssociations = (
  state = null,
  { type, payload },
) => {
  switch (type) {
    case TYPES.SET_SELECTED_DOCUMENT_ASSOCIATIONS:
      return payload;
    default:
      return state;
  }
};

export const actionRequired = (state = null, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ACTION_REQUIRED:
      return payload;
    default:
      return state;
  }
};

export const quickMatchDocumentAssociation = (
  state = { status: null, error: null, data: {} },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_SUCCESS:
    case TYPES.SET_QUICK_MATCH_DOCUMENT_ASSOCIATION:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const featureFlagStatus = (
  state = {
    status: null,
    error: null,
    data: {},
  },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_V2_FEATURE_FLAG_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const adrCompletionStatus = (
  state = {
    status: null,
    error: null,
    data: {},
  },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_ADR_COMPLETION_STATUS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_ADR_COMPLETION_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_ADR_COMPLETION_STATUS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const documentItems = (
  state = {
    status: null,
    error: null,
    data: [],
  },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_CLASSIFY_DOCUMENTS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_CLASSIFY_DOCUMENTS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const documentTypesAndCategories = (
  state = {
    status: null,
    error: null,
    data: [],
  },
  { type, payload, error },
) => {
  switch (type) {
    case TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: payload,
      };
    case TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const documentPDFList = (
  state = {
    status: null,
    error: null,
    data: [],
  },
  { type, payload, error },
) => {
  switch (type) {
    /* Reset error state(if any) when user clicks on a different document
     * that is already saved in redux store. This will unmount the error
     * page and the render the document in the component
     */
    case TYPES.SET_SELECTED_PDF:
      return {
        ...state,
        error: null,
        status: null,
      };
    case TYPES.GET_PDF_DOCUMENT_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_PDF_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: [...state.data, payload],
      };
    case TYPES.GET_PDF_DOCUMENT_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const documentAssociationsList = (
  state = {
    status: null,
    error: null,
    data: [],
  },
  { type, payload, error },
) => {
  switch (type) {
    /* Reset error state(if any) when the associations are set from
     * redux cache
     */
    case TYPES.SET_SELECTED_DOCUMENT_ASSOCIATIONS:
      return {
        ...state,
        status: null,
        error: null,
      };
    case TYPES.GET_DOCUMENT_ASSOCIATIONS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case TYPES.GET_DOCUMENT_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
        data: [
          ...state.data.filter(
            (item) => item.documentId !== payload?.documentId,
          ),
          payload,
        ],
      };
    case TYPES.GET_DOCUMENT_ASSOCIATIONS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const saveDocumentAssociations = (
  state = {
    status: null,
    error: null,
  },
  { type, error },
) => {
  switch (type) {
    case TYPES.SAVE_DOCUMENT_ASSOCIATIONS_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case TYPES.SAVE_DOCUMENT_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case TYPES.SAVE_DOCUMENT_ASSOCIATIONS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    default:
      return state;
  }
};

export const removeDocuments = (
  state = {
    status: null,
    error: null,
  },
  { type, error },
) => {
  switch (type) {
    case TYPES.SET_HIDE_DOCUMENT_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    case TYPES.SET_HIDE_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.SET_HIDE_DOCUMENT_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error,
      };
    case TYPES.RESET_HIDE_DOCUMENT_INITIAL_STATE:
      return {
        status: null,
        error: null,
      };
    default:
      return state;
  }
};

const classifyReducers = combineReducers({
  onFirstRender,
  selectedPDF,
  actionRequired,
  selectedDocument,
  selectedDocumentAssociations,
  quickMatchDocumentAssociation,
  featureFlagStatus,
  adrCompletionStatus,
  documentItems,
  documentTypesAndCategories,
  documentPDFList,
  documentAssociationsList,
  saveDocumentAssociations,
  removeDocuments,
});
export default classifyReducers;
