import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Options for 'Select Document Category' dropdown
const useDocumentCategoryOptions = () => {
  // local state
  const [documentCategoryOptions, setDocumentCategoryOptions] = useState([]);
  // redux
  const {
    documentTypesAndCategories: { data: documentTypesAndCategories },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  // generate documentCategoryOptions
  useEffect(() => {
    const { categoryOptions } = documentTypesAndCategories.reduce(
      (acc, curr) => {
        if (!acc.allCategories.includes(curr.documentCategory)) {
          acc.allCategories.push(curr.documentCategory);
          acc.categoryOptions.push({
            label: curr.documentCategory,
            value: curr.documentCategory,
          });
        }
        return acc;
      },
      {
        allCategories: [],
        categoryOptions: [],
      },
    );
    setDocumentCategoryOptions(categoryOptions);
  }, [documentTypesAndCategories]);

  return documentCategoryOptions;
};

export default useDocumentCategoryOptions;
