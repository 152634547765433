import { Drawer as ChakraDrawer } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Drawer = ({ children, ...props }) => {
  return <ChakraDrawer {...props}>{children}</ChakraDrawer>;
};

export default Drawer;

Drawer.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
