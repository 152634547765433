import { lazy } from 'react';
import PropTypes from 'prop-types';
// local
import { RULE_ID, SUB_CATEGORY_IDS } from 'common/constants';
import MortgageHistory from 'pages/MortgageHistory';
import { IncomeRuleIds } from '../IncomeCalculator/enums';
import FormTemplateWithAuditHistory from 'common/componentMapper/FormTemplate/FormTemplateWithAuditHistory';

const ContractReviewNoDocs = lazy(() =>
  import('../PurchaseAgreement/ContractReviewNoDocs'),
);
const SmartContractReview = lazy(() =>
  import('../PurchaseAgreement/SmartContractReview'),
);
const TitleCommitment = lazy(() =>
  import('../TitlePackageReview/TitleCommitment'),
);
const SellerAcquisition = lazy(() =>
  import('../TitlePackageReview/SellerAcquisition'),
);
const ClosingProtectionLetter = lazy(() =>
  import('../TitlePackageReview/ClosingProtectionLetter'),
);
const WireInstructions = lazy(() =>
  import('../TitlePackageReview/WireInstructions'),
);
const Borrower1003 = lazy(() => import('../Borrower1003'));
const PurchaseAgreement = lazy(() =>
  import('../PurchaseAgreement/ContractReview/index.js'),
);
const Liabilities = lazy(() => import('../Liabilities'));
const DriverLicense = lazy(() => import('../DriverLicense'));
const EarlyClosingDisclosure = lazy(() => import('../EarlyClosingDisclosure'));
const FinalChecks = lazy(() => import('../FinalReview/FinalChecks'));
const IncomeCalculator = lazy(() => import('../IncomeCalculator'));
const IncomeReconciliation = lazy(() => import('../IncomeReconciliation'));
const RentalIncome = lazy(() => import('../RentalIncome'));
const Reo = lazy(() => import('../REO'));
const ThirdPartyChecks = lazy(() => import('../FinalReview/ThirdPartyChecks'));
const ManualChecks = lazy(() => import('../FinalReview/ManualChecks'));
const SubjectProperty = lazy(() => import('../SubjectProperty'));
const AssetDepositoryAccount = lazy(() =>
  import('../Assets/AssetDepositoryAccounts'),
);
const AssetSummary = lazy(() => import('../Assets/AssetSummary'));
const AssetReconciliation = lazy(() => import('../Assets/AssetReconciliation'));
const DepositoryAccountSummary = lazy(() =>
  import('../Assets/DepositoryAccountSummary'),
);
const FHARequirements = lazy(() =>
  import('../PurchaseAgreement/FHARequirements'),
);
const NonOccupyingCoBorrower = lazy(() => import('../NonOccupyingCoBorrower'));
const Generic = lazy(() => import('../Generic'));

const UWFinalReview = lazy(() =>
  import('../FinalReview/UWFinalReview/UWFinalReview'),
);

const HomeOwnerInsurance = lazy(() =>
  import('../SubjectProperty/Insurance/HomeOwnerInsurance'),
);

const SUB_CATEGORY_COMPONENTS = {
  [SUB_CATEGORY_IDS.ASSETS]: <AssetSummary />,
  [SUB_CATEGORY_IDS.CREDIT]: <Generic />,
  [SUB_CATEGORY_IDS.CREDIT_LIABILITIES]: <Liabilities />,
  [SUB_CATEGORY_IDS.DRIVERS_LICENSE]: <DriverLicense />,
  [SUB_CATEGORY_IDS.DRIVERS_LICENSE_LIABILITIES]: <DriverLicense />,
  [SUB_CATEGORY_IDS.NET_TANGIBLE_BENEFITS]: <Generic />,
  [SUB_CATEGORY_IDS.INCOME_CALCULATOR]: <IncomeCalculator />,
  [SUB_CATEGORY_IDS.BORROWER1003]: <Borrower1003 />,
  [SUB_CATEGORY_IDS.PURCHASE_AGREEMENT]: <PurchaseAgreement />,
  [SUB_CATEGORY_IDS.REO]: <Reo />,
  [SUB_CATEGORY_IDS.THIRD_PARTY_CHECKS_]: <ThirdPartyChecks />,
  [SUB_CATEGORY_IDS.RENTAL_INCOME]: <RentalIncome />,
  [SUB_CATEGORY_IDS.FINAL_CHECKS]: <FinalChecks />,
  [SUB_CATEGORY_IDS.EARLY_CLOSING_DISCLOSURE]: <EarlyClosingDisclosure />,
  [SUB_CATEGORY_IDS.MANUAL_CHECKS]: <ManualChecks />,
  [SUB_CATEGORY_IDS.SUBJECT_PROPERTY]: <SubjectProperty />,
  [SUB_CATEGORY_IDS.TITLE]: <TitleCommitment />,
  [SUB_CATEGORY_IDS.NON_OCCUPYING_CO_BORROWERS]: <NonOccupyingCoBorrower />,
  [SUB_CATEGORY_IDS.FINAL_UNDERWRITE_REVIEW]: <UWFinalReview />,
  [SUB_CATEGORY_IDS.HOMEOWNERS_INSURANCE]: <HomeOwnerInsurance />,
};

export const CategoryToDisplay = ({
  subId,
  ruleId,
  additionalReferences,
  isUnderwriter,
}) => {
  if (
    subId === SUB_CATEGORY_IDS.CREDIT ||
    subId === SUB_CATEGORY_IDS.CREDIT_LIABILITIES
  ) {
    switch (true) {
      case ruleId === RULE_ID.MORTGAGE_HISTORY_PARENT_RULE:
        return <MortgageHistory />;
      case ruleId === RULE_ID.CREDIT_REPORT_MANUAL_REVIEW:
      case ruleId === RULE_ID.LIABILITY_ALL_ALERTS_RULE &&
        additionalReferences?.IsLiabilitiesReviewSduiEnabled === 'True':
        return <Generic />;
      default:
        return subId === SUB_CATEGORY_IDS.CREDIT ? (
          <Generic />
        ) : (
          <Liabilities />
        );
    }
  }

  if (
    subId === SUB_CATEGORY_IDS.PURCHASE_AGREEMENT ||
    subId === SUB_CATEGORY_IDS.FHA_REQUIREMENTS
  ) {
    switch (ruleId) {
      case RULE_ID.FHA_REQUIREMENTS:
        return <FHARequirements />;
      case RULE_ID.PURCHASE_AGREEMENT_RULE_ID:
        return <SmartContractReview />;
      case RULE_ID.PURCHASE_AGREEMENT_NO_DOCS_RULE_ID:
        return <ContractReviewNoDocs />;
      default:
        return <PurchaseAgreement />;
    }
  }

  if (subId === SUB_CATEGORY_IDS.ASSETS) {
    switch (ruleId) {
      case RULE_ID.ASSET_TYPE_RULE_ID:
        return <AssetDepositoryAccount />;
      case RULE_ID.ASSET_RECONCILIATION_RULE_ID:
        return <AssetReconciliation />;
      default:
        return <DepositoryAccountSummary />;
    }
  }

  if (subId === SUB_CATEGORY_IDS.TITLE) {
    switch (ruleId) {
      case RULE_ID.SELLER_ACQUISITION_RULE:
        return <SellerAcquisition />;
      case RULE_ID.TITLE_COMMITMENT_RULE:
        return <TitleCommitment />;
      case RULE_ID.CPL_REVIEW_RULE:
        return <ClosingProtectionLetter />;
      case RULE_ID.WIRE_INSTRUCTIONS_RULE:
        return <WireInstructions />;
      default:
        return <TitleCommitment />;
    }
  }

  if (subId === SUB_CATEGORY_IDS.HOMEOWNERS_INSURANCE) {
    return <HomeOwnerInsurance />;
  }

  if (subId === SUB_CATEGORY_IDS.NET_TANGIBLE_BENEFITS) {
    const formTemplate = isUnderwriter
      ? FormTemplateWithAuditHistory
      : undefined;

    return <Generic formTemplate={formTemplate} />;
  }

  if (subId === SUB_CATEGORY_IDS.INCOME_CALCULATOR) {
    switch (true) {
      case [
        IncomeRuleIds.ValidateDocument,
        IncomeRuleIds.CalculateIncome,
        IncomeRuleIds.SmartValidateDocument,
      ].includes(ruleId) &&
        additionalReferences?.IsV2Active === 'True' &&
        additionalReferences?.IsSDUI === 'True':
        return <Generic />;
      case ruleId === RULE_ID.INCOME_RECONCILIATION_RULE_ID:
        return <IncomeReconciliation />;
      default:
        return <IncomeCalculator />;
    }
  }
  return SUB_CATEGORY_COMPONENTS[subId];
};

CategoryToDisplay.propTypes = {
  subId: PropTypes.number.isRequired,
  ruleId: PropTypes.number.isRequired,
  additionalReferences: PropTypes.object,
  isUnderwriter: PropTypes.bool,
};
