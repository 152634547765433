import { createBlobUrl } from 'common/util/pdfUtils';

import actions from './actions';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import { getNextDocument, getNextDocumentAndSave } from '../utils/helpers';
import { GENERIC_ERROR_MESSAGE } from 'common/constants';
import { HomeActions, HomeThunks } from 'pages/Home/redux';
import { CLASSIFICATION } from '../constants';

export const getAdrCompletionStatus = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(actions.getAdrCompletionStatusStarted());
  try {
    const { data } = await apiClient.post(
      apiEndPoints.classifyDocuments.POST_ADR_COMPLETION_STATUS_EXISTS,
      { obfuscatedLoanIdentifier },
    );
    dispatch(actions.getAdrCompletionStatusSuccess(data));
  } catch (error) {
    dispatch(actions.getAdrCompletionStatusError(GENERIC_ERROR_MESSAGE));
  }
};

export const getClassifyDocuments =
  (getNextAlert = false) =>
  async (dispatch, getState) => {
    const {
      url: { obfuscatedLoanIdentifier },
      home: {
        user: { isUnderwriter },
      },
    } = getState();
    dispatch(actions.getClassifyDocumentsStarted());
    try {
      const {
        data: { documentItems },
      } = await apiClient.post(
        apiEndPoints.classifyDocuments.GET_CLASSIFY_DOCUMENTS,
        { obfuscatedLoanIdentifier },
      );
      dispatch(actions.getClassifyDocumentsSuccess(documentItems));
      const hasActionNeededItems = documentItems.some(
        (document) =>
          document.groupCategoryName === CLASSIFICATION.actionNeeded,
      );
      if (!hasActionNeededItems && !isUnderwriter && getNextAlert) {
        dispatch(HomeActions.nextAlert());
      }
      dispatch(actions.setActionRequired(hasActionNeededItems));
    } catch (error) {
      dispatch(actions.getClassifyDocumentsError(GENERIC_ERROR_MESSAGE));
    }
  };

export const getDocumentTypeAssociations = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(actions.getDocumentTypeAssociationsStarted());
  try {
    const {
      data: { documentTypesAndCategories },
    } = await apiClient.post(
      apiEndPoints.classifyDocuments.GET_DOCUMENT_TYPE_ASSOCIATIONS_V2,
      { obfuscatedLoanIdentifier },
    );
    dispatch(
      actions.getDocumentTypeAssociationsSuccess(documentTypesAndCategories),
    );
  } catch (error) {
    dispatch(actions.getDocumentTypeAssociationsError(GENERIC_ERROR_MESSAGE));
  }
};

export const getDocumentPdf = (documentId) => async (dispatch, getState) => {
  try {
    const {
      url: {
        obfuscatedLoanIdentifier: {
          obfuscatedLenderDatabaseId,
          obfuscatedLoanRecordId,
        },
      },
      classifyDocumentsV2: {
        documentPDFList: { data: PDFList },
      },
    } = getState();
    dispatch(actions.getDocumentPdfStarted());
    const pdf = PDFList.find((pdf) => pdf.documentId === documentId);
    if (pdf) {
      dispatch(actions.setSelectedPDF(pdf.blobUrl));
    } else {
      const pdfEndpoint = `${apiEndPoints.classifyDocuments.GET_PDF}/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/${documentId}`;
      const { data: pdfBuffer } = await apiClient.get(pdfEndpoint, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      });
      const blobUrl = await createBlobUrl(pdfBuffer);
      dispatch(actions.setSelectedPDF(blobUrl));
      dispatch(actions.getDocumentPdfSuccess({ blobUrl, documentId }));
    }
  } catch (error) {
    dispatch(actions.getDocumentPdfError(GENERIC_ERROR_MESSAGE));
  }
};

export const getDocumentAssociations =
  (documentId, documentTypeId) => async (dispatch, getState) => {
    // Force to be updated with the latest document type associations since Assets could be updated externally
    dispatch(getDocumentTypeAssociations());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    dispatch(actions.getDocumentAssociationsStarted());
    try {
      const { data } = await apiClient.post(
        apiEndPoints.classifyDocuments.GET_DOCUMENT_ASSOCIATIONS_V2,
        { obfuscatedLoanIdentifier, documentId, documentTypeId },
      );
      dispatch(actions.setSelectedDocumentAssociations(data));
      dispatch(actions.getDocumentAssociationsSuccess(data));
    } catch (error) {
      dispatch(actions.getDocumentAssociationsError(GENERIC_ERROR_MESSAGE));
    }
  };

export const saveDocumentClassificationAssociations =
  (
    associationChanged,
    classificationChanged,
    saveDocumentAssociationsPayload,
  ) =>
  async (dispatch, getState) => {
    const currentDocumentId = saveDocumentAssociationsPayload.documentId;
    /**
     * Handles the transition to the next document or triggers an alert if no next document is available.
     *
     * @param {function} dispatch - The dispatch function to send actions to the Redux store.
     * @param {Array} allDocuments - The list of all documents to consider for the next document.
     */
    const handleNextDocumentOrAlert = (dispatch, allDocuments) => {
      const nextDocument = getNextDocument(allDocuments, currentDocumentId);
      if (!nextDocument) {
        dispatch(HomeActions.nextAlert());
      } else {
        // Otherwise, set the next document as the selected document
        dispatch(actions.setSelectedDocument(nextDocument));
        // Fetch and dispatch the document associations for the next document
        dispatch(
          getDocumentAssociations(
            nextDocument.documentId,
            nextDocument.documentTypeId,
          ),
        );
      }
    };
    const {
      url: { obfuscatedLoanIdentifier },
      classifyDocumentsV2: {
        documentItems: { data: allDocuments },
      },
    } = getState();
    try {
      if (associationChanged || classificationChanged) {
        dispatch(actions.saveDocumentAssociationsStarted());
        await apiClient.post(
          apiEndPoints.classifyDocuments.SAVE_DOCUMENT_ASSOCIATION_V2,
          {
            obfuscatedLoanIdentifier,
            ...saveDocumentAssociationsPayload,
          },
        );
        // After successful save, get updated classified documents
        // and updated associations for that document
        dispatch(getClassifyDocuments());
        dispatch(actions.saveDocumentAssociationsSuccess());
        dispatch(HomeThunks.getNavigationDetails());
        dispatch(getQuickMatchDocumentAssociations());
        handleNextDocumentOrAlert(dispatch, allDocuments);
      } else {
        handleNextDocumentOrAlert(dispatch, allDocuments);
      }
    } catch (error) {
      dispatch(actions.saveDocumentAssociationsError(GENERIC_ERROR_MESSAGE));
    }
  };

export const saveDocumentClassificationAssociationsGoNextDoc =
  (
    associationChanged,
    classificationChanged,
    saveDocumentAssociationsPayload,
  ) =>
  async (dispatch, getState) => {
    const currentDocumentId = saveDocumentAssociationsPayload.documentId;
    const handleNextDocumentOrAlert = (dispatch, allDocuments) => {
      const nextDocument = getNextDocumentAndSave(
        allDocuments,
        currentDocumentId,
      );

      if (nextDocument) {
        dispatch(actions.setSelectedDocument(nextDocument));
        dispatch(
          getDocumentAssociations(
            nextDocument.documentId,
            nextDocument.documentTypeId,
          ),
        );
      } else {
        dispatch(HomeActions.nextAlert());
      }
    };
    const {
      url: { obfuscatedLoanIdentifier },
      classifyDocumentsV2: {
        documentItems: { data: allDocuments },
      },
    } = getState();
    try {
      if (associationChanged || classificationChanged) {
        dispatch(actions.saveDocumentAssociationsStarted());
        await apiClient.post(
          apiEndPoints.classifyDocuments.SAVE_DOCUMENT_ASSOCIATION_V2,
          {
            obfuscatedLoanIdentifier,
            ...saveDocumentAssociationsPayload,
          },
        );
        // After successful save, get updated classified documents
        // and updated associations for that document
        dispatch(getClassifyDocuments());
        dispatch(actions.saveDocumentAssociationsSuccess());
        dispatch(HomeThunks.getNavigationDetails());
        dispatch(getQuickMatchDocumentAssociations());
        handleNextDocumentOrAlert(dispatch, allDocuments);
      } else {
        handleNextDocumentOrAlert(dispatch, allDocuments);
      }
    } catch (error) {
      dispatch(actions.saveDocumentAssociationsError(GENERIC_ERROR_MESSAGE));
    }
  };

export const submitHideDocument =
  (documentId, documentTypeId) => async (dispatch, getState) => {
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    dispatch(actions.setHideDocumentStarted());
    try {
      await apiClient.post(apiEndPoints.classifyDocuments.SET_HIDE_DOCUMENT, {
        obfuscatedLoanIdentifier,
        documentId,
        documentTypeId,
      });
      dispatch(actions.setHideDocumentSuccess());
      dispatch(HomeThunks.getNavigationDetails());
    } catch (error) {
      dispatch(actions.setHideDocumentError(GENERIC_ERROR_MESSAGE));
    }
  };

export const getQuickMatchDocumentAssociations =
  () => async (dispatch, getState) => {
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    dispatch(actions.getClassifiedDocumentsForBatchProcessingStarted());

    try {
      const { data } = await apiClient.post(
        apiEndPoints.classifyDocuments
          .GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING,
        { obfuscatedLoanIdentifier },
      );
      dispatch(actions.getClassifiedDocumentsForBatchProcessingSuccess(data));
      dispatch(actions.setQuickMatchDocumentAssociation(data));
    } catch (error) {
      dispatch(
        actions.getClassifiedDocumentsForBatchProcessingError(
          GENERIC_ERROR_MESSAGE,
        ),
      );
    }
  };
