import Radio from 'common/components/Radios/Radio';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import Text from 'common/components/Texts/Text';
import PropTypes from 'prop-types';

const disabledStyles = {
  opacity: 0.6,
};

export const FormBuilderRadioButtons = ({
  name,
  label,
  options,
  value,
  onChangeHandler,
  getValues,
  disabled,
  tabIndex = 0,
}) => (
  <FormControl>
    <FormLabel htmlFor={name}>{label}</FormLabel>

    {options.map((option, i) => {
      return (
        <Radio
          marginLeft={i > 0 ? '1rem' : ''}
          key={i}
          name={name}
          value={option.value}
          disabled={disabled}
          style={disabled ? disabledStyles : {}}
          tabIndex={tabIndex}
          isChecked={getValues(value) === option.value}
          onClick={() => {
            if (!disabled) onChangeHandler(option.value);
          }}
        >
          <Text style={disabled ? disabledStyles : {}}>{option.label}</Text>
        </Radio>
      );
    })}
  </FormControl>
);

FormBuilderRadioButtons.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  register: PropTypes.func,
  isChecked: PropTypes.func,
  options: PropTypes.any,
  getValues: PropTypes.func,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};
