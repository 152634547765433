/* eslint-disable no-unused-vars */
import AppRenderer, { ChildrenWidget } from 'lib-node-amb-renderer';
import componentMapper from 'common/componentMapper';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLayout from 'common/components/GlobalLayout';
import { useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';

// eslint-disable-next-line import/no-unresolved
import layoutYaml from './referenceLayout.yaml?raw';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';
import {
  getQuickMatchDocumentAssociations,
  getClassifyDocuments,
} from 'pages/ClassifyDocumentsV2/redux/thunks';
import { API_STATUS } from 'common/constants';
import FormTemplate from 'common/componentMapper/FormTemplate/FormTemplate';
import { HomeActions, HomeThunks } from 'pages/Home/redux';
import lang from 'common/constants/language';
import { DynamicTableWidget } from 'common/componentMapper/ChakraTable/DTable';
import { DownloadLinkWidget } from 'common/componentMapper/DownloadLink/DownloadLink';
import { MultiSelectWidget } from 'common/componentMapper/Select/MultiSelect';

const validatorMapper = {
  documentAssociationValidate: () => (value) => {
    return !value ? 'Required' : undefined;
  },
};
class MyTableRowWidget extends ChildrenWidget {
  constructor(props) {
    super(props);
  }
}

const customComponents = {
  DynamicTable: DynamicTableWidget,
  DynamicTableRow: MyTableRowWidget,
  DownloadLink: DownloadLinkWidget,
  MultiSelect: MultiSelectWidget,
};

export default function QuickMatch() {
  const dispatch = useDispatch();
  const [saveStatus, setSaveStatus] = useState(null);

  const toastErrorMessage = {
    id: 'documentAssociationsToast',
    title: lang.DEFAULT_OOPS_TITLE,
    status: lang.TOAST_ERROR_STATUS,
    description: 'There was an issue loading data for Document Association(s)',
  };

  const { UPDATING, DEFAULT } = lang.LOADER_MESSAGE;

  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );

  const {
    quickMatchDocumentAssociation: {
      data: quickMatchData,
      status: getQuickMatchStatus,
    },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  // QUICK MATCH SAVE API
  const saveDocumentAssociation = async (formValues) => {
    setSaveStatus(API_STATUS.UPDATING);
    try {
      setSaveStatus(API_STATUS.UPDATING);
      const data = await apiClient.post(
        apiEndPoints.classifyDocuments.SAVE_BATCH_DOCUMENT_ASSOCIATIONS,
        {
          obfuscatedLoanIdentifier,
          ...formValues,
        },
      );

      // call the get endpoint to update the doc lists outside of quickmatch
      // apiClient
      dispatch(getQuickMatchDocumentAssociations());
      dispatch(getClassifyDocuments(true));
      dispatch(HomeThunks.getNavigationDetails());

      return data;
    } catch {
      setSaveStatus(API_STATUS.ERROR);
      dispatch(HomeActions.setIsToastOpen(true, toastErrorMessage));
      return false;
    } finally {
      setSaveStatus(null);
    }
  };
  const submitting = saveStatus === API_STATUS.UPDATING;
  const isLoading =
    getQuickMatchStatus === API_STATUS.GETTING ||
    submitting ||
    !quickMatchData?.screenData;
  const isError = saveStatus === API_STATUS.ERROR;

  const renderedApp = useMemo(() => {
    return (
      quickMatchData?.screenData &&
      !isLoading && (
        /* c8 ignore next 10 */
        <AppRenderer
          onSubmit={saveDocumentAssociation}
          data={quickMatchData.screenData}
          markup={layoutYaml}
          componentMapper={componentMapper}
          initialValues={quickMatchData.initialFormValues}
          FormTemplate={FormTemplate}
          customComponents={customComponents}
          validatorMapper={validatorMapper}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickMatchData?.screenData, isLoading]);

  if (isLoading) {
    return (
      <GlobalLayout
        heading="Document Association(s)"
        primaryButton={{
          name: 'Continue',
          isSubmitting: submitting,
          isDisabled: isLoading,
        }}
        loadingMessage={submitting && !isError ? UPDATING : DEFAULT}
        isLoading={isLoading}
      />
    );
  }
  return renderedApp;
}
