import TYPES from './types';

//define actions - should never need to use these
export function setOLI(oldi, olri) {
  return {
    type: TYPES.URL_SET_LOAN_PARAMS,
    payload: {
      obfuscatedLenderDatabaseId: parseInt(oldi),
      obfuscatedLoanRecordId: parseInt(olri),
    },
  };
}

export function parseURL(url = window.location.pathname) {
  const [
    // eslint-disable-next-line no-unused-vars
    _,
    obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId,
    attachDocs,
  ] = url.split('/');
  return {
    type: TYPES.URL_SET_ALL_PARAM,
    payload: {
      obfuscatedLenderDatabaseId: parseInt(obfuscatedLenderDatabaseId),
      obfuscatedLoanRecordId: parseInt(obfuscatedLoanRecordId),
      attachDocs: attachDocs !== '' && attachDocs === 'true',
    },
  };
}

export function setAttachDocs(attach) {
  return {
    type: TYPES.URL_SET_ATTACH_PARAMS,
    payload: attach === 'true' || attach === true,
  };
}

export const getLoanInfoStarted = () => ({
  type: TYPES.GET_LOAN_INFO_STARTED,
});

export const getLoanInfoSuccess = (payload) => ({
  type: TYPES.GET_LOAN_INFO_SUCCESS,
  payload,
});

export const getLoanInfoError = (error) => ({
  type: TYPES.GET_LOAN_INFO_ERROR,
  error,
});

export function saveQuestionAnswersStarted() {
  return {
    type: TYPES.SAVE_ANSWERS_STARTED,
  };
}

export function saveQuestionAnswersSuccess() {
  return {
    type: TYPES.SAVE_ANSWERS_SUCCESS,
  };
}

export function saveQuestionAnswersError(payload) {
  return {
    type: TYPES.SAVE_ANSWERS_ERROR,
    payload,
  };
}
/**
 *
 * @param {Object<string,bool>} payload
 */
export function setFeatureFlags(payload) {
  return {
    type: TYPES.SET_FEATURE_FLAG,
    payload,
  };
}

export function setPdfCitation(payload) {
  return { type: TYPES.SET_PDF_CITATION, payload };
}

export const setLoanSummaryUpsertOrDelete = (value) => ({
  type: TYPES.SET_LOAN_SUMMARY_UPSERT_OR_DELETE,
  payload: value,
});

export const setPdfDocumentId = (payload) => ({
  type: TYPES.SET_PDF_DOCUMENT_ID,
  payload: payload,
});
