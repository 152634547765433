//local imports
import API_STATUS from 'common/constants/api-status';
import { OTHER_INCOME_TYPES } from './types';

export const otherIncome = (
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) => {
  switch (action.type) {
    case OTHER_INCOME_TYPES.GET_OTHER_INCOME_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case OTHER_INCOME_TYPES.GET_OTHER_INCOME_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error: action.payload.error,
        data: null,
      };
    case OTHER_INCOME_TYPES.GET_OTHER_INCOME_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: action.payload.data,
        error: null,
      };
    default:
      return state;
  }
};

export const saveOtherIncome = (
  state = {
    status: null,
  },
  action,
) => {
  switch (action.type) {
    case OTHER_INCOME_TYPES.SAVE_OTHER_INCOME_STARTED: {
      return {
        ...state,
        status: API_STATUS.UPDATING,
      };
    }
    case OTHER_INCOME_TYPES.SAVE_OTHER_INCOME_ERROR: {
      return {
        ...state,
        status: API_STATUS.ERROR,
      };
    }
    case OTHER_INCOME_TYPES.SAVE_OTHER_INCOME_SUCCESS:
      return {
        ...state,
        status: API_STATUS.DONE,
      };
    default:
      return state;
  }
};
