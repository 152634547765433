class IncomeValidationKeyDatesDataModel {
  constructor(vd = {}) {
    this.disbursementDate = Object.hasOwnProperty.call(vd, 'disbursementDate')
      ? new Date(vd.disbursementDate)
      : new Date(null);
    this.docHubSubmissionDate = Object.hasOwnProperty.call(
      vd,
      'docHubSubmissionDate',
    )
      ? new Date(vd.docHubSubmissionDate)
      : new Date(null);
    this.estimatedClosingDate = Object.hasOwnProperty.call(
      vd,
      'estimatedClosingDate',
    )
      ? new Date(vd.estimatedClosingDate)
      : new Date(null);
    this.submittedDate = Object.hasOwnProperty.call(vd, 'submittedDate')
      ? new Date(vd.submittedDate)
      : new Date(null);
  }

  getDisbursementDate() {
    return this.disbursementDate;
  }

  getDocHubSubmissionDate() {
    return this.docHubSubmissionDate;
  }

  getEstimatedClosingDate() {
    return this.estimatedClosingDate;
  }

  getSubmittedDate() {
    return this.submittedDate;
  }
}

export default IncomeValidationKeyDatesDataModel;
