export const ASSET_TYPE = [
  {
    value: 'Bonds',
    label: 'Bonds',
  },
  {
    value: 'Business Checking',
    label: 'Business Checking',
  },
  {
    value: 'Business Savings',
    label: 'Business Savings',
  },
  {
    value: 'Cash Deposit',
    label: 'Cash Deposit',
  },
  {
    value: 'CD',
    label: 'CD',
  },
  {
    value: 'Checking',
    label: 'Checking',
  },
  {
    value: 'Earnest Money Deposit',
    label: 'Earnest Money Deposit',
  },
  {
    value: 'Expected Assets',
    label: 'Expected Assets',
  },
  {
    value: 'Gift',
    label: 'Gift',
  },
  {
    value: 'Gift of Equity',
    label: 'Gift of Equity',
  },
  {
    value: 'Grant',
    label: 'Grant',
  },
  {
    value: 'Liquidated Account',
    label: 'Liquidated Account',
  },
  {
    value: 'Money Market',
    label: 'Money Market',
  },
  {
    value: 'Mutual Fund',
    label: 'Mutual Fund',
  },
  {
    value: 'Net Proceeds',
    label: 'Net Proceeds',
  },
  {
    value: 'Other Asset',
    label: 'Other Asset',
  },
  {
    value: 'Retirement Fund',
    label: 'Retirement Fund',
  },
  {
    value: 'Savings',
    label: 'Savings',
  },
  {
    value: 'Stocks',
    label: 'Stocks',
  },
  {
    value: 'Stocks and Bonds',
    label: 'Stocks and Bonds',
  },
];

export const DONOR_RELATIONSHIP_TYPE = [
  {
    value: 'Spouse',
    label: 'Spouse',
  },
  {
    value: 'Child',
    label: 'Child',
  },
  {
    value: 'Dependent',
    label: 'Dependent',
  },
  {
    value: 'Domestic Partner',
    label: 'Domestic Partner',
  },
  {
    value:
      'Other relative related by blood, marriage, adoption, or legal guardianship',
    label:
      'Other relative related by blood, marriage, adoption, or legal guardianship',
  },
  {
    value: 'Individual engaged to marry the borrower',
    label: 'Individual engaged to marry the borrower',
  },
  {
    value: 'Former Relative',
    label: 'Former Relative',
  },
  {
    value: 'Godparent',
    label: 'Godparent',
  },
  {
    value: 'Relative of domestic partner',
    label: 'Relative of domestic partner',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const GIFT_SOURCE_TYPE = {
  1: 'Relative',
  2: 'Parent',
  3: 'Non-Parent Relative',
  4: 'Unmarried Partner',
  5: 'Unrelated Friend',
  6: 'Employer',
  7: 'Religious Non-Profit',
  8: 'Community Non-Profit',
  9: 'Federal Agency',
  10: 'State Agency',
  11: 'Local Agency',
  12: 'Lender',
  13: 'Instiutional',
  14: 'Other',
};
