﻿/** c8 ignore file */

import { connect } from 'react-redux';
import { getHistoricalCalculation } from '../../redux/thunks';
import HistoricalBreakdown from './layout';

const mapStateToProps = ({ incomeCalculator, rentalIncomeData }) => ({
  status: incomeCalculator.calculationHistory.historicalDataStatus,
  results: incomeCalculator.calculationHistory.historicalData,
  loan: incomeCalculator.loan,
  selectedIncome: incomeCalculator.selectedIncome,
  rentalAddress: rentalIncomeData.data?.addressIdentifier,
});

const mapDispatchToProps = {
  getHistoricalCalculation,
};

const HistoricalBreakdownHOC = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricalBreakdown);
export default HistoricalBreakdownHOC;
