import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import Modal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalBody from 'common/components/Modals/ModalBody';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import ModalHeader from 'common/components/Modals/ModalHeader';
import Button from 'common/components/Buttons/Button/index';
import Text from 'common/components/Texts/Text';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import theme from 'theme';

import { submitHideDocument } from './redux/thunks';
import actions from './redux/actions';
import { API_STATUS } from 'common/constants';
// Styles
import {
  StyledRemoveDocsLink,
  StyledTrashIcon,
} from './StyledComponents/ClassifyFormContent.styles';

const ClassifyHideDocsModal = () => {
  const {
    user: { role: userRole },
  } = useSelector(({ home }) => home);
  const {
    selectedDocument,
    removeDocuments: { status: submitHideDocumentAPICallStatus },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const removeDoc = useCallback(() => {
    dispatch(
      submitHideDocument(
        selectedDocument.documentId,
        parseInt(selectedDocument.documentTypeId),
      ),
    );
    if (submitHideDocumentAPICallStatus === API_STATUS.ERROR) {
      return;
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDocument]);

  const openHideDocsModal = () => {
    onOpen();
    dispatch(actions.resetHideDocumentState());
  };

  return (
    <>
      {!userRole && (
        <StyledRemoveDocsLink
          id="hideDocLink"
          data-testid="hideDocLink"
          onClick={() => openHideDocsModal()}
        >
          Remove Document <StyledTrashIcon icon={faTrash} />
        </StyledRemoveDocsLink>
      )}
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size="md"
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader
            p="unset"
            mb="24px"
            mr="24px"
            fontSize="1rem"
            fontWeight="500"
          >
            Are you sure you want to remove this document from BOLT?
          </ModalHeader>
          <ModalCloseButton
            data-testid="hideDocModalClose"
            top="15px"
            right="14px"
          />
          <ModalBody p="unset">
            <Text fontSize={theme.functions.pixelsToRem(14)} textAlign="center">
              By clicking yes, this document will no longer appear in the BOLT
              application.
            </Text>
          </ModalBody>
          <ModalFooter p="unset" mt="24px" justifyContent="space-between">
            <Button
              variant="outline"
              borderColor="blue.600"
              color="gray.900"
              onClick={onClose}
              data-testid="hideDocModalNo"
              id="hideDocModalNo"
              data-tracking={`keptDocumentId-${selectedDocument?.documentId}`}
            >
              No, keep document
            </Button>
            <Button
              backgroundColor="blue.600"
              color="white"
              _hover={{
                backgroundColor: 'blue.500',
              }}
              onClick={removeDoc}
              data-testid="hideDocModalYes"
              id="hideDocModalYes"
              data-tracking={`removedDocumentId-${selectedDocument?.documentId}`}
            >
              Yes, remove document
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClassifyHideDocsModal;
