import { InputLeftElement as ChakraInputLeftElement } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const InputLeftElement = ({ children, ...props }) => {
  return <ChakraInputLeftElement {...props}>{children}</ChakraInputLeftElement>;
};

export default InputLeftElement;

InputLeftElement.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
