import alert from './alert';
import alertMessage from './alertMessage';
import control from './control';
import obfuscatedLoanIdentifier from './obfuscatedLoanIdentifier';
import auditHistoryData from './auditHistoryData';
import extractedIncomeData from './extractedIncomeData';
import citation from './citation';

export default {
  alert,
  alertMessage,
  control,
  obfuscatedLoanIdentifier,
  auditHistoryData,
  extractedIncomeData,
  citation,
};
