import React from 'react';
import Box from 'common/components/Box';
import PropTypes from 'prop-types';
import { propsMapper } from 'common/components/PropertyMappers/propsMapper';

const SimpleGrid = React.forwardRef(({ children, ...props }, ref) => {
  const updatedProps = {
    display: 'grid',
    ...props,
  };

  return (
    <Box ref={ref} {...propsMapper(updatedProps)}>
      {children}
    </Box>
  );
});

SimpleGrid.displayName = 'SimpleGrid';

SimpleGrid.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default SimpleGrid;
