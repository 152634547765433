/**
 * @param {[]} dataArray list of objects in a flat array
 * we are mapping by ruleReferenceValue first since some questions are nested and dependent upon other answered questions,
 * and then mapping under the ruleReferenceValue with questionID
 * @param {string} questionCategory string to be used as a category
 * @return data: {
 *   ruleReferenceID: {
 *     questions: {
 *        23: [],
 *        24: []
 *     }
 *   }
 * }
 */
export const auditHistoryDataReducer = (dataArray, questionCategory = null) => {
  return dataArray.reduce(
    (
      accum,
      {
        questionID,
        answer,
        userName,
        modifiedDateTime,
        questionDesc,
        ruleReferenceValue,
        questionDetailDesc,
        liabilityAdded,
      },
    ) => {
      if (questionCategory) ruleReferenceValue = questionCategory;

      const rowAnswerData = {
        answer,
        userName,
        modifiedDateTime,
        questionText: questionDesc,
        questionDetailDesc,
        liabilityAdded,
      };
      if (accum[ruleReferenceValue]) {
        if (accum[ruleReferenceValue]?.questions[questionID]) {
          return {
            ...accum,
            [ruleReferenceValue]: {
              questions: {
                ...accum[ruleReferenceValue].questions,
                [questionID]: [
                  ...accum[ruleReferenceValue].questions[questionID],
                  rowAnswerData,
                ],
              },
            },
          };
        }
        return {
          ...accum,
          [ruleReferenceValue]: {
            questions: {
              ...accum[ruleReferenceValue].questions,
              [questionID]: [rowAnswerData],
            },
          },
        };
      }
      return {
        ...accum,
        [ruleReferenceValue]: {
          questions: {
            [questionID]: [rowAnswerData],
          },
        },
      };
    },
    {},
  );
};
