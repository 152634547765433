export default class CitizenshipData {
  constructor(data = {}) {
    this.borrowers = Object.prototype.hasOwnProperty.call(data, 'borrowers')
      ? data.borrowers
      : [];

    this.results = Object.prototype.hasOwnProperty.call(data, 'results')
      ? data.results
      : [];
  }

  getBorrowers() {
    return this.borrowers;
  }

  getResults() {
    return this.results;
  }
}
