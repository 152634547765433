const THIRD_PARTY_CHECK_TYPES = {
  GET_DOCUMENTS_ALERT_STATUS_STARTED: 'GET_DOCUMENTS_ALERT_STATUS_STARTED',
  GET_DOCUMENTS_ALERT_STATUS_SUCCESS: 'GET_DOCUMENTS_ALERT_STATUS_SUCCESS',
  GET_DOCUMENTS_ALERT_STATUS_ERROR: 'GET_DOCUMENTS_ALERT_STATUS_ERROR',

  GET_DOCUMENTS_ALERT_DATA_STARTED: 'GET_DOCUMENTS_ALERT_DATA_STARTED',
  GET_DOCUMENTS_ALERT_DATA_SUCCESS: 'GET_DOCUMENTS_ALERT_DATA_SUCCESS',
  GET_DOCUMENTS_ALERT_DATA_ERROR: 'GET_DOCUMENTS_ALERT_DATA_ERROR',

  SAVE_THIRD_PARTY_CHECK_STATUS_STARTED:
    'SAVE_THIRD_PARTY_CHECK_STATUS_STARTED',
  SAVE_THIRD_PARTY_CHECK_STATUS_SUCCESS:
    'SAVE_THIRD_PARTY_CHECK_STATUS_SUCCESS',
  SAVE_THIRD_PARTY_CHECK_STATUS_ERROR: 'SAVE_THIRD_PARTY_CHECK_STATUS_ERROR',

  SAVE_QUESTION_ANSWERS_STARTED: 'SAVE_QUESTION_ANSWERS_STARTED',
  SAVE_QUESTION_ANSWERS_SUCCESS: 'SAVE_QUESTION_ANSWERS_SUCCESS',
  SAVE_QUESTION_ANSWERS_ERROR: 'SAVE_QUESTION_ANSWERS_ERROR',
};

export default THIRD_PARTY_CHECK_TYPES;
