﻿import AlertDataModel from './AlertDataModel';
import BreakdownFieldDataModel from './BreakdownFieldDataModel';
import FormulaResponseDataModel from './FormulaResponseDataModel';

export default class GenericCalculationItemDataModel {
  constructor(item = {}) {
    this.scenarioId = Object.hasOwnProperty.call(item, 'scenarioId')
      ? item.scenarioId
      : 0;
    this.scenarioName = Object.hasOwnProperty.call(item, 'scenarioName')
      ? item.scenarioName
      : '';
    this.isSelected = Object.hasOwnProperty.call(item, 'isSelected')
      ? item.isSelected
      : false;
    this.calculationAmount = Object.hasOwnProperty.call(
      item,
      'calculationAmount',
    )
      ? item.calculationAmount
      : 0;
    this.overriddenBaseIncome = Object.hasOwnProperty.call(
      item,
      'overriddenBaseIncome',
    )
      ? item.overriddenBaseIncome
      : 0;
    // Comments is used in Calculation History. Comment is used to Persist in Review Breakdown
    this.comments = Object.hasOwnProperty.call(item, 'comments')
      ? item.comments
      : '';
    this.comment = Object.hasOwnProperty.call(item, 'comment')
      ? item.comment
      : '';
    this.incomeCalculatorType = Object.hasOwnProperty.call(
      item,
      'incomeCalculatorType',
    )
      ? item.incomeCalculatorType
      : '';
    this.incomeCalculatorTypeId = Object.hasOwnProperty.call(
      item,
      'incomeCalculatorTypeId',
    )
      ? item.incomeCalculatorTypeId
      : 0;
    this.alerts = Object.hasOwnProperty.call(item, 'alerts')
      ? item.alerts.map((alert) => new AlertDataModel(alert))
      : [];
    this.formulas = Object.hasOwnProperty.call(item, 'formulas')
      ? item.formulas.map((f) => new FormulaResponseDataModel(f))
      : [];
    this.calculationFields = Object.hasOwnProperty.call(
      item,
      'calculationFields',
    )
      ? item.calculationFields.map(
          (field) => new BreakdownFieldDataModel(field),
        )
      : [];
    this.calculationId = Object.hasOwnProperty.call(item, 'calculationId')
      ? item.calculationId
      : 0;
    this.calculatorTypeId = Object.hasOwnProperty.call(item, 'calculatorTypeId')
      ? item.calculatorTypeId
      : 0;
    this.inputFields = Object.hasOwnProperty.call(item, 'inputFields')
      ? item.inputFields.map((field) => new BreakdownFieldDataModel(field))
      : [];
    this.overrideAmounts = Object.hasOwnProperty.call(item, 'overrideAmounts')
      ? item.overrideAmounts
      : {};
    this.isSavedToLoan = Object.hasOwnProperty.call(item, 'isSavedToLoan')
      ? item.isSavedToLoan
      : false;
  }

  getCalculationAmount() {
    return this.getAmount();
  }

  getOverrideAmount() {
    return this.overriddenBaseIncome;
  }

  /**
   * determine if any overrides were used
   *
   * @returns {boolean} indicator if override values are set
   */
  isOverrideUsed() {
    return !!this.overriddenBaseIncome;
  }

  /**
   * @returns {number}
   */
  getScenarioId() {
    return this.scenarioId;
  }

  /**
   * @returns {string}
   */
  getScenarioName() {
    return this.scenarioName;
  }

  /**
   * @returns {boolean} True if the result was selected.
   */
  getIsSelected() {
    return this.isSelected;
  }

  /**
   * @returns {number} The amount of calculated income.
   */
  getAmount() {
    return this.calculationAmount;
  }

  setOverrideAmount(value) {
    this.overriddenBaseIncome = value;
  }

  setComments(value) {
    this.comments = value;
  }

  /**
   * @returns {number} The calculator type id.
   */
  getCalculatorType() {
    return this.incomeCalculatorTypeId;
  }

  /**
   * @returns {[AlertDataModel]} The list of returned alerts.
   */
  getAlerts() {
    return this.alerts;
  }

  /**
   * @returns {[BreakdownFieldDataModel]} The output fields of the calculation.
   */
  getFields() {
    return this.calculationFields;
  }

  /**
   * @returns {[BreakdownFieldDataModel]} The input fields of the calculation.
   */
  getInputs() {
    return this.inputFields;
  }

  /**
   * Gets the list of formulas
   * @returns {[FormulaResponseDataModel]}
   */
  getFormulas() {
    return this.formulas;
  }

  /**
   * Gets an input field to display on the screen.
   * @param formType {number} The form type id.
   * @param fiscalYear {number} The fiscal year of the document.
   * @param fieldName {string|number} The name or id of the field.
   * @returns {BreakdownFieldDataModel|null} The requested field, or null.
   */
  getInput(formType, fiscalYear, fieldName) {
    return this.getInputs().find(
      (field) =>
        field.getFormId() === formType &&
        (!fiscalYear || field.getFiscalYear() === fiscalYear) &&
        (field.getFieldName() === fieldName ||
          field.getFieldId() === fieldName),
    );
  }

  /**
   * Gets an input field value to display on the screen.
   * @param formType {number} The form type id.
   * @param fiscalYear {number} The fiscal year of the document.
   * @param fieldName {string|number} The name or id of the field.
   * @returns {number|NaN} The requested field value.
   */
  getInputAsFloat(formType, fiscalYear, fieldName) {
    return parseFloat(
      this.getInput(formType, fiscalYear, fieldName)?.getValue(),
    );
  }

  /**
   * Gets an output field to display on the screen.
   * @param formType {number} The formType type id.
   * @param fiscalYear {number?} The fiscal year, or null to grab the first.
   * @param fieldName {string|number} The name or id of the field.
   * @returns {BreakdownFieldDataModel|null} The requested field, or null.
   */
  getField(formType, fiscalYear, fieldName) {
    return this.calculationFields.find(
      (field) =>
        field.getFormId() === formType &&
        (!fiscalYear || field.getFiscalYear() === fiscalYear) &&
        (field.getFieldName() === fieldName ||
          field.getFieldId() === fieldName),
    );
  }

  /**
   * Gets the list of fiscal years the calculation was ran for.
   * @returns {[number]} The fiscal years.
   */
  getFiscalYears() {
    return [
      ...new Set(
        this.inputFields
          .filter((field) => field.fiscalYear !== 0)
          .map((f) => f.getFiscalYear()),
      ),
    ];
  }
}
