import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import Footer from 'common/components/GlobalLayout/Footer';
import { HomeActions } from 'pages/Home/redux';

// eslint-disable-next-line no-unused-vars
export default function NonGlobalLayoutFormTemplate({ schema, formFields }) {
  const dispatch = useDispatch();
  const { getState, handleSubmit } = useFormApi();
  const { submitting, valid, values } = getState();

  return (
    <>
      {formFields}
      <Footer
        primaryButton={{
          isSubmitting: submitting,
          onSubmit: async () => {
            const res = await handleSubmit(values);

            if (!res && valid) {
              dispatch(HomeActions.setIsToastOpen(true));
            }
            return !!res;
          },
        }}
      />
    </>
  );
}

NonGlobalLayoutFormTemplate.propTypes = {
  schema: PropTypes.object,
  formFields: PropTypes.any,
};
