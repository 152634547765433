import PropTypes from 'prop-types';

const TableCaption = ({ children, ...props }) => {
  return <caption {...props}>{children}</caption>;
};

TableCaption.displayName = 'TableCaption';

export default TableCaption;

TableCaption.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
