const CITIZENSHIP_TYPES = {
  GET_CITIZENSHIP_INFO_STARTED: 'GET_CITIZENSHIP_INFO_STARTED',
  GET_CITIZENSHIP_INFO_SUCCESS: 'GET_CITIZENSHIP_INFO_SUCCESS',
  GET_CITIZENSHIP_INFO_ERROR: 'GET_CITIZENSHIP_INFO_ERROR',
  UPDATE_CITIZENSHIP_INFO_STARTED: 'UPDATE_CITIZENSHIP_INFO_STARTED',
  UPDATE_CITIZENSHIP_INFO_SUCCESS: 'UPDATE_CITIZENSHIP_INFO_SUCCESS',
  UPDATE_CITIZENSHIP_INFO_ERROR: 'UPDATE_CITIZENSHIP_INFO_ERROR',
};

export default CITIZENSHIP_TYPES;
