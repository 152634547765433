import { useState } from 'react';
import PropTypes from 'prop-types';
import ChakraModal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalHeader from 'common/components/Modals/ModalHeader';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import ModalBody from 'common/components/Modals/ModalBody';
import Flex from 'common/components/Flex';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

import Button from 'common/components/Button';
export default function Modal({
  fields = [],
  label,
  title,
  variant = false,
  disabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { renderForm } = useFormApi();

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        disabled={disabled}
        variant={variant}
      >
        {label}
      </Button>
      <ChakraModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent marginTop={350}>
          <ModalCloseButton />
          {title && <ModalHeader>{title}</ModalHeader>}
          <ModalBody mt="24px">
            {!!fields.length && renderForm([fields])}
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" pb="2rem">
            <Flex justifyContent="space-between" gridGap="12px">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  alert('How does this look?');
                  setIsOpen(false);
                }}
              >
                OK
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}

Modal.propTypes = {
  fields: PropTypes.array,
  label: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined']),
  disabled: PropTypes.bool,
  actions: PropTypes.array,
};
