import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createBlobUrl } from 'common/util/pdfUtils';
import { useToast } from '@chakra-ui/react';
import Link from 'common/components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import apiClient from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';
import { VARIANTS } from './contants';
import Alert from '../Alerts/Alert';

const DownloadLink = ({
  children,
  apiError,
  documentId,
  icon,
  text,
  variant,
  //state->props
  obfuscatedLoanIdentifier,
  //dispatch->props
  clearPdf,
}) => {
  const [isDownloading, setDownloading] = useState(false);
  const [isError, setError] = useState(false);
  const toast = useToast();
  const toastRef = useRef();
  const toastId = 'DownloadToast';

  useEffect(() => {
    toast.closeAll();
    return () => {
      clearPdf();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDownloading(false);
    if (isError && !toast.isActive(toastId)) {
      toastRef.current = toast({
        position: 'bottom',

        /* eslint-disable react/display-name */
        render: () => <Alert status="error">{apiError}</Alert>,
        /* eslint-enable react/display-name */
      });
    } else {
      toast.close(toastRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, toast]);

  const link = children ? (
    children
  ) : (
    <>
      {text} <FontAwesomeIcon icon={icon} style={{ marginLeft: '4px' }} />
    </>
  );

  const retrievePdf = async (documentId) => {
    setDownloading(true);
    const { obfuscatedLenderDatabaseId, obfuscatedLoanRecordId } =
      obfuscatedLoanIdentifier;
    const pdfEndpoint = `${apiEndpoints.classifyDocuments.GET_PDF}/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/${documentId}`;

    apiClient
      .get(pdfEndpoint, {
        responseType: 'arraybuffer',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        }),
      })
      .then(({ data }) => createBlobUrl(data))
      .then((blobUrl) => {
        window.open(blobUrl, '__blank');
        setError(false);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <Link
      role="link"
      variant={variant}
      fontSize="md"
      color={isDownloading ? 'uwmOrange.500' : 'blue.700'}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        toast.closeAll();

        await retrievePdf(+documentId);
      }}
      fontWeight="400"
    >
      {isDownloading ? 'Downloading...' : link}
    </Link>
  );
};

DownloadLink.propTypes = {
  //ownProps
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  ]),
  apiError: PropTypes.string,
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  downloadError: PropTypes.string,
  file: PropTypes.string,
  icon: PropTypes.object,
  target: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),

  //state->props
  obfuscatedLoanIdentifier: PropTypes.object,
  //dispatch->props
  clearPdf: PropTypes.func.isRequired,
};

/* c8 ignore next */
DownloadLink.defaultProps = {
  apiError: `We've encountered an error. Please refresh and try again. If the error persists, contact your AE for assistance.`,
  downloadError: `We've encountered an error. Please refresh and try again. If the error persists, contact your AE for assistance.`,
  handleDownload: (url, target) => {
    window.open(url, target);
  },
  file: 'File',
  icon: faExternalLinkAlt,
  target: '_blank',
  text: 'Download',
  variant: 'link',
};

export default DownloadLink;
