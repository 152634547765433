// 3rd party imports
import { useSelector } from 'react-redux';
import CloseButton from 'common/components/Buttons/CloseButton';
import Spinner from 'common/components/Spinner/index';
import UnorderedList from 'common/components/Lists/UnorderedList';
import ListItem from 'common/components/Lists/ListItem';
import Center from 'common/components/Center';
import Flex from 'common/components/Flex';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

//local imports
import { formatCurrency } from 'common/util/format';
import { API_STATUS } from 'common/constants';

const GridTitleItem = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
const GridDataItem = styled.div`
  font-size: 14px;
`;

const formatAmount = (val) => {
  let amount = parseFloat(val).toFixed(2);
  return formatCurrency(amount);
};

const LoanSummary = ({ toggleLoanSummary }) => {
  const { home } = useSelector((state) => state);
  const loanSummaryInfo = home?.loanSummary?.value;
  const status = home?.loanSummary?.status;
  const error = home?.loanSummary?.error;

  return (
    <>
      <Box as="section" backgroundColor="white" width="100%" id="loanSummary">
        {error ? (
          <Box>
            <Center>
              <Flex justifyContent="center">
                <Text height="100px" mt="50px">
                  We&apos;ve encountered an error. Please refresh and try again.
                  If the error persists, contact your AE for assistance.
                </Text>
              </Flex>
            </Center>
          </Box>
        ) : (
          <>
            {status === API_STATUS.GETTING ? (
              <Center width="100%" minHeight="201px">
                <Spinner
                  size="xl"
                  thickness="4px"
                  color="orange.500"
                  emptyColor="gray.200"
                />
              </Center>
            ) : (
              <Box>
                <Flex justifyContent="center">
                  <Box width="100%" maxWidth="1272px" my="32px">
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mb="16px"
                    >
                      <Flex>
                        <Text id="loanNumber" fontWeight="500" fontSize="1rem">
                          Loan Number:
                        </Text>
                        <Text fontSize="1rem" ml="3px">
                          {loanSummaryInfo?.loanNumber}
                        </Text>
                      </Flex>
                      <CloseButton onClick={toggleLoanSummary} mr="6px" />
                    </Flex>

                    <UnorderedList
                      sx={{
                        listStyle: 'none',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(6, 1fr)',
                        marginLeft: 0,
                        gap: '24px',
                      }}
                    >
                      <ListItem id="borrowerName">
                        <GridTitleItem>Borrower</GridTitleItem>
                        <GridDataItem>
                          {`${loanSummaryInfo?.primaryBorrowerFirstName} ${loanSummaryInfo?.primaryBorrowerLastName}`}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="occupancyType">
                        <GridTitleItem>Occupancy Type</GridTitleItem>
                        <GridDataItem>
                          {loanSummaryInfo?.occupancyType}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="htiDtiPercentage">
                        <GridTitleItem>HTI / DTI</GridTitleItem>
                        <GridDataItem>{`${loanSummaryInfo?.hti}% / ${loanSummaryInfo?.dti}%`}</GridDataItem>
                      </ListItem>

                      <ListItem id="appraisedValue">
                        <GridTitleItem>Appraised Value</GridTitleItem>
                        <GridDataItem>
                          {formatAmount(loanSummaryInfo?.appraisedvalue)}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="baseTotalLoanAmount">
                        <GridTitleItem>Base / Total Loan Amount</GridTitleItem>
                        <GridDataItem>
                          {`${formatAmount(loanSummaryInfo?.baseLoanAmount)}
              / ${formatAmount(loanSummaryInfo?.totalLoanAmount)}`}{' '}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="pitiAmount">
                        <GridTitleItem>PITI</GridTitleItem>
                        <GridDataItem>
                          {formatAmount(
                            loanSummaryInfo?.principalInterestTaxesInsurance,
                          )}{' '}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="propertyAddress">
                        <GridDataItem>
                          {loanSummaryInfo?.propertyAddress?.addressLine1}
                          {loanSummaryInfo?.propertyAddress?.unit === !null ? (
                            <Text>
                              {loanSummaryInfo?.propertyAddress?.unit}
                            </Text>
                          ) : (
                            ''
                          )}
                        </GridDataItem>
                        <GridDataItem>
                          {`${loanSummaryInfo?.propertyAddress?.city}, ${loanSummaryInfo?.propertyAddress?.state} ${loanSummaryInfo?.propertyAddress?.zipCode}`}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="propertyType">
                        <GridTitleItem>Property Type</GridTitleItem>
                        <GridDataItem>
                          {loanSummaryInfo?.propertyType}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="mortgagePurpose">
                        <GridTitleItem>Mortgage Purpose</GridTitleItem>
                        <GridDataItem>
                          {loanSummaryInfo?.loanPurpose}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="mortageType">
                        {loanSummaryInfo?.isRefinance === true ? (
                          <GridTitleItem id="refinanceType">
                            Refinance Purpose
                          </GridTitleItem>
                        ) : (
                          <GridTitleItem>Sale Price</GridTitleItem>
                        )}
                        {loanSummaryInfo?.isRefinance === true ? (
                          <GridDataItem id="loanSummaryInfo">
                            {loanSummaryInfo?.refiPurpose}
                          </GridDataItem>
                        ) : (
                          <GridDataItem>
                            {formatAmount(loanSummaryInfo?.salesPrice)}
                          </GridDataItem>
                        )}
                      </ListItem>

                      <ListItem id="ltvCltvTltv">
                        <GridTitleItem>LTV / CLTV / TLTV</GridTitleItem>
                        <GridDataItem>
                          {`${loanSummaryInfo?.ltv}% / ${loanSummaryInfo?.cltv}% / ${loanSummaryInfo?.tltv}%`}
                        </GridDataItem>
                      </ListItem>

                      <ListItem id="aus">
                        <GridTitleItem>AUS</GridTitleItem>
                        <GridDataItem>
                          {loanSummaryInfo?.ausMethod}
                        </GridDataItem>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </Flex>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
LoanSummary.propTypes = {
  toggleLoanSummary: PropTypes.func,
  getLoanSummary: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};
export default LoanSummary;
