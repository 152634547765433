import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

// local imports
import oidcManager from '../util/oidc-manager';

const useSignalR = () => {
  const params = useParams();
  const [notification, setNotification] = useState(null);
  const lenderDatabaseId = parseInt(params.lenderDatabaseId);
  const loanRecordId = parseInt(params.loanRecordId);
  useEffect(() => {
    (async () => {
      const user = await oidcManager.getUser();
      const accessToken = user?.access_token;

      // build signalR connection hub
      let connection = new HubConnectionBuilder()
        .withUrl(
          `${window.globalConfig.signalR.endpoint_uri}/notifications?obfuscatedLenderDatabaseId=${lenderDatabaseId}&obfuscatedLoanRecordId=${loanRecordId}`,
          {
            accessTokenFactory: () => {
              return accessToken;
            },
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          },
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      // set up notification watcher
      /* c8 ignore next */
      connection.on(
        'WebNotification',
        (
          obfuscatedLenderDatabaseId,
          obfuscatedLoanRecordId,
          notificationType,
          correlationId,
          receivedDateTime,
        ) => {
          // TODO: remove logs in all environments before AIME release
          /* eslint-disable no-console*/
          console.log('*** Web Notification received ***');
          console.log('notificationType', notificationType);
          console.log('receivedDateTime', receivedDateTime);
          /* eslint-enable no-console*/
          setNotification({
            notificationType,
            correlationId,
            receivedDateTime,
          });
        },
      );

      // start connection
      /* c8 ignore next */
      connection
        .start()
        .then(function () {
          // eslint-disable-next-line no-console
          console.log('Connected to Web Notification Service (SignalR hub).');
        })
        .catch(function (err) {
          // eslint-disable-next-line no-console
          console.log(
            'Error connecting to Web Notification Service (SignalR hub): ',
            err,
          );
          // if signalR fails, clear tokens and redirect to sso to get new token
          if (process.env.NODE_ENV !== 'development') {
            // INFO: !!This won't work in Preview mode!!
            return oidcManager.signinRedirect({ state: window.location.href });
          }
        });
    })();
  }, [lenderDatabaseId, loanRecordId]);
  return notification;
};

export default useSignalR;
