const HOME_ALERTS_TYPES = {
  ATTACH_IS_EANDO_TO_SELECTED_ALERT: 'ATTACH_IS_EANDO_TO_SELECTED_ALERT',
  SET_ON_INIT: 'SET_ON_INIT',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_CLICKED_SELECTED_CATEGORY: 'SET_CLICKED_SELECTED_CATEGORY',
  SET_SELECTED_ALERT: 'SET_SELECTED_ALERT',
  SET_SELECTED_ALERT_PARENT: 'SET_SELECTED_ALERT_PARENT',
  SET_LATEST_SIGNAL_R_NOTIFICATION: 'SET_LATEST_SIGNAL_R_NOTIFICATION',
  SET_IS_UNDERWRITER: 'SET_IS_UNDERWRITER',
  SET_SHOULD_GET_NEXT_ALERT: 'SET_SHOULD_GET_NEXT_ALERT',
  SET_IS_LAST_ALERT: 'SET_IS_LAST_ALERT',
  SET_IS_BAIL_OUT: 'SET_IS_BAIL_OUT',
  SET_UNDERWRITER_VERIFIED_RULE: 'SET_UNDERWRITER_VERIFIED_RULE',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_IS_UNDERWRITER_CHECKBOX_DISABLED: 'SET_IS_UNDERWRITER_CHECKBOX_DISABLED',
  SET_IS_TOAST_OPEN: 'SET_IS_TOAST_OPEN',
  SET_IS_MENU_TOGGLE_COLLAPSED: 'SET_IS_MENU_TOGGLE_COLLAPSED',

  GET_GUTTER_ALERTS_STARTED: 'GET_GUTTER_ALERTS_STARTED',
  GET_GUTTER_ALERTS_SUCCESS: 'GET_GUTTER_ALERTS_SUCCESS',
  GET_GUTTER_ALERTS_ERROR: 'GET_GUTTER_ALERTS_ERROR',

  GET_NAVIGATION_ITEMS_STARTED: 'GET_NAVIGATION_ITEMS_STARTED',
  GET_NAVIGATION_ITEMS_SUCCESS: 'GET_NAVIGATION_ITEMS_SUCCESS',
  GET_NAVIGATION_ITEMS_ERROR: 'GET_NAVIGATION_ITEMS_ERROR',

  GET_BOLT_STATUS_STARTED: 'GET_BOLT_STATUS_STARTED',
  GET_BOLT_STATUS_SUCCESS: 'GET_BOLT_STATUS_SUCCESS',
  GET_BOLT_STATUS_ERROR: 'GET_BOLT_STATUS_ERROR',

  SUBMIT_TO_SETUP_STARTED: 'SUBMIT_TO_SETUP_STARTED',
  SUBMIT_TO_SETUP_SUCCESS: 'SUBMIT_TO_SETUP_SUCCESS',
  SUBMIT_TO_SETUP_ERROR: 'SUBMIT_TO_SETUP_ERROR',

  POST_TRIGGER_ALL_RULES_STARTED: 'POST_TRIGGER_ALL_RULES_STARTED',
  POST_TRIGGER_ALL_RULES_SUCCESS: 'POST_TRIGGER_ALL_RULES_SUCCESS',
  POST_TRIGGER_ALL_RULES_ERROR: 'POST_TRIGGER_ALL_RULES_ERROR',

  ADD_GUTTER_CALLBACK: 'ADD_GUTTER_CALLBACK',
  REMOVE_GUTTER_CALLBACK: 'REMOVE_GUTTER_CALLBACK',
  CLEAR_GUTTER_CALLBACKS: 'CLEAR_GUTTER_CALLBACKS',

  POST_GET_UNDERWRITER_VERIFICATION_STATUS_STARTED:
    'POST_GET_UNDERWRITER_VERIFICATION_STATUS_STARTED',
  POST_GET_UNDERWRITER_VERIFICATION_STATUS_SUCCESS:
    'POST_GET_UNDERWRITER_VERIFICATION_STATUS_SUCCESS',
  POST_GET_UNDERWRITER_VERIFICATION_STATUS_ERROR:
    'POST_GET_UNDERWRITER_VERiFICATION_STATUS_ERROR',

  POST_SAVE_UNDERWRITER_VERIFICATION_STARTED:
    'POST_SAVE_UNDERWRITER_VERIFICATION_STARTED',
  POST_SAVE_UNDERWRITER_VERIFICATION_SUCCESS:
    'POST_SAVE_UNDERWRITER_VERIFICATION_SUCCESS',
  POST_SAVE_UNDERWRITER_VERIFICATION_ERROR:
    'POST_SAVE_UNDERWRITER_VERIFICATION_ERROR',

  GET_LOAN_SUMMARY_STARTED: 'GET_LOAN_SUMMARY_STARTED',
  GET_LOAN_SUMMARY_SUCCESS: 'GET_LOAN_SUMMARY_SUCCESS',
  GET_LOAN_SUMMARY_ERROR: 'GET_LOAN_SUMMARY_ERROR',

  BAIL_OUT_AND_SUBMIT_TO_SETUP_STARTED: 'BAIL_OUT_AND_SUBMIT_TO_SETUP_STARTED',
  BAIL_OUT_AND_SUBMIT_TO_SETUP_SUCCESS: 'BAIL_OUT_AND_SUBMIT_TO_SETUP_SUCCESS',
  BAIL_OUT_AND_SUBMIT_TO_SETUP_ERROR: 'BAIL_OUT_AND_SUBMIT_TO_SETUP_ERROR',

  RETRIEVE_PDF_STARTED: 'RETRIEVE__PDF_STARTED',
  RETRIEVE_PDF_SUCCESS: 'RETRIEVE__PDF_SUCCESS',
  RETRIEVE_PDF_ERROR: 'RETRIEVE__PDF_ERROR',
  RETRIEVE_PDF_CLEAR: 'RETRIEVE__PDF_CLEAR',

  NEXT_ALERT: 'NEXT_ALERT',
};

export default HOME_ALERTS_TYPES;
