import { set, get, cloneDeep } from 'lodash';
import { PURCHASE_AGREEMENT_TYPES, ERROR_TYPES, INPUT_TYPES } from './types';
import { API_STATUS } from '../../../common/constants';
import { setPurchaseAgreementData } from '../model/PurchaseAgreementController';

const initialState = {
  status: null,
  error: null,
  formDataErrors: setPurchaseAgreementData(),
  formData: setPurchaseAgreementData(),
  fhaRequirementsUserAnswers: null,
  disableSave: false,
  inputEMD: '',
};

const purchaseAgreementReducer = (state = initialState, action) => {
  const stateCopy = cloneDeep(state);
  const inputName = action.payload?.name;
  let stateObjectPath = `formData.${inputName}`;
  if (inputName === 'inputEMD') {
    stateObjectPath = inputName;
  }

  switch (action.type) {
    case PURCHASE_AGREEMENT_TYPES.GET_PURCHASE_AGREEMENT_SUCCESS:
      return {
        ...state,
        formData: setPurchaseAgreementData(action.payload),
        status: action.status,
      };

    case PURCHASE_AGREEMENT_TYPES.RESET_STATUS: {
      return {
        ...state,
        status: null,
      };
    }

    case PURCHASE_AGREEMENT_TYPES.UPDATE_PURCHASE_AGREEMENT_SUCCESS:
      return {
        ...state,
        formData: setPurchaseAgreementData(action.payload),
        status: action.status,
      };

    case PURCHASE_AGREEMENT_TYPES.CALCULATE_ALLOCATED_SELLER_CONCESSIONS_SUCCESS: {
      set(
        stateCopy,
        'formData.sellerContribution.allocatedSellerConcessions',
        action.payload,
      );
      return stateCopy;
    }

    case PURCHASE_AGREEMENT_TYPES.CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_SUCCESS: {
      set(
        stateCopy,
        'formData.sellerContribution.allowableMaxSellerConcessions',
        action.payload,
      );
      return stateCopy;
    }

    case PURCHASE_AGREEMENT_TYPES.GET_PURCHASE_AGREEMENT_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };

    case PURCHASE_AGREEMENT_TYPES.UPDATE_PURCHASE_AGREEMENT_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
        error: null,
      };

    case PURCHASE_AGREEMENT_TYPES.CALCULATE_ALLOCATED_SELLER_CONCESSIONS_ERROR:
      return {
        ...state,
        error: action.error,
        status: null,
      };

    case PURCHASE_AGREEMENT_TYPES.CALCULATE_MAX_ALLOWABLE_SELLER_CONCESSIONS_ERROR:
      return {
        ...state,
        error: action.error,
        status: null,
      };

    case PURCHASE_AGREEMENT_TYPES.GET_PURCHASE_AGREEMENT_ERROR:
    case PURCHASE_AGREEMENT_TYPES.UPDATE_PURCHASE_AGREEMENT_ERROR: {
      return {
        ...state,
        error: action.error,
        status: null,
      };
    }

    case PURCHASE_AGREEMENT_TYPES.GET_FHA_REQUIREMENTS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case PURCHASE_AGREEMENT_TYPES.GET_FHA_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        fhaRequirementsUserAnswers: action.payload,
        status: API_STATUS.SUCCESS,
      };

    case PURCHASE_AGREEMENT_TYPES.GET_FHA_REQUIREMENTS_ERROR: {
      return {
        ...state,
        error: action.error,
        status: action.status,
      };
    }

    case PURCHASE_AGREEMENT_TYPES.UPDATE_FHA_REQUIREMENTS_STARTED:
      return {
        ...state,
        status: action.status,
        error: null,
      };
    case PURCHASE_AGREEMENT_TYPES.UPDATE_FHA_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        status: action.status,
      };

    case PURCHASE_AGREEMENT_TYPES.UPDATE_FHA_REQUIREMENTS_ERROR: {
      return {
        ...state,
        error: action.error,
        status: action.status,
      };
    }

    case INPUT_TYPES.STRING: {
      if (action.payload.value === undefined) action.payload.value = null;
      set(stateCopy, stateObjectPath, action.payload.value);
      return stateCopy;
    }
    case INPUT_TYPES.DATE: {
      set(stateCopy, stateObjectPath, action.payload.value);
      return stateCopy;
    }

    case INPUT_TYPES.CURRENCY: {
      const amountString = action?.payload?.value
        ?.toString()
        .replace(/[^0-9.]/g, '');

      let amountNumber = amountString === '' ? null : +amountString;

      set(stateCopy, stateObjectPath, amountNumber);

      return stateCopy;
    }
    case INPUT_TYPES.BOOLEAN: {
      let booleanValue = null;
      if (action.payload.value?.toString() === 'true') booleanValue = true;
      if (action.payload.value?.toString() === 'false') booleanValue = false;
      set(stateCopy, stateObjectPath, booleanValue);

      return stateCopy;
    }
    case INPUT_TYPES.NUMBER: {
      let numberValue = Number(action.payload.value);
      set(stateCopy, stateObjectPath, numberValue);
      return stateCopy;
    }

    case INPUT_TYPES.UPDATE_EMD_FIELD: {
      const currentArray = get(stateCopy, 'formData.assets') || [];

      const copyObj = {
        assetId: 0,
        assetLdId: 0,
        customerLenderDatabaseId: get(
          stateCopy,
          'formData.borrowers[0].lenderdatabaseId',
        ),
        customerRecordId: get(
          stateCopy,
          'formData.borrowers[0].customerRecordId',
        ),
        isLiquid: true,
        verifiedDate: null,
        balance: Number(action.payload),
        accountNumber: null,
        addressLine1: null,
        addressLine2: null,
        assetType: 'Earnest Money Deposi',
        bankName: null,
        city: null,
        country: null,
        faceValue: 0,
        fhaSource: 0,
        giftDepositedDuFlag: 0,
        giftSourceId: 0,
        includeBalance: null,
        sellerFundDap: null,
        sendToAus: null,
        sourceDesc: null,
        sourceEin: null,
        state: null,
        verified: null,
        zipCode: null,
      };

      set(stateCopy, 'formData.assets', [...currentArray, copyObj]);
      set(stateCopy, 'inputEMD', '');
      return stateCopy;
    }

    case ERROR_TYPES.RESET:
      return { ...state, formDataErrors: setPurchaseAgreementData() };

    case ERROR_TYPES.UPDATE: {
      stateCopy.formDataErrors = setPurchaseAgreementData();
      action.payload.errors?.forEach((error) => {
        let errorSplit = error.split(' ');
        set(
          stateCopy.formDataErrors,
          errorSplit[0],
          errorSplit.slice(1).join(' '),
        );
      });

      return { ...state, formDataErrors: stateCopy.formDataErrors };
    }

    default:
      return state;
  }
};

export default purchaseAgreementReducer;
