import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { formatCurrency } from 'common/util/format';

export default function PaystubYTD({
  fiscalYear,
  currentYearIncome = [],
  variableTitle,
}) {
  return (
    <Box mb={5}>
      <Text
        mr={1}
        pb={4}
        fontSize="md"
        fontWeight="bold"
        textDecoration="underline"
        data-testid={'paystub-fiscalyear'}
      >
        Paystub ({fiscalYear})
      </Text>

      <Flex alignItems={'flex-end'}>
        <Box>
          <Text mr={6} fontSize="md">
            Paystub Total
          </Text>
          <Text mr={6} fontSize="md">
            Monthly
          </Text>
          {variableTitle && (
            <Text mr={6} fontSize="md" data-testid="variable-title">
              {variableTitle}
            </Text>
          )}
        </Box>

        {currentYearIncome.length > 0 &&
          currentYearIncome.map(
            (
              {
                incomeType,
                monthlyAmount,
                annualIncome,
                totalYearToDateIncome,
              },
              idx,
            ) => (
              <Box key={idx} mr={6} data-testid="paystub-items">
                <Text mr={1} fontSize="md" fontWeight="bold">
                  {incomeType}
                </Text>
                <Text fontSize="md" d="inline">
                  {formatCurrency(totalYearToDateIncome)}
                </Text>
                <Text fontSize="md">{formatCurrency(monthlyAmount)}</Text>
                {variableTitle && (
                  <Text fontSize="md">{formatCurrency(annualIncome)}</Text>
                )}
              </Box>
            ),
          )}
      </Flex>
    </Box>
  );
}
PaystubYTD.propTypes = {
  fiscalYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  variableTitle: PropTypes.string.isRequired,
  currentYearIncome: PropTypes.arrayOf(
    PropTypes.shape({
      incomeType: PropTypes.string,
      annualIncome: PropTypes.number,
      monthlyAmount: PropTypes.number,
      totalYearToDateIncome: PropTypes.number,
    }),
  ),
};
