// External Dependencies
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

//Local Components
import { concatenateObjectAttributes } from 'common/components/CrudGrid/crudGridUtils';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import FormBuilder from 'pages/Loan1003DataGrids/common/components/FormBuilder';
import AssetFieldArray from './AssetFieldArray';
import {
  ASSET_TYPE,
  DONOR_RELATIONSHIP_TYPE,
  GIFT_SOURCE_TYPE,
} from '../../common/components/asset1003Constants';
import Loan1003FormContainer from './../../common/components/Loan1003FormContainer';
import Loan1003FormSection from './../../common/components/Loan1003FormSection';
import Loan1003FormBuilderWrapper from 'pages/Loan1003DataGrids/common/components/Loan1003FormBuilderWrapper';
import { reverseFormatCurrency, formatCurrency } from 'common/util/format';

// Utility Functions
const createToast = (title, description, status) => ({
  title,
  description,
  status,
  duration: 5000,
  isClosable: false,
});

const transformDataToSaveObject = (
  originalData,
  modifiedData,
  obfuscatedIds,
  rowToEdit,
  isNewAssetVisible,
) => ({
  obfuscatedLoanIdentifier: {
    obfuscatedLenderDatabaseId: obfuscatedIds.obfuscatedLenderDatabaseId,
    obfuscatedLoanRecordId: obfuscatedIds.obfuscatedLoanRecordId,
  },
  assetCustomerData: {
    ...modifiedData,
    assetId: rowToEdit.assetId || 0,
    assetLdId: isNewAssetVisible ? 0 : 1,
    lenderDatabaseId: 1,
    customerRecordIds: originalData.borrowersAssigned?.map(
      (borrower) => borrower.customerRecordId,
    ),
    giftSourceId: rowToEdit.giftSourceId || null,
    giftInformation: {
      donorName: originalData.donorName,
      donorRelationship: originalData.donorRelationship,
      isEarnestMoneyDepositAGift: originalData.isEarnestMoneyDepositAGift,
    },
    sendToAus: 'Y',
    balance:
      rowToEdit.balance === originalData.balance ? null : originalData.balance,
    additionalReserves: rowToEdit.additionalReserves,
  },
});
const getDefaultValues = (rowToEdit) => {
  return {
    borrowersAssigned: rowToEdit.borrowers,
    assetType: rowToEdit.assetType,
    bankName: rowToEdit.bankName,
    accountNumber: rowToEdit.accountNumber,
    balance: rowToEdit.balance,
    nextLargestDeposit: rowToEdit.nextLargestDeposit,
    comment: rowToEdit.comment ? rowToEdit.comment : '',
    documentAssociationDetails: rowToEdit.documentAssociationDetails?.map(
      (item) => {
        return {
          userResponses: {
            statementStartDate: item.userResponses.statementStartDate,
            statementEndDate: item.userResponses.statementEndDate,
            statementNumberOfDays: item.userResponses.statementNumberOfDays,
          },
        };
      },
    ),
    donorRelationship: rowToEdit.giftInformation?.donorRelationship || '',
    donorName: rowToEdit.giftInformation?.donorName || '',
    isEarnestMoneyDepositAGift:
      rowToEdit.giftInformation?.isEarnestMoneyDepositAGift,
    giftSourceId: GIFT_SOURCE_TYPE[rowToEdit.giftSourceId],
    additionalReserves: rowToEdit.additionalReserves,
  };
};
export default function AssetSummariesForm({
  rowToEdit,
  onClose,
  onSave,
  allBorrowerOptions,
  isWriteAccessDisabled,
  obfuscatedIds,
  handleToastMessage,
  addBlankForm,
  setAddBlankForm,
  addAdditionalReserves,
  productType,
  assetsInfo,
  isUnderwriter,
  isExpectedAssetsVisible,
}) {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    isLoading: false,
    showErrors: false,
    isNewAssetVisible: false,
  });
  const [assetTypeOptions, setAssetTypeOptions] = useState(ASSET_TYPE);

  const doesBankStatementExist = rowToEdit.documentAssociationDetails?.some(
    (detail) =>
      detail.userResponses.statementStartDate &&
      detail.userResponses.statementEndDate,
  );

  // Check if the user has answered the EMD, Cash Deposit, Net Proceeds questions
  const isEMDCashDepositNetProceedsQuestionsAnswered =
    rowToEdit?.userResponses?.emdSourceOption !== undefined;

  const isNextLargestDepositVisible = rowToEdit.nextLargestDeposit !== null;

  function isEMDCashDepositNetProceeds(value) {
    const values = ['Earnest Money Deposit', 'Cash Deposit', 'Net Proceeds'];
    return values.includes(value);
  }

  function shouldPullNameFromAssetType(value) {
    const values = [
      'Earnest Money Deposit',
      'Cash Deposit',
      'Net Proceeds',
      'Gift',
      'Gift of Equity',
      'Grant',
    ];
    return values.includes(value);
  }

  function isGrant(value) {
    const values = ['Grant'];
    return values.includes(value);
  }

  function isGiftOrGiftOfEquity(value) {
    const values = ['Gift', 'Gift of Equity'];
    return values.includes(value);
  }

  function isGrantOrGiftOrGiftOfEquity(value) {
    const values = ['Grant', 'Gift', 'Gift of Equity'];

    if (value === 'Grant') {
      return isGrantProperFilledOut();
    }

    return values.includes(value);
  }

  function isExpectedAssets(value) {
    return value === 'Expected Assets';
  }

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: getDefaultValues(rowToEdit),
    mode: 'onBlur',
  });
  register('borrowersAssigned', {
    validate: (value) => addAdditionalReserves || value.length > 0,
  });
  register('assetType', {
    validate: (value) => addAdditionalReserves || value.length > 0,
  });
  register('bankName');
  register('accountNumber');
  register('balance', {
    validate: (value) => addAdditionalReserves || Number(value) > 0,
  });
  register('donorRelationship');
  register('giftSourceId');
  register('nextLargestDeposit');
  register('comment');
  register('donorName');
  register('isEarnestMoneyDepositAGift');
  register('additionalReserves', {
    required: !!(addAdditionalReserves && !assetsInfo?.additionalReserves),
  });

  const watchAll = watch();
  const assetType = getValues('assetType');
  const bankName = getValues('bankName');
  const isGrantProperFilledOut = () => {
    const isGrantResult = isGrant(assetType);
    const giftSourceId = rowToEdit?.giftSourceId;

    return isGrantResult && giftSourceId != null;
  };
  const shouldPullAssetTypeName = shouldPullNameFromAssetType(assetType);
  const isGrantFilled = isGrantProperFilledOut();
  const isDonorInformationLocked =
    isGiftOrGiftOfEquity(assetType) &&
    getValues('donorName') !== '' &&
    !formState.isNewAssetVisible;

  useEffect(() => {
    reset(getDefaultValues(rowToEdit));
    setAddBlankForm(false);
    setFormState((prevState) => ({ ...prevState, isNewAssetVisible: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit]);

  const handleAssetTypeChange = (value) => {
    if (isEMDCashDepositNetProceeds(value)) {
      setValue('bankName', value);
      setValue('accountNumber', '');
    } else {
      setValue('bankName', '');
      setValue('accountNumber', '');
    }
  };

  const handleChange = (field, value) => {
    setValue(field, value);
    trigger();
    switch (field) {
      case 'assetType':
        handleAssetTypeChange(value);
        break;
      default:
        break;
    }
  };

  const entityLabel = isGrant(assetType) ? 'Entity Name' : 'Bank Name';

  const sourceLabel =
    rowToEdit?.giftSourceId !== null && isGrant(assetType)
      ? 'Grant Source'
      : 'Gift Source';

  const handleBorrowerNameChange = (customerId) => {
    clearErrors('borrowersAssigned');
    const borrowersAssigned = getValues('borrowersAssigned') || [];
    const updatedBorrowers = borrowersAssigned.some(
      (borrower) => borrower.customerRecordId === customerId,
    )
      ? borrowersAssigned.filter(
          (borrower) => borrower.customerRecordId !== customerId,
        )
      : [
          ...borrowersAssigned,
          allBorrowerOptions.find(
            (borrower) => borrower.customerRecordId === customerId,
          ),
        ];
    setValue('borrowersAssigned', updatedBorrowers);
  };

  // If the asset type is EMD, Cash Deposit, Net Proceeds, Gift or Gift of Equity, Grant & the bankName is current empty, set the bank name to the asset type
  if (
    shouldPullAssetTypeName &&
    bankName === '' &&
    !isGrantFilled &&
    bankName !== assetType
  ) {
    setValue('bankName', assetType);
  } else if (
    isGrantFilled &&
    assetType === 'Grant' &&
    bankName !== rowToEdit.bankName
  ) {
    setValue('bankName', rowToEdit.bankName);
  }

  const onCancelClicked = () => {
    reset();
    onClose();
    setAddBlankForm(false);
  };

  // This is used to reset the form when the Add Asset button is clicked
  useEffect(() => {
    if (addBlankForm) {
      setFormState((prevState) => ({ ...prevState, isNewAssetVisible: true }));
      reset({
        borrowersAssigned: [],
        assetType: '',
        bankName: '',
        accountNumber: '',
        balance: '',
        donorRelationship: '',
        nextLargestDeposit: '',
        comment: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBlankForm]);

  const makeUpsertCall = (saveObject) => {
    apiClient
      .post(apiEndPoints.SAVE_ASSET_SUMMARY, saveObject)
      .then((res) => {
        if (res) {
          setFormState((prevState) => ({ ...prevState, isLoading: false }));
          onSave();
          handleToastMessage(
            createToast('Success', 'Saved Successfully', 'success'),
          );
          setAddBlankForm(false);
          setFormState((prevState) => ({
            ...prevState,
            isNewAssetVisible: false,
          }));
          dispatch(setLoanSummaryUpsertOrDelete(true));
        }
      })
      .catch(() => {
        setFormState((prevState) => ({ ...prevState, isLoading: false }));
        handleToastMessage(
          createToast(
            'Save Failed',
            'Record was not able to be saved.',
            'error',
          ),
        );
      });
  };

  const onSubmit = (data) => {
    setFormState((prevState) => ({ ...prevState, isLoading: true }));

    // Create a copy of data and delete the borrowersAssigned property
    const dataCopy = { ...data };
    delete dataCopy.borrowersAssigned;
    delete dataCopy.donorName;
    delete dataCopy.donorRelationship;
    delete dataCopy.isEarnestMoneyDepositAGift;

    const saveObject = transformDataToSaveObject(
      data,
      dataCopy,
      obfuscatedIds,
      rowToEdit,
      formState.isNewAssetVisible,
    );

    makeUpsertCall(saveObject);
  };

  const makeUpsertReservesCall = (
    obfuscatedLoanIdentifier,
    additionalReserves,
    productType,
  ) => {
    const payload = {
      obfuscatedLoanIdentifier,
      additionalReserves,
      productType,
    };

    apiClient
      .post(apiEndPoints.UPDATE_ADDITIONAL_RESERVES, payload)
      .then((res) => {
        if (res) {
          setFormState((prevState) => ({ ...prevState, isLoading: false }));
          onSave();
          setAddBlankForm(false);
          handleToastMessage(
            createToast('Success', 'Saved Successfully', 'success'),
          );
        }
      })
      .catch(() => {
        setFormState((prevState) => ({ ...prevState, isLoading: false }));
        handleToastMessage(
          createToast(
            'Save Failed',
            'Record was not able to be saved.',
            'error',
          ),
        );
      });
  };

  const onReservesSubmit = (data) => {
    setFormState((prevState) => ({ ...prevState, isLoading: true }));

    const savedData =
      data.additionalReserves == null && assetsInfo.additionalReserves > 0
        ? assetsInfo.additionalReserves
        : data.additionalReserves;

    makeUpsertReservesCall(obfuscatedIds, savedData, productType);
  };

  const assetTypeOptionsWithoutExpectedAssets = ASSET_TYPE.filter(
    (x) => x.value !== 'Expected Assets',
  );

  useEffect(() => {
    if (isUnderwriter && isExpectedAssetsVisible) {
      setAssetTypeOptions(ASSET_TYPE);
    } else {
      setAssetTypeOptions(assetTypeOptionsWithoutExpectedAssets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnderwriter, isExpectedAssetsVisible]);

  const assetInformationFields = [
    {
      type: 'checkboxGroup',
      name: 'borrowersAssigned',
      value: 'borrowersAssigned',
      label: 'Associated Borrower(s)',
      isRequired: true,
      dataTestId: 'borrowersAssigned',
      disabled: doesBankStatementExist,
      readOnly: doesBankStatementExist,
      span: 4,
      options: allBorrowerOptions?.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      isChecked: (option) => {
        return getValues('borrowersAssigned')?.some(
          (borrower) => borrower.customerRecordId === option.value,
        );
      },
      onChangeHandler: (value) => {
        handleBorrowerNameChange(value.value);
      },
    },
    {
      name: 'assetType',
      type: 'select',
      label: 'Asset Type',
      isRequired: true,
      placeholder: 'Select option',
      colStart: 1,
      value: 'assetType',
      dataTestId: 'assetType',
      optionsAttributes: {
        optionsLabelName: 'label',
        optionsValueName: 'value',
      },
      onChangeHandler: (value) => {
        handleChange('assetType', value);
      },
      options: assetTypeOptions.map((asset) => {
        return {
          key: asset.value,
          label: asset.label,
        };
      }),
    },
    {
      name: 'bankName',
      type: 'text',
      label: entityLabel,
      isHidden: () =>
        (!isGrantProperFilledOut() && isGrant(assetType)) ||
        isEMDCashDepositNetProceeds(assetType) ||
        isGiftOrGiftOfEquity(assetType) ||
        (isGrant(assetType) && formState.isNewAssetVisible) ||
        isExpectedAssets(assetType),
      value: 'bankName',
      placeholder: entityLabel,
      dataTestId: 'bankName',
      disabled: doesBankStatementExist || isGrantProperFilledOut(),
      readOnly: doesBankStatementExist,
      onChangeHandler: (e) => handleChange('bankName', e.target.value),
    },
    {
      name: 'accountNumber',
      type: 'text',
      label: 'Account Number',
      isHidden: () =>
        isEMDCashDepositNetProceeds(assetType) ||
        isGiftOrGiftOfEquity(assetType) ||
        isGrant(assetType) ||
        isExpectedAssets(assetType),
      dataTestId: 'accountNumber',
      value: 'accountNumber',
      placeholder: 'Account Number',
      disabled: doesBankStatementExist,
      readOnly: doesBankStatementExist,
      onChangeHandler: (e) => handleChange('accountNumber', e.target.value),
    },
    {
      name: 'balance',
      type: 'currency',
      label: 'Balance',
      dataTestId: 'balance',
      value: 'balance',
      placeholder: 'Balance',
      disabled:
        doesBankStatementExist ||
        isGrantProperFilledOut() ||
        isDonorInformationLocked ||
        (isEMDCashDepositNetProceedsQuestionsAnswered &&
          isEMDCashDepositNetProceeds(assetType)),
      readOnly:
        doesBankStatementExist ||
        isDonorInformationLocked ||
        (isEMDCashDepositNetProceedsQuestionsAnswered &&
          isEMDCashDepositNetProceeds(assetType)),
      onChangeHandler: (value) =>
        handleChange('balance', reverseFormatCurrency(value)),
    },
  ];
  const donorInformationFields = [
    {
      name: 'donorRelationship',
      type: 'select',
      label: 'Donor Relationship',
      isHidden: () => !isGiftOrGiftOfEquity(assetType),
      placeholder: 'Select option',
      colStart: 1,
      value: 'donorRelationship',
      dataTestId: 'donorRelationship',
      disabled: isDonorInformationLocked,
      readOnly: isDonorInformationLocked,
      optionsAttributes: {
        optionsLabelName: 'label',
        optionsValueName: 'value',
      },
      onChangeHandler: (value) => {
        handleChange('donorRelationship', value);
        if (isGiftOrGiftOfEquity(value)) {
          setValue('bankName', '');
          setValue('accountNumber', '');
          setValue('giftInformation', {
            ...getValues('giftInformation'),
            donorRelationship: value,
          });
        }
      },
      options: DONOR_RELATIONSHIP_TYPE.map((asset) => {
        return {
          key: asset.value,
          label: asset.label,
        };
      }),
    },
    {
      name: 'giftSourceId',
      type: 'text',
      label: sourceLabel,
      isHidden: () =>
        !isGrantOrGiftOrGiftOfEquity(assetType) ||
        formState.isNewAssetVisible ||
        GIFT_SOURCE_TYPE[rowToEdit.giftSourceId] === undefined,
      placeholder: sourceLabel,
      value: GIFT_SOURCE_TYPE[getValues('giftSourceId')],
      dataTestId: 'giftSourceId',
      disabled: true,
      readOnly: true,
      onChangeHandler: (e) => handleChange('giftSourceId', e.target.value),
    },
    {
      name: 'donorName',
      type: 'text',
      label: 'Donor Name',
      value: 'donorName',
      isHidden: () =>
        !isGiftOrGiftOfEquity(assetType) ||
        formState.isNewAssetVisible ||
        !isDonorInformationLocked,
      placeholder: 'Donor Name',
      dataTestId: 'donorName',
      disabled: true,
      readOnly: true,
      onChangeHandler: (e) => handleChange('donorName', e.target.value),
    },
  ];

  const loanInfoFields = [
    {
      name: 'nextLargestDeposit',
      type: 'currency',
      dataTestId: 'nextLargestDeposit',
      colStart: 1,
      label: 'Next Largest Deposit',
      isHidden: () => !isNextLargestDepositVisible,
      disabled: doesBankStatementExist,
      readOnly: doesBankStatementExist,
      value: 'nextLargeDeposit',
      onChangeHandler: (value) => handleChange('nextLargeDeposit', value),
    },
    {
      type: 'textarea',
      name: 'comment',
      label: 'Comments',
      dataTestId: 'comment',
      value: 'comment',
      size: 'sm',
      placeholder: 'Comments',
      span: 4,
      onChangeHandler: (e) => handleChange('comment', e.target.value),
    },
  ];

  const additionalReservesFields = [
    {
      name: 'additionalReserves',
      type: 'currency',
      dataTestId: 'additionalReserves',
      colStart: 1,
      label: 'Additional Reserves',
      value: formatCurrency(assetsInfo?.additionalReserves),
      placeholder: 'Additional Reserves',
      onChangeHandler: (value) => handleChange('additionalReserves', value),
    },
  ];

  const assetInformationFormDef = {
    fields: assetInformationFields,
    columns: 4,
  };

  const donorInformationFormDef = {
    fields: donorInformationFields,
    columns: 4,
  };

  const loanInfoFormDef = {
    fields: loanInfoFields,
    columns: 4,
  };

  const additionalReservesFormDef = {
    fields: additionalReservesFields,
    columns: 4,
  };

  return (
    <Loan1003FormContainer
      isLoading={formState.isLoading}
      onCloseClicked={onCancelClicked}
      toggleErrors={() =>
        setFormState((prevState) => ({ ...prevState, showErrors: true }))
      }
      onSaveClicked={handleSubmit(
        addAdditionalReserves ? onReservesSubmit : onSubmit,
      )}
      readOnlyMode={isWriteAccessDisabled}
    >
      {addAdditionalReserves && (
        <Loan1003FormSection title="Reserve Information">
          <Loan1003FormBuilderWrapper>
            <FormBuilder
              fields={additionalReservesFields}
              formDef={additionalReservesFormDef}
              showErrors={formState.showErrors}
              readOnlyMode={isWriteAccessDisabled}
              errors={errors}
              rowGap={'1rem'}
              getValues={getValues}
            />
          </Loan1003FormBuilderWrapper>
        </Loan1003FormSection>
      )}
      {!addAdditionalReserves && (
        <>
          <Loan1003FormSection title="Asset Information">
            <Loan1003FormBuilderWrapper>
              <FormBuilder
                fields={assetInformationFields}
                formDef={assetInformationFormDef}
                showErrors={formState.showErrors}
                readOnlyMode={isWriteAccessDisabled}
                errors={errors}
                rowGap={'1rem'}
                getValues={getValues}
              />
              {doesBankStatementExist && (
                <AssetFieldArray
                  handleChange={handleChange}
                  control={control}
                  trigger={trigger}
                  getValues={getValues}
                  watchAll={watchAll}
                  errors={errors}
                  clearErrors={clearErrors}
                  readOnlyMode={true}
                  showErrors={formState.showErrors}
                  setValue={setValue}
                />
              )}
            </Loan1003FormBuilderWrapper>
          </Loan1003FormSection>
          {isGrantOrGiftOrGiftOfEquity(assetType) &&
            !formState.isNewAssetVisible && (
              <Loan1003FormSection title="Donor Information">
                <Loan1003FormBuilderWrapper>
                  <FormBuilder
                    fields={donorInformationFields}
                    formDef={donorInformationFormDef}
                    errors={errors}
                    readOnlyMode={isWriteAccessDisabled}
                    showErrors={formState.showErrors}
                    getValues={getValues}
                  />
                </Loan1003FormBuilderWrapper>
              </Loan1003FormSection>
            )}
          <Loan1003FormSection title="Loan Information">
            <Loan1003FormBuilderWrapper>
              <FormBuilder
                fields={loanInfoFields}
                formDef={loanInfoFormDef}
                errors={errors}
                readOnlyMode={isWriteAccessDisabled}
                showErrors={formState.showErrors}
                getValues={getValues}
              />
            </Loan1003FormBuilderWrapper>
          </Loan1003FormSection>
        </>
      )}
    </Loan1003FormContainer>
  );
}
AssetSummariesForm.propTypes = {
  rowToEdit: PropTypes.object,
  addNewIncomeTypeRow: PropTypes.func,
  deleteIncomeTypeRow: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isEdit: PropTypes.bool,
  allBorrowerOptions: PropTypes.any,
  isWriteAccessDisabled: PropTypes.bool,
  obfuscatedIds: PropTypes.any,
  handleToastMessage: PropTypes.func,
  addBlankForm: PropTypes.bool,
  setAddBlankForm: PropTypes.func,
  addAdditionalReserves: PropTypes.bool,
  productType: PropTypes.any,
  assetsInfo: PropTypes.object,
  isUnderwriter: PropTypes.bool,
  isExpectedAssetsVisible: PropTypes.bool,
};
