import { FormulaType } from 'pages/IncomeCalculator/enums';
import lang from '../language.json';
import React from 'react';
import PropTypes from 'prop-types';
import { pixelsToRem } from 'theme/typography';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';

const BaseFormulas = ({ formulas }) => {
  const capitalGainsFormulas = [
    FormulaType.CapitalGainForm1040ThreeYear,
    FormulaType.CapitalGainForm1040TwoYear,
    FormulaType.CapitalGainForm1040OneYear,
  ];

  return (
    <Box pb={4}>
      <Flex alignItems="center">
        <Text mr={1} fontSize={pixelsToRem(14)} fontWeight="bold" d="inline">
          Calculation Equations:
        </Text>
      </Flex>
      {formulas
        .filter((f) => capitalGainsFormulas.includes(f.formulaId))
        .map(({ formulaId }) => (
          <Flex alignItems="center" key={formulaId}>
            <Text
              fontSize={pixelsToRem(14)}
              d="inline"
              data-testid="monthly-income-equation"
            >
              {lang.FORMULAS[formulaId]}
            </Text>
          </Flex>
        ))}
    </Box>
  );
};
BaseFormulas.propTypes = {
  formulas: PropTypes.array.isRequired,
};
export default BaseFormulas;
