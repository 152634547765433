import { useQuery } from '@tanstack/react-query';
import apiClient from 'common/util/api-client';
import { useSelector } from 'react-redux';
import apiEndPoints from 'common/constants/api-endpoints';

export function useGetAssetAuditHistory(assetId) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getAssetAuditHistoryData', assetId],
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndPoints.auditHistory.GET_ASSET_AUDIT_HISTORY,
        {
          obfuscatedLoanIdentifier,
          assetId: +assetId,
        },
      );

      // this is subject to change, for the POC/Prototype this seemed to be good enough
      return data.assetDocument;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetAuditHistorySummary(pageEnum) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getAuditHistorySummaryData', pageEnum],
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndPoints.auditHistory.GET_BOLT_AUDIT_1003,
        {
          obfuscatedLoanIdentifier,
          page: pageEnum,
        },
      );

      // this is subject to change, for the POC/Prototype this seemed to be good enough
      return data;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetAuditHistory(pageEnum, assetId, entityEnum) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );

  return useQuery({
    queryKey: ['getAuditHistoryData'],
    queryFn: async () => {
      const requestBody = {
        obfuscatedLoanIdentifier,
        page: pageEnum,
      };

      if (entityEnum) {
        requestBody.entityReferenceAssociations = [
          {
            entity: entityEnum,
            references: {
              assetRecordId: assetId,
            },
          },
        ];
      }

      const { data } = await apiClient.post(
        apiEndPoints.auditHistory.GET_AUDIT_HISTORY_DATA,
        requestBody,
      );

      // this is subject to change, for the POC/Prototype this seemed to be good enough
      return data.entitiesAudit;
    },
    refetchOnWindowFocus: false,
  });
}
