import apiClient from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export const useGetLoanSummaryData = () => {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getLoanSummaryData'],
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndpoints.core.GET_LOAN_SUMMARY_V2,
        obfuscatedLoanIdentifier,
      );
      return data;
    },
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export default useGetLoanSummaryData;
