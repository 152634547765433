import PropTypes from 'prop-types';
import Modal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalBody from 'common/components/Modals/ModalBody';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import Button from 'common/components/Buttons/Button/index';
import Center from 'common/components/Center';
import Flex from 'common/components/Flex';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ALERT_SEVERITY } from 'common/constants';
import lang from './language.json';

function ModalIcon({ severity }) {
  const commonStyle = { marginBottom: '8px', fontSize: '32px' };

  switch (severity) {
    case ALERT_SEVERITY.WARNING:
      return (
        <Box
          as="span"
          sx={commonStyle}
          color="orange.300"
          id="bailout-warning-icon"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Box>
      );
    case ALERT_SEVERITY.ALERT:
      return (
        <Box as="span" sx={commonStyle} color="red.500" id="bailout-error-icon">
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Box>
      );
    default:
      return null;
  }
}
ModalIcon.propTypes = {
  severity: PropTypes.number,
};

function ModalComponent({ isOpen, onClose, children, footer }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginTop={350}>
        <ModalCloseButton />
        <ModalBody mt="24px">{children}</ModalBody>
        <ModalFooter display="flex" justifyContent="center" pb="2rem">
          <Flex justifyContent="space-between" gridGap="12px">
            {footer}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
ModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  footer: PropTypes.node.isRequired,
};

export default function AlertOkModal({
  isOpen,
  handleClose,
  handleOk,
  loading,
  data,
}) {
  if (!data) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      footer={
        <Button
          backgroundColor="blue.600"
          color="white"
          _hover={{
            backgroundColor: 'blue.500',
          }}
          onClick={handleOk || handleClose}
          isLoading={loading}
        >
          {lang.OK_BUTTON}
        </Button>
      }
    >
      <Center>
        <ModalIcon severity={data.severity} />
      </Center>
      <Text as="h3" textAlign="center" mb="16px">
        {data.title}
      </Text>
      <Text as="p" fontSize="14px" textAlign="center" lineHeight="1.5rem">
        {data.description}
      </Text>
    </ModalComponent>
  );
}

AlertOkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOk: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    severity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};
