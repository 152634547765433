import { useState, useRef, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import InputGroup from 'common/components/Inputs/InputGroup';
import InputRightElement from 'common/components/Inputs/InputRightElement';
import FormControl from 'common/components/Forms/FormControl';
import FormErrorMessage from 'common/components/Forms/FormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

/*
 * forwardRef(component) will allow us to pass a ref through
 *   the component using the standard ref prop.
 * The ref prop passed in the parent doesn't show up in the
 *   props of a component, but instead comes through as a
 *   secind prop to the component
 *   MyComponent = forwardRef((props,ref)={})
 * This ref can be passed down to any child component
 */
const DatePicker = forwardRef(
  (
    {
      value = '',
      onChange,
      placement,
      isClearable = false,
      showPopperArrow = false,
      isRequiredField = false,
      name,
      maxDate,
      minDate,
      hasIcon = true,
      handleCalendarClose = null,
      showMonthDropdown,
      showYearDropdown,
      dropdownMode,
      isInvalid = false,
      onBlur,
      errorMessage,
      disableInvalidCheck = false,
      disabled,
      ...props
    },
    ref,
  ) => {
    let className = '';
    const [isEmptyError, setEmptyError] = useState(false);
    const [isDateField, setDateField] = useState(value);

    useEffect(() => {
      if (isInvalid && !disableInvalidCheck) {
        onChange?.();
        onBlur?.();
        nullCheck();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInvalid]);

    const localRef = useRef(null);
    const datePickerRef = ref ?? localRef;
    const onIconClick = () => datePickerRef.current.onInputClick();

    /* c8 ignore next */
    const nullCheck = () => {
      const empty = !isDateField;
      setEmptyError(empty && isRequiredField);
      if (handleCalendarClose) handleCalendarClose();
    };

    /* c8 ignore next */
    if (isInvalid || (isRequiredField && isEmptyError)) {
      className = 'is-invalid';
    }

    const selectedDate = value ? new Date(value) : null;

    // when the ‘/’ key is hit, prevent default keypress.
    const formatDate = (e) => {
      !/^[0-9]$/.test(e.key) && e.preventDefault();
      const input = e.target;
      const inputLength = input.value.length;
      if (inputLength !== 1 || inputLength !== 3) {
        if (e.charCode === 47) e.preventDefault();
      }
      if (inputLength === 2 || inputLength === 5) input.value += '/';
    };

    return (
      <FormControl
        id={name}
        isInvalid={isInvalid || isEmptyError}
        className={className}
      >
        <InputGroup>
          <ReactDatePicker
            ref={datePickerRef}
            selected={selectedDate}
            customInput={
              <input type="text" onKeyPress={formatDate} disabled={disabled} />
            }
            onChange={(e) => {
              setDateField(e);
              onChange?.(e);
              if (onBlur) {
                onBlur();
              }
            }}
            popperPlacement={placement}
            isClearable={isClearable}
            showPopperArrow={showPopperArrow}
            {...props}
            maxDate={maxDate}
            onCalendarClose={nullCheck}
            minDate={minDate}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode={dropdownMode}
            data-testid={'react-datepicker'}
            disabled={disabled}
          />
          {hasIcon && (
            <InputRightElement
              data-testid={'react-datepicker-input-right-element'}
              onClick={() => onIconClick()}
            >
              <FontAwesomeIcon icon={faCalendarAlt} />
            </InputRightElement>
          )}
        </InputGroup>

        {isEmptyError && (
          <FormErrorMessage>This field is required</FormErrorMessage>
        )}

        {isInvalid && !isEmptyError && errorMessage && (
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  /** Whether or not to show a clear button*/
  isClearable: PropTypes.bool,
  /** A function to be called when the value is updated. */
  onChange: PropTypes.func,
  /** The current value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Choose where the date popover gets placed with respect to the text field. */
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
    'right-start',
    'right-end',
  ]),
  /** True to show an arrow pointing to the input field.*/
  showPopperArrow: PropTypes.bool,
  isRequiredField: PropTypes.bool,
  name: PropTypes.string,
  /** The earliest date that can be selected.*/
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** The latest date that can be selected. */
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Whether or not to display the calendar icon. */
  hasIcon: PropTypes.bool,
  /** Whether or not to show a dropdown for the month. */
  handleCalendarClose: PropTypes.func,
  /** A function to be called when the calendar closes. */
  showMonthDropdown: PropTypes.bool,
  /** Whether or not to show a dropdown for the year. */
  showYearDropdown: PropTypes.bool,
  /** The dropdown mode for month/year dropdowns.*/
  dropdownMode: PropTypes.oneOf(['select']),
  /** When disabledInvalidCheck is true the useEffect that fires the handlers continuously won't fire, but defaults to false so as to not break existing functionality*/
  disableInvalidCheck: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
