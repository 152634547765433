﻿import {
  CUSTOMER_EMPLOYMENT_TYPES,
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES,
  UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES,
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES,
  PAY_STUB_TYPES,
  W2_TYPES,
  GET_ASSOC_INCOMES_TYPES,
  WVOE_TYPES,
  SET_LOAN_NUMBER,
  CALCULATION_TYPES,
  CALCULATION_HISTORY_TYPES,
  SAVE_SELECTED_INCOME,
  RESET_SELECTED_INCOME,
  SAVE_CALC_TYPES,
  EMPLOYER_DOCUMENT_TYPES,
  SAVE_INCOME_DOCUMENTS_TYPES,
  SAVE_INCOME_ANSWER_TYPES,
  SAVE_VALIDATE_DOCUMENTS_TYPES,
  SET_PAYSTUB_ADDRESS_ALERT,
  SET_W2_ADDRESS_ALERT,
  GET_OTHER_INCOME_DATA_TYPES,
  GET_INCOME_VALIDATION_KEY_DATES_TYPES,
  RUN_CALCS_TYPES,
  VALIDATE_SCHEDULE_C_TYPES,
  VALIDATE_FORM_1099_TYPES,
  VALIDATION_TYPES,
  GET_EMPLOYMENT_QUESTIONS_TYPES,
  GET_ANSWERED_QUESTIONS_TYPES,
  SET_PAYSTUB_DEDUCTION_ALERT,
  INCOME_RESOLUTION_GET,
  INCOME_RESOLUTION_SAVE,
  PREVIOUS_EMPLOYMENT_GET,
  GET_ASSOC_INCOME_DOCS_TYPES,
} from './types';

export function setLoan(databaseId, loanId) {
  return {
    type: SET_LOAN_NUMBER,
    payload: {
      obfuscatedLenderDatabaseId: databaseId,
      obfuscatedLoanRecordId: loanId,
    },
  };
}

// Get CustomerEmploymentDetail
export function getCustomerEmploymentDetailError() {
  return {
    type: CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_ERROR,
    payload: {
      error: 'Unable to get Customer Employment Detail.',
    },
  };
}

export function getCustomerEmploymentDetailStarted() {
  return {
    type: CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_STARTED,
  };
}

export function getCustomerEmploymentDetailSuccess(data) {
  return {
    type: CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_SUCCESS,
    payload: {
      ...data,
    },
  };
}

//Get PayStub info
export function getPayStubInfoError() {
  return {
    type: PAY_STUB_TYPES.GET_PAY_STUB_INFO_ERROR,
    payload: {
      error: 'Unable to get Employer Info.',
    },
  };
}

export function getPayStubInfoStarted() {
  return {
    type: PAY_STUB_TYPES.GET_PAY_STUB_INFO_STARTED,
  };
}

export function getPayStubInfoSuccess(data) {
  return {
    type: PAY_STUB_TYPES.GET_PAY_STUB_INFO_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getPayStubInfoClear() {
  return {
    type: PAY_STUB_TYPES.GET_PAY_STUB_INFO_CLEAR,
  };
}

//Get W2 info
export function getW2InfoError() {
  return {
    type: W2_TYPES.GET_W2_INFO_ERROR,
    payload: {
      error: 'Unable to get Employer Info.',
    },
  };
}

export function getW2InfoStarted() {
  return {
    type: W2_TYPES.GET_W2_INFO_STARTED,
  };
}

export function getW2InfoSuccess(data) {
  return {
    type: W2_TYPES.GET_W2_INFO_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getW2InfoClear() {
  return {
    type: W2_TYPES.GET_W2_INFO_CLEAR,
  };
}

export function getScheduleCInfoStarted() {
  return {
    type: VALIDATE_SCHEDULE_C_TYPES.STARTED,
  };
}

export function getScheduleCInfoClear() {
  return {
    type: VALIDATE_SCHEDULE_C_TYPES.CLEAR,
  };
}

export function getScheduleCInfoSuccess(data) {
  return {
    type: VALIDATE_SCHEDULE_C_TYPES.SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getScheduleCInfoError() {
  return {
    type: VALIDATE_SCHEDULE_C_TYPES.ERROR,
    payload: {
      error: 'Unable to get Employer Info.',
    },
  };
}

// Get Form 1099
export function getForm1099InfoError() {
  return {
    type: VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_ERROR,
    payload: {
      error: 'Unable to get Form 1099.',
    },
  };
}

export function getForm1099InfoStarted() {
  return {
    type: VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_STARTED,
  };
}

export function getForm1099InfoSuccess(data) {
  return {
    type: VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_SUCCESS,
    payload: {
      ...data,
    },
  };
}

//Get employment income validation data
export function getEmploymentIncomeValidationDataError() {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_ERROR,
    payload: {
      error: 'Unable to get employment income validation data.',
    },
  };
}

export function getEmploymentIncomeValidationDataStarted() {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_STARTED,
  };
}

export function getEmploymentIncomeValidationDataSuccess(data) {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_SUCCESS,
    payload: {
      ...data,
    },
  };
}
export function getEmploymentIncomeValidationDataClear() {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_CLEAR,
  };
}

export function updateEmploymentIncomeValidationData(value, path) {
  return {
    type: UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS,
    payload: {
      value,
      path,
    },
  };
}
export function getEmploymentIncomeValidationDataWithFieldResolutionError() {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR,
    payload: {
      error: 'Unable to get employment income validation data.',
    },
  };
}

export function getEmploymentIncomeValidationDataWithFieldResolutionStarted() {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED,
  };
}

export function getEmploymentIncomeValidationDataWithFieldResolutionSuccess(
  data,
) {
  return {
    type: GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getEmployerDocumentStarted() {
  return {
    type: EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_STARTED,
  };
}
export function getEmployerDocumentError() {
  return {
    type: EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_ERROR,
    payload: {
      error: 'Unable to get Employer Info.',
    },
  };
}
export function getEmployerDocumentSuccess(data) {
  return {
    type: EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getEmployerDocumentReset() {
  return {
    type: EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_RESET,
  };
}

export function getAssocIncomeDocsStarted() {
  return {
    type: GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_STARTED,
  };
}
export function getAssocIncomeDocsSuccess(data) {
  return {
    type: GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_SUCCESS,
    payload: data,
  };
}
export function getAssocIncomeDocsError(error) {
  return {
    type: GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_ERROR,
    payload: error,
  };
}

// get other income data
export function getOtherIncomeDataError() {
  return {
    type: GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_ERROR,
    payload: {
      error: 'Unable to get other income data',
    },
  };
}

export function getOtherIncomeDataStarted() {
  return {
    type: GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_STARTED,
  };
}

export function getOtherIncomeDataSuccess(data) {
  return {
    type: GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_SUCCESS,
    payload: {
      ...data,
    },
  };
}

// get income validation key dates
export function getIncomeValidationKeyDatesError() {
  return {
    type: GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_ERROR,
    payload: {
      error: 'Unable to get income validation key dates',
    },
  };
}

export function getIncomeValidationKeyDatesStarted() {
  return {
    type: GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_STARTED,
  };
}

export function getIncomeValidationKeyDatesSuccess(data) {
  return {
    type: GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_SUCCESS,
    payload: {
      ...data,
    },
  };
}

//save income summary docs

export function saveIncomeDocumentError() {
  return {
    type: SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_ERROR,
    payload: {
      error: 'Unable to save documents selection.',
    },
  };
}

export function saveIncomeDocumentStarted() {
  return {
    type: SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_STARTED,
  };
}

export function saveIncomeDocumentSuccess(data) {
  return {
    type: SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_SUCCESS,
    payload: data,
  };
}

export function saveIncomeDocumentReset() {
  return {
    type: SAVE_INCOME_DOCUMENTS_TYPES.RESET_INCOME_DOCUMENTS,
  };
}

// save the answers to the additional questions in the income summary page

export function saveEmploymentAnswerError() {
  return {
    type: SAVE_INCOME_ANSWER_TYPES.SAVE_INCOME_ANSWER_ERROR,
    payload: {
      error: 'Unable to save documents selection.',
    },
  };
}

export function saveEmploymentAnswerStarted() {
  return {
    type: SAVE_INCOME_ANSWER_TYPES.SAVE_INCOME_ANSWER_STARTED,
  };
}

export function saveEmploymentAnswerSuccess(data) {
  return {
    type: SAVE_INCOME_ANSWER_TYPES.SAVE_INCOME_ANSWER_SUCCESS,
    payload: data,
  };
}

//save validate document question answer

export function saveValidateDocumentError() {
  return {
    type: SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_ERROR,
    payload: {
      error: 'Unable to save documents selection.',
    },
  };
}

export function saveValidateDocumentStarted() {
  return {
    type: SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_STARTED,
  };
}

export function saveValidateDocumentClear() {
  return {
    type: SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_CLEAR,
  };
}

export function saveValidateDocumentSuccess(data) {
  return {
    type: SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_SUCCESS,
    payload: data,
  };
}

export function saveValidateDocumentType(data) {
  return {
    type: SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_TYPE,
    payload: data,
  };
}

// Calculation History
export function getCalculationHistoryStarted() {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_STARTED,
  };
}

export function getCalculationHistorySuccess(data) {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_SUCCESS,
    payload: data,
  };
}

export function getCalculationHistoryError(error) {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_ERROR,
    payload: error,
  };
}

export function getCalculationHistoryResultStarted() {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_STARTED,
  };
}

export function getCalculationHistoryResultSuccess(data) {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_SUCCESS,
    payload: data,
  };
}

export function getCalculationHistoryResultError(error) {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_ERROR,
    payload: error,
  };
}

export function resetCalculationHistoryResult() {
  return {
    type: CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_RESET,
  };
}

// Run Calculations
export function runCalculationsError(error) {
  return {
    type: RUN_CALCS_TYPES.RUN_CALCS_ERROR,
    payload: error,
  };
}

export function runCalculationsStarted() {
  return {
    type: RUN_CALCS_TYPES.RUN_CALCS_STARTED,
  };
}

export function runCalculationsSuccess(data) {
  return {
    type: RUN_CALCS_TYPES.RUN_CALCS_SUCCESS,
    payload: data,
  };
}

export function runCalculationsReset() {
  return {
    type: RUN_CALCS_TYPES.RUN_CALCS_RESET,
  };
}

export function getAssocIncomeCalcsStarted() {
  return {
    type: GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_STARTED,
  };
}

export function getAssocIncomeCalcsSuccess(data) {
  return {
    type: GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function getAssocIncomeCalcsError() {
  return {
    type: GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_ERROR,
    payload: {
      error: 'An error occurred calculating all the associated incomes.',
    },
  };
}

export function setSelectedIncomeResult(result) {
  return {
    type: GET_ASSOC_INCOMES_TYPES.SET_SELECTED_ASSOC_INCOME_RESULT,
    payload: result,
  };
}

// Save calculation
export function saveEmploymentIncomeError(error) {
  return {
    type: SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_ERROR,
    payload: {
      error,
    },
  };
}

export function saveEmploymentIncomeStarted() {
  return {
    type: SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_STARTED,
  };
}

export function saveEmploymentIncomeSuccess(data) {
  return {
    type: SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_SUCCESS,
    payload: data,
  };
}

export function addForm(form, income) {
  return {
    type: CALCULATION_TYPES.ADD_FORM,
    payload: {
      incomeIdentifier: income,
      form,
    },
  };
}

export function clearForms(income) {
  return {
    type: CALCULATION_TYPES.CLEAR,
    payload: {
      incomeIdentifier: income,
    },
  };
}

export function addValidationFormByDocumentType(form) {
  return {
    type: VALIDATION_TYPES.ADD_VALIDATION_FORM_BY_DOCUMENT_TYPE,
    payload: form,
  };
}

export function addValidationFormByDocumentId(form) {
  return {
    type: VALIDATION_TYPES.ADD_VALIDATION_FORM_BY_DOCUMENT_ID,
    payload: form,
  };
}

//get Employment Questions
export function getEmploymentQuestionsError() {
  return {
    type: GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_ERROR,
    payload: {
      error: 'Unable to get employment questions.',
    },
  };
}

export function getEmploymentQuestionsStarted() {
  return {
    type: GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_STARTED,
  };
}

export function getEmploymentQuestionsSuccess(data) {
  return {
    type: GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_SUCCESS,
    payload: data,
  };
}

//get Answered Employment Questions
export function getAnsweredQuestionsError() {
  return {
    type: GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_ERROR,
    payload: {
      error: 'Unable to get answered questions.',
    },
  };
}

export function getAnsweredQuestionsStarted() {
  return {
    type: GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_STARTED,
  };
}

export function getAnsweredQuestionsSuccess(data) {
  return {
    type: GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_SUCCESS,
    payload: data,
  };
}

export function saveSelectedIncome(selectedIncome) {
  return {
    type: SAVE_SELECTED_INCOME,
    payload: {
      ...selectedIncome,
    },
  };
}

export function resetSelectedIncome() {
  return {
    type: RESET_SELECTED_INCOME,
  };
}

export function resetSaveToLoanStatus() {
  return {
    type: SAVE_CALC_TYPES.SAVE_CALCULATION_RESET_STATUS,
  };
}

export function setPayStubAddressAlert(show) {
  return {
    type: SET_PAYSTUB_ADDRESS_ALERT,
    payload: {
      payStubAddressAlert: show,
    },
  };
}

export function setDeductionAlert(show) {
  return {
    type: SET_PAYSTUB_DEDUCTION_ALERT,
    payload: {
      payStubDeductionAlert: show,
    },
  };
}

export function setW2AddressAlert(show) {
  return {
    type: SET_W2_ADDRESS_ALERT,
    payload: {
      w2AddressAlert: show,
    },
  };
}

// selected calculation data
export const getSelectedCalculatorDataError = () => ({
  type: CALCULATION_TYPES.GET_SELECTED_CALCULATOR_ERROR,
  error: 'An error occurred calculating income.',
});

export const getSelectedCalculatorDataStarted = () => ({
  type: CALCULATION_TYPES.GET_SELECTED_CALCULATOR_STARTED,
});

export const getSelectedCalculatorDataSuccess = (payload) => ({
  type: CALCULATION_TYPES.GET_SELECTED_CALCULATOR_SUCCESS,
  payload,
});

export const getSelectedCalculatorDataClear = (payload) => ({
  type: CALCULATION_TYPES.GET_SELECTED_CALCULATOR_CLEAR,
  payload,
});

/**
 * @param fiscalYear {int} The fiscal year of the document.
 * @param documentType {int} The type id of the document.
 * @param fieldId {int} The identifier of the field.
 * @param value {string} The new field value.
 */
export const updateCalculationField = (
  fiscalYear,
  documentType,
  fieldId,
  value,
) => ({
  type: CALCULATION_TYPES.UPDATE_CALCULATION_FIELD,
  payload: {
    fiscalYear,
    documentType,
    fieldId,
    value,
  },
});

export const wvoeValidationSummaryError = (error, calculationType) => ({
  type: WVOE_TYPES.VALIDATION_SUMMARY_ERROR,
  payload: {
    type: calculationType,
    data: error,
  },
});
export const wvoeValidationSummaryStarted = (calculationType) => ({
  type: WVOE_TYPES.VALIDATION_SUMMARY_STARTED,
  payload: {
    type: calculationType,
  },
});
export const wvoeValidationSummarySuccess = (data, calculationType) => ({
  type: WVOE_TYPES.VALIDATION_SUMMARY_SUCCESS,
  payload: {
    data,
    type: calculationType,
  },
});

export function previousEmploymentGetStarted() {
  return {
    type: PREVIOUS_EMPLOYMENT_GET.STARTED,
  };
}

export function previousEmploymentGetSuccess(response) {
  return {
    type: PREVIOUS_EMPLOYMENT_GET.SUCCESS,
    payload: response,
  };
}

export function previousEmploymentGetError(error) {
  return {
    type: PREVIOUS_EMPLOYMENT_GET.ERROR,
    payload: error,
  };
}

export function incomeResolutionGetStarted() {
  return {
    type: INCOME_RESOLUTION_GET.STARTED,
  };
}

export function incomeResolutionGetSuccess(response) {
  return {
    type: INCOME_RESOLUTION_GET.SUCCESS,
    payload: response,
  };
}

export function incomeResolutionGetError(error) {
  return {
    type: INCOME_RESOLUTION_GET.ERROR,
    payload: error,
  };
}

export function incomeResolutionSaveStarted() {
  return {
    type: INCOME_RESOLUTION_SAVE.STARTED,
  };
}

export function incomeResolutionSaveSuccess() {
  return {
    type: INCOME_RESOLUTION_SAVE.SUCCESS,
  };
}

export function incomeResolutionSaveError(error) {
  return {
    type: INCOME_RESOLUTION_SAVE.ERROR,
    payload: error,
  };
}
