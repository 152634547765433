import TYPES from './types';
import { API_STATUS } from '../../../common/constants';
import { setInsuranceData } from '../model/HOIController';

const hoiReviewInfo = {
  status: null,
  error: null,
  insuranceData: null,
  snippet: {},
  snippetStatus: null,
};

const hoiReducer = (state = hoiReviewInfo, action) => {
  switch (action.type) {
    case TYPES.POST_HOI_POLICY_DATA_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.POST_HOI_POLICY_DATA_SUCCESS:
      return {
        ...state,
        status: null,
        error: null,
      };
    case TYPES.POST_HOI_POLICY_DATA_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    case TYPES.GET_HOI_POLICY_DATA_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_HOI_POLICY_DATA_SUCCESS:
      return {
        ...state,
        status: null,
        error: null,
        insuranceData: setInsuranceData(action.payload),
      };
    case TYPES.GET_HOI_POLICY_DATA_ERROR:
      return {
        ...state,
        status: null,
        error: action.error,
      };
    case TYPES.GET_DOCUMENT_SNIPPET_STARTED:
      return {
        ...state,
        snippetStatus: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DOCUMENT_SNIPPET_SUCCESS:
      return {
        ...state,
        snippet: action.payload,
        snippetStatus: API_STATUS.SUCCESS,
      };
    case TYPES.GET_DOCUMENT_SNIPPET_ERROR:
      return {
        ...state,
        snippetStatus: API_STATUS.ERROR,
      };
    default:
      return state;
  }
};

export default hoiReducer;
