﻿import EmploymentIncome from './EmploymentIncomeDataModel';
import ExtractionInfoData from './ExtractionInfoDataModel';
import BorrowerAddress from 'model/BorrowerAddressDataModel';

export default class EmployerInfoData {
  constructor(vd = {}) {
    this.employmentIncome = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentIncome',
    )
      ? vd.employmentIncome.map((x) => new EmploymentIncome(x))
      : [];

    this.borrowerAddress = Object.prototype.hasOwnProperty.call(
      vd,
      'borrowerAddresses',
    )
      ? vd.borrowerAddresses.map((x) => new BorrowerAddress(x))
      : [];

    this.borrowerSSN = Object.prototype.hasOwnProperty.call(vd, 'borrowerSSN')
      ? vd.borrowerSSN
      : '';

    this.paystubExtractionInfo = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentExtractedPaystub',
    )
      ? new ExtractionInfoData(vd.employmentExtractedPaystub)
      : new ExtractionInfoData();

    this.w2ExtractionInfo = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentExtractedW2',
    )
      ? new ExtractionInfoData(vd.employmentExtractedW2)
      : new ExtractionInfoData();

    this.userInputDocumentData = Object.prototype.hasOwnProperty.call(
      vd,
      'userInputDocumentData',
    )
      ? vd.userInputDocumentData
      : {};

    this.validationResults =
      Object.prototype.hasOwnProperty.call(vd, 'results') &&
      vd.results?.length > 0
        ? vd.results
        : [
            {
              ruleResultId: 3773,
              ruleMetadata: {
                ruleId: 0,
                category: 0,
                subCategoryId: 0,
              },
              status: 0,
              conditionText: '',
              questions: [],
              additionalData: {
                licenseId: '',
              },
            },
          ];
  }

  /**
   * @returns {[EmploymentIncome]} The list of Employers.
   */
  getEmployerInfo() {
    return this.employmentIncome;
  }

  /**
   * @returns {[BorrowerAddress]} The list of borrowers address.
   */
  getBorrowerAddress() {
    return this.borrowerAddress;
  }

  /**
   * @returns {EmployerInfoData} The paystub extraction address.
   */
  getPaystubExtraction() {
    return this.paystubExtractionInfo;
  }

  /**
   * @returns {EmployerInfoData} The w2 extraction address.
   */
  getW2Extraction() {
    return this.w2ExtractionInfo;
  }

  /**
   * @returns {string} The borrower's SSN number.
   */
  getBorrowerSSN() {
    return this.borrowerSSN;
  }

  getUserInputDocumentData() {
    return this.userInputDocumentData;
  }

  getValidationResults() {
    return this.validationResults;
  }

  getValidationResult(ruleId) {
    return this.validationResults.filter(
      (vr) => vr.ruleMetadata?.ruleId === ruleId,
    );
  }
}
