import React from 'react';
import { Textarea as ChakraTextarea } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Textarea = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraTextarea ref={ref} {...props}>
      {children}
    </ChakraTextarea>
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;

Textarea.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
