export default class InsuranceData {
  constructor(data = {}) {
    this.insuranceCurrentHudNumber = data?.insuranceCurrentHudNumber ?? 0;
    this.companyName = data?.companyName ?? 'Test Insurance Company';
    this.policyStartDate = data?.policyStartDate ? data.policyStartDate : '';
    this.policyEndDate = data?.policyEndDate ? data.policyEndDate : '';
    this.policyEndDateExtractedDataResultId =
      data?.policyEndDateExtractedDataResultId ?? 0;
    this.premiumAmount = data?.premiumAmount ?? 0;
    this.dwellingCoverage = data?.dwellingCoverage ?? 0;
    this.deductibleAmount = data?.deductibleAmount ?? 0;
    this.dwellingCoverageExtractedDataResultId =
      data?.dwellingCoverageExtractedDataResultId ?? 0;
    this.maximumInsurableValue = data?.maximumInsurableValue ?? 0;
    this.questions = data?.questions ? data.questions : [];
    this.amountDue = data?.amountDue ?? 0;
  }

  getPolicyStartDate() {
    return this.policyStartDate;
  }

  getPolicyEndDate() {
    return this.policyEndDate;
  }

  getPolicyEndDateResultId() {
    return this.policyEndDateExtractedDataResultId;
  }
}
