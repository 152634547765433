import TYPES from './types';
import { ALERT_SEVERITY, API_STATUS } from 'common/constants';
import lang from '../language.json';
import fpSet from 'lodash/fp/set';

export const initialState = {
  onInit: true,
  selectedCategory: 0,
  navClickedSelectedCategory: null,
  selectedAlert: { ruleResultId: null },
  selectedAlertParent: { ruleResultId: null },
  shouldGetNextAlert: false,
  isLastAlert: false,
  isBailOut: false,
  isZappedLoan: false,
  isTestLoan: false,
  canImpersonate: false,
  isUnderwriterCheckboxDisabled: false,
  isToastOpen: false,
  toastMessage: {},
  allRequiredRulesCompleted: null,
  boltStatus: {
    value: undefined,
    error: null,
    status: null,
  },
  latestSignalRNotification: {
    notificationType: '',
    correlationId: null,
    receivedDateTime: null,
  },
  loanSummary: {
    status: null,
    error: null,
    isLoading: null,
    qualifiedIND: undefined,
  },
  navigationItems: {
    allAlerts: [],
    categories: [],
    status: null,
    error: null,
  },
  triggerRules: {
    status: null,
    error: null,
    rulesTriggered: false,
  },
  user: {
    status: null,
    error: null,
    isUnderwriter: false,
    role: '',
    email: '',
    isImpersonating: false,
  },
  submitToSetup: {
    status: null,
    error: null,
  },
  bailOutAndSubmitToSetup: {
    status: null,
    error: null,
    success: null,
  },
  pdfLink: {
    status: null,
    error: null,
    data: {},
  },
};

const homeReducer = (state = initialState, action, error) => {
  switch (action.type) {
    case TYPES.SET_ON_INIT:
      return {
        ...state,
        onInit: action.value,
      };
    case TYPES.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.category,
      };
    case TYPES.SET_CLICKED_SELECTED_CATEGORY:
      return {
        ...state,
        navClickedSelectedCategory: action.category,
      };
    case TYPES.SET_SELECTED_ALERT:
      return {
        ...state,
        selectedAlert: action.alert,
      };
    case TYPES.ATTACH_IS_EANDO_TO_SELECTED_ALERT:
      return {
        ...state,
        selectedAlert: {
          ...state.selectedAlert,
          isEandO: action.isEandO,
        },
      };
    case TYPES.SET_SELECTED_ALERT_PARENT:
      return {
        ...state,
        selectedAlertParent: action.alert,
      };
    case TYPES.SET_LATEST_SIGNAL_R_NOTIFICATION:
      return {
        ...state,
        latestSignalRNotification: action.notification,
      };
    case TYPES.SET_IS_UNDERWRITER:
      return {
        ...state,
        user: {
          ...state.user,
          isUnderwriter: action.value,
        },
      };

    case TYPES.SET_IS_LAST_ALERT:
      return {
        ...state,
        isLastAlert: action.value,
      };
    case TYPES.SET_IS_BAIL_OUT:
      return action.value
        ? {
            ...state,
            isBailOut: action.value,
          }
        : {
            ...state,
            isBailOut: false,
            bailOutAndSubmitToSetup: {
              ...state.bailOutAndSubmitToSetup,
              status: null,
              error: null,
            },
          };
    case TYPES.SET_IS_UNDERWRITER_CHECKBOX_DISABLED:
      return {
        ...state,
        isUnderwriterCheckboxDisabled: action.value,
      };
    case TYPES.SET_IS_TOAST_OPEN:
      return {
        ...state,
        isToastOpen: action.value,
        toastMessage: action.message,
      };
    case TYPES.SET_USER_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.user.role,
          email: action.user.email,
          isImpersonating: action.user.isImpersonating,
        },
      };
    case TYPES.SET_UNDERWRITER_VERIFIED_RULE:
      return fpSet(`FEVerified`, action.FEVerified, state);
    case TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          status: API_STATUS.UPDATING,
        },
      };
    case TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          status: null,
          error: action.error,
        },
      };
    case TYPES.POST_SAVE_UNDERWRITER_VERIFICATION_SUCCESS:
      // let newState = fpSet(
      //   `user.verifiedRules.${action.ruleResultID}.FEVerified`,
      //   action.isUnderwriterVerified,
      //   state,
      // );
      // newState = fpSet(
      //   `user.verifiedRules.${action.ruleResultID}.isUnderwriterVerified`,
      //   action.isUnderwriterVerified,
      //   newState,
      // );
      // eslint-disable-next-line no-case-declarations
      const newState = fpSet(`user.status`, null, state);
      return fpSet(`user.error`, null, newState);
    case TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          status: null,
          error: action.error,
        },
      };
    case TYPES.POST_GET_UNDERWRITER_VERIFICATION_STATUS_SUCCESS:
      return { ...state };
    case TYPES.SET_SHOULD_GET_NEXT_ALERT:
      return {
        ...state,
        shouldGetNextAlert: action.value,
      };
    case TYPES.GET_NAVIGATION_ITEMS_STARTED:
      return {
        ...state,
        navigationItems: {
          ...state.navigationItems,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.GET_NAVIGATION_ITEMS_SUCCESS:
      return {
        ...state,
        allRequiredRulesCompleted: action.allRequiredRulesCompleted,
        navigationItems: {
          ...state.navigationItems,
          status: null,
          categories: action.categories,
          allAlerts: action.allAlerts,
        },
      };
    case TYPES.GET_NAVIGATION_ITEMS_ERROR:
      return {
        ...state,
        navigationItems: {
          ...state.navigationItems,
          status: null,
          error: action.error,
        },
      };
    case TYPES.POST_TRIGGER_ALL_RULES_STARTED:
      return {
        ...state,
        triggerRules: {
          ...state.triggerRules,
          status: API_STATUS.UPDATING,
        },
      };
    case TYPES.POST_TRIGGER_ALL_RULES_SUCCESS:
      return {
        ...state,
        triggerRules: {
          ...state.triggerRules,
          status: null,
          error: null,
          rulesTriggered: true,
        },
      };
    case TYPES.POST_TRIGGER_ALL_RULES_ERROR:
      return {
        ...state,
        triggerRules: {
          ...state.triggerRules,
          status: null,
          error: lang.POST_TRIGGER_ALL_RULES_ERROR_MESSAGE,
        },
      };
    case TYPES.SUBMIT_TO_SETUP_STARTED:
      return {
        ...state,
        submitToSetup: {
          ...state.submitToSetup,
          status: API_STATUS.UPDATING,
          error: null,
        },
      };
    case TYPES.SUBMIT_TO_SETUP_SUCCESS:
      return {
        ...state,
        submitToSetup: {
          ...state.submitToSetup,
          status: null,
          data: action.payload,
          error: null,
        },
        boltStatus: {
          ...state.boltStatus,
          value: action.value,
        },
      };
    case TYPES.SUBMIT_TO_SETUP_ERROR:
      return {
        ...state,
        submitToSetup: {
          ...state.submitToSetup,
          status: null,
          error: lang.SUBMIT_TO_SETUP_ERROR_MESSAGE,
        },
      };
    case TYPES.GET_BOLT_STATUS_STARTED:
      return {
        ...state,
        boltStatus: {
          ...state.boltStatus,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.GET_BOLT_STATUS_SUCCESS:
      return {
        ...state,
        isZappedLoan: action.value?.isLoanZapped,
        isTestLoan: action.value?.isTestLoan,
        canImpersonate: action.value?.userCanImpersonate,
        boltStatus: {
          ...state.boltStatus,
          status: API_STATUS.DONE,
          value: action.value?.completionStatusIND,
          error: null,
        },
      };
    case TYPES.GET_BOLT_STATUS_ERROR:
      return {
        ...state,
        boltStatus: {
          ...state.boltStatus,
          status: null,
          error: lang.GET_BOLT_STATUS_ERROR_MESSAGE,
        },
      };
    case TYPES.GET_LOAN_SUMMARY_STARTED:
      return {
        ...state,
        loanSummary: {
          ...state.loanSummary,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.GET_LOAN_SUMMARY_SUCCESS:
      return {
        ...state,
        loanSummary: {
          ...state.loanSummary,
          status: null,
          error: null,
          value: action.payload,
          qualifiedIND: action.qualifiedIND,
        },
      };
    case TYPES.GET_LOAN_SUMMARY_ERROR:
      return {
        ...state,
        loanSummary: {
          ...state.loanSummary,
          status: null,
          error: lang.GET_LOAN_SUMMARY_ERROR_MESSAGE,
        },
      };
    case TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_STARTED:
      return {
        ...state,
        bailOutAndSubmitToSetup: {
          ...state.bailOutAndSubmitToSetup,
          error: null,
          status: API_STATUS.UPDATING,
        },
      };
    case TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_SUCCESS:
      return {
        ...state,
        bailOutAndSubmitToSetup: {
          ...state.bailOutAndSubmitToSetup,
          error: null,
          data: action.payload,
          status: null,
          success: true,
        },
        boltStatus: {
          ...state.boltStatus,
          value: action.value,
        },
      };
    case TYPES.BAIL_OUT_AND_SUBMIT_TO_SETUP_ERROR:
      return {
        ...state,
        bailOutAndSubmitToSetup: {
          ...state.bailOutAndSubmitToSetup,
          error: action.payload.description
            ? action.payload
            : {
                title: lang.DEFAULT_OOPS_HEADER,
                description: lang.DEFAULT_OOPS_DESCRIPTION,
                severity: ALERT_SEVERITY.ALERT,
              },
          status: API_STATUS.ERROR,
          success: false,
        },
      };
    case TYPES.RETRIEVE_PDF_STARTED:
      return {
        ...state,
        pdfLink: {
          ...state.pdfLink,
          status: API_STATUS.GETTING,
        },
      };
    case TYPES.RETRIEVE_PDF_SUCCESS:
      return {
        ...state,
        pdfLink: {
          ...state.pdfLink,
          status: API_STATUS.SUCCESS,
          error: null,
          data: action.payload,
        },
      };
    case TYPES.RETRIEVE_PDF_ERROR:
      return {
        ...state,
        pdfLink: {
          ...state.pdfLink,
          status: API_STATUS.ERROR,
          error: error,
        },
      };
    case TYPES.RETRIEVE_PDF_CLEAR:
      return {
        ...state,
        pdfLink: {
          status: null,
          error: null,
          data: {},
        },
      };
    default:
      return state;
  }
};

export default homeReducer;
