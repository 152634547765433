import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Cookies from 'js-cookie';
import { Menu, MenuButton, MenuItem, MenuList } from 'common/components';
import { API_STATUS, BOLT_IMPERSONATE_COOKIE } from 'common/constants';
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'common/components/Buttons/Button';

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 7px;
`;

const roles = [
  { label: 'Senior Underwriter', role: 'SR Underwriter' },
  { label: 'Underwriter II', role: 'Underwriter' },
];

export default function ImpersonateMenu() {
  const {
    user: { isImpersonating },
    canImpersonate,
    boltStatus: { status: boltStatusStatus },
  } = useSelector(({ home }) => home);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );

  function onImpersonate(role) {
    Cookies.set(
      BOLT_IMPERSONATE_COOKIE(obfuscatedLoanIdentifier.obfuscatedLoanRecordId),
      role,
      {
        secure: true,
        sameSite: 'strict',
      },
    );
    window.location.reload();
  }

  function unImpersonate() {
    Cookies.remove(
      BOLT_IMPERSONATE_COOKIE(obfuscatedLoanIdentifier.obfuscatedLoanRecordId),
    );
    window.location.reload();
  }

  if (boltStatusStatus !== API_STATUS.DONE) {
    return null;
  }

  if (!canImpersonate) {
    if (isImpersonating) {
      unImpersonate();
    }
    return null;
  }

  if (isImpersonating) {
    return (
      <Button
        display="inline"
        backgroundColor="white"
        color="blue.600"
        fontWeight="500"
        lineHeight="24px"
        _hover={{
          textDecoration: 'none',
          background: 'white',
          borderRadius: '7px',
          border: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          textDecoration: 'none',
        }}
        height="unset"
        onClick={unImpersonate}
        aria-label="Stop impersonating"
      >
        <StyledIcon icon={faTimes} />
        Impersonating
      </Button>
    );
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            color="blue.600"
            fontWeight="500"
            mr="20px"
            lineHeight="24px"
            display="flex"
            alignItems="center"
          >
            Impersonate
            <StyledIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </MenuButton>
          <MenuList>
            {roles.map(({ role, label }) => (
              <MenuItem key={role} onClick={() => onImpersonate(role)}>
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
