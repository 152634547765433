import { useState } from 'react';
import FormControl from 'common/componentMapper/FormControl';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import FormHelperText from 'common/componentMapper/FormHelperText';
import FormLabel from 'common/componentMapper/FormLabel';
import Textarea from 'common/componentMapper/Textarea';
import Box from 'common/components/Box';
// eslint-disable-next-line
import { FormSpy, useFieldApi } from '@data-driven-forms/react-form-renderer';
import { FormElementWidget } from 'lib-node-amb-renderer';

export class CommentBoxWidget extends FormElementWidget {
  constructor(props) {
    super(props);
  }
}

export default function CommentBox(props) {
  return (
    <FormSpy subcription={{ values: true, valid: true }}>
      {({ values }) => {
        const isRequired =
          Object.values(
            values.calculationDetails[`N-${values.calculationId}`]
              .outcomeDetails,
          ).findIndex(({ override }) => override === 'true') >= 0;

        return <InnerComponent {...props} isRequired={isRequired} />;
      }}
    </FormSpy>
  );
}

function InnerComponent(props) {
  const { label, input, meta, isRequired } = useFieldApi({
    ...props,
  });
  const [commentLength, setCommentLength] = useState(input.value.length);

  const maxLength = 1000;
  const text = meta.error;

  return (
    <FormControl isInvalid={meta.invalid} maxW="908px">
      <FormLabel m={1} d="flex" justifyContent="space-between">
        <div>
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </div>

        <div>
          {commentLength} / {maxLength}
        </div>
      </FormLabel>
      <Textarea
        borderColor="gray.200"
        borderRadius="4px"
        id="rb-comments"
        minH="100px"
        {...input}
        {...meta}
        onChange={(e) => {
          const { value } = e.target;
          setCommentLength(value.length);
          input.onChange(value);
        }}
        placeholder={label}
        resize="vertical"
        maxLength={maxLength}
      />
      <Box
        maxW={'800px'}
        w="100%"
        borderColor="gray.200"
        borderWidth={'1px'}
        borderRadius={8}
        display="none"
        id="print-comment"
        px={15}
        pt={1.5}
        pb={2}
        minH={'80px'}
      >
        {input.value}
      </Box>
      {meta.invalid && <FormErrorMessage>{text}</FormErrorMessage>}
      {commentLength === maxLength && (
        <FormHelperText color="red.500">Character Limit Reached</FormHelperText>
      )}
    </FormControl>
  );
}
