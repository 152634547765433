import PropTypes from 'prop-types';
import { Tr, Td } from 'common/components/Tables';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { ChildrenWidget } from 'lib-node-amb-renderer';

export class KYLTableRowClass extends ChildrenWidget {
  constructor(props) {
    super(props);
  }
}

export default function KYLTableRow({
  fields,
  position,
  colSpan,
  headerCols,
  ...rest
}) {
  const { renderForm } = useFormApi();
  return (
    <Tr
      align="center"
      {...rest}
      sx={{ background: position % 2 === 0 ? '#FAFAFA' : '' }}
    >
      {fields?.map((field, index) => (
        <Td
          colSpan={colSpan ? colSpan[index] : headerCols[index].colSpan}
          key={index}
          sx={{
            borderBottom: '0 !important',
            borderLeft: '0 !important',
            borderRight: '1px solid #979797 !important',
            fontSize: '0.875rem',
            '& > div': {
              margin: '0 !important',
            },

            '&:first-child': {
              borderRadius: '0 0 0 4px',
              paddingLeft: '1.875rem !important',
            },

            '&:last-child': {
              borderRight: '0 !important',
              borderRadius: '0 0 4px 0',
            },
          }}
        >
          {field && renderForm([field])}
        </Td>
      ))}
    </Tr>
  );
}

KYLTableRow.propTypes = {
  fields: PropTypes.array,
  colSpan: PropTypes.number,
  position: PropTypes.number,
  sx: PropTypes.object,
  headerCols: PropTypes.number,
};
