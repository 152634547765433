﻿import React from 'react';
import PropTypes from 'prop-types';
import BreakdownValue from '../styles/BreakdownValue';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import { FormTypes } from '../../../enums';
import lang from '../language.json';

const BorrowerShareOfIncome = ({ selectedResult, currentYear }) => {
  const borrowerShare = selectedResult.getInputAsFloat(
    FormTypes.Form1120,
    currentYear,
    'borrowerShareOfIncomeOrLoss',
  );
  const addbacks = selectedResult.getInputAsFloat(
    FormTypes.Form1120,
    currentYear,
    'subTotal',
  );
  const percentageOwnership =
    selectedResult.getInputAsFloat(
      FormTypes.ScheduleG,
      currentYear,
      'percentageOwnership',
    ) * 100;

  const {
    FIELDS: { ['1120']: FIELDS },
  } = lang;

  return (
    <BreakdownValue
      name={FIELDS.BORROWER_SHARE_OF_INCOME}
      value={borrowerShare}
      isCurrency={true}
    >
      <BreakdownValue name={FIELDS.ADDBACK_DEDUCTION} value={addbacks} />
      <BreakdownValue
        name={FIELDS.PERCENTOWNERSHIP}
        value={<>&times;{percentageOwnership}%</>}
        isCurrency={false}
      />
    </BreakdownValue>
  );
};
BorrowerShareOfIncome.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
};

export default BorrowerShareOfIncome;
