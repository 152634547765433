import TYPES from './types';
import { API_STATUS } from 'common/constants';

const initialState = {
  checkAlerts: [],
  checkAlertsStatus: null,
  alertsDataStatus: null,
  status: null,
  alerts: null,
  error: null,
};

const thirdPartyCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DOCUMENTS_ALERT_STATUS_STARTED:
      return {
        ...state,
        checkAlertsStatus: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DOCUMENTS_ALERT_STATUS_SUCCESS:
      return {
        ...state,
        checkAlertsStatus: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.GET_DOCUMENTS_ALERT_STATUS_ERROR:
      return {
        ...state,
        checkAlertsStatus: API_STATUS.ERROR,
        error: action.error,
      };
    case TYPES.GET_DOCUMENTS_ALERT_DATA_STARTED:
      return {
        ...state,
        alertsDataStatus: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_DOCUMENTS_ALERT_DATA_SUCCESS:
      return {
        ...state,
        alertsDataStatus: API_STATUS.SUCCESS,
        alerts: action.data,
        error: null,
      };
    case TYPES.GET_DOCUMENTS_ALERT_DATA_ERROR:
      return {
        ...state,
        alertsDataStatus: API_STATUS.ERROR,
        error: action.error,
      };
    case TYPES.SAVE_THIRD_PARTY_CHECK_STATUS_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.SAVE_THIRD_PARTY_CHECK_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.SAVE_THIRD_PARTY_CHECK_STATUS_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};
export default thirdPartyCheckReducer;
