const RENTAL_INCOME_VALIDATION_TYPES = {
  GET_RENTAL_INCOME_VALIDATION_INFO_STARTED:
    'GET_RENTAL_INCOME_VALIDATION_INFO_STARTED',
  GET_RENTAL_INCOME_VALIDATION_INFO_SUCCESS:
    'GET_RENTAL_INCOME_VALIDATION_INFO_SUCCESS',
  GET_RENTAL_INCOME_VALIDATION_INFO_ERROR:
    'GET_RENTAL_INCOME_VALIDATION_INFO_ERROR',
  UPDATE_RENTAL_INCOME_VALIDATION_INFO_STARTED:
    'UPDATE_RENTAL_INCOME_VALIDATION_INFO_STARTED',
  UPDATE_RENTAL_INCOME_VALIDATION_INFO_SUCCESS:
    'UPDATE_RENTAL_INCOME_VALIDATION_INFO_SUCCESS',
  UPDATE_RENTAL_INCOME_VALIDATION_INFO_ERROR:
    'UPDATE_RENTAL_INCOME_VALIDATION_INFO_ERROR',
  // Get the document snapshot
  GET_RENTAL_INCOME_VALIDATION_SNIPPET_STARTED:
    'GET_RENTAL_INCOME_VALIDATION_SNIPPET_STARTED',
  GET_RENTAL_INCOME_VALIDATION_SNIPPET_SUCCESS:
    'GET_RENTAL_INCOME_VALIDATION_SNIPPET_SUCCESS',
  GET_RENTAL_INCOME_VALIDATION_SNIPPET_ERROR:
    'GET_RENTAL_INCOME_VALIDATION_SNIPPET_ERROR',
};

export default RENTAL_INCOME_VALIDATION_TYPES;
