import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import styled from '@emotion/styled';
const DlStyled = styled.dl`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
`;
const DtStyled = styled.dt`
  float: left;
  clear: left;
  font-weight: 500;
  margin-right: 4px;
  &:after {
    content: ':';
  }
`;

const DataDisplayBlock = ({
  header,
  data,
  containerProps,
  headerProps,
  dlProps,
  dtProps,
  ddProps,
}) => (
  <Box {...containerProps}>
    {header && (
      <Text as="h4" {...headerProps}>
        {header}
      </Text>
    )}
    {data.map(({ label, value }) => (
      <Box as={DlStyled} key={`${label}-${value}`} {...dlProps}>
        <Text as={DtStyled} {...dtProps}>
          {label}
        </Text>
        <Text as="dd" {...ddProps}>
          {value}
        </Text>
      </Box>
    ))}
  </Box>
);

DataDisplayBlock.propTypes = {
  containerProps: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  ddProps: PropTypes.object,
  dlProps: PropTypes.object,
  dtProps: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerProps: PropTypes.object,
};

DataDisplayBlock.defaultProps = {
  containerProps: {},
  ddProps: {},
  dlProps: {},
  dtProps: {},
  header: null,
  headerProps: { mb: '14px' },
};

export default DataDisplayBlock;
