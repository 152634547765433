import { Select as ChakraSelect } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Select = forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraSelect {...props} ref={ref}>
      {children}
    </ChakraSelect>
  );
});

Select.displayName = 'Select';

export default Select;

Select.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
