import TYPES from './types';
import { API_STATUS } from '../../../../common/constants';
import { RENTAL_INCOME_VALIDATION_API_STATUS } from '../constants';
import { INITIAL_RENTAL_INCOME_VALIDATION_INFO } from '../constants';
import { setRentalIncomeValidationData } from '../model/RentalIncomeValidationController';

const initialState = {
  status: null,
  error: null,
  data: setRentalIncomeValidationData(INITIAL_RENTAL_INCOME_VALIDATION_INFO),
  snippetStatus: null,
  snippetData: {},
};

const RentalIncomeValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_RENTAL_INCOME_VALIDATION_INFO_STARTED:
      return {
        ...state,
        status: RENTAL_INCOME_VALIDATION_API_STATUS.GETTING,
        error: null,
      };
    case TYPES.UPDATE_RENTAL_INCOME_VALIDATION_INFO_STARTED:
      return {
        ...state,
        status: RENTAL_INCOME_VALIDATION_API_STATUS.UPDATING,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_VALIDATION_INFO_SUCCESS:
      return {
        ...state,
        data: setRentalIncomeValidationData(action.payload),
        status: RENTAL_INCOME_VALIDATION_API_STATUS.GET_SUCCESS,
        error: null,
      };
    case TYPES.UPDATE_RENTAL_INCOME_VALIDATION_INFO_SUCCESS:
      return {
        ...state,
        status: RENTAL_INCOME_VALIDATION_API_STATUS.UPDATE_SUCCESS,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_VALIDATION_INFO_ERROR:
      return {
        ...state,
        status: RENTAL_INCOME_VALIDATION_API_STATUS.GET_ERROR,
        error: action.error,
      };
    case TYPES.UPDATE_RENTAL_INCOME_VALIDATION_INFO_ERROR:
      return {
        ...state,
        status: RENTAL_INCOME_VALIDATION_API_STATUS.UPDATE_ERROR,
        error: action.error,
      };
    // Get snppet
    case TYPES.GET_RENTAL_INCOME_VALIDATION_SNIPPET_STARTED:
      return {
        ...state,
        snippetStatus: API_STATUS.GETTING,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_VALIDATION_SNIPPET_SUCCESS:
      return {
        ...state,
        snippetData: action.payload,
        snippetStatus: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.GET_RENTAL_INCOME_VALIDATION_SNIPPET_ERROR:
      return {
        ...state,
        snippetStatus: API_STATUS.ERROR,
        error: action.error,
      };
    default:
      return state;
  }
};

export default RentalIncomeValidationReducer;
