import { propsMapper } from './propsMapper';

const circularProgressPropsRegex = /^(disableShrink|size|thickness|value)$/;

// Define the size scale
const sizeScale = {
  xs: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
};

export const circularProgressMapper = (props) => {
  if (props?.label) {
    props['aria-label'] = props.label;
    delete props.label;
  }

  const circularProgressProps = {};

  Object.entries(props).forEach(([key, value]) => {
    if (circularProgressPropsRegex.test(key)) {
      if (key === 'size') {
        value = sizeScale[value] ? sizeScale[value] : value;
      }

      if (key === 'thickness') {
        value = parseFloat(value);
      }

      circularProgressProps[key] = value;

      delete props[key];
    }
  });

  const haveCircularProgressProps =
    Object.keys(circularProgressProps).length > 0;

  const mappedProps = propsMapper(props);

  const circularProgressContainerProps = haveCircularProgressProps
    ? { ...mappedProps, ...circularProgressProps }
    : { ...mappedProps };

  return circularProgressContainerProps;
};
