import { AccordionIcon as ChakraAccordionIcon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AccordionIcon = ({ ...props }) => {
  return <ChakraAccordionIcon {...props} />;
};

export default AccordionIcon;

AccordionIcon.propTypes = {
  props: PropTypes.object,
};
