﻿import PropTypes from 'prop-types';
import HStack from 'common/components/Stacks/HStack';

const Card = ({ children, ...props }) => {
  return (
    <HStack
      border="1px solid"
      borderColor="gray.300"
      borderRadius={4}
      p={4}
      role="link"
      cursor="pointer"
      {...props}
    >
      {children}
    </HStack>
  );
};
Card.propTypes = {
  children: PropTypes.any,
};

export default Card;
