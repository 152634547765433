import { css } from '@emotion/react';
import styled from '@emotion/styled';

const RailNavContainer = styled.nav`
  border-right: 1px solid
    ${(props) =>
      props.isLeftDrawerCollapsed && !props.isNavigationVisible
        ? 'none'
        : props.theme.colors.gray[200]};
  transition: all 0.2s ease;
`;

const Rail = styled.ul`
  background-color: ${(props) => props.theme.colors.white};
  max-width: ${(props) => props.theme.functions.pixelsToRem(72)};
  transition: all 0.5s ease-in-out;
`;

const RailItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  margin-left: 0;
  height: 72px;
  width: 72px;
  aspect-ratio: 1;
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.uwmLogo.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[200]};

  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.blue[600]};
    color: ${(props) => props.theme.colors.white};

    > a,
    > div {
      color: ${(props) => props.theme.colors.white};
      transition: all 0.2s ease;
    }

    > a > i,
    > div > i {
      color: ${(props) => props.theme.colors.white};
      transition: all 0.2s ease;
    }

    > i {
      color: ${(props) => props.theme.colors.white};
      transition: all 0.2s ease;
    }
    > a > i > svg,
    > div > i > svg {
      color: ${(props) => props.theme.colors.white};
      transition: all 0.2s ease;
    }
  }

  & > a,
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: ${({ active, theme }) =>
      active ? theme.colors.white : theme.colors.uwmLogo.blue};
  }
  & > a > i,
  & > div > i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors.blue[600]};
  }

  & > a > i > svg,
  & > div > i > svg {
    width: ${(props) =>
      !props?.isNavigationVisible && props.isLeftDrawerCollapsed
        ? '1rem'
        : 'inherit'};
  }

  & > a > i ~ span,
  & > div > i ~ span {
    text-align: center;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.uwmLogo.blue};

      > a {
        color: ${props.theme.colors.white};
      }
    `}

  ${(props) =>
    !props.active &&
    css`
      > a {
        color: ${props.theme.colors.uwmLogo.blue};
      }
    `}
`;

export { Rail, RailItem, RailNavContainer };
