import Input from 'common/components/Inputs/Input';
import Button from 'common/components/Buttons/Button/index';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useFieldArray, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

export default function IncomeSummariesFormCPASection({
  handleChange,
  control,
  trigger,
  closeCPASection,
  readOnlyMode,
}) {
  const { fields, remove, append } = useFieldArray({
    name: 'cpaInformation',
    control,
    shouldUnregister: false,
  });
  const theme = useTheme();

  const readOnlyStyle = {
    pointerEvents: 'none',
    opacity: 0.6,
    cursor: 'not-allowed',
  };

  if (fields.length === 0) {
    append({
      customerCPADetailId: 0,
      lenderDatabaseId: 1,
      customerRecordId: '',
      employerId: '',
      cpaFirstName: '',
      cpaMiddleName: '',
      cpaLastName: '',
      cpaCompanyName: '',
    });
  }

  return (
    <Flex
      direction={'column'}
      justify={'flex-start'}
      style={readOnlyMode ? readOnlyStyle : {}}
    >
      {fields.map((field, i) => (
        <Flex
          key={field.id}
          boxSizing="border-box"
          justifyContent={'flex-start'}
          paddingTop={'1rem'}
          align={'flex-end'}
        >
          {/* Hidden Inputs */}
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type="hidden"
                defaultValue={fields[i].customerCpaDetailId}
              />
            )}
            name={`cpaInformation.${i}.customerCpaDetailId`}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type="hidden"
                defaultValue={fields[i].lenderDatabaseId}
              />
            )}
            name={`cpaInformation.${i}.lenderDatabaseId`}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type="hidden"
                defaultValue={fields[i].customerRecordId}
              />
            )}
            name={`cpaInformation.${i}.customerRecordId`}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type="hidden"
                defaultValue={fields[i].employerId}
              />
            )}
            name={`cpaInformation.${i}.employerId`}
            control={control}
          />
          <FormControl width={'25%'} padding={'0rem 1rem'}>
            <FormLabel htmlFor={`cpaFirstName-${field.id}`}>
              First Name
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  defaultValue={fields[i].cpaFirstName}
                  style={{
                    backgroundColor: theme.colors.white,
                  }}
                  tabIndex={readOnlyMode ? -1 : 0}
                  onChange={(e) =>
                    handleChange(
                      `cpaInformation.${i}.cpaFirstName`,
                      e.target.value,
                    )
                  }
                />
              )}
              name={`cpaInformation.${i}.cpaFirstName`}
              control={control}
            />
          </FormControl>
          <FormControl width={'25%'} padding={'0rem 1rem'}>
            <FormLabel htmlFor={`cpaMiddleName-${field.id}`}>
              Middle Name
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  defaultValue={fields[i].cpaMiddleName}
                  style={{
                    backgroundColor: theme.colors.white,
                  }}
                  tabIndex={readOnlyMode ? -1 : 0}
                  onChange={(e) =>
                    handleChange(
                      `cpaInformation.${i}.cpaMiddleName`,
                      e.target.value,
                    )
                  }
                />
              )}
              name={`cpaInformation.${i}.cpaMiddleName`}
              control={control}
            />
          </FormControl>
          <FormControl width={'25%'} padding={'0rem 1rem'}>
            <FormLabel htmlFor={`cpaLastName-${field.id}`}>Last Name</FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  defaultValue={fields[i].cpaLastName}
                  style={{
                    backgroundColor: theme.colors.white,
                  }}
                  tabIndex={readOnlyMode ? -1 : 0}
                  onChange={(e) =>
                    handleChange(
                      `cpaInformation.${i}.cpaLastName`,
                      e.target.value,
                    )
                  }
                />
              )}
              name={`cpaInformation.${i}.cpaLastName`}
              control={control}
            />
          </FormControl>
          <FormControl width={'25%'} padding={'0rem 1rem'}>
            <FormLabel htmlFor={`cpaCompanyName-${field.id}`}>
              Company Name
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  defaultValue={fields[i].cpaCompanyName}
                  style={{
                    backgroundColor: theme.colors.white,
                  }}
                  tabIndex={readOnlyMode ? -1 : 0}
                  onChange={(e) =>
                    handleChange(
                      `cpaInformation.${i}.cpaCompanyName`,
                      e.target.value,
                    )
                  }
                />
              )}
              name={`cpaInformation.${i}.cpaCompanyName`}
              control={control}
            />
          </FormControl>
          <Button
            title="Delete Income"
            boxSizing="borderBox"
            background={'none'}
            tabIndex={readOnlyMode ? -1 : 0}
            onClick={() => {
              remove(i);
              trigger();
              if (fields.length === 1) {
                closeCPASection();
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} color={theme.colors.red[600]} />
          </Button>
        </Flex>
      ))}
      <Flex
        boxSizing="border-box"
        justifyContent={'flex-start'}
        padding={'0.5em 1rem'}
      >
        <Button
          background={'none'}
          isPrimary={false}
          tabIndex={readOnlyMode ? -1 : 0}
          onClick={() => {
            append({
              customerCpaDetailId: 0,
              lenderDatabaseId: 1,
              customerRecordId: '',
              employerId: '',
              cpaFirstName: '',
              cpaMiddleName: '',
              cpaLastName: '',
              cpaCompanyName: '',
            });
          }}
        >
          <Flex
            align={'flex-start'}
            justify={'space-around'}
            wordBreak={'break'}
          >
            <FontAwesomeIcon
              icon={faPlusSquare}
              color={theme.colors.blue[600]}
            />
            <Text paddingLeft={'1rem'}>Add Additional CPA</Text>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  );
}
IncomeSummariesFormCPASection.propTypes = {
  handleChange: PropTypes.func,
  control: PropTypes.any,
  trigger: PropTypes.func,
  closeCPASection: PropTypes.func,
  readOnlyMode: PropTypes.bool,
};
