import palette from './palette';
import components from './components';
import typography from './typography';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette,
  components,
  typography,
  spacing: 4,
  shape: {
    borderRadius: 1,
  },
});

export default theme;
