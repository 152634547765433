import { universalSort } from './../crudGridUtils';

export const OPERATORS = {
  INCLUDE: 0,
  EXCLUDE: 1,
};

Object.freeze(OPERATORS);

export default function filterGridData(data, filters, gridSort) {
  const filterdData = data.filter((row) => {
    return filters.every((filter) => {
      if (!row[filter.fieldName]) return false;
      return filter.evaluateFilter(row, filter.value);
    });
  });
  return universalSort(filterdData, gridSort.field, gridSort.direction);
}
