import TYPES from './types';
import { API_STATUS } from 'common/constants';

const initialState = {
  status: null,
  data: null,
  error: null,
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_INSURANCE_DATA_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };

    case TYPES.GET_INSURANCE_DATA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: action.payload,
        error: null,
      };

    case TYPES.GET_INSURANCE_DATA_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error: action.payload,
      };

    case TYPES.SAVE_INSURANCE_DATA_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
        error: null,
      };

    case TYPES.SAVE_INSURANCE_DATA_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: action.payload,
        error: null,
      };

    case TYPES.SAVE_INSURANCE_DATA_ERROR:
      return {
        ...state,
        status: API_STATUS.ERROR,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default insuranceReducer;
