export default class RentalIncomeValidationData {
  constructor(data = {}) {
    this.documentOptions = Object.prototype.hasOwnProperty.call(
      data,
      'documentOptions',
    )
      ? data.documentOptions
      : [];

    this.documentSelections = this.documentOptions;
  }

  getDocumentOptions() {
    return this.documentOptions;
  }

  getDocumentSelections() {
    return this.documentSelections;
  }
}
