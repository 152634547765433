export const REO_PROPERTY_TYPE = {
  TWO_TO_4_UNIT_DWELLING: 25,
  COMMERCIAL: 51,
  CONDOMINIUM: 34,
  CONDOTELS: 23,
  COOPERATIVE: 30,
  HIGH_RISE_CONDOMINIUM: 35,
  LOW_RISE_CONDOMINIUM: 36,
  MANUFACTURED_CONDO: 45,
  MANUFACTURED_CONDO_MULTI_WIDE: 56,
  MANUFACTURED_CONDO_SINGLE_WIDE: 52,
  MANUFACTURED_HOUSING: 27,
  MANUFACTURED_MULTI_WIDE: 55,
  MANUFACTURED_PUD: 46,
  MANUFACTURED_PUD_MULTI_WIDE: 57,
  MANUFACTURED_PUD_SINGLE_WIDE: 53,
  MANUFACTURED_PURCHASE_HOME_REFIANCE_LAND: 43,
  MANUFACTURED_SINGLE_WIDE: 54,
  MID_RISE_CONDO: 39,
  MIXED_USE: 37,
  MOBILE_HOME: 26,
  MODULAR: 38,
  MODULAR_PURCHASE_HOME_REFIANCE_LAND: 44,
  MULTI_USE_PROPERTY: 31,
  NON_WARRANTABLE_CONDO: 42,
  PANELIZED: 28,
  PLAN_UNIT_DEVELOPMENT: 32,
  PREFAB: 40,
  PUD_PURCHASE_HOME_REFIANCE_LAND: 49,
  SFR_PURCHASE_HOME_REFIANCE_LAND: 47,
  SINGLE_FAMILY_RESIDENCE: 22,
  SITE_CONDO: 48,
  UNKNOWN: 0,
  VACANT_LAND: 50,
  WARRANTABLE_CONDO: 41,
};

export const REO_GROSS_RENTAL_INCOME_PROPERTY_TYPE = {
  TWO_TO_4_UNIT_DWELLING: 25,
  COMMERCIAL: 51,
  COOPERATIVE: 30,
  MANUFACTURED_CONDO: 45,
  MANUFACTURED_PUD: 46,
  MANUFACTURED_MULTI_WIDE: 55,
  MANUFACTURED_SINGLE_WIDE: 54,
  MANUFACTURED_PUD_MULTI_WIDE: 57,
  MANUFACTURED_PUD_SINGLE_WIDE: 53,
  MANUFACTURED_PURCHASE_HOME_REFIANCE_LAND: 43,
  MIXED_USE: 37,
  MOBILE_HOME: 26,
  MODULAR: 38,
  MODULAR_PURCHASE_HOME_REFIANCE_LAND: 44,
  MULTI_USE_PROPERTY: 31,
  PANELIZED: 28,
  PREFAB: 40,
  PUD_PURCHASE_HOME_REFIANCE_LAND: 49,
  SFR_PURCHASE_HOME_REFIANCE_LAND: 47,
  SINGLE_FAMILY_RESIDENCE: 22,
  UNKNOWN: 0,
  VACANT_LAND: 50,
};

export const REO_PROPERTY_STATUS = {
  PENDING: 3,
  RENTAL: 5,
  RETAINED: 2,
  SOLD: 1,
};

export const REO_INTENDED_OCCUPANCY_TYPE = {
  INVESTMENT_PROPERTY: 2,
  OWNER_OCCUPIED: 5,
  PRIMARY_RESIDENCE: 1,
  SECOND_RESIDENCE: 3,
};

export const REO_ESCROWED_PROPERTY_ANSWER = {
  BOTH_TAXES_INSURANCE: 1,
  TAXES_ONLY: 2,
  INSURANCE_ONLY: 3,
  NEITHER_ESCROWED: 4,
  I_DONT_KNOW: 5,
};

export const NON_SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY = {
  1: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
    {
      key: 3,
      value: 'Secondary Residence',
    },
  ],
  2: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 3,
      value: 'Secondary Residence',
    },
  ],
  3: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
    {
      key: 3,
      value: 'Secondary Residence',
    },
  ],
  5: [
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
};

export const NON_SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY = {
  1: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
  2: [
    {
      key: 1,
      value: 'Primary Residence',
    },
  ],
  3: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
  5: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
};

export const SUBJECT_PROP_NOT_TWO_FOUR_UNIT_OCCUPANCY = {
  2: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 3,
      value: 'Secondary Residence',
    },
  ],
  5: [
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
};

export const SUBJECT_PROP_TWO_FOUR_UNIT_OCCUPANCY = {
  2: [
    {
      key: 1,
      value: 'Primary Residence',
    },
  ],
  5: [
    {
      key: 1,
      value: 'Primary Residence',
    },
    {
      key: 2,
      value: 'Investment Property',
    },
  ],
};

export const SUBJECT_PROPERTY_PROPERTY_STATUS_OPTIONS = {
  true: [
    {
      key: 5,
      value: 'Rental Held for Income',
    },
    {
      key: 2,
      value: 'Retained',
    },
  ],
  false: [
    {
      key: 3,
      value: 'Pending',
    },
    {
      key: 5,
      value: 'Rental Held for Income',
    },
    {
      key: 2,
      value: 'Retained',
    },
    {
      key: 1,
      value: 'Sold',
    },
  ],
};

export const ESCROWED_ON_PROPERTY_OPTIONS = [
  {
    value: 1,
    text: 'Mortgage Payment Includes Taxes and Insurance',
  },
  {
    value: 2,
    text: 'Mortgage Payment Includes Taxes',
  },
  {
    value: 3,
    text: 'Mortgage Payment Includes Insurance',
  },
  {
    value: 4,
    text: 'Mortgage Payment Does Not Include Taxes or Insurance',
  },
];
