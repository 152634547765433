const MANUAL_CHECKS_TYPES = {
  GET_MANUAL_CHECKS_STATUS_STARTED: 'GET_MANUAL_CHECKS_STATUS_STARTED',
  GET_MANUAL_CHECKS_STATUS_SUCCESS: 'GET_MANUAL_CHECKS_STATUS_SUCCESS',
  GET_MANUAL_CHECKS_STATUS_ERROR: 'GET_MANUAL_CHECKS_STATUS_ERROR',

  SAVE_MANUAL_CHECKS_STATUS_STARTED: 'SAVE_MANUAL_CHECKS_STATUS_STARTED',
  SAVE_MANUAL_CHECKS_STATUS_SUCCESS: 'SAVE_MANUAL_CHECKS_STATUS_SUCCESS',
  SAVE_MANUAL_CHECKS_STATUS_ERROR: 'SAVE_MANUAL_CHECKS_STATUS_ERROR',
  SAVE_MANUAL_CHECKS_STATUS_CLEAROUT: 'SAVE_MANUAL_CHECKS_STATUS_CLEAROUT',
};

export default MANUAL_CHECKS_TYPES;
