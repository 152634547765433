﻿import PropTypes from 'prop-types';
import Box from 'common/components/Box';

const LeftCell = ({ children, ...props }) => {
  return (
    <Box
      w="50%"
      pr={2}
      borderRight="1px solid"
      borderColor="gray.300"
      overflow="wrap"
      lineHeight="24px"
      {...props}
    >
      {children}
    </Box>
  );
};
LeftCell.propTypes = {
  children: PropTypes.any,
};

export default LeftCell;
