const htmlFontSize = 16;

const pixelsToRem = (pixels) => {
  return `${pixels / htmlFontSize}rem`;
};

const typography = {
  fontFamily:
    '"Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  h1: {
    fontWeight: 500,
    fontSize: pixelsToRem(32),
    lineHeight: pixelsToRem(40),
    letterSpacing: '-0.01em',
  },
  h2: {
    fontWeight: 500,
    fontSize: pixelsToRem(24),
    lineHeight: pixelsToRem(32),
    letterSpacing: '-0.02em',
  },
  h3: {
    fontWeight: 500,
    fontSize: pixelsToRem(16),
    lineHeight: pixelsToRem(24),
    letterSpacing: '-0.02em',
  },
  h4: {
    fontWeight: 500,
    fontSize: pixelsToRem(14),
    lineHeight: pixelsToRem(24),
    letterSpacing: '-0.02em',
  },
  h5: {
    fontWeight: 500,
    fontSize: pixelsToRem(12),
    lineHeight: pixelsToRem(16),
    letterSpacing: '-0.02em',
  },
  h6: {
    fontWeight: 500,
    fontSize: pixelsToRem(10),
    lineHeight: pixelsToRem(14),
    letterSpacing: '-0.01em',
  },
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
};

export default typography;
