import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import styles from './styles';
import typography, { pixelsToRem } from './typography';
import components from './components';

const theme = extendTheme({
  colors,
  components,
  fonts: typography.fonts,
  functions: { pixelsToRem },
  styles,
  textStyles: typography,
});

export default theme;
