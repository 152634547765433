import PropTypes from 'prop-types';
import { Text } from 'common/components';
import BreakdownContainer from '../BreakdownContainer';
import Continuance from './Continuance';
import SingleLineValue from '../styles/SingleLineValue';
import BreakdownColumnBox from '../styles/BreakdownColumnBox';
import { pixelsToRem } from 'theme/typography';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import lang from '../language.json';

export default function IRA1099Breakdown({
  selectedResult,
  hideAccordion = false,
  onNewWindow,
}) {
  const formulas = selectedResult
    .getFormulas()
    .filter((formula) => !formula.formulaName.includes('Continuance'));

  return (
    <BreakdownContainer
      header={lang.HEADERS[selectedResult.getCalculatorType()]}
      hideAccordion={hideAccordion}
      onNewWindow={onNewWindow}
    >
      <SingleLineValue
        title={lang.MONTHLY_CALC_EQUATION}
        value={lang.FORMULAS.IRA_1099}
      />
      {formulas.length && (
        <BreakdownColumnBox title={lang.CALCULATION} mb={4}>
          {formulas.map(({ formulaId, formula }) => (
            <Text fontSize={pixelsToRem(14)} key={formulaId}>
              {formula}
            </Text>
          ))}
        </BreakdownColumnBox>
      )}
      <SingleLineValue
        title={lang.MONTHLY_INCOME}
        value={selectedResult.getCalculationAmount()}
        isCurrency
      />
      <Continuance selectedResult={selectedResult} />
    </BreakdownContainer>
  );
}

IRA1099Breakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};
