import html2canvas from 'html2canvas';

const print = (pageContent, pageTitle) => () => {
  const content = document.querySelector(`${pageContent}`);
  if (!content) return;
  //Queries for the different types of hidden info contents created that would want to be displayed.
  const printInfo = content.querySelector('#print-only-info');
  const commentInfo = content.querySelector('#print-comment');
  const commentReviewInfo = content.querySelector('#rb-comments');
  const rentalInfo = content.querySelector('#rental');
  const calculateBtn = content.querySelector('#calculateBtn');
  const calculationCards = document.querySelectorAll('div[role="radio"]') ?? [];
  calculationCards.forEach((el) => {
    el.style.width = '170px';
  });
  //Updates Styling to fit print format
  content.style.height = '100%';
  content.style.width = '80%';
  content.style.paddingRight = '1rem';
  content.style.paddingTop = '1rem';

  //Selects all the collapsed accordions
  const collapsedAccordions =
    document.querySelectorAll(
      'button.chakra-accordion__button[aria-expanded="false"]',
    ) ?? [];

  collapsedAccordions.forEach((el) => el.click());

  setTimeout(() => {
    if (printInfo) printInfo.style.display = 'inline-grid';
    if (commentReviewInfo) commentReviewInfo.style.display = 'none';
    if (rentalInfo) rentalInfo.style.marginTop = '10rem';
    if (calculateBtn) calculateBtn.style.display = 'none';
    if (commentInfo) {
      commentInfo.style.display = 'block';
      commentInfo.style.minHeight = commentInfo.scrollHeight + 'px';
    }

    html2canvas(content).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      PrintImage(img, pageTitle);
    });

    // resetting content style
    if (printInfo) printInfo.style.display = 'none';
    if (commentReviewInfo) commentReviewInfo.style.display = 'block';
    if (rentalInfo) rentalInfo.style.marginTop = '3rem';
    if (calculateBtn) calculateBtn.style.display = 'inline-flex';
    if (commentInfo) {
      commentInfo.style.display = 'none';
      commentInfo.style.minHeight = '';
    }
    content.style.height = '';
    content.style.width = '';
    content.style.paddingRight = '0';
    content.style.paddingTop = '0';
    collapsedAccordions.forEach((el) => el.click());
    calculationCards.forEach((el) => {
      el.style.width = '200px';
    });
  }, 250);
};

//pass another arg to this next one
function ImagetoPrint(source, pageTitle) {
  return (
    `<html lang="en-US"><head><title>${pageTitle}</title></head><script>` +
    'function step1(){\n' +
    "setTimeout('step2()', 10);}\n" +
    'function step2(){window.focus();window.print();window.close()}\n' +
    "</script></head><body onload='step1()'>\n" +
    "<img alt='Review Breakdown' src='" +
    source +
    "' /></body></html>"
  );
}

function PrintImage(source, pageTitle) {
  if (pageTitle == null || pageTitle.length === 0)
    pageTitle = 'Calculation Review';
  const Pagelink = pageTitle;
  const pwa = window.open(Pagelink, '_new');
  pwa.document.open();
  pwa.document.write(ImagetoPrint(source, pageTitle));
  pwa.document.close();
}

export default print;
