import { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from 'common/componentMapper/FormControl';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import FormLabel from 'common/componentMapper/FormLabel';
// eslint-disable-next-line import/no-unresolved
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { FormElementWidgetWithOptions } from 'lib-node-amb-renderer';

import { validationError } from '../utils';
import CustomSelect from 'common/components/CustomSelect';

export class MultiSelectWidget extends FormElementWidgetWithOptions {
  options;
  isMulti;
  constructor(props) {
    super(props);
    this.options = props.options;
    this.isMulti = props.isMulti;
  }
}

export default function MultiSelect(props) {
  const {
    options,
    input,
    label,
    placeholder,
    fcStyle,
    isMulti,
    meta,
    validateOnMount,
    onChange,
    onBlur,
    onFocus,
  } = useFieldApi({
    ...props,
  });

  let isMultiSet = isMulti === 'true';

  const invalid = validationError(meta, validateOnMount);
  const text = invalid || ((meta.touched || validateOnMount) && meta.warning);
  // eslint-disable-next-line react/prop-types
  let selectName = props.name;
  const [values, setValues] = useState(getValuesLabel(input.value));

  // helpers
  function isNotApplicable(value) {
    return [
      'IncomeId:0,BorrowerRecordId:0,BorrowerLenderDatabaseId:0',
      'AddressId:0,AddressLdId:0,IsAddressSubjectProperty:False',
      'AssetId:0,AssetLdId:0',
    ].includes(value);
  }

  function isNotListedAddToSystem(value) {
    return ['AssetId:-100,AssetLdId:-100'].includes(value);
  }

  function getValuesLabel(value) {
    let theLabelValue = [];

    if (value) {
      theLabelValue = options.reduce((acc, item) => {
        if (value.includes(item.value)) {
          acc.push({
            label: item.label,
            value: item.value,
          });
        }
        return acc;
      }, []);
    }
    return theLabelValue;
  }
  function sendValuesNoLabels(options) {
    let theValues = [];

    if (options) {
      theValues = options.reduce((acc, item) => {
        acc.push(item.value);
        return acc;
      }, []);
    }
    return theValues;
  }

  const handleChange = (allSelectedOptions, currentSelection) => {
    let singleSelectDisplayArray = [];
    let singleSelectSaveArray = [];

    if (!isMultiSet) {
      // handle change as a single select
      singleSelectSaveArray.push(allSelectedOptions.value);
      singleSelectDisplayArray.push(allSelectedOptions);
      setValues(singleSelectDisplayArray);
      input.onChange(singleSelectSaveArray);
      return;
    }

    // if NA selected, clear remaining selections
    if (isNotApplicable(currentSelection?.value)) {
      setValues([{ label: 'Not Applicable', value: currentSelection.value }]);
      input.onChange([currentSelection.value]);
    } else if (isNotListedAddToSystem(currentSelection?.value)) {
      setValues([
        {
          label: 'Not Listed - Add to system',
          value: currentSelection.value,
        },
      ]);
      input.onChange([currentSelection.value]);

      // if any option other than NA is selected, clear NA from list
    } else {
      const remaining = allSelectedOptions?.filter(
        (option) =>
          !isNotApplicable(option.value) &&
          !isNotListedAddToSystem(option.value),
      );

      const sV = remaining.length ? sendValuesNoLabels(remaining) : [];
      remaining.length ? setValues(remaining) : setValues([]);
      input.onChange(sV);
    }

    onChange?.(currentSelection);
  };

  return (
    <FormControl sx={{ ...fcStyle }} isInvalid={!!invalid}>
      <FormLabel mb="unset">{label}</FormLabel>
      <CustomSelect
        isMulti={isMultiSet}
        value={values}
        placeholder={placeholder}
        options={options}
        onChange={(allSelectedOptions, { option: currentSelection }) => {
          handleChange(allSelectedOptions, currentSelection);
        }}
        onBlur={(e) => {
          input.onBlur(e);
          onBlur?.(e);
        }}
        onFocus={(e) => onFocus?.(e)}
        hasError={!!invalid}
        inputId={selectName}
        data-tracking={selectName}
        data-testid={selectName}
      />
      {!!invalid && <FormErrorMessage>{text}</FormErrorMessage>}
    </FormControl>
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
