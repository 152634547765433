﻿import { combineReducers } from 'redux/es/redux.mjs';
import { set, cloneDeep, isEqual } from 'lodash';
import {
  CALCULATION_HISTORY_TYPES,
  CALCULATION_TYPES,
  CUSTOMER_EMPLOYMENT_TYPES,
  EMPLOYER_DOCUMENT_TYPES,
  GET_ANSWERED_QUESTIONS_TYPES,
  GET_ASSOC_INCOMES_TYPES,
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES,
  GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES,
  GET_EMPLOYMENT_QUESTIONS_TYPES,
  GET_INCOME_VALIDATION_KEY_DATES_TYPES,
  GET_OTHER_INCOME_DATA_TYPES,
  PAY_STUB_TYPES,
  RUN_CALCS_TYPES,
  SAVE_CALC_TYPES,
  SAVE_INCOME_DOCUMENTS_TYPES,
  SAVE_SELECTED_INCOME,
  SAVE_VALIDATE_DOCUMENTS_TYPES,
  SET_LOAN_NUMBER,
  SET_PAYSTUB_ADDRESS_ALERT,
  SET_PAYSTUB_DEDUCTION_ALERT,
  SET_W2_ADDRESS_ALERT,
  VALIDATE_FORM_1099_TYPES,
  VALIDATE_SCHEDULE_C_TYPES,
  VALIDATION_TYPES,
  UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES,
  W2_TYPES,
  WVOE_TYPES,
  INCOME_RESOLUTION_GET,
  INCOME_RESOLUTION_SAVE,
  RESET_SELECTED_INCOME,
  GET_ASSOC_INCOME_DOCS_TYPES,
} from './types';

import { EmploymentReviewReducer } from '../Pages/EmploymentReview/redux';

import API_STATUS from '../../../common/constants/api-status';
import { setEmployerPayStubInfoData } from '../model/Paystub/EmploymentPayStubDataController';
import { setEmployerW2InfoData } from '../model/W2/EmploymentW2DataController';

import { setOtherIncomeData } from '../model/OtherIncomeDataController';
import { setIncomeValidationKeyDatesData } from '../model/IncomeValidationKeyDatesDataController';

import ObfuscatedLoanIdentifierDataModel from '../../../model/ObfuscatedLoanIdentifierDataModel';
import WvoeResponseDataModel from '../model/Wvoe/WvoeResponseDataModel';
import IncomeRecordData from '../model/IncomeRecordDataModel';
import SelectedCalculatorDataModel from '../model/SelectedCalculatorDataModel';
import EmploymentIncomeValidationData from '../model/EmploymentIncomeValidationData/EmploymentIncomeValidationDataModel';
import IncomeSummaryDataModel from '../model/IncomeSummary/IncomeSummaryDataModel';
import PreviousIncomeSummaryDataModel from '../model/IncomeSummary/PreviousIncomeSummaryDataModel';
import GenericCalculationItemDataModel from '../model/GenericCalculationItemDataModel';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import W2ResponseDataModel from '../model/W2/W2ResponseDataModel';
import Form1099ValidationInfoDataModel from '../model/1099/Form1099ValidationInfoDataModel';
import EmployerInfoData from '../model/Paystub/EmployerInfoDataModel';

export function loanReducer(state = null, action) {
  if (action.type === SET_LOAN_NUMBER) {
    return new ObfuscatedLoanIdentifierDataModel(action.payload);
  }
  return state;
}

function customerEmploymentDetailReducer(
  state = { status: null, data: null, error: null },
  action,
) {
  switch (action.type) {
    case CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };
    case CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        data: new IncomeRecordData(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case CUSTOMER_EMPLOYMENT_TYPES.GET_CUSTOMER_EMPLOYMENT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    default:
      return state;
  }
}

function employerDocumentReducer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_SUCCESS:
      return {
        data: action.payload.isPrevious
          ? new PreviousIncomeSummaryDataModel(action.payload)
          : new IncomeSummaryDataModel(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };

    case EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case EMPLOYER_DOCUMENT_TYPES.GET_EMPLOYER_DOCUMENT_RESET:
      return {
        status: null,
        error: null,
        data: null,
      };
    default:
      return state;
  }
}

function associatedIncomeDocumentsReducer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_STARTED:
      return {
        status: API_STATUS.GETTING,
        data: null,
        error: null,
      };

    case GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_SUCCESS:
      return {
        status: API_STATUS.DONE,
        data: action.payload,
        error: null,
      };

    case GET_ASSOC_INCOME_DOCS_TYPES.GET_ASSOC_INCOME_DOCS_ERROR:
      return {
        status: API_STATUS.ERROR,
        data: null,
        error: action.payload,
      };

    default:
      return state;
  }
}

function documentExtractionReducer(
  state = {
    state: {},
    data: {},
  },
  action,
) {
  switch (action.type) {
    case WVOE_TYPES.VALIDATION_SUMMARY_STARTED:
      delete state.data[action.payload.type];
      return { ...state };

    case WVOE_TYPES.VALIDATION_SUMMARY_SUCCESS:
      state.state[action.payload.type] = {
        status: API_STATUS.DONE,
        error: null,
      };
      state.data[action.payload.type] = action.payload.data;
      return { ...state };

    case WVOE_TYPES.VALIDATION_SUMMARY_ERROR:
      state.state[action.payload.type] = {
        status: API_STATUS.ERROR,
        error: action.payload.data,
      };
      delete state.data[action.payload.type];

      return { ...state };
    default:
      return state;
  }
}

function OtherIncomeDataReducer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_SUCCESS:
      return {
        status: API_STATUS.DONE,
        error: null,
        data: setOtherIncomeData(action.payload),
      };
    case GET_OTHER_INCOME_DATA_TYPES.GET_OTHER_INCOME_DATA_ERROR:
      return {
        status: API_STATUS.ERROR,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

function employerPayStubInfoReducer(
  state = {
    status: null,
    error: null,
    data: new EmployerInfoData(),
  },
  action,
) {
  switch (action.type) {
    case PAY_STUB_TYPES.GET_PAY_STUB_INFO_SUCCESS:
      return {
        data: setEmployerPayStubInfoData(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case PAY_STUB_TYPES.GET_PAY_STUB_INFO_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case PAY_STUB_TYPES.GET_PAY_STUB_INFO_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case PAY_STUB_TYPES.GET_PAY_STUB_INFO_CLEAR:
      return {
        status: null,
        error: null,
        data: new EmployerInfoData(),
      };
    default:
      return state;
  }
}

function employerW2InfoReducer(
  state = {
    status: null,
    error: null,
    data: new EmployerInfoData(),
  },
  action,
) {
  switch (action.type) {
    case W2_TYPES.GET_W2_INFO_SUCCESS:
      return {
        data: setEmployerW2InfoData(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case W2_TYPES.GET_W2_INFO_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case W2_TYPES.GET_W2_INFO_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case W2_TYPES.GET_W2_INFO_CLEAR:
      return {
        status: null,
        error: null,
        data: setEmployerW2InfoData(),
      };
    default:
      return state;
  }
}

function validateScheduleCInfoReducer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case VALIDATE_SCHEDULE_C_TYPES.STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case VALIDATE_SCHEDULE_C_TYPES.SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: API_STATUS.DONE,
      };
    case VALIDATE_SCHEDULE_C_TYPES.ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case VALIDATE_SCHEDULE_C_TYPES.CLEAR:
      return {
        status: null,
        error: null,
        data: null,
      };

    default:
      return state;
  }
}

function validateForm1099InfoReducer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_SUCCESS:
      return {
        data: new Form1099ValidationInfoDataModel(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case VALIDATE_FORM_1099_TYPES.GET_FORM_1099_INFO_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    default:
      return state;
  }
}

function employmentIncomeValidationDataReducer(
  state = {
    status: null,
    error: null,
    data: new EmploymentIncomeValidationData(),
  },
  action,
) {
  switch (action.type) {
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_SUCCESS:
      return {
        data: new EmploymentIncomeValidationData(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    default:
      return state;
  }
}

function employmentIncomeValidationDataWithFieldResolutionReducer(
  state = {
    status: null,
    error: null,
    data: new EmploymentIncomeValidationData(),
  },
  action,
) {
  switch (action.type) {
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS:
      return {
        data: new EmploymentIncomeValidationData(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.UPDATE_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_SUCCESS: {
      const stateCopy = cloneDeep(state);
      set(stateCopy, action.payload.path, action.payload.value);
      return stateCopy;
    }
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case GET_EMPLOYMENT_INCOME_VALIDATION_DATA_TYPES.GET_EMPLOYMENT_INCOME_VALIDATION_DATA_CLEAR:
      return {
        status: null,
        error: null,
        data: new EmploymentIncomeValidationData(),
      };

    default:
      return state;
  }
}

// Get Associated Income Calculations
const calculationResultMapper = (data, comment) =>
  data.calculationItems.map((item) => ({
    ...item,
    calculationId: data.calculationId,
    calculatorTypeId: data.calculatorTypeId,
    inputFields: data.inputFields,
    overrideAmounts: data.overrideAmounts ?? {},
    comment: comment ?? '',
  }));

function parseCalculationResultData(payload) {
  return [
    payload.genericIncomeData
      .map((data) => calculationResultMapper(data, payload.comment))
      ?.flat()
      .map((item) => new GenericCalculationItemDataModel(item)) ?? [],
    payload.voeData
      .map((data) => calculationResultMapper(data, payload.comment))
      ?.flat()
      .map((item) => new WvoeResponseDataModel(item)) ?? [],
    payload.wageEarnerData
      .map((data) => calculationResultMapper(data, payload.comment))
      ?.flat()
      .map((item) => new W2ResponseDataModel(item)) ?? [],
  ].flat();
}

function calculationsResultsReducer(
  state = {
    status: null,
    error: null,
    loanCalculationId: null,
    items: [],
    selectedResult: null,
  },
  action,
) {
  switch (action.type) {
    case GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_SUCCESS:
      return {
        loanCalculationId: action.payload.loanCalculationId,
        items: parseCalculationResultData(action.payload),
        selectedResult: null,
        status: API_STATUS.DONE,
        error: null,
      };
    case GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        items: [],
        selectedResult: null,
      };
    case GET_ASSOC_INCOMES_TYPES.GET_ASSOC_INCOMES_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
        items: [],
        selectedResult: null,
      };
    case GET_ASSOC_INCOMES_TYPES.SET_SELECTED_ASSOC_INCOME_RESULT:
      return {
        ...state,
        selectedResult: action.payload,
      };
    default:
      return state;
  }
}

function validationReducer(
  state = {
    forms: [],
  },
  action,
) {
  let documentTypeIndex, documentIdIndex;
  const updatedForms = [...state.forms];

  switch (action.type) {
    case VALIDATION_TYPES.ADD_VALIDATION_FORM_BY_DOCUMENT_TYPE:
      documentTypeIndex = state.forms.findIndex(
        (form) => form.getDocumentType() === action.payload.getDocumentType(),
      );
      if (documentTypeIndex !== -1) {
        updatedForms[documentTypeIndex] = action.payload;
      } else {
        updatedForms.push(action.payload);
      }
      return {
        ...state,
        forms: updatedForms,
      };
    case VALIDATION_TYPES.ADD_VALIDATION_FORM_BY_DOCUMENT_ID:
      documentIdIndex = state.forms.findIndex(
        (form) => form.getDocumentId() === action.payload.getDocumentId(),
      );
      if (documentIdIndex !== -1) {
        updatedForms[documentIdIndex] = action.payload;
      } else {
        updatedForms.push(action.payload);
      }
      return {
        ...state,
        forms: updatedForms,
      };
    default:
      return state;
  }
}

function incomeValidationKeyDatesReducer(
  state = { status: null, error: null, data: null },
  action,
) {
  switch (action.type) {
    case GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: null,
      };
    case GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_SUCCESS:
      return {
        status: API_STATUS.DONE,
        error: null,
        data: setIncomeValidationKeyDatesData(action.payload),
      };
    case GET_INCOME_VALIDATION_KEY_DATES_TYPES.GET_INCOME_VALIDATION_KEY_DATES_ERROR:
      return {
        status: API_STATUS.ERROR,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

function calculationReducer(
  state = {
    incomes: [],
  },
  action,
) {
  let borrower;
  let givenYearIndex;
  let borrowerIndex;
  let forms;

  switch (action.type) {
    case CALCULATION_TYPES.ADD_FORM:
      borrowerIndex = state.incomes.findIndex((income) =>
        isEqual(income.incomeIdentifier, action.payload.incomeIdentifier),
      );

      if (borrowerIndex === -1) {
        borrower = {
          incomeIdentifier: action.payload.incomeIdentifier,
          forms: [],
        };
      } else {
        borrower = state.incomes[borrowerIndex];
      }
      forms = borrower.forms;

      givenYearIndex = forms.findIndex(
        (result) =>
          result.getFiscalYear() === action.payload.form.getFiscalYear(),
      );

      if (givenYearIndex !== -1) {
        forms[givenYearIndex] = action.payload.form;
      } else {
        forms = [...forms, action.payload.form];
      }
      borrower.forms = forms;

      if (borrowerIndex === -1) {
        return {
          incomes: [...state.incomes, borrower],
        };
      } else {
        state.incomes[borrowerIndex] = borrower;
        return {
          incomes: [...state.incomes],
        };
      }

    case CALCULATION_TYPES.CLEAR:
      borrowerIndex = state.incomes.findIndex((income) =>
        isEqual(income.incomeIdentifier, action.payload.incomeIdentifier),
      );

      if (borrowerIndex !== -1) {
        state.incomes[borrowerIndex].forms = [];
        return {
          incomes: [...state.incomes],
        };
      }

      return state;

    default:
      return state;
  }
}

function employmentQuestionsReducer(
  state = {
    status: null,
    error: null,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: [],
      };
    case GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_SUCCESS:
      return {
        status: API_STATUS.DONE,
        error: null,
        data: action.payload,
      };
    case GET_EMPLOYMENT_QUESTIONS_TYPES.GET_EMPLOYMENT_QUESTIONS_ERROR:
      return {
        status: API_STATUS.ERROR,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

function answeredEmploymentQuestionsReducer(
  state = {
    status: null,
    error: null,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_STARTED:
      return {
        status: API_STATUS.GETTING,
        error: null,
        data: [],
      };
    case GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_SUCCESS:
      return {
        status: API_STATUS.DONE,
        error: null,
        data: action.payload,
      };
    case GET_ANSWERED_QUESTIONS_TYPES.GET_ANSWERED_QUESTIONS_ERROR:
      return {
        status: API_STATUS.ERROR,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

function saveCalcReducer(
  state = {
    status: null,
    error: null,
  },
  action,
) {
  switch (action.type) {
    case SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_SUCCESS:
      if (action.payload === false) {
        return {
          status: API_STATUS.ERROR,
          error: action.payload,
        };
      }
      return {
        status: API_STATUS.DONE,
        error: null,
      };
    case SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_STARTED:
      return {
        status: API_STATUS.UPDATING,
        error: null,
      };
    case SAVE_CALC_TYPES.SAVE_EMPLOYMENT_INCOME_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case SAVE_CALC_TYPES.SAVE_CALCULATION_RESET_STATUS:
      return {
        status: null,
        error: null,
      };
    default:
      return state;
  }
}

function saveIncomeSummaryDocuments(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_SUCCESS:
      return {
        data: action.payload,
        status: API_STATUS.DONE,
        error: null,
      };
    case SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_STARTED:
      return {
        status: API_STATUS.UPDATING,
        error: null,
        data: null,
      };
    case SAVE_INCOME_DOCUMENTS_TYPES.SAVE_INCOME_DOCUMENTS_ERROR:
      return {
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case SAVE_INCOME_DOCUMENTS_TYPES.RESET_INCOME_DOCUMENTS:
      return {
        error: null,
        status: null,
      };
    default:
      return state;
  }
}

function saveValidateDocumentsQuestionAnswer(
  state = {
    status: null,
    error: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_SUCCESS:
      return {
        data: action.payload,
        status: API_STATUS.DONE,
        error: null,
      };
    case SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_STARTED:
      return {
        status: API_STATUS.UPDATING,
        data: null,
        error: null,
      };
    case SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: API_STATUS.ERROR,
      };
    case SAVE_VALIDATE_DOCUMENTS_TYPES.SAVE_VALIDATE_DOCUMENTS_CLEAR:
      return {
        status: null,
        error: null,
        data: null,
      };

    default:
      return state;
  }
}

export function selectedIncomeReducer(state = null, action) {
  switch (action.type) {
    case SAVE_SELECTED_INCOME:
      return new IncomeRecordData(action.payload);
    case RESET_SELECTED_INCOME:
      return null;
    default: {
      return state;
    }
  }
}

export function setAddressAlert(
  state = {
    w2AddressAlert: false,
    payStub: false,
    payStubDeductionAlert: false,
  },
  action,
) {
  switch (action.type) {
    case SET_PAYSTUB_ADDRESS_ALERT:
    case SET_W2_ADDRESS_ALERT:
    case SET_PAYSTUB_DEDUCTION_ALERT:
      return {
        ...state,
        ...action.payload,
      };

    default: {
      return state;
    }
  }
}

export function calculationHistoryReducer(
  state = {
    error: null,
    summaryStatus: null,
    summaries: null,
    historicalDataStatus: null,
    historicalData: null,
  },
  action,
) {
  switch (action.type) {
    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_STARTED:
      return {
        summaryStatus: API_STATUS.GETTING,
        error: null,
        summaries: null,
        historicalDataStatus: null,
        historicalData: null,
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_SUCCESS:
      return {
        summaryStatus: API_STATUS.DONE,
        summaries: action.payload.calculationSummaries.map(
          (summary) => new HistoricalCalculationDataModel(summary),
        ),
        error: null,
        historicalDataStatus: null,
        historicalData: null,
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_ERROR:
      return {
        summaryStatus: API_STATUS.ERROR,
        summaries: null,
        error: action.payload,
        historicalData: null,
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_STARTED:
      return {
        ...state,
        historicalDataStatus: API_STATUS.GETTING,
        error: null,
        historicalData: null,
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_SUCCESS:
      return {
        ...state,
        historicalDataStatus: API_STATUS.DONE,
        error: null,
        historicalData: {
          items: parseCalculationResultData(action.payload),
          loanCalculationId: action.payload.loanCalculationId,
          comments: action.payload.comment,
        },
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_ERROR:
      return {
        ...state,
        historicalDataStatus: API_STATUS.ERROR,
        error: action.payload,
        historicalData: null,
      };

    case CALCULATION_HISTORY_TYPES.CALC_HISTORY_RESULT_RESET:
      return {
        ...state,
        historicalDataStatus: null,
        historicalData: null,
      };

    default:
      return state;
  }
}

export function selectedCalculatorReducer(
  state = {
    error: null,
    status: null,
    data: null,
    initialData: null,
  },
  action,
) {
  if (action.type === CALCULATION_TYPES.UPDATE_CALCULATION_FIELD) {
    let { fiscalYear, documentType, fieldId, value } = action.payload;

    if (!value) {
      value = '';
    } else if (value instanceof Date) {
      value = value.toLocaleDateString('en-US');
    }

    const newState = cloneDeep(state);
    const document = newState.data?.getDocument(documentType, fiscalYear);
    const field = document?.getInputField(fieldId);

    if (!document || !field) {
      return state;
    }

    field.setValue(value);

    return newState;
  }

  switch (action.type) {
    case CALCULATION_TYPES.GET_SELECTED_CALCULATOR_ERROR:
      return {
        error: action.error,
        status: API_STATUS.ERROR,
        data: null,
        initialData: null,
      };
    case CALCULATION_TYPES.GET_SELECTED_CALCULATOR_STARTED:
      return {
        error: null,
        status: API_STATUS.GETTING,
        data: null,
        initialData: null,
      };
    case CALCULATION_TYPES.GET_SELECTED_CALCULATOR_SUCCESS:
      return {
        data: new SelectedCalculatorDataModel(action.payload),
        initialData: new SelectedCalculatorDataModel(action.payload),
        status: API_STATUS.DONE,
        error: null,
      };
    case CALCULATION_TYPES.GET_SELECTED_CALCULATOR_CLEAR:
      return {
        error: null,
        status: null,
        data: null,
        initialData: null,
      };
    default: {
      return state;
    }
  }
}

export function runCalculationsReducer(
  state = {
    error: null,
    status: null,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case RUN_CALCS_TYPES.RUN_CALCS_STARTED:
      return {
        error: null,
        status: API_STATUS.UPDATING,
        data: null,
      };
    case RUN_CALCS_TYPES.RUN_CALCS_SUCCESS:
      return {
        error: null,
        status: API_STATUS.SUCCESS,
        data: action.payload,
      };
    case RUN_CALCS_TYPES.RUN_CALCS_ERROR:
      return {
        error: action.payload,
        status: API_STATUS.ERROR,
        data: null,
      };
    case RUN_CALCS_TYPES.RUN_CALCS_RESET:
      return {
        error: null,
        status: null,
        data: null,
      };
    default:
      return state;
  }
}

const incomeResolutionInitialState = {
  statusGet: null,
  statusSave: null,
  error: null,
  data: null,
};
function incomeResolutionReducer(
  state = incomeResolutionInitialState,
  { type, payload },
) {
  switch (type) {
    case INCOME_RESOLUTION_GET.STARTED:
      return {
        ...incomeResolutionInitialState,
        statusGet: API_STATUS.GETTING,
        statusSave: null,
        data: null,
      };
    case INCOME_RESOLUTION_GET.SUCCESS:
      return {
        ...state,
        statusGet: API_STATUS.DONE,
        data: payload,
      };
    case INCOME_RESOLUTION_GET.ERROR:
      return {
        ...state,
        statusGet: API_STATUS.ERROR,
        error: payload,
      };
    case INCOME_RESOLUTION_SAVE.STARTED:
      return {
        ...state,
        statusSave: API_STATUS.UPDATING,
      };
    case INCOME_RESOLUTION_SAVE.SUCCESS:
      return {
        ...state,
        statusSave: API_STATUS.DONE,
      };
    case INCOME_RESOLUTION_SAVE.ERROR:
      return {
        ...state,
        statusSave: API_STATUS.ERROR,
      };
    default:
      return state;
  }
}

const IncomeCalculatorReducer = combineReducers({
  loan: loanReducer,
  employmentReviewData: EmploymentReviewReducer,
  incomeDocumentInfo: employerDocumentReducer,
  associatedIncomeDocuments: associatedIncomeDocumentsReducer,
  calculationsData: calculationsResultsReducer,
  calculationHistory: calculationHistoryReducer,
  calculation: calculationReducer,
  selectedIncome: selectedIncomeReducer,
  saveCalculation: saveCalcReducer,
  saveIncomeSummary: saveIncomeSummaryDocuments,
  validateDocumentQuestionAnswer: saveValidateDocumentsQuestionAnswer,
  addressAlert: setAddressAlert,
  otherIncomeData: OtherIncomeDataReducer,
  employerPayStubValidationInfo: employerPayStubInfoReducer,
  employerW2ValidationInfo: employerW2InfoReducer,
  selectedCalculatorData: selectedCalculatorReducer,
  employmentIncomeValidationData: employmentIncomeValidationDataReducer,
  employmentIncomeValidationDataWithFieldResolution:
    employmentIncomeValidationDataWithFieldResolutionReducer,
  runCalculations: runCalculationsReducer,
  validateScheduleC: validateScheduleCInfoReducer,
  validateForm1099: validateForm1099InfoReducer,
  validation: validationReducer,
  employmentQuestions: employmentQuestionsReducer,
  answeredQuestions: answeredEmploymentQuestionsReducer,
  incomeValidationKeyDates: incomeValidationKeyDatesReducer,
  extractedDocuments: documentExtractionReducer,
  customerEmploymentDetails: customerEmploymentDetailReducer,
  incomeResolution: incomeResolutionReducer,
});
export default IncomeCalculatorReducer;
