import { Text as ChakraText } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Text = ({ children, ...props }) => {
  return <ChakraText {...props}>{children}</ChakraText>;
};

export default Text;

Text.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
