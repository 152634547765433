import {
  CircularProgress as MUICircularProgress,
  circularProgressClasses,
} from '@mui/material';
import PropTypes from 'prop-types';
import { circularProgressMapper } from '../PropertyMappers/CircularProgressMapper';
import Box from '../Box';

const Spinner = ({ emptyColor = 'gray.200', ...props }) => {
  const { sx, ...circularProgressProps } = circularProgressMapper(props);

  return (
    <Box sx={{ position: 'relative' }}>
      <MUICircularProgress
        aria-label="Loading"
        sx={{ color: emptyColor }}
        size={circularProgressProps.size}
        thickness={circularProgressProps.thickness}
      />

      <MUICircularProgress
        {...circularProgressProps}
        sx={{
          ...sx,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
      />
    </Box>
  );
};

Spinner.propTypes = {
  emptyColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sx: PropTypes.object,
  value: PropTypes.number,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static']),
  disableShrink: PropTypes.bool,
};

export default Spinner;
