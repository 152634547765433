import React from 'react';
import PropTypes from 'prop-types';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import BreakdownValue from '../styles/BreakdownValue';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import { FORMULA1120 } from '../../../constants';
import { FormTypes } from '../../../enums';
import lang from '../language.json';

const {
  FIELDS: { ['1120']: FIELDS },
} = lang;

const Value = ({ monthlyIncome, children }) => (
  <BreakdownValue
    name={FIELDS.CALCULATED_MONTHTY_INCOME}
    value={monthlyIncome}
    isCurrency={true}
  >
    {children}
  </BreakdownValue>
);

const OneYearIncomeScenario = ({
  monthlyIncome,
  currentYear,
  currentAnnual,
}) => (
  <Value monthlyIncome={monthlyIncome}>
    <BreakdownValue
      name={`Annual Income for ${currentYear}`}
      value={currentAnnual}
    />
    <BreakdownValue name="Months" value={<>&divide;12</>} isCurrency={false} />
  </Value>
);

const TwoYearIncomeScenario = ({
  monthlyIncome,
  currentYear,
  priorYear,
  currentAnnual,
  priorAnnual,
}) => (
  <Value monthlyIncome={monthlyIncome}>
    <BreakdownValue
      name={`Annual Income for ${currentYear}`}
      value={currentAnnual}
    />
    <BreakdownValue
      name={`Annual Income for ${priorYear}`}
      value={priorAnnual}
    />
    <BreakdownValue name="Months" value={<>&divide;24</>} isCurrency={false} />
  </Value>
);

const MonthlyIncomeValue = ({ selectedResult, currentYear, formulaId }) => {
  const priorYear = currentYear - 1;

  const props = {
    monthlyIncome: selectedResult.getCalculationAmount(),
    currentYear,
    priorYear,
    currentAnnual: selectedResult.getInputAsFloat(
      FormTypes.Form1120,
      currentYear,
      'annualIncome',
    ),
    priorAnnual: selectedResult.getInputAsFloat(
      FormTypes.Form1120,
      priorYear,
      'annualIncome',
    ),
  };

  switch (formulaId) {
    case FORMULA1120.OneYearPositiveOrNegativeTend:
    case FORMULA1120.OneYrNegBreakDownFormulaId:
      return <OneYearIncomeScenario {...props} />;

    case FORMULA1120.TwoYearPositiveOrNegativeTrend:
    case FORMULA1120.TwoYrNegBreakdownFormulaId:
      return <TwoYearIncomeScenario {...props} />;

    default:
      return null;
  }
};
MonthlyIncomeValue.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
  formulaId: PropTypes.number,
};

const MonthlyIncome = (props) => {
  return (
    <BreakdownYearContainer title="Monthly Income Calculation" mb={4}>
      <MonthlyIncomeValue {...props} />
    </BreakdownYearContainer>
  );
};

Value.propTypes = {
  monthlyIncome: PropTypes.number,
  children: PropTypes.node,
};
OneYearIncomeScenario.propTypes = {
  monthlyIncome: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  currentAnnual: PropTypes.number.isRequired,
};
TwoYearIncomeScenario.propTypes = {
  monthlyIncome: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  priorYear: PropTypes.number.isRequired,
  currentAnnual: PropTypes.number.isRequired,
  priorAnnual: PropTypes.number.isRequired,
};
MonthlyIncome.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
  formulaId: PropTypes.number.isRequired,
};

export default MonthlyIncome;
