﻿import PropTypes from 'prop-types';
import { CalculationTypes } from '../../enums';
import W2ResponseDataModel from '../../model/W2/W2ResponseDataModel';
import WvoeResponseDataModel from '../../model/Wvoe/WvoeResponseDataModel';
import GenericCalculationItemDataModel from '../../model/GenericCalculationItemDataModel';
import RentalCalculationReviewBreakdown from 'pages/RentalIncome/RentalIncomeCalculation/model/RentalCalculationReviewBreakdown';

// Breakdowns
import SocialSecurityBreakdown from '../../Pages/ReviewBreakdown/RetirementIncome/SocialSecurity/CalculationBreakdown';
import PensionBreakdown from '../../Pages/ReviewBreakdown/RetirementIncome/Pension/CalculationBreakdown';
import DividendsAndInterestBreakdown from '../../Pages/ReviewBreakdown/DividendsAndInterest/CalculationBreakdown';
import CapitalGainsBreakdown from '../../Pages/ReviewBreakdown/CapitalGainsBreakdown/CalculationBreakdown';
import ChildSupportBreakdown from 'pages/IncomeCalculator/ChildSupport/Pages/ReviewBreakdown/CalculationBreakdown';
import ScheduleCBreakdown from '../../Pages/ReviewBreakdown/ScheduleC/CalculationBreakdown';
import Form1120SBreakdown from '../../Pages/ReviewBreakdown/1120s/CalculationBreakdown';
import Form1120Breakdown from '../../Pages/ReviewBreakdown/1120/CalculationBreakdown';
import Form1065Breakdown from '../../Pages/ReviewBreakdown/1065/CalculationBreakdown';
import PaystubBreakdown from '../../Pages/ReviewBreakdown/W2/CalculationBreakdown';
import WvoeBreakdown from '../../Pages/ReviewBreakdown/Wvoe/CalculationBreakdown';
import Rental1040 from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/Rental1040';
import Rental1007 from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/Rental1007';
import LeaseAgreement from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/LeaseAgreement';
import Rental998 from 'pages/RentalIncome/RentalIncomeCalculation/ReviewBreakdown/Rental998';
import IraDistributions1040 from 'pages/IncomeCalculator/Pages/ReviewBreakdown/IraDistributions/IRA1040Breakdown';
import IraDistributions1099 from 'pages/IncomeCalculator/Pages/ReviewBreakdown/IraDistributions/IRA1099Breakdown';
import IraDistributionsCurrentReceipt from 'pages/IncomeCalculator/Pages/ReviewBreakdown/IraDistributions/IRABankBreakdown';
import NoCalcCalculationBreakdown from 'pages/IncomeCalculator/Pages/ReviewBreakdown/NoCalcCalculationBreakdown';

const CalculationBreakdown = ({
  results,
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const buildRentalModel = () =>
    new RentalCalculationReviewBreakdown({
      genericIncomeData: [
        {
          ...selectedResult,
          calculationItems: [selectedResult],
        },
      ],
      comment: selectedResult.comments,
    });

  switch (selectedResult.calculatorTypeId) {
    case CalculationTypes.SocialSecurityAwardLetter:
    case CalculationTypes.SocialSecurity1040:
    case CalculationTypes.SocialSecurity1099:
    case CalculationTypes.SocialSecurityBankStatement:
      return (
        <SocialSecurityBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.PensionAwardLetter:
    case CalculationTypes.Pension1099:
    case CalculationTypes.Pension1040:
    case CalculationTypes.PensionBankStatement:
      return (
        <PensionBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.ScheduleC:
      return (
        <ScheduleCBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.SoleProprietorship:
      return (
        <Form1120SBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.Partnership:
      return (
        <Form1065Breakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.Corporation:
      return (
        <Form1120Breakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.PayStub:
      return (
        <PaystubBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hasWvoeCalculation={
            !!results?.find(
              ({ calculatorTypeId }) =>
                calculatorTypeId === CalculationTypes.Wvoe,
            )
          }
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.Wvoe:
    case CalculationTypes.YearEndPaystub:
    case CalculationTypes.VoeWithPaystub:
      return (
        <WvoeBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.InterestDividends:
      return (
        <DividendsAndInterestBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );
    case CalculationTypes.CapitalGains1040:
      return (
        <CapitalGainsBreakdown
          selectedResult={selectedResult}
          onNewWindow={onNewWindow}
          hideAccordion={hideAccordion}
        />
      );
    case CalculationTypes.Rental1040:
      return (
        <Rental1040
          formulas={JSON.parse(
            selectedResult.getFormulas()[0]?.getFormula() || '{}',
          )}
          data={buildRentalModel()}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.Rental1007_1025:
      return (
        <Rental1007
          formulas={JSON.parse(
            selectedResult.getFormulas()[0]?.getFormula() || '{}',
          )}
          data={buildRentalModel()}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.Rental998:
      return (
        <Rental998
          formulas={JSON.parse(
            selectedResult.getFormulas()[0]?.getFormula() || '{}',
          )}
          data={buildRentalModel()}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.LeaseAgreement:
      return (
        <LeaseAgreement
          formulas={JSON.parse(
            selectedResult.getFormulas()[0]?.getFormula() || '{}',
          )}
          data={buildRentalModel()}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.ChildSupportPapers:
    case CalculationTypes.ChildSupportGrossUp:
    case CalculationTypes.DivorceDecreeGrossUp:
      return (
        <ChildSupportBreakdown
          selectedResult={selectedResult}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.IRADistributions1040:
      return (
        <IraDistributions1040
          selectedResult={selectedResult}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.IRADistributions1099:
      return (
        <IraDistributions1099
          selectedResult={selectedResult}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.IRADistributionsBankStatement:
    case CalculationTypes.IRADistributions401KAwardLetter:
      return (
        <IraDistributionsCurrentReceipt
          selectedResult={selectedResult}
          hideAccordion={hideAccordion}
        />
      );

    case CalculationTypes.NoCalcTypes:
      return (
        <NoCalcCalculationBreakdown
          selectedResult={selectedResult}
          hideAccordion={hideAccordion}
        />
      );
    default:
      return null;
  }
};
CalculationBreakdown.propTypes = {
  results: PropTypes.array,
  selectedResult: PropTypes.oneOfType([
    PropTypes.instanceOf(W2ResponseDataModel),
    PropTypes.instanceOf(GenericCalculationItemDataModel),
    PropTypes.instanceOf(WvoeResponseDataModel),
  ]).isRequired,
  onNewWindow: PropTypes.func.isRequired,
  hideAccordion: PropTypes.bool,
};

export default CalculationBreakdown;
