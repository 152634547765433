import Flex from 'common/components/Flex';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import PropTypes from 'prop-types';

export default function FlexBox({
  flexDirection = 'column',
  alignItems,
  justifyContent,
  padding,
  margin,
  className,
  fields = [],
  ...rest
}) {
  const { renderForm } = useFormApi();
  return (
    <Flex
      {...rest}
      direction={flexDirection}
      align={alignItems}
      justify={justifyContent}
      padding={padding}
      margin={margin}
      className={className}
    >
      {renderForm(fields)}
    </Flex>
  );
}

FlexBox.propTypes = {
  className: PropTypes.string,
  flexDirection: PropTypes.oneOf(['column', 'row']),
  justifyContent: PropTypes.oneOf([
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'left',
    'right',
    'normal',
    'space-between',
    'space-around',
    'space-evenly',
    'stretch',
  ]),
  alignItems: PropTypes.oneOf([
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'left',
    'right',
    'normal',
    'self-end',
    'self-start',
    'baseline',
    'first baseline',
    'last baseline',
    'safe center',
    'unsafe center',
    'stretch',
  ]),
  fields: PropTypes.array,
  padding: PropTypes.string,
  margin: PropTypes.string,
};
