﻿import PropTypes from 'prop-types';
import SelfEmploymentTrendingAnalysis from '../SelfEmploymentTrendingAnalysis';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormTypes } from 'pages/IncomeCalculator/enums';

const TrendingAnalysis = ({ selectedResult }) => (
  <SelfEmploymentTrendingAnalysis
    isPositiveTrend={selectedResult
      .getInput(FormTypes.Form1065Output, 0, 'isPositiveTrend')
      ?.getValue()
      ?.toLowerCase()}
    grossMinusCogs={selectedResult.getInputAsFloat(
      FormTypes.Form1065Output,
      0,
      'percentChangeInGrossMinusCogs',
    )}
    expenses={selectedResult.getInputAsFloat(
      FormTypes.Form1065Output,
      0,
      'percentChangeInExpenses',
    )}
    tangibleIncome={selectedResult.getInputAsFloat(
      FormTypes.Form1065Output,
      0,
      'percentChangeInTangibleIncome',
    )}
  />
);
TrendingAnalysis.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};

export default TrendingAnalysis;
