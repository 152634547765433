import actions from './actions';
import apiClient from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';
const getApprovalData = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(actions.getApprovalDataStarted());
  try {
    const response = await apiClient.post(
      apiEndpoints.POST_APPROVAL_DATA,
      obfuscatedLoanIdentifier,
    );
    dispatch(actions.getApprovalDataSuccess(response.data));
  } catch (error) {
    dispatch(actions.getApprovalDataError(error));
  }
};
export default {
  getApprovalData,
};
