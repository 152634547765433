import { FormErrorMessage as ChakraFormErrorMessage } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const FormErrorMessage = ({ children, ...props }) => {
  return <ChakraFormErrorMessage {...props}>{children}</ChakraFormErrorMessage>;
};

export default FormErrorMessage;

FormErrorMessage.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
