import React from 'react';
import { TableHead as MUIThead } from '@mui/material';
import { propsMapper } from 'common/components/PropertyMappers/propsMapper';
import PropTypes from 'prop-types';

const Thead = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <MUIThead ref={ref} {...propsMapper(props)}>
      {children}
    </MUIThead>
  );
});

Thead.displayName = 'Thead';

export default Thead;

Thead.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
