﻿import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/Buttons/Button/index';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Spinner from 'common/components/GlobalLayout/Spinner';
import CalculationCards from '../../Pages/ReviewBreakdown/Wrapper/CalculationCards';
import Alerts from '../../Pages/ReviewBreakdown/Alerts';
import CalculationBreakdown from './CalculationBreakdown';
import ObfuscatedLoanIdentifierDataModel from '../../../../model/ObfuscatedLoanIdentifierDataModel';
import IncomeRecordData from '../../model/IncomeRecordDataModel';
import RentalAddressIdentifierDataModel from '../../model/RentalAddressIdentifierDataModel';
import { REQUEST_REFERENCE_TYPE } from '../../enums';
import { API_STATUS } from 'common/constants';
import { formatCurrency } from 'common/util/format';
import lang from 'pages/IncomeCalculator/language.json';

const HistoricalBreakdown = ({
  status,
  results,
  getHistoricalCalculation,
  loan,
  selectedIncome,
  rentalAddress,
  requestReferenceType,
  calculationId,
  onClose,
  onNewWindow,
}) => {
  const [selectedResult, setSelectedResult] = useState(null);

  useEffect(() => {
    if (
      requestReferenceType === REQUEST_REFERENCE_TYPE.employmentIncome &&
      selectedIncome
    ) {
      getHistoricalCalculation(
        loan,
        selectedIncome.getIncomeIdentifier(),
        REQUEST_REFERENCE_TYPE.employmentIncome,
        calculationId,
      );
    } else if (
      requestReferenceType === REQUEST_REFERENCE_TYPE.rentalIncome &&
      rentalAddress
    ) {
      getHistoricalCalculation(
        loan,
        rentalAddress,
        REQUEST_REFERENCE_TYPE.rentalIncome,
        calculationId,
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      status === API_STATUS.DONE &&
      results?.items?.length > 0 &&
      !selectedResult
    ) {
      setSelectedResult(results.items.find(item => item.isSelected));
    }
  }, [status, results, selectedResult]);

  if (!status || status === API_STATUS.GETTING) {
    return <Spinner />;
  }

  const BackButton = () => (
    <Box h="24px" pt={4}>
      <Button
        variant="link"
        colorScheme="black"
        onClick={onClose}
        leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        {lang.BACK}
      </Button>
    </Box>
  );

  if (status === API_STATUS.ERROR) {
    return (
      <Flex h="100%" direction="column">
        <Text fontSize="lg" textAlign="center" flexBasis="calc(100% - 36px)">
          {lang.ERROR_CALC_HISTORY_RESULT}
        </Text>
        <BackButton />
      </Flex>
    );
  }

  if (!results || !selectedResult) {
    return null;
  }

  return (
    <Flex h="100%" direction="column">
      <Box flexBasis="calc(100% - 36px)" overflowY="scroll">
        {results.items.length > 1 && (
          <CalculationCards
            calculations={results.items}
            selectedCard={selectedResult}
            onSelect={(result) => setSelectedResult(result)}
          />
        )}

        <Box mb={4}>
          <CalculationBreakdown
            results={results.items}
            selectedResult={selectedResult}
            onNewWindow={onNewWindow}
          />
        </Box>

        <Text mb={4} data-testid="calculated-income">
          <Text as="span" fontWeight={500}>
            {lang.CALCULATED_MONTHLY_INCOME}&nbsp;
          </Text>
          {formatCurrency(selectedResult.getCalculationAmount())}
        </Text>
        {selectedResult.isOverrideUsed() && (
          <Text mb={4} data-testid="overridden-income">
            <Text as="span" fontWeight={500}>
              {lang.OVERRIDDEN_MONTHLY_INCOME}&nbsp;
            </Text>
            {formatCurrency(selectedResult.getOverrideAmount())}
          </Text>
        )}

        <Alerts
          alerts={selectedResult.alerts}
          selectedIncome={selectedIncome}
          my={4}
        />

        {results.comments && (
          <Box>
            <Text fontWeight={500} borderBottom="2px solid" mb={4}>
              {lang.COMMENT}
            </Text>
            <Text>{results.comments}</Text>
          </Box>
        )}
      </Box>
      <BackButton />
    </Flex>
  );
};
HistoricalBreakdown.propTypes = {
  status: PropTypes.string,
  results: PropTypes.shape({
    items: PropTypes.array,
    comments: PropTypes.string,
    loanCalculationId: PropTypes.number,
  }),
  getHistoricalCalculation: PropTypes.func.isRequired,
  loan: PropTypes.instanceOf(ObfuscatedLoanIdentifierDataModel).isRequired,
  selectedIncome: PropTypes.instanceOf(IncomeRecordData),
  rentalAddress: PropTypes.instanceOf(RentalAddressIdentifierDataModel),
  requestReferenceType: PropTypes.oneOf([
    REQUEST_REFERENCE_TYPE.employmentIncome,
    REQUEST_REFERENCE_TYPE.rentalIncome,
  ]).isRequired,
  calculationId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onNewWindow: PropTypes.func.isRequired,
};

export default HistoricalBreakdown;
