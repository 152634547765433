export const REO_TYPES = {
  GET_REO_ERROR: 'GET_REO_ERROR',
  GET_REO_STARTED: 'GET_REO_STARTED',
  GET_REO_SUCCESS: 'GET_REO_SUCCESS',
  UPDATE_REO_ERROR: 'UPDATE_REO_ERROR',
  UPDATE_REO_STARTED: 'UPDATE_REO_STARTED',
  UPDATE_REO_SUCCESS: 'UPDATE_REO_SUCCESS',

  UPDATE_REO_CURRENCIES_ERROR: 'UPDATE_REO_CURRENCIES_ERROR',
  UPDATE_REO_CURRENCIES_STARTED: 'UPDATE_REO_CURRENCIES_STARTED',
  UPDATE_REO_CURRENCIES_SUCCESS: 'UPDATE_REO_CURRENCIES_SUCCESS',
};

export const ERROR_TYPES = {
  INITIALIZE: 'REO_ERROR_INITIALIZE',
  RESET: 'REO_ERROR_RESET',
  UPDATE: 'REO_ERROR_UPDATE',
  UPDATE_CURRENCIES: 'REO_ERROR_UPDATE_CURRENCIES',
  VALIDATION: 'REO_ERROR_VALIDATION',
  VALIDATION_RESET: 'REO_ERROR_VALIDATION_RESET',
};
export const INPUT_TYPES = {
  INITIALIZE: 'REO_INITIALIZE',
  STRING: 'REO_STRING',
  OBJECT: 'REO_OBJECT',
  CURRENCY: 'REO_CURRENCY',
  NUMBER: 'REO_NUMBER',
  BOOLEAN: 'REO_BOOLEAN',
  MULTI: 'REO_MULTI',
  UPDATE_EMD_FIELD: 'REO_UPDATE_EMD_FIELD',
  INPUT_TOUCHED_RESET: 'REO_INPUT_TOUCHED_RESET',
  INPUT_TOUCHED_UPDATE: 'REO_INPUT_TOUCHED_UPDATE',
};
