import {
  getAnsweredQuestionsError,
  getAnsweredQuestionsStarted,
  getAnsweredQuestionsSuccess,
  getAssocIncomeCalcsError,
  getAssocIncomeCalcsStarted,
  getAssocIncomeCalcsSuccess,
  getCalculationHistoryError,
  getCalculationHistoryResultError,
  getCalculationHistoryResultStarted,
  getCalculationHistoryResultSuccess,
  getCalculationHistoryStarted,
  getCalculationHistorySuccess,
  getCustomerEmploymentDetailError,
  getCustomerEmploymentDetailStarted,
  getCustomerEmploymentDetailSuccess,
  getEmployerDocumentError,
  getEmployerDocumentStarted,
  getEmployerDocumentSuccess,
  getEmploymentIncomeValidationDataError,
  getEmploymentIncomeValidationDataStarted,
  getEmploymentIncomeValidationDataSuccess,
  getEmploymentIncomeValidationDataWithFieldResolutionError,
  getEmploymentIncomeValidationDataWithFieldResolutionStarted,
  getEmploymentIncomeValidationDataWithFieldResolutionSuccess,
  getEmploymentQuestionsError,
  getEmploymentQuestionsStarted,
  getEmploymentQuestionsSuccess,
  getForm1099InfoError,
  getForm1099InfoStarted,
  getForm1099InfoSuccess,
  getIncomeValidationKeyDatesError,
  getIncomeValidationKeyDatesStarted,
  getIncomeValidationKeyDatesSuccess,
  getOtherIncomeDataError,
  getOtherIncomeDataStarted,
  getOtherIncomeDataSuccess,
  getPayStubInfoError,
  getPayStubInfoStarted,
  getPayStubInfoSuccess,
  getScheduleCInfoError,
  getScheduleCInfoStarted,
  getScheduleCInfoSuccess,
  getSelectedCalculatorDataError,
  getSelectedCalculatorDataStarted,
  getSelectedCalculatorDataSuccess,
  getSelectedCalculatorDataClear,
  getW2InfoError,
  getW2InfoStarted,
  getW2InfoSuccess,
  resetSaveToLoanStatus,
  runCalculationsError,
  runCalculationsReset,
  runCalculationsStarted,
  runCalculationsSuccess,
  saveEmploymentAnswerError,
  saveEmploymentAnswerStarted,
  saveEmploymentAnswerSuccess,
  saveEmploymentIncomeError,
  saveEmploymentIncomeStarted,
  saveEmploymentIncomeSuccess,
  saveIncomeDocumentError,
  saveIncomeDocumentStarted,
  saveIncomeDocumentSuccess,
  saveSelectedIncome,
  saveValidateDocumentError,
  saveValidateDocumentStarted,
  saveValidateDocumentSuccess,
  wvoeValidationSummaryError,
  wvoeValidationSummaryStarted,
  wvoeValidationSummarySuccess,
  previousEmploymentGetStarted,
  previousEmploymentGetSuccess,
  previousEmploymentGetError,
  incomeResolutionGetStarted,
  incomeResolutionGetSuccess,
  incomeResolutionGetError,
  incomeResolutionSaveStarted,
  incomeResolutionSaveError,
  incomeResolutionSaveSuccess,
  clearForms,
  resetSelectedIncome,
  getAssocIncomeDocsStarted,
  getAssocIncomeDocsSuccess,
  getAssocIncomeDocsError,
} from './actions';
import apiClient from 'common/util/api-client';
import apiEndPoints from 'common/constants/api-endpoints';
import {
  IncomeDocumentId,
  IncomeValidationDocumentType,
  REQUEST_REFERENCE_TYPE,
} from '../enums';
import lang from 'pages/IncomeCalculator/language.json';
import IncomeIdentifierDataModel from '../model/IncomeIdentifierDataModel';

/**
 * Gets the borrower's information from the API
 * @param loan The obfuscated loan number.
 * @param incomeIdentifier Income Identifier data model
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getCustomerEmploymentDetail =
  (loan, incomeIdentifier) => async (dispatch) => {
    dispatch(getCustomerEmploymentDetailStarted(null));
    dispatch(resetSelectedIncome());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_CUSTOMER_EMPLOYMENT_DETAILS,
        {
          obfuscatedLoanIdentifier: loan,
          incomeIdentifier,
        },
      );
      dispatch(getCustomerEmploymentDetailSuccess(response.data));
      dispatch(saveSelectedIncome(response.data));
    } catch (error) {
      dispatch(getCustomerEmploymentDetailError(error));
    }
  };

/**
 * Gets the Validate Paystub Employer info  from the API
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getPayStubValidationInfo =
  (
    documentId,
    loan,
    customerRecordId,
    employerId,
    incomeValidationDocumentType,
  ) =>
  async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getPayStubInfoStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.GET_EMPLOYER_VALIDATION_PAY_STUB_INFO,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId: incomeValidationDocumentType,
          },
          incomeValidationDocumentType,
          documentId,
        },
      );
      dispatch(getPayStubInfoSuccess(response.data));
    } catch (error) {
      dispatch(getPayStubInfoError(error));
    }
  };

/**
 * Gets the Validate W2 employer info from the API
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getW2ValidationInfo =
  (
    loan,
    customerRecordId,
    employerId,
    documentId,
    incomeValidationDocumentType,
  ) =>
  async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getW2InfoStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.GET_EMPLOYER_VALIDATION_W2_INFO,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId: incomeValidationDocumentType,
          },
          incomeValidationDocumentType,
          documentId,
        },
      );
      dispatch(getW2InfoSuccess(response.data));
    } catch (error) {
      dispatch(getW2InfoError(error));
    }
  };

/**
 * Gets the Validate Schedule C documents  from the API
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getScheduleCValidationInfo =
  (documentId, loan, customerRecordId, employerId) => async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getScheduleCInfoStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.GET_VALIDATION_SCHEDULE_C_INFO,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId: 5,
          },
          incomeValidationDocumentType: 3,
          documentId,
        },
      );
      dispatch(getScheduleCInfoSuccess(response.data));
    } catch (error) {
      dispatch(getScheduleCInfoError(error));
    }
  };

/**
 * Gets the Validate Form1099 documents  from the API
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getForm1099ValidationInfo =
  (documentId, loan, customerRecordId, employerId, documentTypeId) =>
  async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getForm1099InfoStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator
          .GET_EMPLOYMENT_INCOME_VALIDATION_DATA_1099,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId: IncomeDocumentId.Form1099,
          },
          incomeValidationDocumentType: IncomeValidationDocumentType.IRS1099R,
          documentId,
          documentTypeId,
        },
      );

      dispatch(getForm1099InfoSuccess(response.data));
    } catch (error) {
      dispatch(getForm1099InfoError(error));
    }
  };

export const getEmploymentIncomeValidationData =
  (
    loan,
    customerRecordId,
    employerId,
    documentId,
    incomeValidationDocumentType,
    incomeDocumentId,
    documentTypeId,
  ) =>
  async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getEmploymentIncomeValidationDataStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_EMPLOYMENT_INCOME_VALIDATION_DATA,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId,
          },
          incomeValidationDocumentType,
          documentId,
          documentTypeId,
        },
      );

      dispatch(getEmploymentIncomeValidationDataSuccess(response.data));
    } catch (error) {
      dispatch(getEmploymentIncomeValidationDataError(error));
    }
  };

/**
 * Gets the employment income validation data with questions (Field Resolution) from the API
 * @param loan The obfuscated loan number.
 * @param customerRecordId The selected income customer record Id.
 * @param employerId The selected income employerId
 * @param documentId The Document Id of respective document
 * @param incomeValidationDocumentType The Income document type
 * @param incomeDocumentId The Income document Id
 * @param documentTypeId The Document type Id
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getEmploymentIncomeValidationDataWithFieldResolution =
  (
    loan,
    customerRecordId,
    employerId,
    documentId,
    incomeValidationDocumentType,
    incomeDocumentId,
    documentTypeId,
    ausUnderwritingMethod,
  ) =>
  async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;

    dispatch(getEmploymentIncomeValidationDataWithFieldResolutionStarted());

    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator
          .GET_EMPLOYMENT_INCOME_VALIDATION_DATA_WITH_FIELD_RESOLUTION,
        {
          borrowerEmploymentIdentifier: {
            obfuscatedLoanIdentifier: loan,
            customerIdentifier: {
              obfuscatedLenderDatabaseId,
              customerRecordId,
            },
            employerId,
            incomeDocumentId,
          },
          incomeValidationDocumentType,
          documentId,
          documentTypeId,
          ausUnderwritingMethod,
        },
      );
      dispatch(
        getEmploymentIncomeValidationDataWithFieldResolutionSuccess(
          response.data,
        ),
      );
    } catch (error) {
      dispatch(
        getEmploymentIncomeValidationDataWithFieldResolutionError(error),
      );
    }
  };

/**
 * Gets the income summary documents selection from the API
 * @param request The request object.
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */

export const getIncomeSummary = (request) => async (dispatch) => {
  dispatch(getEmployerDocumentStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_INCOME_SUMMARY_DATA,
      request,
    );
    dispatch(getEmployerDocumentSuccess({ ...response.data }));
  } catch (error) {
    dispatch(getEmployerDocumentError(error));
  }
};

export const getAssociatedIncomeDocs = () => async (dispatch, getState) => {
  dispatch(getAssocIncomeDocsStarted());
  const {
    url: { obfuscatedLoanIdentifier },
    home: { selectedAlert },
  } = getState();

  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_ASSOC_INCOME_DOCS,
      {
        obfuscatedLoanIdentifier,
        customerIdentifier: {
          obfuscatedLenderDatabaseId:
            obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
          customerRecordId: selectedAlert?.customerRecordId,
        },
        employerId: selectedAlert?.ruleReferenceId,
      },
    );
    dispatch(getAssocIncomeDocsSuccess(response.data));
  } catch (error) {
    dispatch(getAssocIncomeDocsError(error));
  }
};

/**
 * Gets the underwriting message from the API
 * * @param loan The obfuscated loan number.
 * * @param selectedIncome The selected income.

 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */
export const getOtherIncomeData =
  (loan, selectedIncome) => async (dispatch) => {
    const { obfuscatedLenderDatabaseId } = loan;
    const { customerRecordId, employerId, sequenceNumber } = selectedIncome;

    dispatch(getOtherIncomeDataStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_OTHER_INCOME_DATA,
        {
          obfuscatedLoanIdentifier: loan,
          incomeIdentifier: {
            obfuscatedLenderDatabaseId,
            customerRecordId,
            employerId,
            sequenceNumber,
          },
        },
      );
      dispatch(getOtherIncomeDataSuccess(response.data));
    } catch (error) {
      dispatch(getOtherIncomeDataError(error));
    }
  };

/**
 * Gets the income validation key dates from the API
 * * @param loan The obfuscated loan number.
 *
 * @returns {function(*): Promise<string>} A thunk that returns the API promise.
 */

export const getIncomeValidationKeyDates = (loan) => async (dispatch) => {
  const { obfuscatedLenderDatabaseId, obfuscatedLoanRecordId } = loan;
  dispatch(getIncomeValidationKeyDatesStarted());

  try {
    const response = await apiClient.post(
      apiEndPoints.validationData.GET_INCOME_VALIDATION_KEY_DATES,
      {
        obfuscatedLoanIdentifier: {
          obfuscatedLenderDatabaseId,
          obfuscatedLoanRecordId,
        },
      },
    );
    dispatch(getIncomeValidationKeyDatesSuccess(response.data));
  } catch (error) {
    dispatch(getIncomeValidationKeyDatesError(error));
  }
};

/**
 * Saves income summary documents selection.
 * @param {saveIncomeSummaryDocuments} request
 * @returns {function(*): Promise<Boolean>} A thunk that save income summary selection to redux.
 */

export const saveIncomeSummaryDocuments = (request) => async (dispatch) => {
  dispatch(saveIncomeDocumentStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.POST_SAVE_INCOME_DOCUMENT_SELECTION,
      request,
    );
    dispatch(saveIncomeDocumentSuccess(response.data));
    const incomeId = new IncomeIdentifierDataModel({
      ...request.borrowerEmploymentIdentifier.customerIdentifier,
      employerId: request.borrowerEmploymentIdentifier.employerId,
      sequenceNumber: 0,
    });

    dispatch(clearForms(incomeId));
    dispatch(getSelectedCalculatorDataClear());
    return true;
  } catch (error) {
    dispatch(saveIncomeDocumentError(error));
    return false;
  }
};

/**
 * Saves the employment question answers on the income summary page.
 * @param request ({{}} The employment answers
 * @returns {(function(*): Promise<Boolean>)} A thunk that saves the employment answers.
 */
export const saveEmploymentAnswer = (request) => async (dispatch) => {
  dispatch(saveEmploymentAnswerStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.SAVE_EMPLOYMENT_ANSWER,
      request,
    );
    dispatch(saveEmploymentAnswerSuccess(response.data));
    return true;
  } catch (error) {
    dispatch(saveEmploymentAnswerError(error));
    return false;
  }
};

/**
 * Saves validate documents selection.
 * @returns {function(*): Promise<Boolean>} A thunk that save validate document selection to redux.
 */

export const saveValidateDocumentQuestionAnswers =
  (loan, userResponse) => async (dispatch) => {
    dispatch(saveValidateDocumentStarted());

    try {
      const response = await apiClient.post(
        apiEndPoints.POST_SAVE_EMPLOYER_VALIDATION_DOCUMENTS,
        {
          obfuscatedLoanIdentifier: loan,
          saveEmploymentRequest: {
            ...userResponse.saveEmploymentRequest,
          },
          saveQuestionAnswersRequest: userResponse.saveQuestionAnswersRequest,
          saveExtractedDataUpdatesRequest: userResponse.UpdatedExtractionData
            ? userResponse.UpdatedExtractionData
            : { isDataChanged: false, extractedDataUpdates: [] },

          saveCPAInformationRequest: userResponse.saveCPAInformationRequest,
        },
      );
      dispatch(saveValidateDocumentSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(saveValidateDocumentError());
      return false;
    }
  };

/**
 * Gets the calculation history for an income calculation.
 * @param loan {ObfuscatedLoanIdentifierDataModel} The loan number to run.
 * @param incomeIdentifier {IncomeIdentifierDataModel} The income identifier.
 * @returns {(function(*): Promise<void>)} A thunk that grabs the historical data.
 */
export const getIncomeCalculationHistory =
  (loan, incomeIdentifier) => async (dispatch) => {
    dispatch(getCalculationHistoryStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_CALCULATION_HISTORY,
        {
          obfuscatedLoanIdentifier: loan,
          incomeIdentifier: incomeIdentifier,
          addressIdentifier: null,
        },
      );
      dispatch(getCalculationHistorySuccess(response.data));
    } catch (error) {
      dispatch(getCalculationHistoryError(error.response?.data ?? error));
    }
  };

/**
 * Gets the calculation history for a rental calculation.
 * @param loan {ObfuscatedLoanIdentifierDataModel} The loan number to run.
 * @param rentalAddress {RentalAddressIdentifierDataModel} The rental address identifier.
 * @returns {(function(*): Promise<void>)} A thunk that grabs the historical data.
 */
export const getRentalCalculationHistory =
  (loan, rentalAddress) => async (dispatch) => {
    dispatch(getCalculationHistoryStarted());
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_CALCULATION_HISTORY,
        {
          obfuscatedLoanIdentifier: loan,
          incomeIdentifier: null,
          addressIdentifier: rentalAddress,
        },
      );
      dispatch(getCalculationHistorySuccess(response.data));
    } catch (error) {
      dispatch(getCalculationHistoryError(error.response?.data ?? error));
    }
  };

/**
 * Gets a historical income calculation for review.
 * @param loan {ObfuscatedLoanIdentifierDataModel} The loan id.
 * @param identifier {IncomeIdentifierDataModel|RentalAddressIdentifierDataModel} The income identifier or rental address id.
 * @param requestReferenceType {number} The request reference type (income or rental).
 * @param calculationId {number} The calculation id.
 * @returns {(function(*): Promise<void>)} A thunk that grabs the selected calculation.
 */
export const getHistoricalCalculation =
  (loan, identifier, requestReferenceType, calculationId) =>
  async (dispatch) => {
    const request = {
      obfuscatedLoanIdentifier: loan,
      requestReferenceType,
      calculationId,
    };
    switch (requestReferenceType) {
      case REQUEST_REFERENCE_TYPE.employmentIncome:
        request.incomeIdentifier = identifier;
        break;
      case REQUEST_REFERENCE_TYPE.rentalIncome:
        request.rentalAddressId = identifier.getAddressId();
        break;
      default:
        dispatch(
          getCalculationHistoryResultError('Invalid request reference type.'),
        );
        return;
    }

    dispatch(getCalculationHistoryResultStarted());

    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_ASSOC_INCOME_CALCS,
        request,
      );
      dispatch(getCalculationHistoryResultSuccess(response.data));
    } catch (error) {
      dispatch(getCalculationHistoryResultError(error.response?.data ?? error));
    }
  };

/**
 * get selected income calculator data.
 * @param {GetSelectedCalculationRequestDataModel} request
 * @returns {function(*): Promise<void>} An action that adds selected income calculator data/error to redux.
 */
export const getSelectedCalculatorData = (request) => async (dispatch) => {
  dispatch(runCalculationsReset());
  dispatch(getSelectedCalculatorDataStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_SELECTED_CALCULATOR_DATA,
      request,
    );
    dispatch(getSelectedCalculatorDataSuccess(response.data));
  } catch (error) {
    dispatch(getSelectedCalculatorDataError(error));
  }
};

/**
 * Runs the calculation with the given input data and saves the calculation result to the loan for the review breakdown page.
 * @param request {RunCalculationsRequestDataModel} The calculation identifiers and the list of input fields by document.
 * @returns {function(*): Promise<Boolean>}
 */
export const runCalculations = (request) => async (dispatch) => {
  dispatch(runCalculationsStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.CALCULATE,
      request,
    );
    dispatch(runCalculationsSuccess(response.data));
    dispatch(getSelectedCalculatorDataClear());
    return true;
  } catch (error) {
    let errorText = null;
    if (error.response?.status === 400) {
      errorText = error.response?.data;
    }
    errorText = errorText ?? lang.INCOME_CALCULATOR_RUN_CALCULATION_ERROR;

    dispatch(runCalculationsError(errorText));
    return false;
  }
};

/**
 * Gets all the associated income calculations
 * @param request {CalculationsRequestDataModel}
 * @returns {function(*): Promise<void>}
 */
export const getAssocIncomeCalculations = (request) => async (dispatch) => {
  dispatch(runCalculationsReset());
  dispatch(getAssocIncomeCalcsStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_ASSOC_INCOME_CALCS,
      request,
    );
    dispatch(getAssocIncomeCalcsSuccess(response.data));
    dispatch(resetSaveToLoanStatus());
  } catch (error) {
    dispatch(getAssocIncomeCalcsError(error));
  }
};

/**
 * Saves the selected review breakdown to the loan.
 * @param {SaveEmploymentIncomeRequestDataModel} request
 * @returns {function(*): Promise<Boolean>} A thunk that adds save calculation results to redux.
 */
export const saveEmploymentIncome = (request) => async (dispatch) => {
  dispatch(saveEmploymentIncomeStarted());
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.SAVE_EMPLOYMENT_INCOME,
      request,
    );

    dispatch(saveEmploymentIncomeSuccess(response.data));
    return true;
  } catch (error) {
    dispatch(saveEmploymentIncomeError(error));
    return false;
  }
};

export const getWvoeValidationSummary = (request) => async (dispatch) => {
  const calculationType = request.getCalculationType();
  dispatch(wvoeValidationSummaryStarted(calculationType));
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_WVOE_VALIDATION_SUMMARY,
      request,
    );
    dispatch(wvoeValidationSummarySuccess(response.data, calculationType));
  } catch (error) {
    dispatch(wvoeValidationSummaryError(calculationType));
  }
};

export const getEmploymentQuestions =
  (loan, selectedIncome) => async (dispatch) => {
    const { obfuscatedLenderDatabaseId, obfuscatedLoanRecordId } = loan;
    const { customerRecordId, employerId } = selectedIncome;
    dispatch(getEmploymentQuestionsStarted());

    try {
      const response = await apiClient.post(
        apiEndPoints.employmentIncome.GET_EMPLOYMENT_QUESTIONS,
        {
          obfuscatedLoanIdentifier: {
            obfuscatedLenderDatabaseId,
            obfuscatedLoanRecordId,
          },
          customerIdentifier: {
            obfuscatedLenderDatabaseId,
            customerRecordId,
          },
          employerId,
        },
      );
      dispatch(getEmploymentQuestionsSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(getEmploymentQuestionsError(error));
      return false;
    }
  };

export const getAnsweredQuestions =
  (loan, selectedIncome) => async (dispatch) => {
    const { obfuscatedLenderDatabaseId, obfuscatedLoanRecordId } = loan;
    const { customerRecordId, employerId } = selectedIncome;
    dispatch(getAnsweredQuestionsStarted());

    try {
      const response = await apiClient.post(
        apiEndPoints.employmentIncome.GET_ANSWERED_QUESTIONS,
        {
          obfuscatedLoanIdentifier: {
            obfuscatedLenderDatabaseId,
            obfuscatedLoanRecordId,
          },
          customerIdentifier: {
            obfuscatedLenderDatabaseId,
            customerRecordId,
          },
          employerId,
        },
      );
      dispatch(getAnsweredQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(getAnsweredQuestionsError(error));
    }
  };

/**
 * Get the Income Resolution question data for the selected alert
 *
 * @param state.url.obfuscatedLoanIdentifier {object} the OLI as specified by the url
 * @param state.home.selectedAlert.additionalReferences.calculationId {string} selected alerts calcualtion id
 * @returns {function} A thunk to call the Fluctuating Income get data API
 */
export const getIncomeResoltuion = () => async (dispatch, getState) => {
  dispatch(incomeResolutionGetStarted());
  const {
    url: { obfuscatedLoanIdentifier },
    home: { selectedAlert },
  } = getState();
  const incomeIdentifier = {
    obfuscatedLenderDatabaseId: selectedAlert?.customerLenderDatabaseId,
    customerRecordId: selectedAlert?.customerRecordId,
    employerId: selectedAlert?.ruleReferenceId,
    sequenceNumber: 0,
  };
  try {
    const response = await apiClient.post(
      apiEndPoints.incomeCalculator.GET_INCOME_RESOLUTION,
      {
        obfuscatedLoanIdentifier,
        incomeIdentifier,
        calculationId: parseInt(
          selectedAlert.additionalReferences.CalculationId,
        ),
      },
    );
    dispatch(incomeResolutionGetSuccess(response.data));
  } catch (err) {
    dispatch(incomeResolutionGetError(err));
  }
};

export const saveIncomeResolution =
  (resolutionItems, topLevelQuestions) => async (dispatch, getState) => {
    dispatch(incomeResolutionSaveStarted());
    const {
      url: { obfuscatedLoanIdentifier },
      home: {
        selectedAlert: { additionalReferences },
      },
      incomeCalculator: {
        selectedIncome: { incomeIdentifier },
      },
    } = getState();
    try {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.SAVE_INCOME_RESOLUTION,
        {
          obfuscatedLoanIdentifier,
          incomeIdentifier,
          calculationId: parseInt(additionalReferences.CalculationId),
          topLevelQuestions,
          resolutionItems,
        },
      );
      dispatch(incomeResolutionSaveSuccess(response.data));
      return true;
    } catch (err) {
      dispatch(incomeResolutionSaveError(err));
      return false;
    }
  };

/**
 * Get the Previous Employment Income Resolution question data for the selected alert
 *
 * @param state.url.obfuscatedLoanIdentifier {object} the OLI as specified by the url
 * @param state.home.selectedAlert.additionalReferences.calculationId {string} selected alerts calcualtion id
 * @returns {function} A thunk to call the Fluctuating Income get data API
 */
export const getPreviousEmployment = () => async (dispatch, getState) => {
  dispatch(previousEmploymentGetStarted());
  const {
    url: { obfuscatedLoanIdentifier },
    home: {
      selectedAlertParent: { gutterItemChildren },
    },
    incomeCalculator: {
      selectedIncome: { incomeIdentifier },
    },
  } = getState();
  try {
    const {
      additionalReferences: { CalculationId },
    } = gutterItemChildren.find(({ displayTitle }) =>
      displayTitle.toLowerCase().includes('calculate'),
    );
    if (CalculationId) {
      const response = await apiClient.post(
        apiEndPoints.incomeCalculator.GET_PREVIOUS_EMPLOYMENT,
        {
          obfuscatedLoanIdentifier,
          incomeIdentifier,
          loanCalculationId: +CalculationId,
        },
      );

      dispatch(previousEmploymentGetSuccess(response.data));
    }
  } catch (err) {
    dispatch(previousEmploymentGetError(err));
  }
};
