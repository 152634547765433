import { CATEGORY_IDS } from 'pages/Home/constants';
import useLeftDrawerContext from '../context/useLeftDrawerContext';
import ClassifyNavAlertItems from './NavAlertItems/ClassifyNavAlertItems';
import WorkNavAlertItems from './NavAlertItems/WorkNavAlertItems';
import LoanSummary from './LoanSummary';
import PropTypes from 'prop-types';
import useRailRouteMatches from '../../Rail/hooks/useRailRouteMatches';

const SelectedLeftDrawerContent = ({ selectedCategory }) => {
  const { isNavigationVisible } = useLeftDrawerContext();
  const { is1003Match } = useRailRouteMatches();

  const isClassifySelected =
    isNavigationVisible && selectedCategory === CATEGORY_IDS.CLASSIFY;

  if (isNavigationVisible && isClassifySelected) {
    return <ClassifyNavAlertItems />;
  }
  if (isNavigationVisible && !is1003Match) {
    return <WorkNavAlertItems />;
  }
  return <LoanSummary />;
};

SelectedLeftDrawerContent.propTypes = {
  selectedCategory: PropTypes.number,
};

export default SelectedLeftDrawerContent;
