import actions from './actions';
import apiClient from 'common/util/api-client';
import apiEndpoints from 'common/constants/api-endpoints';

export const getLiabilities = (databaseId, loanId) => async (dispatch) => {
  dispatch(actions.getLiabilitiesStarted());
  try {
    const response = await apiClient.post(apiEndpoints.GET_LIABILTIES, {
      obfuscatedLoanIdentifier: {
        obfuscatedLenderDatabaseId: databaseId,
        obfuscatedLoanRecordId: loanId,
      },
    });

    const remappedData = response?.data?.map((liability) => {
      // check in place because data is stored differently in different systems.
      // If exclude payment is unchecked - the exclusion reason should be "None"

      return {
        ...liability,
        exclusionReason: liability?.excludePayment
          ? liability.exclusionReason
          : 'None',
      };
    });

    dispatch(actions.getLiabilitiesSuccess(remappedData));
  } catch (error) {
    dispatch(actions.getLiabilitiesError(error));
  }
};

export const updateLiabilities =
  (databaseId, loanId, data) => async (dispatch) => {
    dispatch(actions.updateLiabilitiesStarted());
    try {
      await apiClient.post(apiEndpoints.UPDATE_LIABILTIES, {
        obfuscatedLoanIdentifier: {
          obfuscatedLenderDatabaseId: databaseId,
          obfuscatedLoanRecordId: loanId,
        },
        liabilities: [data],
      });

      dispatch(actions.updateLiabilitiesSuccess(data));
    } catch (error) {
      dispatch(actions.updateLiabilitiesError(error));
    }
  };

const updateCreditInfo = () => async (dispatch, getState) => {
  dispatch(actions.updateCreditInfoStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const response = await apiClient.post(apiEndpoints.POST_CREDIT_INFO, {
      obfuscatedLoanIdentifier,
    });
    dispatch(actions.updateCreditInfoSuccess(response.data));
  } catch (error) {
    dispatch(actions.updateCreditInfoError(error));
  }
};

export const getLiabilitiesVerifyData = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(actions.getLiabilitiesVerifyDataStarted());
  try {
    const response = await apiClient.post(
      apiEndpoints.GET_LIABILTIES_VERIFY_DATA,
      {
        obfuscatedLoanIdentifier,
      },
    );

    dispatch(actions.getLiabilitiesVerifyDataSuccess(response.data));
  } catch (error) {
    dispatch(actions.getLiabilitiesVerifyDataError(error));
  }
};

export const getLiabilitiesAssocaiteData = () => async (dispatch, getState) => {
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  dispatch(actions.getLiabilitiesAssociateDataStarted());
  try {
    const response = await apiClient.post(
      apiEndpoints.GET_LIABILTIES_ASSOCIATE_DATA,
      {
        obfuscatedLoanIdentifier,
      },
    );

    dispatch(actions.getLiabilitiesAssociateDataSuccess(response.data));
  } catch (error) {
    dispatch(actions.getLiabilitiesAssociateDataError(error));
  }
};

export const updateLiabilitiesVerifyData =
  (data) => async (dispatch, getState) => {
    dispatch(actions.updateLiabilitiesVerifyDataStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    try {
      const response = await apiClient.post(
        apiEndpoints.UPDATE_LIABILTIES_VERIFY_DATA,
        {
          obfuscatedLoanIdentifier,
          liabilities: data,
        },
      );
      dispatch(actions.updateLiabilitiesVerifyDataSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(actions.updateLiabilitiesVerifyDataError(error));
      return false;
    }
  };

export const updateLiabilitiesAssociateData =
  (data) => async (dispatch, getState) => {
    dispatch(actions.updateLiabilitiesAssociateDataStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    try {
      const response = await apiClient.post(
        apiEndpoints.UPDATE_LIABILTIES_ASSOCIATE_DATA,
        {
          obfuscatedLoanIdentifier,
          liabilities: data,
        },
      );

      dispatch(actions.updateLiabilitiesAssociateDataSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(actions.updateLiabilitiesAssociateDataError(error));
      return false;
    }
  };

export const getCreditReportInfo = () => async (dispatch, getState) => {
  dispatch(actions.getCreditReportStarted());
  const {
    url: { obfuscatedLoanIdentifier },
  } = getState();
  try {
    const response = await apiClient.post(
      apiEndpoints.GET_CREDIT_REPORT,
      obfuscatedLoanIdentifier,
    );
    dispatch(actions.getCreditReportSuccess(response.data));
  } catch (error) {
    dispatch(actions.getCreditReportError(error));
  }
};

const updateCreditValidationResults =
  (loanId, databaseId, ruleSubCategoryId) => async (dispatch, getState) => {
    dispatch(actions.updateCreditValidationResultsStarted());
    const {
      url: { obfuscatedLoanIdentifier },
    } = getState();
    try {
      const response = await apiClient.post(
        apiEndpoints.POST_CREDIT_VALIDATION_RESULTS,
        {
          obfuscatedLoanIdentifier,
          ruleSubCategoryId: ruleSubCategoryId,
        },
      );
      dispatch(
        actions.updateCreditValidationResultsSuccess({
          result: response.data,
        }),
      );
    } catch (error) {
      dispatch(actions.updateCreditValidationResultsError(error));
    }
  };

const updateSaveCreditAnswers =
  (formData, callback) => async (dispatch, getState) => {
    dispatch(actions.updateSaveCreditAnswersStarted());
    try {
      const {
        url: { obfuscatedLoanIdentifier },
      } = getState();
      const answers = Object.keys(formData).reduce(
        (a, c) =>
          isNaN(c?.split('-')[0])
            ? {
                ...a,
                ...formData[c],
              }
            : {
                ...a,
                [c]: formData[c]?.toString(),
              },
        {},
      );

      const response = await apiClient.post(
        apiEndpoints.POST_SAVE_CREDIT_ANSWERS,
        {
          obfuscatedLoanIdentifier,
          saveQuestionAnswerRequest: {
            userResponses: Object.keys(answers).map((questionId) => {
              const questionIdentifier = questionId.split('-');
              return {
                questionUserResponseID: parseInt(questionIdentifier[0]),
                ruleResultDetailId: questionIdentifier[1]
                  ? parseInt(questionIdentifier[1])
                  : null,
                questionAnswerID: questionIdentifier[2]
                  ? parseInt(questionIdentifier[2])
                  : null,
                currentAnswerText:
                  (answers[questionId] || '').toString() || null,
              };
            }),
          },
        },
      );
      dispatch(actions.updateSaveCreditAnswersSuccess(response.data));
      callback(response.data);
    } catch (error) {
      dispatch(actions.updateSaveCreditAnswersError(error));
      callback(error);
    }
  };

export const calculateRemainingTerm =
  (obfuscatedLoanIdentifier, payment, balance) => async (dispatch) => {
    actions.calculalateRemainingTermsStarted();
    try {
      const response = await apiClient.post(
        apiEndpoints.CALCULATE_REMAINING_TERMS,
        {
          obfuscatedLoanIdentifier,
          payment,
          balance,
        },
      );
      dispatch(actions.calculalateRemainingTermsSuccess(response.data));
    } catch (err) {
      actions.calculalateRemainingTermsError(err);
    }
  };

export default {
  updateCreditInfo,
  getCreditReportInfo,
  updateCreditValidationResults,
  updateSaveCreditAnswers,
};
