import TYPES from './types';

// UI only actions
const setSelectedDocument = (payload) => ({
  type: TYPES.SET_SELECTED_DOCUMENT,
  payload,
});

const setSelectedPDF = (payload) => ({
  type: TYPES.SET_SELECTED_PDF,
  payload,
});

const setOnFirstRender = (payload) => ({
  type: TYPES.SET_ON_FIRST_RENDER,
  payload,
});

const setSelectedDocumentAssociations = (payload) => ({
  type: TYPES.SET_SELECTED_DOCUMENT_ASSOCIATIONS,
  payload,
});

const setActionRequired = (payload) => ({
  type: TYPES.SET_ACTION_REQUIRED,
  payload,
});

const setQuickMatchDocumentAssociation = (payload) => ({
  type: TYPES.SET_QUICK_MATCH_DOCUMENT_ASSOCIATION,
  payload,
});

// actions for v1/ClassifyDocuments/GetAdrCompletionStatusExists
const getAdrCompletionStatusStarted = () => ({
  type: TYPES.GET_ADR_COMPLETION_STATUS_STARTED,
});

const getAdrCompletionStatusSuccess = (payload) => ({
  type: TYPES.GET_ADR_COMPLETION_STATUS_SUCCESS,
  payload,
});

const getAdrCompletionStatusError = (error) => ({
  type: TYPES.GET_ADR_COMPLETION_STATUS_ERROR,
  error,
});

// actions for v2/ClassifyDocuments/GetClassifiedDocuments
const getClassifyDocumentsStarted = () => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_STARTED,
});

const getClassifyDocumentsSuccess = (payload) => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_SUCCESS,
  payload,
});

const getClassifyDocumentsError = (error) => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_ERROR,
  error,
});

// actions for /v1/ClassifyDocuments/GetClassifiedDocumentsForBatchProcessing
const getClassifiedDocumentsForBatchProcessingStarted = () => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_STARTED,
});

const getClassifiedDocumentsForBatchProcessingSuccess = (payload) => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_SUCCESS,
  payload,
});

const getClassifiedDocumentsForBatchProcessingError = (error) => ({
  type: TYPES.GET_CLASSIFY_DOCUMENTS_FOR_BATCH_PROCESSING_ERROR,
  error,
});

// actions for /v2/ClassifyDocuments/GetDocumentTypeAssociations
const getDocumentTypeAssociationsStarted = () => ({
  type: TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_STARTED,
});

const getDocumentTypeAssociationsSuccess = (payload) => ({
  type: TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_SUCCESS,
  payload,
});

const getDocumentTypeAssociationsError = (error) => ({
  type: TYPES.GET_DOCUMENT_TYPE_ASSOCIATIONS_ERROR,
  error,
});

// actions for /v1/ClassifyDocuments/GetDocumentPdf
const getDocumentPdfStarted = () => ({
  type: TYPES.GET_PDF_DOCUMENT_STARTED,
});

const getDocumentPdfSuccess = (payload) => ({
  type: TYPES.GET_PDF_DOCUMENT_SUCCESS,
  payload,
});

const getDocumentPdfError = (error) => ({
  type: TYPES.GET_PDF_DOCUMENT_ERROR,
  error,
});

// actions for /v2/DocumentAssociation/GetDocumentAssociations
const getDocumentAssociationsStarted = () => ({
  type: TYPES.GET_DOCUMENT_ASSOCIATIONS_STARTED,
});

const getDocumentAssociationsSuccess = (payload) => ({
  type: TYPES.GET_DOCUMENT_ASSOCIATIONS_SUCCESS,
  payload,
});

const getDocumentAssociationsError = (error) => ({
  type: TYPES.GET_DOCUMENT_ASSOCIATIONS_ERROR,
  error,
});

// actions for /v2/DocumentAssociation/SaveDocumentAssociations
const saveDocumentAssociationsStarted = () => ({
  type: TYPES.SAVE_DOCUMENT_ASSOCIATIONS_STARTED,
});

const saveDocumentAssociationsSuccess = () => ({
  type: TYPES.SAVE_DOCUMENT_ASSOCIATIONS_SUCCESS,
});

const saveDocumentAssociationsError = (error) => ({
  type: TYPES.SAVE_DOCUMENT_ASSOCIATIONS_ERROR,
  error,
});

// actions for /v1/ClassifyDocuments/SetHideDocuments
const setHideDocumentStarted = () => ({
  type: TYPES.SET_HIDE_DOCUMENT_STARTED,
});

const setHideDocumentSuccess = () => ({
  type: TYPES.SET_HIDE_DOCUMENT_SUCCESS,
});

const setHideDocumentError = (error) => ({
  type: TYPES.SET_HIDE_DOCUMENT_ERROR,
  error,
});

const resetHideDocumentState = () => ({
  type: TYPES.RESET_HIDE_DOCUMENT_INITIAL_STATE,
});

export default {
  setOnFirstRender,
  setSelectedDocument,
  setSelectedPDF,
  setActionRequired,
  setQuickMatchDocumentAssociation,
  setSelectedDocumentAssociations,
  getAdrCompletionStatusStarted,
  getAdrCompletionStatusSuccess,
  getAdrCompletionStatusError,
  getClassifyDocumentsStarted,
  getClassifyDocumentsSuccess,
  getClassifyDocumentsError,
  getDocumentTypeAssociationsStarted,
  getDocumentTypeAssociationsSuccess,
  getDocumentTypeAssociationsError,
  getDocumentPdfStarted,
  getDocumentPdfSuccess,
  getDocumentPdfError,
  getDocumentAssociationsStarted,
  getDocumentAssociationsSuccess,
  getDocumentAssociationsError,
  getClassifiedDocumentsForBatchProcessingStarted,
  getClassifiedDocumentsForBatchProcessingSuccess,
  getClassifiedDocumentsForBatchProcessingError,
  saveDocumentAssociationsStarted,
  saveDocumentAssociationsSuccess,
  saveDocumentAssociationsError,
  setHideDocumentStarted,
  setHideDocumentSuccess,
  setHideDocumentError,
  resetHideDocumentState,
};
