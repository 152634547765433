﻿import IncomeDocumentNode from './IncomeDocumentNode';
import IncomeValidationKeyDatesDataModel from '../IncomeValidationKeyDatesDataModel';
import IncomeSummaryMessageDataModel from './IncomeSummaryMessageDataModel';

export default class IncomeSummaryDataModel {
  constructor(bi = {}) {
    this.documentTree = Object.prototype.hasOwnProperty.call(bi, 'documentTree')
      ? bi.documentTree?.map((x) => new IncomeDocumentNode(x))
      : [];

    this.message =
      Object.prototype.hasOwnProperty.call(bi, 'message') && bi.message
        ? new IncomeSummaryMessageDataModel(bi.message)
        : null;

    this.employmentQuestionsAndAnswers = Object.prototype.hasOwnProperty.call(
      bi,
      'employmentQuestionsAndAnswers',
    )
      ? bi.employmentQuestionsAndAnswers
      : [];

    this.incomeValidationKeyDates = Object.prototype.hasOwnProperty.call(
      bi,
      'incomeValidationKeyDates',
    )
      ? new IncomeValidationKeyDatesDataModel(bi.incomeValidationKeyDates)
      : null;
  }

  /**
    @returns {[IncomeDocumentNode]} The list of document nodes.
  */
  getDocumentTree() {
    return this.documentTree;
  }

  getMessage() {
    return this.message;
  }

  getQuestions() {
    return this.employmentQuestionsAndAnswers;
  }

  /**
   * @returns {IncomeValidationKeyDatesDataModel} The key dates for the loan.
   */
  getIncomeValidationKeyDates() {
    return this.incomeValidationKeyDates;
  }
}
