import { useSelector } from 'react-redux';

function isReadOnlyUrl() {
  return window.location.pathname.includes('/readOnly');
}

function useIsUnderwriter() {
  return useSelector((state) => state.home.user.isUnderwriter);
}
export { isReadOnlyUrl, useIsUnderwriter };
