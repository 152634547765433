/* eslint-disable react/no-danger-with-children */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormElementWidgetWithOptions } from 'lib-node-amb-renderer';
import { validationError } from '../utils';
import styled from '@emotion/styled';
import theme from 'theme/';

const CustomSelectWithStyledOptionsWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1rem;
`;

const CustomSelectWithStyledOptionsButton = styled.button`
  width: 100%;
  height: auto;
  background-color: ${theme.colors.white};
  border-style: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  border-color: ${({ invalid }) =>
    invalid ? theme.colors.red[500] : theme.colors.gray[200]};
  border-width: ${({ invalid }) => (invalid ? '2px' : '1px')};
  padding: 0.4rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomSelectWithStyledOptionsDrawer = styled('div')`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  overflow: hidden;
  position: absolute;
  transition: height 0.3s;
  z-index: ${({ isOpen }) => (isOpen ? '1' : '-1')};
  padding-bottom: 1rem;
`;

const DrawerWrapper = styled('ul')`
  text-decoration: none;
  border: 1px solid #e2e8f0;
  width: 100%;
  max-height: ${(props) => (props.hasMaxHeight ? '300px' : 'auto')};
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
`;

const StyledOption = styled('li')`
  border-bottom: 1px solid #e2e8f0;
  width: 100%;
  position: relative;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  margin: 0;
  &:hover {
    background-color: ${theme.colors.gray[50]};
  }
`;

const StyledRadioInput = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;
`;

const Placeholder = styled.p`
  color: ${theme.colors.bgGray[800]};
`;

const StyledErrorMessage = styled.p`
  color: ${theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

export class CustomSelectWithStyledOptionsClass extends FormElementWidgetWithOptions {
  icon;
  required;

  constructor(props) {
    super(props);
    this.icon = props.icon ?? faChevronDown;
  }
}

function CustomSelectWithStyledOptionsIcon({ isOpen, icon, hasSelection }) {
  const iconStyles = {
    fontSize: '.65rem',
    rotate: isOpen ? '-180deg' : '0deg',
    transition: 'rotate 0.3s',
  };

  return (
    <FontAwesomeIcon
      color={hasSelection ? theme.colors.black : theme.colors.bgGray[800]}
      icon={!icon ? faChevronDown : icon}
      style={iconStyles}
    />
  );
}

CustomSelectWithStyledOptionsIcon.propTypes = {
  isOpen: PropTypes.bool,
  icon: PropTypes.string,
  hasSelection: PropTypes.bool,
};

export function StyledOptions({
  label,
  value,
  name,
  isDisabled = false,
  handleChange,
  optionStyles = {},
}) {
  return (
    <StyledOption
      value={value}
      onClick={() => {
        if (isDisabled) return;
        handleChange(value);
      }}
      disabled={isDisabled}
      style={optionStyles}
    >
      <StyledRadioInput type="radio" value={value} name={name} />
      <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} />
    </StyledOption>
  );
}

StyledOptions.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  optionStyles: PropTypes.object,
};

export default function CustomSelectWithStyledOptions(props) {
  const {
    name,
    options,
    icon,
    input,
    label,
    placeholder = 'Select an option',
    meta,
    validateOnMount,
    hasMaxHeight = false,
    sx = {},
    selectedOptionStyle = {},
  } = useFieldApi({
    ...props,
    type: 'select',
  });
  const { value, onChange, onBlur, onFocus } = input;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const selectedOption = useMemo(() => {
    if (!value) return <Placeholder>{placeholder}</Placeholder>;
    const findOption = options.find((option) => option.value === value);
    return <p dangerouslySetInnerHTML={{ __html: findOption.label }}></p>;
  }, [options, value, placeholder]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      const listBox = document.getElementById('listBox');
      listBox.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [isOpen]);

  const hasError = validationError(meta, validateOnMount);
  const text = hasError || ((meta.touched || validateOnMount) && meta.warning);

  const handleChange = (newValue) => {
    onChange(newValue);
    setIsOpen(false);
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  const handleFocus = () => {
    onChange(value);
    setIsOpen(true);
  };

  return (
    <div>
      <CustomSelectWithStyledOptionsWrapper
        className="CompositeDropdownSelect"
        name={name}
        ref={ref}
        onBlur={() => onBlur(value)}
        onFocus={() => handleFocus()}
      >
        {label ?? <p>{label}</p>}
        <CustomSelectWithStyledOptionsButton
          className="CompositeDropdownSelect__value"
          invalid={hasError}
          role="combobox"
          aria-labelledby="select button"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-controls="select-dropdown"
        >
          <div style={selectedOptionStyle}>{selectedOption}</div>
          <CustomSelectWithStyledOptionsIcon
            isOpen={isOpen}
            icon={icon ?? ''}
            hasSelection={value}
            onFocus={onFocus}
          />
        </CustomSelectWithStyledOptionsButton>
        <CustomSelectWithStyledOptionsDrawer
          className="CompositeDropdownSelect__children"
          id="listBox"
          isOpen={isOpen}
        >
          <DrawerWrapper
            hasMaxHeight={hasMaxHeight}
            role="listbox"
            id="select-dropdown"
          >
            {options.map((option, index) => (
              <StyledOptions
                style={sx}
                key={index}
                name={index}
                value={option.value}
                role="option"
                tabIndex={-1}
                isDisabled={option.isDisabled}
                label={option.label}
                handleChange={(newValue) => handleChange(newValue)}
              />
            ))}
          </DrawerWrapper>
        </CustomSelectWithStyledOptionsDrawer>
        {!!hasError && <StyledErrorMessage>{text}</StyledErrorMessage>}
      </CustomSelectWithStyledOptionsWrapper>
    </div>
  );
}
CustomSelectWithStyledOptions.propTypes = {
  value: PropTypes.any,
  icon: PropTypes.string,
  Children: PropTypes.node,
  options: PropTypes.array,
  renderLabel: PropTypes.func,
  sx: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  matchType: PropTypes.number,
  isClearable: PropTypes.bool,
};
