import MUICircularProgress from 'common/components/Spinner/index';
import Flex from 'common/components/Flex';

const Spinner = () => (
  <Flex justifyContent="center" alignItems="center" height="656px">
    <MUICircularProgress
      data-testid="loaderIcon"
      size="xl"
      thickness="4px"
      color="orange.500"
      emptyColor="gray.200"
      role="progressbar"
      aria-busy="true"
      aria-live="polite"
      aria-label="Loading"
    />
  </Flex>
);

export default Spinner;
