import Box from '../Box';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, name, ...other } = props;

  return (
    <section
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </section>
  );
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
