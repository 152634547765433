import UserResponse from './UserResponse';
import Answer from './ValidationAnswer';
export default class Question {
  questionId;
  ruleResultId;
  questionText;
  userResponse;
  answers = [];

  constructor(q = {}) {
    this.questionId = Object.prototype.hasOwnProperty.call(q, 'questionId')
      ? q.questionId
      : 0;
    this.ruleResultId = Object.prototype.hasOwnProperty.call(q, 'ruleResultId')
      ? q.ruleResultId
      : 0;
    this.questionText = Object.prototype.hasOwnProperty.call(q, 'questionText')
      ? q.questionText
      : '';
    this.userResponse = Object.prototype.hasOwnProperty.call(q, 'userResponse')
      ? new UserResponse(q.userResponse)
      : new UserResponse();
    this.answers = Object.prototype.hasOwnProperty.call(q, 'answers')
      ? q.answers.map((answer) => new Answer(answer))
      : [];
  }

  get currentAnswerText() {
    return this.userResponse.currentAnswerText;
  }

  getAnswerByQuestionAnswerId(id) {
    return this.answers.find(({ questionAnswerId }) => questionAnswerId === id);
  }

  getAnswerByAnswerText(answer) {
    return this.answers.find(({ answerText }) => answerText === answer);
  }
}
