import PropTypes from 'prop-types';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

export default function Page({ fields = [] }) {
  const { renderForm } = useFormApi();

  return <div>{renderForm(fields)}</div>;
}

Page.propTypes = {
  fields: PropTypes.array,
  title: PropTypes.string,
};
