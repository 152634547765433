// ***** AUSMethod *****
export const AUS_METHOD_DU = 1;
export const AUS_METHOD_LP = 2;

// ***** Property Types *****
export const PROPERTY_TYPE_SUBJECT_PURCHASE = 1;
export const PROPERTY_TYPE_SUBJECT_REFI = 2;
export const PROPERTY_TYPE_REO = 3;

// ***** Rental Income Document ID *****
export const APPRAISAL_FORM_1007_1025 = 1;
export const LEASE_AGREEMENT = 2;
export const ONE_YEAR_1040_SCHEDULE_E = 3;
export const APPRAISAL_ORDER_PENDING = 4;
export const FHA_ONE_YEAR_1040_SCHEDULE_E = 5;
export const FHA_TWO_YEAR_1040_SCHEDULE_E = 6;
export const OPERATING_INCOME_FORM_998 = 7;
export const SCHEDULE_E_1040_DOCS = [
  ONE_YEAR_1040_SCHEDULE_E,
  FHA_ONE_YEAR_1040_SCHEDULE_E,
  FHA_TWO_YEAR_1040_SCHEDULE_E,
];
export const FHA_SCHEDULE_E_1040_DOCS = [
  FHA_ONE_YEAR_1040_SCHEDULE_E,
  FHA_TWO_YEAR_1040_SCHEDULE_E,
];

export const QUESTIONS_IDS = {
  twelveMonthRentalHistoryToolTip: 9,
  yearQuestion: [20, 34, 71, 72, 75, 76],
  matchingAddress: [44, 45, 73, 77],
  subjectRefiLpScheduleE: 46,
  subjectReoLpScheduleE: 47,
  fhaTwoYearHistorySPR: 53,
  fhaTwoYearHistoryREO: 56,
  fullAppraisal: [61, 69, 81],
  fhaTwoYearHistoryREOTwoToFour: 64,
  acquisitionDate: [55, 60],
  acquiredAfterTaxFiling: [67, 84],
  fhaOneYearIsYearOnDocument: 71,
  fhaOneYearCorrectYearDropdown: 72,
  fhaTwoYearIsYearOnDocument: 75,
  fhaTwoYearCorrectYearDropdown: 76,
  appraisalFormOrTwoMonthsReceipt: 91,
  leaseAgreementPrimaryConversionPropertyOutOfService: 113,
  leaseAgreementRootQuestionIds: [92, 116],
  leaseAgreementQuestionIds: [43, 92, 93, 111, 112, 113, 114, 116],
  form1040RootQuestionIds: [109, 110],
  form1040QuestionIds: [101, 102, 106, 107, 109, 110],
  leaseAgreementAnd1040RootQuestionIds: [115, 117],
  leaseAgreementAnd1040QuestionIds: [115, 117, 118, 119, 120, 121],
};

export const RENTAL_INCOME_VALIDATION_API_STATUS = {
  GETTING: 'GETTING',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_ERROR: 'GET_ERROR',
  UPDATING: 'UPDATING',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',
  GETTING_SNIPPET: 'GETTING_SNIPPET',
  GET_SNIPPET_SUCCESS: 'GET_SNIPPET_SUCCESS',
  GET_SNIPPET_ERROR: 'GET_SNIPPET_ERROR',
};

export const INITIAL_RENTAL_INCOME_VALIDATION_INFO = {
  documentOptions: [],
};
