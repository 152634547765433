import { API_STATUS } from 'common/constants';

import TYPES from './types';

export const auditHistoryInitialState = {
  error: null,
  status: null,
  data: {},
  selectedIds: [],
  showAuditHistory: false,
  isFeatureFlagEnabled: false,
  onInit: true,
  questionIds: [],
  questions: [],
};

const AuditHistoryReducer = (state = auditHistoryInitialState, action) => {
  switch (action?.type) {
    case TYPES.GET_AUDIT_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        status: API_STATUS.SUCCESS,
        error: null,
      };
    case TYPES.GET_AUDIT_HISTORY_DATA_STARTED:
      return {
        ...state,
        error: null,
        status: API_STATUS.UPDATING,
      };
    case TYPES.GET_AUDIT_HISTORY_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        status: API_STATUS.ERROR,
      };
    case TYPES.TOGGLE_SHOW_AUDIT_HISTORY:
      return {
        ...state,
        showAuditHistory: !state.showAuditHistory,
      };
    case TYPES.SET_SELECTED_HISTORY_ID:
      return {
        ...state,
        selectedIds: action.payload,
      };
    case TYPES.SET_QUESTION_IDS:
      return {
        ...state,
        questionIds: action.payload,
      };
    case TYPES.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case TYPES.TOGGLE_AUDIT_HISTORY:
      return {
        ...state,
        ...action.payload,
        showAuditHistory: !state.showAuditHistory,
      };
    default:
      return { ...state };
  }
};

export default AuditHistoryReducer;
