import React from 'react';
import PropTypes from 'prop-types';
import { SimpleGrid, HStack, Text } from 'common/components';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import BreakdownContainer from 'pages/IncomeCalculator/Pages/ReviewBreakdown/BreakdownContainer';
import { formatCurrency } from 'common/util/format';
import { CHILD_SUPPORT_CALCULATION_TYPES } from 'pages/IncomeCalculator/constants';

function CalculationBreakdown({ selectedResult, hideAccordion = false }) {
  const formula = selectedResult.getFormulas()[0]?.getFormula().split('=')[0];
  return (
    <BreakdownContainer
      header={`${
        CHILD_SUPPORT_CALCULATION_TYPES[selectedResult.calculatorTypeId]
      } Review Breakdown`}
      hideAccordion={hideAccordion}
    >
      <SimpleGrid columns={1} spacingY={6} mt={2}>
        <HStack>
          <Text mr={1} fontSize="sm" fontWeight="bold">
            Monthly Income Equation:
          </Text>
          <Text fontSize="sm" data-testid="calculator-type">
            (Monthly Amount) * {formula.split('*')[1]}
          </Text>
        </HStack>
        <HStack>
          <Text mr={1} fontSize="sm" fontWeight="bold">
            Calculation:
          </Text>
          <Text fontSize="sm" data-testid="formula-name">
            ({formula}) ={' '}
            {formatCurrency(selectedResult.getCalculationAmount())}
          </Text>
        </HStack>
        <HStack>
          <Text mr={1} fontSize="sm" fontWeight="bold">
            Monthly Income:
          </Text>
          <Text fontSize="sm" data-testid="formula-name">
            {formatCurrency(selectedResult.getCalculationAmount())}
          </Text>
        </HStack>
      </SimpleGrid>
    </BreakdownContainer>
  );
}

CalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel),
  hideAccordion: PropTypes.bool,
};
const CalculationBreakdownMemo = React.memo(CalculationBreakdown);
export default CalculationBreakdownMemo;
