import { connect } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';

export function mapStateToProps({
  home: { selectedAlert },
  url: { obfuscatedLoanIdentifier },
}) {
  return {
    loan: obfuscatedLoanIdentifier,
    selectedAlert,
  };
}

// INFO: ErrorBoundary is a class component
const ErrorBoundaryHOC = connect(mapStateToProps)(ErrorBoundary);
export default ErrorBoundaryHOC;
