export const GET_DRIVER_LICENSE_VALIDATION_ERROR_MESSAGE =
  'Unable to get Driver License Validation Information for review';

export const SAVE_DRIVER_LICENSE_VALIDATION_ERROR_MESSAGE =
  'Unable to save Driver License Validation Information';

export const GET_DRIVER_LICENSE_SNIPPET_ERROR_MESSAGE =
  'Unable to get Driver License Snippet Information for review';

export const GET_DRIVER_LICENSE_RESULTS_ERROR_MESSAGE =
  'Unable to get Driver License Results';

export const POST_DRIVER_LICENSE_QUESTION_ANSWERS_ERROR_MESSAGE =
  'Unable to get Driver License Question Answers Results';

export const RADIO_QUESTION_ANSWERS = {
  YES: 'Yes',
  NO: 'No',
};

export const QUESTION_STATUS = {
  FIRED: 1,
  ACTIVE: 2,
  HIDDEN: 3,
};

export const QUESTION_ANSWER_ID = {
  NAME: {
    YES: 32,
    NO: 31,
    FIRST: 34,
    MIDDLE: 35,
    LAST: 36,
  },
  ADDRESS_HISTORY: {
    YES: 28,
    NO: 27,
  },
  ADDRESS_LOX: {
    YES: 12002,
    NO: 12001,
  },
  DOB_CORRECT: {
    YES: 24,
    NO: 23,
  },
  DOB_SELECT: {
    YES: 25,
    NO: 26,
  },
  EXP_DATE: {
    YES: 22,
    NO: 21,
  },
};

export const PI_CONTENT_STRINGS = {
  SUB_HEADING: (name) =>
    `Review the below discrepancies found for ${name} between the information in our system and the personal identification provided.`,
  NAME: {
    EASE: 'What we have in EASE:',
    DOC: 'What we have on the document:',
  },
  ADDDRESS: {
    EASE: 'Residence history in EASE:',
    DOC: 'Borrower address from document:',
  },
  DATE_OF_BIRTH: {
    EASE: 'Date of Birth in EASE:',
    DOC: 'Date of Birth from document:',
  },
  EXP_DATE: {
    DOC: 'Expiration date from document:',
  },
  //fix
  ISSUE_DATE: {
    IND: 'Indicate the correct date:',
    DOC: 'License Issue Date:',
  },
};
