﻿import React from 'react';
import PropTypes from 'prop-types';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import { REQUEST_REFERENCE_TYPE } from '../enums';
import IncomeCalculationCard from './IncomeCalculationCard';
import RentalCalculationCard from './RentalCalculationCard';

const CalculationCard = ({ summary, onClick }) => {
  switch (summary.getRequestReferenceType()) {
    case REQUEST_REFERENCE_TYPE.employmentIncome:
      return <IncomeCalculationCard summary={summary} onClick={onClick} />;

    case REQUEST_REFERENCE_TYPE.rentalIncome:
      return <RentalCalculationCard summary={summary} onClick={onClick} />;
    default:
      return null;
  }
};
CalculationCard.propTypes = {
  summary: PropTypes.instanceOf(HistoricalCalculationDataModel).isRequired,
  onClick: PropTypes.func,
};

export default CalculationCard;
