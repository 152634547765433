import Grid from 'common/components/Grids/Grid';
import GridItem from 'common/components/Grids/GridItem';
import PropTypes from 'prop-types';
import InputMapper from './InputMapper';
import FormBuilderErrorMessage from './FormBuilderErrorMessage';

export default function FormBuilder({
  fields,
  columns = 4,
  errors = {},
  getValues,
  readOnlyMode = false,
  showErrors,
  rowGap,
  dataTestId,
}) {
  const readOnlyStyle = {
    pointerEvents: 'none',
    opacity: 0.6,
    cursor: 'not-allowed',
  };

  return (
    <Grid
      templateColumns={`repeat(${columns}, 1fr)`}
      gap={'3rem'}
      rowGap={rowGap ? rowGap : '1rem'}
      style={{ marginBottom: '1.5rem' }}
    >
      {fields.map((field) => {
        const { name, type } = field;
        const isVisible = field.isHidden ? !field.isHidden() : true;
        const isInvalid = showErrors && (errors[name] ? true : false);
        return (
          isVisible && (
            <GridItem
              colSpan={field.span ?? 1}
              colStart={field.colStart ?? undefined}
              key={name}
              style={readOnlyMode ? readOnlyStyle : {}}
            >
              <InputMapper
                type={type}
                tabIndex={readOnlyMode ? -1 : 0}
                getValues={getValues}
                isInvalid={isInvalid}
                dataTestId={dataTestId}
                value={field.value}
                {...field}
              />
              {errors[name] && showErrors && (
                <FormBuilderErrorMessage error={errors[name]} />
              )}
            </GridItem>
          )
        );
      })}
    </Grid>
  );
}

FormBuilder.propTypes = {
  columns: PropTypes.number,
  rowGap: PropTypes.string,
  dataTestId: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      span: PropTypes.number,
      readOnly: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  errors: PropTypes.object,
  getValues: PropTypes.func,
  handleChange: PropTypes.func,
  showErrors: PropTypes.bool,
  readOnlyMode: PropTypes.bool,
};
