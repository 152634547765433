const palette = {
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    350: '#CECECE',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  blue: {
    50: '#EBF8FF',
    100: '#CEEDFF',
    200: '#90CDF4',
    300: '#63B3ED',
    400: '#4299E1',
    500: '#3182CE',
    600: '#2A69AC',
    700: '#1E4F8C',
    800: '#153E75',
    900: '#1A365D',
  },
  orange: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },
  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#48BB78',
    800: '#22543D',
    900: '#1C4532',
  },
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  black: '#000000',
  white: '#FFFFFF',
  uwmOrange: {
    500: '#F16F00',
    600: '#C05621',
  },
  uwmLogo: {
    lightGray: '#E5E6E2',
    mediumGray: '#B2B5B6',
    darkGray: '#575757',
    blue: '#005F9E',
    teal: '#00A39D',
    limeGreen: '#A1CE57',
  },
  bgGray: {
    50: '#FCFCFC',
    100: '#FAFAFA',
    200: '#F7F7F7',
    300: '#E3E3E3',
    400: '#CACACA',
    500: '#B0B0B0',
    600: '#979797',
    700: '#888888',
    800: '#7E7E7E',
    900: '#646464',
  },
};

export default palette;
