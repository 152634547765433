import { connect } from 'react-redux';
import DownloadLink from './layout';
import pdfLink from 'pages/Home/redux/thunks';
import { API_STATUS } from 'common/constants';

export const mapStateToProps = (
  {
    home: {
      pdfLink: { status: pdfStatus, data: blobPdfUrl },
    },
    url: { obfuscatedLoanIdentifier },
  },
  {
    apiError,
    documentId,
    downloadError,
    handleDownload,
    file,
    icon,
    target,
    text,
    variant,
  },
) => {
  return {
    //ownProps
    apiError,
    documentId,
    downloadError,
    handleDownload,
    file,
    icon,
    target,
    text,
    variant,
    //Redux store variables
    obfuscatedLoanIdentifier,
    isFetching: pdfStatus === API_STATUS.GETTING,
    isError: pdfStatus === API_STATUS.ERROR,
    isDone: pdfStatus === API_STATUS.SUCCESS,
    pdfURL: pdfStatus === API_STATUS.SUCCESS && blobPdfUrl ? blobPdfUrl : '',
  };
};

export const mapDispatchToProps = {
  clearPdf: pdfLink.clearPdf,
};

const DownloadLinkHOC = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadLink);
export default DownloadLinkHOC;
