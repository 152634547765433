﻿import { UnnamedWidget } from 'lib-node-amb-renderer';
import Box from 'common/components/Box';
import IncomeHeader from './index.js';
import PropTypes from 'prop-types';
import CalculationHistory from '../CalculationHistory/index.js';

export class IncomeHeaderWithCalculationHistoryClass extends UnnamedWidget {
  showCalculationHistory;
  constructor(props) {
    super(props);
    this.showCalculationHistory = props.showCalculationHistory;
    this.sx = props.sx;
  }

  execute(data, props) {
    this.showCalculationHistory ??= false;
    return super.execute(data, props);
  }
}
const defaultSx = {
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 100,
  background: 'white',
};

const IncomeHeaderWithCalculationHistory = ({ showCalculationHistory, sx }) => {
  return (
    <Box sx={{ ...defaultSx, ...sx }}>
      <IncomeHeader />
      {showCalculationHistory && (
        <CalculationHistory
          position="absolute"
          top="5rem"
          right={0}
          background={'white'}
          marginRight=".5rem"
        />
      )}
    </Box>
  );
};

IncomeHeaderWithCalculationHistory.propTypes = {
  showCalculationHistory: PropTypes.bool,
  sx: PropTypes.object,
};

export default IncomeHeaderWithCalculationHistory;
