import React from 'react';
import PropTypes from 'prop-types';
import { Container as MUIContainer } from '@mui/material';
import { propsMapper } from 'common/components/PropertyMappers/propsMapper';

const Container = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <MUIContainer ref={ref} {...propsMapper(props)}>
      {children}
    </MUIContainer>
  );
});

Container.displayName = 'Container';

export default Container;

Container.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  disableGutters: PropTypes.bool,
  fixed: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    PropTypes.string,
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
