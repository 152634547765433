import axios from 'axios';
import oidcManager from './oidc-manager';
import jwtDecode from 'jwt-decode';
import API_ENDPOINTS from '../constants/api-endpoints.js';

/* Returns a new Axios instance configured for the project
 * @param {number} timeout - how many milliseconds you'd like Axios to wait before reporting a timeout error.  Defaults to 0 (or not specified).  This setting has no effect on the server's timeout limit.
 */

const apiClient = axios.create({
  baseURL: `${window.globalConfig?.api.endpoint_uri}/`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
apiClient.lastRequest = null;

/* Interceptor for adding bearer token to requests.
This isn't needed when using the mock API */

const userExpired = (user) => {
  return (
    !user ||
    user.expired ||
    !user.access_token ||
    new Date(jwtDecode(user.access_token).exp * 1000) <= Date.now()
  );
};

/* c8 ignore next */
apiClient.interceptors.request.use(
  async (config) => {
    if (!config.url.endsWith(API_ENDPOINTS.POST_ERROR_LOG)) {
      apiClient.lastRequest = config.url.replace(config.baseURL, '');
    }
    if (window.ADRUM) {
      const apiCallLogger = new window.ADRUM.events.Ajax();
      window.ADRUM.report(apiCallLogger);
    }
    let user = await oidcManager.getUser();

    if (userExpired(user)) {
      await oidcManager.clearStaleState();
      if (!user?.refresh_token) {
        return oidcManager.signinRedirect({
          state: window.location.href,
        });
      } else {
        await oidcManager.signInSilentWithRedirectFallback();
        user = await oidcManager.getUser();
      }
    }

    config.headers.authorization = `Bearer ${user.access_token}`;
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error));
  },
);

export default apiClient;
