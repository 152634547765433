import React from 'react';
import { Radio as ChakraRadio } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Radio = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraRadio ref={ref} {...props}>
      {children}
    </ChakraRadio>
  );
});

Radio.displayName = 'Radio';

export default Radio;

Radio.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
