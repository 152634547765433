import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, ...props }) => {
  return <ChakraTooltip {...props}>{children}</ChakraTooltip>;
};

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
