export default class BoltApprovalData {
  constructor(ad = {}) {
    this.loanConditionData = Object.prototype.hasOwnProperty.call(
      ad,
      'loanConditionData',
    )
      ? {
          ...ad.loanConditionData,
          preapprovalLoanConditions:
            ad.loanConditionData.preapprovalLoanConditions.sort((a, b) =>
              a.condAlias > b.condAlias ? 1 : -1,
            ),
        }
      : {
          preapprovalLoanConditions: [
            {
              csid: 0,
              condName: 'string',
              dateCompleted: '2021-09-07T15:56:42.685Z',
              dateFirstEntered: '2021-09-07T15:56:42.685Z',
              categoryID: 0,
              lcOrder: 0,
              approvedFlag: 'string',
              condID: 0,
              printCondition: 'string',
              condEnum: 'string',
              state: 'string',
              sourceID: 0,
              condAlias: 'string',
            },
          ],
        };

    this.loanInfo = Object.prototype.hasOwnProperty.call(ad, 'loanInfo')
      ? ad.loanInfo
      : {
          loanNumber: 0,
          borrowers: [
            {
              customerRecordId: 0,
              firstName: '',
              lastName: '',
              address: '',
              isPrimaryBorrower: true,
            },
          ],
          propertyAddress: {
            addressLine1: '',
            unit: '',
            city: '',
            state: '',
            zipCode: '',
          },
          totalLoanAmount: 0,
        };

    this.complianceFooter = Object.prototype.hasOwnProperty.call(
      ad,
      'complianceFooter',
    )
      ? ad.complianceFooter
      : '';
  }

  getPreapprovalConditions() {
    return this.loanConditionData.preapprovalLoanConditions;
  }

  setPreapprovalConditions(conditions = []) {
    if (conditions.length > 0) {
      this.loanConditionData.preapprovalLoanConditions = [...conditions];
    }
  }

  getLoanInfo() {
    return this.loanInfo;
  }

  setLoanInfo(loanInfo = {}) {
    if (Object.entries(loanInfo).length > 0) {
      this.loanInfo = { ...loanInfo };
    }
  }

  getComplianceFooter() {
    return this.complianceFooter;
  }

  setComplianceFooter(footerText = '') {
    if (footerText) {
      this.complianceFooter = footerText;
    }
  }
}
