import PropTypes from 'prop-types';
import { Box, Flex, ListItem, OrderedList, Text } from 'common/components';
import BreakdownValue from '../styles/BreakdownValue';
import BreakdownContainer from '../BreakdownContainer';
import BreakdownColumnBox from '../styles/BreakdownColumnBox';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import SingleLineValue from '../styles/SingleLineValue';
import Continuance from './Continuance';
import { formatCurrency } from 'common/util/format';
import { pixelsToRem } from 'theme/typography';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormTypes, FormulaType } from 'pages/IncomeCalculator/enums';
import lang from '../language.json';

const inputForm = FormTypes.Form1040;

const Hr = (props) => (
  <Box {...props} role="separator" borderBottom="1px solid black" h={1} />
);

function mapFormulaForSingleYear(formulaId) {
  switch (formulaId) {
    case FormulaType.IRADistributionsForm1040OneYear:
    case FormulaType.IRADistributionsForm1040TwoYear:
      return [lang.FORMULAS.IRA_1040_CONV, '.25'];
    case FormulaType.IRADistributionsForm1040OneYearFHA:
    case FormulaType.IRADistributionsForm1040TwoYearFHA:
      return [lang.FORMULAS.IRA_1040_FHA, '.15'];
    default:
      return ['', ''];
  }
}

export default function IRA1040Breakdown({
  selectedResult,
  hideAccordion = false,
  onNewWindow,
}) {
  const fiscalYears = selectedResult.getFiscalYears().sort((a, b) => b - a);
  const formula = selectedResult.getFormulas()[0];
  const [equation, grossUp] = mapFormulaForSingleYear(formula.formulaId);
  const annuals = {};

  function OneYearBreakdown({ year }) {
    const distributions = selectedResult
      .getInput(inputForm, year, 'iraDistributions')
      ?.getValue();
    const taxable = selectedResult
      .getInput(inputForm, year, 'taxableAmount')
      ?.getValue();
    const subtotal = parseFloat(distributions) - parseFloat(taxable);
    const grossedUp = subtotal * parseFloat(grossUp);
    annuals[year] = parseFloat(distributions) + grossedUp;

    return (
      <BreakdownYearContainer title={year} flexGrow={1} pb={4}>
        <SingleLineValue title={lang.EQUATION} value={equation} />
        <BreakdownColumnBox title={lang.CALCULATIONS}>
          <OrderedList fontSize={pixelsToRem(14)}>
            <ListItem>
              {formatCurrency(distributions)} - {formatCurrency(taxable)} ={' '}
              {formatCurrency(subtotal)} (Net non-taxable income)
            </ListItem>
            <ListItem>
              {formatCurrency(subtotal)} * {grossUp} ={' '}
              {formatCurrency(grossedUp)} (Grossed up amount)
            </ListItem>
            <ListItem>
              {formatCurrency(distributions)} + {formatCurrency(grossedUp)} ={' '}
              {formatCurrency(annuals[year])} (Annual amount)
            </ListItem>
          </OrderedList>
        </BreakdownColumnBox>
        <Hr />
        {formatCurrency(annuals[year])}
      </BreakdownYearContainer>
    );
  }
  OneYearBreakdown.propTypes = {
    year: PropTypes.number.isRequired,
  };

  function MonthlyIncomeBreakdown() {
    const total = Object.values(annuals).reduce((acc, value) => acc + value, 0);
    const years = Object.keys(annuals).length;

    return (
      <Box mb={4}>
        <Text fontWeight="bold" fontSize={pixelsToRem(14)} mb={2}>
          {lang.MONTHLY_CALC}
        </Text>
        {years > 1 && (
          <Box d="inline-block">
            {Object.values(annuals).map((annual, i) => (
              <BreakdownValue
                value={annual}
                isChild={i > 0}
                isCurrency
                pr={4}
                key={i}
              />
            ))}
            <Hr my={1} />
          </Box>
        )}
        <Text fontSize="small">
          {formatCurrency(total)} / {years * 12} (months)
        </Text>
      </Box>
    );
  }

  return (
    <BreakdownContainer
      header={lang.HEADERS[selectedResult.getCalculatorType()]}
      hideAccordion={hideAccordion}
      onNewWindow={onNewWindow}
    >
      <Flex justifyContent="space-between" gridGap={4} mb={4}>
        {fiscalYears.map((fy) => (
          <OneYearBreakdown year={fy} key={fy} />
        ))}
      </Flex>
      <MonthlyIncomeBreakdown />
      <SingleLineValue
        title={lang.MONTHLY_INCOME}
        value={selectedResult.getCalculationAmount()}
        isCurrency
      />
      <Continuance selectedResult={selectedResult} />
    </BreakdownContainer>
  );
}
IRA1040Breakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel),
  hideAccordion: PropTypes.bool,
  onNewWindow: PropTypes.func,
};
