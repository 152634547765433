import TYPES from './types';
import { API_STATUS } from 'common/constants';
import { BOLT_APPROVAL_ERROR_MESSAGE } from '../constants';

const initialState = {
  runAus: {
    status: null,
    error: null,
    ausTriggered: false,
    startTimeOut: null,
  },
  skipAus: {
    status: null,
    error: null,
    ausTriggered: false,
    startTimeOut: null,
  },
  ausResponse: {
    status: null,
    error: null,
    data: {
      ausRecommendation: '',
      underwritingMethod: '',
    },
  },
  runBoltApproval: {
    status: null,
    error: null,
    data: null,
  },
  initialValidations: {
    status: null,
    error: null,
    data: null,
  },
  productValidation: {
    status: null,
    error: null,
    data: null,
  },
};

const finalReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.RUN_AUS_STARTED:
      return {
        ...state,
        runAus: {
          ...state.runAus,
          status: API_STATUS.UPDATING,
          ausTriggered: true,
        },
      };
    case TYPES.RUN_AUS_SUCCESS:
      return {
        ...state,
        runAus: {
          ...state.runAus,
          status: null,
          error: null,
        },
      };
    case TYPES.RUN_AUS_ERROR:
      return {
        ...state,
        runAus: {
          ...state.runAus,
          status: null,
        },
      };

    case TYPES.SKIP_AUS_STARTED:
      return {
        ...state,
        skipAus: {
          ...state.skipAus,
          status: API_STATUS.UPDATING,
          ausTriggered: true,
        },
      };
    case TYPES.SKIP_AUS_SUCCESS:
      return {
        ...state,
        skipAus: {
          ...state.skipAus,
          status: API_STATUS.SUCCESS,
          error: null,
        },
      };
    case TYPES.SKIP_AUS_ERROR:
      return {
        ...state,
        skipAus: {
          ...state.skipAus,
          status: API_STATUS.ERROR,
        },
      };
    case TYPES.SET_AUS_TIMEOUT:
      return {
        ...state,
        runAus: {
          ...state.runAus,
          startTimeOut: action.value,
        },
      };
    case TYPES.GET_AUS_RECOMMENDATION_STARTED:
      return {
        ...state,
        ausResponse: {
          ...state.ausResponse,
          status: API_STATUS.GETTING,
          error: null,
        },
      };
    case TYPES.GET_AUS_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        ausResponse: {
          ...state.ausResponse,
          status: null,
          error: null,
          data: action.payload,
        },
      };
    case TYPES.GET_AUS_RECOMMENDATION_ERROR:
      return {
        ...state,
        ausResponse: {
          ...state.ausResponse,
          status: null,
          error: null,
        },
      };

    case TYPES.PRODUCT_VALIDATION_STARTED:
      return {
        ...state,
        productValidation: {
          status: API_STATUS.GETTING,
          error: null,
          data: null,
        },
      };
    case TYPES.PRODUCT_VALIDATION_SUCCESS:
      return {
        ...state,
        productValidation: {
          data: action.payload,
          status: API_STATUS.DONE,
          error: null,
        },
      };
    case TYPES.PRODUCT_VALIDATION_ERROR:
      return {
        ...state,
        productValidation: {
          status: API_STATUS.ERROR,
          error: action.payload.error,
        },
      };
    case TYPES.RUN_BOLT_APPROVAL_STARTED:
      return {
        ...state,
        runBoltApproval: {
          ...state.runBoltApproval,
          status: API_STATUS.GETTING,
          error: null,
        },
      };

    case TYPES.RUN_BOLT_APPROVAL_SUCCESS:
      return {
        ...state,
        runBoltApproval: {
          ...state.runBoltApproval,
          status: null,
          error: null,
          data: action.payload,
        },
      };
    case TYPES.RUN_BOLT_APPROVAL_ERROR:
      return {
        ...state,
        runBoltApproval: {
          ...state.runBoltApproval,
          status: null,
          error: BOLT_APPROVAL_ERROR_MESSAGE,
        },
      };

    case TYPES.CLEAR_BOLT_APPROVAL_DATA:
      return {
        ...state,
        runBoltApproval: {
          ...state.runBoltApproval,
          status: null,
          error: null,
          data: null,
        },
      };
    case TYPES.GET_LOAN_CONDITIONS_STARTED:
      return {
        ...state,
        getLoanConditions: {
          ...state.getLoanConditions,
          status: API_STATUS.GETTING,
          error: null,
        },
      };

    case TYPES.GET_LOAN_CONDITIONS_SUCCESS:
      return {
        ...state,
        getLoanConditions: {
          ...state.getLoanConditions,
          status: null,
          error: null,
          data: action.payload,
        },
      };
    case TYPES.GET_LOAN_CONDITIONS_ERROR:
      return {
        ...state,
        getLoanConditions: {
          ...state.getLoanConditions,
          status: null,
          error: 'error',
        },
      };

    case TYPES.GET_INITIAL_VALIDATIONS_STARTED:
      return {
        ...state,
        initialValidations: {
          ...state.initialValidations,
          status: API_STATUS.GETTING,
          error: null,
          data: null,
        },
      };

    case TYPES.GET_INITIAL_VALIDATIONS_SUCCESS:
      return {
        ...state,
        initialValidations: {
          ...state.initialValidations,
          data: action.payload,
          status: API_STATUS.SUCCESS,
          error: null,
        },
      };

    case TYPES.GET_INITIAL_VALIDATIONS_ERROR:
      return {
        ...state,
        initialValidations: {
          ...state.initialValidations,
          status: API_STATUS.ERROR,
          error: 'Error getting initial validations',
        },
      };
    default:
      return state;
  }
};
export default finalReviewReducer;
