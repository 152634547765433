import { AlertTitle as ChakraAlertTitle } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AlertTitle = ({ children, ...props }) => {
  return <ChakraAlertTitle {...props}>{children}</ChakraAlertTitle>;
};

export default AlertTitle;

AlertTitle.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
