import React from 'react';
import { TableRow as MUITr } from '@mui/material';
import { tableMapper } from 'common/components/PropertyMappers/tableMapper';
import PropTypes from 'prop-types';

const Tr = React.forwardRef(({ children, ...props }, ref) => {
  const tableProps = tableMapper(props);

  return (
    <MUITr ref={ref} {...tableProps}>
      {children}
    </MUITr>
  );
});

Tr.displayName = 'Tr';

export default Tr;

Tr.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  hover: PropTypes.bool,
  selected: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
