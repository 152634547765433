import { DrawerHeader as ChakraDrawerHeader } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const DrawerHeader = ({ children, ...props }) => {
  return <ChakraDrawerHeader {...props}>{children}</ChakraDrawerHeader>;
};

export default DrawerHeader;

DrawerHeader.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
