import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import AccordionButton from 'common/components/Accordions/AccordionButton';
import AccordionIcon from 'common/components/Accordions/AccordionIcon';
import AccordionItem from 'common/components/Accordions/AccordionItem';
import Accordion from 'common/components/Accordions/Accordion';
import AccordionPanel from 'common/components/Accordions/AccordionPanel';
import HStack from 'common/components/Stacks/HStack';
import VStack from 'common/components/Stacks/VStack';
import Tooltip from 'common/components/Tooltip';
import Divider from 'common/components/Divider';
import SimpleGrid from 'common/components/Grids/Grid/SimpleGrid';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Alert from 'common/components/Alerts/Alert';
import { formatCurrency } from 'common/util/format';
import RentalCalculationReviewBreakdown from '../model/RentalCalculationReviewBreakdown';
import language from '../language.json';

const renderFormulaLine = (formula, addBacks, colors, key) => {
  switch (formula.Name) {
    case 'Gross Rents Equation':
      return (
        <Box align="start" key={key}>
          <Text fontSize="14px" fontWeight="700">
            {formula.Name + ':'}
          </Text>
          <Box>
            {formula.Value.map((val, i) => {
              if (val === 'Addbacks') {
                return (
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={`${addBacks.join(', ')}`}
                    color={colors.black}
                    bg={colors.gray[200]}
                    key={i}
                  >
                    <Text as="span" color={colors.blue[500]}>
                      {val}
                    </Text>
                  </Tooltip>
                );
              } else {
                return (
                  <Text as="span" key={i}>
                    {val}
                  </Text>
                );
              }
            })}
          </Box>
        </Box>
      );
    default:
      return (
        <VStack align="left" key={key}>
          <Text fontSize="14px" fontWeight="700">
            {formula.Name?.join?.(': ') || formula.Name + ':'}
          </Text>
          {formula.Value.map((val, i) => {
            if (val === 'LineBreak')
              return (
                <Divider
                  width="190px"
                  borderColor="black"
                  orientation="horizontal"
                  key={i}
                />
              );
            return (
              <Text key={val} fontSize="14px" fontWeight="400">
                {val}
              </Text>
            );
          })}
        </VStack>
      );
  }
};

const renderYearContainer = (
  title,
  breakdown,
  addBacksList,
  colors,
  hideAccordion,
) => {
  const Container = hideAccordion ? Box : AccordionPanel;
  return (
    <Box border="1px solid" borderRadius={4} borderColor="gray.200" key={title}>
      <Box bgColor="gray.50" borderTopRadius={4} h="40px" p={2} px={4}>
        <Text fontWeight={500} fontSize="lg">
          {title < 1
            ? language.FHA_1040_FORM_HEADERS.MOST_RECENT_YEAR
            : language.FHA_1040_FORM_HEADERS.PREVIOUS_YEAR}
        </Text>
      </Box>
      <Container p={6} mt="2px" background={colors.white}>
        <VStack align="left" spacing={4}>
          {breakdown?.map((formula, key) =>
            renderFormulaLine(formula, addBacksList, colors, key),
          )}
        </VStack>
      </Container>
    </Box>
  );
};

const renderFHA1040Breakdown = (formula, colors, hideAccordion) => {
  const breakdownCopy = [...formula.BreakDown];
  const topOfLine = breakdownCopy.splice(0, 1)[0];
  const startingPoint = breakdownCopy.findIndex(
    (breakdown) => breakdown.SortOrder === 1,
  );
  const endPoint = breakdownCopy.findIndex(
    (breakdown) => breakdown.SortOrder === 4,
  );
  const yearBreakdowns = [breakdownCopy.splice(startingPoint, endPoint + 1)];
  if (breakdownCopy[startingPoint].SortOrder === 1) {
    yearBreakdowns.push(breakdownCopy.splice(startingPoint, endPoint + 1));
  }
  const restOfFormula = breakdownCopy;

  return (
    <VStack align="left" spacing={4}>
      {renderFormulaLine(topOfLine, formula.addBacksList, colors)}
      <SimpleGrid columns={2} spacing={8} pb={4}>
        {yearBreakdowns.map((year, i) => {
          return renderYearContainer(
            i,
            year,
            formula.addBacksList,
            colors,
            hideAccordion,
          );
        })}
      </SimpleGrid>
      {restOfFormula.map((formula, key) =>
        renderFormulaLine(formula, formula.addBacksList, colors, key),
      )}
    </VStack>
  );
};

function Rental1040({
  data,
  pita,
  formulas,
  isFHA1040,
  isInCombinedCard,
  hideAccordion,
}) {
  const { colors } = useTheme();
  const productTypeName = useSelector(
    ({ home }) => home.loanSummary?.value?.productType,
  );
  if (isFHA1040 === undefined) {
    isFHA1040 = productTypeName === 'FHA';
  }

  if (!data) {
    return null;
  }

  const twelveMonthHistoryTip =
    data.inputFields?.find(
      (b) => b?.fieldName === 'DisplayRentalTwelveMonthHistoryTip',
    )?.fieldValue === 'True';

  function Panel() {
    return (
      <>
        {!isFHA1040 && (
          <VStack align="left" spacing={4}>
            {formulas?.BreakDown?.map((formula, key) =>
              renderFormulaLine(formula, formulas.addBacksList, colors, key),
            )}
          </VStack>
        )}
        {isFHA1040 && renderFHA1040Breakdown(formulas, colors, hideAccordion)}
      </>
    );
  }

  return (
    <Box>
      {hideAccordion && (
        <Box pl="1rem">
          <Panel />
        </Box>
      )}
      {!hideAccordion && (
        <Accordion
          defaultIndex={[0]}
          allowMultiple
          mt={isInCombinedCard ? 4 : 8}
        >
          <AccordionItem
            background={colors.gray[50]}
            border={`1px solid ${colors.gray[200]}`}
          >
            <AccordionButton>
              <Box
                flex="1"
                textAlign="left"
                as="h3"
                fontSize="18px"
                fontWeight="500"
              >
                1040 - Schedule E Review Breakdown
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel p={6} mt="2px" background={colors.white}>
              <Panel />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
      {twelveMonthHistoryTip && (
        <Alert status="info" mt={4}>
          {language.FNMA_GUIDELINES}
        </Alert>
      )}
      {!isInCombinedCard && (
        <Box
          bg={colors.bgGray[100]}
          padding={{ sm: '20px', md: '20px' }}
          mt={8}
        >
          <Text fontSize="16px" fontWeight="500">
            1040 - Schedule E:
          </Text>
          <HStack>
            <Text fontSize="14px" fontWeight="500">
              Adjusted PITIA:
            </Text>
            <Text fontSize="14px" fontWeight="400">
              {pita && `${formatCurrency(pita)} to `}
              {formatCurrency(data.getAdjustedPITIA())}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize="14px" fontWeight="500">
              Positive Net Rental Income:
            </Text>
            <Text fontSize="14px" fontWeight="400">
              {formatCurrency(data.getPositiveNetRentalIncome())}
            </Text>
          </HStack>
        </Box>
      )}
    </Box>
  );
}

export default Rental1040;

Rental1040.propTypes = {
  data: PropTypes.instanceOf(RentalCalculationReviewBreakdown),
  pita: PropTypes.number,
  formulas: PropTypes.object,
  isFHA1040: PropTypes.bool,
  isInCombinedCard: PropTypes.bool,
  hideAccordion: PropTypes.bool,
};
