import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import VStack from 'common/components/Stacks/VStack';
import Stack from 'common/components/Stacks/Stack';
import Flex from 'common/components/Flex';
import { Button as CommonButton } from 'common/components/Button';

import theme from 'theme/';

const showFormAnimation = keyframes`
 from {
  padding: 0;
  opacity: 0;
  border-width: 0;
  background-color: transparent;
  overflow: hidden;
 }
 50% {
  opacity: 0;
  overflow: hidden;
 }
 to {
  height: auto;
  padding: 1rem;
  opacity: 1;
  border-width: 0.1rem;
  overflow: visible;
  background-color: ${theme.colors.gray[50]};
 }
 `;

const StyledVStack = styled(VStack)`
  width: 100%;
  border: 0rem solid ${theme.colors.blue[600]};
  margin-bottom: 2rem;
  opacity: 0;
  animation: ${showFormAnimation} 0.25s linear forwards;
`;

export default function Loan1003FormContainer({
  onCloseClicked,
  isLoading,
  onSaveClicked,
  toggleErrors,
  children,
  disableSave = false,
  readOnlyMode = false,
}) {
  return (
    <StyledVStack align={'flex-start'}>
      {children}
      <Stack width={'100%'} boxSizing="border-box">
        {!readOnlyMode && (
          <Flex
            align={'center'}
            boxSizing="border-box"
            justify={'flex-end'}
            padding={'0rem 1rem'}
          >
            <CommonButton
              isPrimary={false}
              id="asset-cancel-save-btn"
              onClick={() => onCloseClicked()}
            >
              Cancel
            </CommonButton>
            <CommonButton
              marginLeft={'1rem'}
              isDisabled={disableSave}
              isPrimary={true}
              isLoading={isLoading}
              onClick={() => {
                onSaveClicked();
                toggleErrors();
              }}
            >
              Save
            </CommonButton>
          </Flex>
        )}
      </Stack>
    </StyledVStack>
  );
}

Loan1003FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  toggleErrors: PropTypes.func,
  readOnlyMode: PropTypes.bool,
  disableSave: PropTypes.bool,
  summaryCategory: PropTypes.string,
};
