import EmploymentIncome from '../Paystub/EmploymentIncomeDataModel';
import BorrowerAddress from 'model/BorrowerAddressDataModel';
import EmploymentExtractedDataDataModel from './EmploymentExtractedDataDataModel';

export default class EmploymentIncomeValidationData {
  constructor(vd = {}) {
    this.employmentIncome = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentIncome',
    )
      ? vd.employmentIncome.map((x) => new EmploymentIncome(x))
      : [];

    this.borrowerAddress = Object.prototype.hasOwnProperty.call(
      vd,
      'borrowerAddresses',
    )
      ? vd.borrowerAddresses.map((x) => new BorrowerAddress(x))
      : [];

    this.employmentExtractedData = Object.prototype.hasOwnProperty.call(
      vd,
      'employmentExtractedData',
    )
      ? new EmploymentExtractedDataDataModel(vd.employmentExtractedData)
      : new EmploymentExtractedDataDataModel();

    this.validationResults =
      Object.prototype.hasOwnProperty.call(vd, 'results') &&
      vd.results?.length > 0
        ? vd.results
        : [
            {
              ruleDetails: {
                ruleId: 0,
                category: 0,
                subCategoryId: 0,
              },
              status: 0,
              message: '',
              questions: [],
              additionalData: {
                licenseId: '',
              },
            },
          ];

    this.userInputDocumentData = Object.prototype.hasOwnProperty.call(
      vd,
      'userInputDocumentData',
    )
      ? vd.userInputDocumentData
      : '';

    this.incomeDocumentFields = Object.prototype.hasOwnProperty.call(
      vd,
      'incomeDocumentFields',
    )
      ? vd.incomeDocumentFields
      : [];

    this.borrowerSSN = Object.prototype.hasOwnProperty.call(vd, 'borrowerSSN')
      ? vd.borrowerSSN
      : '';
    this.ausUnderwritingMethod = Object.prototype.hasOwnProperty.call(
      vd,
      'ausUnderwritingMethod',
    )
      ? vd.ausUnderwritingMethod
      : null;

    this.incomeDocumentFieldQuestions = Object.prototype.hasOwnProperty.call(
      vd,
      'incomeDocumentFieldQuestions',
    )
      ? vd.incomeDocumentFieldQuestions
      : [];

    this.cpaInformationStrings = Object.prototype.hasOwnProperty.call(
      vd,
      'cpaInformationStrings',
    )
      ? vd.cpaInformationStrings
      : [];

    this.incomeDocumentId = Object.hasOwn(vd, 'incomeDocumentId')
      ? vd.incomeDocumentId
      : '';

    this.autoAnswers = Object.hasOwn(vd, 'autoAnswers') ? vd.autoAnswers : {};
  }

  /**
   * @returns {[]} The list of Employers.
   */
  getEmployerInfo() {
    return this.employmentIncome;
  }

  /**
   * @returns {[]} The list of borrowers address.
   */
  getBorrowerAddress() {
    return this.borrowerAddress;
  }

  /**
   * @returns {} The employment extraction data.
   */
  getEmploymentExtractedData() {
    return this.employmentExtractedData;
  }

  getUserInputDocumentData() {
    return this.userInputDocumentData;
  }

  getValidationResults() {
    return this.validationResults;
  }

  getValidationResult(ruleId) {
    return this.validationResults.filter(
      (vr) => vr.ruleMetadata?.ruleId === ruleId,
    );
  }

  getIncomeDocumentFields() {
    return this.incomeDocumentFields;
  }

  getAUSUnderwritingMethod() {
    return this.ausUnderwritingMethod;
  }

  getIncomeDocumentFieldQuestions() {
    return this.incomeDocumentFieldQuestions;
  }

  getCpaInformationStrings() {
    return this.cpaInformationStrings;
  }

  getAutoAnswers() {
    return this.autoAnswers;
  }
}
