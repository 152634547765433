import { Divider as ChakraDivider } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Divider = ({ ...props }) => {
  return <ChakraDivider {...props} />;
};

export default Divider;

Divider.propTypes = {
  props: PropTypes.object,
};
