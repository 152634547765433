﻿export default class IncomeHeader {
  constructor(i = {}) {
    this.monthlyIncome = i?.getMonthlyAmount() ?? 0;
    this.fullName = i?.getBorrowerName().getFullName() ?? '';
    this.incomeCategoryType = i?.getIncomeCategoryType() ?? '';
    this.startDate = i?.getStartDate()?.toLocaleDateString() ?? '';
    this.isPrimarySource = i?.getIsPrimarySource() ?? false;
  }

  getMonthlyIncome() {
    return this.monthlyIncome;
  }

  getFullName() {
    return this.fullName;
  }

  getIncomeCategoryType() {
    return this.incomeCategoryType;
  }

  getStartDate() {
    return this.startDate;
  }

  getIsPrimarySource() {
    return this.isPrimarySource;
  }
}
