import AccordionContainer from 'common/components/AccordionContainer';
import AuditTable from './AuditTable';
import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';
import theme from 'theme/';

const accordionProps = {
  h2Style: {
    borderBottomWidth: '1px',
    borderColor: `${theme.colors.blue['600']}`,
  },
  color: { color: `${theme.colors.blue['600']}` },
  border: 'none !important',
};

// TODOs
// [ ] refactor to use styled-components instead of inline style objects
export default function AuditHistoryAccordion({ data }) {
  return data.map((audit) => {
    return (
      <AccordionContainer key={audit.title} header={audit.title}>
        {audit?.auditSections?.map((section, index) =>
          section?.fields?.length > 0 ? (
            <AccordionContainer
              key={`audit-accordion-inner-${index}`}
              header={section?.type}
              {...accordionProps}
            >
              <AuditTable key={`audit-table-${index}`} data={section.fields} />
            </AccordionContainer>
          ) : section?.children?.length > 0 ? (
            <AccordionContainer
              key={`audit-accordion-inner-${index}`}
              header={section?.type}
              {...accordionProps}
            >
              {section?.children?.map((childSection, childIndex) => (
                <>
                  <Text
                    sx={{
                      padding: '16px 10px 16px 16px',
                      fontWeight: '500',
                      fontSize: '0.875rem',
                      lineHeight: '1.5rem',
                    }}
                  >
                    {childSection?.type}
                  </Text>
                  <AuditTable
                    key={`audit-table-${index}-${childIndex}`}
                    data={childSection?.fields}
                  />
                </>
              ))}
            </AccordionContainer>
          ) : null,
        )}
      </AccordionContainer>
    );
  });
}

AuditHistoryAccordion.propTypes = {
  data: PropTypes.array,
};
