import { FormControl as ChakraFormControl } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const FormControl = ({ children, ...props }) => {
  return <ChakraFormControl {...props}>{children}</ChakraFormControl>;
};

export default FormControl;

FormControl.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
