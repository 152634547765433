import { Flex, MenuItem } from 'common/components';
import InputLabel from 'common/components/InputLabel';
import ListItemText from 'common/components/Lists/ListItemText';
import FormControl from 'common/components/Forms/FormControl/FormControl';
import Checkbox from 'common/components/Checkboxes/Checkbox/MuiCheckbox';
import EnhancedSelect from 'common/components/Selects/EnhancedSelect';
import PropTypes from 'prop-types';

const findValue = (value, options) =>
  options.find((option) => option.value === value).label;

export default function CrudGridFilter({
  filter,
  currentFilter,
  handleFilterChange,
}) {
  return (
    <Flex width={'15rem'}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
        <InputLabel id="demo-multiple-checkbox-label">
          {filter.label}
        </InputLabel>
        <EnhancedSelect
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          isMultiple={true}
          value={currentFilter}
          label={filter.label}
          onChange={(e) => {
            handleFilterChange(filter, e.target.value);
          }}
          renderValue={(selected) =>
            selected.map((value) => findValue(value, filter.options)).join(', ')
          }
        >
          {filter.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={currentFilter.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </EnhancedSelect>
      </FormControl>
    </Flex>
  );
}

CrudGridFilter.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  currentFilter: PropTypes.array,
  handleFilterChange: PropTypes.func,
};
