import { set, get, cloneDeep } from 'lodash';
import { REO_TYPES } from './types';
import { INITIAL_REO_FORM_ERRORS } from '../constants';
import { API_STATUS } from '../../../common/constants';
import { setREOData } from '../model/REOController';
import { ERROR_TYPES, INPUT_TYPES } from './types';

const initialState = {
  status: null,
  error: null,
  formDataErrors: INITIAL_REO_FORM_ERRORS,
  data: [],
  disableSave: false,
  validationError: false,
};

const REOReducer = (state = initialState, action) => {
  const stateCopy = cloneDeep(state);
  const inputName = action.payload?.name;

  let stateObjectPath = `${inputName}`;

  switch (action.type) {
    case REO_TYPES.GET_REO_SUCCESS:
    case REO_TYPES.UPDATE_REO_SUCCESS: {
      return {
        ...state,
        data: setREOData(action.payload),
        status: null,
      };
    }
    case REO_TYPES.GET_REO_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
        error: null,
      };

    case REO_TYPES.UPDATE_REO_STARTED:
      return {
        ...state,
        status: API_STATUS.UPDATING,
        error: null,
      };

    case REO_TYPES.GET_REO_ERROR:
    case REO_TYPES.UPDATE_REO_ERROR: {
      return {
        ...state,
        error: action.error,
        status: null,
      };
    }

    case INPUT_TYPES.STRING: {
      if (action.payload.value === undefined) action.payload.value = null;
      set(stateCopy, stateObjectPath, action.payload.value);
      return stateCopy;
    }

    case INPUT_TYPES.OBJECT: {
      const userResponseObject = get(stateCopy, stateObjectPath);
      const payload = action.payload.value;
      const updatedUserResponse = {
        ...userResponseObject,
        currentAnswerText: payload.currentAnswerText,
        questionAnswerId: payload.questionAnswerId,
      };
      set(stateCopy, stateObjectPath, updatedUserResponse);

      return stateCopy;
    }

    case INPUT_TYPES.CURRENCY: {
      const amountString = action?.payload?.value
        ?.toString()
        .replace(/[^0-9.]/g, '');

      let amountNumber = amountString === '' ? null : amountString;
      set(stateCopy, stateObjectPath, amountNumber);

      if (action?.payload?.resetErrors) {
        stateCopy.validationError[action.payload.name] = undefined;
      }

      return stateCopy;
    }

    case INPUT_TYPES.BOOLEAN: {
      set(stateCopy, stateObjectPath, action.payload.value);
      return stateCopy;
    }

    case INPUT_TYPES.NUMBER: {
      let numberValue = Number(action.payload.value);
      set(stateCopy, stateObjectPath, numberValue);
      let questionPath = stateObjectPath.replace('.userResponse', '');
      let questionIds = get(stateCopy, questionPath)
        .answers.map((answer) => answer.nextQuestionIds)
        .flat();
      while (questionIds.length > 0) {
        let currentId = questionIds.pop();
        let questionsPath = questionPath.replace(/\[\d+\]$/, '');
        let questionIndex = get(stateCopy, questionsPath).findIndex(
          (question) => question.questionId === currentId,
        );

        set(stateCopy, `${questionsPath}[${questionIndex}].userResponse`, null);
      }
      return stateCopy;
    }

    case ERROR_TYPES.RESET:
      return { ...state, formDataErrors: INITIAL_REO_FORM_ERRORS };

    case ERROR_TYPES.UPDATE: {
      stateCopy.formDataErrors = cloneDeep(INITIAL_REO_FORM_ERRORS);
      action.payload.errors?.forEach((error) => {
        let errorSplit = error.split(' ');
        set(
          stateCopy.formDataErrors,
          errorSplit[0],
          errorSplit.slice(1).join(' '),
        );
      });

      return { ...state, formDataErrors: stateCopy.formDataErrors };
    }

    case ERROR_TYPES.VALIDATION: {
      let validationError = { ...state.validationError };
      if (Array.isArray(action.payload)) {
        action.payload.forEach(
          (payload) => (validationError[payload.id] = payload.value),
        );
      } else {
        validationError[action.payload.id] = action.payload.value;
      }

      return {
        ...state,
        validationError: validationError,
      };
    }

    case ERROR_TYPES.VALIDATION_RESET: {
      const validationErrorCopy = { ...state.validationError };
      if (Array.isArray(action.payload)) {
        action.payload.forEach(
          (payload) => (validationErrorCopy[payload] = undefined),
        );
      } else {
        delete validationErrorCopy[action.payload.id];
      }
      return {
        ...state,
        validationError: validationErrorCopy,
      };
    }

    default:
      return state;
  }
};

export default REOReducer;
