import { AccordionButton as ChakraAccordionButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const AccordionButton = ({ children, ...props }) => {
  return <ChakraAccordionButton {...props}>{children}</ChakraAccordionButton>;
};

export default AccordionButton;

AccordionButton.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
