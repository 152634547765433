export default class Answer {
  questionAnswerId;
  answerText;
  resolutionMessages = [];

  constructor(a = {}) {
    this.questionAnswerId = Object.prototype.hasOwnProperty.call(
      a,
      'questionAnswerId',
    )
      ? a.questionAnswerId
      : 0;
    this.answerText = Object.prototype.hasOwnProperty.call(a, 'answerText')
      ? a.answerText
      : '';
    this.resolutionMessages = Object.hasOwnProperty.call(
      a,
      'resolutionMessages',
    )
      ? a.resolutionMessages
      : [];
  }

  get alertText() {
    return this.resolutionMessages[0].alertText;
  }
}
