import { Children } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from 'common/components';

const AccordionWrapper = ({ children, allowMultiple, hide }) => {
  if (hide) {
    return (
      <Box
        m="1rem"
        py="1rem"
        pl="24px"
        border="1px solid"
        borderColor="gray.200"
      >
        {Children.map(children, (child) => {
          const [title, body] = Children.toArray(child.props.children);
          return (
            <div key={child.key}>
              <Text component="h4" fontSize="1rem" textDecoration="underline">
                {title}
              </Text>
              {body}
            </div>
          );
        })}
      </Box>
    );
  }

  return (
    <Accordion allowMultiple={allowMultiple}>
      {Children.map(children, (child) => {
        const [title, body] = Children.toArray(child.props.children);
        return (
          <AccordionItem
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.200"
            borderRadius="4px"
            mb="16px"
            key={child.key}
          >
            <Text as="h4" backgroundColor="gray.50">
              <AccordionButton>
                {title}
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4} pr={0} borderColor="gray.200">
              {body}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

AccordionWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  allowMultiple: PropTypes.bool,
  hide: PropTypes.bool,
};

AccordionWrapper.defaultProps = {
  allowMultiple: false,
};

export default AccordionWrapper;
