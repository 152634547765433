export const FIRST_TIME_HOME_BUYER_API_STATUS = {
  GETTING: 'GETTING',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_ERROR: 'GET_ERROR',
  UPDATING: 'UPDATING',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATE_ERROR',
};

export const INITIAL_FIRST_TIME_HOME_BUYER_INFO = {
  citizenshipData: [],
};

export const FIRST_TIME_HOME_BUYER = 'FirstTimeHomeBuyer';
