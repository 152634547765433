import PropTypes from 'prop-types';
import { Table, Tbody, Thead, Tr, Td } from 'common/components/Tables';

import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import styled from '@emotion/styled';

import { formatCurrency } from 'common/util/format';
import TitleCaseUi from 'common/components/TitleCaseUi';
import render from 'common/util/dropin';
import ConsideredFormulas from '../styles/ConsideredFormulas';

const TitleTd = TitleCaseUi(Td);
const TitleTh = TitleCaseUi(styled.th`
  text-align: left;
  padding: 0.5rem 1rem;
  font-weight: 600;
`);
const DlStyled = styled.dl`
  margin-top: 25px;
  display: flex;
  align-items: flex-end;
`;
const DtStyled = styled.dt`
  float: left;
  clear: left;
  font-weight: 500;
  margin-right: 4px;
  &:after {
    content: ':';
  }
`;

function FormulaDisplay({ calculationEquation, consideredFormulas }) {
  if (consideredFormulas?.length) {
    return (
      <Box fontSize="xs" mt={4}>
        <Text fontWeight="bold" mb={1}>
          Calculations:
        </Text>
        <ConsideredFormulas consideredFormulas={consideredFormulas} />
      </Box>
    );
  }

  return (
    <Box as={DlStyled} fontSize="xs">
      <Text as={DtStyled} fontSize="xs">
        Calculation
      </Text>
      <Text as="dd" fontSize="xs">
        {calculationEquation}
      </Text>
    </Box>
  );
}
FormulaDisplay.propTypes = {
  calculationEquation: PropTypes.string.isRequired,
  consideredFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      formula: PropTypes.string,
      formulaType: PropTypes.string,
      incomeValue: PropTypes.number,
      isFormulaUsed: PropTypes.bool,
    }),
  ),
};

const WvoeSection = ({
  calculationEquation,
  incomeDetails,
  consideredFormulas,
}) => {
  const columnHeaders = [
    `{{$fiscalYear}} (YTD)`,
    `{{$fiscalYear}}`,
    `{{$fiscalYear}}`,
  ];
  const rowHeaders = { monthlyIncome: 'Monthly', totalIncome: 'total' };
  return (
    <>
      <Table size="sm" width="auto">
        <Thead>
          <Tr>
            <td></td>
            {columnHeaders.map((header, idx) => {
              // eslint-disable-next-line testing-library/render-result-naming-convention
              const h = render(header, undefined, {
                fiscalYear:
                  idx in incomeDetails ? incomeDetails[idx].fiscalYear : '####',
              });
              return (
                <TitleTh scope="col" key={h}>
                  {h}
                </TitleTh>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(rowHeaders).map((field) => (
            <Tr key={field}>
              <TitleTd scope="row" textAlign="right">
                {rowHeaders[field]}
              </TitleTd>
              {incomeDetails.map((detail) => (
                <Td key={`${field}-${detail.incomeYear}`} pr="24px">
                  {formatCurrency(detail[field])}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <FormulaDisplay
        calculationEquation={calculationEquation}
        consideredFormulas={consideredFormulas}
      />
    </>
  );
};

WvoeSection.propTypes = {
  calculationEquation: PropTypes.string.isRequired,
  incomeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      incomeYear: PropTypes.oneOf(['Ytd', 'PrevYear', 'TwoYearsPrev']),
      monthlyIncome: PropTypes.number,
      totalIncome: PropTypes.number,
      fiscalYear: PropTypes.number,
    }),
  ).isRequired,
  consideredFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      formula: PropTypes.string,
      formulaType: PropTypes.string,
      incomeValue: PropTypes.number,
      isFormulaUsed: PropTypes.bool,
    }),
  ),
};

WvoeSection.defaultProps = {
  isOverride: false,
  isOverrideError: false,
  overrideVal: '0.00',
};

export default WvoeSection;
