﻿class RentalAddressIdentifierDataModel {
  constructor(id = {}) {
    this.addressLdId = Object.hasOwnProperty.call(id, 'addressLdId')
      ? id.addressLdId
      : 0;

    this.addressId = Object.hasOwnProperty.call(id, 'addressId')
      ? id.addressId
      : 0;
  }

  getAddressLdid() {
    return this.addressLdId;
  }

  getAddressId() {
    return this.addressId;
  }
}

export default RentalAddressIdentifierDataModel;
