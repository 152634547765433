import TYPES from './types';
const getApprovalDataStarted = () => ({
  type: TYPES.GET_APPROVAL_DATA_STARTED,
});
const getApprovalDataSuccess = (approvalData) => ({
  type: TYPES.GET_APPROVAL_DATA_SUCCESS,
  approvalData,
});
const getApprovalDataError = () => ({
  type: TYPES.GET_APPROVAL_DATA_ERROR,
  error: 'Unable to get Approval data',
});
export default {
  getApprovalDataStarted,
  getApprovalDataSuccess,
  getApprovalDataError,
};
