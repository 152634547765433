import {
  CONFIDENCE_LEVEL,
  CONFIDENCE_LEVEL_ICON_COLORS,
  CONFIDENCE_LEVEL_THRESHOLD,
  allConfidenceColors,
} from '../QuestionBox/constants';
import { TITLE_ICON_TYPE } from '../QuestionBox/constants';
import {
  faBolt,
  faUser,
  faGauge,
  faGaugeLow,
  faGaugeHigh,
} from '@fortawesome/pro-solid-svg-icons';
import newIcon from 'common/assets/New Icon.svg';
import aiIcon from 'common/util/icons/AIOn.svg';

export const handleTitleConfidenceLevelColor = (confidenceLevel) => {
  switch (+confidenceLevel) {
    case CONFIDENCE_LEVEL.GREEN:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
    case CONFIDENCE_LEVEL.RED:
      return CONFIDENCE_LEVEL_ICON_COLORS.RED;
    case CONFIDENCE_LEVEL.YELLOW:
      return CONFIDENCE_LEVEL_ICON_COLORS.YELLOW;
    default:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
  }
};

export function handleAssetsConfidenceLevelColor(confidencePoint) {
  const isGreaterThanOrEqualToHighConfidence =
    confidencePoint >= CONFIDENCE_LEVEL_THRESHOLD.HIGH_CONFIDENCE_THRESHOLD;
  const isLessThanHighConfidence =
    confidencePoint < CONFIDENCE_LEVEL_THRESHOLD.HIGH_CONFIDENCE_THRESHOLD;
  const isGreaterThanOrEqualToMediumConfidence =
    confidencePoint >= CONFIDENCE_LEVEL_THRESHOLD.MEDIUM_CONFIDENCE_THRESHOLD;
  const isLessThanMediumConfidence =
    confidencePoint < CONFIDENCE_LEVEL_THRESHOLD.MEDIUM_CONFIDENCE_THRESHOLD;

  switch (true) {
    case isGreaterThanOrEqualToHighConfidence:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
    case isLessThanHighConfidence && isGreaterThanOrEqualToMediumConfidence:
      return CONFIDENCE_LEVEL_ICON_COLORS.YELLOW;
    case isLessThanMediumConfidence:
      return CONFIDENCE_LEVEL_ICON_COLORS.RED;
    default:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
  }
}

export const getExtractionDataIcon = (iconType, color) => {
  const highConfidenceThreshold =
    iconType >= CONFIDENCE_LEVEL_THRESHOLD.HIGH_CONFIDENCE_THRESHOLD;
  const mediumConfidenceThreshold =
    iconType < CONFIDENCE_LEVEL_THRESHOLD.HIGH_CONFIDENCE_THRESHOLD ||
    iconType >= CONFIDENCE_LEVEL_THRESHOLD.MEDIUM_CONFIDENCE_THRESHOLD;
  const lowConfidenceThreshold =
    iconType < CONFIDENCE_LEVEL_THRESHOLD.MEDIUM_CONFIDENCE_THRESHOLD;

  const parsedIconType = parseInt(iconType);
  const isHighConfidence =
    (parsedIconType === TITLE_ICON_TYPE.GAUGE || highConfidenceThreshold) &&
    color === CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
  const isMediumConfidence =
    (parsedIconType === TITLE_ICON_TYPE.GAUGE || mediumConfidenceThreshold) &&
    color === CONFIDENCE_LEVEL_ICON_COLORS.YELLOW;
  const isLowConfidence =
    (parsedIconType === TITLE_ICON_TYPE.GAUGE &&
      (color === CONFIDENCE_LEVEL_ICON_COLORS.RED ||
        !color ||
        !allConfidenceColors.includes(color))) ||
    (lowConfidenceThreshold && color === CONFIDENCE_LEVEL_ICON_COLORS.RED);

  const isBOLTIcon = parsedIconType === TITLE_ICON_TYPE.BOLT;
  const isUserIcon = parsedIconType === TITLE_ICON_TYPE.USER;
  const isNewIcon = parsedIconType === TITLE_ICON_TYPE.NEW;
  const isAutomationIcon = parsedIconType === TITLE_ICON_TYPE.AUTOMATION;

  switch (true) {
    case isBOLTIcon:
      return faBolt;
    case isUserIcon:
      return faUser;
    case isNewIcon:
      return newIcon;
    case isHighConfidence:
      return faGaugeHigh;
    case isMediumConfidence:
      return faGauge;
    case isLowConfidence:
      return faGaugeLow;
    case isAutomationIcon:
      return aiIcon;
    default:
      return null;
  }
};
