const htmlFontSize = 16;

export const pixelsToRem = (pixels) => {
  return `${pixels / htmlFontSize}rem`;
};

export const typography = {
  fonts: {
    heading:
      '"Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    body: '"Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  h1: {
    fontWeight: 500,
    fontSize: pixelsToRem(32),
    lineHeight: pixelsToRem(40),
    letterSpacing: '-0.01em',
  },
  h2: {
    fontWeight: 500,
    fontSize: pixelsToRem(24),
    lineHeight: pixelsToRem(32),
    letterSpacing: '-0.02em',
  },
  h3: {
    fontWeight: 500,
    fontSize: pixelsToRem(16),
    lineHeight: pixelsToRem(24),
    letterSpacing: '-0.02em',
  },
  h4: {
    fontWeight: 500,
    fontSize: pixelsToRem(14),
    lineHeight: pixelsToRem(24),
    letterSpacing: '-0.02em',
  },
  h5: {
    fontWeight: 500,
    fontSize: pixelsToRem(12),
    lineHeight: pixelsToRem(16),
    letterSpacing: '-0.02em',
  },
  h6: {
    fontWeight: 500,
    fontSize: pixelsToRem(10),
    lineHeight: pixelsToRem(14),
    letterSpacing: '-0.01em',
  },
};

export default typography;
