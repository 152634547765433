import IncomeSummaryDataModel from './IncomeSummaryDataModel';

export default class PreviousIncomeSummaryDataModel extends IncomeSummaryDataModel {
  constructor(data = {}) {
    super(data);
    this.isDocumentationProvided = Object.hasOwnProperty.call(
      data,
      'isDocumentationProvided',
    )
      ? data.isDocumentationProvided
      : null;
  }

  getIsDocumentationProvided() {
    return this.isDocumentationProvided;
  }
}
