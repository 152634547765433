import { Center as ChakraCenter } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Center = ({ children, ...props }) => {
  return <ChakraCenter {...props}>{children}</ChakraCenter>;
};

Center.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};

export default Center;
