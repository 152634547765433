﻿import PropTypes from 'prop-types';
import { Box, SimpleGrid, Text } from 'common/components';
import { formatCurrency } from 'common/util/format';
import BreakdownContainer from '../BreakdownContainer';
import BreakdownValue from '../styles/BreakdownValue';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormTypes } from 'pages/IncomeCalculator/enums';

const formType = FormTypes.ScheduleC;
const CalculatedMileage = ({ year, selectedResult }) => {
  const milesDriven = selectedResult
    .getInput(formType, year, 'milesDrivenForBusiness')
    ?.getValue();

  const taxMileageRate = parseFloat(
    selectedResult.getInput(formType, year, 'taxMileageRate')?.getValue(),
  );

  return (
    <Box pb={4}>
      <Text fontWeight="bold">Calculated Vehicle Miles ({year})</Text>
      <Text fontSize="sm">Vehicle Miles * Tax Mileage Rate</Text>
      <BreakdownValue name="Vehicle Miles:" value={milesDriven} />
      <BreakdownValue
        name="Tax Mileage Rate:"
        value={`${taxMileageRate * 100}%`}
      />
      <Text fontSize="sm">
        {milesDriven} * {taxMileageRate}
      </Text>
    </Box>
  );
};
CalculatedMileage.propTypes = {
  year: PropTypes.number.isRequired,
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};

const AnnualIncome = ({ year, selectedResult }) => {
  return (
    <Box>
      <Text fontWeight="bold">Annual Income ({year})</Text>

      <BreakdownValue
        name="Net Profit/Loss"
        value={selectedResult.getInput(formType, year, 'netProfit')?.getValue()}
        testId={`net-profit-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="+ Depreciation"
        value={selectedResult
          .getInput(formType, year, 'depreciation')
          ?.getValue()}
        testId={`depreciation-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="+ Depletion"
        value={selectedResult.getInput(formType, year, 'depletion')?.getValue()}
        testId={`depletion-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="+ Business Use of Home"
        value={selectedResult.getInput(formType, year, 'useOfHome')?.getValue()}
        testId={`use-home-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="+ Business Use of Home (Form 8829)"
        value={selectedResult
          .getInput(formType, year, 'calculatedBusinessUseOfHome')
          ?.getValue()}
        testId={`use-home-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="+ Calculated Vehicle Miles"
        value={selectedResult
          .getInput(formType, year, 'calculatedVehicleMiles')
          ?.getValue()}
        testId={`calc-miles-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="- Meals and Entertainment"
        value={selectedResult
          .getInput(formType, year, 'mealsAndEntertainment')
          ?.getValue()}
        testId={`meals-${year}`}
        isCurrency={true}
      />
      <BreakdownValue
        name="- Non-Recurring Other Income"
        value={selectedResult
          .getInput(formType, year, 'otherIncome')
          ?.getValue()}
        testId={`other-income-${year}`}
        isCurrency={true}
      />
    </Box>
  );
};
AnnualIncome.propTypes = {
  year: PropTypes.number.isRequired,
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};

const ScheduleCCalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const years = selectedResult.getFiscalYears().sort((a, b) => b - a);

  const OneYearBreakdown = () => {
    const year = years[0];
    return (
      <>
        <SimpleGrid
          w={`${50 * Math.min(years.length, 2)}%`}
          columns={Math.min(2, years.length)}
          spacingX={0}
          spacingY={8}
          pb={4}
        >
          <BreakdownYearContainer title={year}>
            <CalculatedMileage year={year} selectedResult={selectedResult} />

            <AnnualIncome year={year} selectedResult={selectedResult} />
          </BreakdownYearContainer>
        </SimpleGrid>
        <Text fontWeight="bold">Calculated Monthly Income/Loss:</Text>
        <Text fontSize="sm" pb={2} borderBottom="1px solid #005F9E">
          {formatCurrency(
            selectedResult.getInput(formType, year, 'annualIncome')?.getValue(),
          )}{' '}
          / 12
        </Text>

        <BreakdownValue
          name="Net Income"
          value={selectedResult.getAmount()}
          isCurrency={true}
          pt={2}
          fontWeight="bold"
        />
      </>
    );
  };

  const TwoYearBreakdown = () => {
    const currentYear = years[0];
    const priorYear = years[1];

    const currentAnnualIncome = parseFloat(
      selectedResult
        .getInput(formType, currentYear, 'annualIncome')
        ?.getValue(),
    );

    const priorAnnualIncome = parseFloat(
      selectedResult.getInput(formType, priorYear, 'annualIncome')?.getValue(),
    );

    return (
      <>
        <SimpleGrid
          w={`${50 * Math.min(years.length, 2)}%`}
          columns={Math.min(2, years.length)}
          spacing={8}
          pb={4}
        >
          <BreakdownYearContainer title={currentYear}>
            <CalculatedMileage
              year={currentYear}
              selectedResult={selectedResult}
            />
            {
              <AnnualIncome
                year={currentYear}
                selectedResult={selectedResult}
              />
            }
          </BreakdownYearContainer>
          <BreakdownYearContainer title={priorYear}>
            <CalculatedMileage
              year={priorYear}
              selectedResult={selectedResult}
            />

            {<AnnualIncome year={priorYear} selectedResult={selectedResult} />}
          </BreakdownYearContainer>
        </SimpleGrid>
        <Text fontWeight="bold">Calculated Monthly Income/Loss:</Text>
        {currentAnnualIncome > priorAnnualIncome ? (
          <Text fontSize="sm" pb={2} borderBottom="1px solid #005F9E">
            {formatCurrency(currentAnnualIncome)}
            {' + '}
            {formatCurrency(priorAnnualIncome)}
            {' / 24 '}
          </Text>
        ) : (
          <Text fontSize="sm" pb={2} borderBottom="1px solid #005F9E">
            {formatCurrency(currentAnnualIncome)} / 12
          </Text>
        )}

        <BreakdownValue
          name="Net Income"
          value={selectedResult.getAmount()}
          isCurrency={true}
          pt={2}
          fontWeight="bold"
        />
      </>
    );
  };

  return (
    <BreakdownContainer
      header="Schedule C Breakdown"
      onNewWindow={onNewWindow}
      hideAccordion={hideAccordion}
    >
      {years.length === 1 && <OneYearBreakdown />}
      {years.length >= 2 && <TwoYearBreakdown />}
    </BreakdownContainer>
  );
};
ScheduleCCalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};

export default ScheduleCCalculationBreakdown;
