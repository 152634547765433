import PropTypes from 'prop-types';
import { List, ListItem, Text } from 'common/components';
import BreakdownContainer from '../../BreakdownContainer';
import BreakdownColumnBox from '../../styles/BreakdownColumnBox';
import SingleLineValue from '../../styles/SingleLineValue';
import { pixelsToRem } from 'theme/typography';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormulaType, CalculationTypes } from 'pages/IncomeCalculator/enums';
import lang from '../../language.json';

function getTemplateFormula(formulas, calculatorType) {
  const formula = formulas.find((f) =>
    [
      FormulaType.SSIAwardLetterMonthlyIncomeLineThree,
      FormulaType.SSIAwardLetterMonthlyIncomeLineThreeFHA,
      FormulaType.SSI1099MonthlyIncomeLine3Conv,
      FormulaType.SSI1099MonthlyIncomeLine3FHA,
      FormulaType.SSI1040MonthlyIncome,
      FormulaType.SSI1040MonthlyIncomeFHA,
    ].includes(f.formulaId),
  );

  if (calculatorType === CalculationTypes.SocialSecurityBankStatement) {
    return lang.FORMULAS[`${formula?.formulaId}_CR`];
  }

  return lang.FORMULAS[formula?.formulaId];
}

const SSACalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const formula = getTemplateFormula(
    selectedResult.getFormulas(),
    selectedResult.getCalculatorType(),
  );

  return (
    <div>
      <BreakdownContainer
        header={lang.HEADERS[selectedResult.getCalculatorType()]}
        onNewWindow={onNewWindow}
        hideAccordion={hideAccordion}
      >
        {formula && (
          <SingleLineValue title={lang.MONTHLY_CALC_HEADER} value={formula} />
        )}
        <BreakdownColumnBox title={lang.CALCULATION} mb={4}>
          <List fontSize={pixelsToRem(14)}>
            {selectedResult.getFormulas().map(({ formulaId, formula }) => (
              <Text as={ListItem} key={formulaId}>
                {!formula.trim() && <br />}
                {formula}
              </Text>
            ))}
          </List>
        </BreakdownColumnBox>
        <SingleLineValue
          title={lang.MONTHLY_INCOME}
          value={selectedResult.getCalculationAmount()}
          isCurrency
        />
      </BreakdownContainer>
    </div>
  );
};
SSACalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  hideAccordion: PropTypes.bool,
  onNewWindow: PropTypes.func,
};

export default SSACalculationBreakdown;
