import { useRef } from 'react';
import Button from 'common/components/Button';
import Text from 'common/components/Texts/Text';
import { isReadOnlyUrl, useIsUnderwriter } from 'common/util/appState';

function useReadOnlyWindow() {
  const windowRef = useRef(null);
  const path = window.location.pathname.includes('/true')
    ? window.location.pathname.replace('/true', '')
    : window.location.pathname;
  const basePath = `${path}/summary/liabilities/readOnly?`;

  const openPopUpWindow = () => {
    const shouldOpenNewWindow = !windowRef.current || windowRef.current.closed;
    const windowFeatures = 'popup,width=1000,height=800';

    if (shouldOpenNewWindow) {
      windowRef.current = window.open(basePath, '_blank', windowFeatures);
    } else {
      windowRef.current.focus();
    }
  };

  return openPopUpWindow;
}

function Form1003PopUpButton({ style }) {
  const isUnderwriter = useIsUnderwriter();
  const openPopUpWindow = useReadOnlyWindow();

  if (!isUnderwriter || isReadOnlyUrl()) return null;

  return (
    <Button
      onClick={openPopUpWindow}
      isPrimary={false}
      data-testid="form-1003-button"
      style={style}
    >
      <Text as="span" color="gray.900">
        Form 1003
      </Text>
    </Button>
  );
}

export { Form1003PopUpButton };
