import PropTypes from 'prop-types';

const citation = PropTypes.shape({
  pageNumber: PropTypes.number,
  documentType: PropTypes.string,
  x1: PropTypes.number.isRequired,
  x2: PropTypes.number.isRequired,
  x3: PropTypes.number.isRequired,
  x4: PropTypes.number.isRequired,
  y1: PropTypes.number.isRequired,
  y2: PropTypes.number.isRequired,
  y3: PropTypes.number.isRequired,
  y4: PropTypes.number.isRequired,
});

export default citation;
