// 3rd party imports
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import Spinner from './common/components/GlobalLayout/Spinner';
import Box from './common/components/Box';
import Center from 'common/components/Center';
import { BOLT_IMPERSONATE_COOKIE } from 'common/constants';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// local imports
import boltRoutes from 'routes';
import BlockedPage from 'common/components/BlockedPage';
import { HomeActions, HomeThunks } from 'pages/Home/redux';
import { setLoan } from 'pages/IncomeCalculator/redux/actions';
import useAuthentication from 'common/hooks/useAuthentication';
import { USER_ROLE } from 'common/constants';
import Header from 'pages/Home/Header';
import Footer from 'pages/Home/Footer';
import { isReadOnlyUrl } from 'common/util/appState';
import { LeftDrawerContextProvider } from 'common/components/Nav/LeftDrawer/context/LeftDrawerContextProvider';
import useWorkTracking from 'common/hooks/useWorkTracking';
import { getFeatureFlags } from 'common/redux/thunks';

const BOLT = `
    ____        ____ 
   / __ )____  / / /_
  / __  / __ \\/ / __/
 / /_/ / /_/ / / /_  
/_____/\\____/_/\\__/                 
`;
// TODO: replace with Figlet https://github.com/patorjk/figlet.js/tree/main
// eslint-disable-next-line no-console
console.log(BOLT);

const App = () => {
  useWorkTracking();
  const dispatch = useDispatch();
  const [isAuthorized, claims, isLoading, redirect] = useAuthentication();
  const { isAuditHistoryEnabled } = useSelector(
    ({ featureFlags }) => featureFlags,
  );

  const {
    user: { isUnderwriter },
  } = useSelector(({ home }) => home);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );

  const routes = useMemo(() => {
    let filteredRoutes = boltRoutes;
    if (!isAuditHistoryEnabled) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.isAuditHistoryEnabled,
      );
    }
    if (!isUnderwriter) {
      return filteredRoutes.filter(
        ({ isUnderwriterOnly }) => !isUnderwriterOnly,
      );
    }
    return filteredRoutes;
  }, [isUnderwriter, isAuditHistoryEnabled]);

  useEffect(() => {
    if (!isEmpty(redirect)) {
      // HACK: This causes the entire application to reload, ideally we should use the useHistory hook.
      window.location = redirect || '/';
    }
  }, [redirect]);

  // get bolt status & setLoan to be used in income calculator
  useEffect(() => {
    if (
      obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId &&
      obfuscatedLoanIdentifier.obfuscatedLoanRecordId
    ) {
      dispatch(HomeThunks.getBoltStatus());
    }
    dispatch(
      setLoan(
        obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
        obfuscatedLoanIdentifier.obfuscatedLoanRecordId,
      ),
    );
  }, [dispatch, obfuscatedLoanIdentifier]);

  useEffect(() => {
    if (isEmpty(claims)) {
      return;
    }

    const { role, email, name } = claims;
    const isImpersonating = !!Cookies.get(
      BOLT_IMPERSONATE_COOKIE(obfuscatedLoanIdentifier.obfuscatedLoanRecordId),
    );

    if (role && window.dataTracking) {
      window.dataTracking.role = role;
      window.dataTracking.email = email;
      window.dataTracking.name = name;
    }

    dispatch(
      HomeActions.setUserDetails({
        role,
        email,
        isImpersonating,
      }),
    );
    let isUnderwriter = false;
    if (isImpersonating) {
      isUnderwriter = true;
    } else if (Array.isArray(role)) {
      isUnderwriter = role.includes(USER_ROLE.UNDERWRITER);
    } else if (typeof role === 'string') {
      isUnderwriter = role === USER_ROLE.UNDERWRITER;
    }
    dispatch(HomeActions.setIsUnderwriter(isUnderwriter));
    dispatch(getFeatureFlags(isUnderwriter));
  }, [claims, dispatch, obfuscatedLoanIdentifier.obfuscatedLoanRecordId]);

  const userRole = claims?.role;

  if (isLoading || isAuthorized === null) {
    return (
      <Center width="100%" height="100%">
        <Spinner
          size="xl"
          thickness="4px"
          color="orange.500"
          emptyColor="gray.200"
        />
      </Center>
    );
  }

  /*
   * isAuthorized is null on app load and
   * will change to false upon token expiration
   */
  if (!isAuthorized) {
    return <BlockedPage />;
  }

  return (
    <>
      {!isReadOnlyUrl() && <Header />}

      {isAuthorized && (
        <LeftDrawerContextProvider>
          <Router>
            <Box display="none" data-user-role={userRole} />
            <Switch>
              {routes.map((route, index) => {
                return <Route {...route} key={index} />;
              })}
            </Switch>
          </Router>
        </LeftDrawerContextProvider>
      )}

      {!isReadOnlyUrl() && <Footer />}
    </>
  );
};

export default App;
