import PropTypes from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Stack from 'common/components/Stacks/Stack';
import Text from 'common/componentMapper/Text';
import { Icon } from '../Icon';
import { useFormApi } from 'lib-node-amb-renderer';
import {
  StyledInnerContainerBox,
  StyledFormContainerBox,
  StyledLink,
  StyledText,
  StyledFooter,
  StyledIconBox,
  StyledAnswerBox,
  StyledComponentBox,
} from './Styled';
import { TITLE_ICON_TYPE } from './constants';
import Button from 'common/components/Button';
import { CONFIDENCE_LEVEL, CONFIDENCE_LEVEL_ICON_COLORS } from './constants';

// Confidence helper
const handleConfidenceLevelColorInd = (confidenceLevel) => {
  switch (+confidenceLevel) {
    case CONFIDENCE_LEVEL.GREEN:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
    case CONFIDENCE_LEVEL.RED:
      return CONFIDENCE_LEVEL_ICON_COLORS.RED;
    case CONFIDENCE_LEVEL.YELLOW:
      return CONFIDENCE_LEVEL_ICON_COLORS.YELLOW;
    default:
      return CONFIDENCE_LEVEL_ICON_COLORS.GREEN;
  }
};

const QuestionBoxIcon = ({ iconType }) => {
  return (
    <StyledIconBox width="4%" paddingTop="0">
      <Icon
        sx={{
          display: 'grid',
          placeItems: 'center',
          backgroundColor: 'blue.600',
          color: 'white',
          borderRadius: '.3rem',
          minWidth: '1.5rem',
          minHeight: '1.5rem',
        }}
        iconType={iconType}
      />
    </StyledIconBox>
  );
};

QuestionBoxIcon.propTypes = {
  iconType: PropTypes.string,
};

const QuestionBoxConfidenceFooter = ({ detailedAnswer, confidenceLevel }) => {
  const [{ overflow, whiteSpace, showMore }, setDetailedAnswerStyle] = useState(
    {
      overflow: false,
      whiteSpace: false,
      showMore: false,
    },
  );

  const hasEllipsis = detailedAnswer.split('').length > 50 ?? false;

  const handleDetailedAnswerStyles = () => {
    setDetailedAnswerStyle((prev) => {
      return {
        ...prev,
        overflow: !prev.overflow,
        whiteSpace: !prev.whiteSpace,
        showMore: !prev.showMore,
      };
    });
  };
  return (
    <StyledFooter>
      <StyledAnswerBox>
        <StyledIconBox paddingTop="0" paddingRight=".5rem" minWidth="5%">
          <Icon
            sx={{ fontSize: 'clamp(1.5rem, 1.5rem, 2rem)' }}
            iconType={TITLE_ICON_TYPE.GAUGE}
            color={handleConfidenceLevelColorInd(confidenceLevel)}
          />
        </StyledIconBox>
        <StyledText overflow={overflow} whiteSpace={whiteSpace}>
          {detailedAnswer}
        </StyledText>
        {hasEllipsis && (
          <StyledLink onClick={handleDetailedAnswerStyles} minWidth={'100px'}>
            {showMore ? 'Show Less' : 'Show More'}
          </StyledLink>
        )}
      </StyledAnswerBox>
    </StyledFooter>
  );
};

QuestionBoxConfidenceFooter.propTypes = {
  detailedAnswer: PropTypes.string,
  confidenceLevel: PropTypes.number,
};

const QuestionBoxAnswerFooter = ({
  answer,
  detailedAnswer,
  confidenceLevel,
}) => {
  const [showBOLTAnswer, setShowBOLTAnswer] = useState(false);
  const handleBOLTAnswerShowHide = () => {
    setShowBOLTAnswer((prev) => !prev);
  };
  return (
    <StyledFooter>
      <Stack direction="column" paddingTop="1rem">
        {showBOLTAnswer && (
          <Stack direction="row">
            <StyledIconBox paddingTop="0" paddingRight=".5rem" minWidth="4%">
              <Icon
                sx={{ fontSize: '1.5rem' }}
                iconType={TITLE_ICON_TYPE.GAUGE}
                color={handleConfidenceLevelColorInd(confidenceLevel)}
              />
            </StyledIconBox>
            <Stack direction="column" width="90%">
              <StyledText>BOLT Answer: {answer}</StyledText>
              <Text
                sx={{
                  fontStyle: 'italic',
                  color: 'gray.500',
                  padding: '0.3rem 0',
                }}
              >
                {detailedAnswer}
              </Text>
            </Stack>
          </Stack>
        )}
        <StyledLink
          minWidth={'30%'}
          textAlign="left"
          placeItems="left"
          onClick={handleBOLTAnswerShowHide}
        >
          {showBOLTAnswer ? 'Hide BOLT Answer' : 'Show BOLT Answer'}
        </StyledLink>
      </Stack>
    </StyledFooter>
  );
};

QuestionBoxAnswerFooter.propTypes = {
  answer: PropTypes.string,
  detailedAnswer: PropTypes.string,
  confidenceLevel: PropTypes.number,
};

export default function QuestionBoxSmartQuestion({
  input,
  detailedAnswer,

  answer,
  confidenceLevel,
  iconType,
  snippets,
  handlePDFOnFocus,
  displayFlags: {
    displayIcon,
    displayConfidenceFooter,
    displayBOLTAnswerFooter,
  },
}) {
  const { renderForm } = useFormApi();
  const hasSnippets = !!snippets.length;

  return (
    <StyledInnerContainerBox>
      <StyledFormContainerBox>
        <StyledComponentBox>
          <StyledFormContainerBox>{renderForm([input])}</StyledFormContainerBox>
        </StyledComponentBox>
        {displayIcon && <QuestionBoxIcon iconType={iconType} />}
      </StyledFormContainerBox>
      {displayConfidenceFooter && (
        <QuestionBoxConfidenceFooter
          confidenceLevel={confidenceLevel}
          detailedAnswer={detailedAnswer}
        />
      )}
      {displayBOLTAnswerFooter && (
        <QuestionBoxAnswerFooter
          answer={answer}
          detailedAnswer={detailedAnswer}
          confidenceLevel={confidenceLevel}
        />
      )}
      {hasSnippets && (
        <Button mt="1rem" isPrimary={false} onClick={() => handlePDFOnFocus()}>
          <FontAwesomeIcon icon={faCamera} />
          <Text ml=".5rem" as="span">
            Snapshot
          </Text>
        </Button>
      )}
    </StyledInnerContainerBox>
  );
}

QuestionBoxSmartQuestion.propTypes = {
  input: PropTypes.object,
  detailedAnswer: PropTypes.string,
  answer: PropTypes.string,
  confidenceLevel: PropTypes.number,
  renderForm: PropTypes.func,
  iconType: PropTypes.string,
  snippets: PropTypes.array,
  handlePDFOnFocus: PropTypes.func,
  displayFlags: PropTypes.shape({
    displayIcon: PropTypes.bool,
    displayConfidenceFooter: PropTypes.bool,
    displayBOLTAnswerFooter: PropTypes.bool,
  }),
};
