import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'common/components';
import BreakdownContainer from '../BreakdownContainer';
import SingleLineValue from '../styles/SingleLineValue';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import BreakdownValueWithChild from '../styles/BreakdownValueWithChild';
import BreakdownValue from '../styles/BreakdownValue';
import { formatCurrency } from 'common/util/format';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import { FormTypes, FormulaType } from 'pages/IncomeCalculator/enums';
import lang from '../language.json';
import calcLang from 'pages/IncomeCalculator/Calculators/language.json';

const inputForm = FormTypes.Form1040;
const fhaFormula = [
  FormulaType.InterestForm1040OneYearFHA,
  FormulaType.InterestForm1040YearOneFHA,
  FormulaType.InterestForm1040YearTwoFHA,
  FormulaType.DividendsInterestForm1040OneYearFHA,
  FormulaType.DividendsInterestForm1040YearOneFHA,
  FormulaType.DividendsInterestForm1040YearTwoFHA,
];
const fields = calcLang.FIELDS.DIV_INT;

const Hr = (props) => (
  <Box {...props} role="separator" borderBottom="1px solid black" h={1} />
);

function buildAnnualValue(
  formula,
  taxExemptInterest,
  taxableInterest,
  dividends,
) {
  let grossUp;

  if (fhaFormula.includes(formula.formulaId)) {
    grossUp = 1.15;
  } else {
    grossUp = 1.25;
  }

  const useInterest = formula.formulaName.includes('Interest');
  const useDividends = formula.formulaName.includes('Dividends');

  const total =
    (useInterest ? taxExemptInterest * grossUp + taxableInterest : 0) +
    (useDividends ? dividends : 0);

  const annualChildren = [];

  if (useInterest) {
    annualChildren.push(
      {
        name: `${fields.TAX_EXEMPT_INTEREST} * ` + grossUp.toString(),
        value: taxExemptInterest * grossUp,
      },
      {
        name: fields.TAXABLE_INTEREST,
        value: taxableInterest,
      },
    );
  }
  if (useDividends) {
    annualChildren.push({
      name: fields.ORDINARY_DIVIDENDS,
      value: dividends,
    });
  }
  annualChildren.reduce((acc, cur) => {
    if (cur.value <= 0) {
      return acc;
    }

    if (acc.length) {
      cur.sign = '+';
    }
    return [...acc, cur];
  }, []);
  return [annualChildren, total];
}

function MonthlyIncomeBreakdown({ selectedResult, annuals }) {
  const finalFormula = selectedResult
    .getFormulas()
    .find((formula) =>
      [FormulaType.OneYearMonthly, FormulaType.TwoYearMonthly].includes(
        formula.formulaId,
      ),
    )?.formulaId;
  const years = Object.keys(annuals)
    .sort((a, b) => b - a)
    .slice(0, finalFormula === FormulaType.OneYearMonthly ? 1 : 2);
  const total = Object.values(annuals).reduce((acc, value) => acc + value, 0);

  return (
    <Box mb={4}>
      <Text fontWeight="bold" fontSize={15} mb={2}>
        {lang.MONTHLY_CALC}
      </Text>
      <Box d="inline-block">
        {Object.values(years).map((year, i) => (
          <BreakdownValue
            value={annuals[year]}
            isChild={i > 0}
            isCurrency
            pr={4}
            key={year}
          />
        ))}
        <Hr my={1} />
      </Box>
      <Text fontSize="small">
        {years.length > 1 && <>{formatCurrency(total)} /</>} {years.length * 12}{' '}
        (months)
      </Text>
    </Box>
  );
}

export default function CalculationBreakdown({
  selectedResult,
  hideAccordion = false,
  onNewWindow,
}) {
  const fiscalYears = selectedResult.getFiscalYears().sort((a, b) => b - a);
  const formula = selectedResult.getFormulas()[0];
  const annuals = {};

  function OneYearBreakdown({ year }) {
    const dividends = parseFloat(
      selectedResult
        .getInput(inputForm, year, 'ordinaryDividend')
        ?.getValue() || 0,
    );
    const taxableInterest = parseFloat(
      selectedResult.getInput(inputForm, year, 'taxableInterest')?.getValue() ||
        0,
    );
    const taxExemptInterest = parseFloat(
      selectedResult
        .getInput(inputForm, year, 'taxExemptInterest')
        ?.getValue() || 0,
    );
    const [annualChildren, annualTotal] = buildAnnualValue(
      formula,
      taxExemptInterest,
      taxableInterest,
      dividends,
    );
    annuals[year] = annualTotal;

    return (
      <BreakdownYearContainer title={year} flexGrow={1}>
        <BreakdownValueWithChild
          name={lang.ANNUAL_INCOME}
          value={annualTotal}
          isCurrency
          childrenData={annualChildren}
          fontWeight="bold"
        />
      </BreakdownYearContainer>
    );
  }
  OneYearBreakdown.propTypes = {
    year: PropTypes.number.isRequired,
  };

  return (
    <BreakdownContainer
      header={lang.HEADERS[selectedResult.getCalculatorType()]}
      hideAccordion={hideAccordion}
      onNewWindow={onNewWindow}
    >
      <Flex justifyContent="space-between" gridGap={4} mb={4}>
        {fiscalYears.map((fy) => (
          <OneYearBreakdown year={fy} key={fy} />
        ))}
      </Flex>
      <MonthlyIncomeBreakdown
        selectedResult={selectedResult}
        annuals={annuals}
      />
      <SingleLineValue
        title={lang.MONTHLY_INCOME}
        value={selectedResult.getCalculationAmount()}
        isCurrency
      />
    </BreakdownContainer>
  );
}
CalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};
MonthlyIncomeBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  annuals: PropTypes.object.isRequired,
};
