import Flex from 'common/components/Flex';
import PropTypes from 'prop-types';

export default function Loan1003FormBuilderWrapper({
  children,
  customPadding,
}) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      padding={customPadding || '0 1.5rem'}
    >
      {children}
    </Flex>
  );
}

Loan1003FormBuilderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  customPadding: PropTypes.string,
};
