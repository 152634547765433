import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/Tooltip';
import { Tr, Td } from 'common/components/Tables';
import styled from '@emotion/styled';
import { truncate } from 'lodash';
import { formatDate } from 'common/util/format';

const StyledTd = styled(Td)`
  width: calc(400px / 3);
  padding: 7px 10px;
  text-align: ${(props) => (props?.isCentered ? 'left' : 'center')};
  font-size: 0.9rem;

  div {
    margin-bottom: 5px;
  }

  span {
    display: inline-block;
    max-width: calc(400px / 3);
    width: fit-content;
  }
`;

const AuditHistoryRow = ({
  answer,
  modifiedDateTime,
  userName,
  questionDetailDesc,
  liabilityAdded,
}) => {
  const {
    selectedAlert: { displayTitle },
  } = useSelector(({ home }) => home);
  const isCreditInquiries = displayTitle === 'Credit Inquiries';
  const date = formatDate(modifiedDateTime);
  const time = new Date(modifiedDateTime).toLocaleTimeString('en-US');
  const isMultiAnswer = Array.isArray(answer);

  let toolTipLabel = questionDetailDesc
    ? `${answer} - ${questionDetailDesc}`
    : answer;

  return (
    <Tr>
      <StyledTd isCentered={isMultiAnswer}>
        {isMultiAnswer ? (
          answer.map((answer) => <div key={answer}>{answer}</div>)
        ) : (
          <Tooltip
            label={toolTipLabel}
            bg="bgGray.200"
            borderRadius="2px"
            fontSize=".9rem"
            color="blue.900"
          >
            {truncate(answer, { length: 10 })}
          </Tooltip>
        )}
      </StyledTd>
      {isCreditInquiries && (
        <StyledTd isCentered={true}>{liabilityAdded}</StyledTd>
      )}
      <StyledTd>
        <span>{date}</span>
        <span>{time}</span>
      </StyledTd>
      <StyledTd cursor="default">
        <Tooltip
          label={userName}
          bg="bgGray.200"
          borderRadius="2px"
          fontSize=".9rem"
          color="blue.900"
          shouldWrapChildren
        >
          {truncate(userName, { length: 15 })}
        </Tooltip>
      </StyledTd>
    </Tr>
  );
};
AuditHistoryRow.propTypes = {
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  modifiedDateTime: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  questionDetailDesc: PropTypes.string,
  liabilityAdded: PropTypes.string,
};
export default AuditHistoryRow;
