import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const NavWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 94%;
  }
`;

const NavItem = styled.span`
  cursor: pointer;
  margin: 0 0.75rem 0 1rem;
  padding: 10px 20px;
  display: list-item;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${props.theme.colors.blue[600]}` : 'none'};
`;

const NavLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
`;

export { StyledSection, NavItem, NavWrapper, NavLink };
