import ChakraAlert from 'common/components/Alerts/Alert/Alert';
import AlertDescription from 'common/components/Alerts/AlertDescription';
import AlertTitle from 'common/components/Alerts/AlertTitle';
import Box from 'common/components/Box';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NamedWidget, useFormApi } from 'lib-node-amb-renderer';
import { useState, useEffect } from 'react';
import { formatCurrency } from 'common/util/format';

export class AssetsAmountMismatchAlertClass extends NamedWidget {
  constructor(props) {
    super(props);
  }
}
//shows an alert if the two gift amount inputs do not match
const AssetsAmountMismatchAlert = ({ name, sx, message }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { getState } = useFormApi();
  const { values } = getState();
  const docId = `1-${values?.documentId}`;
  const {
    statementDetails: {
      [docId]: {
        ['giftAmountInput']: giftAmountInput,
        ['giftAmountTransfer']: giftAmountTransfer,
      },
    },
  } = values;

  const formattedGiftAmountInput = formatCurrency(giftAmountInput);
  const formattedGiftAmountTransfer = formatCurrency(giftAmountTransfer);

  //show alert if the two gift amount inputs do not match
  useEffect(() => {
    if (
      formattedGiftAmountInput === formattedGiftAmountTransfer ||
      giftAmountInput === null ||
      formattedGiftAmountTransfer === `$NaN`
    ) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  }, [formattedGiftAmountInput, formattedGiftAmountTransfer, giftAmountInput]);

  //set alert message text based on message prop
  switch (message) {
    case 'borrowerTransferMessage':
      message = `Gift amount from the gift letter does not match the amount received by the borrower. Provide updated gift letter in the amount of ${formattedGiftAmountTransfer} that matches the amount transferred to the borrower.`;
      break;
    case 'titleTransferMessage':
      message = `Gift amount from the gift letter does not match the amount received by title. Provide updated gift letter in the amount of ${formattedGiftAmountTransfer} that matches the amount transferred to title.`;
      break;
    default:
      message = `Provide updated gift letter in the amount of ${formattedGiftAmountTransfer} that matches the amount being transferred.`;
      break;
  }
  let alertColor, alertIcon, alertBackground, status, title;
  alertColor = 'orange.600';
  alertBackground = 'orange.100';
  alertIcon = faExclamationTriangle;
  title = 'New Condition';

  return (
    <>
      {showAlert && (
        <ChakraAlert
          name={name}
          border="1px solid"
          borderColor={alertColor}
          background={alertBackground}
          borderRadius="4px"
          status={status}
          w="100%"
          sx={sx}
          mt="1rem"
        >
          <Box color={`${alertColor}`} mr={2}>
            <FontAwesomeIcon icon={alertIcon} />
          </Box>

          <AlertTitle
            mr={4}
            fontSize="sm"
            color={alertColor}
            whiteSpace="nowrap"
          >
            {title}
          </AlertTitle>

          <AlertDescription fontSize="sm" lineHeight="24px">
            {message}
          </AlertDescription>
        </ChakraAlert>
      )}
    </>
  );
};

AssetsAmountMismatchAlert.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
  message: PropTypes.string.isRequired,
};

export default AssetsAmountMismatchAlert;
