import { useEffect, useCallback } from 'react';
import { FIXED_HEADER_FOR_AUTO_SCROLL } from 'pages/Home/constants';

function getErrorMessages() {
  return document.querySelectorAll('.chakra-form__error-message, .Mui-error');
}

export function scrollToFirstErrorMessage() {
  const elements = getErrorMessages();

  if (elements && elements.length > 0) {
    const firstElement = elements[0];
    // Add scroll margin to the top of the first element to account for the fixed header
    firstElement.style.scrollMarginTop = FIXED_HEADER_FOR_AUTO_SCROLL;

    // Scroll to the first element
    requestAnimationFrame(() => {
      firstElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }
}

function useErrorScroll(hasValidationErrors) {
  useEffect(() => {
    if (!hasValidationErrors) return () => {};

    const observer = new MutationObserver((_, obs) => {
      const elements = getErrorMessages();
      if (elements && elements.length > 0) {
        scrollToFirstErrorMessage();
        obs.disconnect(); // Stop observing once the elements are found
      }
    });

    // Start observing the body for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [hasValidationErrors]);

  return useCallback(() => {
    if (hasValidationErrors) {
      scrollToFirstErrorMessage();
    }
  }, [hasValidationErrors]);
}

export default useErrorScroll;
