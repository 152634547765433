import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import { formatCurrency } from 'common/util/format';

const BreakdownValueWithChild = ({
  name,
  value,
  testId,
  isCurrency,
  childrenData,
  ...props
}) => {
  return (
    <Box>
      <Flex {...props}>
        <Text fontSize="sm">{name}</Text>
        <Text data-testid={testId} fontSize="sm" pl={1}>
          {value && ((isCurrency && formatCurrency(value)) || <>{value}%</>)}
        </Text>
      </Flex>
      {childrenData
        ?.filter((child) => child.value)
        .map((child, i) => (
          <Box key={i}>
            {child.monthlyIncome?.show ? (
              <>
                {child.monthlyIncome?.percentValue ? (
                  <Text data-testid={testId} fontSize="sm" pl={4} pt={1}>
                    {child.value < 0 ? <>-</> : <>+</>}&nbsp;[&nbsp;(
                    {formatCurrency(Math.abs(child.value))}) &#42; (
                    {child.monthlyIncome?.percentValue})&nbsp;]
                  </Text>
                ) : (
                  <Text data-testid={testId} fontSize="sm" pl={4} pt={1}>
                    {child.value < 0 ? <>-</> : <>+</>}&nbsp;
                    {formatCurrency(Math.abs(child.value))}
                  </Text>
                )}
                <Text
                  data-testid={testId}
                  fontSize="sm"
                  borderTop="1px solid"
                  border-width="160px"
                  marginLeft="16px"
                  marginTop="2px"
                  style={{ textIndent: '-6px' }}
                  pl={4}
                >
                  {formatCurrency(child.monthlyIncome?.totalSumValue)}
                  &nbsp; &divide; 12 (Months)
                </Text>
              </>
            ) : (
              <Text data-testid={testId} fontSize="sm" pl={4} pt={1}>
                {isCurrency ? (
                  <>
                    {child?.sign}
                    {formatCurrency(Math.abs(child.value))}
                  </>
                ) : (
                  <>{child.value}%</>
                )}
                &nbsp;
                {child.name && <>({child.name})</>}
              </Text>
            )}
          </Box>
        ))}
    </Box>
  );
};
BreakdownValueWithChild.propTypes = {
  /** The name of the value. */
  name: PropTypes.string.isRequired,
  /** The value of the field. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** The value for data-testid. */
  testId: PropTypes.string,
  /** Whether the value should be formatted as a currency. */
  isCurrency: PropTypes.bool,
  /** Children data object for each field. */
  childrenData: PropTypes.array,
};

export default BreakdownValueWithChild;
