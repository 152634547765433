﻿import React from 'react';
import PropTypes from 'prop-types';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import BreakdownValue from '../styles/BreakdownValue';
import { FormTypes } from '../../../enums';

const MedicareWages = ({ selectedResult, currentYear }) => {
  const medicareWages = selectedResult.getInputAsFloat(
    FormTypes.W2,
    currentYear,
    'medicareWages',
  );

  return (
    <BreakdownValue
      name="Wages:"
      value={medicareWages}
      isCurrency={true}
      fontWeight="bold"
    >
      <BreakdownValue name="W2 Box 5" value={medicareWages} />
    </BreakdownValue>
  );
};
MedicareWages.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
};

export default MedicareWages;
