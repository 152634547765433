import React from 'react';
import PropTypes from 'prop-types';
import AccordionPanel from 'common/components/Accordions/AccordionPanel';
import AccordionButton from 'common/components/Accordions/AccordionButton';
import AccordionIcon from 'common/components/Accordions/AccordionIcon';
import AccordionItem from 'common/components/Accordions/AccordionItem';
import Accordion from 'common/components/Accordions/Accordion';
import Box from 'common/components/Box';
import { css } from '@emotion/react';
/* copied from Review Breakdown */
const SingleAccordion = ({ title, children, accordionProps = {} }) => {
  return (
    <Accordion
      {...accordionProps}
      defaultIndex={[0]}
      allowMultiple
      backgroundColor="#FFFFFF"
      boxSizing="border-box"
      borderRadius="4px"
      css={css`
        & .chakra-collapse {
          overflow: unset !important;
        }
      `}
    >
      <AccordionItem border="unset">
        <h2>
          <AccordionButton
            h="40px"
            backgroundColor="gray.50"
            border="1px solid"
            borderLeftColor="gray.200"
            borderRightColor="gray.200"
            borderTopColor="gray.200"
            borderBottomColor="white"
            _focus={{
              backgroundColor: 'gray.50',
            }}
            _active={{
              backgroundColor: 'gray.50',
            }}
            _hover={{
              backgroundColor: 'gray.50',
            }}
            p="unset"
          >
            <Box
              as="h4"
              padding="1.5rem 1rem 1.5rem 1rem"
              alignItems="center"
              textAlign="left"
              flexGrow="1"
            >
              {title}
            </Box>
            <AccordionIcon mr={3} />
          </AccordionButton>
        </h2>
        <AccordionPanel
          px="1rem"
          m="unset"
          border="1px solid"
          borderTop={0}
          borderRadius="4px"
          borderColor="gray.200"
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

SingleAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accordionProps: PropTypes.object,
};

export default SingleAccordion;
