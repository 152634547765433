import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StyledLeftDrawerContainer } from './Styled';
import useLeftDrawerContext from '../context/useLeftDrawerContext';
import LeftDrawerButton from './LeftDrawerButton';
import useRailRouteMatches from '../../Rail/hooks/useRailRouteMatches';
export default function LeftDrawerWrapper({ children }) {
  const { boltRedesignV2 } = useSelector(({ featureFlags }) => featureFlags);
  const { isLeftDrawerCollapsed, isNavigationVisible } = useLeftDrawerContext();
  const { is1003Match } = useRailRouteMatches();
  return (
    <StyledLeftDrawerContainer
      id="leftDrawer"
      as="nav"
      isLeftDrawerCollapsed={isLeftDrawerCollapsed}
      isNavigationVisible={isNavigationVisible}
      featureFlag={boltRedesignV2}
      is1003Match={is1003Match}
    >
      <LeftDrawerButton is1003Match={is1003Match} />
      {children}
    </StyledLeftDrawerContainer>
  );
}
LeftDrawerWrapper.propTypes = {
  children: PropTypes.node,
};
