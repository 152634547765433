import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import CrudGrid from 'common/components/CrudGrid';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';
import { formatCurrency } from 'common/util/format';
import {
  renderMultipleBorrowers,
  renderDate,
  concatenateObjectAttributes,
} from 'common/components/CrudGrid/crudGridUtils';
import TotalAssetInfoWindow from './TotalAssetInfo/TotalAssetInfoWindow';
import AssetSummariesForm from './Asset1003Form/Asset1003Form';
import Loan1003FormSection from '../common/components/Loan1003FormSection';
import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';
import { USER_ROLE } from 'common/constants';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { OPERATORS } from 'common/components/CrudGrid/Filters/filterGridData';
import ScrollIntoViewContainer from '../common/components/ScrollIntoViewContainer';
import { isReadOnlyUrl } from 'common/util/appState';

export class NewAsset {
  borrowersAssigned = [];
  assetType = '';
  bankName = '';
  accountNumber = '';
  balance = 0;
  userResponse = [
    {
      statementStartDate: null,
      statementEndDate: null,
      numberOfDays: null,
    },
  ];
  nextLargestDeposit = null;
  comment = '';
}

const fetchData = async (obfuscatedLoanIdentifier) => {
  const res = await apiClient.post(
    apiEndPoints.GET_ASSETS_INFORMATION_FOR_ASSET_SUMMARY,
    obfuscatedLoanIdentifier,
  );
  return res.data;
};

export default function Assets1003Layout() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [addBlankForm, setAddBlankForm] = useState(false);
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [addAdditionalReserves, setAddAdditionalReserves] = useState(false);
  const obfuscatedLoanIdentifier = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => obfuscatedLoanIdentifier,
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);
  const featureFlags = useSelector(({ featureFlags }) => featureFlags);

  const toast = useToast();
  const transformBorrowers = (borrowers) => {
    const uniqueBorrowers = new Map();
    borrowers.forEach((borrower) => {
      uniqueBorrowers.set(borrower.customerRecordId, borrower);
    });
    return Array.from(uniqueBorrowers.values());
  };

  const transformAssets = (assets) =>
    assets?.map((asset) => ({ ...asset, ...asset.asset }));

  const mapExcludedAssets = (excludedAssetsArray, borrowersArray) => {
    const borrowersLookup = borrowersArray.reduce((lookup, borrower) => {
      lookup[borrower.customerRecordId] = borrower;
      return lookup;
    }, {});

    return excludedAssetsArray?.map((excludedAsset) => ({
      ...excludedAsset,
      borrowers: borrowersLookup[excludedAsset.customerRecordId]
        ? [borrowersLookup[excludedAsset.customerRecordId]]
        : [],
    }));
  };

  const { data, error, isLoading } = useQuery(
    ['fetchData', obfuscatedLoanIdentifier],
    () => fetchData(obfuscatedLoanIdentifier),
    {
      select: (data) => {
        const {
          assets,
          excludedAssets,
          borrowers,
          assetsSummaryInformation,
          loanProductType,
          displayAuditHistory,
          hasWritePermission,
        } = data;
        const filteredBorrowers = transformBorrowers(borrowers.borrowers);

        return {
          excludedAssets: mapExcludedAssets(excludedAssets, filteredBorrowers),
          transformedAssets: transformAssets(assets),
          filteredBorrowers,
          assetsSummaryInformation,
          loanProductType,
          displayAuditHistory,
          hasWritePermission: hasWritePermission ?? false,
        };
      },
      onError: () => {
        let toastMessage = {
          title: 'Error',
          description: error.message || 'Could not get data',
          status: 'error',
          duration: 5000,
          isClosable: false,
        };
        toast(toastMessage);
      },
    },
  );

  const {
    excludedAssets: excludedData,
    transformedAssets,
    filteredBorrowers,
    assetsSummaryInformation,
    loanProductType,
    displayAuditHistory,
    hasWritePermission,
  } = data || {};

  const totalAssets = useMemo(() => {
    return transformedAssets?.reduce((sum, obj) => sum + obj.balance, 0) || 0;
  }, [transformedAssets]);

  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !hasWritePermission) ||
      isReadOnlyUrl()
    );
  }, [isUnderwriter, boltStatus, hasWritePermission, role]);

  const isAuditHistoryEnabled = isUnderwriter && displayAuditHistory;
  const AdditionalReservesInBoltEnabled =
    featureFlags?.AdditionalReservesInBoltEnabled;
  const AssetsScreenExpectedAssetsWorkflowEffectiveDate =
    featureFlags?.AssetsScreenExpectedAssetsWorkflowEffectiveDate;

  const columns = [
    {
      fieldName: 'bankName',
      headerName: 'Asset Name',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'accountNumber',
      headerName: 'Account Number',
      isNumeric: false,
      display: true,
      renderCell: (val) => (val != '' ? val : '-'),
    },
    {
      fieldName: 'assetType',
      headerName: 'Type of Asset',
      isNumeric: false,
      display: true,
    },
    {
      fieldName: 'balance',
      headerName: 'Balance',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
    {
      fieldName: 'verifiedDate',
      headerName: 'Statement Date',
      isNumeric: false,
      display: true,
      renderCell: (val) => renderDate(val),
    },
    {
      fieldName: 'borrowers',
      headerName: 'Associated Borrower(s)',
      isNumeric: false,
      display: true,
      renderCell: (val) =>
        renderMultipleBorrowers(val, ['firstName', 'middleName', 'lastName']),
    },
  ];

  const showExcludedData = excludedData?.length > 0;
  const handleEditMode = (row) => {
    setRowToEdit(row);
    setShowForm(true);
  };

  const handleAddMode = () => {
    setRowToEdit(new NewAsset());
    setShowForm(true);
    setAddAdditionalReserves(false);
  };

  const handleAddReserve = () => {
    setShowForm(true);
    setAddAdditionalReserves(true);
  };

  const handleClose = () => {
    setRowToEdit({});
    setShowForm(false);
    setAddAdditionalReserves(false);
  };

  const handleSave = () => {
    // Refetch data after save
    queryClient.invalidateQueries('fetchData');
    setRowToEdit({});
    setShowForm(false);
  };

  const deleteMultipleAssets = () => {
    setShowDeleteMultiple(!showDeleteMultiple);
  };

  const handleDelete = (list) => {
    let toastMessage = {};
    const isSingleDelete = !Array.isArray(list);

    if (!Array.isArray(list)) {
      list = [list];
    }
    const assetRecords = list.map((item) => ({
      assetDatabaseId: item.asset.assetLdId,
      assetRecordId: item.asset.assetId,
    }));

    apiClient
      .post(apiEndPoints.BATCH_DELETE_ASSET, {
        obfuscatedLoanIdentifier,
        assetRecords,
      })
      .then((res) => {
        if (res) {
          toastMessage = {
            title: 'Success',
            description: 'Asset Deleted',
            status: 'success',
            duration: 5000,
            isClosable: true,
          };

          if (!isSingleDelete) {
            deleteMultipleAssets();
          }
          queryClient.invalidateQueries('fetchData');
          dispatch(setLoanSummaryUpsertOrDelete(true));
        }
      })
      .catch(() => {
        toastMessage = {
          title: 'Delete Failed',
          description: 'Could not delete asset',
          status: 'error',
          duration: 5000,
          isClosable: true,
        };
      })
      .finally(() => {
        toast(toastMessage);
      });
  };

  const gridDef = {
    gridType: 'Assets',
    defaultSort: { field: 'balance', direction: 'desc' },
    uniqueIdAttributeFields: ['assetId'],
    manageColumns: false,
    allowFilters: true,
    gridLanguage: {
      helperText: '',
      singularItem: 'Asset',
      pluralItem: 'Assets',
    },
    getDialogDeleteMessage: (list) => {
      if (!Array.isArray(list)) {
        return (
          <Text fontWeight={'500'}>
            Are you sure you want to remove this asset:{' '}
            <strong>
              {list?.asset?.bankName
                ? list?.asset?.bankName
                : list?.asset?.assetType}
            </strong>
            ?
          </Text>
        );
      }

      if (list?.length > 1) {
        return (
          <div>
            <Text textAlign="start" fontWeight={'500'}>
              Are you sure you want to remove the selected assets?
            </Text>
            <ul>
              {list.map((item) => (
                <li key={item?.asset?.assetId}>
                  {item?.asset?.bankName
                    ? item?.asset?.bankName
                    : item?.asset?.assetType}
                </li>
              ))}
            </ul>
          </div>
        );
      } else {
        return (
          <Text fontWeight={'500'}>
            Are you sure you want to remove this asset:{' '}
            <strong>
              {list[0]?.asset?.bankName
                ? list[0]?.asset?.bankName
                : list[0]?.asset?.assetType}
            </strong>
            ?
          </Text>
        );
      }
    },
    getAriaLabel: (row) => row.asset.bankName,
  };

  const excludedAssetsGridDef = {
    gridType: 'Assets',
    defaultSort: { field: 'balance', direction: 'desc' },
    uniqueIdAttributeFields: ['assetId'],
    manageColumns: false,
    allowFilters: false,
    gridLanguage: {
      helperText: '',
      singularItem: 'Asset',
      pluralItem: 'Assets',
    },
  };

  const multiDelete = {
    buttonLabel: 'Delete Multiple Assets',
    subActions: [
      {
        label: 'Select All / Deselect All',
        action: (allData, currentSelected) => {
          if (currentSelected.length === allData.length) {
            return [];
          }
          return allData;
        },
      },
    ],
  };

  const buttonDefs = [
    {
      label: 'Add Asset',
      action: () => {
        handleAddMode(new NewAsset());
        setAddBlankForm(true);
      },
      visible: true,
      isDisable: false,
      testId: 'addAssetButton',
    },
    {
      label: 'Add Reserves',
      action: () => {
        handleAddReserve();
        setAddBlankForm(true);
      },
      visible: AdditionalReservesInBoltEnabled && isUnderwriter,
      isDisable: false,
      testId: 'addReservesButton',
    },
  ];

  const filters = [
    {
      options: filteredBorrowers?.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      label: 'Borrower(s)',
      inputType: 'multiSelect',
      fieldName: 'borrowers',
      operator: OPERATORS.EXCLUDE,
      evaluateFilter: (row, value) =>
        row.borrowers.some((borrower) =>
          value.includes(borrower.customerRecordId),
        ),
    },
  ];

  return (
    <StyledLoan1003LayoutContainer>
      {assetsSummaryInformation && (
        <TotalAssetInfoWindow
          assetsInfo={assetsSummaryInformation}
          loanProductType={loanProductType}
        />
      )}

      {transformedAssets && (
        <CrudGrid
          gridDef={gridDef}
          columns={columns}
          data={transformedAssets}
          disableRowClick={isWriteAccessDisabled}
          onRowEditClick={(row) => {
            handleEditMode(row);
          }}
          filters={filters}
          selectedRow={rowToEdit}
          isLoading={isLoading}
          onDelete={(list) => handleDelete(list)}
          buttonDefs={buttonDefs}
          multiSelectActionDef={multiDelete}
          onGridModeChange={() => setShowForm(false)}
          readOnly={isWriteAccessDisabled}
          isAuditHistoryEnabled={isAuditHistoryEnabled}
        >
          <Text as="b">Total Assets: {formatCurrency(totalAssets)}</Text>
        </CrudGrid>
      )}
      {showForm && (
        <ScrollIntoViewContainer
          valueToWatch={rowToEdit}
          style={{ width: '100%' }}
        >
          <AssetSummariesForm
            rowToEdit={rowToEdit}
            onClose={() => handleClose()}
            onSave={() => {
              handleSave();
            }}
            addBlankForm={addBlankForm}
            setAddBlankForm={setAddBlankForm}
            isWriteAccessDisabled={isWriteAccessDisabled}
            handleToastMessage={(toastMessage) => toast(toastMessage)}
            allBorrowerOptions={filteredBorrowers}
            obfuscatedIds={obfuscatedLoanIdentifier}
            addAdditionalReserves={addAdditionalReserves}
            productType={loanProductType}
            assetsInfo={assetsSummaryInformation}
            isUnderwriter={isUnderwriter}
            isExpectedAssetsVisible={
              AssetsScreenExpectedAssetsWorkflowEffectiveDate
            }
          />
        </ScrollIntoViewContainer>
      )}
      {showExcludedData && (
        <>
          <Loan1003FormSection title="Excluded Assets">
            <Text mb={'1.5rem'}>
              The following asset(s) were removed. Return to the Asset
              Reconciliation page in the BOLT tab to make updates.
            </Text>
          </Loan1003FormSection>
          <CrudGrid
            gridDef={excludedAssetsGridDef}
            columns={columns}
            data={excludedData}
            isLoading={isLoading}
            readOnly={true}
            disableRowClick={true}
          ></CrudGrid>
        </>
      )}
    </StyledLoan1003LayoutContainer>
  );
}
