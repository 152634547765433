import { Modal as ChakraModal } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Modal = ({ children, ...props }) => {
  return <ChakraModal {...props}>{children}</ChakraModal>;
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
