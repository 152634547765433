import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  StyledContainer,
  StyledIframe,
} from './StyledComponents/PDFViewer.styles';

const PDFViewer = ({ selectedPDFBlobUrl }) => {
  // redux
  const { selectedDocument } = useSelector(
    ({ classifyDocumentsV2 }) => classifyDocumentsV2,
  );

  return (
    <StyledContainer as="article">
      {selectedPDFBlobUrl && (
        <StyledIframe
          allow="fullscreen"
          title={selectedDocument?.documentId}
          src={`${selectedPDFBlobUrl}#view=fitH`}
          id="documentViewer"
        />
      )}
    </StyledContainer>
  );
};

PDFViewer.propTypes = {
  selectedPDFBlobUrl: PropTypes.string,
};

export default PDFViewer;
