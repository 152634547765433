class OtherIncomeDataModel {
  constructor(id = {}) {
    this.amount = Object.hasOwnProperty.call(id, 'amount') ? id.amount : 0;
    this.borrowerLenderDatabaseId = Object.hasOwnProperty.call(
      id,
      'borrowerLenderDatabaseId',
    )
      ? id.borrowerLenderDatabaseId
      : 0;
    this.borrowerRecordId = Object.hasOwnProperty.call(id, 'borrowerRecordId')
      ? id.borrowerRecordId
      : 0;
    this.employerId = Object.hasOwnProperty.call(id, 'employerId')
      ? id.employerId
      : 0;
    this.incomeType = Object.hasOwnProperty.call(id, 'incomeType')
      ? id.incomeType
      : 0;
    this.isDocumentationProvided = Object.hasOwnProperty.call(
      id,
      'isDocumentationProvided',
    )
      ? id.isDocumentationProvided
      : false;
    this.underwritingMessage = Object.hasOwnProperty.call(
      id,
      'underwritingMessage',
    )
      ? id.underwritingMessage
      : {};
  }

  getAmount() {
    return this.amount;
  }

  getBorrowerDatabaseId() {
    return this.borrowerLenderDatabaseId;
  }

  getBorrowerRecordId() {
    return this.borrowerRecordId;
  }

  getEmployerId() {
    return this.employerId;
  }

  getIncomeType() {
    return this.incomeType;
  }

  getIsDocumentationProvided() {
    return this.isDocumentationProvided;
  }

  getUnderwritingMessage() {
    return this.underwritingMessage;
  }
}

export default OtherIncomeDataModel;
