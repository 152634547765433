import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'common/components/Heading';
import Tag from 'common/components/Tags/Tag';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import { formatCurrency } from 'common/util/format';
import theme from 'theme/';

const CalculationCard = (props) => {
  const {
    isChecked,
    isDisabled = false,
    title,
    titleSecondLine,
    payFrequency,
    amount,
    secondaryPayFrequency,
    secondaryAmount,
    ourPick = false,
    onClick = () => {},
  } = props;

  const borderColor = setBorderColor(isDisabled, isChecked);
  const valueLength = (value) => String(value).length;
  const fontSize = valueLength(amount) < 8 ? '24px' : '18px';
  const id = `monopolyCardLabel-${title}`;
  return (
    <Flex
      as="div"
      position="relative"
      cursor="pointer"
      role="radio"
      id={id}
      borderRadius="4px"
      border="1px solid"
      borderColor={borderColor}
      boxShadow={`1px 1px 5px ${theme?.colors.gray[400]}`}
      _hover={{
        boxShadow: `4px 4px 5px ${theme?.colors.gray[400]}`,
        transition: '0.1s',
      }}
      _focus={{ boxShadow: 'sm' }}
      fontWeight="500"
      direction="column"
      align="center"
      w="200px"
      minH="183px"
      data-testid={`calc-card-${title}-${payFrequency}`}
      aria-checked={isChecked}
      aria-disabled={isDisabled}
      onClick={onClick}
    >
      <Flex
        justify="center"
        bg={borderColor}
        w="100%"
        py="11px"
        minH="38px"
      ></Flex>

      <Flex
        direction="column"
        align="center"
        justify="space-between"
        height="inherit"
        width="100%"
      >
        <Heading
          as="label"
          htmlFor={id}
          fontSize="md"
          py="16px"
          style={
            valueLength(title) < 12
              ? {}
              : {
                  textAlign: 'center',
                  maxWidth: '128px',
                  lineHeight: '16px',
                }
          }
        >
          {title}
          {titleSecondLine && <Text>{titleSecondLine}</Text>}
        </Heading>
        <Text
          style={
            valueLength(payFrequency) < 12
              ? {
                  fontSize: '14px',
                  paddingBottom: '18px',
                }
              : {
                  fontSize: '12px',
                  textAlign: 'center',
                  paddingBottom: '14px',
                  maxWidth: '120px',
                  lineHeight: '16px',
                }
          }
        >
          {payFrequency}
        </Text>
        <Text lineHeight="32px" fontSize={fontSize} pb="16px">
          {formatCurrency(amount)}
        </Text>

        {!!secondaryPayFrequency && (
          <>
            <hr
              style={{
                width: '100%',
                borderTopWidth: '1px',
                borderTopColor: '#E2E8F0',
              }}
            />
            <Text fontSize="12px" pt="8px">
              {secondaryPayFrequency}
            </Text>
            <Text fontSize="24px" mb={4}>
              {secondaryAmount ? formatCurrency(secondaryAmount) : '$0.00'}
            </Text>
          </>
        )}

        {ourPick && (
          <Tag
            data-testid="ourpick-tag"
            position="absolute"
            bottom="-11px"
            left="50%"
            ml="-40px"
            borderRadius="4px"
            bg="blue.600"
            color="white"
            fontSize="12px"
            fontWeight="400"
            justifyContent="center"
            w="80px"
            h="22px"
          >
            Our Pick
          </Tag>
        )}
      </Flex>
    </Flex>
  );
};

function setBorderColor(isDisabled, isChecked) {
  const grayOut = 'gray.200';
  const blue = 'blue.600';

  if (isDisabled) {
    return grayOut;
  }

  return isChecked ? blue : grayOut;
}

CalculationCard.propTypes = {
  /** Indicates if the card is checked */
  isChecked: PropTypes.bool.isRequired,
  /** Indicates if the card can be selected */
  isDisabled: PropTypes.bool,
  /** The title of the calculation card*/
  title: PropTypes.string.isRequired,
  /** The subtitle/line 2 of the calculation card*/
  titleSecondLine: PropTypes.string,
  /** The pay frequency */
  payFrequency: PropTypes.string.isRequired,
  /** The amount value of the calculation */
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Secondary pay frequency for rental */
  secondaryPayFrequency: PropTypes.string,
  /** Secondary amount value for rental */
  secondaryAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Optional tag for the our pick */
  ourPick: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CalculationCard;
