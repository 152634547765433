import PropTypes from 'prop-types';
import StackComponent from 'common/components/Stacks/Stack';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

export default function Stack({
  fields = [],
  direction,
  spacing,
  sx,
  children,
}) {
  const { renderForm } = useFormApi();

  return (
    <StackComponent direction={direction} spacing={spacing} sx={sx}>
      {children ?? renderForm(fields)}
    </StackComponent>
  );
}
Stack.propTypes = {
  fields: PropTypes.array,
  direction: PropTypes.oneOf([
    'row',
    'column',
    'row-reverse',
    'column-reverse',
  ]),
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
  children: PropTypes.node,
};
