import { ALERT_SEVERITY } from 'common/constants';

/**
 * An alert generated by an income calculation.
 */
export default class AlertDataModel {
  constructor(cr = {}) {
    this.title = Object.prototype.hasOwnProperty.call(cr, 'title')
      ? cr.title
      : '';
    this.description = Object.prototype.hasOwnProperty.call(cr, 'description')
      ? cr.description
      : '';

    this.severity = Object.prototype.hasOwnProperty.call(cr, 'severity')
      ? cr.severity
      : 0;

    this.calculationScenarioId = Object.prototype.hasOwnProperty.call(
      cr,
      'calculationScenarioID',
    )
      ? cr.calculationScenarioID
      : 0;

    this.conditionCategory = Object.prototype.hasOwnProperty.call(
      cr,
      'conditionCategory',
    )
      ? cr.conditionCategory
      : 0;
  }

  /**
   * @returns {string} The alert title.
   */
  getTitle() {
    return this.title;
  }

  /**
   * @returns {string} The alert text.
   */
  getDescription() {
    return this.description;
  }

  /**
   * @returns {string|null} The alert variant based on its severity.
   */
  getSeverity() {
    switch (this.severity) {
      case ALERT_SEVERITY.HINT:
        return 'info';
      case ALERT_SEVERITY.WARNING:
        return 'warning';
      case ALERT_SEVERITY.ALERT:
        return 'error';
      case ALERT_SEVERITY.SUCCESS:
        return 'success';
      case ALERT_SEVERITY.ATTENTION:
        return 'extractionFailed';
      default:
        return null;
    }
  }
}
