import React from 'react';
import { TableCell as MUITh } from '@mui/material';
import { tableMapper } from 'common/components/PropertyMappers/tableMapper';
import PropTypes from 'prop-types';

const Th = React.forwardRef(({ children, ...props }, ref) => {
  const tableProps = tableMapper(props);
  return (
    <MUITh scope="row" component="th" ref={ref} {...tableProps}>
      {children}
    </MUITh>
  );
});

Th.displayName = 'Th';

export default Th;

Th.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  children: PropTypes.node,
  classes: PropTypes.object,
  component: PropTypes.elementType,
  padding: PropTypes.oneOf(['checkbox', 'none', 'normal']),
  scope: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['medium', 'small']),
    PropTypes.string,
  ]),
  sortDirection: PropTypes.oneOfType([
    PropTypes.oneOf(['asc', 'desc']),
    PropTypes.bool,
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['body', 'footer', 'head']),
    PropTypes.string,
  ]),
};
