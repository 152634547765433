export default class ExtractionInfoData {
  constructor(b = {}) {
    this.addressLine1 = Object.prototype.hasOwnProperty.call(
      b,
      'borrowerAddress',
    )
      ? b.borrowerAddress
      : '';

    this.state = Object.prototype.hasOwnProperty.call(b, 'borrowerState')
      ? b.borrowerState
      : '';

    this.city = Object.prototype.hasOwnProperty.call(b, 'borrowerCity')
      ? b.borrowerCity
      : '';

    this.zipCode = Object.prototype.hasOwnProperty.call(b, 'borrowerZipCode')
      ? b.borrowerZipCode
      : '';

    this.socialSecurityNumber = Object.prototype.hasOwnProperty.call(
      b,
      'socialSecurityNumber',
    )
      ? b.socialSecurityNumber
      : '';

    this.employerName = Object.prototype.hasOwnProperty.call(b, 'employerName')
      ? b.employerName
      : '';

    this.taxYear = Object.prototype.hasOwnProperty.call(b, 'taxYear')
      ? b.taxYear
      : '';

    this.taxYearExtractedDataResultId = Object.prototype.hasOwnProperty.call(
      b,
      'taxYearExtractedDataResultId',
    )
      ? b.taxYearExtractedDataResultId
      : 0;

    this.borrowerAddressExtractedDataResultId =
      Object.prototype.hasOwnProperty.call(
        b,
        'borrowerAddressExtractedDataResultId',
      )
        ? b.borrowerAddressExtractedDataResultId
        : 0;

    this.employerNameExtractedDataResultId =
      Object.prototype.hasOwnProperty.call(
        b,
        'employerNameExtractedDataResultId',
      )
        ? b.employerNameExtractedDataResultId
        : 0;

    this.paymentDateExtractedDataResultId =
      Object.prototype.hasOwnProperty.call(
        b,
        'paymentDateExtractedDataResultId',
      )
        ? b.paymentDateExtractedDataResultId
        : 0;

    this.socialSecurityNumberExtractedDataResultId =
      Object.prototype.hasOwnProperty.call(
        b,
        'socialSecurityNumberExtractedDataResultId',
      )
        ? b.socialSecurityNumberExtractedDataResultId
        : 0;

    this.paymentDate = Object.prototype.hasOwnProperty.call(b, 'paymentDate')
      ? b.paymentDate
      : '';
  }

  /**
   * @returns {string} The AddressL Line 1.
   */
  getAddressLine() {
    return this.addressLine1;
  }

  /**
   * @returns {string} state name.
   */
  getState() {
    return this.state;
  }

  /**
   * @returns {string} city Name.
   */
  getCity() {
    return this.city;
  }

  /**
   * @returns {string} zipcode.
   */
  getZipCode() {
    return this.zipCode;
  }

  /**
   * @returns {string} borrowerSSN.
   */
  getSocialSecurityNumber() {
    return this.socialSecurityNumber;
  }
  /**
   * @returns {string} paymentDate.
   */
  getPaymentDate() {
    return this.paymentDate;
  }

  /**
   * @returns {string} employerName.
   */
  getExtractedEmployerName() {
    return this.employerName;
  }

  /**
   * @returns {string} fiscalYear.
   */
  getTaxYear() {
    return this.taxYear;
  }

  /**
   * @returns {number} snippet Id.
   */
  getTaxYearExtractedDataResultId() {
    return this.taxYearExtractedDataResultId;
  }
}
