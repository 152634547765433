import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@mui/material';

// TODO: Remove once the MUI transition includes inputs like checkboxes
export default function Checkbox({ checked, children, ...props }) {
  return (
    <MuiCheckbox checked={checked} {...props}>
      {children}
    </MuiCheckbox>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  props: PropTypes.object,
};
