import Input from 'common/components/Inputs/Input';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useFieldArray, Controller } from 'react-hook-form';
import DatePicker from 'common/components/DatePicker/index';

const StyledAssetFieldArray = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  flex-direction: column;
  width: 100%;
`;
const StyledFormControl = styled(FormControl)`
  position: relative;
  width: ${(props) =>
    props.column === 'first' ? 'calc(25% - 3rem)' : 'calc(25% - 1.5rem)'};
  margin-right: 3rem;
`;

const StyledStatementRow = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
`;

const StyledStatementTile = styled(Box)`
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray[900]};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.gray[300]};
  text-decroation-thickness: 2px;
  margin-bottom: 1.125rem;
  width: 100%;
`;

export default function IncomeFieldArray({
  handleChange,
  control,
  getValues,
  isHidden,
  readOnlyMode,
}) {
  const { fields } = useFieldArray({
    name: 'documentAssociationDetails',
    control,
    shouldUnregister: false,
  });

  const theme = useTheme();

  const readOnlyStyle = {
    pointerEvents: 'none',
    opacity: 0.6,
    cursor: 'not-allowed',
  };

  if (isHidden) {
    return null;
  }

  return (
    <Flex
      direction={'column'}
      justify={'flex-start'}
      style={readOnlyMode ? readOnlyStyle : {}}
    >
      {fields.map((field, index) => {
        return (
          <Flex
            width={'100%'}
            direction={'column'}
            marginBottom={'1rem'}
            key={field.id}
            align={'flex-end'}
            boxSizing="border-box"
            alignItems={'flex-start'}
          >
            <Flex
              width={'100%'}
              boxSizing="border-box"
              justifyContent={'flex-start'}
            >
              {/* DOC DATE FOR BANK STATEMENTS SOURCE TYPES */}
              <StyledAssetFieldArray>
                <StyledStatementTile>{`Bank Statement ${
                  index + 1
                }`}</StyledStatementTile>
                <StyledStatementRow>
                  <StyledFormControl column={'first'}>
                    <FormLabel htmlFor={`startDate-${field.id}`}>
                      Statement Start Date
                    </FormLabel>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          value={getValues(
                            `documentAssociationDetails.${index}.userResponses.statementStartDate`,
                          )}
                          onChange={(e) =>
                            handleChange(
                              `documentAssociationDetails.${index}.userResponses.statementStartDate`,
                              new Date(e),
                            )
                          }
                          tabIndex={readOnlyMode ? -1 : 0}
                          color={theme.colors.red}
                          disabled={readOnlyMode}
                        />
                      )}
                      name={`documentAssociationDetails.${index}.userResponses.statementStartDate`}
                      control={control}
                      defaultValue={field.userResponses.statementStartDate}
                    />
                  </StyledFormControl>
                  <StyledFormControl count={index}>
                    <FormLabel
                      htmlFor={`documentAssociationDetails.${index}.userResponses.statementEndDate`}
                    >
                      Statement End Date
                    </FormLabel>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          value={getValues(
                            `documentAssociationDetails.${index}.userResponses.statementEndDate`,
                          )}
                          onChange={(e) =>
                            handleChange(
                              `documentAssociationDetails.${index}.userResponses.statementEndDate`,
                              new Date(e),
                            )
                          }
                          tabIndex={readOnlyMode ? -1 : 0}
                          color={theme.colors.red}
                          disabled={readOnlyMode}
                        />
                      )}
                      name={`documentAssociationDetails.${index}.userResponses.statementEndDate`}
                      control={control}
                      defaultValue={field.userResponses.statementEndDate}
                    />
                  </StyledFormControl>
                  <StyledFormControl count={index}>
                    <FormLabel
                      htmlFor={`documentAssociationDetails.${index}.userResponses.statementNumberOfDays`}
                    >
                      Number of Days
                    </FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          value={getValues(
                            `documentAssociationDetails.${index}.userResponses.statementNumberOfDays`,
                          )}
                          disabled={readOnlyMode}
                        />
                      )}
                      name={`documentAssociationDetails.${index}.userResponses.statementNumberOfDays`}
                      rules={{
                        required: true,
                      }}
                      defaultValue={
                        fields[index].userResponses.statementNumberOfDays
                      }
                      control={control}
                    />
                  </StyledFormControl>
                </StyledStatementRow>
              </StyledAssetFieldArray>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}

IncomeFieldArray.propTypes = {
  handleChange: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  isHidden: PropTypes.bool,
  readOnlyMode: PropTypes.bool,
};
