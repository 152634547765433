import CrudGrid from 'common/components/CrudGrid';
import { useState, useEffect, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import Text from 'common/components/Texts/Text';
import IncomeSummariesForm from './Income1003Form/Income1003Form';
import apiEndPoints from 'common/constants/api-endpoints';
import { useSelector } from 'react-redux';
import apiClient from 'common/util/api-client';
import {
  concatenateObjectAttributes,
  renderDate,
  renderNullValueAsDash,
} from 'common/components/CrudGrid/crudGridUtils';
import { OPERATORS } from 'common/components/CrudGrid/Filters/filterGridData';
import { useDispatch } from 'react-redux';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { formatCurrency } from 'common/util/format';
import { USER_ROLE } from 'common/constants';
import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';
import ScrollIntoViewContainer from '../common/components/ScrollIntoViewContainer';
import { isReadOnlyUrl } from 'common/util/appState';

export class NewIncome {
  employerId = 0;
  employerName = '';
  borrowerName = {
    firstName: '',
    middleName: '',
    lastName: '',
    suffixName: '',
  };
  customerRecordId = null;
  customerRecordIdOriginal = null;
  currentEmployment = 'Y';
  primaryEmployment = false;
  country = null;
  employerPhone = undefined;
  endDate = null;
  startDate = null;
  percentageOwnership = null;
  yearsOnJob = null;
  cpaInformation = [];
  selfEmployed = false;
  employerAddress = {
    addressLine1: '',
    state: '',
    city: '',
    zipCode: '',
  };
  monthlyIncomeDetails = [];
  verifiedDate = null;
}

export default function Income1003Layout() {
  //TODO refactor to useReducer
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allBorrowers, setAllBorrowers] = useState([]);
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const { obfuscatedLoanIdentifier } = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => {
      return {
        obfuscatedLoanIdentifier,
      };
    },
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);
  const totalMonthlyPayments = useMemo(() => {
    return data.reduce(
      (sum, obj) =>
        sum + (obj.currentEmployment === 'Y' ? obj.monthlyAmount : 0),
      0,
    );
  }, [data]);
  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !hasWritePermission) ||
      isReadOnlyUrl()
    );
  }, [isUnderwriter, boltStatus, hasWritePermission, role]);

  const toast = useToast();

  const columns = [
    {
      fieldName: 'borrowerName',
      headerName: 'Borrower Name',
      sortable: false,
      isNumeric: false,
      display: true,
      filterable: true,
      renderCell: (val) =>
        concatenateObjectAttributes(val, [
          'firstName',
          'middleName',
          'lastName',
          'suffixName',
        ]),
    },
    {
      fieldName: 'employerName',
      headerName: 'Employer or Description',
      isNumeric: false,
      sortable: false,
      display: true,
    },
    {
      fieldName: 'title',
      headerName: 'Job Title',
      isNumeric: false,
      sortable: false,
      display: true,
      renderCell: (val) => renderNullValueAsDash(val, '-'),
    },
    {
      fieldName: 'currentEmployment',
      headerName: 'Current',
      isNumeric: false,
      sortable: false,
      display: true,
      renderCell: (val) => (val === 'Y' ? 'Yes' : 'No'),
    },
    {
      fieldName: 'startDate',
      headerName: 'Start Date',
      isNumeric: false,
      sortable: true,
      display: true,
      renderCell: (val) => renderDate(val),
    },
    {
      fieldName: 'endDate',
      headerName: 'End Date',
      isNumeric: false,
      sortable: true,
      display: true,
      renderCell: (val) => renderDate(val),
    },
    {
      fieldName: 'monthlyAmount',
      headerName: 'Monthly Income',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
  ];

  useEffect(
    () => getAllData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getAllData = () => {
    let isMounted = true;
    setIsLoading(true);
    apiClient
      .post(apiEndPoints.underwritingSummaries.GET_SUMMARY_INCOME_DATA, {
        obfuscatedLoanIdentifier,
      })
      .then((res) => {
        if (!isMounted) return;
        setData(res.data.employmentReviewDetail);
        setAllBorrowers(res.data.allBorrowers);
        setHasWritePermission(res.data.hasWritePermission ?? false);
      })
      .catch(() => {
        let toastMessage = {
          title: 'Error',
          description: 'Could not get data',
          status: 'error',
          duration: 5000,
          isClosable: false,
        };
        toast(toastMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  };

  const handleEditMode = (row) => {
    setRowToEdit({
      ...row,
      customerRecordIdOriginal: row.customerRecordId,
    });
    setShowForm(true);
  };

  const handleClose = () => {
    setRowToEdit({});
    setShowForm(false);
  };

  const handleSave = () => {
    getAllData();
    setShowForm(false);
    setRowToEdit({});
  };

  const handleDelete = (value) => {
    const itemToDelete = value[0];
    let toastMessage = {};
    apiClient
      .post(apiEndPoints.underwritingSummaries.DELETE_SUMMARY_INCOME, {
        obfuscatedLoanIdentifier,
        customerIdentifier: {
          obfuscatedLenderDatabaseId:
            obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,

          customerRecordId: itemToDelete.customerRecordId,
        },
        employerId: itemToDelete.employerId,
      })
      .then((res) => {
        if (res) {
          toastMessage = {
            title: 'Success',
            description: 'Employment Deleted',
            status: 'success',
            duration: 5000,
            isClosable: true,
          };
          getAllData();
          setRowToEdit({});
          setShowForm(false);
          toast(toastMessage);
          dispatch(setLoanSummaryUpsertOrDelete(true));
        }
      })
      .catch(() => {
        toastMessage = {
          title: 'Delete Failed',
          description: 'Record was not able to be deleted.',
          status: 'error',
          duration: 5000,
          isClosable: false,
        };
        toast(toastMessage);
      });
  };
  const gridDef = {
    gridType: 'Income',
    defaultSort: { field: 'monthlyAmount', direction: 'desc' },
    uniqueIdAttributeFields: ['customerRecordId', 'employerId'],
    gridLanguage: {
      singularItem: 'Income',
    },
    getDialogDeleteMessage: (row) =>
      `Are you sure you want to remove income source -${row[0].employerName}`,
    getAriaLabel: (row) => row.employerName,
    readOnly: false,
    manageColumns: false,
    allowFilters: true,
  };

  const buttonDefs = [
    {
      label: 'Add Income',
      action: () => {
        handleEditMode(new NewIncome());
      },
      visible: !isWriteAccessDisabled,
      isDisable: false,
      testId: 'addIncomeButton',
    },
  ];
  const filters = [
    {
      options: allBorrowers.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      label: 'Borrower(s)',
      inputType: 'multiSelect',
      fieldName: 'customerRecordId',
      operator: OPERATORS.EXCLUDE,
      evaluateFilter: (row, value) => value.includes(row.customerRecordId),
    },
  ];

  return (
    <StyledLoan1003LayoutContainer>
      <CrudGrid
        gridDef={gridDef}
        columns={columns}
        data={data}
        disableRowClick={isWriteAccessDisabled}
        onRowEditClick={(row) => {
          handleEditMode(row);
        }}
        filters={filters}
        isLoading={isLoading}
        readOnly={isWriteAccessDisabled}
        selectedRow={rowToEdit}
        onDelete={handleDelete}
        buttonDefs={buttonDefs}
      />
      {!isLoading && (
        <div
          style={{
            margin: '1rem 0rem',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Text fontWeight={'bold'} margin={'2rem 0rem'}>
            Total Monthly Income : {formatCurrency(totalMonthlyPayments)}
          </Text>
        </div>
      )}
      {showForm && (
        <ScrollIntoViewContainer
          valueToWatch={rowToEdit}
          style={{ width: '100%' }}
        >
          <IncomeSummariesForm
            rowToEdit={rowToEdit}
            onClose={() => handleClose()}
            onSave={handleSave}
            isWriteAccessDisabled={isWriteAccessDisabled}
            handleToastMessage={(toastMessage) => toast(toastMessage)}
            allBorrowerOptions={allBorrowers}
            obfuscatedIds={obfuscatedLoanIdentifier}
          />
        </ScrollIntoViewContainer>
      )}
    </StyledLoan1003LayoutContainer>
  );
}
