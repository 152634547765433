import { SET_ACTIVE_FIELD, RESET_ACTIVE_FIELD } from './types';

export const setActiveField = (payload) => ({
  type: SET_ACTIVE_FIELD,
  payload,
});

export const resetActiveField = () => ({
  type: RESET_ACTIVE_FIELD,
});
