import { List as ChakraList } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const List = ({ children, ...props }) => {
  return <ChakraList {...props}>{children}</ChakraList>;
};

export default List;

List.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
