import PropTypes from 'prop-types';
import { SimpleGrid } from '@chakra-ui/react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { ChildrenWidget } from 'lib-node-amb-renderer';

export class FullPageWithPDFViewerClass extends ChildrenWidget {
  constructor(props) {
    super(props);
  }
}

export default function FullPageWithPDFViewer({
  fields = [],
  name,
  columns = 2,
  rowSpacing = 0,
  columnSpacing = 0,
  gridTemplateColumns = '1fr 1fr',
  flexGrow = 1,
  sx,
  children,
  showSplitScreen,
  height,
}) {
  const { renderForm } = useFormApi();
  let isDocumentProvidedValue;

  if (showSplitScreen) {
    isDocumentProvidedValue = JSON.parse(showSplitScreen);
  }

  return (
    <SimpleGrid
      id={name}
      height={height ?? '900px'}
      spacingX={columnSpacing}
      spacingY={rowSpacing}
      columns={columns}
      gridTemplateColumns={
        isDocumentProvidedValue === false ? '1fr' : gridTemplateColumns
      }
      flexGrow={flexGrow}
      sx={sx}
    >
      {children ? children : renderForm(fields)}
    </SimpleGrid>
  );
}

FullPageWithPDFViewer.propTypes = {
  fields: PropTypes.array,
  name: PropTypes.string,
  columns: PropTypes.number,
  rowSpacing: PropTypes.string,
  columnSpacing: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  flexGrow: PropTypes.number,
  sx: PropTypes.object,
  children: PropTypes.node,
  showSplitScreen: PropTypes.any,
  height: PropTypes.string,
};
