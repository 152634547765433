import { propsMapper } from './propsMapper';

const sizeMapping = {
  sm: 'small',
  md: 'medium',
  // mui won't accept 'large' as a size prop
  lg: 'medium',
};

// This has table, tablecell, table row props
const tablePropsRegex =
  /^(size|stickyHeader|align|scope|sortDirection|variant|hover|selected|colSpan)$/;

export const tableMapper = (props) => {
  const tableProps = {};

  Object.entries(props).forEach(([key, value]) => {
    if (tablePropsRegex.test(key)) {
      if (key === 'size') {
        tableProps[key] = sizeMapping[value] ? sizeMapping[value] : value;
      } else {
        tableProps[key] = value;
      }
      delete props[key];
    }
  });

  const haveTableProps = Object.keys(tableProps).length > 0;

  const mappedProps = propsMapper(props);
  const updatedTableProps = haveTableProps
    ? { ...mappedProps, ...tableProps }
    : { ...mappedProps };

  return updatedTableProps;
};
