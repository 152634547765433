import ExtractedDocumentDataModel from './ExtractedDocumentDataModel';
import ExtractedSnippetDataModel from './ExtractedSnippetDataModel';

export default class DataExtractionDataModel {
  constructor(data = {}) {
    this.documents = Object.hasOwnProperty.call(data, 'documents')
      ? data?.documents?.map((doc) => new ExtractedDocumentDataModel(doc))
      : [];

    this.snippets = Object.hasOwnProperty.call(data, 'snippets')
      ? data?.snippets?.map((snip) => new ExtractedSnippetDataModel(snip))
      : [];
  }

  /**
   * @return {[ExtractedDocumentDataModel]} The sorted list of documents.
   */
  getDocuments() {
    return this.documents?.sort(
      (a, b) =>
        `${b.getDocumentType()}`.localeCompare(a.getDocumentType()) ||
        b.getFiscalYear() - a.getFiscalYear(),
    );
  }

  /**
   * Gets the matching snippet.
   * @param documentType {string} The document type name of the document.
   * @param fieldName {string} The name of the field.
   * @param fiscalYear {number} The year of the document.
   * @return {ExtractedSnippetDataModel} The matching snippet, or null.
   */
  getSnippet(documentType, fieldName, fiscalYear = -1) {
    return (
      this.snippets?.find(
        (x) =>
          x.getDocumentType() === documentType &&
          x.getFieldName() === fieldName &&
          (fiscalYear === -1 || x.getFiscalYear() === fiscalYear),
      ) || {}
    );
  }
}

/**
 * Converts returned extraction data into a data extraction data model.
 * @param data {object} An object containing a list of document fields and snippets.
 * @param documentId {number} The document id.
 * @param documentType {string} A string representing the name of the document.
 * @return {DataExtractionDataModel} A list of documents and snippets.
 */
export function parseExtractionData(data, documentId, documentType) {
  if (!data) {
    return null;
  }

  const documents = [
    {
      documentId,
      documentType,
    },
  ];

  const snippets = Object.keys(data)
    .filter((key) => key.includes('ExtractedDataResultId'))
    .map((key) => {
      return {
        snippetId: data[key],
        fieldName: key.replace('ExtractedDataResultId', ''),
        documentType,
      };
    });

  return new DataExtractionDataModel({
    documents,
    snippets,
  });
}

/**
 * Converts returned validation data into a data extraction data model.
 * @param data {Array} An array of extracted document and snippets
 * @param documentId {number} The id of the document to parse for.
 * @param documentType {string} The type of document
 * @return {DataExtractionDataModel} The extraction data.
 */
export function parseValidateDocument(data, documentId, documentType) {
  if (!data) {
    return null;
  }

  const documents = [
    {
      documentId,
      documentType,
    },
  ];

  const snippets = data
    ?.getEmploymentExtractedData()
    ?.getDocumentField()
    .map((doc) => {
      return {
        snippetId: doc.snippetId,
        fieldName: doc.documentFieldName,
        documentType,
      };
    })
    .flat();

  return new DataExtractionDataModel({
    documents,
    snippets,
  });
}
