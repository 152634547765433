import { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { truncate } from 'lodash';

import StatusIcon from 'common/components/Icons/StatusIcon';
import { ALERT_TYPES } from 'common/constants';
import { HomeActions } from './redux';
import { IS_LEFT_DRAWER_COLLAPSED } from 'pages/ClassifyDocumentsV2/constants';
import useLeftDrawerContext from 'common/components/Nav/LeftDrawer/context/useLeftDrawerContext';

const StyledParentAlert = styled('button', {
  shouldForwardProp: (prop) => prop !== IS_LEFT_DRAWER_COLLAPSED,
})`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: ${(props) => (props.isLeftDrawerCollapsed ? '72px' : '100%')};
  height: 72px;
  padding: 24px;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[200]};
  background-color: ${(props) =>
    props.selected ? props.theme.colors.blue[50] : props.theme.colors.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.blue[100]};
  }
  &:focus {
    box-shadow: none;
  }
`;

const StyledSortDownIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'showParentChildren',
})((props) => ({
  transformOrigin: 'center',
  transition: 'transform 0.2s',
  transform: props.showParentChildren ? 'rotate(0deg)' : 'rotate(-180deg)',
}));

const isSelected = (alert, selectedAlert) => {
  if (alert?.gutterItemChildren?.length === 0) {
    return selectedAlert?.gutterItemId === alert?.gutterItemId;
  }
  return alert?.gutterItemChildren
    ?.map((child) => child?.gutterItemId)
    ?.includes(selectedAlert?.gutterItemId);
};

const ParentAlert = ({ alert }) => {
  const dispatch = useDispatch();
  const {
    isLeftDrawerCollapsed,
    showParentChildren,
    handleLeftDrawerCollapseForParentAlert,
  } = useLeftDrawerContext();
  const { home } = useSelector((state) => state);
  const selectedAlert = home?.selectedAlert;

  useEffect(() => {
    if (selectedAlert?.gutterItemId === alert?.gutterItemId) {
      dispatch(HomeActions.setSelectedAlertParent(selectedAlert));
    }
  }, [alert, dispatch, selectedAlert]);

  return (
    <StyledParentAlert
      title={alert?.displayTitle}
      data-testid="parent-alert"
      role="tab"
      onClick={() =>
        handleLeftDrawerCollapseForParentAlert(alert, selectedAlert, dispatch)
      }
      selected={isSelected(alert, selectedAlert)}
      aria-label={alert?.displayTitle}
      aria-selected={isSelected(alert, selectedAlert)}
      data-tracking={`Parent-${alert?.displayTitle}`}
      aria-expanded={showParentChildren[alert?.displayTitle]}
      isLeftDrawerCollapsed={isLeftDrawerCollapsed}
    >
      <StatusIcon
        type={ALERT_TYPES.PARENT}
        status={alert?.ruleResultStatusId}
      />
      <Text
        as="h3"
        marginLeft="0.5rem"
        opacity={isLeftDrawerCollapsed ? 0 : 1}
        whiteSpace="nowrap"
        transform={isLeftDrawerCollapsed ? 'translateX(-8px)' : 'translateX(0)'}
        transition="all 0.5s ease-in-out"
      >
        {truncate(alert?.displayTitle, {
          length: 28,
        })}
      </Text>
      <Box flexGrow="1"></Box>
      {alert?.gutterItemChildren?.length > 0 && (
        <StyledSortDownIcon
          showParentChildren={showParentChildren[alert?.displayTitle]}
          icon={faCaretUp}
        />
      )}
    </StyledParentAlert>
  );
};

ParentAlert.propTypes = {
  alert: PropTypes.object,
  showChildren: PropTypes.bool,
  setShowChildren: PropTypes.func,
};

export default ParentAlert;
