import PropTypes from 'prop-types';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { useMemo } from 'react';
import { Radio } from '@chakra-ui/react';

export default function RadioButton({
  onBlur,
  onFocus,
  onChange,
  value,
  id,
  name,
  groupName,
  label,
  sx,
  ...props
}) {
  const radioGroupName = useMemo(() => {
    const path = name.replace(/\..+$/, '');
    return `${path}.${groupName}`;
  }, [groupName, name]);

  const { input, meta } = useFieldApi({
    ...props,
    name: radioGroupName,
  });

  return (
    <Radio
      {...input}
      id={id}
      value={value}
      isChecked={input.value == value}
      onBlur={(e) => {
        input.onBlur(e);
        onBlur?.(e);
      }}
      onFocus={(e) => {
        input.onFocus(e);
        onFocus?.(e);
      }}
      onChange={(e) => {
        input.onChange(e);
        onChange?.(e);
      }}
      isInvalid={meta.invalid}
      sx={sx}
    >
      {label}
    </Radio>
  );
}
RadioButton.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
