import Grid from 'common/components/Grids/Grid';
import Text from 'common/components/Texts/Text';
import { formatDateStringToDateTime } from 'common/util/format';
import PropTypes from 'prop-types';

const displayContent = (value) => {
  if (!value || value.length == 0) return 'none';

  return 'initial';
};

const PrintInfoHeader = ({
  rows,
  columns,
  borrowerName,
  loanNumber,
  productType,
  ausMethod,
  employerName,
  startDate,
  throughDate,
  rentalAddress,
}) => {
  const rowTemplate = `repeat(${rows}, 1fr)`;
  const columnsTemplate = `repeat(${columns}, 1fr)`;
  return (
    <Grid
      id="print-only-info"
      templateColumns={columnsTemplate}
      templateRows={rowTemplate}
      gap="1rem"
      d="none"
      mb="2rem"
      maxWidth="100%"
    >
      <Text as="h2" fontSize="sm">
        <b>Loan Number:</b> {loanNumber}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(borrowerName)}>
        <b>Borrower Name:</b> {borrowerName}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(ausMethod)}>
        <b>AUS Method: </b> {ausMethod}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(productType)}>
        <b>Product Type: </b> {productType}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(rentalAddress)}>
        <b>Rental Address: </b> {rentalAddress}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(employerName)}>
        <b>Employer Name: </b>
        {employerName}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(startDate)}>
        <b>Start Date:</b> {formatDateStringToDateTime(startDate)}
      </Text>
      <Text as="h2" fontSize="sm" d={displayContent(throughDate)}>
        <b>Through Date:</b> {formatDateStringToDateTime(throughDate)}
      </Text>
    </Grid>
  );
};

PrintInfoHeader.propTypes = {
  rows: PropTypes.number,
  columns: PropTypes.number,
  borrowerName: PropTypes.any,
  loanNumber: PropTypes.any,
  productType: PropTypes.any,
  ausMethod: PropTypes.any,
  employerName: PropTypes.any,
  startDate: PropTypes.any,
  throughDate: PropTypes.any,
  rentalAddress: PropTypes.any,
};

export default PrintInfoHeader;
