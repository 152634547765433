﻿import React from 'react';
import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import Card from './styles/Card';
import LeftCell from './styles/LeftCell';
import HistoricalCalculationDataModel from '../model/HistoricalCalculationDataModel';
import { formatCurrency } from '../../../common/util/format';

const IncomeCalculationCard = ({ summary, onClick }) => {
  return (
    <Card onClick={onClick}>
      <LeftCell>
        <Text fontSize="md" fontWeight={500}>
          {summary.getCalculatorName()}
        </Text>
        <Text fontWeight={500} fontSize="xs">
          {summary.getUsername()}
        </Text>
        <Text fontSize="xs">{summary.getCalculationDateTime()}</Text>
      </LeftCell>
      <Text w="50%" fontSize="2xl" fontWeight={500} textAlign="center">
        {formatCurrency(summary.getCalculatedMonthlyAmount())}
      </Text>
      <Text fontSize="xl">
        <FontAwesomeIcon icon={faChevronRight} />
      </Text>
    </Card>
  );
};
IncomeCalculationCard.propTypes = {
  summary: PropTypes.instanceOf(HistoricalCalculationDataModel).isRequired,
  onClick: PropTypes.func,
};

export default IncomeCalculationCard;
