import { ModalCloseButton as ChakraModalCloseButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalCloseButton = ({ ...props }) => {
  return <ChakraModalCloseButton {...props} />;
};

export default ModalCloseButton;

ModalCloseButton.propTypes = {
  props: PropTypes.object,
};
