import PropTypes from 'prop-types';
// import { ListItem as MuiListItem } from '@mui/material';
import { ListItemText as MuiListItemText } from '@mui/material';

export default function ListItemText({ primary, ...props }) {
  return <MuiListItemText primary={primary} {...props} />;
}

ListItemText.propTypes = {
  primary: PropTypes.string,
  props: PropTypes.object,
};
