import PropTypes from 'prop-types';
import { Box, Flex, SimpleGrid, Text } from 'common/components';
import BreakdownValueWithChild from '../styles/BreakdownValueWithChild';
import TrendingAnalysis from './TrendingAnalysis';
import { FormTypes } from 'pages/IncomeCalculator/enums';
import { formatCurrency } from 'common/util/format';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import BreakdownContainer from '../BreakdownContainer';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import { FORMULA1065 as FORMULA } from 'pages/IncomeCalculator/constants';
import lang from '../language.json';

const k1CombineBox = (selectedResult, currentYear) => {
  return (
    (parseFloat(
      selectedResult
        .getInput(
          FormTypes.ScheduleK1Form1065,
          currentYear,
          'ordinaryBusinessIncome',
        )
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(
          FormTypes.ScheduleK1Form1065,
          currentYear,
          'netRentalIncomeLoss',
        )
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(
          FormTypes.ScheduleK1Form1065,
          currentYear,
          'otherNetRentalIncomeLoss',
        )
        ?.getValue(),
    ) || 0)
  );
};

const totalAddbacksAndDeductions = (selectedResult, currentYear) => {
  return (
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1065, currentYear, 'depletion')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1065, currentYear, 'depreciation')
        ?.getValue(),
    ) || 0) +
    (parseFloat(
      selectedResult
        .getInput(FormTypes.Form1065, currentYear, 'amortizationCasualtyLoss')
        ?.getValue(),
    ) || 0) -
    ((parseFloat(
      selectedResult
        .getInput(FormTypes.Form1065, currentYear, 'ordinaryIncome')
        ?.getValue(),
    ) || 0) +
      (parseFloat(
        selectedResult
          .getInput(FormTypes.Form1065, currentYear, 'otherIncome')
          ?.getValue(),
      ) || 0) +
      (parseFloat(
        selectedResult
          .getInput(
            FormTypes.Form1065,
            currentYear,
            'mortgagesNotesBondsPayableInLessThanOneYear',
          )
          ?.getValue(),
      ) || 0) +
      (parseFloat(
        selectedResult
          .getInput(FormTypes.Form1065, currentYear, 'travelAndEntertainment')
          ?.getValue(),
      ) || 0))
  );
};

const annualIncome = (selectedResult, currentYear) => {
  return (
    parseFloat(
      selectedResult
        .getInputs()
        .filter(
          (field) =>
            field.getFormId() === FormTypes.Form1065 &&
            (!currentYear || field.getFiscalYear() === currentYear) &&
            (field.getFieldName() === 'annualIncome' ||
              field.getFieldId() === 'annualIncome'),
        )
        .find((field) => field.getValue() !== '')
        ?.getValue(),
    ) || 0
  );
};

const BreakdownView1065 = ({
  currentYear,
  selectedResult,
  showFullBreakdown,
}) => {
  const distributionsA = parseFloat(
    selectedResult
      .getInput(FormTypes.ScheduleK1Form1065, currentYear, 'distributions')
      ?.getValue() || 0,
  );
  const distributionsB = parseFloat(
    selectedResult
      .getInput(FormTypes.ScheduleK1Form1065, currentYear, 'distributions2')
      ?.getValue() || 0,
  );
  const distributions = distributionsA + distributionsB;

  const {
    FIELDS: { ['1065']: FIELDS },
  } = lang;

  const useK1 =
    selectedResult
      .getInput(45, 0, 'didPassLiquidityTest')
      ?.getValue()
      ?.toLowerCase() === 'true';

  const k1Combined = k1CombineBox(selectedResult, currentYear);

  return (
    <BreakdownYearContainer title={currentYear}>
      <BreakdownValueWithChild
        name={FIELDS.WAGES}
        value={
          parseFloat(
            selectedResult
              .getInput(FormTypes.W2, currentYear, 'medicareWages')
              ?.getValue(),
          ) || 0
        }
        isCurrency={true}
        childrenData={[
          {
            name: FIELDS.W2BOX5,
            value:
              parseFloat(
                selectedResult
                  .getInput(FormTypes.W2, currentYear, 'medicareWages')
                  ?.getValue(),
              ) || 0,
            sign:
              (parseFloat(
                selectedResult
                  .getInput(FormTypes.W2, currentYear, 'medicareWages')
                  ?.getValue(),
              ) || 0) < 0
                ? '-'
                : '+',
          },
        ]}
        fontWeight="bold"
      />
      {showFullBreakdown ? (
        <>
          {useK1 ? (
            <BreakdownValueWithChild
              name={FIELDS.K1BOXES}
              value={k1Combined}
              isCurrency={true}
              childrenData={[
                {
                  name: FIELDS.KBOX1,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'ordinaryBusinessIncome',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'ordinaryBusinessIncome',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
                {
                  name: FIELDS.KBOX2,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'netRentalIncomeLoss',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'netRentalIncomeLoss',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
                {
                  name: FIELDS.KBOX3,
                  value:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'otherNetRentalIncomeLoss',
                        )
                        ?.getValue(),
                    ) || 0,
                  sign:
                    (parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'otherNetRentalIncomeLoss',
                        )
                        ?.getValue(),
                    ) || 0) < 0
                      ? '-'
                      : '+',
                },
              ]}
              fontWeight="bold"
            />
          ) : (
            <BreakdownValueWithChild
              name={FIELDS.DISTRIBUTIONS}
              value={distributions}
              isCurrency={true}
              childrenData={[
                {
                  name: FIELDS.DISTRIBUTIONS19A,
                  value: distributionsA,
                  sign: distributionsA < 0 ? '-' : '+',
                },
                {
                  name: FIELDS.DISTRIBUTIONS19B,
                  value: distributionsB,
                  sign: distributionsB < 0 ? '-' : '+',
                },
              ]}
              fontWeight="bold"
            />
          )}
          <BreakdownValueWithChild
            name={FIELDS.GUARANTEED_PAYMENTS}
            value={
              parseFloat(
                selectedResult
                  .getInput(
                    FormTypes.ScheduleK1Form1065,
                    currentYear,
                    'guaranteedPayments',
                  )
                  ?.getValue(),
              ) || 0
            }
            isCurrency={true}
            childrenData={[
              {
                name: FIELDS.K1BOX4C,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1Form1065,
                        currentYear,
                        'guaranteedPayments',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1Form1065,
                        currentYear,
                        'guaranteedPayments',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.ADDBACK_DEDUCTIONS}
            value={totalAddbacksAndDeductions(selectedResult, currentYear)}
            isCurrency={true}
            childrenData={[
              {
                name: FIELDS.LINE4,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'ordinaryIncome',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'ordinaryIncome',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.LINE7,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'otherIncome')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'otherIncome')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.LINE16C,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'depreciation')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'depreciation')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
              {
                name: FIELDS.LINE17,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'depletion')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'depletion')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
              {
                name: FIELDS.LLINE16COLUMND,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'mortgagesNotesBondsPayableInLessThanOneYear',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'mortgagesNotesBondsPayableInLessThanOneYear',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.M1LINE4B,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'travelAndEntertainment',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'travelAndEntertainment',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '+'
                    : '-',
              },
              {
                name: FIELDS.AMORTIZATION,
                value: parseFloat(
                  selectedResult
                    .getInput(
                      FormTypes.Form1065,
                      currentYear,
                      'amortizationCasualtyLoss',
                    )
                    ?.getValue() || 0,
                ),
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'amortizationCasualtyLoss',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.PERCENTOWNERSHIP}
            value={
              parseFloat(
                selectedResult
                  .getInput(
                    FormTypes.ScheduleK1Form1065,
                    currentYear,
                    'percentageOwnership',
                  )
                  ?.getValue(),
              ) * 100 || 0
            }
            isCurrency={false}
            childrenData={[
              {
                name: FIELDS.K1BOXJ,
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1Form1065,
                        currentYear,
                        'percentageOwnership',
                      )
                      ?.getValue(),
                  ) * 100 || 0,
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={lang.MONTHLY_CALC}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.W2, currentYear, 'medicareWages')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.W2, currentYear, 'medicareWages')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
              {
                name: '',
                value: useK1 ? k1Combined : distributions,
                sign: (useK1 ? k1Combined : distributions) < 0 ? '-' : '+',
              },
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1Form1065,
                        currentYear,
                        'guaranteedPayments',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.ScheduleK1Form1065,
                        currentYear,
                        'guaranteedPayments',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '+',
              },
              {
                name: '',
                value: totalAddbacksAndDeductions(selectedResult, currentYear),
                sign:
                  totalAddbacksAndDeductions(selectedResult, currentYear) < 0
                    ? '-'
                    : '+',
                monthlyIncome: {
                  show: true,
                  percentValue:
                    parseFloat(
                      selectedResult
                        .getInput(
                          FormTypes.ScheduleK1Form1065,
                          currentYear,
                          'percentageOwnership',
                        )
                        ?.getValue(),
                    ) || 0,
                  totalSumValue: annualIncome(selectedResult, currentYear),
                },
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={currentYear + ' Calculated Monthly Income'}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value: annualIncome(selectedResult, currentYear) / 12,
                sign: annualIncome(selectedResult, currentYear) < 0 ? '-' : '',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.PARTNERSHIP_SUBTOTAL}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'subTotal')
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(FormTypes.Form1065, currentYear, 'subTotal')
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '',
              },
            ]}
            fontWeight="bold"
          />
          <BreakdownValueWithChild
            name={FIELDS.BORROWER_SHARE_OF_PARTNER_SHIPINCOME}
            isCurrency={true}
            childrenData={[
              {
                name: '',
                value:
                  parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'borrowerShareOfIncomeOrLoss',
                      )
                      ?.getValue(),
                  ) || 0,
                sign:
                  (parseFloat(
                    selectedResult
                      .getInput(
                        FormTypes.Form1065,
                        currentYear,
                        'borrowerShareOfIncomeOrLoss',
                      )
                      ?.getValue(),
                  ) || 0) < 0
                    ? '-'
                    : '',
              },
            ]}
            fontWeight="bold"
          />
        </>
      ) : (
        <Box>
          <Flex fontWeight="bold">
            <Text fontSize="sm">{lang.MONTHLY_CALC}</Text>
          </Flex>
          <Text
            fontSize="sm"
            marginLeft="10px"
            marginTop="2px"
            style={{ textIndent: '-6px' }}
            pl={4}
          >
            {formatCurrency(
              parseFloat(
                selectedResult
                  .getInput(FormTypes.W2, currentYear, 'medicareWages')
                  ?.getValue(),
              ) || 0,
            )}
            &nbsp; &divide; 12 (Months)
          </Text>
        </Box>
      )}
    </BreakdownYearContainer>
  );
};
BreakdownView1065.propTypes = {
  currentYear: PropTypes.number.isRequired,
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  showFullBreakdown: PropTypes.bool.isRequired,
};

const Form1065CalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const years = selectedResult
    .getFiscalYears()
    .filter((year) => year !== 0)
    .sort((a, b) => b - a);

  const currentYear = years[0];

  const isPositiveTrend = selectedResult
    .getInput(FormTypes.Form1065Output, 0, 'isPositiveTrend')
    ?.getValue()
    ?.toLowerCase();

  const formula = selectedResult.getFormulas()[0];

  const OneYearBreakdown = () => {
    return (
      <SimpleGrid
        w={`${50 * Math.min(years.length, 2)}%`}
        columns={Math.min(2, years.length)}
        spacingX={0}
        spacingY={8}
        pb={4}
      >
        <BreakdownView1065
          currentYear={currentYear}
          selectedResult={selectedResult}
          showFullBreakdown={true}
        />
      </SimpleGrid>
    );
  };

  const TwoYearBreakdown = () => {
    const priorYear = years[1];

    return (
      <>
        {(isPositiveTrend !== 'false' && (
          <SimpleGrid
            w={`${50 * Math.min(years.length, 2)}%`}
            columns={Math.min(2, years.length)}
            spacingX={0}
            spacingY={8}
            pb={4}
          >
            <Box pr={4}>
              <BreakdownView1065
                currentYear={currentYear}
                selectedResult={selectedResult}
                showFullBreakdown={true}
              />
            </Box>
            <BreakdownView1065
              currentYear={priorYear}
              selectedResult={selectedResult}
              showFullBreakdown={true}
            />
          </SimpleGrid>
        )) || (
          <>
            {(formula.getFormulaId() === FORMULA.OneYrNegBreakdownFormulaId ||
              formula.getFormulaId() ===
                FORMULA.TwoYrNegBreakdownFormulaId) && (
              <SimpleGrid
                w={`${50 * Math.min(years.length, 2)}%`}
                columns={Math.min(2, years.length)}
                spacingX={0}
                spacingY={8}
                pb={4}
              >
                <Box pr={4}>
                  <BreakdownView1065
                    currentYear={currentYear}
                    selectedResult={selectedResult}
                    showFullBreakdown={false}
                  />
                </Box>
                <BreakdownView1065
                  currentYear={priorYear}
                  selectedResult={selectedResult}
                  showFullBreakdown={false}
                />
              </SimpleGrid>
            )}
            {formula.getFormulaId() === FORMULA.BusinessIncomeFormulaId && (
              <SimpleGrid
                w={`${50 * Math.min(years.length, 2)}%`}
                columns={Math.min(2, years.length)}
                spacingX={0}
                spacingY={8}
                pb={4}
              >
                <Box pr={4}>
                  <BreakdownView1065
                    currentYear={currentYear}
                    selectedResult={selectedResult}
                    showFullBreakdown={true}
                  />
                </Box>

                <BreakdownView1065
                  currentYear={priorYear}
                  selectedResult={selectedResult}
                  showFullBreakdown={true}
                />
              </SimpleGrid>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <BreakdownContainer
      header="1065 Calculation Breakdown"
      onNewWindow={onNewWindow}
      hideAccordion={hideAccordion}
    >
      <Box>
        {formula && (
          <Text pb={4} fontSize="sm">
            <Text as="span" fontWeight="bold">
              Monthly Income Equation:&nbsp;
            </Text>
            {formula.getFormula()}
          </Text>
        )}
        {years.length === 1 && <OneYearBreakdown />}

        {years.length >= 2 && (
          <>
            <TwoYearBreakdown />
            <TrendingAnalysis selectedResult={selectedResult} />
          </>
        )}
      </Box>
    </BreakdownContainer>
  );
};
Form1065CalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};

export default Form1065CalculationBreakdown;
