import { DrawerCloseButton as ChakraDrawerCloseButton } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const DrawerCloseButton = ({ ...props }) => {
  return <ChakraDrawerCloseButton {...props} />;
};

export default DrawerCloseButton;

DrawerCloseButton.propTypes = {
  props: PropTypes.object,
};
