import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CREDIT } from 'common/constants';
import { getCreditReportInfo } from 'pages/Liabilities/redux/thunks';
import Link from 'common/components/Link';
import Box from 'common/components/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const CreditReportLink = ({ marginBottom }) => {
  const creditReports = useSelector(({ credit }) => {
    return credit.creditData.creditReports;
  });

  const dispatch = useDispatch();
  const launchCreditReport = (creditReportData) => {
    if (creditReportData?.underwritingMethod === CREDIT.LOAN_PRODUCT_ADVISOR) {
      const pdfUrl = atob(creditReportData.documentBase64);
      window.open(`${window.globalConfig.ease.baseUrl}/${pdfUrl}`);
    } else if (
      creditReportData?.underwritingMethod === CREDIT.DESKTOP_UNDERWRITER
    ) {
      const pageContent = atob(creditReportData.documentBase64);
      const newWindow = window.open();
      const pre = document.createElement('pre');
      pre.textContent = pageContent;
      newWindow.document.body.appendChild(pre);
    }
  };

  const getCreditReportClicked = () => {
    if (!creditReports?.data?.documentBase64) {
      dispatch(async (dispatch, getState) => {
        await getCreditReportInfo()(dispatch, getState);
        const {
          credit: { creditData },
        } = getState();
        launchCreditReport(creditData?.creditReports?.data);
      });
    } else {
      launchCreditReport(creditReports?.data);
    }
  };

  return (
    <Box mb={marginBottom ?? '12px'}>
      <Link onClick={getCreditReportClicked} color="blue.600">
        Credit Report <FontAwesomeIcon icon={faExternalLinkAlt} />
      </Link>
      <br />
    </Box>
  );
};

CreditReportLink.propTypes = {
  marginBottom: PropTypes.string,
  getCreditReport: PropTypes.func,
  creditReports: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
    data: PropTypes.shape({
      documentBase64: PropTypes.string.isRequired,
      underwritingMethod: PropTypes.string.isRequired,
    }),
  }),
};

export default CreditReportLink;
