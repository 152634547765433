import { Box, Center, Flex, Text } from 'common/components';

const Oops = (props) => (
  <Box
    {...props}
    data-testid="oopsError"
    sx={{
      maxHeight: 'calc(100% - 259px)', //100% of the container minus the header and footer
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Flex flexDirection="column">
      <Center>
        <Text
          as="h2"
          sx={{
            fontSize: '6xl',
            fontWeight: 'bold',
            color: 'blue.700',
            mb: '1.5rem',
          }}
        >
          OOPS!
        </Text>
      </Center>
      <Center>
        <Text as="h3" sx={{ fontSize: '1xl' }}>
          An unexpected error has occurred.
        </Text>
      </Center>
      <Center>
        <Text as="h3" sx={{ fontSize: '1xl' }}>
          Try again, or submit a CR for additional assistance if the error
          persists.
        </Text>
      </Center>
    </Flex>
  </Box>
);

export default Oops;
