import PropTypes from 'prop-types';
import { Text, SimpleGrid } from 'common/components';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import BreakdownContainer from '../BreakdownContainer';
import AnnualBreakdown from './AnnualBreakdown';
import TrendingAnalysis from './TrendingAnalysis';
import MonthlyIncome from './MonthlyIncome';

const Form1120CalculationBreakdown = ({
  selectedResult,
  onNewWindow,
  hideAccordion = false,
}) => {
  const years = selectedResult
    .getFiscalYears()
    .filter((year) => year !== 0)
    .sort((a, b) => b - a);

  const formula = selectedResult?.getFormulas()[0];

  return (
    <BreakdownContainer
      header="1120 Calculation Breakdown"
      onNewWindow={onNewWindow}
      hideAccordion={hideAccordion}
    >
      <Text pb={4} fontSize="sm">
        <Text as="span" fontWeight="bold">
          Monthly Income Equation:&nbsp;
        </Text>
        {formula.getFormula()}
      </Text>
      <SimpleGrid
        w={`${50 * Math.min(years.length, 2)}%`}
        columns={Math.min(2, years.length)}
        spacing={8}
        pb={4}
      >
        {years.map((currentYear, i) => (
          <AnnualBreakdown
            selectedResult={selectedResult}
            currentYear={currentYear}
            formulaId={formula.getFormulaId()}
            key={i}
          />
        ))}
      </SimpleGrid>
      <MonthlyIncome
        selectedResult={selectedResult}
        currentYear={years[0]}
        formulaId={formula.getFormulaId()}
      />
      {years.length > 1 && <TrendingAnalysis selectedResult={selectedResult} />}
    </BreakdownContainer>
  );
};
Form1120CalculationBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  onNewWindow: PropTypes.func,
  hideAccordion: PropTypes.bool,
};

export default Form1120CalculationBreakdown;
