import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import Text from 'common/components/Texts/Text';
import { useForm } from 'react-hook-form';
import { omit, isEqual } from 'lodash';
import genericPageKeys from 'pages/Generic/keys';

// Local
import Button from 'common/components/Button';
import PDFViewer from './PDFViewer';
import ClassifyFormContent from './ClassifyFormContent';
import Spinner from 'common/components/Spinner';
import Oops from 'common/components/Oops';
import {
  saveDocumentClassificationAssociations,
  saveDocumentClassificationAssociationsGoNextDoc,
  getDocumentPdf,
  getDocumentAssociations,
} from './redux/thunks';
import { CLASSIFICATION } from './constants';
import { API_STATUS, USER_ROLE } from 'common/constants';
import { LOADER_MESSAGE, UNCLASSIFIED_WARNING } from './language.json';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';

// Styles
import {
  StyledClassifyRightContainer,
  StyledClassifyHeader,
  StyledClassifyBody,
  StyledClassifyFooter,
} from './StyledComponents/ClassifyRightContainer.styles';

const ClassifyRightContainer = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    errors,
    watch,
    setValue,
  } = useForm();

  const {
    user: { role: userRole, isUnderwriter },
    navigationItems,
    selectedCategory,
  } = useSelector(({ home }) => home);

  const isGivenRole = (role) => {
    if (Array.isArray(userRole)) {
      return userRole.includes(role);
    } else if (typeof userRole === 'string') {
      return userRole === role;
    }
  };

  const {
    selectedDocument,
    selectedPDF,
    selectedDocumentAssociations,
    documentTypesAndCategories: {
      status: getDocumentTypesAndCategoriesAPICallStatus,
    },
    documentItems: { status: getClassifiedDocumentsAPICallStatus },
    documentPDFList: { status: getDocumentPdfAPICallStatus },
    documentAssociationsList: { status: getDocumentAssociationsAPICallStatus },
    saveDocumentAssociations: { status: saveDocumentAssociationsAPICallStatus },
    removeDocuments: { status: hideDocsAPICallStatus },
  } = useSelector(({ classifyDocumentsV2 }) => classifyDocumentsV2);

  const isCategoryReadOnlyPermission = navigationItems?.categories?.find(
    (category) => category?.navigationCategoryId === selectedCategory,
  )?.isReadOnlyPermission;

  const [btnClickedName, setBtnClickedName] = useState(null);

  const isLoading = [
    getDocumentTypesAndCategoriesAPICallStatus,
    getDocumentAssociationsAPICallStatus,
    getDocumentPdfAPICallStatus,
    getClassifiedDocumentsAPICallStatus,
  ].includes(API_STATUS.GETTING);

  const isSaving =
    saveDocumentAssociationsAPICallStatus === API_STATUS.UPDATING;

  const hasError = [
    getDocumentTypesAndCategoriesAPICallStatus,
    getDocumentAssociationsAPICallStatus,
    getDocumentPdfAPICallStatus,
    hideDocsAPICallStatus,
  ].includes(API_STATUS.ERROR);

  const hasClassificationChanged = (selectedDocument, formData) =>
    selectedDocument.categoryName !== formData.documentCategory.value ||
    +selectedDocument.documentTypeId !== formData.documentType.value;

  // removes label property(from react-select inputs) and normalizes formData for the save API call
  const normalizeAssociations = (associations) => {
    let normalizedAssociations = {
      borrowerAssociations: [],
      assetDocumentAssociations: [],
      incomeDocumentAssociations: [],
      liabilityDocumentAssociations: [],
      propertyAssociations: [],
    };
    for (const [association, associationSelections] of Object.entries(
      associations,
    )) {
      normalizedAssociations = {
        ...normalizedAssociations,
        [association]: associationSelections.map(
          (selection) => selection.value,
        ),
      };
    }
    return normalizedAssociations;
  };

  async function invalidateYamlCaches() {
    queryClient.invalidateQueries({ queryKey: genericPageKeys.all });
  }

  const handleDocumentAction = (formData, actionType) => {
    if (
      formData.documentCategory.value === CLASSIFICATION.unclassified &&
      formData.documentType.value !== CLASSIFICATION.notListed
    ) {
      setError(CLASSIFICATION.docCategory, {
        type: CLASSIFICATION.unknown,
        message: UNCLASSIFIED_WARNING,
      });
      return;
    }

    const { documentId, receivedMailItemId } = selectedDocument;
    const userSelectedAssociations = normalizeAssociations(
      omit(formData, [CLASSIFICATION.docCategory, CLASSIFICATION.docType]),
    );

    const classificationChanged = hasClassificationChanged(
      selectedDocument,
      formData,
    );
    const associationChanged = !isEqual(selectedDocumentAssociations, {
      documentId,
      ...userSelectedAssociations,
    });

    const saveDocumentAssociationsPayload = {
      classificationChanged,
      documentId,
      documentTypeId: formData.documentType.value,
      receivedMailItemId,
      userId: 0,
      previousDocumentTypeId: parseInt(selectedDocument.documentTypeId),
      ...userSelectedAssociations,
    };

    invalidateYamlCaches();
    // Dispatch call based on the actionType
    if (actionType === 'continue') {
      dispatch(
        saveDocumentClassificationAssociations(
          associationChanged,
          classificationChanged,
          saveDocumentAssociationsPayload,
        ),
      );
    } else if (actionType === 'next') {
      dispatch(
        saveDocumentClassificationAssociationsGoNextDoc(
          associationChanged,
          classificationChanged,
          saveDocumentAssociationsPayload,
        ),
      );
    }
  };

  const handleContinueClick = (formData) => {
    handleDocumentAction(formData, 'continue');
    setBtnClickedName('continue');
  };

  const handleNextClick = (formData) => {
    handleDocumentAction(formData, 'next');
    setBtnClickedName('next');
  };

  useEffect(() => {
    if (selectedDocument) {
      const { documentId, documentTypeId } = selectedDocument;
      dispatch(getDocumentPdf(documentId));
      dispatch(getDocumentAssociations(documentId, +documentTypeId));
      setBtnClickedName(null);
    }
  }, [dispatch, selectedDocument]);

  if (isLoading)
    return (
      <Spinner
        loadingMessage={LOADER_MESSAGE.DEFAULT}
        data-testid="loadingComponent"
      />
    );

  if (hasError) return <Oops />;

  return (
    <StyledClassifyRightContainer
      as="form"
      data-testid="classifyRightContainer"
      onSubmit={handleSubmit(handleContinueClick)}
      // disable write access based on permissions
      disabled={
        isCategoryReadOnlyPermission || isGivenRole(USER_ROLE.ACCOUNT_EXECUTIVE)
      }
    >
      <StyledClassifyHeader as="header">
        <Text as="h2" id="documentTitle">
          {selectedDocument?.documentTypeName}
        </Text>
        <Form1003PopUpButton />
      </StyledClassifyHeader>
      <StyledClassifyBody as="section">
        <PDFViewer selectedPDFBlobUrl={selectedPDF} />
        <ClassifyFormContent
          rhfProps={{ clearErrors, control, errors, setValue, watch }}
        />
      </StyledClassifyBody>
      <StyledClassifyFooter as="footer">
        {isUnderwriter && (
          <Button
            isPrimary={false}
            id="nextBtn"
            type="button"
            dataTestid="nextBtn"
            mr="1rem"
            onClick={handleSubmit(handleNextClick)}
            isLoading={isSaving && btnClickedName === 'next'}
            loadingText="Saving..."
            // disable write access for AE's
            isDisabled={isGivenRole(USER_ROLE.ACCOUNT_EXECUTIVE)}
          >
            Save and Next Document
          </Button>
        )}
        <Button
          isPrimary
          id="continueBtn"
          dataTestid="continueBtn"
          type="submit"
          isLoading={isSaving && btnClickedName === 'continue'}
          loadingText="Saving..."
          // disable write access for AE's
          isDisabled={isGivenRole(USER_ROLE.ACCOUNT_EXECUTIVE)}
        >
          {isUnderwriter ? 'Save and Continue' : 'Continue'}
        </Button>
      </StyledClassifyFooter>
    </StyledClassifyRightContainer>
  );
};

export default ClassifyRightContainer;
