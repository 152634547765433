import { DrawerBody as ChakraDrawerBody } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const DrawerBody = ({ children, ...props }) => {
  return <ChakraDrawerBody {...props}>{children}</ChakraDrawerBody>;
};

export default DrawerBody;

DrawerBody.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
