import PropTypes from 'prop-types';
import { Oops, Spinner } from 'common/components';
import useCalculationData from 'pages/IncomeCalculator/Pages/GenericReviewBreakdown/useCalculationData';
import CalculationBreakdown from 'pages/IncomeCalculator/Pages/ReviewBreakdown/QualifyingCalculationBreakdown';

export default function QualifyingIncomeBreakdown({ incomeIdentifier }) {
  const { data, isFetching, isError } = useCalculationData(incomeIdentifier);

  if (isError) {
    return <Oops />;
  }

  if (isFetching || !data) {
    return <Spinner />;
  }

  return (
    <CalculationBreakdown
      selectedResult={data.screenData.calculationData[0]}
      hideAccordion
    />
  );
}
QualifyingIncomeBreakdown.propTypes = {
  incomeIdentifier: PropTypes.object.isRequired,
};
