import { CheckboxGroup as ChakraCheckboxGroup } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CheckboxGroup = ({ children, ...props }) => {
  return <ChakraCheckboxGroup {...props}>{children}</ChakraCheckboxGroup>;
};

export default CheckboxGroup;

CheckboxGroup.propTypes = {
  children: PropTypes.node,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  onChange: PropTypes.func,
  isNative: PropTypes.bool,
};
