import { UnorderedList as ChakraUnorderedList } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const UnorderedList = ({ children, ...props }) => {
  return <ChakraUnorderedList {...props}>{children}</ChakraUnorderedList>;
};

export default UnorderedList;

UnorderedList.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
