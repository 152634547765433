import Center from 'common/components/Center';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';

const BlockedPage = () => {
  return (
    <Center data-testid="blocked-page">
      <Flex flexDirection="column" mt="100px">
        <Center>
          <Text fontSize="6xl" color="blue.700" fontWeight="bold">
            OOPS!
          </Text>
        </Center>
        <Center>
          <Text fontSize="1xl" fontWeight="semibold">
            We&lsquo;re sorry, it looks like you do not have access to view this
            page.
          </Text>
        </Center>
        <Center>
          <Text fontSize="1xl" fontWeight="semibold">
            Head back to the home page or contact your AE if you think this is
            an error.
          </Text>
        </Center>
      </Flex>
    </Center>
  );
};

export default BlockedPage;
