export const TIE_QUESTION_IDS = {
  IsOneOfBorrowersListedAsAnInsuredParty: 1001,
  IsMailingPolicyAddressSameAsInsuredPolicyAddress: 1018,
};

export const TIE_QUESTION_NAMES = Object.entries(TIE_QUESTION_IDS).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {},
);
