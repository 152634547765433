import React from 'react';
import MuiStack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { propsMapper } from 'common/components/PropertyMappers/propsMapper';

const Stack = React.forwardRef(({ children, ...props }, ref) => {
  const updatedProps = propsMapper(props);

  return (
    <MuiStack ref={ref} {...updatedProps}>
      {children}
    </MuiStack>
  );
});

Stack.displayName = 'Stack';

export default Stack;

Stack.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  direction: PropTypes.oneOfType([
    PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
    PropTypes.arrayOf(
      PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
    ),
    PropTypes.object,
  ]),
  divider: PropTypes.node,
  spacing: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  useFlexGap: PropTypes.bool,
};
