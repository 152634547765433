import ChakraText from 'common/componentMapper/Text';
import PropTypes from 'prop-types';
import GenericTooltip from 'common/components/GenericTooltip';

export default function Text({
  text,
  sx,
  as = 'div',
  margin,
  helperText,
  padding,
  role,
  className,
}) {
  // set as <div> since we can set text through markdown
  return (
    <ChakraText
      as={as}
      m={margin}
      p={padding}
      sx={sx}
      role={role}
      className={className}
    >
      {text}
      {!!helperText && <GenericTooltip label={helperText} />}
    </ChakraText>
  );
}

Text.propTypes = {
  text: PropTypes.string,
  as: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  helperText: PropTypes.string,
  sx: PropTypes.object,
  role: PropTypes.string,
  className: PropTypes.string,
};
