import TYPES from './types';
import language from 'pages/Liabilities/language.json';

const updateCreditInfoStarted = () => ({
  type: TYPES.UPDATE_CREDIT_INFO_STARTED,
});
const updateCreditInfoSuccess = (payload) => ({
  type: TYPES.UPDATE_CREDIT_INFO_SUCCESS,
  payload,
});
const updateCreditInfoError = () => ({
  type: TYPES.UPDATE_CREDIT_INFO_ERROR,
  payload: language.CREDIT_INFO_ERROR,
});

const getCreditReportStarted = () => ({
  type: TYPES.GET_CREDIT_REPORT_STARTED,
});
const getCreditReportSuccess = (payload) => ({
  type: TYPES.GET_CREDIT_REPORT_SUCCESS,
  payload,
});
const getCreditReportError = () => ({
  type: TYPES.GET_CREDIT_REPORT_ERROR,
  payload: language.CREDIT_REPORT_ERROR,
});

const getLiabilitiesStarted = () => ({
  type: TYPES.GET_LIABILITIES_STARTED,
});
const getLiabilitiesSuccess = (payload) => ({
  type: TYPES.GET_LIABILITIES_SUCCESS,
  payload,
});
const getLiabilitiesError = () => ({
  type: TYPES.GET_LIABILITIES_ERROR,
  payload: language.LIABILITIES_ERROR,
});

const updateLiabilitiesStarted = () => ({
  type: TYPES.UPDATE_LIABILITIES_STARTED,
});
const updateLiabilitiesSuccess = (payload) => ({
  type: TYPES.UPDATE_LIABILITIES_SUCCESS,
  payload,
});
const updateLiabilitiesError = () => ({
  type: TYPES.UPDATE_LIABILITIES_ERROR,
  payload: language.VIEW_ALL_LIABILITIES_ERROR,
});

const getLiabilitiesVerifyDataStarted = () => ({
  type: TYPES.GET_LIABILITIES_VERIFY_DATA_STARTED,
});
const getLiabilitiesVerifyDataSuccess = (payload) => ({
  type: TYPES.GET_LIABILITIES_VERIFY_DATA_SUCCESS,
  payload,
});
const getLiabilitiesVerifyDataError = () => ({
  type: TYPES.GET_LIABILITIES_VERIFY_DATA_ERROR,
  payload: language.LIABILITIES_VERIFY_DATA_ERROR,
});

const getLiabilitiesAssociateDataStarted = () => ({
  type: TYPES.GET_LIABILITIES_ASSOCIATE_DATA_STARTED,
});
const getLiabilitiesAssociateDataSuccess = (payload) => ({
  type: TYPES.GET_LIABILITIES_ASSOCIATE_DATA_SUCCESS,
  payload,
});
const getLiabilitiesAssociateDataError = () => ({
  type: TYPES.GET_LIABILITIES_ASSOCIATE_DATA_ERROR,
  payload: language.LIABILITIES_ASSOCIATE_DATA_ERROR,
});
const updateLiabilitiesVerifyDataStarted = () => ({
  type: TYPES.UPDATE_LIABILITIES_VERIFY_DATA_STARTED,
});
const updateLiabilitiesVerifyDataSuccess = (payload) => ({
  type: TYPES.UPDATE_LIABILITIES_VERIFY_DATA_SUCCESS,
  payload,
});
const updateLiabilitiesVerifyDataError = () => ({
  type: TYPES.UPDATE_LIABILITIES_VERIFY_DATA_ERROR,
  error: language.UPDATE_LIABILITIES_VERIFY_DATA_ERROR,
});

const updateLiabilitiesAssociateDataStarted = () => ({
  type: TYPES.UPDATE_LIABILITIES_ASSOCIATE_DATA_STARTED,
});
const updateLiabilitiesAssociateDataSuccess = (payload) => ({
  type: TYPES.UPDATE_LIABILITIES_ASSOCIATE_DATA_SUCCESS,
  payload,
});
const updateLiabilitiesAssociateDataError = () => ({
  type: TYPES.UPDATE_LIABILITIES_ASSOCIATE_DATA_ERROR,
  error: language.UPDATE_LIABILITIES_ASSOCIATE_DATA_ERROR,
});

const updateCreditValidationResultsStarted = () => ({
  type: TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_STARTED,
});
const updateCreditValidationResultsSuccess = (payload) => ({
  type: TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_SUCCESS,
  payload,
});
const updateCreditValidationResultsError = () => ({
  type: TYPES.UPDATE_CREDIT_VALIDATION_RESULTS_ERROR,
  payload: language.CREDIT_VALIDATION_RESULTS_ERROR,
});
const updateSaveCreditAnswersStarted = () => ({
  type: TYPES.UPDATE_SAVE_CREDIT_ANSWERS_STARTED,
});
const updateSaveCreditAnswersSuccess = (payload) => ({
  type: TYPES.UPDATE_SAVE_CREDIT_ANSWERS_SUCCESS,
  payload,
});
const updateSaveCreditAnswersError = () => ({
  type: TYPES.UPDATE_SAVE_CREDIT_ANSWERS_ERROR,
  payload: language.SAVE_CREDIT_ANSWERS_ERROR,
});
const setIsLoadingDocumentLink = (theBool) => ({
  type: TYPES.SET_IS_LOADING_DOCUMENT_LINK,
  payload: theBool,
});

const clearSaveCreditData = () => ({
  type: TYPES.CLEAR_SAVE_CREDIT_DATA,
});

const calculalateRemainingTermsReset = () => ({
  type: TYPES.CALCULATE_REMAINING_TERMS_RESET,
});
const calculalateRemainingTermsStarted = () => ({
  type: TYPES.CALCULATE_REMAINING_TERMS_STARTED,
});
const calculalateRemainingTermsSuccess = ({ originalTermRemaining }) => ({
  type: TYPES.CALCULATE_REMAINING_TERMS_SUCCESS,
  payload: originalTermRemaining,
});
const calculalateRemainingTermsError = (error) => ({
  type: TYPES.CALCULATE_REMAINING_TERMS_ERROR,
  payload: error,
});

export default {
  updateCreditInfoStarted,
  updateCreditInfoSuccess,
  updateCreditInfoError,
  getCreditReportStarted,
  getCreditReportSuccess,
  getCreditReportError,
  getLiabilitiesStarted,
  getLiabilitiesSuccess,
  getLiabilitiesError,
  getLiabilitiesVerifyDataStarted,
  getLiabilitiesVerifyDataSuccess,
  getLiabilitiesVerifyDataError,
  getLiabilitiesAssociateDataStarted,
  getLiabilitiesAssociateDataSuccess,
  getLiabilitiesAssociateDataError,
  updateLiabilitiesStarted,
  updateLiabilitiesSuccess,
  updateLiabilitiesError,
  updateLiabilitiesVerifyDataStarted,
  updateLiabilitiesVerifyDataSuccess,
  updateLiabilitiesVerifyDataError,
  updateLiabilitiesAssociateDataStarted,
  updateLiabilitiesAssociateDataSuccess,
  updateLiabilitiesAssociateDataError,
  updateCreditValidationResultsStarted,
  updateCreditValidationResultsSuccess,
  updateCreditValidationResultsError,
  updateSaveCreditAnswersStarted,
  updateSaveCreditAnswersSuccess,
  updateSaveCreditAnswersError,
  setIsLoadingDocumentLink,
  clearSaveCreditData,
  calculalateRemainingTermsStarted,
  calculalateRemainingTermsSuccess,
  calculalateRemainingTermsError,
  calculalateRemainingTermsReset,
};
