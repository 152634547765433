import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const StyledSelect = styled(Select)`
  &.react-select-container {
    font-size: ${(props) => props.theme.functions.pixelsToRem(14)};
    // border color and box-shadow
    .react-select__control {
      cursor: pointer;
      border: 1px solid
        ${(props) =>
          !props.hasError
            ? props.theme.colors.gray[200]
            : props.theme.colors.red[600]};
      box-shadow: unset;
      span {
        display: contents;
      }
      &:focus-within {
        border: 1px solid
          ${(props) =>
            !props.hasError
              ? props.theme.colors.blue[600]
              : props.theme.colors.red[600]};
        box-shadow: 0 0 0 1px
          ${(props) =>
            !props.hasError
              ? props.theme.colors.blue[600]
              : props.theme.colors.red[600]};
      }
      // hide the divider
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
      }
      .react-select__value-container--is-multi {
        padding: calc(0.5rem - 1px) 0.5rem 0px;
        .react-select__placeholder {
          color: ${(props) => props.theme.colors.bgGray[900]};
        }
        .react-select__multi-value {
          border-radius: 12px;
          border: 1px solid ${(props) => props.theme.colors.blue[600]};
          background: unset;
          padding: 0 0.5rem;
          margin: 0 0.5rem calc(0.5rem - 1px) 0;
          .react-select__multi-value__label {
            padding-left: unset;
            color: ${(props) => props.theme.colors.gray[900]};
            font-size: ${(props) => props.theme.functions.pixelsToRem(12)};
          }
          .react-select__multi-value__remove {
            &:hover {
              background-color: ${(props) => props.theme.colors.blue[500]};
              .fa-times {
                color: ${(props) => props.theme.colors.white};
              }
            }
          }
        }
      }
    }
    // change options fontsize
    .react-select__menu {
      .react-select__menu-list {
        .react-select__option {
          font-size: ${(props) => props.theme.functions.pixelsToRem(12)};

          &.react-select__option--is-focused {
            background-color: ${(props) => props.theme.colors.blue[50]};
          }
          &.react-select__option--is-selected {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.blue[500]};
            font-weight: 600;
            &:hover {
              color: ${(props) => props.theme.colors.white};
              background-color: ${(props) => props.theme.colors.blue[500]};
            }
          }
        }
      }
    }
  }
`;

const StyledAngleDownIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.gray[900]};
`;

const StyledXMarkIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.gray[900]};
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledAngleDownIcon icon={faAngleDown} />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <StyledXMarkIcon icon={faTimes} />
    </components.MultiValueRemove>
  );
};

const CustomSelect = forwardRef(
  ({ placeholder, isMulti, hasError, ...rest }, ref) => {
    const DefaultPlaceHolder = () => (
      <span>{isMulti ? 'Select one or more' : 'Select'}</span>
    );
    const ValueContainer = (innerProps) => {
      return (
        <span
          data-tracking={rest['data-tracking']}
          data-testid={rest['data-testid']}
        >
          <components.ValueContainer {...innerProps} />
        </span>
      );
    };
    return (
      <StyledSelect
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          ValueContainer,
          MultiValueRemove,
          DropdownIndicator,
        }}
        aria-label={placeholder}
        placeholder={placeholder || <DefaultPlaceHolder />}
        isMulti={isMulti}
        hasError={hasError}
        isClearable={false}
        ref={ref}
        {...rest}
      />
    );
  },
);

CustomSelect.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isMulti: PropTypes.bool,
  hasError: PropTypes.bool,
  'data-tracking': PropTypes.string,
  'data-testid': PropTypes.string,
};

CustomSelect.displayName = 'Select';

export default CustomSelect;
