import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useTheme, useDisclosure } from '@chakra-ui/react';
import Modal from 'common/components/Modals/Modal';
import ModalOverlay from 'common/components/Modals/ModalOverlay';
import ModalContent from 'common/components/Modals/ModalContent';
import ModalHeader from 'common/components/Modals/ModalHeader';
import ModalFooter from 'common/components/Modals/ModalFooter';
import ModalCloseButton from 'common/components/Modals/ModalCloseButton';
import ModalBody from 'common/components/Modals/ModalBody';
import Text from 'common/components/Texts/Text';
import { API_STATUS } from 'common/constants';
import Button from 'common/components/Button';

const ConfirmationDialog = ({
  onClick,
  title,
  message,
  itemName,
  status,
  children,
  buttonId,
  isDeleteDisabled = false,
}) => {
  const { colors, functions } = useTheme();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleConfirmation = () => {
    onClick();
    onClose();
  };

  const isDisabled =
    status === API_STATUS.GETTING || status === API_STATUS.UPDATING;

  return (
    <>
      <Button
        aria-label="openDeleteModalButton"
        backgroundColor={'transparent'}
        _hover={{ bg: 'transparent', cursor: 'pointer' }}
        _active={{ bg: 'transparent' }}
        _focus={{ bg: 'transparent' }}
        onClick={() => onOpen()}
        px={0}
        dataTestid={buttonId}
        isDisabled={isDeleteDisabled}
      >
        {children ? (
          children
        ) : (
          <FontAwesomeIcon icon={faTrash} color={`${colors.red['600']}`} />
        )}
      </Button>
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size="md"
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={6}>
          {!!title && (
            <ModalHeader textTransform="capitalize">{title}</ModalHeader>
          )}

          <ModalCloseButton
            data-testid="hideDocModalClose"
            top="15px"
            right="14px"
            _hover={{ bg: 'transparent', cursor: 'pointer' }}
            _active={{ bg: 'transparent' }}
            _focus={{ bg: 'transparent' }}
          />

          <ModalBody p="unset" mt={5}>
            <Text fontSize={functions.pixelsToRem(14)} textAlign="center">
              {message}
            </Text>
          </ModalBody>

          <ModalFooter
            p="unset"
            mt="24px"
            justifyContent="space-between"
            textTransform="capitalize"
          >
            <Button
              disabled={isDisabled}
              onClick={onClose}
              data-testid="hideDocModalNo"
              id="hideDocModalNo"
              isPrimary={false}
            >
              No, Keep {itemName}
            </Button>

            <Button
              disabled={isDisabled}
              onClick={handleConfirmation}
              data-testid="hideDocModalYes"
              id="hideDocModalYes"
            >
              Yes, Remove {itemName}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
ConfirmationDialog.propTypes = {
  status: PropTypes.oneOf([...Object.values(API_STATUS)]),
  title: PropTypes.string,
  isDeleteDisabled: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  itemName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  buttonId: PropTypes.string,
};

export default ConfirmationDialog;
