const HOI_REVIEW_TYPES = {
  POST_HOI_POLICY_DATA_STARTED: 'POST_HOI_POLICY_DATA_STARTED',
  POST_HOI_POLICY_DATA_SUCCESS: 'POST_HOI_POLICY_DATA_SUCCESS',
  POST_HOI_POLICY_DATA_ERROR: 'POST_HOI_POLICY_DATA_ERROR',
  GET_HOI_POLICY_DATA_STARTED: 'GET_HOI_POLICY_DATA_STARTED',
  GET_HOI_POLICY_DATA_SUCCESS: 'GET_HOI_POLICY_DATA_SUCCESS',
  GET_HOI_POLICY_DATA_ERROR: 'GET_HOI_POLICY_DATA_ERROR',
  GET_DOCUMENT_SNIPPET_STARTED: 'GET_DOCUMENT_SNIPPET_STARTED',
  GET_DOCUMENT_SNIPPET_SUCCESS: 'GET_DOCUMENT_SNIPPET_SUCCESS',
  GET_DOCUMENT_SNIPPET_ERROR: 'GET_DOCUMENT_SNIPPET_ERROR',
};

export default HOI_REVIEW_TYPES;
