import Box from 'common/components/Box';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pages from './Pages';
import { defaultScale } from './constants';

function Area(props) {
  const {
    pdf,
    display,
    scale,
    highlights,
    pageRotations,
    onRenderSuccess,
    onLoadSuccess,
    onRenderError,
    onLoadError,
    onScaleChange,
    pageNumber,
    citations,
    isFullPage,
    showHighLight,
  } = props;
  const [cursor, setCursor] = useState('default');
  const containerRef = useRef(null);
  const containerSizeRef = useRef(null);
  const pageRefs = useRef([]);
  const pageSizeRefs = useRef([]);
  const pageCountRef = useRef(0);
  const scrollBarWidthRef = useRef(0);
  const hasAutoScaledFullPageRef = useRef(false);
  const hasAutoScaledFitPageRef = useRef(false);
  const lastScrolledPageNumberRef = useRef(0);
  const hasFirstPageRenderRef = useRef(false);
  const calculatedPageRotationsRef = useRef([]);

  useEffect(() => {
    pageRefs.current = [];
    pageSizeRefs.current = [];
    hasAutoScaledFullPageRef.current = false;
    hasAutoScaledFitPageRef.current = false;
    lastScrolledPageNumberRef.current = 0;
    hasFirstPageRenderRef.current = false;
  }, [pdf]);

  useEffect(() => {
    if (containerRef.current != null && containerRef.current.offsetWidth > 0) {
      containerSizeRef.current = {
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
        clientWidth: containerRef.current.clientWidth,
        clientHeight: containerRef.current.clientHeight,
      };
    }
    if (pageNumber != 0 && hasFirstPageRenderRef.current == true) {
      scrollToPageNumber(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber != 0 && hasFirstPageRenderRef.current == true) {
      scrollToPageNumber(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights]);

  function handleOnRenderSuccess(e) {
    pageSizeRefs.current[e.pageNumber - 1] = {
      width: e.originalWidth,
      height: e.originalHeight,
    };
    if (pageNumber == e.pageNumber) {
      scrollToPageNumber(pageNumber);
    } else {
      if (
        (pageNumber == 0 || pageNumber == undefined) &&
        e.pageNumber == 1 &&
        hasFirstPageRenderRef.current == false
      ) {
        scrollToPageNumber(1);
      }
    }
    hasFirstPageRenderRef.current = true;
    if (onRenderSuccess) {
      onRenderSuccess(e);
    }
  }

  function handleOnLoadSuccess(e) {
    pageCountRef.current = e.numPages;
    if (onLoadSuccess) {
      onLoadSuccess(e);
    }
  }

  function handleSetPageRef(pageNumber, element) {
    pageRefs.current[pageNumber - 1] = element;
  }

  function handleClearPageRef() {
    pageRefs.current = [];
  }

  function getPageRotation(number) {
    var rot = 0;
    if (
      calculatedPageRotationsRef.current != null &&
      calculatedPageRotationsRef.current.length > 0
    ) {
      var page = calculatedPageRotationsRef.current.find(
        (pr) => pr.pageNumber == number,
      );
      if (page != null) {
        rot = page.rotationAngle;
      }
    }
    return rot;
  }

  function handleSetCalculatedPageRotations(newCalculatedPageRotations) {
    calculatedPageRotationsRef.current = newCalculatedPageRotations;
  }

  function scrollToPageNumber(number) {
    if (number == 0 || number == undefined) {
      return;
    }
    lastScrolledPageNumberRef.current = number;
    try {
      if (
        containerRef.current != null &&
        pageRefs.current != null &&
        pageRefs.current[number - 1] != undefined &&
        pageRefs.current[number - 1] != null
      ) {
        //Find the rotation of the page if it exists
        var rotationAngle = getPageRotation(number);
        //Scrolls to the desired page
        if (highlights != null && highlights.length > 0) {
          if (!hasAutoScaledFitPageRef.current) {
            autoScalePage(number, rotationAngle, isFullPage ?? true);
            hasAutoScaledFitPageRef.current = true;
          }
          let h = highlights[0];
          //Scroll vertically
          var highlightTop =
            pageRefs.current[number - 1].clientHeight * (h.top + h.height / 2);
          var pageTop =
            pageRefs.current[number - 1].offsetTop -
            containerRef.current.offsetTop;
          var centralVerticalPoint = pageTop + highlightTop;
          var scrollTopPosition =
            centralVerticalPoint - containerRef.current.offsetHeight / 2;
          containerRef.current.scrollTop = scrollTopPosition;
          //Scroll horizontally if needed
          const hasHorizontalScroll =
            containerRef.current.scrollWidth > containerRef.current.clientWidth;
          if (hasHorizontalScroll) {
            var highlightLeft =
              pageRefs.current[number - 1].clientWidth * (h.left + h.width / 2);
            var pageLeft =
              pageRefs.current[number - 1].offsetLeft -
              containerRef.current.offsetLeft;
            var centralHorizontalPoint = pageLeft + highlightLeft;
            var scrollLeftPosition =
              centralHorizontalPoint - containerRef.current.offsetWidth / 2;
            containerRef.current.scrollLeft = scrollLeftPosition;
          }
        } else {
          if (
            !hasAutoScaledFullPageRef.current &&
            !hasAutoScaledFitPageRef.current
          ) {
            autoScalePage(number, rotationAngle, isFullPage ?? true);
            hasAutoScaledFullPageRef.current = true;
          }
          //Check if we're not already scrolled to the page
          if (
            containerRef.current.scrollTop <
              pageRefs.current[number - 1].offsetTop -
                containerRef.current.offsetTop ||
            containerRef.current.scrollTop >
              pageRefs.current[number - 1].offsetTop -
                containerRef.current.offsetTop +
                pageRefs.current[number - 1].clientHeight
          ) {
            containerRef.current.scrollTop =
              pageRefs.current[number - 1].offsetTop -
              containerRef.current.offsetTop;
          }
        }
      }
    } catch (error) {
      //TODO: Handle error
    }
  }

  function autoScalePage(number, rotationAngle, fullPage) {
    try {
      //Get the scrollbar width if needed
      if (scrollBarWidthRef.current == 0) {
        scrollBarWidthRef.current = getScrollbarWidth();
      }
      //Update scaling
      var pageWidth = pageSizeRefs.current[number - 1].width;
      var pageHeight = pageSizeRefs.current[number - 1].height + 2; //Add 2 to height for padding
      //Rotated pages should have swapped height/width
      if (rotationAngle == 90 || rotationAngle == 270) {
        pageWidth = pageSizeRefs.current[number - 1].height;
        pageHeight = pageSizeRefs.current[number - 1].width + 2; //Add 2 to height for padding
      }
      var containerWidth,
        containerHeight = 0;
      if (
        containerRef.current != null &&
        containerRef.current.offsetWidth > 0
      ) {
        containerWidth = containerRef.current.offsetWidth;
        containerHeight = containerRef.current.offsetHeight;
      }
      if (containerWidth == 0 || containerHeight == 0) {
        if (containerSizeRef.current != null) {
          containerWidth = containerSizeRef.current.width;
          containerHeight = containerSizeRef.current.height;
        }
      }
      //Calculate new scale
      var newScale = getNewScale(
        pageWidth,
        pageHeight,
        containerWidth,
        containerHeight,
        fullPage,
      );
      //Check if scroll viewer will be displayed, re-scale if necessary
      var reScale = false;
      if (pageHeight * newScale > containerHeight) {
        containerWidth -= scrollBarWidthRef.current;
        reScale = true;
      }
      if (pageWidth * newScale > containerWidth) {
        containerHeight -= scrollBarWidthRef.current;
        reScale = true;
      }
      if (reScale) {
        newScale = getNewScale(
          pageWidth,
          pageHeight,
          containerWidth,
          containerHeight,
          fullPage,
        );
      }
      //Check that scale is not less than or equal to zero
      // IF it is set to default
      if (newScale <= 0) {
        newScale = defaultScale;
      }
      //Update the scale
      if (scale != newScale) {
        onScaleChange(newScale);
      }
    } catch (error) {
      //TODO: Handle Error
    }
  }

  function getNewScale(
    pageWidth,
    pageHeight,
    containerWidth,
    containerHeight,
    isFullPageScaling,
  ) {
    if (isFullPageScaling) {
      // When the aspect ratio of the page is greater than the container,
      // the width of the page will be the limiting factor.
      // So calculate the scale factor based on width
      var containerAspectRatio = containerWidth / containerHeight;
      var pageAspectRatio = pageWidth / pageHeight;
      if (pageAspectRatio > containerAspectRatio) {
        return containerWidth / pageWidth;
      }
      // Otherwise, the height of the page will be the limiting factor.
      // So calculate the scale factor based on height
      else {
        return containerHeight / pageHeight;
      }
    } else {
      //Size the page to fit the width of the viewer, we can auto-scroll to the part of the page with the highlight.
      if (pageHeight > pageWidth) {
        return containerWidth / pageWidth;
      } else {
        return containerHeight / pageHeight;
      }
    }
  }

  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    document.body.removeChild(outer);

    return scrollbarWidth;
  }

  function handleScroll() {
    //setPageNumber(0);
  }

  function handleMouseMove(e) {
    let leftClick = 1;
    if (e.buttons === leftClick) {
      setCursor('grabbing');
      containerRef.current.scrollTop -= e.movementY;
      containerRef.current.scrollLeft -= e.movementX;
    } else {
      setCursor('default');
    }
  }

  function handleMouseDown() {
    setCursor('grabbing');
  }

  function handleMouseUp() {
    setCursor('default');
  }

  function handleWheel(e) {
    if (e.ctrlKey || e.altKey) {
      if (e.deltaY > 0) {
        onScaleChange(scale - 0.1);
      } else {
        onScaleChange(scale + 0.1);
      }
    }
  }

  return (
    <Box
      ref={containerRef}
      onMouseMove={handleMouseMove}
      style={{ cursor: cursor }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onWheel={handleWheel}
      onScroll={handleScroll}
      sx={{
        display: { display },
        height: '100%',
        overflow: 'auto',
        touchAction: 'pan-x pan-y',
      }}
    >
      <Pages
        pdf={pdf}
        scale={scale}
        pageNumber={pageNumber}
        onRenderSuccess={handleOnRenderSuccess}
        onLoadSuccess={handleOnLoadSuccess}
        onRenderError={onRenderError}
        onLoadError={onLoadError}
        highlights={highlights}
        citations={citations}
        setPageRef={handleSetPageRef}
        clearPageRef={handleClearPageRef}
        pageRotations={pageRotations}
        setCalculatedPageRotations={handleSetCalculatedPageRotations}
        showHighLight={showHighLight}
      />
    </Box>
  );
}

export default Area;

Area.propTypes = {
  pdf: PropTypes.any,
  display: PropTypes.any,
  scale: PropTypes.any,
  highlights: PropTypes.any,
  pageRotations: PropTypes.any,
  onRenderSuccess: PropTypes.any,
  onLoadSuccess: PropTypes.any,
  onRenderError: PropTypes.any,
  onLoadError: PropTypes.any,
  onScaleChange: PropTypes.any,
  pageNumber: PropTypes.any,
  setPageNumber: PropTypes.any,
  citations: PropTypes.any,
  isFullPage: PropTypes.bool,
  showHighLight: PropTypes.bool,
};
