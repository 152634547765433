import PropTypes from 'prop-types';
import AccordionContainer from 'common/components/AccordionContainer';
import { useFormApi } from '@data-driven-forms/react-form-renderer';

export default function Accordion({
  title,
  secondaryTitle,
  multiple = true,
  expanded = false,
  fields = [],
  layoutType,
  border,
  accordionButtonProps,
  accordionPanelProps,
  accordionContainerProps,
}) {
  const { renderForm } = useFormApi();
  return (
    <AccordionContainer
      header={title}
      secondaryHeader={secondaryTitle}
      allowToggle={!expanded}
      allowMultiple={multiple}
      marginTop={accordionContainerProps?.marginTop ?? '1rem'}
      marginRight={accordionContainerProps?.marginRight}
      marginLeft={accordionContainerProps?.marginLeft}
      layoutType={layoutType}
      accordionPanelProps={accordionPanelProps}
      accordionButtonProps={accordionButtonProps}
      border={border}
    >
      {renderForm(fields)}
    </AccordionContainer>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  multiple: PropTypes.bool,
  expanded: PropTypes.bool,
  accordionButtonProps: PropTypes.object,
  accordionPanelProps: PropTypes.object,
  accordionContainerProps: PropTypes.object,
  border: PropTypes.string,
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  layoutType: PropTypes.string,
};
