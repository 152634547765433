import PropTypes from 'prop-types';
import { UnnamedWidget } from 'lib-node-amb-renderer';

import DownloadLink from 'common/components/DownloadLink';

export class DownloadLinkWidget extends UnnamedWidget {
  text;
  documentId;
  constructor(props) {
    super(props);
    this.text = props.text;
    this.documentId = props.documentId;
  }
}
export default function DownloadLinkContainer({ text, documentId }) {
  return <DownloadLink text={text} documentId={+documentId} />;
}

DownloadLinkContainer.propTypes = {
  text: PropTypes.string,
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};
