import React from 'react';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Checkbox = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraCheckbox ref={ref} {...props}>
      {children}
    </ChakraCheckbox>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;

Checkbox.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
