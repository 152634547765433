import React from 'react';
import PropTypes from 'prop-types';
import BreakdownValue from '../styles/BreakdownValue';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import { FormTypes } from '../../../enums';
import lang from '../language.json';

const AddbacksAndDeductions = ({ selectedResult, currentYear }) => {
  const getValue = (fieldName) =>
    selectedResult
      .getInput(FormTypes.Form1120, currentYear, fieldName)
      ?.getValue();

  const {
    FIELDS: { ['1120']: FIELDS },
  } = lang;

  return (
    <BreakdownValue
      name={FIELDS.ADDBACK_DEDUCTIONS}
      value={getValue('subTotal')}
      isCurrency={true}
    >
      <BreakdownValue
        name={FIELDS.LINE8}
        value={getValue('capitalGainNetIncome')}
        subtract={true}
      />
      <BreakdownValue
        name={FIELDS.LINE9}
        value={getValue('netGainFrom4797')}
        subtract={true}
      />
      <BreakdownValue
        name={FIELDS.LINE10}
        value={getValue('otherIncome')}
        subtract={true}
      />
      <BreakdownValue name={FIELDS.LINE20} value={getValue('depreciation')} />
      <BreakdownValue name={FIELDS.LLINE21} value={getValue('depletion')} />
      <BreakdownValue
        name={FIELDS.LINE29A}
        value={getValue('netOperatingLossDeduction')}
      />
      <BreakdownValue name={FIELDS.LINE30} value={getValue('taxableIncome')} />
      <BreakdownValue
        name={FIELDS.LINE31}
        value={getValue('totalTax')}
        subtract={true}
      />
      <BreakdownValue
        name={FIELDS.LINE17}
        value={getValue('mortgagesNotesBondsPayableInLessThanOneYear')}
        subtract={true}
      />
      <BreakdownValue
        name={FIELDS.M1LINE5C}
        value={getValue('travelAndEntertainment')}
        subtract={true}
      />
      <BreakdownValue
        name={FIELDS.AMORTIZARTION}
        value={getValue('amortizationCasualtyLoss')}
      />
    </BreakdownValue>
  );
};
AddbacksAndDeductions.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
};

export default AddbacksAndDeductions;
