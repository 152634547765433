import ChakraTextarea from 'common/componentMapper/Textarea';
import Text from 'common/componentMapper/Text';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
const TextArea = forwardRef(
  (
    { maxLength, sx, value, showMaxLength, resize, defaultValue, ...props },
    ref,
  ) => {
    const currentTextLength = value?.split('').length;
    return (
      <>
        <ChakraTextarea
          {...props}
          value={value}
          defaultValue={defaultValue}
          sx={sx}
          maxLength={maxLength}
          resize={resize}
          ref={ref}
        />
        {showMaxLength && (
          <Text align={'right'} fontWeight={'bold'}>
            {currentTextLength} / {maxLength}
          </Text>
        )}
      </>
    );
  },
);

TextArea.displayName = 'TextArea';
export default TextArea;

TextArea.propTypes = {
  maxLength: PropTypes.number,
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showMaxLength: PropTypes.bool,
  defaultValue: PropTypes.any,
  resize: PropTypes.oneOf([
    'both',
    'vertical',
    'none',
    'horizontal',
    'initial',
    'inherit',
  ]),
};
