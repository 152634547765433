import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * hook to calculate ParentAlert children show/hide states
 * @param {bool} isLeftDrawerCollapsed
 * @returns {object} parentChildrenStates, allTrueStates, allFalseStates
 */
const useGetParentChildrenStates = (isLeftDrawerCollapsed) => {
  const [showParentChildren, setShowParentChildren] = useState({});

  const selectedCategory = useSelector(({ home }) => home?.selectedCategory);

  const categories = useSelector(
    ({ home }) => home?.navigationItems?.categories,
  );
  const currentCategory = categories?.find(
    (category) => category?.navigationCategoryId === selectedCategory,
  );

  const navigationGutterItems = currentCategory?.navigationGutterItems;

  const { parentChildrenStates, allFalseStates, allTrueStates } =
    useMemo(() => {
      let parentChildrenStates = {};
      let allTrueStates = {};
      let allFalseStates = {};

      navigationGutterItems?.map((gutterItem) => {
        if (gutterItem?.gutterItemChildren.length > 0) {
          parentChildrenStates = {
            ...parentChildrenStates,
            [gutterItem?.displayTitle]: false,
          };
          allFalseStates = {
            ...allFalseStates,
            [gutterItem?.displayTitle]: false,
          };
          allTrueStates = {
            ...allTrueStates,
            [gutterItem?.displayTitle]: true,
          };
        }
      });

      return { allTrueStates, allFalseStates, parentChildrenStates };
    }, [navigationGutterItems]);

  //persisting allTrueStates when switching categories and LeftDrawer is open
  useEffect(() => {
    setShowParentChildren({});
    if (!isLeftDrawerCollapsed) {
      setShowParentChildren((prev) => {
        return { ...prev, ...allTrueStates };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeftDrawerCollapsed, allTrueStates]);

  return {
    allTrueStates,
    allFalseStates,
    parentChildrenStates,
    setShowParentChildren,
    showParentChildren,
  };
};

export default useGetParentChildrenStates;
