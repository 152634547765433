import PropTypes from 'prop-types';
import Text from 'common/components/Texts/Text';
import { camelCase } from 'lodash';
import { useDispatch } from 'react-redux';

import Badge, { BADGE_COLOR } from 'common/components/Badge';
import {
  StyledAccordionItem,
  StyledCategoryGroupButton,
} from './StyledComponents/DocumentList.styles';
import { CLASSIFICATION, QUICK_MATCH } from './constants';
import { HomeActions } from 'pages/Home/redux';
import useLeftDrawerContext from 'common/components/Nav/LeftDrawer/context/useLeftDrawerContext';

/**
 * -------------------- *
 * Local & Global state *
 * -------------------- *
 */
const ACTION_NEEDED = 'needed';
const COMPLETED = 'completed';
const CATEGORIES = {
  [ACTION_NEEDED]: 0,
  [COMPLETED]: 0,
};
/**
 * -------------- *
 * Event handlers *
 * -------------- *
 */
const QuickMatchDocumentAssociation = ({ documentItems, isActive }) => {
  const dispatch = useDispatch();
  const { isLeftDrawerCollapsed, toggleLeftDrawer } = useLeftDrawerContext();
  const { completed, needed } = documentItems.reduce(
    (accum, { categoryStatus, tableData }) => {
      const category =
        categoryStatus === CLASSIFICATION.completed ? COMPLETED : ACTION_NEEDED;

      accum[category] += tableData.length;

      return accum;
    },
    { ...CATEGORIES },
  );

  const dataTracking = camelCase(documentItems[0]?.categoryUwActionTypeId);

  const handleDocumentClick = () => {
    isLeftDrawerCollapsed && toggleLeftDrawer();
    dispatch(HomeActions.setSelectedAlert(QUICK_MATCH));
    // setActivePage

    //call right panel
  };

  /**
   * -------- *
   * Template *
   * -------- *
   */
  return (
    <StyledAccordionItem>
      <StyledCategoryGroupButton
        data-tracking={dataTracking}
        selected={isActive}
        onClick={handleDocumentClick}
      >
        <Badge
          content={needed || completed}
          color={needed ? BADGE_COLOR.RED : BADGE_COLOR.GREEN}
        />
        <Text
          as="h3"
          sx={{ ml: 2 }}
          opacity={isLeftDrawerCollapsed ? 0 : 1}
          whiteSpace="nowrap"
          transform={
            isLeftDrawerCollapsed ? 'translateX(-8px)' : 'translateX(0)'
          }
          transition="all 0.5s ease-in-out"
        >
          Document Associations
        </Text>
      </StyledCategoryGroupButton>
    </StyledAccordionItem>
  );
};

QuickMatchDocumentAssociation.propTypes = {
  documentItems: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
};

export default QuickMatchDocumentAssociation;
