﻿import React from 'react';
import PropTypes from 'prop-types';
import BreakdownYearContainer from '../styles/BreakdownYearContainer';
import GenericCalculationItemDataModel from '../../../model/GenericCalculationItemDataModel';
import MedicareWages from './MedicareWages';
import AddbacksAndDeductions from './AddbacksAndDeductions';
import BorrowerShareOfIncome from './BorrowerShareOfIncome';
import AnnualIncome from './AnnualIncome';

const AnnualBreakdown = ({ selectedResult, currentYear, formulaId }) => {
  return (
    <BreakdownYearContainer title={currentYear}>
      <MedicareWages
        selectedResult={selectedResult}
        currentYear={currentYear}
      />
      <AddbacksAndDeductions
        selectedResult={selectedResult}
        currentYear={currentYear}
      />
      <BorrowerShareOfIncome
        selectedResult={selectedResult}
        currentYear={currentYear}
      />
      <AnnualIncome
        selectedResult={selectedResult}
        currentYear={currentYear}
        formulaId={formulaId}
      />
    </BreakdownYearContainer>
  );
};
AnnualBreakdown.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
  currentYear: PropTypes.number.isRequired,
  formulaId: PropTypes.number.isRequired,
};

export default AnnualBreakdown;
