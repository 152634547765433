import { QUESTION_STATUS } from '../constants';
import { formatDate } from 'common/util/format';
import { RULE_ID } from 'common/constants/api-ids';
import Question from './Question';
export default class DriverLicenseReviewData {
  borrower;
  driverLicense;
  nameCorrectQuestion = null;
  nameSelectQuestion = null;
  addressInHistoryQuestion = null;
  addressLoxQuestion = null;
  dateOfBirthCorrectQuestion = null;
  dateOfBirthSelectQuestion = null;
  issueDateSelectQuestion = null;
  expDateQuestion = null;

  constructor(dl = {}) {
    this.borrower = Object.prototype.hasOwnProperty.call(dl, 'borrower')
      ? dl.borrower
      : {
          lenderdatabaseId: 0,
          customerRecordId: 0,
          firstName: '',
          middleName: null,
          lastName: '',
          suffixName: null,
          socialSecurityNumber: '',
          borrowerPosition: 0,
          isPrimaryBorrower: false,
          dateOfBirth: '',
          citizenship: 0,
          borrowerAddressHistory: [
            {
              addressLine1: '',
              unit: null,
              city: '',
              state: '',
              zipCode: '',
            },
          ],
        };
    if (this.borrower.dateOfBirth !== '') {
      this.borrower.dateOfBirth = formatDate(this.borrower.dateOfBirth);
    }
    this.driverLicense = Object.prototype.hasOwnProperty.call(
      dl,
      'driversLicense',
    )
      ? dl.driversLicense
      : {
          documentId: 0,
          firstName: '',
          lastName: '',
          middleName: '',
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
          dateOfBirth: '',
          issueDate: '',
          dateOfExpiry: '',
          ocrCreatedDateTime: '',
          firstNameResultId: 0,
          lastNameResultId: 0,
          middleNameResultId: 0,
          address1ResultId: 0,
          address2ResultId: null,
          cityResultId: 0,
          stateResultId: 0,
          postalCodeResultId: 0,
          dateOfBirthResultId: 0,
          expirationDateResultId: 0,
          issueDateResultId: 0,
          fullName: '',
          receivedMailitemId: 0,
          additionalData: [
            {
              fieldName: '',
              actualExtractedDataresultId: 0,
              boundingBox: '',
              confidence: '',
            },
          ],
        };
    this.loanApplicationDate = Object.prototype.hasOwnProperty.call(
      dl,
      'loanApplicationDate',
    )
      ? dl.loanApplicationDate
      : { loanApplicationDate: '' };
    if (this.loanApplicationDate) {
      this.loanApplicationDate = formatDate(this.loanApplicationDate);
    }
    if (this.driverLicense.dateOfBirth) {
      this.driverLicense.dateOfBirth = formatDate(
        this.driverLicense.dateOfBirth,
      );
    }
    if (this.driverLicense.dateOfExpiry) {
      this.driverLicense.dateOfExpiry = formatDate(
        this.driverLicense.dateOfExpiry,
      );
    }
    if (this.driverLicense.issueDate) {
      this.driverLicense.issueDate = formatDate(this.driverLicense.issueDate);
    }
    // Set Questions
    const results =
      Object.prototype.hasOwnProperty.call(dl, 'results') &&
      dl.results?.length > 0
        ? dl.results.filter(
            ({ status, questions }) =>
              (status === QUESTION_STATUS.FIRED ||
                status === QUESTION_STATUS.ACTIVE) &&
              questions?.length > 0,
          )
        : [];

    results.forEach(({ ruleMetadata, questions }) => {
      const [additionalQuestion, correctQuestion, selectQuestion] =
        questions.map((q) => new Question(q));
      switch (ruleMetadata.ruleId) {
        case RULE_ID.DATE_OF_BIRTH_MISMATCH_RULE:
          this.dateOfBirthCorrectQuestion = additionalQuestion;
          this.dateOfBirthSelectQuestion = correctQuestion;
          break;
        case RULE_ID.EXPIRATION_DATE_BEFORE_UPLOAD_DATE_RULE:
          this.expDateQuestion = additionalQuestion;
          break;
        case RULE_ID.DRIVERS_LICENSE_ADDRESS_MISMATCH:
          this.addressInHistoryQuestion = additionalQuestion;
          this.addressLoxQuestion = correctQuestion;
          this.issueDateSelectQuestion = selectQuestion;
          break;
        case RULE_ID.DRIVERS_LICENSE_NAME_MISMATCH:
          this.nameCorrectQuestion = additionalQuestion;
          this.nameSelectQuestion = correctQuestion;
          break;
        default:
          break;
      }
    });
  }

  setQuestions(results) {
    if (results.length === 0) return;
    const filteredResults = results.filter(
      ({ status, questions }) =>
        (status === QUESTION_STATUS.FIRED ||
          status === QUESTION_STATUS.ACTIVE) &&
        questions?.length > 0,
    );
    filteredResults.forEach(({ ruleMetadata, questions }) => {
      const [additionalQuestion, correctQuestion, selectQuestion] =
        questions.map((q) => new Question(q));
      switch (ruleMetadata.ruleId) {
        case RULE_ID.DATE_OF_BIRTH_MISMATCH_RULE:
          this.dateOfBirthCorrectQuestion = additionalQuestion;
          this.dateOfBirthSelectQuestion = correctQuestion;
          break;
        case RULE_ID.EXPIRATION_DATE_BEFORE_UPLOAD_DATE_RULE:
          this.expDateQuestion = additionalQuestion;
          break;
        case RULE_ID.DRIVERS_LICENSE_ADDRESS_MISMATCH:
          this.addressInHistoryQuestion = additionalQuestion;
          this.addressLoxQuestion = correctQuestion;
          this.issueDateSelectQuestion = selectQuestion;
          break;
        case RULE_ID.DRIVERS_LICENSE_NAME_MISMATCH:
          this.nameCorrectQuestion = additionalQuestion;
          this.nameSelectQuestion = correctQuestion;
          break;
        default:
          break;
      }
    });
  }

  getQuestions() {
    return {
      nameCorrectQuestion: this.nameCorrectQuestion,
      nameSelectQuestion: this.nameSelectQuestion,
      addressInHistoryQuestion: this.addressInHistoryQuestion,
      addressLoxQuestion: this.addressLoxQuestion,
      dateOfBirthCorrectQuestion: this.dateOfBirthCorrectQuestion,
      dateOfBirthSelectQuestion: this.dateOfBirthSelectQuestion,
      expDateQuestion: this.expDateQuestion,
      issueDateSelectQuestion: this.issueDateSelectQuestion,
    };
  }

  getActiveQuestionIds() {
    const ids = [
      this.nameCorrectQuestion?.questionId, // questionId could be null
      this.nameSelectQuestion?.questionId,
      this.addressInHistoryQuestion?.questionId,
      this.addressLoxQuestion?.questionId,
      this.dateOfBirthCorrectQuestion?.questionId,
      this.dateOfBirthSelectQuestion?.questionId,
      this.expDateQuestion?.questionId,
      this.issueDateSelectQuestion?.questionId,
    ];

    return ids.filter(Boolean); // remove falsey values
  }

  getBorrowerAddressHistory() {
    return this.borrower.borrowerAddressHistory;
  }

  getBorrower() {
    return this.borrower;
  }

  getBorrowerName() {
    return `${this.borrower.firstName} ${this.borrower.lastName}`;
  }

  getBorrowerFullName() {
    const concatNames = (accumulator, current, index) => {
      if (current) {
        if (index === 0) return accumulator + current;
        return accumulator + ` ${current}`;
      }
      return accumulator;
    };
    return [
      this.borrower.firstName,
      this.borrower.middleName,
      this.borrower.lastName,
    ].reduce(concatNames, '');
  }

  getBorrowerDateOfBirth() {
    return this.borrower.dateOfBirth;
  }

  setBorrower(borrower) {
    this.borrower = borrower;
  }

  getDriverLicense() {
    return this.driverLicense;
  }

  getDriverLicenseName() {
    return `${this.driverLicense.firstName} ${this.driverLicense.lastName}`;
  }

  getDriverLicenseDateOfBirth() {
    return this.driverLicense.dateOfBirth;
  }

  getDriverLicenseDateOfExpiry() {
    return this.driverLicense.dateOfExpiry;
  }

  setDriverLicense(driverLicense) {
    this.driverLicense = driverLicense;
  }

  getDriverLicenseIssueDate() {
    return this.driversLicense.issueDate;
  }

  getLoanApplicationDate() {
    return this.loanApplicationDate;
  }

  setLoanApplicationDate(loanApplicationDate) {
    this.loanApplicationDate = loanApplicationDate;
  }
}
