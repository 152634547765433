import { useFieldApi, FormSpy } from '@data-driven-forms/react-form-renderer';
import PropTypes from 'prop-types';
import ChakraInput from 'common/components/Inputs/Input';
import Tooltip from 'common/components/Tooltip';
import FormControl from 'common/componentMapper/FormControl';
import FormLabel from 'common/componentMapper/FormLabel';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import { PatternFormat } from 'react-number-format';
import { validationError } from '../utils';
import CurrencyInput from './Currency';
import DatePicker from './DatePicker';
import GenericTooltip from 'common/components/GenericTooltip';
import TextArea from './TextArea';

import { useState, useEffect, forwardRef } from 'react';
import { handleParcelIdLabelChange } from './handlers';
import { formatCurrencyValue, formatDate } from 'common/util/format';

const InputSwitch = forwardRef(
  ({ type, input, onChange, meta, ...rest }, ref) => {
    const handleChange = (e) => {
      input.onChange(e);
      onChange?.(e);
    };

    switch (type) {
      case 'currency':
        return (
          <CurrencyInput
            {...input}
            meta={meta}
            {...rest}
            onChange={handleChange}
            ref={ref}
          />
        );
      case 'text':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="text"
            ref={ref}
          />
        );
      case 'number':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="number"
            ref={ref}
          />
        );
      case 'email':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="email"
            ref={ref}
          />
        );
      case 'ssn':
        return (
          <PatternFormat
            format="###-##-####"
            mask="_"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'phone':
        return (
          <PatternFormat
            format="+1 (###) ###-####"
            mask="_"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'percentage':
        return (
          <PatternFormat
            format="##%"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'date':
        return (
          <DatePicker
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            preventOpenOnFocus={rest.preventOpenOnFocus}
            placeholderText={rest.placeholder}
            isInvalid={meta.invalid}
            errorMessage={meta.error}
            ref={ref}
          />
        );
      case 'textarea':
        return (
          <TextArea
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            ref={ref}
          />
        );
      case 'hidden':
        return (
          <ChakraInput
            {...input}
            defaultValue={meta.initial}
            type="hidden"
            ref={ref}
          />
        );
      default:
        return (
          <ChakraInput
            {...input}
            {...rest}
            onChange={handleChange}
            tabIndex={rest.tabIndex}
            defaultValue={meta.initial}
            type="text"
            ref={ref}
          />
        );
    }
  },
);

InputSwitch.displayName = 'InputSwitch';

InputSwitch.propTypes = {
  type: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  rest: PropTypes.object,
  onChange: PropTypes.func,
};

const Input = forwardRef(
  ({ formProps, helperText, showIsRequired, ...props }, ref) => {
    const {
      ariaLabel,
      label: labelFromInput,
      input,
      meta,
      validateOnMount,
      type,
      isInvalid,
      hidden,
      isRequired,
      sx,
      fcStyle = {},
      placeholder = '',
      isDisabled,
      disabled,
      maxLength,
      resize,
      showMaxLength,
      readonly,
      placement = '',
      maxWidth,
      role,
      showToolTip,
      onChange,
      onBlur,
      onFocus,
      preventOpenOnFocus,
    } = useFieldApi({
      ...props,
    });
    const { name } = props;

    const [label, setLabel] = useState(labelFromInput);
    const [isEditing, setIsEditing] = useState(false);

    const invalid =
      validationError(meta, validateOnMount) || (meta.invalid && meta.touched);
    const errorText =
      ((meta.touched || validateOnMount) && meta.warning) ||
      isInvalid ||
      meta.error;

    const showError = !(errorText === true || errorText === false);
    const valueLength = input.value?.toString().length;
    const tabIndex = isDisabled ? -1 : 0;
    const cutOffLength = 5;
    const showInputWithToolTip =
      showToolTip && valueLength > cutOffLength && !isEditing;
    const backgroundColor = sx?.backgroundColor ? sx.backgroundColor : 'white';

    const handleOnBlur = (e) => {
      input.onBlur();
      onBlur?.(e);
      setIsEditing(false);
    };

    const handleOnFocus = (e) => {
      setIsEditing(true);
      onFocus?.(e);
    };

    useEffect(() => {
      if (input) {
        input.onChange(`${input.value}`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (showIsRequired === undefined && !label) {
      showIsRequired = false;
    }

    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const isFirstParcelInput = labelFromInput?.includes('Parcel ID');
          isFirstParcelInput &&
            handleParcelIdLabelChange({ values, isFirstParcelInput, setLabel });
          return (
            <FormControl
              sx={{
                marginBottom: formProps?.mb ? '1rem' : 0,
                ...fcStyle,
              }}
              defaultValue={meta.initial}
              hidden={type === 'hidden'}
              isRequired={
                isRequired && (showIsRequired || showIsRequired === undefined)
              }
              isInvalid={!!invalid && !isDisabled && showError}
              role={role ? role : 'group'}
            >
              <FormLabel m={0}>
                {label}
                {helperText && <GenericTooltip label={helperText} />}
              </FormLabel>
              {showInputWithToolTip ? (
                <Tooltip
                  placement="top"
                  textAlign="center"
                  color="black"
                  bg="gray.200"
                  borderRadius="2px"
                  fontSize="12px"
                  fontWeight="normal"
                  p={2}
                  label={
                    type === 'currency'
                      ? formatCurrencyValue(input.value.toString())
                      : type === 'date'
                      ? formatDate(input.value.toString())
                      : input.value
                  }
                >
                  <span role="tooltip" aria-label={label ?? 'Information'}>
                    <InputSwitch
                      sx={{
                        ...sx,
                        backgroundColor,
                      }}
                      tabIndex={tabIndex}
                      type={hidden ? 'hidden' : type}
                      input={input}
                      id={name}
                      placeholder={placeholder}
                      meta={meta}
                      readOnly={readonly}
                      isDisabled={isDisabled || disabled}
                      maxLength={maxLength}
                      showMaxLength={showMaxLength}
                      resize={resize}
                      placement={placement}
                      maxWidth={maxWidth}
                      preventOpenOnFocus={preventOpenOnFocus}
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                      onChange={onChange}
                      ref={ref}
                      ariaLabel={ariaLabel}
                      role={role}
                    />
                  </span>
                </Tooltip>
              ) : (
                <InputSwitch
                  sx={{ ...sx, backgroundColor }}
                  tabIndex={tabIndex}
                  type={hidden ? 'hidden' : type}
                  input={input}
                  id={name}
                  placeholder={placeholder}
                  meta={meta}
                  readOnly={readonly}
                  isDisabled={isDisabled || disabled} // Need to standardize this
                  maxLength={maxLength}
                  showMaxLength={showMaxLength}
                  resize={resize}
                  placement={placement}
                  maxWidth={maxWidth}
                  preventOpenOnFocus={preventOpenOnFocus}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  onChange={onChange}
                  ref={ref}
                  ariaLabel={ariaLabel}
                  role={role}
                />
              )}
              {showError && <FormErrorMessage>{errorText}</FormErrorMessage>}
            </FormControl>
          );
        }}
      </FormSpy>
    );
  },
);

Input.displayName = 'Input';

Input.propTypes = {
  type: PropTypes.oneOf([
    'currency',
    'text',
    'number',
    'email',
    'ssn',
    'phone',
    'percentage',
    'date',
    'textarea',
    'hidden',
  ]).isRequired,
  helperText: PropTypes.string,
  formProps: PropTypes.object,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  showIsRequired: PropTypes.bool,
  showMaxLength: PropTypes.bool,
  showToolTip: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  sx: PropTypes.object,
  fcStyle: PropTypes.object,
  name: PropTypes.string,
};

export default Input;
