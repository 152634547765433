export default class DocumentFieldDataModel {
  constructor(b = {}) {
    this.documentFieldId = Object.prototype.hasOwnProperty.call(
      b,
      'documentFieldId',
    )
      ? b.documentFieldId
      : 0;

    this.documentFieldName = Object.prototype.hasOwnProperty.call(
      b,
      'documentFieldName',
    )
      ? b.documentFieldName
      : '';

    this.documentFieldValue = Object.prototype.hasOwnProperty.call(
      b,
      'documentFieldValue',
    )
      ? b.documentFieldValue
      : '';

    this.snippetId = Object.prototype.hasOwnProperty.call(b, 'snippetId')
      ? b.snippetId
      : 0;

    this.boundingBoxCoordinates = Object.prototype.hasOwnProperty.call(
      b,
      'boundingBoxCoordinates',
    )
      ? b.boundingBoxCoordinates
      : '';
  }

  /**
   * @returns {number} Document field Id.
   */
  getDocumentFieldId() {
    return this.documentFieldId;
  }

  /**
   * @returns {string} Document field name.
   */
  getDocumentFieldName() {
    return this.documentFieldName;
  }

  /**
   * @returns {string} Field value.
   */
  getDocumentFieldValue() {
    return this.documentFieldValue;
  }

  /**
   * @returns {number} Extracted data result Id.
   */
  getSnippetId() {
    return this.snippetId;
  }

  /**
   * @returns {array} document extraction bounding box coordinates
   */
  getBoundingBoxCoordinates() {
    return this.boundingBoxCoordinates;
  }
}
