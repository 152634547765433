import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// This is a custom menu item that can be used in place of a Link component so that the item receives tab focus
const CustomMenuItem = ({ role, tabIndex, title, onClick, children }) => (
  <div
    role={role}
    tabIndex={tabIndex}
    title={title}
    onClick={onClick}
    onKeyUp={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick(e);
      }
    }}
  >
    {children}
  </div>
);

CustomMenuItem.propTypes = {
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const RailItemComponentTypeWrapper = ({
  title,
  to,
  isLink = true,
  children,
  onClick,
}) => {
  return isLink ? (
    <Link title={title} to={to} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <CustomMenuItem role="link" tabIndex={0} title={title} onClick={onClick}>
      {children}
    </CustomMenuItem>
  );
};

RailItemComponentTypeWrapper.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  isLink: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default RailItemComponentTypeWrapper;
