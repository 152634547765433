import PropTypes from 'prop-types';
import ChakraButton from 'common/components/Buttons/Button/index';

export const Button = ({
  isPrimary = true,
  isLoading = false,
  isDisabled = false,
  dataTestid = '',
  dataTracking = 'N/A',
  children,
  ...props
}) => {
  props = isPrimary
    ? {
        color: 'white',
        backgroundColor: 'blue.600',
        _hover: { backgroundColor: 'blue.500' },
        'data-testid': dataTestid || 'primary-button',
        ...props,
      }
    : {
        colorScheme: 'blue',
        variant: 'outline',
        'data-testid': dataTestid || 'secondary-button',
        ...props,
      };
  props['data-tracking'] = dataTracking;

  return (
    <ChakraButton isLoading={isLoading} isDisabled={isDisabled} {...props}>
      {children}
    </ChakraButton>
  );
};

Button.propTypes = {
  isPrimary: PropTypes.bool,
  isDefaultTextColor: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  dataTestid: PropTypes.string,
  dataTracking: PropTypes.string,
  children: PropTypes.any,
};

export default Button;
