import { ModalFooter as ChakraModalFooter } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalFooter = ({ children, ...props }) => {
  return <ChakraModalFooter {...props}>{children}</ChakraModalFooter>;
};

export default ModalFooter;

ModalFooter.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
