import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import InputGroup from 'common/components/Inputs/InputGroup';
import InputRightElement from 'common/components/Inputs/InputRightElement';
import FormControl from 'common/components/Forms/FormControl';
import Box from 'common/components/Box';
import React, { useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';
import 'common/components/DatePicker/style.css';

/*
 * forwardRef(component) will allow us to pass a ref through
 *   the component using the standard ref prop.
 * The ref prop passed in the parent doesn't show up in the
 *   props of a component, but instead comes through as a
 *   second prop to the component
 *   MyComponent = forwardRef((props,ref)={})
 * This ref can be passed down to any child component
 */
export const YamlDatePicker = ({
  ariaLabel,
  placement,
  preventOpenOnFocus = false,
  isClearable = false,
  showPopperArrow = false,
  hasIcon = true,
  value,
  isDisabled,
  isInvalid,
  ...props
}) => {
  const datePickerRef = useRef(null);
  const onIconClick = () => datePickerRef.current.onInputClick();
  const selectedDate = value ? new Date(value) : null;

  useEffect(() => {
    if (value && !isNaN(selectedDate.getDate())) {
      const date = new Date(value);
      props.onChange(new Date(date.toLocaleDateString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      isInvalid={isInvalid}
      className={isInvalid ? 'is-invalid' : undefined}
    >
      <InputGroup>
        <ReactDatePicker
          {...props}
          dateFormat={'MM/dd/yyyy'}
          ref={datePickerRef}
          selected={!isNaN(selectedDate?.getDate()) ? selectedDate : null}
          popperPlacement={placement ? placement : 'right-start'}
          isClearable={isClearable}
          preventOpenOnFocus={preventOpenOnFocus}
          showPopperArrow={showPopperArrow}
          data-testid={'react-datepicker'}
          disabled={isDisabled}
          ariaLabelledBy={ariaLabel}
        />
        {hasIcon && (
          <InputRightElement
            data-testid={'react-datepicker-input-right-element'}
            ariaLabel={`${ariaLabel}-right-element`}
            onClick={() => onIconClick()}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

YamlDatePicker.displayName = 'DatePicker';

YamlDatePicker.propTypes = {
  /** Aria label for the input field. */
  ariaLabel: PropTypes.string,
  /** Whether or not to show a clear button*/
  isClearable: PropTypes.bool,
  /** A function to be called when the value is updated. */
  onChange: PropTypes.func,
  /** The current value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Choose where the date popover gets placed with respect to the text field. */
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
    'right-start',
    'right-end',
  ]),
  /** True to show an arrow pointing to the input field.*/
  showPopperArrow: PropTypes.bool,
  isRequiredField: PropTypes.bool,
  name: PropTypes.string,
  /** The earliest date that can be selected.*/
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** The latest date that can be selected. */
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** Whether or not to display the calendar icon. */
  hasIcon: PropTypes.bool,
  /** Whether or not to show a dropdown for the month. */
  handleCalendarClose: PropTypes.func,
  /** A function to be called when the calendar closes. */
  showMonthDropdown: PropTypes.bool,
  /** Whether or not to show a dropdown for the year. */
  showYearDropdown: PropTypes.bool,
  /** The dropdown mode for month/year dropdowns.*/
  dropdownMode: PropTypes.oneOf(['select']),
  preventOpenOnFocus: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const DatePicker = forwardRef(({ sx, ...rest }, ref) => {
  if (sx)
    return (
      <Box sx={sx}>
        <YamlDatePicker {...rest} ref={ref} useFormattedDate />
      </Box>
    );
  return <YamlDatePicker {...rest} ref={ref} useFormattedDate />;
});

DatePicker.displayName = 'DatePickerWithRef';

DatePicker.propTypes = {
  sx: PropTypes.object,
};
export default DatePicker;
