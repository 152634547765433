export const EMPLOYMENT_TYPES = {
  NONEMPLOYMENT: 0,
  SELFEMPLOYMENT: 1,
  EMPLOYMENT: 2,
  PREVIOUSEMPLOYMENT: 3,
  PREVIOUSSELFEMPLOYMENT: 4,
};

export const employmentIncomeTypeOptions = [
  { type: 'Base Income', typeCode: 'BaseEmploymentIncome', value: 1 },
  { type: 'Bonuses', typeCode: 'Bonuses', value: 7 },
  { type: 'Commissions', typeCode: 'Commissions', value: 10 },
  { type: 'Military Base Pay', typeCode: 'MilitaryBasePay', value: 17 },
  {
    type: 'Military Clothes Allowance',
    typeCode: 'MilitaryClothesAllowance',
    value: 23,
  },
  { type: 'Military Combat Pay', typeCode: 'MilitaryCombatPay', value: 18 },
  { type: 'Military Flight Pay', typeCode: 'MilitaryFlightPay', value: 19 },
  { type: 'Military Hazard Pay', typeCode: 'MilitaryHazardPay', value: 20 },
  { type: 'Military Prop Pay', typeCode: 'MilitaryPropPay', value: 24 },
  { type: 'Military Overseas Pay', typeCode: 'MilitaryOverseasPay', value: 21 },
  {
    type: 'Military Quarters Allowance',
    typeCode: 'MilitaryQuartersAllowance',
    value: 25,
  },
  {
    type: 'Military Rations Allowance',
    typeCode: 'MilitaryRationsAllowance',
    value: 26,
  },
  {
    type: 'Military Variable Housing Allowance',
    typeCode: 'MilitaryVariableHousingAllowance',
    value: 22,
  },
  { type: 'Other W2 Income', typeCode: 'OtherW2Income', value: 30 },
  { type: 'Overtime', typeCode: 'Overtime', value: 31 },
  { type: 'Seasonal Income', typeCode: 'SeasonalIncome', value: 35 },
  { type: 'Tip Income', typeCode: 'TipIncome', value: 39 },
];
export const nonEmploymentIncomeTypeOptions = [
  { type: 'Accessory Unit Income', typeCode: 'AccessoryUnitIncome', value: 2 },
  { type: 'Alimony', typeCode: 'Alimony', value: 3 },
  { type: 'Assets as Income', typeCode: 'AssetsAsIncome', value: 4 },
  { type: 'Boarder Income', typeCode: 'BoarderIncome', value: 6 },
  { type: 'Capital Gains', typeCode: 'CapitalGains', value: 8 },
  {
    type: 'Automobile Or Expense Account Income',
    typeCode: 'AutomobileOrExpenseAccountIncome',
    value: 5,
  },
  { type: 'Child Support', typeCode: 'ChildSupport', value: 9 },
  { type: 'Dividends Or Interest', typeCode: 'DividendsOrInterest', value: 11 },
  {
    type: 'Employment Related Assets',
    typeCode: 'EmploymentRelatedAsset',
    value: 12,
  },
  { type: 'Farm Income', typeCode: 'FarmIncome', value: 13 },
  { type: 'Foreign Income', typeCode: 'ForeignIncome', value: 14 },
  { type: 'Foster Care', typeCode: 'FosterCare', value: 15 },
  { type: 'IRA Distributions', typeCode: 'IraDistributions', value: 16 },
  {
    type: 'Notes Receivable',
    typeCode: 'NotesReceivableOrInstallment',
    value: 27,
  },
  { type: 'Other Income', typeCode: 'OtherIncome', value: 28 },
  {
    type: 'Pension Or Retirement Income',
    typeCode: 'PensionOrRetirementIncome',
    value: 32,
  },
  {
    type: 'Real Estate Or Mortgage Differential',
    typeCode: 'RealEstateOrMortgageDifferential',
    value: 33,
  },
  { type: 'Royalty Payments', typeCode: 'RoyaltyPayments', value: 34 },
  {
    type: 'Social Security Or Disability Income',
    typeCode: 'SocialSecurityOrDisabilityIncome',
    value: 36,
  },
  { type: 'Temporary Leave', typeCode: 'TemporaryLeave', value: 38 },
  { type: 'Trust Income', typeCode: 'TrustIncome', value: 40 },
  {
    type: 'Unemployment Or Public Assistance',
    typeCode: 'UnemploymentOrPublicAssistance',
    value: 41,
  },
  {
    type: 'UnReimbursed Expenses',
    typeCode: 'UnReimbursedExpenses',
    value: 42,
  },
  { type: 'VA Benefits', typeCode: 'VaBenefits', value: 43 },
];

export const allEmploymentIncomeTypeOptions = [
  { type: 'Base Income', typeCode: 'BaseEmploymentIncome', value: 1 },
  { type: 'Bonuses', typeCode: 'Bonuses', value: 7 },
  { type: 'Commissions', typeCode: 'Commissions', value: 10 },
  { type: 'Military Base Pay', typeCode: 'MilitaryBasePay', value: 17 },
  {
    type: 'Military Clothes Allowance',
    typeCode: 'MilitaryClothesAllowance',
    value: 23,
  },
  { type: 'Military Combat Pay', typeCode: 'MilitaryCombatPay', value: 18 },
  { type: 'Military Flight Pay', typeCode: 'MilitaryFlightPay', value: 19 },
  { type: 'Military Hazard Pay', typeCode: 'MilitaryHazardPay', value: 20 },
  { type: 'Military Prop Pay', typeCode: 'MilitaryPropPay', value: 24 },
  { type: 'Military Overseas Pay', typeCode: 'MilitaryOverseasPay', value: 21 },
  {
    type: 'Military Quarters Allowance',
    typeCode: 'MilitaryQuartersAllowance',
    value: 25,
  },
  {
    type: 'Military Rations Allowance',
    typeCode: 'MilitaryRationsAllowance',
    value: 26,
  },
  {
    type: 'Military Variable Housing Allowance',
    typeCode: 'MilitaryVariableHousingAllowance',
    value: 22,
  },
  { type: 'Other W2 Income', typeCode: 'OtherW2Income', value: 30 },
  { type: 'Overtime', typeCode: 'Overtime', value: 31 },
  { type: 'Seasonal Income', typeCode: 'SeasonalIncome', value: 35 },
  { type: 'Accessory Unit Income', typeCode: 'AccessoryUnitIncome', value: 2 },
  { type: 'Alimony', typeCode: 'Alimony', value: 3 },
  { type: 'Assets as Income', typeCode: 'AssetsAsIncome', value: 4 },
  { type: 'Boarder Income', typeCode: 'BoarderIncome', value: 6 },
  { type: 'Capital Gains', typeCode: 'CapitalGains', value: 8 },
  {
    type: 'Automobile Or Expense Account Income',
    typeCode: 'AutomobileOrExpenseAccountIncome',
    value: 5,
  },
  { type: 'Child Support', typeCode: 'ChildSupport', value: 9 },
  { type: 'Dividends Or Interest', typeCode: 'DividendsOrInterest', value: 11 },
  {
    type: 'Employment Related Assets',
    typeCode: 'EmploymentRelatedAsset',
    value: 12,
  },
  { type: 'Farm Income', typeCode: 'FarmIncome', value: 13 },
  { type: 'Foreign Income', typeCode: 'ForeignIncome', value: 14 },
  { type: 'Foster Care', typeCode: 'FosterCare', value: 15 },
  { type: 'IRA Distributions', typeCode: 'IraDistributions', value: 16 },
  {
    type: 'Notes Receivable',
    typeCode: 'NotesReceivableOrInstallment',
    value: 27,
  },
  { type: 'Other Income', typeCode: 'OtherIncome', value: 28 },
  {
    type: 'Pension Or Retirement Income',
    typeCode: 'PensionOrRetirementIncome',
    value: 32,
  },
  {
    type: 'Real Estate Or Mortgage Differential',
    typeCode: 'RealEstateOrMortgageDifferential',
    value: 33,
  },
  { type: 'Royalty Payments', typeCode: 'RoyaltyPayments', value: 34 },
  {
    type: 'Social Security Or Disability Income',
    typeCode: 'SocialSecurityOrDisabilityIncome',
    value: 36,
  },
  { type: 'Temporary Leave', typeCode: 'TemporaryLeave', value: 38 },
  { type: 'Tip Income', typeCode: 'TipIncome', value: 39 },
  { type: 'Trust Income', typeCode: 'TrustIncome', value: 40 },
  {
    type: 'Unemployment Or Public Assistance',
    typeCode: 'UnemploymentOrPublicAssistance',
    value: 41,
  },
  {
    type: 'UnReimbursed Expenses',
    typeCode: 'UnReimbursedExpenses',
    value: 42,
  },
  { type: 'VA Benefits', typeCode: 'VaBenefits', value: 43 },
];
