import PropTypes from 'prop-types';
import List from 'common/components/Lists/List/index.js';
import ListItem from 'common/components/Lists/ListItem/index.js';
import Text from 'common/components/Texts/Text/index.js';
import BreakdownColumnBox from '../styles/BreakdownColumnBox.js';
import { pixelsToRem } from 'theme/typography';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel.js';
import lang from '../language.json';

export default function Continuance({ selectedResult }) {
  const formulas = selectedResult
    .getFormulas()
    .filter((formula) => formula.formulaName.includes('Continuance'));

  if (!formulas.length) {
    return null;
  }

  return (
    <BreakdownColumnBox title={lang.HEADERS.CONTINUANCE}>
      <List as="ul">
        {formulas.map(({ formulaId, formula }) => (
          <Text fontSize={pixelsToRem(14)} as={ListItem} key={formulaId}>
            {formula}
          </Text>
        ))}
      </List>
    </BreakdownColumnBox>
  );
}
Continuance.propTypes = {
  selectedResult: PropTypes.instanceOf(GenericCalculationItemDataModel)
    .isRequired,
};
