import { FormHelperText as ChakraFormHelperText } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const FormHelperText = ({ children, ...props }) => {
  return <ChakraFormHelperText {...props}>{children}</ChakraFormHelperText>;
};

export default FormHelperText;

FormHelperText.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};
