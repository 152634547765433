import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonVariant, ButtonType } from './Button/Button';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';

const stateProp = PropTypes.shape({
  label: PropTypes.string,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  icon: PropTypes.string,
  sx: PropTypes.object,
  type: ButtonType,
  variant: ButtonVariant,
});

export default function ToggleButton(props) {
  const {
    name,
    label,
    on,
    off,
    defaultValue = false,
    type,
    variant,
    startIcon,
    endIcon,
    icon,
    disabled,
    sx,
    onClick,
  } = props;
  const {
    input: { value, onChange },
  } = useFieldApi(props);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(defaultValue), []);

  const selectedProps = value ? on : off;

  const toPass = {
    name,
    label,
    type,
    variant,
    startIcon,
    endIcon,
    icon,
    ...selectedProps,
    sx: {
      ...(sx || {}),
      ...(selectedProps.sx || {}),
    },
    role: 'switch',
    ['aria-checked']: value,
  };

  return (
    <Button
      onClick={(e) => {
        onChange(!value);
        onClick?.(e);
      }}
      disabled={disabled}
      {...toPass}
    />
  );
}
ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.bool,
  on: stateProp.isRequired,
  off: stateProp.isRequired,
  sx: PropTypes.object,
  type: ButtonType,
  variant: ButtonVariant,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
