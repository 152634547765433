/* c8 ignore file */
const EARLY_CLOSING_DISCLOSURE_TYPES = {
  GET_EARLY_CLOSING_DISCLOSURE_ERROR: 'GET_EARLY_CLOSING_DISCLOSURE_ERROR',
  GET_EARLY_CLOSING_DISCLOSURE_STARTED: 'GET_EARLY_CLOSING_DISCLOSURE_STARTED',
  GET_EARLY_CLOSING_DISCLOSURE_SUCCESS: 'GET_EARLY_CLOSING_DISCLOSURE_SUCCESS',
  SAVE_EARLY_CLOSING_DISCLOSURE_ERROR: 'SAVE_EARLY_CLOSING_DISCLOSURE_ERROR',
  SAVE_EARLY_CLOSING_DISCLOSURE_STARTED:
    'SAVE_EARLY_CLOSING_DISCLOSURE_STARTED',
  SAVE_EARLY_CLOSING_DISCLOSURE_SUCCESS:
    'SAVE_EARLY_CLOSING_DISCLOSURE_SUCCESS',
  PERSIST_FORM_DATA: 'PERSIST_FORM_DATA',
  UPDATE_FORM_FIELD: 'UPDATE_FORM_FIELD',
};

export default EARLY_CLOSING_DISCLOSURE_TYPES;
